import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import config from './store/config/Reducer';
import adminData from './store/connect/userData/reducer';
import connectAppData from './store/connect/appData/reducer';
import connectUi from './store/connect/ui/reducer';
import connectCamera from './store/connect/camera/reducer';
import common from './store/appData/common/reducer';
import user from './store/userData/user/reducer';
import texts from './store/appData/texts/Reducer';
import carEquipments from './store/appData/carEquipments/Reducer';
import serviceTypes from './store/appData/serviceTypes/Reducer';
import rental from './store/userData/rental/reducer';
import bookings from './store/userData/bookings/reducer';
import carsSearch from './store/carsSearch/Reducer';
import bluetooth from './store/bluetooth/Reducer';
import device from './store/device/Reducer';
import networkStatus from './store/networkStatus/Reducer';
import notifications from './store/ui/notifications/Reducer';
import dimensions from './store/ui/dimensions/Reducer';
import map from './store/ui/map/Reducer';
import deeplink from './store/ui/deeplink/Reducer';
import firstSteps from './store/ui/firstSteps/Reducer';
import damages from './store/ui/damages/Reducer';
import services from './store/service/reducer';
import areas from './store/appData/areas/Reducer';
import pois from './store/appData/pois/Reducer';
import features from './store/appData/features/Reducer';
import camera from './store/camera/Reducer';
import pricing from './store/pricing/Reducer';
import { getPersistConfig } from './getPersistConfig';

const rootReducer = combineReducers({
  connect: combineReducers({
    userData: persistReducer(getPersistConfig('adminData'), adminData),
    appData: persistReducer(getPersistConfig('connectAppData'), connectAppData),
    ui: persistReducer(getPersistConfig('connectUi'), connectUi),
    camera: persistReducer(getPersistConfig('connectCamera'), connectCamera),
  }),
  appData: persistReducer(
    getPersistConfig('appData'),
    combineReducers({
      features,
      areas,
      pois,
      texts,
      carEquipments,
      serviceTypes,
      common,
    })
  ),
  services,
  userData: combineReducers({
    user: persistReducer(getPersistConfig('user'), user),
    rental: persistReducer(getPersistConfig('rental'), rental),
    bookings: persistReducer(getPersistConfig('bookings'), bookings),
  }),
  carsSearch: persistReducer(getPersistConfig('carsSearch'), carsSearch),
  device: persistReducer(getPersistConfig('device'), device),
  bluetooth: persistReducer(getPersistConfig('bluetooth'), bluetooth),
  pricing,
  networkStatus: persistReducer(
    getPersistConfig('networkStatus'),
    networkStatus
  ),
  ui: combineReducers({
    notifications,
    dimensions,
    map: persistReducer(getPersistConfig('map'), map),
    deeplink,
    firstSteps: persistReducer(getPersistConfig('firstSteps'), firstSteps),
    damages: persistReducer(getPersistConfig('damages'), damages),
  }),
  camera: persistReducer(getPersistConfig('camera'), camera),
  config: persistReducer(getPersistConfig('config'), config),
});
export default rootReducer;
