import React, { useEffect } from 'react';
import './LanguageSelector.less';
import BrandIcon from '../BrandIcon/BrandIcon';
import { showAlertWithOptions } from '../../../utils';
import {
  getTexts,
  LocalizePropType,
  selectBrandSettings,
  selectDeviceLanguage,
  setLanguage,
  withTextLocalizer,
} from 'getaway-data-layer';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
  localize: LocalizePropType;
  showSelectedLanguage: boolean;
  white: boolean;
};

const languageFlagMap: Record<string, string> = {
  en: 'GB',
  de: 'DE',
  fr: 'FR',
  it: 'IT',
  pl: 'PL',
  sk: 'SK',
  cz: 'CZ',
  tr: 'TR',
};

const LanguageSelector = ({ showSelectedLanguage, white, localize }: Props) => {
  const dispatch = useDispatch();
  const language = useSelector(selectDeviceLanguage);
  const brandSettings = useSelector(selectBrandSettings);
  const allowedLanguages = brandSettings?.allowedLanguages || [];

  const showLanguageDialog = () => {
    const options = allowedLanguages.map((lang) => {
      const lg = lang === 'deformal' ? 'de' : lang;
      const countryCode = languageFlagMap?.[lg] || lg;
      return {
        title: localize(`language.dialog.${lg}`),
        selected: language === lang,
        icon: countryCode
          ? `http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`
          : undefined,
        action: () => {
          dispatch(setLanguage(lang));
          dispatch(getTexts());
        },
      };
    });

    showAlertWithOptions(localize('language.dialog.title'), '', options);
  };

  const displayLanguage = language === 'deformal' ? 'de' : language;

  useEffect(() => {
    // Handle cached languages for old users who have cached language as de but brand supports only deformal
    if (
      language &&
      language === 'de' &&
      !allowedLanguages.includes('de') &&
      allowedLanguages.includes('deformal')
    ) {
      dispatch(setLanguage('deformal'));
    }
  }, []);

  return (
    allowedLanguages.length > 1 && (
      <div
        className="language-container"
        style={white ? { opacity: 1 } : {}}
        onClick={showLanguageDialog}
      >
        <BrandIcon
          name={white ? 'languageWhite' : 'language'}
          style={{ width: 24, height: 24, marginRight: 3 }}
        />
        {showSelectedLanguage && displayLanguage?.toUpperCase()}
      </div>
    )
  );
};

LanguageSelector.defaultProps = {
  showSelectedLanguage: true,
  white: false,
};

export default React.memo(withTextLocalizer(LanguageSelector));
