import { TextsState } from '../../../../@types';
import activeTexts from './activeTexts';
import { isConnect } from '../../../../utils/isConnect';
import connectTexts from './initialData/InitialState.connect.data';

const emptyLanguage = {
  de: {},
  deformal: {},
  en: {},
};

const initialState: TextsState = {
  connect: isConnect() ? connectTexts : emptyLanguage,

  default: isConnect() ? emptyLanguage : activeTexts,
};

export default initialState;
