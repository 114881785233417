import React from 'react';
import './RentalWalkThroughView.less';
import Modal from '@mui/material/Modal';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.less';
import 'slick-carousel/slick/slick-theme.less';
import { LocalizePropType, isKYCApp } from 'getaway-data-layer';
import SlideItem from '../../../components/SlideItem/SlideItem';

type Props = {
  localize: LocalizePropType;
  open: boolean;
  sliderIndex: number;
  sliderRef: any;
  onClickNext: () => void;
  afterSlide: (index: number) => void;
};

const RentalWalkThroughView = ({
  localize,
  open,
  sliderRef,
  onClickNext,
  afterSlide,
}: Props) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'slick-dots rental-slick-dots',
  };
  return (
    <Modal open={open} onClose={() => {}} hideBackdrop sx={{ zIndex: 15 }}>
      <div>
        <div>
          <button
            className="home-signup-btn"
            type="submit"
            onClick={onClickNext}
          >
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            {localize('rental.walkthrough.button')}
          </button>
        </div>
        <Slider ref={sliderRef} {...settings} afterChange={afterSlide}>
          <SlideItem
            title={localize(`rental.walkthrough.page1.title`)}
            description={localize(`rental.walkthrough.page1.description`)}
            imageSrc={'/images/rentalWalkThrough1.png'}
          />
          <SlideItem
            title={localize(`rental.walkthrough.page2.title`)}
            description={localize(`rental.walkthrough.page2.description`)}
            imageSrc={'/images/rentalWalkThrough2.png'}
          />
          {!isKYCApp() && <div style={{ backgroundColor: 'transparent' }} />}
        </Slider>
      </div>
    </Modal>
  );
};

export default RentalWalkThroughView;
