import { APP } from '../enums';
import { TextGroup } from '../@types';
import { isConnect } from '../utils/isConnect';

export const isBrand = (xbrandId?: string | null | undefined) =>
  !!xbrandId && xbrandId.length;

// For now all Brands are forcing de, will keep the function for any future changes
export const getBrandLanguage = (xBrandId: string): string =>
  xBrandId
    ? isBND() || isTLA() || isFRM() || isMVV() || isGRF()
      ? 'deformal'
      : 'de'
    : 'de';

export const getBrandName = (xbrandId: string): string => {
  if (xbrandId) {
    if (xbrandId === '000000000000000000000002') {
      return 'ubq';
    }
    if (xbrandId === '000000000000000000000003') {
      return 'rw';
    }
    if (
      xbrandId === '6329b7e9448df21336278b69' ||
      xbrandId === '631b10eb46d0600e4545bb18'
    ) {
      return 'mba';
    }
    if (
      xbrandId === '66d47bb5d760865f0f1ec06a' ||
      xbrandId === '64215421fa9269520df3aad2' ||
      xbrandId === '6425a673a90bb10f63678c28'
    ) {
      return 'bnd';
    }
    if (xbrandId === '5ef1f2e4c8f48f0f7912d487') {
      return 'msi';
    }
    if (
      xbrandId === '64a44cc5d7a31b6ffe6628ed' ||
      xbrandId === '665eea6c3a906e06ecc5d306'
    ) {
      return 'tla';
    }
    if (
      xbrandId === '664e14bd4543650cd14422e9' ||
      xbrandId === '664f63166420fb076bbd2500'
    ) {
      return 'ctf';
    }
    if (
      xbrandId === '67224e0b93cfd812145b8cb4' ||
      xbrandId === '67225e21ec3cde06ec91240e'
    ) {
      return 'grf';
    }
    if (
      xbrandId === '667c2f40a4c9af5af7b7d529' ||
      xbrandId === '667d6383ea8db206e72d8148'
    ) {
      return 'sps';
    }
    if (
      xbrandId === '65771a4bc9944c3d1f0befcc' ||
      xbrandId === '6579aa3d3aadc607bd9674ae'
    ) {
      return 'frm';
    }
    if (
      xbrandId === '67031429d2e19b50c05b3ac5' ||
      xbrandId === '6705641eaa354b06daa69152'
    ) {
      return 'mvv';
    }
    return '';
  }
  return 'getaway';
};

export const DEFAULT_PASSWORD_REGEX =
  /^(?=(?=.*?[A-Z])|(?=.*?[0-9])|(?=.*?[#?!@$%^&*-]))(?=.*?[a-z]).{8,}$/ as any;

export const getBrandColor = (fleetStyle = null) => {
  switch (process.env.REACT_APP_X_BRAND_ID) {
    case '000000000000000000000003':
      return '#0077BE';
    case '000000000000000000000002':
      return fleetStyle === 'obi'
        ? '#ff7e21'
        : fleetStyle === 'expert'
        ? '#FF5E19'
        : fleetStyle === 'spp'
        ? '#2d2d2d'
        : '#00c4d1';
    case '5ef1f2e4c8f48f0f7912d487':
      return '#fac500';
    case '6329b7e9448df21336278b69':
    case '631b10eb46d0600e4545bb18':
      return '#1a6c7a';
    case '66d47bb5d760865f0f1ec06a':
    case '64215421fa9269520df3aad2':
    case '6425a673a90bb10f63678c28':
      return '#bb1b10';
    case '64a44cc5d7a31b6ffe6628ed':
    case '665eea6c3a906e06ecc5d306':
      return '#2E3080';
    case '65771a4bc9944c3d1f0befcc':
    case '6579aa3d3aadc607bd9674ae':
      return '#009EE2';
    case '67031429d2e19b50c05b3ac5':
    case '6705641eaa354b06daa69152':
      return '#0049ec';
    case '664e14bd4543650cd14422e9':
    case '664f63166420fb076bbd2500':
    case '667c2f40a4c9af5af7b7d529':
    case '667d6383ea8db206e72d8148':
      return '#75b42b';
    case '67224e0b93cfd812145b8cb4':
    case '67225e21ec3cde06ec91240e':
      return '#82ba26';
    default:
      return '#a2ad00';
  }
};

export const getSecondaryBrandColor = () => {
  switch (process.env.REACT_APP_X_BRAND_ID) {
    case '000000000000000000000003':
    case '000000000000000000000002':
    case '65771a4bc9944c3d1f0befcc':
    case '6579aa3d3aadc607bd9674ae':
    case '664e14bd4543650cd14422e9':
    case '67224e0b93cfd812145b8cb4':
    case '67225e21ec3cde06ec91240e':
    case '664f63166420fb076bbd2500':
    case '667c2f40a4c9af5af7b7d529':
    case '667d6383ea8db206e72d8148':
    case '67031429d2e19b50c05b3ac5':
    case '6705641eaa354b06daa69152':
      return 'white';
    case '5ef1f2e4c8f48f0f7912d487':
      return 'rgba(0,0,0,0.5)';
    case '6329b7e9448df21336278b69':
    case '631b10eb46d0600e4545bb18':
    case '64a44cc5d7a31b6ffe6628ed':
    case '665eea6c3a906e06ecc5d306':
      return 'white';
    default:
      return 'white';
  }
};

export const getLightBrandColor = (fleetStyle = null): string => {
  switch (process.env.REACT_APP_X_BRAND_ID) {
    case '000000000000000000000002':
      return fleetStyle === 'obi' || fleetStyle === 'expert'
        ? 'rgba(255,126, 33,0.1)'
        : fleetStyle === 'spp'
        ? 'rgba(45,45, 45,0.1)'
        : 'rgba(0, 196, 209, 0.13)';
    case '000000000000000000000003':
      return 'rgba(0, 119, 190, 0.13)';
    case '5ef1f2e4c8f48f0f7912d487':
      return 'rgba(250, 197, 0, 0.13)';
    case '6329b7e9448df21336278b69':
    case '631b10eb46d0600e4545bb18':
      return 'rgba(26, 108, 122,0.1)';
    case '66d47bb5d760865f0f1ec06a':
    case '64215421fa9269520df3aad2':
    case '6425a673a90bb10f63678c28':
      return 'rgba(255, 0, 0,0.1)';
    case '64a44cc5d7a31b6ffe6628ed':
    case '665eea6c3a906e06ecc5d306':
      return 'rgba(46,48,128,0.1)';
    case '65771a4bc9944c3d1f0befcc':
    case '6579aa3d3aadc607bd9674ae':
    case '67031429d2e19b50c05b3ac5':
    case '6705641eaa354b06daa69152':
      return 'rgba(0, 158, 226,0.1)';
    case '664e14bd4543650cd14422e9':
    case '664f63166420fb076bbd2500':
      return 'rgba(117, 180, 43,0.1)';
    case '667c2f40a4c9af5af7b7d529':
    case '667d6383ea8db206e72d8148':
    case '67224e0b93cfd812145b8cb4':
    case '67225e21ec3cde06ec91240e':
      return 'rgba(0, 158, 226,0.1)';
    default:
      return 'rgba(0, 119, 190, 0.13)';
  }
};

export const getVersionHeaderColor = (): string => {
  switch (process.env.REACT_APP_X_BRAND_ID) {
    case '000000000000000000000002':
    case '000000000000000000000003':
    case '5ef1f2e4c8f48f0f7912d487':
      return 'white';
    case '6329b7e9448df21336278b69':
    case '631b10eb46d0600e4545bb18':
    case '66d47bb5d760865f0f1ec06a':
    case '64215421fa9269520df3aad2':
    case '6425a673a90bb10f63678c28':
    case '64a44cc5d7a31b6ffe6628ed':
    case '665eea6c3a906e06ecc5d306':
      return 'grey';
    case '65771a4bc9944c3d1f0befcc':
    case '6579aa3d3aadc607bd9674ae':
    case '67031429d2e19b50c05b3ac5':
    case '6705641eaa354b06daa69152':
      return 'black';
    case '664e14bd4543650cd14422e9':
    case '664f63166420fb076bbd2500':
    case '667c2f40a4c9af5af7b7d529':
    case '667d6383ea8db206e72d8148':
    case '67224e0b93cfd812145b8cb4':
    case '67225e21ec3cde06ec91240e':
      return 'black';
    default:
      return 'rgba(0, 119, 190, 0.13)';
  }
};

export const getBrandImagePath = (imageName: string): string => {
  if (process.env.REACT_APP_THEME) {
    return `/images/${process.env.REACT_APP_THEME?.toLowerCase()}/${imageName}.png`;
  }
  return `/images/msm/${imageName}.png`;
};

export const isROB = (): boolean =>
  process.env.REACT_APP_X_BRAND_ID === '000000000000000000000003';

export const isUBQ = (): boolean =>
  process.env.REACT_APP_X_BRAND_ID === '000000000000000000000002';

export const isMSM = (): boolean =>
  process.env.REACT_APP_X_BRAND_ID === '5ef1f2e4c8f48f0f7912d487';

export const isTLA = (): boolean =>
  process.env.REACT_APP_X_BRAND_ID === '64a44cc5d7a31b6ffe6628ed' ||
  process.env.REACT_APP_X_BRAND_ID === '665eea6c3a906e06ecc5d306';

export const isCTF = (): boolean =>
  process.env.REACT_APP_X_BRAND_ID === '664e14bd4543650cd14422e9' ||
  process.env.REACT_APP_X_BRAND_ID === '664f63166420fb076bbd2500';

export const isGRF = (): boolean =>
  process.env.REACT_APP_X_BRAND_ID === '67224e0b93cfd812145b8cb4' ||
  process.env.REACT_APP_X_BRAND_ID === '67225e21ec3cde06ec91240e';

export const isFRM = (): boolean =>
  process.env.REACT_APP_X_BRAND_ID === '65771a4bc9944c3d1f0befcc' ||
  process.env.REACT_APP_X_BRAND_ID === '6579aa3d3aadc607bd9674ae';

export const isMVV = (): boolean =>
  process.env.REACT_APP_X_BRAND_ID === '67031429d2e19b50c05b3ac5' ||
  process.env.REACT_APP_X_BRAND_ID === '6705641eaa354b06daa69152';

export const isSPS = (): boolean =>
  process.env.REACT_APP_X_BRAND_ID === '667c2f40a4c9af5af7b7d529' ||
  process.env.REACT_APP_X_BRAND_ID === '667d6383ea8db206e72d8148';

export const isMBA = (): boolean =>
  process.env.REACT_APP_X_BRAND_ID === '6329b7e9448df21336278b69' ||
  process.env.REACT_APP_X_BRAND_ID === '631b10eb46d0600e4545bb18';

export const isMYM = (): boolean =>
  process.env.REACT_APP_X_BRAND_ID === '65c902b020b9da363f47ac71' ||
  process.env.REACT_APP_X_BRAND_ID === '65c4e44424b2c707891f4159';

export const isBND = (): boolean =>
  process.env.REACT_APP_X_BRAND_ID === '64215421fa9269520df3aad2' ||
  process.env.REACT_APP_X_BRAND_ID === '6425a673a90bb10f63678c28' ||
  process.env.REACT_APP_X_BRAND_ID === '66d47bb5d760865f0f1ec06a';

export const isMymBrand = (xbrandId?: string | null | undefined) =>
  xbrandId === '65c902b020b9da363f47ac71' ||
  xbrandId === '65c4e44424b2c707891f4159';

export const isKYCApp = (): boolean => process.env.REACT_APP_KYC_APP === 'true';

export const isServicesTabEnabled = (): boolean =>
  process.env.REACT_APP_ENABEL_SERVICES_TAB === 'true';

const MOBILE_GROUPS = {
  [APP.BRAND.UBEEQO]: 'ubeeqo',
  [APP.BRAND.ROBBEN_WIENTJES]: 'robben-wientjes',
  [APP.BRAND.KONIG]: 'konig',
  [APP.BRAND.MBA_STAGING]: 'mietbar',
  [APP.BRAND.MBA_PRODUCTION]: 'mietbar',
  [APP.BRAND.MYM_STAGING]: 'my-motor',
  [APP.BRAND.MYM_PRODUCTION]: 'my-motor',
};

export const getBrandGroup = (xBrandId): TextGroup => {
  // Mobile
  if (MOBILE_GROUPS[xBrandId]) {
    return MOBILE_GROUPS[xBrandId] as TextGroup;
  }
  // Web
  if (isMSM()) return 'miet-smart';
  if (isBND()) return 'book-n-drive';
  if (isTLA()) return 'teilauto';
  if (isCTF()) return 'cityflitzer';
  if (isFRM()) return 'franklin-mobil';
  if (isMVV()) return 'mvv-fahrpack';
  if (isGRF()) return 'gruene-flotte';
  if (isSPS()) return 'sheepersharing';
  if (isConnect()) return 'connect';
  return 'default';
};
