import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  signUp,
  withTextLocalizer,
  selectBrandSettings,
  LocalizePropType,
  DEFAULT_PASSWORD_REGEX,
  isKYCApp,
  selectDialog,
  setShowHomeWT,
  selectPreSignupBooking,
} from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import SignupView from './SignupView';
import {
  GA_TITLES,
  isEmailVerificationRequired,
  navigateTo,
  reportGAEvent,
} from '../../../utils';
import { showSignupTermsDialog } from '../../../utils/dialogsHelpers';
import {
  SignupModal,
  SignupSchema,
  SIGNUP_TYPES,
  LEGAL_TYPES,
} from '../../../validation';
import { useQuery } from '../../../hooks/useQuery';
import { PRIVACY_POLICY_STATUS } from '../../../enums';

interface Values {
  // org
  companyName?: string;
  legalType?: string;
  otherLegalName?: string;
  registerNr?: string;
  registerLocation?: string;

  // private
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

type Props = {
  localize: LocalizePropType;
  containerStyles: any;
  showBack: boolean;
};

const SignupContainer = ({
  localize,
  containerStyles,
  showBack = true,
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const brandSettings = useSelector(selectBrandSettings);
  const orgSignupDisabled =
    brandSettings?.userOnboardingSettings?.orgSignupDisabled;
  const legacyOrgURL = brandSettings?.userOnboardingSettings?.legacyOrgURL;
  const privacyPolicyConsentRequired =
    brandSettings?.privacyPolicyConsentRequired;
  const preSignupBooking = useSelector(selectPreSignupBooking);
  const selectedDialog = useSelector(selectDialog);
  const query = useQuery();
  const [signupType, setSignupType] = useState(
    query.get('type') === 'org' ? SIGNUP_TYPES.ORG : SIGNUP_TYPES.PRIVATE
  );

  const [showPassword, setShowPassword] = useState(false);
  const [privacyPolicyStatus, setPrivacyPolicyStatus] = useState(
    PRIVACY_POLICY_STATUS.OPEN
  );
  const circleCode = query?.get('id') || query?.get('circleCode');

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit = (values: any) => {
    const { firstName, lastName, email, password } = values;
    if (
      privacyPolicyConsentRequired &&
      privacyPolicyStatus === PRIVACY_POLICY_STATUS.OPEN
    ) {
      setPrivacyPolicyStatus(PRIVACY_POLICY_STATUS.ERROR);
    } else if (
      !privacyPolicyConsentRequired ||
      (privacyPolicyStatus === PRIVACY_POLICY_STATUS.APPROVED &&
        privacyPolicyConsentRequired)
    ) {
      const signUpInput = {
        ...values,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        password: password.trim(),
        userType: signupType === SIGNUP_TYPES.ORG ? 'org' : 'user',
      };
      if (circleCode) {
        // @ts-ignore
        signUpInput.circleCode = circleCode;
      }
      dispatch(
        signUp(
          signUpInput,
          localize,
          (user) => {
            if (brandSettings?.onboardingType === 'onBooking') {
              navigateTo(history, '/map');
            } else {
              navigateTo(
                history,
                isEmailVerificationRequired(user)
                  ? '/onboarding/email'
                  : '/onboarding/addPhone'
              );
            }
            reportGAEvent(GA_TITLES.AUTH.SIGN_UP_SUBMITTED);
          },
          query.get('utm_source'),
          query.get('utm_medium'),
          () => {
            setTimeout(() => navigateTo(history, '/login'), 100);
          }
        )
      );
    }
  };

  const SignupFormik = useFormik({
    initialValues: SignupModal(signupType),
    validationSchema: SignupSchema(
      signupType,
      localize,
      brandSettings?.passwordRegex || DEFAULT_PASSWORD_REGEX
    ),
    onSubmit,
  });

  const onClose = () => {
    if (signupType === SIGNUP_TYPES.ORG) {
      setSignupType(SIGNUP_TYPES.PRIVATE);
    } else if (preSignupBooking) {
      history.goBack();
    } else {
      window.location.replace(location.origin);
    }
  };

  const showAboutDialog = () => {
    showSignupTermsDialog(localize);
  };

  const onPressPrivacyPolicyConsent = () => {
    setPrivacyPolicyStatus(
      privacyPolicyStatus === PRIVACY_POLICY_STATUS.APPROVED
        ? PRIVACY_POLICY_STATUS.OPEN
        : PRIVACY_POLICY_STATUS.APPROVED
    );
  };

  const onChangeSignupType = (e) => {
    if (e.target.value === SIGNUP_TYPES.COMPANY) {
      if (!orgSignupDisabled) {
        if (brandSettings?.kycSettings?.v2OrgSignupEnabled) {
          history.replace('/org-signup');
        } else {
          history.replace('/signup/?type=org');
          setSignupType(SIGNUP_TYPES.ORG);
        }
      } else {
        window.open(
          legacyOrgURL || localize('signup.type.company.url'),
          '_blank'
        );
      }
      reportGAEvent(GA_TITLES.AUTH.SIGN_UP_VIA_COMPANY_CLICKED);
    } else if (e.target.value === SIGNUP_TYPES.CO_USER) {
      window.open(localize('signup.type.co_user.url'), '_blank');
      reportGAEvent(GA_TITLES.AUTH.SIGN_UP_AS_CO_USER);
    } else if (e.target.value === SIGNUP_TYPES.BIKE) {
      window.open(localize('signup.type.bike.url'), '_blank');
      reportGAEvent(GA_TITLES.AUTH.SIGN_UP_AS_CARGO_BIKE);
    } else if (e.target.value === SIGNUP_TYPES.ORG) {
      if (!orgSignupDisabled) {
        if (brandSettings?.kycSettings?.v2OrgSignupEnabled) {
          history.replace('/org-signup');
        } else {
          history.replace('/signup/?type=org');
          setSignupType(SIGNUP_TYPES.ORG);
        }
      } else {
        window.open(legacyOrgURL || localize('signup.type.org.url'), '_blank');
      }
      reportGAEvent(GA_TITLES.AUTH.SIGN_UP_AS_ORG);
    } else if (e.target.value === SIGNUP_TYPES.PARTNER) {
      window.open(localize('signup.type.partner.url'), '_blank');
      reportGAEvent(GA_TITLES.AUTH.SIGN_UP_AS_PARTNER);
    }
  };
  const onChangeLegalType = (e) => {
    SignupFormik.setFieldValue('legalType', e.target.value);
  };

  const onPressLogin = () => {
    navigateTo(history, '/login');
  };

  const handleCompanyNameChange = (e) => {
    const value = e.target.value;
    const trimmedValue = value.trim();

    const lastWord = trimmedValue.split(' ').pop();

    if (Object.values(LEGAL_TYPES).includes(lastWord)) {
      SignupFormik.setFieldValue('legalType', lastWord);
      SignupFormik.setFieldValue(
        'companyName',
        trimmedValue.replace(new RegExp(`\\s*${lastWord}$`), '')
      );
    } else {
      SignupFormik.setFieldValue('companyName', value);
    }
  };

  useEffect(() => {
    if (!selectedDialog) {
      SignupFormik.setFieldValue('signupType', SIGNUP_TYPES.PRIVATE);
    }
  }, [selectedDialog]);

  useEffect(() => {
    SignupFormik.setValues(SignupModal(signupType));
  }, [signupType]);

  return (
    <SignupView
      localize={localize}
      formik={SignupFormik}
      signupType={signupType}
      containerStyles={containerStyles}
      showBack={showBack}
      showPassword={showPassword}
      showLoginLink={!!preSignupBooking}
      privacyPolicyStatus={privacyPolicyStatus}
      privacyPolicyConsentRequired={privacyPolicyConsentRequired}
      onClose={onClose}
      showAboutDialog={showAboutDialog}
      handleClickShowPassword={handleClickShowPassword}
      handleMouseDownPassword={handleMouseDownPassword}
      onChangeSignupType={onChangeSignupType}
      onPressLogin={onPressLogin}
      onPressPrivacyPolicyConsent={onPressPrivacyPolicyConsent}
      onChangeLegalType={onChangeLegalType}
      handleCompanyNameChange={handleCompanyNameChange}
    />
  );
};

export default withTextLocalizer(SignupContainer);
