import { TextGroup } from './ConfigStoreTypes';
import { Language } from './DeviceTypes';

export type Languages = {
  readonly en?: Record<string, string>;
  readonly de?: Record<string, string>;
} & {
  readonly [key: string]: Record<string, string>;
};

export type Texts = {
  readonly default: Languages;

  readonly connect: Languages;
};

export type TextsState = Texts;

const TextsTypes = {
  SET_TEXTS: 'appData/texts/SET_TEXTS',
};

export default TextsTypes;

export type SetTexts = {
  type: string;
  payload: {
    group: TextGroup;
    language: Language;
    texts: Texts;
  };
};

export type TextsAction = SetTexts;
