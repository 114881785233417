export default [
  {
    "Gas Station Number": "0F021",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heerstraße  325",
    "Postcode": 13593,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5166640",
    "Longitude": "013.1778080",
    "Telephone Number": "0303616676",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.516664,13.177808",
    "IsOpen24Hours": true,
    "id": "52.516664,13.177808"
  },
  {
    "Gas Station Number": "0F022",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Residenzstraße 29",
    "Postcode": 13409,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5683150",
    "Longitude": "013.3618640",
    "Telephone Number": "0304961035",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.568315,13.361864",
    "IsOpen24Hours": false,
    "id": "52.568315,13.361864"
  },
  {
    "Gas Station Number": "0F023",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Seestraße 18/19",
    "Postcode": 13353,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5464030",
    "Longitude": "013.3451870",
    "Telephone Number": "0304536026",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.546403,13.345187",
    "IsOpen24Hours": true,
    "id": "52.546403,13.345187"
  },
  {
    "Gas Station Number": "0F027",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Britzer Damm 202-204",
    "Postcode": 12347,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4453680",
    "Longitude": "013.4334140",
    "Telephone Number": "0306076210",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.445368,13.433414",
    "IsOpen24Hours": true,
    "id": "52.445368,13.433414"
  },
  {
    "Gas Station Number": "0F028",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Potsdamer Chaussee 6",
    "Postcode": 14163,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4331100",
    "Longitude": "013.2380920",
    "Telephone Number": "0308027017",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.43311,13.238092",
    "IsOpen24Hours": true,
    "id": "52.43311,13.238092"
  },
  {
    "Gas Station Number": "0F029",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Reichsstraße 13/14",
    "Postcode": 14052,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5136460",
    "Longitude": "013.2660360",
    "Telephone Number": "0303042708",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.513646,13.266036",
    "IsOpen24Hours": true,
    "id": "52.513646,13.266036"
  },
  {
    "Gas Station Number": "0F030",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Prinzenstraße 29",
    "Postcode": 10969,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5018940",
    "Longitude": "013.4098390",
    "Telephone Number": "0306149860",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.501894,13.409839",
    "IsOpen24Hours": true,
    "id": "52.501894,13.409839"
  },
  {
    "Gas Station Number": "0F031",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Falkenseer Damm 16",
    "Postcode": 13585,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5427460",
    "Longitude": "013.1993040",
    "Telephone Number": "0303333811",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.542746,13.199304",
    "IsOpen24Hours": true,
    "id": "52.542746,13.199304"
  },
  {
    "Gas Station Number": "0F032",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rathenower Straße 8",
    "Postcode": 10559,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5254580",
    "Longitude": "013.3568910",
    "Telephone Number": "0303944041",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.525458,13.356891",
    "IsOpen24Hours": true,
    "id": "52.525458,13.356891"
  },
  {
    "Gas Station Number": "0F033",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Levetzowstraße 9",
    "Postcode": 10555,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5210820",
    "Longitude": "013.3340040",
    "Telephone Number": "0303914575",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.521082,13.334004",
    "IsOpen24Hours": true,
    "id": "52.521082,13.334004"
  },
  {
    "Gas Station Number": "0F034",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Argentinische Allee 49",
    "Postcode": 14163,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4445440",
    "Longitude": "013.2425880",
    "Telephone Number": "03081496169",
    "Week From": "06:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "52.444544,13.242588",
    "IsOpen24Hours": false,
    "id": "52.444544,13.242588"
  },
  {
    "Gas Station Number": "0F035",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Brunnenstraße 119",
    "Postcode": 13355,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5407850",
    "Longitude": "013.3934570",
    "Telephone Number": "03046404481",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.540785,13.393457",
    "IsOpen24Hours": true,
    "id": "52.540785,13.393457"
  },
  {
    "Gas Station Number": "0F036",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mariendorfer Damm 195",
    "Postcode": 12107,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4340360",
    "Longitude": "013.3905710",
    "Telephone Number": "0307418509",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.434036,13.390571",
    "IsOpen24Hours": true,
    "id": "52.434036,13.390571"
  },
  {
    "Gas Station Number": "0F037",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gartenfelder Straße 71",
    "Postcode": 13599,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5435680",
    "Longitude": "013.2432650",
    "Telephone Number": "0303343935",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.543568,13.243265",
    "IsOpen24Hours": true,
    "id": "52.543568,13.243265"
  },
  {
    "Gas Station Number": "0F004",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 86",
    "Postcode": 12159,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4741130",
    "Longitude": "013.3388690",
    "Telephone Number": "0308515286",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.474113,13.338869",
    "IsOpen24Hours": true,
    "id": "52.474113,13.338869"
  },
  {
    "Gas Station Number": "0F039",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Scharnweberstraße 12-13",
    "Postcode": 13405,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5633750",
    "Longitude": "013.3298870",
    "Telephone Number": "0304964005",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.563375,13.329887",
    "IsOpen24Hours": true,
    "id": "52.563375,13.329887"
  },
  {
    "Gas Station Number": "0F040",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neuköllner Straße 219",
    "Postcode": 12357,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4286310",
    "Longitude": "013.4798370",
    "Telephone Number": "0306614212",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.428631,13.479837",
    "IsOpen24Hours": false,
    "id": "52.428631,13.479837"
  },
  {
    "Gas Station Number": "0F042",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berliner Straße 24",
    "Postcode": 13507,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5843720",
    "Longitude": "013.2877440",
    "Telephone Number": "03043400950",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.584372,13.287744",
    "IsOpen24Hours": true,
    "id": "52.584372,13.287744"
  },
  {
    "Gas Station Number": "0F043",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Vor dem Schlesischen Tor",
    "Postcode": 10997,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4968570",
    "Longitude": "013.4496140",
    "Telephone Number": "03061286590",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.496857,13.449614",
    "IsOpen24Hours": true,
    "id": "52.496857,13.449614"
  },
  {
    "Gas Station Number": "0F044",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Forsthausstraße 50",
    "Postcode": 60528,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.0800150",
    "Longitude": "008.6552580",
    "Telephone Number": "069671026",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.080015,8.655258",
    "IsOpen24Hours": true,
    "id": "50.080015,8.655258"
  },
  {
    "Gas Station Number": "0F045",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Eschersh. Landstr.  523",
    "Postcode": 60431,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.1516550",
    "Longitude": "008.6623660",
    "Telephone Number": "069525947",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "08:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "50.151655,8.662366",
    "IsOpen24Hours": false,
    "id": "50.151655,8.662366"
  },
  {
    "Gas Station Number": "0F046",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Louisenstraße 146",
    "Postcode": 61348,
    "City": "Bad Homburg",
    "State": "Hessen",
    "Latitude": "050.2217340",
    "Longitude": "008.6285910",
    "Telephone Number": "06172450201",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.221734,8.628591",
    "IsOpen24Hours": false,
    "id": "50.221734,8.628591"
  },
  {
    "Gas Station Number": "0F047",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gelnhäuser Straße 17",
    "Postcode": 63505,
    "City": "Langenselbold",
    "State": "Hessen",
    "Latitude": "050.1756960",
    "Longitude": "009.0460260",
    "Telephone Number": "061842103",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.175696,9.046026",
    "IsOpen24Hours": false,
    "id": "50.175696,9.046026"
  },
  {
    "Gas Station Number": "0F048",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "A 66 Erbenheim-Nord",
    "Postcode": 65205,
    "City": "Wiesbaden",
    "State": "Hessen",
    "Latitude": "050.0551130",
    "Longitude": "008.3119170",
    "Telephone Number": "0611718300",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.055113,8.311917",
    "IsOpen24Hours": true,
    "id": "50.055113,8.311917"
  },
  {
    "Gas Station Number": "0F049",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Frankfurter Landstr. 200",
    "Postcode": 61440,
    "City": "Oberursel",
    "State": "Hessen",
    "Latitude": "050.1804410",
    "Longitude": "008.6053760",
    "Telephone Number": "0617173993",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.180441,8.605376",
    "IsOpen24Hours": true,
    "id": "50.180441,8.605376"
  },
  {
    "Gas Station Number": "0F050",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Konrad-Adenauer-Allee 1-3",
    "Postcode": 61130,
    "City": "Nidderau",
    "State": "Hessen",
    "Latitude": "050.2339360",
    "Longitude": "008.8632510",
    "Telephone Number": "0618722133",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.233936,8.863251",
    "IsOpen24Hours": true,
    "id": "50.233936,8.863251"
  },
  {
    "Gas Station Number": "0F052",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Frankfurter Straße 52",
    "Postcode": 61476,
    "City": "Kronberg",
    "State": "Hessen",
    "Latitude": "050.1739230",
    "Longitude": "008.5233870",
    "Telephone Number": "0617379124",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.173923,8.523387",
    "IsOpen24Hours": false,
    "id": "50.173923,8.523387"
  },
  {
    "Gas Station Number": "0F053",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Saalburgstraße 30",
    "Postcode": 61267,
    "City": "Neu-Anspach",
    "State": "Hessen",
    "Latitude": "050.2898100",
    "Longitude": "008.5122900",
    "Telephone Number": "060819657590",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.28981,8.51229",
    "IsOpen24Hours": false,
    "id": "50.28981,8.51229"
  },
  {
    "Gas Station Number": "0F054",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 10",
    "Postcode": 63486,
    "City": "Bruchköbel",
    "State": "Hessen",
    "Latitude": "050.1780680",
    "Longitude": "008.9142580",
    "Telephone Number": "0618177724",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "18:00",
    "Sundays From": "09:00",
    "Sundays To": "18:00",
    "24 hour?": "Nein",
    "location": "50.178068,8.914258",
    "IsOpen24Hours": false,
    "id": "50.178068,8.914258"
  },
  {
    "Gas Station Number": "0F055",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kirchhohl 15",
    "Postcode": 65207,
    "City": "Wiesbaden",
    "State": "Hessen",
    "Latitude": "050.1328180",
    "Longitude": "008.2971000",
    "Telephone Number": "0612761428",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "08:00",
    "Saturdays To": "18:00",
    "Sundays From": "10:00",
    "Sundays To": "17:00",
    "24 hour?": "Nein",
    "location": "50.132818,8.2971",
    "IsOpen24Hours": false,
    "id": "50.132818,8.2971"
  },
  {
    "Gas Station Number": "0F056",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dotzheimer Straße 93",
    "Postcode": 65197,
    "City": "Wiesbaden",
    "State": "Hessen",
    "Latitude": "050.0777020",
    "Longitude": "008.2212850",
    "Telephone Number": "061147027",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.077702,8.221285",
    "IsOpen24Hours": true,
    "id": "50.077702,8.221285"
  },
  {
    "Gas Station Number": "0F057",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bieberer Straße 157",
    "Postcode": 63071,
    "City": "Offenbach",
    "State": "Hessen",
    "Latitude": "050.0989880",
    "Longitude": "008.7858160",
    "Telephone Number": "06985703117",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.098988,8.785816",
    "IsOpen24Hours": true,
    "id": "50.098988,8.785816"
  },
  {
    "Gas Station Number": "0F058",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Darmstädter Landstr. 304",
    "Postcode": 60598,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.0897010",
    "Longitude": "008.6907470",
    "Telephone Number": "0696809040",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.089701,8.690747",
    "IsOpen24Hours": true,
    "id": "50.089701,8.690747"
  },
  {
    "Gas Station Number": "0F059",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mainzer Landstraße 545",
    "Postcode": 65933,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.1001870",
    "Longitude": "008.6037210",
    "Telephone Number": "069394954",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.100187,8.603721",
    "IsOpen24Hours": true,
    "id": "50.100187,8.603721"
  },
  {
    "Gas Station Number": "0F005",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Zeilsheimer Straße 62",
    "Postcode": 65719,
    "City": "Hofheim",
    "State": "Hessen",
    "Latitude": "050.0879860",
    "Longitude": "008.4583490",
    "Telephone Number": "061926791",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.087986,8.458349",
    "IsOpen24Hours": true,
    "id": "50.087986,8.458349"
  },
  {
    "Gas Station Number": "0F060",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Pariser Straße 2",
    "Postcode": 55131,
    "City": "Mainz",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.9878600",
    "Longitude": "008.2669550",
    "Telephone Number": "06131573110",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.98786,8.266955",
    "IsOpen24Hours": true,
    "id": "49.98786,8.266955"
  },
  {
    "Gas Station Number": "0F061",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Erbacher Straße 2",
    "Postcode": 65343,
    "City": "Eltville",
    "State": "Hessen",
    "Latitude": "050.0241370",
    "Longitude": "008.1160590",
    "Telephone Number": "06123899352",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.024137,8.116059",
    "IsOpen24Hours": false,
    "id": "50.024137,8.116059"
  },
  {
    "Gas Station Number": "0F063",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A3",
    "Postcode": 65520,
    "City": "Bad Camberg",
    "State": "Hessen",
    "Latitude": "050.3004100",
    "Longitude": "008.2368240",
    "Telephone Number": "06434/9085738",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.30041,8.236824",
    "IsOpen24Hours": true,
    "id": "50.30041,8.236824"
  },
  {
    "Gas Station Number": "0F064",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Steinbacher Straße1",
    "Postcode": 65620,
    "City": "Waldbrunn",
    "State": "Hessen",
    "Latitude": "050.5055610",
    "Longitude": "008.0922330",
    "Telephone Number": "06436602648",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.505561,8.092233",
    "IsOpen24Hours": false,
    "id": "50.505561,8.092233"
  },
  {
    "Gas Station Number": "0F065",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Urseler Straße 38",
    "Postcode": 61348,
    "City": "Bad Homburg",
    "State": "Hessen",
    "Latitude": "050.2216590",
    "Longitude": "008.6070800",
    "Telephone Number": "06172301300",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.221659,8.60708",
    "IsOpen24Hours": false,
    "id": "50.221659,8.60708"
  },
  {
    "Gas Station Number": "0F066",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 1",
    "Postcode": 55595,
    "City": "Roxheim",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.8675740",
    "Longitude": "007.8202350",
    "Telephone Number": "067120354475",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.867574,7.820235",
    "IsOpen24Hours": false,
    "id": "49.867574,7.820235"
  },
  {
    "Gas Station Number": "0F067",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Monzinger Straße 102-104",
    "Postcode": 55566,
    "City": "Bad Sobernheim",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.7876750",
    "Longitude": "007.6393320",
    "Telephone Number": "067514069",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "06:00",
    "Saturdays To": "20:00",
    "Sundays From": "07:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.787675,7.639332",
    "IsOpen24Hours": false,
    "id": "49.787675,7.639332"
  },
  {
    "Gas Station Number": "0F068",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bosenheimer Straße 45",
    "Postcode": 55543,
    "City": "Bad Kreuznach",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.8419890",
    "Longitude": "007.8672410",
    "Telephone Number": "06718962600",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.841989,7.867241",
    "IsOpen24Hours": true,
    "id": "49.841989,7.867241"
  },
  {
    "Gas Station Number": "0F069",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Im Steinautal 2",
    "Postcode": 55765,
    "City": "Ellweiler",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.6172270",
    "Longitude": "007.1589070",
    "Telephone Number": "067822453",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.617227,7.158907",
    "IsOpen24Hours": false,
    "id": "49.617227,7.158907"
  },
  {
    "Gas Station Number": "0F070",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kreuznacher Straße/B 41",
    "Postcode": 55596,
    "City": "Waldböckelheim",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.8190160",
    "Longitude": "007.7213660",
    "Telephone Number": "067586273",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "08:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.819016,7.721366",
    "IsOpen24Hours": false,
    "id": "49.819016,7.721366"
  },
  {
    "Gas Station Number": "0F071",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Aral an der A 66, Nordsei",
    "Postcode": 65929,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.1196080",
    "Longitude": "008.5439030",
    "Telephone Number": "06930852981",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.119608,8.543903",
    "IsOpen24Hours": true,
    "id": "50.119608,8.543903"
  },
  {
    "Gas Station Number": "0F072",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Aral an der A 66, Südseit",
    "Postcode": 65929,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.1180790",
    "Longitude": "008.5417270",
    "Telephone Number": "06930850810",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.118079,8.541727",
    "IsOpen24Hours": true,
    "id": "50.118079,8.541727"
  },
  {
    "Gas Station Number": "0F073",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Vordere Oderstraße 2",
    "Postcode": 65468,
    "City": "Trebur",
    "State": "Hessen",
    "Latitude": "049.9303500",
    "Longitude": "008.4066780",
    "Telephone Number": "061473139",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "49.93035,8.406678",
    "IsOpen24Hours": false,
    "id": "49.93035,8.406678"
  },
  {
    "Gas Station Number": "0F074",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gelnhäuser Straße 1",
    "Postcode": 63571,
    "City": "Gelnhausen",
    "State": "Hessen",
    "Latitude": "050.1867130",
    "Longitude": "009.1621860",
    "Telephone Number": "0605168801",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.186713,9.162186",
    "IsOpen24Hours": false,
    "id": "50.186713,9.162186"
  },
  {
    "Gas Station Number": "0F076",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hitchinstraße 44",
    "Postcode": 55411,
    "City": "Bingen",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.9484580",
    "Longitude": "007.9031180",
    "Telephone Number": "0672144743",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.948458,7.903118",
    "IsOpen24Hours": true,
    "id": "49.948458,7.903118"
  },
  {
    "Gas Station Number": "0F077",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Waldstraße 215",
    "Postcode": 63071,
    "City": "Offenbach",
    "State": "Hessen",
    "Latitude": "050.0868800",
    "Longitude": "008.7704830",
    "Telephone Number": "06983071423",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.08688,8.770483",
    "IsOpen24Hours": true,
    "id": "50.08688,8.770483"
  },
  {
    "Gas Station Number": "0F078",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Homburger Straße 66",
    "Postcode": 61184,
    "City": "Karben",
    "State": "Hessen",
    "Latitude": "050.2317800",
    "Longitude": "008.7664450",
    "Telephone Number": "060393472",
    "Week From": "04:30",
    "Week To": "23:00",
    "Saturdays From": "04:30",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "50.23178,8.766445",
    "IsOpen24Hours": false,
    "id": "50.23178,8.766445"
  },
  {
    "Gas Station Number": "0F080",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "An der B 49 Südseite",
    "Postcode": 65614,
    "City": "Beselich",
    "State": "Hessen",
    "Latitude": "050.4679150",
    "Longitude": "008.1374990",
    "Telephone Number": "06484396",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.467915,8.137499",
    "IsOpen24Hours": true,
    "id": "50.467915,8.137499"
  },
  {
    "Gas Station Number": "0F082",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 27",
    "Postcode": 61209,
    "City": "Echzell",
    "State": "Hessen",
    "Latitude": "050.3833140",
    "Longitude": "008.8824630",
    "Telephone Number": "06008424",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.383314,8.882463",
    "IsOpen24Hours": false,
    "id": "50.383314,8.882463"
  },
  {
    "Gas Station Number": "0F083",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Höhenstraße 1",
    "Postcode": 61381,
    "City": "Friedrichsdorf",
    "State": "Hessen",
    "Latitude": "050.2529220",
    "Longitude": "008.6350350",
    "Telephone Number": "061722663645",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.252922,8.635035",
    "IsOpen24Hours": false,
    "id": "50.252922,8.635035"
  },
  {
    "Gas Station Number": "0F079",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lämmerspieler Straße 50",
    "Postcode": 63179,
    "City": "Obertshausen",
    "State": "Hessen",
    "Latitude": "050.0887200",
    "Longitude": "008.8637300",
    "Telephone Number": "06104407280",
    "Week From": "06:00",
    "Week To": "21:30",
    "Saturdays From": "06:00",
    "Saturdays To": "21:30",
    "Sundays From": "07:00",
    "Sundays To": "21:30",
    "24 hour?": "Nein",
    "location": "50.08872,8.86373",
    "IsOpen24Hours": false,
    "id": "50.08872,8.86373"
  },
  {
    "Gas Station Number": "0F081",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kennedy-/Berliner Straße",
    "Postcode": 63477,
    "City": "Maintal",
    "State": "Hessen",
    "Latitude": "050.1321620",
    "Longitude": "008.8500560",
    "Telephone Number": "06181441748",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.132162,8.850056",
    "IsOpen24Hours": true,
    "id": "50.132162,8.850056"
  },
  {
    "Gas Station Number": "0F084",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Usinger Straße 154",
    "Postcode": 61239,
    "City": "Ober-Mörlen-Ziegenbe",
    "State": "Hessen",
    "Latitude": "050.3663840",
    "Longitude": "008.6238500",
    "Telephone Number": "06002868",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "06:00",
    "Saturdays To": "19:30",
    "Sundays From": "08:00",
    "Sundays To": "19:30",
    "24 hour?": "Nein",
    "location": "50.366384,8.62385",
    "IsOpen24Hours": false,
    "id": "50.366384,8.62385"
  },
  {
    "Gas Station Number": "0F085",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lindenstraße 33",
    "Postcode": 63538,
    "City": "Großkrotzenburg",
    "State": "Hessen",
    "Latitude": "050.0818660",
    "Longitude": "008.9862470",
    "Telephone Number": "06186318",
    "Week From": "05:30",
    "Week To": "23:00",
    "Saturdays From": "07:00",
    "Saturdays To": "23:00",
    "Sundays From": "09:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "50.081866,8.986247",
    "IsOpen24Hours": false,
    "id": "50.081866,8.986247"
  },
  {
    "Gas Station Number": "0F086",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ludwig-Landmann-Straße 90",
    "Postcode": 60488,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.1414470",
    "Longitude": "008.6098930",
    "Telephone Number": "069764309",
    "Week From": "06:00",
    "Week To": "23:59",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "06:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "50.141447,8.609893",
    "IsOpen24Hours": false,
    "id": "50.141447,8.609893"
  },
  {
    "Gas Station Number": "0F088",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kelkheimer Str 43",
    "Postcode": 65779,
    "City": "Kelkheim",
    "State": "Hessen",
    "Latitude": "050.1459940",
    "Longitude": "008.4295850",
    "Telephone Number": "0619561301",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.145994,8.429585",
    "IsOpen24Hours": false,
    "id": "50.145994,8.429585"
  },
  {
    "Gas Station Number": "0F089",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "B 448",
    "Postcode": 63110,
    "City": "Rodgau",
    "State": "Hessen",
    "Latitude": "050.0691980",
    "Longitude": "008.8778690",
    "Telephone Number": "061044657",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.069198,8.877869",
    "IsOpen24Hours": true,
    "id": "50.069198,8.877869"
  },
  {
    "Gas Station Number": "0F090",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ratsweg 12",
    "Postcode": 60386,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.1235240",
    "Longitude": "008.7233960",
    "Telephone Number": "069413968",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.123524,8.723396",
    "IsOpen24Hours": true,
    "id": "50.123524,8.723396"
  },
  {
    "Gas Station Number": "0F092",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Koblenzer Straße 81",
    "Postcode": 56130,
    "City": "Bad Ems",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.3363060",
    "Longitude": "007.7062840",
    "Telephone Number": "026032131",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.336306,7.706284",
    "IsOpen24Hours": false,
    "id": "50.336306,7.706284"
  },
  {
    "Gas Station Number": "0F093",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Koblenzer Straße 15",
    "Postcode": 56459,
    "City": "Langenhahn",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.5838390",
    "Longitude": "007.9182990",
    "Telephone Number": "02663969011",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.583839,7.918299",
    "IsOpen24Hours": false,
    "id": "50.583839,7.918299"
  },
  {
    "Gas Station Number": "0F094",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Autobahnzubringer/West",
    "Postcode": 56203,
    "City": "Höhr-Grenzhausen",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.4419190",
    "Longitude": "007.6664160",
    "Telephone Number": "026242696",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.441919,7.666416",
    "IsOpen24Hours": true,
    "id": "50.441919,7.666416"
  },
  {
    "Gas Station Number": "0F095",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Koblenzer Straße 25 a",
    "Postcode": 56410,
    "City": "Montabaur",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.4293460",
    "Longitude": "007.8229060",
    "Telephone Number": "02602970181",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.429346,7.822906",
    "IsOpen24Hours": false,
    "id": "50.429346,7.822906"
  },
  {
    "Gas Station Number": "0F097",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hunsrückhöhenstraße",
    "Postcode": 54497,
    "City": "Morbach",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.8169040",
    "Longitude": "007.1278800",
    "Telephone Number": "065333195",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.816904,7.12788",
    "IsOpen24Hours": false,
    "id": "49.816904,7.12788"
  },
  {
    "Gas Station Number": "0F098",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kurfürstenstraße 69",
    "Postcode": 54516,
    "City": "Wittlich",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.9822800",
    "Longitude": "006.9006370",
    "Telephone Number": "0657120841",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.98228,6.900637",
    "IsOpen24Hours": true,
    "id": "49.98228,6.900637"
  },
  {
    "Gas Station Number": "0F099",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ostallee 22",
    "Postcode": 54290,
    "City": "Trier",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.7547050",
    "Longitude": "006.6473960",
    "Telephone Number": "065174942",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.754705,6.647396",
    "IsOpen24Hours": true,
    "id": "49.754705,6.647396"
  },
  {
    "Gas Station Number": "0F101",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Griedeler Straße 70",
    "Postcode": 35510,
    "City": "Butzbach",
    "State": "Hessen",
    "Latitude": "050.4366100",
    "Longitude": "008.6812570",
    "Telephone Number": "06033970722",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.43661,8.681257",
    "IsOpen24Hours": true,
    "id": "50.43661,8.681257"
  },
  {
    "Gas Station Number": "0F102",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 9",
    "Postcode": 56477,
    "City": "Rennerod",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.6031630",
    "Longitude": "008.0640230",
    "Telephone Number": "02664999560",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.603163,8.064023",
    "IsOpen24Hours": false,
    "id": "50.603163,8.064023"
  },
  {
    "Gas Station Number": "0F104",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Oppenheimer Str. 7",
    "Postcode": 64521,
    "City": "Groß-Gerau",
    "State": "Hessen",
    "Latitude": "049.9216750",
    "Longitude": "008.4721260",
    "Telephone Number": "061527854",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.921675,8.472126",
    "IsOpen24Hours": true,
    "id": "49.921675,8.472126"
  },
  {
    "Gas Station Number": "0F106",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Biebricher Allee 186",
    "Postcode": 65203,
    "City": "Wiesbaden",
    "State": "Hessen",
    "Latitude": "050.0485350",
    "Longitude": "008.2412740",
    "Telephone Number": "0611694289",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.048535,8.241274",
    "IsOpen24Hours": false,
    "id": "50.048535,8.241274"
  },
  {
    "Gas Station Number": "0F107",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schlangenbader Straße 8",
    "Postcode": 65344,
    "City": "Eltville",
    "State": "Hessen",
    "Latitude": "050.0563600",
    "Longitude": "008.1192020",
    "Telephone Number": "0612371407",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.05636,8.119202",
    "IsOpen24Hours": true,
    "id": "50.05636,8.119202"
  },
  {
    "Gas Station Number": "0F110",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Frankfurter Straße",
    "Postcode": 63619,
    "City": "Bad Orb",
    "State": "Hessen",
    "Latitude": "050.2291550",
    "Longitude": "009.3438290",
    "Telephone Number": "06052900313",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.229155,9.343829",
    "IsOpen24Hours": false,
    "id": "50.229155,9.343829"
  },
  {
    "Gas Station Number": "0F108",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Taunus-Schnellweg-Nordsei",
    "Postcode": 61352,
    "City": "Bad Homburg",
    "State": "Hessen",
    "Latitude": "050.2087200",
    "Longitude": "008.6166690",
    "Telephone Number": "0617221266",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.20872,8.616669",
    "IsOpen24Hours": true,
    "id": "50.20872,8.616669"
  },
  {
    "Gas Station Number": "0F109",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Taunus-Schnellweg-Südseit",
    "Postcode": 61352,
    "City": "Bad Homburg",
    "State": "Hessen",
    "Latitude": "050.2084270",
    "Longitude": "008.6153390",
    "Telephone Number": "06172690835",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.208427,8.615339",
    "IsOpen24Hours": true,
    "id": "50.208427,8.615339"
  },
  {
    "Gas Station Number": "0F112",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Industriehof 11",
    "Postcode": 60487,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.1291130",
    "Longitude": "008.6240050",
    "Telephone Number": "069776451",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.129113,8.624005",
    "IsOpen24Hours": true,
    "id": "50.129113,8.624005"
  },
  {
    "Gas Station Number": "0F020",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Chauvignystraße 15",
    "Postcode": 65366,
    "City": "Geisenheim",
    "State": "Hessen",
    "Latitude": "049.9882610",
    "Longitude": "007.9778770",
    "Telephone Number": "06722995520",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.988261,7.977877",
    "IsOpen24Hours": false,
    "id": "49.988261,7.977877"
  },
  {
    "Gas Station Number": "0F113",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Isenburger Straße 20",
    "Postcode": 63150,
    "City": "Heusenstamm",
    "State": "Hessen",
    "Latitude": "050.0586980",
    "Longitude": "008.7889440",
    "Telephone Number": "061045123",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.058698,8.788944",
    "IsOpen24Hours": false,
    "id": "50.058698,8.788944"
  },
  {
    "Gas Station Number": "0F115",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Frankfurter Straße 55",
    "Postcode": 65549,
    "City": "Limburg",
    "State": "Hessen",
    "Latitude": "050.3837340",
    "Longitude": "008.0702570",
    "Telephone Number": "06431408687",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.383734,8.070257",
    "IsOpen24Hours": true,
    "id": "50.383734,8.070257"
  },
  {
    "Gas Station Number": "0F117",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedrich-Ebert-Straße54",
    "Postcode": 65428,
    "City": "Rüsselsheim",
    "State": "Hessen",
    "Latitude": "049.9868440",
    "Longitude": "008.4163740",
    "Telephone Number": "0614215334",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.986844,8.416374",
    "IsOpen24Hours": false,
    "id": "49.986844,8.416374"
  },
  {
    "Gas Station Number": "0F118",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Darmstädter Straße 43",
    "Postcode": 63303,
    "City": "Dreieich",
    "State": "Hessen",
    "Latitude": "050.0133040",
    "Longitude": "008.6923310",
    "Telephone Number": "0610365671",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.013304,8.692331",
    "IsOpen24Hours": false,
    "id": "50.013304,8.692331"
  },
  {
    "Gas Station Number": "0F119",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berliner Straße 68",
    "Postcode": 65189,
    "City": "Wiesbaden",
    "State": "Hessen",
    "Latitude": "050.0662370",
    "Longitude": "008.2690200",
    "Telephone Number": "0611701141",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.066237,8.26902",
    "IsOpen24Hours": true,
    "id": "50.066237,8.26902"
  },
  {
    "Gas Station Number": "0F120",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bierstadter Straße 51",
    "Postcode": 65189,
    "City": "Wiesbaden",
    "State": "Hessen",
    "Latitude": "050.0833420",
    "Longitude": "008.2623080",
    "Telephone Number": "0611565373",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.083342,8.262308",
    "IsOpen24Hours": true,
    "id": "50.083342,8.262308"
  },
  {
    "Gas Station Number": "0F121",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mainzer Straße 103",
    "Postcode": 65189,
    "City": "Wiesbaden",
    "State": "Hessen",
    "Latitude": "050.0621700",
    "Longitude": "008.2523170",
    "Telephone Number": "061174478",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.06217,8.252317",
    "IsOpen24Hours": true,
    "id": "50.06217,8.252317"
  },
  {
    "Gas Station Number": "0F137",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Andernacher Straße 207",
    "Postcode": 56070,
    "City": "Koblenz",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.3783680",
    "Longitude": "007.5849870",
    "Telephone Number": "026183720",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.378368,7.584987",
    "IsOpen24Hours": true,
    "id": "50.378368,7.584987"
  },
  {
    "Gas Station Number": "0F122",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hochheimer Straße 25",
    "Postcode": 55246,
    "City": "Mainz-Kostheim",
    "State": "Hessen",
    "Latitude": "050.0079510",
    "Longitude": "008.2920790",
    "Telephone Number": "0613463241",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.007951,8.292079",
    "IsOpen24Hours": true,
    "id": "50.007951,8.292079"
  },
  {
    "Gas Station Number": "0F123",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lyoner Straße 70",
    "Postcode": 60528,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.0781000",
    "Longitude": "008.6339340",
    "Telephone Number": "0696661885",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.0781,8.633934",
    "IsOpen24Hours": false,
    "id": "50.0781,8.633934"
  },
  {
    "Gas Station Number": "0F124",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Talstraße 12 / B414",
    "Postcode": 56472,
    "City": "Hof",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.6644410",
    "Longitude": "008.0157910",
    "Telephone Number": "0266164375",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.664441,8.015791",
    "IsOpen24Hours": false,
    "id": "50.664441,8.015791"
  },
  {
    "Gas Station Number": "0F125",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sebastianstraße 158",
    "Postcode": 53474,
    "City": "Bad Neuenahr-Ahrweil",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.5455230",
    "Longitude": "007.1121390",
    "Telephone Number": "0264135033",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.545523,7.112139",
    "IsOpen24Hours": false,
    "id": "50.545523,7.112139"
  },
  {
    "Gas Station Number": "0F126",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Trierer Straße 97",
    "Postcode": 54411,
    "City": "Hermeskeil",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.6577230",
    "Longitude": "006.9331540",
    "Telephone Number": "06503404",
    "Week From": "05:00",
    "Week To": "22:30",
    "Saturdays From": "06:00",
    "Saturdays To": "23:30",
    "Sundays From": "07:00",
    "Sundays To": "22:30",
    "24 hour?": "Nein",
    "location": "49.657723,6.933154",
    "IsOpen24Hours": false,
    "id": "49.657723,6.933154"
  },
  {
    "Gas Station Number": "0F127",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ruwerer Straße 35",
    "Postcode": 54292,
    "City": "Trier",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.7802840",
    "Longitude": "006.7024400",
    "Telephone Number": "065152397",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.780284,6.70244",
    "IsOpen24Hours": false,
    "id": "49.780284,6.70244"
  },
  {
    "Gas Station Number": "0F128",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Oberstiftstr 41 A",
    "Postcode": 54338,
    "City": "Schweich",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.8269630",
    "Longitude": "006.7539250",
    "Telephone Number": "065022225",
    "Week From": "07:00",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "10:00",
    "Sundays To": "18:00",
    "24 hour?": "Nein",
    "location": "49.826963,6.753925",
    "IsOpen24Hours": false,
    "id": "49.826963,6.753925"
  },
  {
    "Gas Station Number": "0F008",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Trierer Straße 1",
    "Postcode": 53518,
    "City": "Adenau",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.3779370",
    "Longitude": "006.9492870",
    "Telephone Number": "02691433",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.377937,6.949287",
    "IsOpen24Hours": false,
    "id": "50.377937,6.949287"
  },
  {
    "Gas Station Number": "0F130",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 221",
    "Postcode": 56575,
    "City": "Weißenthurm",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.4224020",
    "Longitude": "007.4454420",
    "Telephone Number": "026372107",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "05:00",
    "Saturdays To": "23:00",
    "Sundays From": "05:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "50.422402,7.445442",
    "IsOpen24Hours": false,
    "id": "50.422402,7.445442"
  },
  {
    "Gas Station Number": "0F131",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Brohltalstraße 71",
    "Postcode": 56651,
    "City": "Niederzissen",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.4601810",
    "Longitude": "007.2230010",
    "Telephone Number": "026366272",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.460181,7.223001",
    "IsOpen24Hours": false,
    "id": "50.460181,7.223001"
  },
  {
    "Gas Station Number": "0F132",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wiedtalstraße 24",
    "Postcode": 53577,
    "City": "Neustadt (Wied)",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.6208070",
    "Longitude": "007.4278440",
    "Telephone Number": "0268331350",
    "Week From": "08:00",
    "Week To": "20:00",
    "Saturdays From": "08:00",
    "Saturdays To": "19:00",
    "Sundays From": "10:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "50.620807,7.427844",
    "IsOpen24Hours": false,
    "id": "50.620807,7.427844"
  },
  {
    "Gas Station Number": "0F133",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Moselstraße / B 416",
    "Postcode": 56330,
    "City": "Kobern-Gondorf",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.3142550",
    "Longitude": "007.4623930",
    "Telephone Number": "026074065",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.314255,7.462393",
    "IsOpen24Hours": false,
    "id": "50.314255,7.462393"
  },
  {
    "Gas Station Number": "0F134",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kelberger Straße 68",
    "Postcode": 56727,
    "City": "Mayen",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.3252990",
    "Longitude": "007.2136250",
    "Telephone Number": "026513438",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "05:00",
    "Saturdays To": "21:00",
    "Sundays From": "05:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.325299,7.213625",
    "IsOpen24Hours": false,
    "id": "50.325299,7.213625"
  },
  {
    "Gas Station Number": "0F135",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berggärtenstraße",
    "Postcode": 56564,
    "City": "Neuwied",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.4430750",
    "Longitude": "007.4694690",
    "Telephone Number": "0263131991",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.443075,7.469469",
    "IsOpen24Hours": true,
    "id": "50.443075,7.469469"
  },
  {
    "Gas Station Number": "0F136",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A3",
    "Postcode": 53577,
    "City": "Neustadt",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.5999240",
    "Longitude": "007.4323480",
    "Telephone Number": "026833502",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.599924,7.432348",
    "IsOpen24Hours": true,
    "id": "50.599924,7.432348"
  },
  {
    "Gas Station Number": "0F139",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der Bundesstraße 521",
    "Postcode": 63674,
    "City": "Altenstadt",
    "State": "Hessen",
    "Latitude": "050.2848880",
    "Longitude": "008.9408600",
    "Telephone Number": "060479858950",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.284888,8.94086",
    "IsOpen24Hours": false,
    "id": "50.284888,8.94086"
  },
  {
    "Gas Station Number": "0F140",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedrich-Ebert-Anlage 7",
    "Postcode": 63450,
    "City": "Hanau",
    "State": "Hessen",
    "Latitude": "050.1296990",
    "Longitude": "008.9215110",
    "Telephone Number": "061819698409",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.129699,8.921511",
    "IsOpen24Hours": true,
    "id": "50.129699,8.921511"
  },
  {
    "Gas Station Number": "0F141",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rheinstraße 18",
    "Postcode": 55435,
    "City": "Gau-Algesheim",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.9648730",
    "Longitude": "008.0177130",
    "Telephone Number": "067252340",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.964873,8.017713",
    "IsOpen24Hours": false,
    "id": "49.964873,8.017713"
  },
  {
    "Gas Station Number": "0F142",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedberger Landstr. 300",
    "Postcode": 60389,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.1333840",
    "Longitude": "008.6960170",
    "Telephone Number": "0699551070",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.133384,8.696017",
    "IsOpen24Hours": true,
    "id": "50.133384,8.696017"
  },
  {
    "Gas Station Number": "0F144",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "A 66 Erbenheim-Süd",
    "Postcode": 65205,
    "City": "Wiesbaden",
    "State": "Hessen",
    "Latitude": "050.0542560",
    "Longitude": "008.3114880",
    "Telephone Number": "0611700710",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.054256,8.311488",
    "IsOpen24Hours": false,
    "id": "50.054256,8.311488"
  },
  {
    "Gas Station Number": "0F145",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A5",
    "Postcode": 35305,
    "City": "Grünberg",
    "State": "Hessen",
    "Latitude": "050.6236640",
    "Longitude": "008.8955330",
    "Telephone Number": "0640191370",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.623664,8.895533",
    "IsOpen24Hours": true,
    "id": "50.623664,8.895533"
  },
  {
    "Gas Station Number": "0F146",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A3",
    "Postcode": 63879,
    "City": "Weibersbrunn",
    "State": "Bayern",
    "Latitude": "049.8974500",
    "Longitude": "009.3925360",
    "Telephone Number": "060949889336",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.89745,9.392536",
    "IsOpen24Hours": true,
    "id": "49.89745,9.392536"
  },
  {
    "Gas Station Number": "0F148",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "BAB A 49 -Ostseite",
    "Postcode": 34295,
    "City": "Edermünde",
    "State": "Hessen",
    "Latitude": "051.2230410",
    "Longitude": "009.4201250",
    "Telephone Number": "056656454",
    "Week From": "04:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "08:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "51.223041,9.420125",
    "IsOpen24Hours": false,
    "id": "51.223041,9.420125"
  },
  {
    "Gas Station Number": "0F149",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Königstraße 32",
    "Postcode": 66538,
    "City": "Neunkirchen",
    "State": "Saarland",
    "Latitude": "049.3446070",
    "Longitude": "007.1742580",
    "Telephone Number": "06821140910",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.344607,7.174258",
    "IsOpen24Hours": true,
    "id": "49.344607,7.174258"
  },
  {
    "Gas Station Number": "0F150",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Bahnhofstraße 53",
    "Postcode": 55494,
    "City": "Rheinböllen",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.9961640",
    "Longitude": "007.6838200",
    "Telephone Number": "067643000",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.996164,7.68382",
    "IsOpen24Hours": true,
    "id": "49.996164,7.68382"
  },
  {
    "Gas Station Number": "0F151",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schiffenberger Weg 68",
    "Postcode": 35394,
    "City": "Gießen",
    "State": "Hessen",
    "Latitude": "050.5741590",
    "Longitude": "008.6849710",
    "Telephone Number": "064176428",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.574159,8.684971",
    "IsOpen24Hours": true,
    "id": "50.574159,8.684971"
  },
  {
    "Gas Station Number": "0F152",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Prälat-Caire-Straße 14",
    "Postcode": 67071,
    "City": "Ludwigshafen",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.4920610",
    "Longitude": "008.3833110",
    "Telephone Number": "0621680551",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.492061,8.383311",
    "IsOpen24Hours": true,
    "id": "49.492061,8.383311"
  },
  {
    "Gas Station Number": "0F019",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Haagstraße",
    "Postcode": 66679,
    "City": "Losheim am See",
    "State": "Saarland",
    "Latitude": "049.5113730",
    "Longitude": "006.7533740",
    "Telephone Number": "0687291044",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.511373,6.753374",
    "IsOpen24Hours": true,
    "id": "49.511373,6.753374"
  },
  {
    "Gas Station Number": "0F153",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Brunckstraße 100",
    "Postcode": 67063,
    "City": "Ludwigshafen",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.5038060",
    "Longitude": "008.4126000",
    "Telephone Number": "0621696456",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.503806,8.4126",
    "IsOpen24Hours": true,
    "id": "49.503806,8.4126"
  },
  {
    "Gas Station Number": "0F154",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sandstraße 126",
    "Postcode": 57072,
    "City": "Siegen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8802570",
    "Longitude": "008.0265440",
    "Telephone Number": "027124660",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "08:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.880257,8.026544",
    "IsOpen24Hours": false,
    "id": "50.880257,8.026544"
  },
  {
    "Gas Station Number": "0F155",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der B 27/B292",
    "Postcode": 74821,
    "City": "Mosbach",
    "State": "Baden-Württemberg",
    "Latitude": "049.3377320",
    "Longitude": "009.1166380",
    "Telephone Number": "0626191199",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.337732,9.116638",
    "IsOpen24Hours": true,
    "id": "49.337732,9.116638"
  },
  {
    "Gas Station Number": "0F156",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Straßburger Straße 34a",
    "Postcode": 77694,
    "City": "Kehl",
    "State": "Baden-Württemberg",
    "Latitude": "048.5755460",
    "Longitude": "007.8174700",
    "Telephone Number": "07851958877",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.575546,7.81747",
    "IsOpen24Hours": true,
    "id": "48.575546,7.81747"
  },
  {
    "Gas Station Number": "0F157",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Industriestraße 5 a",
    "Postcode": 67346,
    "City": "Speyer",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.3097310",
    "Longitude": "008.4445350",
    "Telephone Number": "0623261143",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.309731,8.444535",
    "IsOpen24Hours": true,
    "id": "49.309731,8.444535"
  },
  {
    "Gas Station Number": "0F158",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mainzer Straße 117",
    "Postcode": 67657,
    "City": "Kaiserslautern",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.4557550",
    "Longitude": "007.7958790",
    "Telephone Number": "063143688",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.455755,7.795879",
    "IsOpen24Hours": true,
    "id": "49.455755,7.795879"
  },
  {
    "Gas Station Number": "0FE97",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Linkenheimer Landstr. 125",
    "Postcode": 76149,
    "City": "Karlsruhe",
    "State": "Baden-Württemberg",
    "Latitude": "049.0381180",
    "Longitude": "008.3935980",
    "Telephone Number": "07214539429",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.038118,8.393598",
    "IsOpen24Hours": true,
    "id": "49.038118,8.393598"
  },
  {
    "Gas Station Number": "0F159",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Mainzer Straße 140",
    "Postcode": 67547,
    "City": "Worms",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.6547370",
    "Longitude": "008.3593950",
    "Telephone Number": "06241490921",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.654737,8.359395",
    "IsOpen24Hours": true,
    "id": "49.654737,8.359395"
  },
  {
    "Gas Station Number": "0F160",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Saarstraße 58 a",
    "Postcode": 54634,
    "City": "Bitburg",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.9559210",
    "Longitude": "006.5251960",
    "Telephone Number": "06561940247",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.955921,6.525196",
    "IsOpen24Hours": true,
    "id": "49.955921,6.525196"
  },
  {
    "Gas Station Number": "0F161",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neumühler Weg 40",
    "Postcode": 66130,
    "City": "Saarbrücken",
    "State": "Saarland",
    "Latitude": "049.2073920",
    "Longitude": "007.0214820",
    "Telephone Number": "0681875544",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.207392,7.021482",
    "IsOpen24Hours": true,
    "id": "49.207392,7.021482"
  },
  {
    "Gas Station Number": "0F162",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 72",
    "Postcode": 57334,
    "City": "Bad Laasphe",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9309530",
    "Longitude": "008.4289700",
    "Telephone Number": "02752200416",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.930953,8.42897",
    "IsOpen24Hours": false,
    "id": "50.930953,8.42897"
  },
  {
    "Gas Station Number": "0F163",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Petersberger Straße 101",
    "Postcode": 36100,
    "City": "Petersberg",
    "State": "Hessen",
    "Latitude": "050.5525480",
    "Longitude": "009.7019440",
    "Telephone Number": "066165535",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.552548,9.701944",
    "IsOpen24Hours": false,
    "id": "50.552548,9.701944"
  },
  {
    "Gas Station Number": "0F164",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rommelsbacher Straße 140",
    "Postcode": 72760,
    "City": "Reutlingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.5117140",
    "Longitude": "009.2042240",
    "Telephone Number": "07121380665",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.511714,9.204224",
    "IsOpen24Hours": true,
    "id": "48.511714,9.204224"
  },
  {
    "Gas Station Number": "0F165",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bundesstraße 3",
    "Postcode": 77749,
    "City": "Hohberg",
    "State": "Baden-Württemberg",
    "Latitude": "048.4152450",
    "Longitude": "007.8869770",
    "Telephone Number": "07808911057",
    "Week From": "06:00",
    "Week To": "23:59",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "06:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "48.415245,7.886977",
    "IsOpen24Hours": false,
    "id": "48.415245,7.886977"
  },
  {
    "Gas Station Number": "0F166",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Calmbacher Straße 53",
    "Postcode": 75323,
    "City": "Bad Wildbad",
    "State": "Baden-Württemberg",
    "Latitude": "048.7588470",
    "Longitude": "008.5513760",
    "Telephone Number": "07081380973",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.758847,8.551376",
    "IsOpen24Hours": false,
    "id": "48.758847,8.551376"
  },
  {
    "Gas Station Number": "0F167",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alter Postweg 1",
    "Postcode": 74564,
    "City": "Crailsheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.1386810",
    "Longitude": "010.0627690",
    "Telephone Number": "07951294260",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.138681,10.062769",
    "IsOpen24Hours": true,
    "id": "49.138681,10.062769"
  },
  {
    "Gas Station Number": "0F168",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Saarbrücker Straße 175",
    "Postcode": 66557,
    "City": "Illingen-Uchtelfangen",
    "State": "Saarland",
    "Latitude": "049.3832640",
    "Longitude": "007.0115430",
    "Telephone Number": "0682543054",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.383264,7.011543",
    "IsOpen24Hours": false,
    "id": "49.383264,7.011543"
  },
  {
    "Gas Station Number": "0F169",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lorscher Straße 53",
    "Postcode": 64646,
    "City": "Heppenheim",
    "State": "Hessen",
    "Latitude": "049.6439890",
    "Longitude": "008.6255360",
    "Telephone Number": "062527969160",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.643989,8.625536",
    "IsOpen24Hours": true,
    "id": "49.643989,8.625536"
  },
  {
    "Gas Station Number": "0F170",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Amperestraße 1",
    "Postcode": 64625,
    "City": "Bensheim",
    "State": "Hessen",
    "Latitude": "049.6729100",
    "Longitude": "008.5976380",
    "Telephone Number": "06251581734",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.67291,8.597638",
    "IsOpen24Hours": true,
    "id": "49.67291,8.597638"
  },
  {
    "Gas Station Number": "0F171",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schönbornstraße 11",
    "Postcode": 63741,
    "City": "Aschaffenburg",
    "State": "Bayern",
    "Latitude": "049.9870990",
    "Longitude": "009.1601520",
    "Telephone Number": "06021410332",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.987099,9.160152",
    "IsOpen24Hours": true,
    "id": "49.987099,9.160152"
  },
  {
    "Gas Station Number": "0F173",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mainzer Straße 95",
    "Postcode": 65428,
    "City": "Rüsselsheim",
    "State": "Hessen",
    "Latitude": "049.9931630",
    "Longitude": "008.3871300",
    "Telephone Number": "06142910915",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.993163,8.38713",
    "IsOpen24Hours": true,
    "id": "49.993163,8.38713"
  },
  {
    "Gas Station Number": "0F172",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Talgraben 1",
    "Postcode": 74199,
    "City": "Untergruppenbach",
    "State": "Baden-Württemberg",
    "Latitude": "049.0864240",
    "Longitude": "009.2751760",
    "Telephone Number": "0713170810",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.086424,9.275176",
    "IsOpen24Hours": true,
    "id": "49.086424,9.275176"
  },
  {
    "Gas Station Number": "0F174",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Elisabeth-Selbert-Straße",
    "Postcode": 63128,
    "City": "Dietzenbach",
    "State": "Hessen",
    "Latitude": "050.0134300",
    "Longitude": "008.7941100",
    "Telephone Number": "060748511300",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.01343,8.79411",
    "IsOpen24Hours": true,
    "id": "50.01343,8.79411"
  },
  {
    "Gas Station Number": "0F175",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Konrad-Adenauer-Straße 14",
    "Postcode": 55218,
    "City": "Ingelheim",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.9790700",
    "Longitude": "008.0540200",
    "Telephone Number": "06132431937",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "05:00",
    "Saturdays To": "23:00",
    "Sundays From": "05:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "49.97907,8.05402",
    "IsOpen24Hours": false,
    "id": "49.97907,8.05402"
  },
  {
    "Gas Station Number": "0F176",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Matthias-Öchsler- Str. 2",
    "Postcode": 91522,
    "City": "Ansbach",
    "State": "Bayern",
    "Latitude": "049.2990540",
    "Longitude": "010.5861780",
    "Telephone Number": "09819775633",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.299054,10.586178",
    "IsOpen24Hours": true,
    "id": "49.299054,10.586178"
  },
  {
    "Gas Station Number": "0F177",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schwabenheimer Weg 135",
    "Postcode": 55543,
    "City": "Bad Kreuznach",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.8518060",
    "Longitude": "007.8927670",
    "Telephone Number": "06718965105",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.851806,7.892767",
    "IsOpen24Hours": true,
    "id": "49.851806,7.892767"
  },
  {
    "Gas Station Number": "0F178",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Trierer Straße 44-46",
    "Postcode": 66265,
    "City": "Heusweiler",
    "State": "Saarland",
    "Latitude": "049.3412240",
    "Longitude": "006.9336070",
    "Telephone Number": "06806609512",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.341224,6.933607",
    "IsOpen24Hours": false,
    "id": "49.341224,6.933607"
  },
  {
    "Gas Station Number": "0F179",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rheinallee 207",
    "Postcode": 55120,
    "City": "Mainz",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.0271540",
    "Longitude": "008.2229960",
    "Telephone Number": "06131687011",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.027154,8.222996",
    "IsOpen24Hours": true,
    "id": "50.027154,8.222996"
  },
  {
    "Gas Station Number": "0F180",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Mainfrankenpark 24",
    "Postcode": 97337,
    "City": "Dettelbach",
    "State": "Bayern",
    "Latitude": "049.7784830",
    "Longitude": "010.0662750",
    "Telephone Number": "09302932100",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.778483,10.066275",
    "IsOpen24Hours": true,
    "id": "49.778483,10.066275"
  },
  {
    "Gas Station Number": "0FG15",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mammolshainer Weg",
    "Postcode": 61462,
    "City": "Königstein",
    "State": "Hessen",
    "Latitude": "050.1792420",
    "Longitude": "008.4759640",
    "Telephone Number": "06174209242",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.179242,8.475964",
    "IsOpen24Hours": false,
    "id": "50.179242,8.475964"
  },
  {
    "Gas Station Number": "0F182",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Straßburgstraße 1",
    "Postcode": 97424,
    "City": "Schweinfurt",
    "State": "Bayern",
    "Latitude": "050.0216550",
    "Longitude": "010.2166180",
    "Telephone Number": "09721608526",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.021655,10.216618",
    "IsOpen24Hours": true,
    "id": "50.021655,10.216618"
  },
  {
    "Gas Station Number": "0F183",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mannheimer Straße 1",
    "Postcode": 68766,
    "City": "Hockenheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.3304580",
    "Longitude": "008.5360520",
    "Telephone Number": "06205289123",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.330458,8.536052",
    "IsOpen24Hours": true,
    "id": "49.330458,8.536052"
  },
  {
    "Gas Station Number": "0FE98",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hockenheimer Landstraße 4",
    "Postcode": 68723,
    "City": "Schwetzingen",
    "State": "Baden-Württemberg",
    "Latitude": "049.3771070",
    "Longitude": "008.5606630",
    "Telephone Number": "06202128091",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.377107,8.560663",
    "IsOpen24Hours": true,
    "id": "49.377107,8.560663"
  },
  {
    "Gas Station Number": "0F191",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Fackenburger Allee 20",
    "Postcode": 23554,
    "City": "Lübeck",
    "State": "Schleswig-Holstein",
    "Latitude": "053.8704600",
    "Longitude": "010.6700160",
    "Telephone Number": "045142183",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.87046,10.670016",
    "IsOpen24Hours": true,
    "id": "53.87046,10.670016"
  },
  {
    "Gas Station Number": "0F192",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lindenstraße 24",
    "Postcode": 25524,
    "City": "Itzehoe",
    "State": "Schleswig-Holstein",
    "Latitude": "053.9291920",
    "Longitude": "009.5099990",
    "Telephone Number": "048213114",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.929192,9.509999",
    "IsOpen24Hours": true,
    "id": "53.929192,9.509999"
  },
  {
    "Gas Station Number": "0FG27",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Maintaler Straße 20",
    "Postcode": 63452,
    "City": "Hanau",
    "State": "Hessen",
    "Latitude": "050.1475830",
    "Longitude": "008.9012710",
    "Telephone Number": "061819839891",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.147583,8.901271",
    "IsOpen24Hours": true,
    "id": "50.147583,8.901271"
  },
  {
    "Gas Station Number": "0FE87",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Raun 112",
    "Postcode": 63667,
    "City": "Nidda",
    "State": "Hessen",
    "Latitude": "050.4085800",
    "Longitude": "009.0132240",
    "Telephone Number": "060432456",
    "Week From": "04:30",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.40858,9.013224",
    "IsOpen24Hours": false,
    "id": "50.40858,9.013224"
  },
  {
    "Gas Station Number": "0FE88",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Seestraße 34",
    "Postcode": 61200,
    "City": "Wölfersheim",
    "State": "Hessen",
    "Latitude": "050.4027270",
    "Longitude": "008.8200520",
    "Telephone Number": "060365204",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.402727,8.820052",
    "IsOpen24Hours": true,
    "id": "50.402727,8.820052"
  },
  {
    "Gas Station Number": "0FE89",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedberger Straße 89",
    "Postcode": 61118,
    "City": "Bad Vilbel",
    "State": "Hessen",
    "Latitude": "050.1902240",
    "Longitude": "008.7423650",
    "Telephone Number": "06101307498",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.190224,8.742365",
    "IsOpen24Hours": true,
    "id": "50.190224,8.742365"
  },
  {
    "Gas Station Number": "0FE90",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kleine Brückenstraße 34",
    "Postcode": 61352,
    "City": "Bad Homburg",
    "State": "Hessen",
    "Latitude": "050.2253090",
    "Longitude": "008.6773700",
    "Telephone Number": "06172185476",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.225309,8.67737",
    "IsOpen24Hours": false,
    "id": "50.225309,8.67737"
  },
  {
    "Gas Station Number": "0FG41",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A5",
    "Postcode": 64625,
    "City": "Bensheim",
    "State": "Hessen",
    "Latitude": "049.6892800",
    "Longitude": "008.6033410",
    "Telephone Number": "0625165100",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.68928,8.603341",
    "IsOpen24Hours": true,
    "id": "49.68928,8.603341"
  },
  {
    "Gas Station Number": "0F184",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kieler Straße 285",
    "Postcode": 22525,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5838110",
    "Longitude": "009.9331570",
    "Telephone Number": "040545127",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.583811,9.933157",
    "IsOpen24Hours": true,
    "id": "53.583811,9.933157"
  },
  {
    "Gas Station Number": "0F185",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rendsburger Straße 48-52",
    "Postcode": 24534,
    "City": "Neumünster",
    "State": "Schleswig-Holstein",
    "Latitude": "054.0821410",
    "Longitude": "009.9794840",
    "Telephone Number": "0432112622",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.082141,9.979484",
    "IsOpen24Hours": true,
    "id": "54.082141,9.979484"
  },
  {
    "Gas Station Number": "0F186",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stresemannstraße 329",
    "Postcode": 22761,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5638140",
    "Longitude": "009.9255840",
    "Telephone Number": "040857265",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.563814,9.925584",
    "IsOpen24Hours": true,
    "id": "53.563814,9.925584"
  },
  {
    "Gas Station Number": "0F187",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rissener Straße 89",
    "Postcode": 22880,
    "City": "Wedel",
    "State": "Schleswig-Holstein",
    "Latitude": "053.5836410",
    "Longitude": "009.7205150",
    "Telephone Number": "041032519",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.583641,9.720515",
    "IsOpen24Hours": true,
    "id": "53.583641,9.720515"
  },
  {
    "Gas Station Number": "0F188",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Pinneberger Straße 1-3",
    "Postcode": 22457,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.6325370",
    "Longitude": "009.9106570",
    "Telephone Number": "0405502787",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.632537,9.910657",
    "IsOpen24Hours": true,
    "id": "53.632537,9.910657"
  },
  {
    "Gas Station Number": "0F189",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Fockbeker Chaussee 4-6",
    "Postcode": 24768,
    "City": "Rendsburg",
    "State": "Schleswig-Holstein",
    "Latitude": "054.3079290",
    "Longitude": "009.6480260",
    "Telephone Number": "0433171246",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.307929,9.648026",
    "IsOpen24Hours": true,
    "id": "54.307929,9.648026"
  },
  {
    "Gas Station Number": "0F190",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gneversdorfer Weg 12-14",
    "Postcode": 23570,
    "City": "Lübeck",
    "State": "Schleswig-Holstein",
    "Latitude": "053.9593330",
    "Longitude": "010.8614920",
    "Telephone Number": "045025175",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.959333,10.861492",
    "IsOpen24Hours": true,
    "id": "53.959333,10.861492"
  },
  {
    "Gas Station Number": "0F193",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Peutestraße 2",
    "Postcode": 20539,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5276350",
    "Longitude": "010.0246570",
    "Telephone Number": "0407899840",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.527635,10.024657",
    "IsOpen24Hours": true,
    "id": "53.527635,10.024657"
  },
  {
    "Gas Station Number": "0F194",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bundesstraße 1",
    "Postcode": 24376,
    "City": "Kappeln",
    "State": "Schleswig-Holstein",
    "Latitude": "054.6592500",
    "Longitude": "009.9253320",
    "Telephone Number": "04642924788",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "54.65925,9.925332",
    "IsOpen24Hours": false,
    "id": "54.65925,9.925332"
  },
  {
    "Gas Station Number": "0F195",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Manhagener Allee 47-49",
    "Postcode": 22926,
    "City": "Ahrensburg",
    "State": "Schleswig-Holstein",
    "Latitude": "053.6695360",
    "Longitude": "010.2439550",
    "Telephone Number": "0410252343",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.669536,10.243955",
    "IsOpen24Hours": false,
    "id": "53.669536,10.243955"
  },
  {
    "Gas Station Number": "0F196",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hermann-Körner-Straße 51",
    "Postcode": 21465,
    "City": "Reinbek",
    "State": "Schleswig-Holstein",
    "Latitude": "053.5222280",
    "Longitude": "010.2445150",
    "Telephone Number": "0407279460",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "53.522228,10.244515",
    "IsOpen24Hours": false,
    "id": "53.522228,10.244515"
  },
  {
    "Gas Station Number": "0F197",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nordstraße 20",
    "Postcode": 24943,
    "City": "Flensburg",
    "State": "Schleswig-Holstein",
    "Latitude": "054.7970870",
    "Longitude": "009.4762140",
    "Telephone Number": "04616740568",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.797087,9.476214",
    "IsOpen24Hours": true,
    "id": "54.797087,9.476214"
  },
  {
    "Gas Station Number": "0F199",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bgm.-Steenbock-Str. 39",
    "Postcode": 23701,
    "City": "Eutin",
    "State": "Schleswig-Holstein",
    "Latitude": "054.1288610",
    "Longitude": "010.6248680",
    "Telephone Number": "045217908656",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "54.128861,10.624868",
    "IsOpen24Hours": false,
    "id": "54.128861,10.624868"
  },
  {
    "Gas Station Number": "0F200",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Eiffestraße 506",
    "Postcode": 20537,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5515890",
    "Longitude": "010.0514300",
    "Telephone Number": "040213820",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.551589,10.05143",
    "IsOpen24Hours": true,
    "id": "53.551589,10.05143"
  },
  {
    "Gas Station Number": "0F201",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Osterfeldstraße 30",
    "Postcode": 22529,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5983940",
    "Longitude": "009.9744700",
    "Telephone Number": "0405531672",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.598394,9.97447",
    "IsOpen24Hours": true,
    "id": "53.598394,9.97447"
  },
  {
    "Gas Station Number": "0F203",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nordschleswiger Straße 91",
    "Postcode": 22049,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5798510",
    "Longitude": "010.0698370",
    "Telephone Number": "040687042",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.579851,10.069837",
    "IsOpen24Hours": false,
    "id": "53.579851,10.069837"
  },
  {
    "Gas Station Number": "0F204",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Winterhuder Weg 25",
    "Postcode": 22085,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5735750",
    "Longitude": "010.0225810",
    "Telephone Number": "0402207516",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.573575,10.022581",
    "IsOpen24Hours": true,
    "id": "53.573575,10.022581"
  },
  {
    "Gas Station Number": "0F205",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kastanienallee 17-19",
    "Postcode": 21255,
    "City": "Tostedt",
    "State": "Niedersachsen",
    "Latitude": "053.2821340",
    "Longitude": "009.7140030",
    "Telephone Number": "04182293396",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.282134,9.714003",
    "IsOpen24Hours": true,
    "id": "53.282134,9.714003"
  },
  {
    "Gas Station Number": "0F206",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bargteheider Straße 100",
    "Postcode": 22143,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.6044690",
    "Longitude": "010.1478260",
    "Telephone Number": "0406773554",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.604469,10.147826",
    "IsOpen24Hours": true,
    "id": "53.604469,10.147826"
  },
  {
    "Gas Station Number": "0F207",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hamburger Straße 110",
    "Postcode": 23843,
    "City": "Bad Oldesloe",
    "State": "Schleswig-Holstein",
    "Latitude": "053.8030710",
    "Longitude": "010.3590540",
    "Telephone Number": "0453182243",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.803071,10.359054",
    "IsOpen24Hours": false,
    "id": "53.803071,10.359054"
  },
  {
    "Gas Station Number": "0F208",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schönberger Landstraße135",
    "Postcode": 24232,
    "City": "Schönkirchen",
    "State": "Schleswig-Holstein",
    "Latitude": "054.3389120",
    "Longitude": "010.2219490",
    "Telephone Number": "04348446",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "54.338912,10.221949",
    "IsOpen24Hours": false,
    "id": "54.338912,10.221949"
  },
  {
    "Gas Station Number": "0F209",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sievekingsallee/Saling 30",
    "Postcode": 20535,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5613930",
    "Longitude": "010.0484900",
    "Telephone Number": "0402502222",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.561393,10.04849",
    "IsOpen24Hours": true,
    "id": "53.561393,10.04849"
  },
  {
    "Gas Station Number": "0F210",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "PoppenbüttelerWeg 126",
    "Postcode": 22399,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.6497320",
    "Longitude": "010.0644190",
    "Telephone Number": "0405385227",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.649732,10.064419",
    "IsOpen24Hours": true,
    "id": "53.649732,10.064419"
  },
  {
    "Gas Station Number": "0F211",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Segeberger Straße 42",
    "Postcode": 23866,
    "City": "Nahe",
    "State": "Schleswig-Holstein",
    "Latitude": "053.7957330",
    "Longitude": "010.1414600",
    "Telephone Number": "04535407",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.795733,10.14146",
    "IsOpen24Hours": false,
    "id": "53.795733,10.14146"
  },
  {
    "Gas Station Number": "0F213",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Spaldingstraße 107",
    "Postcode": 20097,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5504290",
    "Longitude": "010.0199640",
    "Telephone Number": "040234524",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.550429,10.019964",
    "IsOpen24Hours": true,
    "id": "53.550429,10.019964"
  },
  {
    "Gas Station Number": "0F214",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Jesteburger Straße 12",
    "Postcode": 21218,
    "City": "Seevetal",
    "State": "Niedersachsen",
    "Latitude": "053.3824500",
    "Longitude": "009.9793460",
    "Telephone Number": "041052804",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.38245,9.979346",
    "IsOpen24Hours": true,
    "id": "53.38245,9.979346"
  },
  {
    "Gas Station Number": "0F217",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Holsteiner Chaussee 55",
    "Postcode": 22523,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.6147120",
    "Longitude": "009.9060610",
    "Telephone Number": "0405706132",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.614712,9.906061",
    "IsOpen24Hours": true,
    "id": "53.614712,9.906061"
  },
  {
    "Gas Station Number": "0F218",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedensallee 92",
    "Postcode": 25335,
    "City": "Elmshorn",
    "State": "Schleswig-Holstein",
    "Latitude": "053.7638490",
    "Longitude": "009.6627110",
    "Telephone Number": "0412182611",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.763849,9.662711",
    "IsOpen24Hours": false,
    "id": "53.763849,9.662711"
  },
  {
    "Gas Station Number": "0F219",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Apensener Straße 20",
    "Postcode": 21614,
    "City": "Buxtehude",
    "State": "Niedersachsen",
    "Latitude": "053.4664800",
    "Longitude": "009.6766100",
    "Telephone Number": "0416184850",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.46648,9.67661",
    "IsOpen24Hours": false,
    "id": "53.46648,9.67661"
  },
  {
    "Gas Station Number": "0F220",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ohechaussee 146",
    "Postcode": 22848,
    "City": "Norderstedt",
    "State": "Schleswig-Holstein",
    "Latitude": "053.6719940",
    "Longitude": "009.9833600",
    "Telephone Number": "0405234266",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.671994,9.98336",
    "IsOpen24Hours": true,
    "id": "53.671994,9.98336"
  },
  {
    "Gas Station Number": "0F221",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der E 47 -Landseite-",
    "Postcode": 23775,
    "City": "Großenbrode",
    "State": "Schleswig-Holstein",
    "Latitude": "054.3832940",
    "Longitude": "011.0932690",
    "Telephone Number": "04367206",
    "Week From": "06:00",
    "Week To": "17:00",
    "Saturdays From": "09:00",
    "Saturdays To": "19:00",
    "Sundays From": "09:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "54.383294,11.093269",
    "IsOpen24Hours": false,
    "id": "54.383294,11.093269"
  },
  {
    "Gas Station Number": "0F222",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Meldorfer Straße 24",
    "Postcode": 25709,
    "City": "Marne",
    "State": "Schleswig-Holstein",
    "Latitude": "053.9599750",
    "Longitude": "009.0076510",
    "Telephone Number": "04851606",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.959975,9.007651",
    "IsOpen24Hours": false,
    "id": "53.959975,9.007651"
  },
  {
    "Gas Station Number": "0F223",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Grodener Chaussee 52",
    "Postcode": 27472,
    "City": "Cuxhaven",
    "State": "Niedersachsen",
    "Latitude": "053.8530610",
    "Longitude": "008.7112410",
    "Telephone Number": "0472123442",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.853061,8.711241",
    "IsOpen24Hours": true,
    "id": "53.853061,8.711241"
  },
  {
    "Gas Station Number": "0F224",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Flensburger Chaussee 44",
    "Postcode": 25813,
    "City": "Husum",
    "State": "Schleswig-Holstein",
    "Latitude": "054.4876440",
    "Longitude": "009.0772710",
    "Telephone Number": "0484172690",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.487644,9.077271",
    "IsOpen24Hours": true,
    "id": "54.487644,9.077271"
  },
  {
    "Gas Station Number": "0F225",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stadtstraße 31",
    "Postcode": 25348,
    "City": "Glückstadt",
    "State": "Schleswig-Holstein",
    "Latitude": "053.7809170",
    "Longitude": "009.4304470",
    "Telephone Number": "041242725",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.780917,9.430447",
    "IsOpen24Hours": true,
    "id": "53.780917,9.430447"
  },
  {
    "Gas Station Number": "0F226",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 4",
    "Postcode": 22885,
    "City": "Barsbüttel",
    "State": "Schleswig-Holstein",
    "Latitude": "053.5693880",
    "Longitude": "010.1525570",
    "Telephone Number": "0406700104",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.569388,10.152557",
    "IsOpen24Hours": false,
    "id": "53.569388,10.152557"
  },
  {
    "Gas Station Number": "0F227",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kollaustraße 200",
    "Postcode": 22453,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.6155520",
    "Longitude": "009.9518080",
    "Telephone Number": "040585456",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.615552,9.951808",
    "IsOpen24Hours": true,
    "id": "53.615552,9.951808"
  },
  {
    "Gas Station Number": "0F228",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bremer Straße 300",
    "Postcode": 21077,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.4385960",
    "Longitude": "009.9361190",
    "Telephone Number": "0407604241",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.438596,9.936119",
    "IsOpen24Hours": true,
    "id": "53.438596,9.936119"
  },
  {
    "Gas Station Number": "0F229",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mengestraße 14",
    "Postcode": 21107,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5002840",
    "Longitude": "009.9941580",
    "Telephone Number": "0407534767",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.500284,9.994158",
    "IsOpen24Hours": true,
    "id": "53.500284,9.994158"
  },
  {
    "Gas Station Number": "0F230",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schleswiger Damm 173",
    "Postcode": 22457,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.6415630",
    "Longitude": "009.9168750",
    "Telephone Number": "0405509471",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.641563,9.916875",
    "IsOpen24Hours": true,
    "id": "53.641563,9.916875"
  },
  {
    "Gas Station Number": "0F009",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ostfrieslandstraße 80",
    "Postcode": 21129,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5265800",
    "Longitude": "009.8848680",
    "Telephone Number": "0407428605",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.52658,9.884868",
    "IsOpen24Hours": true,
    "id": "53.52658,9.884868"
  },
  {
    "Gas Station Number": "0F231",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hans-Böckler-Straße 35",
    "Postcode": 27578,
    "City": "Bremerhaven",
    "State": "Bremen",
    "Latitude": "053.5877040",
    "Longitude": "008.6126000",
    "Telephone Number": "047160300",
    "Week From": "05:00",
    "Week To": "22:30",
    "Saturdays From": "07:00",
    "Saturdays To": "22:30",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.587704,8.6126",
    "IsOpen24Hours": false,
    "id": "53.587704,8.6126"
  },
  {
    "Gas Station Number": "0F232",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stresemannstraße 118",
    "Postcode": 27576,
    "City": "Bremerhaven",
    "State": "Bremen",
    "Latitude": "053.5552020",
    "Longitude": "008.5936560",
    "Telephone Number": "047153080",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.555202,8.593656",
    "IsOpen24Hours": true,
    "id": "53.555202,8.593656"
  },
  {
    "Gas Station Number": "0F233",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bremer Landstraße4",
    "Postcode": 27412,
    "City": "Tarmstedt",
    "State": "Niedersachsen",
    "Latitude": "053.2232330",
    "Longitude": "009.0767150",
    "Telephone Number": "0428389110",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "53.223233,9.076715",
    "IsOpen24Hours": false,
    "id": "53.223233,9.076715"
  },
  {
    "Gas Station Number": "0F234",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nienburger Straße 1",
    "Postcode": 27283,
    "City": "Verden",
    "State": "Niedersachsen",
    "Latitude": "052.9180790",
    "Longitude": "009.2198630",
    "Telephone Number": "042312747",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.918079,9.219863",
    "IsOpen24Hours": true,
    "id": "52.918079,9.219863"
  },
  {
    "Gas Station Number": "0F235",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ziegelstraße 46",
    "Postcode": 23795,
    "City": "Bad Segeberg",
    "State": "Schleswig-Holstein",
    "Latitude": "053.9420050",
    "Longitude": "010.3000400",
    "Telephone Number": "045512920",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.942005,10.30004",
    "IsOpen24Hours": true,
    "id": "53.942005,10.30004"
  },
  {
    "Gas Station Number": "0F236",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Theisbrügger Straße 1",
    "Postcode": 21706,
    "City": "Drochtersen",
    "State": "Niedersachsen",
    "Latitude": "053.7153520",
    "Longitude": "009.3727770",
    "Telephone Number": "041437463",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "53.715352,9.372777",
    "IsOpen24Hours": false,
    "id": "53.715352,9.372777"
  },
  {
    "Gas Station Number": "0F239",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Elmshorner Straße 36",
    "Postcode": 25524,
    "City": "Itzehoe",
    "State": "Schleswig-Holstein",
    "Latitude": "053.9058090",
    "Longitude": "009.5079330",
    "Telephone Number": "0482182469",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.905809,9.507933",
    "IsOpen24Hours": true,
    "id": "53.905809,9.507933"
  },
  {
    "Gas Station Number": "0F237",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Langenhorner Chaussee 53-",
    "Postcode": 22335,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.6387530",
    "Longitude": "010.0133720",
    "Telephone Number": "0405315155",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.638753,10.013372",
    "IsOpen24Hours": true,
    "id": "53.638753,10.013372"
  },
  {
    "Gas Station Number": "0F238",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ulzburger Straße 440",
    "Postcode": 22844,
    "City": "Norderstedt",
    "State": "Schleswig-Holstein",
    "Latitude": "053.7276330",
    "Longitude": "009.9977790",
    "Telephone Number": "0405222155",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.727633,9.997779",
    "IsOpen24Hours": true,
    "id": "53.727633,9.997779"
  },
  {
    "Gas Station Number": "0F241",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hamburger Straße 30",
    "Postcode": 21481,
    "City": "Lauenburg",
    "State": "Schleswig-Holstein",
    "Latitude": "053.3741860",
    "Longitude": "010.5503940",
    "Telephone Number": "041533120",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "53.374186,10.550394",
    "IsOpen24Hours": false,
    "id": "53.374186,10.550394"
  },
  {
    "Gas Station Number": "0F242",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ratzeburger Allee 116",
    "Postcode": 23562,
    "City": "Lübeck",
    "State": "Schleswig-Holstein",
    "Latitude": "053.8412190",
    "Longitude": "010.7079040",
    "Telephone Number": "0451503133",
    "Week From": "04:30",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.841219,10.707904",
    "IsOpen24Hours": false,
    "id": "53.841219,10.707904"
  },
  {
    "Gas Station Number": "0F243",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wasserkrüger Weg 131",
    "Postcode": 23879,
    "City": "Mölln",
    "State": "Schleswig-Holstein",
    "Latitude": "053.6149670",
    "Longitude": "010.6911390",
    "Telephone Number": "045423656",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.614967,10.691139",
    "IsOpen24Hours": true,
    "id": "53.614967,10.691139"
  },
  {
    "Gas Station Number": "0F244",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hamburger Landstraße 103",
    "Postcode": 24113,
    "City": "Molfsee",
    "State": "Schleswig-Holstein",
    "Latitude": "054.2725920",
    "Longitude": "010.0749240",
    "Telephone Number": "0431651536",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.272592,10.074924",
    "IsOpen24Hours": true,
    "id": "54.272592,10.074924"
  },
  {
    "Gas Station Number": "0F245",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schweriner Straße 75",
    "Postcode": 23909,
    "City": "Ratzeburg",
    "State": "Schleswig-Holstein",
    "Latitude": "053.6929100",
    "Longitude": "010.7877400",
    "Telephone Number": "0454183929",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.69291,10.78774",
    "IsOpen24Hours": false,
    "id": "53.69291,10.78774"
  },
  {
    "Gas Station Number": "0F246",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 61",
    "Postcode": 24980,
    "City": "Schafflund",
    "State": "Schleswig-Holstein",
    "Latitude": "054.7607460",
    "Longitude": "009.1763940",
    "Telephone Number": "04639326",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "54.760746,9.176394",
    "IsOpen24Hours": false,
    "id": "54.760746,9.176394"
  },
  {
    "Gas Station Number": "0F247",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mittelweg 3",
    "Postcode": 25980,
    "City": "Sylt",
    "State": "Schleswig-Holstein",
    "Latitude": "054.9004010",
    "Longitude": "008.3392050",
    "Telephone Number": "046518367790",
    "Week From": "06:30",
    "Week To": "21:30",
    "Saturdays From": "07:00",
    "Saturdays To": "21:30",
    "Sundays From": "08:00",
    "Sundays To": "21:30",
    "24 hour?": "Nein",
    "location": "54.900401,8.339205",
    "IsOpen24Hours": false,
    "id": "54.900401,8.339205"
  },
  {
    "Gas Station Number": "0F248",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hindenburgstraße 10",
    "Postcode": 27442,
    "City": "Gnarrenburg",
    "State": "Niedersachsen",
    "Latitude": "053.3764170",
    "Longitude": "009.0068090",
    "Telephone Number": "04763627155",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.376417,9.006809",
    "IsOpen24Hours": false,
    "id": "53.376417,9.006809"
  },
  {
    "Gas Station Number": "0F265",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ringstraße 26",
    "Postcode": 32427,
    "City": "Minden",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.2831310",
    "Longitude": "008.9020970",
    "Telephone Number": "057121415",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.283131,8.902097",
    "IsOpen24Hours": true,
    "id": "52.283131,8.902097"
  },
  {
    "Gas Station Number": "0F266",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Georgstraße 48",
    "Postcode": 49809,
    "City": "Lingen",
    "State": "Niedersachsen",
    "Latitude": "052.5223260",
    "Longitude": "007.3340730",
    "Telephone Number": "05913800",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.522326,7.334073",
    "IsOpen24Hours": false,
    "id": "52.522326,7.334073"
  },
  {
    "Gas Station Number": "0F267",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Fröhlkingstraße 6",
    "Postcode": 49584,
    "City": "Fürstenau",
    "State": "Niedersachsen",
    "Latitude": "052.5136030",
    "Longitude": "007.6777450",
    "Telephone Number": "059012771",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.513603,7.677745",
    "IsOpen24Hours": false,
    "id": "52.513603,7.677745"
  },
  {
    "Gas Station Number": "0F268",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Oppelner Straße 1",
    "Postcode": 31167,
    "City": "Bockenem",
    "State": "Niedersachsen",
    "Latitude": "052.0019070",
    "Longitude": "010.1351850",
    "Telephone Number": "05067697875",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.001907,10.135185",
    "IsOpen24Hours": true,
    "id": "52.001907,10.135185"
  },
  {
    "Gas Station Number": "0F249",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schiffdorfer Chaussee 120",
    "Postcode": 27574,
    "City": "Bremerhaven",
    "State": "Bremen",
    "Latitude": "053.5287090",
    "Longitude": "008.6160310",
    "Telephone Number": "047135219",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.528709,8.616031",
    "IsOpen24Hours": true,
    "id": "53.528709,8.616031"
  },
  {
    "Gas Station Number": "0F252",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Drangstedter Straße 46",
    "Postcode": 27624,
    "City": "GEESTLAND",
    "State": "Niedersachsen",
    "Latitude": "053.6264140",
    "Longitude": "008.8243840",
    "Telephone Number": "047455085",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "53.626414,8.824384",
    "IsOpen24Hours": false,
    "id": "53.626414,8.824384"
  },
  {
    "Gas Station Number": "0F253",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 110",
    "Postcode": 25462,
    "City": "Rellingen",
    "State": "Schleswig-Holstein",
    "Latitude": "053.6547860",
    "Longitude": "009.8234910",
    "Telephone Number": "04101204018",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.654786,9.823491",
    "IsOpen24Hours": true,
    "id": "53.654786,9.823491"
  },
  {
    "Gas Station Number": "0F254",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stockholmstraße 2",
    "Postcode": 24109,
    "City": "Kiel",
    "State": "Schleswig-Holstein",
    "Latitude": "054.3261090",
    "Longitude": "010.0582830",
    "Telephone Number": "0431527585",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.326109,10.058283",
    "IsOpen24Hours": true,
    "id": "54.326109,10.058283"
  },
  {
    "Gas Station Number": "0F255",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ritterhuder Straße 55",
    "Postcode": 27711,
    "City": "Osterholz-Scharmbeck",
    "State": "Niedersachsen",
    "Latitude": "053.2148260",
    "Longitude": "008.7786520",
    "Telephone Number": "047912772",
    "Week From": "05:30",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "53.214826,8.778652",
    "IsOpen24Hours": false,
    "id": "53.214826,8.778652"
  },
  {
    "Gas Station Number": "0F256",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Möllner Straße 85",
    "Postcode": 21493,
    "City": "Schwarzenbek",
    "State": "Schleswig-Holstein",
    "Latitude": "053.5115300",
    "Longitude": "010.4891150",
    "Telephone Number": "04151895430",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.51153,10.489115",
    "IsOpen24Hours": true,
    "id": "53.51153,10.489115"
  },
  {
    "Gas Station Number": "0F257",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A7",
    "Postcode": 24791,
    "City": "Alt Duvenstedt",
    "State": "Schleswig-Holstein",
    "Latitude": "054.3641910",
    "Longitude": "009.6706570",
    "Telephone Number": "04338466",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.364191,9.670657",
    "IsOpen24Hours": true,
    "id": "54.364191,9.670657"
  },
  {
    "Gas Station Number": "0F258",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Pagenstecherstraße 58-60",
    "Postcode": 49090,
    "City": "Osnabrück",
    "State": "Niedersachsen",
    "Latitude": "052.2897380",
    "Longitude": "008.0274460",
    "Telephone Number": "054161931",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.289738,8.027446",
    "IsOpen24Hours": true,
    "id": "52.289738,8.027446"
  },
  {
    "Gas Station Number": "0F259",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Oesterstraße 92",
    "Postcode": 25704,
    "City": "Meldorf",
    "State": "Schleswig-Holstein",
    "Latitude": "054.0886840",
    "Longitude": "009.0960750",
    "Telephone Number": "048323211",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "54.088684,9.096075",
    "IsOpen24Hours": false,
    "id": "54.088684,9.096075"
  },
  {
    "Gas Station Number": "0F262",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Olof-Palme-Allee 2",
    "Postcode": 25541,
    "City": "Brunsbüttel",
    "State": "Schleswig-Holstein",
    "Latitude": "053.9004220",
    "Longitude": "009.1210240",
    "Telephone Number": "048527272",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "08:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "53.900422,9.121024",
    "IsOpen24Hours": false,
    "id": "53.900422,9.121024"
  },
  {
    "Gas Station Number": "0F263",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Winsener Str. 25",
    "Postcode": 21220,
    "City": "Seevetal",
    "State": "Niedersachsen",
    "Latitude": "053.3906450",
    "Longitude": "010.0452430",
    "Telephone Number": "0410582801",
    "Week From": "04:30",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.390645,10.045243",
    "IsOpen24Hours": false,
    "id": "53.390645,10.045243"
  },
  {
    "Gas Station Number": "0F264",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schwedendamm 21",
    "Postcode": 24143,
    "City": "Kiel",
    "State": "Schleswig-Holstein",
    "Latitude": "054.3073560",
    "Longitude": "010.1367290",
    "Telephone Number": "043174400",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.307356,10.136729",
    "IsOpen24Hours": true,
    "id": "54.307356,10.136729"
  },
  {
    "Gas Station Number": "0F261",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hamburger Straße 123",
    "Postcode": 24558,
    "City": "Henstedt-Ulzburg",
    "State": "Schleswig-Holstein",
    "Latitude": "053.7869870",
    "Longitude": "009.9792660",
    "Telephone Number": "0419393626",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.786987,9.979266",
    "IsOpen24Hours": true,
    "id": "53.786987,9.979266"
  },
  {
    "Gas Station Number": "0F025",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Isenstedter Straße 139",
    "Postcode": 32339,
    "City": "Espelkamp",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.3739300",
    "Longitude": "008.6347290",
    "Telephone Number": "05772936176",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.37393,8.634729",
    "IsOpen24Hours": true,
    "id": "52.37393,8.634729"
  },
  {
    "Gas Station Number": "0F269",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "BAB 21",
    "Postcode": 23795,
    "City": "Schackendorf",
    "State": "Schleswig-Holstein",
    "Latitude": "053.9601760",
    "Longitude": "010.2604580",
    "Telephone Number": "045514698",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.960176,10.260458",
    "IsOpen24Hours": true,
    "id": "53.960176,10.260458"
  },
  {
    "Gas Station Number": "0F270",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 62",
    "Postcode": 26954,
    "City": "Nordenham",
    "State": "Niedersachsen",
    "Latitude": "053.4868120",
    "Longitude": "008.4752460",
    "Telephone Number": "0473122702",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.486812,8.475246",
    "IsOpen24Hours": true,
    "id": "53.486812,8.475246"
  },
  {
    "Gas Station Number": "0F271",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Leipziger Straße 8c",
    "Postcode": 38302,
    "City": "Wolfenbüttel",
    "State": "Niedersachsen",
    "Latitude": "052.1631110",
    "Longitude": "010.5528710",
    "Telephone Number": "05331907575",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.163111,10.552871",
    "IsOpen24Hours": true,
    "id": "52.163111,10.552871"
  },
  {
    "Gas Station Number": "0F272",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rönnebrocksweg 15",
    "Postcode": 27356,
    "City": "Rotenburg",
    "State": "Niedersachsen",
    "Latitude": "053.1166880",
    "Longitude": "009.3951520",
    "Telephone Number": "04261971902",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.116688,9.395152",
    "IsOpen24Hours": true,
    "id": "53.116688,9.395152"
  },
  {
    "Gas Station Number": "0F273",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Münsterstraße 63-67",
    "Postcode": 49377,
    "City": "Vechta",
    "State": "Niedersachsen",
    "Latitude": "052.7189230",
    "Longitude": "008.2871410",
    "Telephone Number": "04441921437",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.718923,8.287141",
    "IsOpen24Hours": true,
    "id": "52.718923,8.287141"
  },
  {
    "Gas Station Number": "0F274",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Altendorfer Tor",
    "Postcode": 37574,
    "City": "Einbeck",
    "State": "Niedersachsen",
    "Latitude": "051.8115660",
    "Longitude": "009.8907610",
    "Telephone Number": "0556174228",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.811566,9.890761",
    "IsOpen24Hours": true,
    "id": "51.811566,9.890761"
  },
  {
    "Gas Station Number": "0F275",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "An der Autobahn 1",
    "Postcode": 27404,
    "City": "Gyhum",
    "State": "Niedersachsen",
    "Latitude": "053.1934170",
    "Longitude": "009.2888840",
    "Telephone Number": "0428695120",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.193417,9.288884",
    "IsOpen24Hours": true,
    "id": "53.193417,9.288884"
  },
  {
    "Gas Station Number": "0F276",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rendsburger Landstr. 205",
    "Postcode": 24113,
    "City": "Kiel",
    "State": "Schleswig-Holstein",
    "Latitude": "054.3004660",
    "Longitude": "010.0921950",
    "Telephone Number": "04316409660",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.300466,10.092195",
    "IsOpen24Hours": true,
    "id": "54.300466,10.092195"
  },
  {
    "Gas Station Number": "0F277",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Detmolder Straße 75",
    "Postcode": 33100,
    "City": "Paderborn",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.7322990",
    "Longitude": "008.7648380",
    "Telephone Number": "05251527584",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.732299,8.764838",
    "IsOpen24Hours": true,
    "id": "51.732299,8.764838"
  },
  {
    "Gas Station Number": "0F278",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Braunschw. Heerstraße 79",
    "Postcode": 29227,
    "City": "Celle",
    "State": "Niedersachsen",
    "Latitude": "052.6006350",
    "Longitude": "010.1073820",
    "Telephone Number": "05141880723",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.600635,10.107382",
    "IsOpen24Hours": true,
    "id": "52.600635,10.107382"
  },
  {
    "Gas Station Number": "0F279",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dieselstraße 1",
    "Postcode": 23738,
    "City": "Lensahn",
    "State": "Schleswig-Holstein",
    "Latitude": "054.2175900",
    "Longitude": "010.8967730",
    "Telephone Number": "04363901841",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.21759,10.896773",
    "IsOpen24Hours": true,
    "id": "54.21759,10.896773"
  },
  {
    "Gas Station Number": "0F280",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Großmannstraße 195-211",
    "Postcode": 20539,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5367530",
    "Longitude": "010.0642040",
    "Telephone Number": "04078079995",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.536753,10.064204",
    "IsOpen24Hours": true,
    "id": "53.536753,10.064204"
  },
  {
    "Gas Station Number": "0F324",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bruchstraße 35",
    "Postcode": 57462,
    "City": "Olpe",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0242640",
    "Longitude": "007.8446930",
    "Telephone Number": "027612212",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.024264,7.844693",
    "IsOpen24Hours": false,
    "id": "51.024264,7.844693"
  },
  {
    "Gas Station Number": "0F282",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Konrad-Adenauer-Allee 3 a",
    "Postcode": 21614,
    "City": "Buxtehude",
    "State": "Niedersachsen",
    "Latitude": "053.4701050",
    "Longitude": "009.7136460",
    "Telephone Number": "04161645637",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.470105,9.713646",
    "IsOpen24Hours": false,
    "id": "53.470105,9.713646"
  },
  {
    "Gas Station Number": "0F283",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schimmelmannstraße 97",
    "Postcode": 22043,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5722340",
    "Longitude": "010.1049740",
    "Telephone Number": "04065389512",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.572234,10.104974",
    "IsOpen24Hours": true,
    "id": "53.572234,10.104974"
  },
  {
    "Gas Station Number": "0F284",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lange Straße 39",
    "Postcode": 38300,
    "City": "Wolfenbüttel",
    "State": "Niedersachsen",
    "Latitude": "052.1565580",
    "Longitude": "010.5396760",
    "Telephone Number": "053311235",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.156558,10.539676",
    "IsOpen24Hours": true,
    "id": "52.156558,10.539676"
  },
  {
    "Gas Station Number": "0F007",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 1",
    "Postcode": 37589,
    "City": "Echte",
    "State": "Niedersachsen",
    "Latitude": "051.7877550",
    "Longitude": "010.0642170",
    "Telephone Number": "05553919618",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.787755,10.064217",
    "IsOpen24Hours": true,
    "id": "51.787755,10.064217"
  },
  {
    "Gas Station Number": "0F013",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Hamburger Straße 35 b",
    "Postcode": 21339,
    "City": "Lüneburg",
    "State": "Niedersachsen",
    "Latitude": "053.2751590",
    "Longitude": "010.4010780",
    "Telephone Number": "04131855291",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.275159,10.401078",
    "IsOpen24Hours": true,
    "id": "53.275159,10.401078"
  },
  {
    "Gas Station Number": "0F285",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Ludwig-Erhard-Straße 4",
    "Postcode": 29525,
    "City": "Uelzen",
    "State": "Niedersachsen",
    "Latitude": "052.9954800",
    "Longitude": "010.5396050",
    "Telephone Number": "05813897450",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.99548,10.539605",
    "IsOpen24Hours": true,
    "id": "52.99548,10.539605"
  },
  {
    "Gas Station Number": "0F286",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bramfelder Chaussee 66",
    "Postcode": 22177,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5972080",
    "Longitude": "010.0647010",
    "Telephone Number": "04069793273",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.597208,10.064701",
    "IsOpen24Hours": true,
    "id": "53.597208,10.064701"
  },
  {
    "Gas Station Number": "0F287",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nettelnburger Landweg 2",
    "Postcode": 21035,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.4866990",
    "Longitude": "010.1838660",
    "Telephone Number": "04073596311",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.486699,10.183866",
    "IsOpen24Hours": true,
    "id": "53.486699,10.183866"
  },
  {
    "Gas Station Number": "0F288",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Weserstraße 239",
    "Postcode": 27572,
    "City": "Bremerhaven",
    "State": "Bremen",
    "Latitude": "053.5183630",
    "Longitude": "008.5914780",
    "Telephone Number": "04717004265",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.518363,8.591478",
    "IsOpen24Hours": true,
    "id": "53.518363,8.591478"
  },
  {
    "Gas Station Number": "0F289",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Koppelberg 1",
    "Postcode": 24159,
    "City": "Kiel-Friedrichsort",
    "State": "Schleswig-Holstein",
    "Latitude": "054.4034290",
    "Longitude": "010.1662160",
    "Telephone Number": "04313970552",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.403429,10.166216",
    "IsOpen24Hours": true,
    "id": "54.403429,10.166216"
  },
  {
    "Gas Station Number": "0F290",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alte Heerstraße 4",
    "Postcode": 31061,
    "City": "Alfeld",
    "State": "Niedersachsen",
    "Latitude": "051.9957520",
    "Longitude": "009.7998700",
    "Telephone Number": "05181828326",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.995752,9.79987",
    "IsOpen24Hours": true,
    "id": "51.995752,9.79987"
  },
  {
    "Gas Station Number": "0F291",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Lise-Meitner-Allee 3",
    "Postcode": 25436,
    "City": "Tornesch",
    "State": "Schleswig-Holstein",
    "Latitude": "053.7181200",
    "Longitude": "009.7598080",
    "Telephone Number": "04120909760",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.71812,9.759808",
    "IsOpen24Hours": true,
    "id": "53.71812,9.759808"
  },
  {
    "Gas Station Number": "0F292",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Paderborner Tor 180/B 7",
    "Postcode": 34414,
    "City": "Warburg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4945930",
    "Longitude": "009.1260220",
    "Telephone Number": "05641743838",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.494593,9.126022",
    "IsOpen24Hours": true,
    "id": "51.494593,9.126022"
  },
  {
    "Gas Station Number": "0F293",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Rudolf-Diesel-Straße 4",
    "Postcode": 26670,
    "City": "Uplengen-Jübberde",
    "State": "Niedersachsen",
    "Latitude": "053.2628410",
    "Longitude": "007.7560890",
    "Telephone Number": "04956990408",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.262841,7.756089",
    "IsOpen24Hours": true,
    "id": "53.262841,7.756089"
  },
  {
    "Gas Station Number": "0F295",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Max-Planck-Straße 18",
    "Postcode": 21423,
    "City": "Winsen",
    "State": "Niedersachsen",
    "Latitude": "053.3393230",
    "Longitude": "010.2195390",
    "Telephone Number": "04171690795",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.339323,10.219539",
    "IsOpen24Hours": true,
    "id": "53.339323,10.219539"
  },
  {
    "Gas Station Number": "0FF01",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Hörster Heide 2",
    "Postcode": 49434,
    "City": "Neuenkirchen",
    "State": "Niedersachsen",
    "Latitude": "052.4914890",
    "Longitude": "008.0789940",
    "Telephone Number": "05495952150",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.491489,8.078994",
    "IsOpen24Hours": true,
    "id": "52.491489,8.078994"
  },
  {
    "Gas Station Number": "0F296",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Holstenstraße 112",
    "Postcode": 25560,
    "City": "Schenefeld",
    "State": "Schleswig-Holstein",
    "Latitude": "054.0396410",
    "Longitude": "009.4800570",
    "Telephone Number": "04892859750",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.039641,9.480057",
    "IsOpen24Hours": true,
    "id": "54.039641,9.480057"
  },
  {
    "Gas Station Number": "0F294",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Osterholzer Heerstraße 119-121",
    "Postcode": 28307,
    "City": "Bremen",
    "State": "Bremen",
    "Latitude": "053.0581020",
    "Longitude": "008.9385440",
    "Telephone Number": "04214173193",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.058102,8.938544",
    "IsOpen24Hours": true,
    "id": "53.058102,8.938544"
  },
  {
    "Gas Station Number": "0F298",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Weser-Ems-Str. 1",
    "Postcode": 28816,
    "City": "Stuhr",
    "State": "Niedersachsen",
    "Latitude": "053.0210270",
    "Longitude": "008.6878740",
    "Telephone Number": "042213799",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.021027,8.687874",
    "IsOpen24Hours": true,
    "id": "53.021027,8.687874"
  },
  {
    "Gas Station Number": "0FE94",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Europaallee",
    "Postcode": 28309,
    "City": "Bremen",
    "State": "Bremen",
    "Latitude": "053.0347400",
    "Longitude": "008.8913150",
    "Telephone Number": "04214581380",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.03474,8.891315",
    "IsOpen24Hours": true,
    "id": "53.03474,8.891315"
  },
  {
    "Gas Station Number": "0FG14",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Rudolf-Diesel-Straße 2",
    "Postcode": 24558,
    "City": "Henstedt-Ulzburg",
    "State": "Schleswig-Holstein",
    "Latitude": "053.8039600",
    "Longitude": "009.9391070",
    "Telephone Number": "04193888252",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.80396,9.939107",
    "IsOpen24Hours": true,
    "id": "53.80396,9.939107"
  },
  {
    "Gas Station Number": "0F299",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der E 47 -Seeseite-",
    "Postcode": 23775,
    "City": "Großenbrode",
    "State": "Schleswig-Holstein",
    "Latitude": "054.3813260",
    "Longitude": "011.0943200",
    "Telephone Number": "04367207",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.381326,11.09432",
    "IsOpen24Hours": true,
    "id": "54.381326,11.09432"
  },
  {
    "Gas Station Number": "0FG22",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ernst-Barlach-Straße 1",
    "Postcode": 26389,
    "City": "Wilhelmshaven",
    "State": "Niedersachsen",
    "Latitude": "053.5266130",
    "Longitude": "008.0657670",
    "Telephone Number": "0442171688",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.526613,8.065767",
    "IsOpen24Hours": true,
    "id": "53.526613,8.065767"
  },
  {
    "Gas Station Number": "0F300",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dieselstraße 2",
    "Postcode": 48499,
    "City": "Salzbergen",
    "State": "Niedersachsen",
    "Latitude": "052.3147800",
    "Longitude": "007.3536560",
    "Telephone Number": "05976940750",
    "Week From": "05:30",
    "Week To": "22:30",
    "Saturdays From": "06:00",
    "Saturdays To": "22:30",
    "Sundays From": "08:30",
    "Sundays To": "22:30",
    "24 hour?": "Nein",
    "location": "52.31478,7.353656",
    "IsOpen24Hours": false,
    "id": "52.31478,7.353656"
  },
  {
    "Gas Station Number": "0FE75",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Bockhorner Landstraße 63a",
    "Postcode": 23826,
    "City": "Bark O.T. Bockhorn",
    "State": "Schleswig-Holstein",
    "Latitude": "053.9184090",
    "Longitude": "010.1276570",
    "Telephone Number": "045586759924",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.918409,10.127657",
    "IsOpen24Hours": true,
    "id": "53.918409,10.127657"
  },
  {
    "Gas Station Number": "0FE69",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Eckernförder Straße 9 b",
    "Postcode": 24376,
    "City": "Kappeln",
    "State": "Schleswig-Holstein",
    "Latitude": "054.6572390",
    "Longitude": "009.9461280",
    "Telephone Number": "0464281008",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.657239,9.946128",
    "IsOpen24Hours": true,
    "id": "54.657239,9.946128"
  },
  {
    "Gas Station Number": "0FE74",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der Autobahn Nr. 2",
    "Postcode": 23858,
    "City": "Reinfeld",
    "State": "Schleswig-Holstein",
    "Latitude": "053.8235760",
    "Longitude": "010.5040980",
    "Telephone Number": "04533791203",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.823576,10.504098",
    "IsOpen24Hours": true,
    "id": "53.823576,10.504098"
  },
  {
    "Gas Station Number": "0F301",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Wilhelm-Iwan-Ring 1",
    "Postcode": 21035,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.4898170",
    "Longitude": "010.1150830",
    "Telephone Number": "04073431926",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.489817,10.115083",
    "IsOpen24Hours": true,
    "id": "53.489817,10.115083"
  },
  {
    "Gas Station Number": "0FG42",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hamburger Straße 65",
    "Postcode": 25355,
    "City": "Barmstedt",
    "State": "Schleswig-Holstein",
    "Latitude": "053.7843490",
    "Longitude": "009.7882030",
    "Telephone Number": "0412385138",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "53.784349,9.788203",
    "IsOpen24Hours": false,
    "id": "53.784349,9.788203"
  },
  {
    "Gas Station Number": "0FG34",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 1",
    "Postcode": 21709,
    "City": "Himmelpforten",
    "State": "Niedersachsen",
    "Latitude": "053.6100390",
    "Longitude": "009.3138230",
    "Telephone Number": "04144210521",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.610039,9.313823",
    "IsOpen24Hours": false,
    "id": "53.610039,9.313823"
  },
  {
    "Gas Station Number": "0F302",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sprockhöveler Straße 147",
    "Postcode": 58455,
    "City": "Witten",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4377220",
    "Longitude": "007.3212800",
    "Telephone Number": "02302274691",
    "Week From": "05:00",
    "Week To": "23:59",
    "Saturdays From": "05:00",
    "Saturdays To": "23:59",
    "Sundays From": "05:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "51.437722,7.32128",
    "IsOpen24Hours": false,
    "id": "51.437722,7.32128"
  },
  {
    "Gas Station Number": "0F303",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Astenbergstraße 2",
    "Postcode": 57319,
    "City": "Bad Berleburg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0566540",
    "Longitude": "008.3929030",
    "Telephone Number": "02751892558",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.056654,8.392903",
    "IsOpen24Hours": false,
    "id": "51.056654,8.392903"
  },
  {
    "Gas Station Number": "0F304",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Südring 285",
    "Postcode": 45711,
    "City": "Datteln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6557550",
    "Longitude": "007.3519150",
    "Telephone Number": "023632297",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.655755,7.351915",
    "IsOpen24Hours": false,
    "id": "51.655755,7.351915"
  },
  {
    "Gas Station Number": "0F305",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Evinger Straße 75",
    "Postcode": 44145,
    "City": "Dortmund",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5425330",
    "Longitude": "007.4589340",
    "Telephone Number": "0231806206",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.542533,7.458934",
    "IsOpen24Hours": false,
    "id": "51.542533,7.458934"
  },
  {
    "Gas Station Number": "0F323",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Im I. Westfeld 35",
    "Postcode": 44388,
    "City": "Dortmund",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4970460",
    "Longitude": "007.3328390",
    "Telephone Number": "0231631156",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.497046,7.332839",
    "IsOpen24Hours": true,
    "id": "51.497046,7.332839"
  },
  {
    "Gas Station Number": "0F306",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wittener Straße 95",
    "Postcode": 44575,
    "City": "Castrop-Rauxel",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5434950",
    "Longitude": "007.3170060",
    "Telephone Number": "0230523098",
    "Week From": "05:00",
    "Week To": "23:59",
    "Saturdays From": "05:00",
    "Saturdays To": "23:59",
    "Sundays From": "05:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "51.543495,7.317006",
    "IsOpen24Hours": false,
    "id": "51.543495,7.317006"
  },
  {
    "Gas Station Number": "0F307",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Westfalendamm 166",
    "Postcode": 44141,
    "City": "Dortmund",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5040570",
    "Longitude": "007.4984770",
    "Telephone Number": "02319776560",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.504057,7.498477",
    "IsOpen24Hours": true,
    "id": "51.504057,7.498477"
  },
  {
    "Gas Station Number": "0F308",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wambeler Hellweg 129",
    "Postcode": 44143,
    "City": "Dortmund",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5207840",
    "Longitude": "007.5235120",
    "Telephone Number": "0231597276",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.520784,7.523512",
    "IsOpen24Hours": false,
    "id": "51.520784,7.523512"
  },
  {
    "Gas Station Number": "0F309",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Marienborner Straße 173",
    "Postcode": 57074,
    "City": "Siegen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8763160",
    "Longitude": "008.0377260",
    "Telephone Number": "027162061",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.876316,8.037726",
    "IsOpen24Hours": false,
    "id": "50.876316,8.037726"
  },
  {
    "Gas Station Number": "0F310",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Allendorfer Straße 41",
    "Postcode": 59846,
    "City": "Sundern",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2848780",
    "Longitude": "007.9543610",
    "Telephone Number": "02393321",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "08:00",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "51.284878,7.954361",
    "IsOpen24Hours": false,
    "id": "51.284878,7.954361"
  },
  {
    "Gas Station Number": "0F311",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hattinger Straße 451",
    "Postcode": 44795,
    "City": "Bochum",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4433690",
    "Longitude": "007.1940650",
    "Telephone Number": "0234471650",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.443369,7.194065",
    "IsOpen24Hours": false,
    "id": "51.443369,7.194065"
  },
  {
    "Gas Station Number": "0F312",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Frankfurter Straße 22",
    "Postcode": 58339,
    "City": "Breckerfeld",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2573000",
    "Longitude": "007.4692400",
    "Telephone Number": "023384519894",
    "Week From": "06:30",
    "Week To": "20:00",
    "Saturdays From": "08:00",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "51.2573,7.46924",
    "IsOpen24Hours": false,
    "id": "51.2573,7.46924"
  },
  {
    "Gas Station Number": "0F313",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lindemannstraße 65",
    "Postcode": 44137,
    "City": "Dortmund",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5014860",
    "Longitude": "007.4517440",
    "Telephone Number": "02319003692",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.501486,7.451744",
    "IsOpen24Hours": true,
    "id": "51.501486,7.451744"
  },
  {
    "Gas Station Number": "0F314",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Weseler Straße 90",
    "Postcode": 45721,
    "City": "Haltern",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.7400000",
    "Longitude": "007.1740350",
    "Telephone Number": "023643624",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.74,7.174035",
    "IsOpen24Hours": false,
    "id": "51.74,7.174035"
  },
  {
    "Gas Station Number": "0F315",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heedfelder Straße14",
    "Postcode": 58579,
    "City": "Schalksmühle",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2560030",
    "Longitude": "007.5836290",
    "Telephone Number": "023516759180",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.256003,7.583629",
    "IsOpen24Hours": false,
    "id": "51.256003,7.583629"
  },
  {
    "Gas Station Number": "0F317",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heinrich-Lübke-Straße 66",
    "Postcode": 59759,
    "City": "Arnsberg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4235720",
    "Longitude": "007.9893890",
    "Telephone Number": "0293231576",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.423572,7.989389",
    "IsOpen24Hours": true,
    "id": "51.423572,7.989389"
  },
  {
    "Gas Station Number": "0F318",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schaphusstraße 9",
    "Postcode": 44359,
    "City": "Dortmund",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5737480",
    "Longitude": "007.3871330",
    "Telephone Number": "0231333191",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.573748,7.387133",
    "IsOpen24Hours": true,
    "id": "51.573748,7.387133"
  },
  {
    "Gas Station Number": "0F320",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Martener Straße 276",
    "Postcode": 44379,
    "City": "Dortmund",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5087620",
    "Longitude": "007.3884770",
    "Telephone Number": "0231613466",
    "Week From": "06:00",
    "Week To": "23:00",
    "Saturdays From": "07:00",
    "Saturdays To": "23:00",
    "Sundays From": "08:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "51.508762,7.388477",
    "IsOpen24Hours": false,
    "id": "51.508762,7.388477"
  },
  {
    "Gas Station Number": "0F321",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bochumer Straße 173",
    "Postcode": 45661,
    "City": "Recklinghausen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5676000",
    "Longitude": "007.2118700",
    "Telephone Number": "0236163663",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.5676,7.21187",
    "IsOpen24Hours": true,
    "id": "51.5676,7.21187"
  },
  {
    "Gas Station Number": "0F322",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Barmer Straße 44",
    "Postcode": 58332,
    "City": "Schwelm",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2806050",
    "Longitude": "007.2786960",
    "Telephone Number": "023364089979",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.280605,7.278696",
    "IsOpen24Hours": false,
    "id": "51.280605,7.278696"
  },
  {
    "Gas Station Number": "0F325",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 78",
    "Postcode": 59872,
    "City": "Meschede",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3652440",
    "Longitude": "008.1725380",
    "Telephone Number": "0290397120",
    "Week From": "04:30",
    "Week To": "22:00",
    "Saturdays From": "04:30",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.365244,8.172538",
    "IsOpen24Hours": false,
    "id": "51.365244,8.172538"
  },
  {
    "Gas Station Number": "0F326",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Eppenhauser Straße 134",
    "Postcode": 58093,
    "City": "Hagen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3609600",
    "Longitude": "007.5062920",
    "Telephone Number": "023317878512",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.36096,7.506292",
    "IsOpen24Hours": false,
    "id": "51.36096,7.506292"
  },
  {
    "Gas Station Number": "0F026",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hagener Straße 10",
    "Postcode": 57223,
    "City": "Kreuztal",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9609270",
    "Longitude": "007.9888810",
    "Telephone Number": "02732765532",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.960927,7.988881",
    "IsOpen24Hours": true,
    "id": "50.960927,7.988881"
  },
  {
    "Gas Station Number": "0F327",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mindener Straße 14",
    "Postcode": 59889,
    "City": "Eslohe",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2504390",
    "Longitude": "008.2028310",
    "Telephone Number": "02973571",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.250439,8.202831",
    "IsOpen24Hours": false,
    "id": "51.250439,8.202831"
  },
  {
    "Gas Station Number": "0F328",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Talstraße 161",
    "Postcode": 58515,
    "City": "Lüdenscheid",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2055440",
    "Longitude": "007.5826530",
    "Telephone Number": "0235171177",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.205544,7.582653",
    "IsOpen24Hours": true,
    "id": "51.205544,7.582653"
  },
  {
    "Gas Station Number": "0F329",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wehrscheid 7",
    "Postcode": 57392,
    "City": "Schmallenberg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1851180",
    "Longitude": "008.3136420",
    "Telephone Number": "0297496660",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.185118,8.313642",
    "IsOpen24Hours": false,
    "id": "51.185118,8.313642"
  },
  {
    "Gas Station Number": "0F330",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bräukerweg 122 b",
    "Postcode": 58708,
    "City": "Menden",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4374250",
    "Longitude": "007.7614290",
    "Telephone Number": "0237360775",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.437425,7.761429",
    "IsOpen24Hours": false,
    "id": "51.437425,7.761429"
  },
  {
    "Gas Station Number": "0F331",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 210",
    "Postcode": 59846,
    "City": "Sundern",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3364170",
    "Longitude": "007.9993830",
    "Telephone Number": "029339837150",
    "Week From": "05:30",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "51.336417,7.999383",
    "IsOpen24Hours": false,
    "id": "51.336417,7.999383"
  },
  {
    "Gas Station Number": "0F332",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hagener Straße 19",
    "Postcode": 57234,
    "City": "Wilnsdorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8177050",
    "Longitude": "008.1061760",
    "Telephone Number": "0273987330",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.817705,8.106176",
    "IsOpen24Hours": false,
    "id": "50.817705,8.106176"
  },
  {
    "Gas Station Number": "0F333",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hundemstraße 75",
    "Postcode": 57368,
    "City": "Lennestadt",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1011670",
    "Longitude": "008.0709880",
    "Telephone Number": "027236200",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:30",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.101167,8.070988",
    "IsOpen24Hours": false,
    "id": "51.101167,8.070988"
  },
  {
    "Gas Station Number": "0F334",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Altenaer Straße 29",
    "Postcode": 58769,
    "City": "Nachrodt-Wiblingwerde",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3338900",
    "Longitude": "007.6497260",
    "Telephone Number": "023523515",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.33389,7.649726",
    "IsOpen24Hours": false,
    "id": "51.33389,7.649726"
  },
  {
    "Gas Station Number": "0F335",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hörder Straße 89",
    "Postcode": 58239,
    "City": "Schwerte",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4527500",
    "Longitude": "007.5608580",
    "Telephone Number": "0230412930",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.45275,7.560858",
    "IsOpen24Hours": true,
    "id": "51.45275,7.560858"
  },
  {
    "Gas Station Number": "0F336",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kölner Straße 154",
    "Postcode": 58566,
    "City": "Kierspe",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1245740",
    "Longitude": "007.6033500",
    "Telephone Number": "023592057",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.124574,7.60335",
    "IsOpen24Hours": false,
    "id": "51.124574,7.60335"
  },
  {
    "Gas Station Number": "0F337",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Herdecker Bach 37 A",
    "Postcode": 58313,
    "City": "Herdecke",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4155100",
    "Longitude": "007.4322010",
    "Telephone Number": "023302878",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.41551,7.432201",
    "IsOpen24Hours": true,
    "id": "51.41551,7.432201"
  },
  {
    "Gas Station Number": "0F338",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Fröndenberger Straße 291",
    "Postcode": 58708,
    "City": "Menden",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4684780",
    "Longitude": "007.7688070",
    "Telephone Number": "02373390839",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.468478,7.768807",
    "IsOpen24Hours": false,
    "id": "51.468478,7.768807"
  },
  {
    "Gas Station Number": "0F339",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Siegstraße 43",
    "Postcode": 57076,
    "City": "Siegen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8999100",
    "Longitude": "008.0343910",
    "Telephone Number": "027173043",
    "Week From": "06:00",
    "Week To": "23:59",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.89991,8.034391",
    "IsOpen24Hours": false,
    "id": "50.89991,8.034391"
  },
  {
    "Gas Station Number": "0F340",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Eilper Straße 40",
    "Postcode": 58091,
    "City": "Hagen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3469340",
    "Longitude": "007.4948600",
    "Telephone Number": "0233177020",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.346934,7.49486",
    "IsOpen24Hours": true,
    "id": "51.346934,7.49486"
  },
  {
    "Gas Station Number": "0F341",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Warsteiner Straße 46",
    "Postcode": 59872,
    "City": "Meschede",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3529730",
    "Longitude": "008.2814120",
    "Telephone Number": "02912306",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.352973,8.281412",
    "IsOpen24Hours": false,
    "id": "51.352973,8.281412"
  },
  {
    "Gas Station Number": "0F342",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Möhneufer 2",
    "Postcode": 59755,
    "City": "Arnsberg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4563610",
    "Longitude": "007.9617090",
    "Telephone Number": "0293222223",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.456361,7.961709",
    "IsOpen24Hours": true,
    "id": "51.456361,7.961709"
  },
  {
    "Gas Station Number": "0F343",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Westenfelder Straße 20",
    "Postcode": 59846,
    "City": "Sundern",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3256780",
    "Longitude": "008.0383650",
    "Telephone Number": "02933976818",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:30",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "51.325678,8.038365",
    "IsOpen24Hours": false,
    "id": "51.325678,8.038365"
  },
  {
    "Gas Station Number": "0F344",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Koblenzer Straße 82",
    "Postcode": 57072,
    "City": "Siegen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8708540",
    "Longitude": "008.0145190",
    "Telephone Number": "0271331480",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.870854,8.014519",
    "IsOpen24Hours": true,
    "id": "50.870854,8.014519"
  },
  {
    "Gas Station Number": "0F345",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Brackeler Hellweg 160",
    "Postcode": 44309,
    "City": "Dortmund",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5239900",
    "Longitude": "007.5515520",
    "Telephone Number": "0231257429",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "23:59",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.52399,7.551552",
    "IsOpen24Hours": false,
    "id": "51.52399,7.551552"
  },
  {
    "Gas Station Number": "0F346",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hagener Straße 156",
    "Postcode": 44225,
    "City": "Dortmund",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4673940",
    "Longitude": "007.4602690",
    "Telephone Number": "0231731257",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.467394,7.460269",
    "IsOpen24Hours": true,
    "id": "51.467394,7.460269"
  },
  {
    "Gas Station Number": "0F348",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Marburger Straße 25",
    "Postcode": 57339,
    "City": "Erndtebrück",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9910460",
    "Longitude": "008.2589310",
    "Telephone Number": "027532085",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.991046,8.258931",
    "IsOpen24Hours": false,
    "id": "50.991046,8.258931"
  },
  {
    "Gas Station Number": "0F349",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hagener Straße 121",
    "Postcode": 58099,
    "City": "Hagen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3864650",
    "Longitude": "007.4779070",
    "Telephone Number": "0233161999",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.386465,7.477907",
    "IsOpen24Hours": true,
    "id": "51.386465,7.477907"
  },
  {
    "Gas Station Number": "0F350",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "Lichtendorfer Straße 136",
    "Postcode": 58239,
    "City": "Schwerte",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4673380",
    "Longitude": "007.5950740",
    "Telephone Number": "0230440366",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.467338,7.595074",
    "IsOpen24Hours": true,
    "id": "51.467338,7.595074"
  },
  {
    "Gas Station Number": "0F351",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Preußenstraße 49",
    "Postcode": 44532,
    "City": "Lünen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5927560",
    "Longitude": "007.5482830",
    "Telephone Number": "0230641069",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.592756,7.548283",
    "IsOpen24Hours": false,
    "id": "51.592756,7.548283"
  },
  {
    "Gas Station Number": "0F352",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alfredstraße 121",
    "Postcode": 45131,
    "City": "Essen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4320690",
    "Longitude": "007.0019870",
    "Telephone Number": "0201772604",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.432069,7.001987",
    "IsOpen24Hours": true,
    "id": "51.432069,7.001987"
  },
  {
    "Gas Station Number": "0F354",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dortmunder Straße 20",
    "Postcode": 45665,
    "City": "Recklinghausen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6129160",
    "Longitude": "007.2047190",
    "Telephone Number": "023619378150",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.612916,7.204719",
    "IsOpen24Hours": true,
    "id": "51.612916,7.204719"
  },
  {
    "Gas Station Number": "0F356",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hermannstraße 100",
    "Postcode": 45964,
    "City": "Gladbeck",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5746730",
    "Longitude": "006.9820060",
    "Telephone Number": "0204325991",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.574673,6.982006",
    "IsOpen24Hours": true,
    "id": "51.574673,6.982006"
  },
  {
    "Gas Station Number": "0F357",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Steeler Straße 281",
    "Postcode": 45138,
    "City": "Essen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4472000",
    "Longitude": "007.0458700",
    "Telephone Number": "0201287957",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.4472,7.04587",
    "IsOpen24Hours": true,
    "id": "51.4472,7.04587"
  },
  {
    "Gas Station Number": "0F355",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Holsterhauser Straße 2",
    "Postcode": 45147,
    "City": "Essen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4462540",
    "Longitude": "007.0005780",
    "Telephone Number": "0201777426",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.446254,7.000578",
    "IsOpen24Hours": true,
    "id": "51.446254,7.000578"
  },
  {
    "Gas Station Number": "0F359",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kölner Straße 70",
    "Postcode": 41334,
    "City": "Nettetal",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3108360",
    "Longitude": "006.2048540",
    "Telephone Number": "021576169",
    "Week From": "06:30",
    "Week To": "21:00",
    "Saturdays From": "06:30",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "51.310836,6.204854",
    "IsOpen24Hours": false,
    "id": "51.310836,6.204854"
  },
  {
    "Gas Station Number": "0F360",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hülser Straße 299",
    "Postcode": 47803,
    "City": "Krefeld",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3479650",
    "Longitude": "006.5434260",
    "Telephone Number": "02151753235",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.347965,6.543426",
    "IsOpen24Hours": true,
    "id": "51.347965,6.543426"
  },
  {
    "Gas Station Number": "0F363",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Klosterstraße 1",
    "Postcode": 41379,
    "City": "Brüggen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2378520",
    "Longitude": "006.1892370",
    "Telephone Number": "021635534",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.237852,6.189237",
    "IsOpen24Hours": false,
    "id": "51.237852,6.189237"
  },
  {
    "Gas Station Number": "0F364",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Umgehungsstraße 1",
    "Postcode": 47647,
    "City": "Kerken",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4426200",
    "Longitude": "006.4143140",
    "Telephone Number": "028337594",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.44262,6.414314",
    "IsOpen24Hours": true,
    "id": "51.44262,6.414314"
  },
  {
    "Gas Station Number": "0F365",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Krefelder Straße 10",
    "Postcode": 47441,
    "City": "Moers",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4520670",
    "Longitude": "006.6203240",
    "Telephone Number": "0284129441",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "06:00",
    "Saturdays To": "20:00",
    "Sundays From": "07:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "51.452067,6.620324",
    "IsOpen24Hours": false,
    "id": "51.452067,6.620324"
  },
  {
    "Gas Station Number": "0F366",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Röntgenstraße 4",
    "Postcode": 45470,
    "City": "Mülheim",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4174800",
    "Longitude": "006.8954280",
    "Telephone Number": "0208374546",
    "Week From": "07:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.41748,6.895428",
    "IsOpen24Hours": false,
    "id": "51.41748,6.895428"
  },
  {
    "Gas Station Number": "0F367",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Klever Straße 85",
    "Postcode": 47559,
    "City": "Kranenburg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.7834020",
    "Longitude": "006.0226680",
    "Telephone Number": "02826437",
    "Week From": "06:00",
    "Week To": "02:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "51.783402,6.022668",
    "IsOpen24Hours": false,
    "id": "51.783402,6.022668"
  },
  {
    "Gas Station Number": "0F368",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 48",
    "Postcode": 41366,
    "City": "Schwalmtal",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2327080",
    "Longitude": "006.2401230",
    "Telephone Number": "0216320442",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "08:00",
    "Saturdays To": "18:00",
    "Sundays From": "09:00",
    "Sundays To": "18:00",
    "24 hour?": "Nein",
    "location": "51.232708,6.240123",
    "IsOpen24Hours": false,
    "id": "51.232708,6.240123"
  },
  {
    "Gas Station Number": "0F369",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schottelstraße55",
    "Postcode": 47877,
    "City": "Willich",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2808640",
    "Longitude": "006.4674190",
    "Telephone Number": "0215691870",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.280864,6.467419",
    "IsOpen24Hours": false,
    "id": "51.280864,6.467419"
  },
  {
    "Gas Station Number": "0F370",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dorstener Straße  84",
    "Postcode": 44809,
    "City": "Bochum",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4894370",
    "Longitude": "007.2080330",
    "Telephone Number": "023495544430",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.489437,7.208033",
    "IsOpen24Hours": true,
    "id": "51.489437,7.208033"
  },
  {
    "Gas Station Number": "0F371",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bismarckstraße 280",
    "Postcode": 45889,
    "City": "Gelsenkirchen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5331430",
    "Longitude": "007.1107590",
    "Telephone Number": "020985906",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.533143,7.110759",
    "IsOpen24Hours": true,
    "id": "51.533143,7.110759"
  },
  {
    "Gas Station Number": "0F372",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Frintroper Straße 66-68",
    "Postcode": 45359,
    "City": "Essen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4626750",
    "Longitude": "006.9415570",
    "Telephone Number": "0201672659",
    "Week From": "05:30",
    "Week To": "23:00",
    "Saturdays From": "05:30",
    "Saturdays To": "23:59",
    "Sundays From": "05:30",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "51.462675,6.941557",
    "IsOpen24Hours": false,
    "id": "51.462675,6.941557"
  },
  {
    "Gas Station Number": "0F373",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Klever Straße 165",
    "Postcode": 47839,
    "City": "Krefeld",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3828430",
    "Longitude": "006.5049780",
    "Telephone Number": "02151730295",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.382843,6.504978",
    "IsOpen24Hours": false,
    "id": "51.382843,6.504978"
  },
  {
    "Gas Station Number": "0F374",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Viersener Straße 142",
    "Postcode": 41751,
    "City": "Viersen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2544890",
    "Longitude": "006.3475750",
    "Telephone Number": "0216242285",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.254489,6.347575",
    "IsOpen24Hours": true,
    "id": "51.254489,6.347575"
  },
  {
    "Gas Station Number": "0F375",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wankumer Straße 18",
    "Postcode": 47669,
    "City": "Wachtendonk",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4068560",
    "Longitude": "006.3270470",
    "Telephone Number": "028369726510",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.406856,6.327047",
    "IsOpen24Hours": false,
    "id": "51.406856,6.327047"
  },
  {
    "Gas Station Number": "0F376",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Polsumer Straße 152",
    "Postcode": 45896,
    "City": "Gelsenkirchen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5978560",
    "Longitude": "007.0515300",
    "Telephone Number": "0209630444",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:00",
    "24 hour?": "Ja",
    "location": "51.597856,7.05153",
    "IsOpen24Hours": true,
    "id": "51.597856,7.05153"
  },
  {
    "Gas Station Number": "0F377",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wittener Straße 71",
    "Postcode": 58456,
    "City": "Witten",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4191600",
    "Longitude": "007.2751610",
    "Telephone Number": "02302760298",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.41916,7.275161",
    "IsOpen24Hours": false,
    "id": "51.41916,7.275161"
  },
  {
    "Gas Station Number": "0F378",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Im Hammertal 1",
    "Postcode": 58456,
    "City": "Witten",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4054510",
    "Longitude": "007.2521300",
    "Telephone Number": "0232430592",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.405451,7.25213",
    "IsOpen24Hours": true,
    "id": "51.405451,7.25213"
  },
  {
    "Gas Station Number": "0F379",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Weseler Straße 90",
    "Postcode": 47661,
    "City": "Issum",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5402480",
    "Longitude": "006.4379340",
    "Telephone Number": "028353864",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.540248,6.437934",
    "IsOpen24Hours": false,
    "id": "51.540248,6.437934"
  },
  {
    "Gas Station Number": "0F381",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Halterner Straße 17",
    "Postcode": 45657,
    "City": "Recklinghausen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6193940",
    "Longitude": "007.1978110",
    "Telephone Number": "0236126261",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.619394,7.197811",
    "IsOpen24Hours": false,
    "id": "51.619394,7.197811"
  },
  {
    "Gas Station Number": "0F383",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Königsallee 142",
    "Postcode": 44789,
    "City": "Bochum",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4611550",
    "Longitude": "007.2206080",
    "Telephone Number": "0234337046",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.461155,7.220608",
    "IsOpen24Hours": true,
    "id": "51.461155,7.220608"
  },
  {
    "Gas Station Number": "0F384",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Castroper Straße 190",
    "Postcode": 44791,
    "City": "Bochum",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4907160",
    "Longitude": "007.2415190",
    "Telephone Number": "0234591182",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.490716,7.241519",
    "IsOpen24Hours": true,
    "id": "51.490716,7.241519"
  },
  {
    "Gas Station Number": "0F385",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rellinghauser Straße 312",
    "Postcode": 45136,
    "City": "Essen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4348780",
    "Longitude": "007.0339270",
    "Telephone Number": "0201250191",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.434878,7.033927",
    "IsOpen24Hours": true,
    "id": "51.434878,7.033927"
  },
  {
    "Gas Station Number": "0F386",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hölkeskampring 109",
    "Postcode": 44625,
    "City": "Herne",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5333120",
    "Longitude": "007.2348710",
    "Telephone Number": "0232340037",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.533312,7.234871",
    "IsOpen24Hours": false,
    "id": "51.533312,7.234871"
  },
  {
    "Gas Station Number": "0F387",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alleestraße 79",
    "Postcode": 44793,
    "City": "Bochum",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4793280",
    "Longitude": "007.2047100",
    "Telephone Number": "023466401",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.479328,7.20471",
    "IsOpen24Hours": true,
    "id": "51.479328,7.20471"
  },
  {
    "Gas Station Number": "0F388",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Niederrheinallee 48",
    "Postcode": 47506,
    "City": "Neukirchen-Vluyn",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4466970",
    "Longitude": "006.5768520",
    "Telephone Number": "028454465",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.446697,6.576852",
    "IsOpen24Hours": true,
    "id": "51.446697,6.576852"
  },
  {
    "Gas Station Number": "0F389",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Brechtstraße 12",
    "Postcode": 45739,
    "City": "Oer-Erkenschwick",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6405790",
    "Longitude": "007.2547540",
    "Telephone Number": "023681536",
    "Week From": "06:00",
    "Week To": "23:00",
    "Saturdays From": "07:00",
    "Saturdays To": "23:00",
    "Sundays From": "08:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "51.640579,7.254754",
    "IsOpen24Hours": false,
    "id": "51.640579,7.254754"
  },
  {
    "Gas Station Number": "0F390",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Krayer Straße 188",
    "Postcode": 45307,
    "City": "Essen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4620590",
    "Longitude": "007.0788260",
    "Telephone Number": "0201590516",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.462059,7.078826",
    "IsOpen24Hours": true,
    "id": "51.462059,7.078826"
  },
  {
    "Gas Station Number": "0F392",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bochumer Straße 177",
    "Postcode": 45886,
    "City": "Gelsenkirchen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4962420",
    "Longitude": "007.1144370",
    "Telephone Number": "0209204182",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "09:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.496242,7.114437",
    "IsOpen24Hours": false,
    "id": "51.496242,7.114437"
  },
  {
    "Gas Station Number": "0F393",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kemnader Straße328",
    "Postcode": 44797,
    "City": "Bochum",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4282530",
    "Longitude": "007.2437800",
    "Telephone Number": "0234791759",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.428253,7.24378",
    "IsOpen24Hours": false,
    "id": "51.428253,7.24378"
  },
  {
    "Gas Station Number": "0F394",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Brassertstraße 124",
    "Postcode": 45768,
    "City": "Marl",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6631600",
    "Longitude": "007.0805400",
    "Telephone Number": "0236566398",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "08:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.66316,7.08054",
    "IsOpen24Hours": false,
    "id": "51.66316,7.08054"
  },
  {
    "Gas Station Number": "0F395",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ewaldstraße 81",
    "Postcode": 45892,
    "City": "Gelsenkirchen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5751170",
    "Longitude": "007.1164410",
    "Telephone Number": "020975527",
    "Week From": "06:00",
    "Week To": "21:30",
    "Saturdays From": "07:00",
    "Saturdays To": "21:30",
    "Sundays From": "09:00",
    "Sundays To": "21:30",
    "24 hour?": "Nein",
    "location": "51.575117,7.116441",
    "IsOpen24Hours": false,
    "id": "51.575117,7.116441"
  },
  {
    "Gas Station Number": "0F397",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hans-Sachs-Straße 64",
    "Postcode": 46236,
    "City": "Bottrop",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5303230",
    "Longitude": "006.9245940",
    "Telephone Number": "020416505",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.530323,6.924594",
    "IsOpen24Hours": true,
    "id": "51.530323,6.924594"
  },
  {
    "Gas Station Number": "0F398",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Brabanter Straße 85",
    "Postcode": 41751,
    "City": "Viersen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2609190",
    "Longitude": "006.3348660",
    "Telephone Number": "0216251369",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.260919,6.334866",
    "IsOpen24Hours": false,
    "id": "51.260919,6.334866"
  },
  {
    "Gas Station Number": "0F399",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedrich-Ebert-Straße 117",
    "Postcode": 47226,
    "City": "Duisburg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4049890",
    "Longitude": "006.7119440",
    "Telephone Number": "02065313826",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.404989,6.711944",
    "IsOpen24Hours": true,
    "id": "51.404989,6.711944"
  },
  {
    "Gas Station Number": "0F400",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Triftstraße 118-120",
    "Postcode": 47533,
    "City": "Kleve",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.7737850",
    "Longitude": "006.1321180",
    "Telephone Number": "0282140960",
    "Week From": "07:00",
    "Week To": "22:00",
    "Saturdays From": "08:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.773785,6.132118",
    "IsOpen24Hours": false,
    "id": "51.773785,6.132118"
  },
  {
    "Gas Station Number": "0F401",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ostring 201",
    "Postcode": 46238,
    "City": "Bottrop",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5218960",
    "Longitude": "006.9526060",
    "Telephone Number": "0204163364",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "09:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.521896,6.952606",
    "IsOpen24Hours": false,
    "id": "51.521896,6.952606"
  },
  {
    "Gas Station Number": "0F402",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heidhauser Straße 1",
    "Postcode": 45239,
    "City": "Essen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3881020",
    "Longitude": "007.0226000",
    "Telephone Number": "0201404066",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.388102,7.0226",
    "IsOpen24Hours": true,
    "id": "51.388102,7.0226"
  },
  {
    "Gas Station Number": "0F403",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Römerstraße 454",
    "Postcode": 47441,
    "City": "Moers",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4438920",
    "Longitude": "006.6610560",
    "Telephone Number": "0284151105",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.443892,6.661056",
    "IsOpen24Hours": true,
    "id": "51.443892,6.661056"
  },
  {
    "Gas Station Number": "0F404",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Holsterhauser Straße 301",
    "Postcode": 44625,
    "City": "Herne",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5330590",
    "Longitude": "007.2141340",
    "Telephone Number": "0232354548",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.533059,7.214134",
    "IsOpen24Hours": true,
    "id": "51.533059,7.214134"
  },
  {
    "Gas Station Number": "0F405",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mülheimer Straße 278-284",
    "Postcode": 46045,
    "City": "Oberhausen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4669880",
    "Longitude": "006.8646040",
    "Telephone Number": "02088830797",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.466988,6.864604",
    "IsOpen24Hours": true,
    "id": "51.466988,6.864604"
  },
  {
    "Gas Station Number": "0F407",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schermbecker Landstr. 34",
    "Postcode": 46569,
    "City": "Hünxe",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6681540",
    "Longitude": "006.7418040",
    "Telephone Number": "028582468",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.668154,6.741804",
    "IsOpen24Hours": false,
    "id": "51.668154,6.741804"
  },
  {
    "Gas Station Number": "0F408",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Frankfurter Straße 343",
    "Postcode": 46562,
    "City": "Voerde",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5991750",
    "Longitude": "006.6639430",
    "Telephone Number": "028556574",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.599175,6.663943",
    "IsOpen24Hours": false,
    "id": "51.599175,6.663943"
  },
  {
    "Gas Station Number": "0F409",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Spellenerstraße 13",
    "Postcode": 46562,
    "City": "Voerde",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6291960",
    "Longitude": "006.6587930",
    "Telephone Number": "028141301",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.629196,6.658793",
    "IsOpen24Hours": true,
    "id": "51.629196,6.658793"
  },
  {
    "Gas Station Number": "0F411",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kupferdreher Straße 61",
    "Postcode": 45257,
    "City": "Essen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3968360",
    "Longitude": "007.0854880",
    "Telephone Number": "0201482034",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.396836,7.085488",
    "IsOpen24Hours": false,
    "id": "51.396836,7.085488"
  },
  {
    "Gas Station Number": "0F410",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Teutoburger Straße 1-3",
    "Postcode": 46145,
    "City": "Oberhausen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5166230",
    "Longitude": "006.8572460",
    "Telephone Number": "0208667060",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.516623,6.857246",
    "IsOpen24Hours": true,
    "id": "51.516623,6.857246"
  },
  {
    "Gas Station Number": "0F412",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wüstrathstraße 6",
    "Postcode": 47829,
    "City": "Krefeld",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3534370",
    "Longitude": "006.6427400",
    "Telephone Number": "0215146632",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "05:00",
    "Saturdays To": "23:00",
    "Sundays From": "05:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "51.353437,6.64274",
    "IsOpen24Hours": false,
    "id": "51.353437,6.64274"
  },
  {
    "Gas Station Number": "0F413",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bochumer Straße 4",
    "Postcode": 45549,
    "City": "Sprockhövel",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3668400",
    "Longitude": "007.2523760",
    "Telephone Number": "0232474188",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.36684,7.252376",
    "IsOpen24Hours": false,
    "id": "51.36684,7.252376"
  },
  {
    "Gas Station Number": "0F415",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Talstraße 10",
    "Postcode": 58332,
    "City": "Schwelm",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2929550",
    "Longitude": "007.2862600",
    "Telephone Number": "023363645",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.292955,7.28626",
    "IsOpen24Hours": true,
    "id": "51.292955,7.28626"
  },
  {
    "Gas Station Number": "0F416",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hüstener Straße 44",
    "Postcode": 59821,
    "City": "Arnsberg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4104960",
    "Longitude": "008.0549330",
    "Telephone Number": "0293177251",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.410496,8.054933",
    "IsOpen24Hours": true,
    "id": "51.410496,8.054933"
  },
  {
    "Gas Station Number": "0F418",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A45",
    "Postcode": 57258,
    "City": "Freudenberg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8817330",
    "Longitude": "007.9341000",
    "Telephone Number": "02734495780",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.881733,7.9341",
    "IsOpen24Hours": true,
    "id": "50.881733,7.9341"
  },
  {
    "Gas Station Number": "0F002",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Werdohler Landstraße 4",
    "Postcode": 58511,
    "City": "Lüdenscheid",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2285900",
    "Longitude": "007.6514200",
    "Telephone Number": "023514329875",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.22859,7.65142",
    "IsOpen24Hours": true,
    "id": "51.22859,7.65142"
  },
  {
    "Gas Station Number": "0F420",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Flughafenstraße 425",
    "Postcode": 44328,
    "City": "Dortmund",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5491280",
    "Longitude": "007.5385850",
    "Telephone Number": "0231235201",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "07:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "51.549128,7.538585",
    "IsOpen24Hours": false,
    "id": "51.549128,7.538585"
  },
  {
    "Gas Station Number": "0F421",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dortmunder Straße 110",
    "Postcode": 58638,
    "City": "Iserlohn",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3805690",
    "Longitude": "007.6818900",
    "Telephone Number": "0237127435",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.380569,7.68189",
    "IsOpen24Hours": true,
    "id": "51.380569,7.68189"
  },
  {
    "Gas Station Number": "0F422",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hohenlimburger Straße 191",
    "Postcode": 58119,
    "City": "Hagen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3559590",
    "Longitude": "007.5561660",
    "Telephone Number": "0233445186",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.355959,7.556166",
    "IsOpen24Hours": true,
    "id": "51.355959,7.556166"
  },
  {
    "Gas Station Number": "0F003",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Brechtener Straße 26",
    "Postcode": 44536,
    "City": "Lünen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5928670",
    "Longitude": "007.4400230",
    "Telephone Number": "0231871659",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.592867,7.440023",
    "IsOpen24Hours": false,
    "id": "51.592867,7.440023"
  },
  {
    "Gas Station Number": "0F423",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bebelstraße 69 a",
    "Postcode": 46049,
    "City": "Oberhausen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4715250",
    "Longitude": "006.8376100",
    "Telephone Number": "020825434",
    "Week From": "06:00",
    "Week To": "22:45",
    "Saturdays From": "07:00",
    "Saturdays To": "22:45",
    "Sundays From": "08:00",
    "Sundays To": "22:45",
    "24 hour?": "Nein",
    "location": "51.471525,6.83761",
    "IsOpen24Hours": false,
    "id": "51.471525,6.83761"
  },
  {
    "Gas Station Number": "0F424",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Horster Straße 370",
    "Postcode": 45968,
    "City": "Gladbeck",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5465580",
    "Longitude": "007.0128830",
    "Telephone Number": "0204334859",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.546558,7.012883",
    "IsOpen24Hours": false,
    "id": "51.546558,7.012883"
  },
  {
    "Gas Station Number": "0F425",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mülheimer Straße 155",
    "Postcode": 46045,
    "City": "Oberhausen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4727610",
    "Longitude": "006.8635270",
    "Telephone Number": "020821691",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.472761,6.863527",
    "IsOpen24Hours": true,
    "id": "51.472761,6.863527"
  },
  {
    "Gas Station Number": "0F426",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Krefelder Straße 101",
    "Postcode": 41539,
    "City": "Dormagen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1083700",
    "Longitude": "006.8256190",
    "Telephone Number": "021333310",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.10837,6.825619",
    "IsOpen24Hours": true,
    "id": "51.10837,6.825619"
  },
  {
    "Gas Station Number": "0F427",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Athener Ring 1",
    "Postcode": 50765,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0191990",
    "Longitude": "006.8965810",
    "Telephone Number": "02217089582",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.019199,6.896581",
    "IsOpen24Hours": true,
    "id": "51.019199,6.896581"
  },
  {
    "Gas Station Number": "0F428",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedrich-Ebert-Str. 113",
    "Postcode": 46236,
    "City": "Bottrop",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5199130",
    "Longitude": "006.9311380",
    "Telephone Number": "0204122389",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.519913,6.931138",
    "IsOpen24Hours": true,
    "id": "51.519913,6.931138"
  },
  {
    "Gas Station Number": "0F429",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lüdinghauserstr 60",
    "Postcode": 59394,
    "City": "Nordkirchen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.7420070",
    "Longitude": "007.5169440",
    "Telephone Number": "0259698557",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "51.742007,7.516944",
    "IsOpen24Hours": false,
    "id": "51.742007,7.516944"
  },
  {
    "Gas Station Number": "0F430",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hülser Straße 45",
    "Postcode": 47906,
    "City": "Kempen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3645530",
    "Longitude": "006.4306040",
    "Telephone Number": "021523439",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.364553,6.430604",
    "IsOpen24Hours": false,
    "id": "51.364553,6.430604"
  },
  {
    "Gas Station Number": "0F431",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Siegesstraße 140",
    "Postcode": 46147,
    "City": "Oberhausen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5211490",
    "Longitude": "006.7878090",
    "Telephone Number": "0208688849",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.521149,6.787809",
    "IsOpen24Hours": false,
    "id": "51.521149,6.787809"
  },
  {
    "Gas Station Number": "0F432",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Koblenzer Straße 171",
    "Postcode": 53177,
    "City": "Bonn",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.6776110",
    "Longitude": "007.1685000",
    "Telephone Number": "0228333097",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.677611,7.1685",
    "IsOpen24Hours": false,
    "id": "50.677611,7.1685"
  },
  {
    "Gas Station Number": "0F433",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Roermonder Straße 25-29",
    "Postcode": 52134,
    "City": "Herzogenrath",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8294930",
    "Longitude": "006.0780540",
    "Telephone Number": "0240718113",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "50.829493,6.078054",
    "IsOpen24Hours": false,
    "id": "50.829493,6.078054"
  },
  {
    "Gas Station Number": "0F434",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Märkische Straße 29",
    "Postcode": 42281,
    "City": "Wuppertal",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2847640",
    "Longitude": "007.2081790",
    "Telephone Number": "0202520277",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.284764,7.208179",
    "IsOpen24Hours": true,
    "id": "51.284764,7.208179"
  },
  {
    "Gas Station Number": "0F436",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Hagenblech 60",
    "Postcode": 59955,
    "City": "Winterberg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2004440",
    "Longitude": "008.5309260",
    "Telephone Number": "0298192600",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.200444,8.530926",
    "IsOpen24Hours": true,
    "id": "51.200444,8.530926"
  },
  {
    "Gas Station Number": "0F438",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kölner Straße 316",
    "Postcode": 47807,
    "City": "Krefeld",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3134770",
    "Longitude": "006.5795830",
    "Telephone Number": "02151315131",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "51.313477,6.579583",
    "IsOpen24Hours": false,
    "id": "51.313477,6.579583"
  },
  {
    "Gas Station Number": "0F439",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Herner Straße 256",
    "Postcode": 44809,
    "City": "Bochum",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5016220",
    "Longitude": "007.2127000",
    "Telephone Number": "02349530941",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.501622,7.2127",
    "IsOpen24Hours": true,
    "id": "51.501622,7.2127"
  },
  {
    "Gas Station Number": "0F440",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedrich-Ebert-Straße 14",
    "Postcode": 45659,
    "City": "Recklinghausen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6027070",
    "Longitude": "007.1850950",
    "Telephone Number": "0236126544",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.602707,7.185095",
    "IsOpen24Hours": false,
    "id": "51.602707,7.185095"
  },
  {
    "Gas Station Number": "0F442",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hafenstraße 43 A",
    "Postcode": 47119,
    "City": "Duisburg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4532080",
    "Longitude": "006.7403330",
    "Telephone Number": "0203873020",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.453208,6.740333",
    "IsOpen24Hours": true,
    "id": "51.453208,6.740333"
  },
  {
    "Gas Station Number": "0F443",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neustraße 19",
    "Postcode": 58256,
    "City": "Ennepetal",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2968700",
    "Longitude": "007.3604760",
    "Telephone Number": "0233388816",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.29687,7.360476",
    "IsOpen24Hours": true,
    "id": "51.29687,7.360476"
  },
  {
    "Gas Station Number": "0F444",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 2",
    "Postcode": 50169,
    "City": "Kerpen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9242500",
    "Longitude": "006.7054490",
    "Telephone Number": "022731002",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.92425,6.705449",
    "IsOpen24Hours": true,
    "id": "50.92425,6.705449"
  },
  {
    "Gas Station Number": "0F441",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Frauenberger Straße 168",
    "Postcode": 53879,
    "City": "Euskirchen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.6701300",
    "Longitude": "006.7725480",
    "Telephone Number": "02251782560",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.67013,6.772548",
    "IsOpen24Hours": false,
    "id": "50.67013,6.772548"
  },
  {
    "Gas Station Number": "0F445",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Overweg 33",
    "Postcode": 59494,
    "City": "Soest",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5535490",
    "Longitude": "008.1585490",
    "Telephone Number": "02921944064",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.553549,8.158549",
    "IsOpen24Hours": true,
    "id": "51.553549,8.158549"
  },
  {
    "Gas Station Number": "0F446",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Untergath 210",
    "Postcode": 47805,
    "City": "Krefeld",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3219780",
    "Longitude": "006.5903650",
    "Telephone Number": "02151557780",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.321978,6.590365",
    "IsOpen24Hours": true,
    "id": "51.321978,6.590365"
  },
  {
    "Gas Station Number": "0F447",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mülheimer Straße 15",
    "Postcode": 53840,
    "City": "Troisdorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8190620",
    "Longitude": "007.1426350",
    "Telephone Number": "02241809505",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.819062,7.142635",
    "IsOpen24Hours": true,
    "id": "50.819062,7.142635"
  },
  {
    "Gas Station Number": "0F448",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berliner Straße 96",
    "Postcode": 44867,
    "City": "Bochum",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4652610",
    "Longitude": "007.1310560",
    "Telephone Number": "02327939497",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.465261,7.131056",
    "IsOpen24Hours": true,
    "id": "51.465261,7.131056"
  },
  {
    "Gas Station Number": "0F449",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kardinal-Galen-Straße 106",
    "Postcode": 47058,
    "City": "Duisburg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4374320",
    "Longitude": "006.7762410",
    "Telephone Number": "0203331435",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.437432,6.776241",
    "IsOpen24Hours": true,
    "id": "51.437432,6.776241"
  },
  {
    "Gas Station Number": "0F450",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neuenkamper Straße 2-4",
    "Postcode": 42855,
    "City": "Remscheid",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1770400",
    "Longitude": "007.2115030",
    "Telephone Number": "02191387558",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.17704,7.211503",
    "IsOpen24Hours": true,
    "id": "51.17704,7.211503"
  },
  {
    "Gas Station Number": "0F451",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alter Deutzer Postweg 102",
    "Postcode": 51149,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9186210",
    "Longitude": "007.0493320",
    "Telephone Number": "02203371622",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.918621,7.049332",
    "IsOpen24Hours": true,
    "id": "50.918621,7.049332"
  },
  {
    "Gas Station Number": "0F452",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Castroper Hellweg 111",
    "Postcode": 44805,
    "City": "Bochum",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5016910",
    "Longitude": "007.2586430",
    "Telephone Number": "02349508090",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.501691,7.258643",
    "IsOpen24Hours": true,
    "id": "51.501691,7.258643"
  },
  {
    "Gas Station Number": "0F453",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Bürener Straße 155",
    "Postcode": 59590,
    "City": "Geseke",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5977610",
    "Longitude": "008.5157200",
    "Telephone Number": "02942574916",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.597761,8.51572",
    "IsOpen24Hours": true,
    "id": "51.597761,8.51572"
  },
  {
    "Gas Station Number": "0F454",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nürnberger Straße 33",
    "Postcode": 40599,
    "City": "Düsseldorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1704250",
    "Longitude": "006.8594080",
    "Telephone Number": "02119991725",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "51.170425,6.859408",
    "IsOpen24Hours": false,
    "id": "51.170425,6.859408"
  },
  {
    "Gas Station Number": "0F455",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wittgensteiner Straße 8 -",
    "Postcode": 57271,
    "City": "Hilchenbach",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9868850",
    "Longitude": "008.1020470",
    "Telephone Number": "027332686",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.986885,8.102047",
    "IsOpen24Hours": false,
    "id": "50.986885,8.102047"
  },
  {
    "Gas Station Number": "0F456",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Siegener Straße 449",
    "Postcode": 57258,
    "City": "Freudenberg - Linden",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9013090",
    "Longitude": "007.9266700",
    "Telephone Number": "027347192",
    "Week From": "06:30",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.901309,7.92667",
    "IsOpen24Hours": false,
    "id": "50.901309,7.92667"
  },
  {
    "Gas Station Number": "0F457",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ernestinenstraße 157",
    "Postcode": 45141,
    "City": "Essen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4677830",
    "Longitude": "007.0454530",
    "Telephone Number": "02012018242",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.467783,7.045453",
    "IsOpen24Hours": true,
    "id": "51.467783,7.045453"
  },
  {
    "Gas Station Number": "0FE92",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Oskar-Schindler-Straße 1",
    "Postcode": 50769,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0329670",
    "Longitude": "006.9199210",
    "Telephone Number": "022170995092",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.032967,6.919921",
    "IsOpen24Hours": true,
    "id": "51.032967,6.919921"
  },
  {
    "Gas Station Number": "0F458",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Roermonder Straße 18",
    "Postcode": 41836,
    "City": "Hückelhoven",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0527150",
    "Longitude": "006.2173740",
    "Telephone Number": "02433938733",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.052715,6.217374",
    "IsOpen24Hours": true,
    "id": "51.052715,6.217374"
  },
  {
    "Gas Station Number": "0F459",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Marburger Straße 61",
    "Postcode": 35083,
    "City": "Wetter",
    "State": "Hessen",
    "Latitude": "050.9009840",
    "Longitude": "008.7362790",
    "Telephone Number": "0642392110",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.900984,8.736279",
    "IsOpen24Hours": true,
    "id": "50.900984,8.736279"
  },
  {
    "Gas Station Number": "0FG18",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Widdersdorfer Straße 427-",
    "Postcode": 50933,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9468770",
    "Longitude": "006.8805000",
    "Telephone Number": "02215894077",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.946877,6.8805",
    "IsOpen24Hours": true,
    "id": "50.946877,6.8805"
  },
  {
    "Gas Station Number": "0FE93",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Espenhof 1",
    "Postcode": 49549,
    "City": "Ladbergen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.1464880",
    "Longitude": "007.7361240",
    "Telephone Number": "05485831620",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.146488,7.736124",
    "IsOpen24Hours": true,
    "id": "52.146488,7.736124"
  },
  {
    "Gas Station Number": "0F479",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Balinger Straße 26",
    "Postcode": 78727,
    "City": "Oberndorf",
    "State": "Baden-Württemberg",
    "Latitude": "048.3001680",
    "Longitude": "008.6117520",
    "Telephone Number": "074233558",
    "Week From": "05:30",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.300168,8.611752",
    "IsOpen24Hours": false,
    "id": "48.300168,8.611752"
  },
  {
    "Gas Station Number": "0F461",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Frankfurter Straße 78",
    "Postcode": 53757,
    "City": "Sankt Augustin",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7832310",
    "Longitude": "007.2359830",
    "Telephone Number": "02241590763",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.783231,7.235983",
    "IsOpen24Hours": false,
    "id": "50.783231,7.235983"
  },
  {
    "Gas Station Number": "0F463",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hohenstaufenstraße 5",
    "Postcode": 73033,
    "City": "Göppingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.7028370",
    "Longitude": "009.6617010",
    "Telephone Number": "0716175454",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.702837,9.661701",
    "IsOpen24Hours": true,
    "id": "48.702837,9.661701"
  },
  {
    "Gas Station Number": "0F464",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wilhelm-Leuschner-Str. 14",
    "Postcode": 74080,
    "City": "Heilbronn",
    "State": "Baden-Württemberg",
    "Latitude": "049.1414430",
    "Longitude": "009.1963020",
    "Telephone Number": "0713142113",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.141443,9.196302",
    "IsOpen24Hours": true,
    "id": "49.141443,9.196302"
  },
  {
    "Gas Station Number": "0F465",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Reutlinger Straße 72",
    "Postcode": 72072,
    "City": "Tübingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.5129520",
    "Longitude": "009.0736250",
    "Telephone Number": "0707131941",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.512952,9.073625",
    "IsOpen24Hours": true,
    "id": "48.512952,9.073625"
  },
  {
    "Gas Station Number": "0F466",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Waiblinger Straße 23-25",
    "Postcode": 70372,
    "City": "Stuttgart",
    "State": "Baden-Württemberg",
    "Latitude": "048.8043450",
    "Longitude": "009.2202730",
    "Telephone Number": "07119561039",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.804345,9.220273",
    "IsOpen24Hours": true,
    "id": "48.804345,9.220273"
  },
  {
    "Gas Station Number": "0F467",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der B 14/Sulzbach.-Eck",
    "Postcode": 71560,
    "City": "Sulzbach",
    "State": "Baden-Württemberg",
    "Latitude": "049.0050610",
    "Longitude": "009.5019420",
    "Telephone Number": "071936104",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.005061,9.501942",
    "IsOpen24Hours": false,
    "id": "49.005061,9.501942"
  },
  {
    "Gas Station Number": "0F468",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ringstraße 20",
    "Postcode": 71364,
    "City": "Winnenden",
    "State": "Baden-Württemberg",
    "Latitude": "048.8783290",
    "Longitude": "009.3976370",
    "Telephone Number": "07195946149",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.878329,9.397637",
    "IsOpen24Hours": false,
    "id": "48.878329,9.397637"
  },
  {
    "Gas Station Number": "0F469",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Entenstraße 18",
    "Postcode": 74199,
    "City": "Untergruppenbach",
    "State": "Baden-Württemberg",
    "Latitude": "049.0867250",
    "Longitude": "009.2755600",
    "Telephone Number": "07131976124",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "08:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.086725,9.27556",
    "IsOpen24Hours": false,
    "id": "49.086725,9.27556"
  },
  {
    "Gas Station Number": "0F470",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neuenstädter Straße 93",
    "Postcode": 74172,
    "City": "Neckarsulm",
    "State": "Baden-Württemberg",
    "Latitude": "049.1989070",
    "Longitude": "009.2323690",
    "Telephone Number": "0713217709",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.198907,9.232369",
    "IsOpen24Hours": true,
    "id": "49.198907,9.232369"
  },
  {
    "Gas Station Number": "0F471",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neue Unlinger St. 4",
    "Postcode": 88499,
    "City": "Riedlingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.1482900",
    "Longitude": "009.4851310",
    "Telephone Number": "0737110286",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.14829,9.485131",
    "IsOpen24Hours": true,
    "id": "48.14829,9.485131"
  },
  {
    "Gas Station Number": "0F472",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mergentheimer Straße 2/1",
    "Postcode": 74653,
    "City": "Künzelsau",
    "State": "Baden-Württemberg",
    "Latitude": "049.2819380",
    "Longitude": "009.6913810",
    "Telephone Number": "079402221",
    "Week From": "07:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.281938,9.691381",
    "IsOpen24Hours": false,
    "id": "49.281938,9.691381"
  },
  {
    "Gas Station Number": "0F473",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heilbronner Straße 17",
    "Postcode": 74613,
    "City": "Öhringen",
    "State": "Baden-Württemberg",
    "Latitude": "049.2022230",
    "Longitude": "009.4951080",
    "Telephone Number": "0794134945",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.202223,9.495108",
    "IsOpen24Hours": true,
    "id": "49.202223,9.495108"
  },
  {
    "Gas Station Number": "0F474",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stuttgarter Straße 30",
    "Postcode": 72172,
    "City": "Sulz",
    "State": "Baden-Württemberg",
    "Latitude": "048.3671810",
    "Longitude": "008.6365950",
    "Telephone Number": "074549767037",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.367181,8.636595",
    "IsOpen24Hours": false,
    "id": "48.367181,8.636595"
  },
  {
    "Gas Station Number": "0F475",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Herbertinger Straße 1",
    "Postcode": 88348,
    "City": "Bad Saulgau",
    "State": "Baden-Württemberg",
    "Latitude": "048.0191100",
    "Longitude": "009.4970700",
    "Telephone Number": "07581488636",
    "Week From": "06:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:30",
    "Sundays From": "07:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "48.01911,9.49707",
    "IsOpen24Hours": false,
    "id": "48.01911,9.49707"
  },
  {
    "Gas Station Number": "0F476",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kochendorfer Straße 2",
    "Postcode": 74196,
    "City": "Neuenstadt",
    "State": "Baden-Württemberg",
    "Latitude": "049.2365690",
    "Longitude": "009.3246720",
    "Telephone Number": "071391382",
    "Week From": "07:00",
    "Week To": "19:00",
    "Saturdays From": "07:30",
    "Saturdays To": "19:00",
    "Sundays From": "09:00",
    "Sundays To": "17:00",
    "24 hour?": "Nein",
    "location": "49.236569,9.324672",
    "IsOpen24Hours": false,
    "id": "49.236569,9.324672"
  },
  {
    "Gas Station Number": "0F477",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Leipheimer Straße 34",
    "Postcode": 89233,
    "City": "Neu-Ulm",
    "State": "Bayern",
    "Latitude": "048.4044060",
    "Longitude": "010.0339400",
    "Telephone Number": "0731711295",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.404406,10.03394",
    "IsOpen24Hours": false,
    "id": "48.404406,10.03394"
  },
  {
    "Gas Station Number": "0F478",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Haller Straße 18",
    "Postcode": 74248,
    "City": "Ellhofen",
    "State": "Baden-Württemberg",
    "Latitude": "049.1488110",
    "Longitude": "009.3219140",
    "Telephone Number": "07134916854",
    "Week From": "06:30",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "19:00",
    "Sundays From": "09:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "49.148811,9.321914",
    "IsOpen24Hours": false,
    "id": "49.148811,9.321914"
  },
  {
    "Gas Station Number": "0F480",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Crailsheimer Straße 9",
    "Postcode": 74585,
    "City": "Rot am See",
    "State": "Baden-Württemberg",
    "Latitude": "049.2470700",
    "Longitude": "010.0285080",
    "Telephone Number": "079552294",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "07:30",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.24707,10.028508",
    "IsOpen24Hours": false,
    "id": "49.24707,10.028508"
  },
  {
    "Gas Station Number": "0F481",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Herdweg 52",
    "Postcode": 74523,
    "City": "Schwäbisch Hall",
    "State": "Baden-Württemberg",
    "Latitude": "049.0932790",
    "Longitude": "009.8384560",
    "Telephone Number": "079072277",
    "Week From": "06:30",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:30",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "49.093279,9.838456",
    "IsOpen24Hours": false,
    "id": "49.093279,9.838456"
  },
  {
    "Gas Station Number": "0F482",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ludwigsburger Straße 15",
    "Postcode": 74343,
    "City": "Sachsenheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.9596290",
    "Longitude": "009.0689520",
    "Telephone Number": "071476360",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.959629,9.068952",
    "IsOpen24Hours": false,
    "id": "48.959629,9.068952"
  },
  {
    "Gas Station Number": "0F484",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Leonberger Straße 76",
    "Postcode": 71272,
    "City": "Renningen",
    "State": "Baden-Württemberg",
    "Latitude": "048.7679750",
    "Longitude": "008.9442950",
    "Telephone Number": "07159800881",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.767975,8.944295",
    "IsOpen24Hours": false,
    "id": "48.767975,8.944295"
  },
  {
    "Gas Station Number": "0F485",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stuttgarter Straße 75",
    "Postcode": 74348,
    "City": "Lauffen",
    "State": "Baden-Württemberg",
    "Latitude": "049.0703170",
    "Longitude": "009.1580540",
    "Telephone Number": "071336000",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.070317,9.158054",
    "IsOpen24Hours": true,
    "id": "49.070317,9.158054"
  },
  {
    "Gas Station Number": "0F488",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gosbertstraße 24",
    "Postcode": 72108,
    "City": "Rottenburg",
    "State": "Baden-Württemberg",
    "Latitude": "048.4956690",
    "Longitude": "008.8130090",
    "Telephone Number": "0745791655",
    "Week From": "06:30",
    "Week To": "20:00",
    "Saturdays From": "06:30",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "48.495669,8.813009",
    "IsOpen24Hours": false,
    "id": "48.495669,8.813009"
  },
  {
    "Gas Station Number": "0F489",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Jakob-Schüle-Straße 60",
    "Postcode": 73655,
    "City": "Plüderhausen",
    "State": "Baden-Württemberg",
    "Latitude": "048.8018360",
    "Longitude": "009.5917050",
    "Telephone Number": "071819940325",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.801836,9.591705",
    "IsOpen24Hours": false,
    "id": "48.801836,9.591705"
  },
  {
    "Gas Station Number": "0F492",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Laizerstraße 3",
    "Postcode": 72488,
    "City": "Sigmaringen",
    "State": "Baden-Württemberg",
    "Latitude": "048.0868570",
    "Longitude": "009.2119070",
    "Telephone Number": "075713198",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.086857,9.211907",
    "IsOpen24Hours": false,
    "id": "48.086857,9.211907"
  },
  {
    "Gas Station Number": "0F494",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sigmaringer Straße 40",
    "Postcode": 72501,
    "City": "Gammertingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.2461590",
    "Longitude": "009.2228720",
    "Telephone Number": "075749350076",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.246159,9.222872",
    "IsOpen24Hours": false,
    "id": "48.246159,9.222872"
  },
  {
    "Gas Station Number": "0F495",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ritterstraße14",
    "Postcode": 73650,
    "City": "Winterbach",
    "State": "Baden-Württemberg",
    "Latitude": "048.8048790",
    "Longitude": "009.4788210",
    "Telephone Number": "0718141154",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "08:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "48.804879,9.478821",
    "IsOpen24Hours": false,
    "id": "48.804879,9.478821"
  },
  {
    "Gas Station Number": "0F496",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 30",
    "Postcode": 88427,
    "City": "Bad Schussenried",
    "State": "Baden-Württemberg",
    "Latitude": "048.0002510",
    "Longitude": "009.6601360",
    "Telephone Number": "075832413",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.000251,9.660136",
    "IsOpen24Hours": false,
    "id": "48.000251,9.660136"
  },
  {
    "Gas Station Number": "0F497",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neckarsulmer Straße 91",
    "Postcode": 74076,
    "City": "Heilbronn",
    "State": "Baden-Württemberg",
    "Latitude": "049.1564030",
    "Longitude": "009.2242440",
    "Telephone Number": "07131953061",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.156403,9.224244",
    "IsOpen24Hours": true,
    "id": "49.156403,9.224244"
  },
  {
    "Gas Station Number": "0F498",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ehinger Straße 11",
    "Postcode": 89601,
    "City": "Schelklingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.3708290",
    "Longitude": "009.7326290",
    "Telephone Number": "073942346",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.370829,9.732629",
    "IsOpen24Hours": false,
    "id": "48.370829,9.732629"
  },
  {
    "Gas Station Number": "0F499",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schwieberdinger Straße 70",
    "Postcode": 71636,
    "City": "Ludwigsburg",
    "State": "Baden-Württemberg",
    "Latitude": "048.8905330",
    "Longitude": "009.1738350",
    "Telephone Number": "07141462453",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.890533,9.173835",
    "IsOpen24Hours": true,
    "id": "48.890533,9.173835"
  },
  {
    "Gas Station Number": "0F017",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Siemensstraße 21",
    "Postcode": 71254,
    "City": "Ditzingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.8229020",
    "Longitude": "009.0694220",
    "Telephone Number": "071566437",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.822902,9.069422",
    "IsOpen24Hours": true,
    "id": "48.822902,9.069422"
  },
  {
    "Gas Station Number": "0F500",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Marbacher Straße 36",
    "Postcode": 74385,
    "City": "Pleidelsheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.9592200",
    "Longitude": "009.2101520",
    "Telephone Number": "07144281777",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.95922,9.210152",
    "IsOpen24Hours": true,
    "id": "48.95922,9.210152"
  },
  {
    "Gas Station Number": "0F501",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kirchheimer Straße 108",
    "Postcode": 70619,
    "City": "Stuttgart",
    "State": "Baden-Württemberg",
    "Latitude": "048.7419360",
    "Longitude": "009.2173380",
    "Telephone Number": "07114799609",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.741936,9.217338",
    "IsOpen24Hours": false,
    "id": "48.741936,9.217338"
  },
  {
    "Gas Station Number": "0F503",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ulmer Straße 13",
    "Postcode": 89264,
    "City": "Weißenhorn",
    "State": "Bayern",
    "Latitude": "048.3075090",
    "Longitude": "010.1524890",
    "Telephone Number": "073092751",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.307509,10.152489",
    "IsOpen24Hours": true,
    "id": "48.307509,10.152489"
  },
  {
    "Gas Station Number": "0F504",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Weilimdorfer Straße 25",
    "Postcode": 70839,
    "City": "Gerlingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.8009040",
    "Longitude": "009.0668100",
    "Telephone Number": "0715621349",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.800904,9.06681",
    "IsOpen24Hours": false,
    "id": "48.800904,9.06681"
  },
  {
    "Gas Station Number": "0F505",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wiener Straße 39",
    "Postcode": 70469,
    "City": "Stuttgart",
    "State": "Baden-Württemberg",
    "Latitude": "048.8132310",
    "Longitude": "009.1651550",
    "Telephone Number": "07111353230",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.813231,9.165155",
    "IsOpen24Hours": true,
    "id": "48.813231,9.165155"
  },
  {
    "Gas Station Number": "0F506",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Esslinger Straße 43",
    "Postcode": 73776,
    "City": "Altbach",
    "State": "Baden-Württemberg",
    "Latitude": "048.7236480",
    "Longitude": "009.3777170",
    "Telephone Number": "0715327370",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.723648,9.377717",
    "IsOpen24Hours": false,
    "id": "48.723648,9.377717"
  },
  {
    "Gas Station Number": "0F508",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wieslauftalstraße 72",
    "Postcode": 73614,
    "City": "Schorndorf",
    "State": "Baden-Württemberg",
    "Latitude": "048.8269940",
    "Longitude": "009.5499570",
    "Telephone Number": "071815423",
    "Week From": "06:00",
    "Week To": "19:00",
    "Saturdays From": "07:30",
    "Saturdays To": "17:00",
    "Sundays From": "09:00",
    "Sundays To": "12:30",
    "24 hour?": "Nein",
    "location": "48.826994,9.549957",
    "IsOpen24Hours": false,
    "id": "48.826994,9.549957"
  },
  {
    "Gas Station Number": "0F507",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schillerstraße 34",
    "Postcode": 73312,
    "City": "Geislingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.6097570",
    "Longitude": "009.8426130",
    "Telephone Number": "0733142173",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.609757,9.842613",
    "IsOpen24Hours": true,
    "id": "48.609757,9.842613"
  },
  {
    "Gas Station Number": "0F509",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kemptener Straße 61",
    "Postcode": 89250,
    "City": "Senden",
    "State": "Bayern",
    "Latitude": "048.3202870",
    "Longitude": "010.0496290",
    "Telephone Number": "073075217",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.320287,10.049629",
    "IsOpen24Hours": true,
    "id": "48.320287,10.049629"
  },
  {
    "Gas Station Number": "0F510",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stuttgarter Straße 67",
    "Postcode": 71384,
    "City": "Weinstadt",
    "State": "Baden-Württemberg",
    "Latitude": "048.8053270",
    "Longitude": "009.3819610",
    "Telephone Number": "0715165446",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.805327,9.381961",
    "IsOpen24Hours": false,
    "id": "48.805327,9.381961"
  },
  {
    "Gas Station Number": "0F511",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hohenheimer Straße 49",
    "Postcode": 70794,
    "City": "Filderstadt",
    "State": "Baden-Württemberg",
    "Latitude": "048.6593770",
    "Longitude": "009.1977650",
    "Telephone Number": "07117705710",
    "Week From": "07:00",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.659377,9.197765",
    "IsOpen24Hours": false,
    "id": "48.659377,9.197765"
  },
  {
    "Gas Station Number": "0F512",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stuttgarter Straße 36",
    "Postcode": 73614,
    "City": "Schorndorf",
    "State": "Baden-Württemberg",
    "Latitude": "048.8056990",
    "Longitude": "009.5150020",
    "Telephone Number": "07181979888",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.805699,9.515002",
    "IsOpen24Hours": true,
    "id": "48.805699,9.515002"
  },
  {
    "Gas Station Number": "0F513",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 51",
    "Postcode": 71706,
    "City": "Markgröningen",
    "State": "Baden-Württemberg",
    "Latitude": "048.9050160",
    "Longitude": "009.0910170",
    "Telephone Number": "071454642",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.905016,9.091017",
    "IsOpen24Hours": false,
    "id": "48.905016,9.091017"
  },
  {
    "Gas Station Number": "0F514",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ludwigsburger Straße 20",
    "Postcode": 71696,
    "City": "Möglingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.8877790",
    "Longitude": "009.1286910",
    "Telephone Number": "07141484326",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.887779,9.128691",
    "IsOpen24Hours": false,
    "id": "48.887779,9.128691"
  },
  {
    "Gas Station Number": "0F515",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 84",
    "Postcode": 72411,
    "City": "Bodelshausen",
    "State": "Baden-Württemberg",
    "Latitude": "048.3873210",
    "Longitude": "008.9790320",
    "Telephone Number": "074717511",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "48.387321,8.979032",
    "IsOpen24Hours": false,
    "id": "48.387321,8.979032"
  },
  {
    "Gas Station Number": "0F516",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 18",
    "Postcode": 73072,
    "City": "Donzdorf",
    "State": "Baden-Württemberg",
    "Latitude": "048.6859770",
    "Longitude": "009.8038480",
    "Telephone Number": "0716229869",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.685977,9.803848",
    "IsOpen24Hours": false,
    "id": "48.685977,9.803848"
  },
  {
    "Gas Station Number": "0F517",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Messkircher Straße 2a",
    "Postcode": 72488,
    "City": "Sigmaringen",
    "State": "Baden-Württemberg",
    "Latitude": "048.0756340",
    "Longitude": "009.1934970",
    "Telephone Number": "0757151988",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.075634,9.193497",
    "IsOpen24Hours": false,
    "id": "48.075634,9.193497"
  },
  {
    "Gas Station Number": "0F518",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mahdentalstraße 86",
    "Postcode": 71065,
    "City": "Sindelfingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.7067910",
    "Longitude": "009.0279860",
    "Telephone Number": "07031811966",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.706791,9.027986",
    "IsOpen24Hours": true,
    "id": "48.706791,9.027986"
  },
  {
    "Gas Station Number": "0F519",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berliner Straße 55",
    "Postcode": 72458,
    "City": "Albstadt",
    "State": "Baden-Württemberg",
    "Latitude": "048.2056030",
    "Longitude": "009.0371560",
    "Telephone Number": "074312697",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.205603,9.037156",
    "IsOpen24Hours": true,
    "id": "48.205603,9.037156"
  },
  {
    "Gas Station Number": "0F520",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kiesstraße 4-6",
    "Postcode": 74348,
    "City": "Lauffen",
    "State": "Baden-Württemberg",
    "Latitude": "049.0770720",
    "Longitude": "009.1529360",
    "Telephone Number": "071337261",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "19:00",
    "Sundays From": "08:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "49.077072,9.152936",
    "IsOpen24Hours": false,
    "id": "49.077072,9.152936"
  },
  {
    "Gas Station Number": "0F521",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heilbronner Straße 70",
    "Postcode": 74363,
    "City": "Güglingen",
    "State": "Baden-Württemberg",
    "Latitude": "049.0664030",
    "Longitude": "009.0113040",
    "Telephone Number": "071358420",
    "Week From": "06:30",
    "Week To": "19:30",
    "Saturdays From": "07:00",
    "Saturdays To": "19:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.066403,9.011304",
    "IsOpen24Hours": false,
    "id": "49.066403,9.011304"
  },
  {
    "Gas Station Number": "0F522",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Marbacher Straße 21",
    "Postcode": 71729,
    "City": "Erdmannhausen",
    "State": "Baden-Württemberg",
    "Latitude": "048.9418890",
    "Longitude": "009.2911320",
    "Telephone Number": "071446651",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.941889,9.291132",
    "IsOpen24Hours": false,
    "id": "48.941889,9.291132"
  },
  {
    "Gas Station Number": "0F525",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lindauer Straße 50",
    "Postcode": 88316,
    "City": "Isny",
    "State": "Baden-Württemberg",
    "Latitude": "047.6896000",
    "Longitude": "010.0328560",
    "Telephone Number": "075628117",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.6896,10.032856",
    "IsOpen24Hours": true,
    "id": "47.6896,10.032856"
  },
  {
    "Gas Station Number": "0F526",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stuttgarter Straße43",
    "Postcode": 71083,
    "City": "Herrenberg",
    "State": "Baden-Württemberg",
    "Latitude": "048.6005530",
    "Longitude": "008.8730950",
    "Telephone Number": "0703224366",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.600553,8.873095",
    "IsOpen24Hours": true,
    "id": "48.600553,8.873095"
  },
  {
    "Gas Station Number": "0F528",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stuttgarter Straße 97A",
    "Postcode": 73430,
    "City": "Aalen",
    "State": "Baden-Württemberg",
    "Latitude": "048.8320230",
    "Longitude": "010.0879910",
    "Telephone Number": "0736164525",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.832023,10.087991",
    "IsOpen24Hours": true,
    "id": "48.832023,10.087991"
  },
  {
    "Gas Station Number": "0F530",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Löchgauer Straße 77-79",
    "Postcode": 74321,
    "City": "Bietigheim-Bissingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.9621100",
    "Longitude": "009.1219240",
    "Telephone Number": "07142987935",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.96211,9.121924",
    "IsOpen24Hours": false,
    "id": "48.96211,9.121924"
  },
  {
    "Gas Station Number": "0F531",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 159",
    "Postcode": 70771,
    "City": "Leinfelden-Echterdin",
    "State": "Baden-Württemberg",
    "Latitude": "048.6967060",
    "Longitude": "009.1666950",
    "Telephone Number": "0711793779",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.696706,9.166695",
    "IsOpen24Hours": true,
    "id": "48.696706,9.166695"
  },
  {
    "Gas Station Number": "0F533",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Haller Straße 5",
    "Postcode": 74582,
    "City": "Gerabronn",
    "State": "Baden-Württemberg",
    "Latitude": "049.2478070",
    "Longitude": "009.9200460",
    "Telephone Number": "079525059",
    "Week From": "07:00",
    "Week To": "19:00",
    "Saturdays From": "08:00",
    "Saturdays To": "19:00",
    "Sundays From": "08:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "49.247807,9.920046",
    "IsOpen24Hours": false,
    "id": "49.247807,9.920046"
  },
  {
    "Gas Station Number": "0F535",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Albstraße 82",
    "Postcode": 72764,
    "City": "Reutlingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.4850590",
    "Longitude": "009.2225000",
    "Telephone Number": "07121270312",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.485059,9.2225",
    "IsOpen24Hours": true,
    "id": "48.485059,9.2225"
  },
  {
    "Gas Station Number": "0F536",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstr. 156",
    "Postcode": 70563,
    "City": "Stuttgart",
    "State": "Baden-Württemberg",
    "Latitude": "048.7309370",
    "Longitude": "009.0953620",
    "Telephone Number": "0711731748",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.730937,9.095362",
    "IsOpen24Hours": false,
    "id": "48.730937,9.095362"
  },
  {
    "Gas Station Number": "0F537",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wilhelm-Kraut-Straße111",
    "Postcode": 72336,
    "City": "Balingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.2642030",
    "Longitude": "008.8524850",
    "Telephone Number": "074334278",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.264203,8.852485",
    "IsOpen24Hours": true,
    "id": "48.264203,8.852485"
  },
  {
    "Gas Station Number": "0F538",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ernst-Haller-Straße 62",
    "Postcode": 78647,
    "City": "Trossingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.0667720",
    "Longitude": "008.6390780",
    "Telephone Number": "074256197",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "09:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.066772,8.639078",
    "IsOpen24Hours": false,
    "id": "48.066772,8.639078"
  },
  {
    "Gas Station Number": "0F539",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nürtinger Straße 49",
    "Postcode": 72649,
    "City": "Wolfschlugen",
    "State": "Baden-Württemberg",
    "Latitude": "048.6511580",
    "Longitude": "009.2955940",
    "Telephone Number": "07022953580",
    "Week From": "06:30",
    "Week To": "21:00",
    "Saturdays From": "06:30",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.651158,9.295594",
    "IsOpen24Hours": false,
    "id": "48.651158,9.295594"
  },
  {
    "Gas Station Number": "0F541",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bundesstraße 19A",
    "Postcode": 77652,
    "City": "Offenburg",
    "State": "Baden-Württemberg",
    "Latitude": "048.4904970",
    "Longitude": "007.9509690",
    "Telephone Number": "078125793",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.490497,7.950969",
    "IsOpen24Hours": false,
    "id": "48.490497,7.950969"
  },
  {
    "Gas Station Number": "0F540",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bestenheider Landstraße 7",
    "Postcode": 97877,
    "City": "Wertheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.7817130",
    "Longitude": "009.5040710",
    "Telephone Number": "093429347282",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.781713,9.504071",
    "IsOpen24Hours": false,
    "id": "49.781713,9.504071"
  },
  {
    "Gas Station Number": "0F542",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berghauptener Str. 21",
    "Postcode": 77723,
    "City": "Gengenbach",
    "State": "Baden-Württemberg",
    "Latitude": "048.4000530",
    "Longitude": "008.0074240",
    "Telephone Number": "07803922517",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.400053,8.007424",
    "IsOpen24Hours": true,
    "id": "48.400053,8.007424"
  },
  {
    "Gas Station Number": "0F543",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Vaihinger Straße 70",
    "Postcode": 70567,
    "City": "Stuttgart",
    "State": "Baden-Württemberg",
    "Latitude": "048.7277440",
    "Longitude": "009.1426740",
    "Telephone Number": "0711719300",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "48.727744,9.142674",
    "IsOpen24Hours": false,
    "id": "48.727744,9.142674"
  },
  {
    "Gas Station Number": "0F544",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Holger-Crafoord-Straße 1",
    "Postcode": 72379,
    "City": "Hechingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.3520990",
    "Longitude": "008.9756890",
    "Telephone Number": "074714950",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.352099,8.975689",
    "IsOpen24Hours": true,
    "id": "48.352099,8.975689"
  },
  {
    "Gas Station Number": "0F545",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Herrenberger Straße 50",
    "Postcode": 71034,
    "City": "Böblingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.6830290",
    "Longitude": "009.0015260",
    "Telephone Number": "07031225353",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.683029,9.001526",
    "IsOpen24Hours": true,
    "id": "48.683029,9.001526"
  },
  {
    "Gas Station Number": "0F546",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Raiffeisenstraße 1",
    "Postcode": 89079,
    "City": "Ulm",
    "State": "Baden-Württemberg",
    "Latitude": "048.3582420",
    "Longitude": "009.9702910",
    "Telephone Number": "073144488",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.358242,9.970291",
    "IsOpen24Hours": true,
    "id": "48.358242,9.970291"
  },
  {
    "Gas Station Number": "0F547",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nördlinger Straße 72",
    "Postcode": 89520,
    "City": "Heidenheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.6906360",
    "Longitude": "010.1658610",
    "Telephone Number": "0732125375",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.690636,10.165861",
    "IsOpen24Hours": true,
    "id": "48.690636,10.165861"
  },
  {
    "Gas Station Number": "0F548",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Karlsruher Straße 26A",
    "Postcode": 75179,
    "City": "Pforzheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.9020540",
    "Longitude": "008.6610440",
    "Telephone Number": "07231106050",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.902054,8.661044",
    "IsOpen24Hours": true,
    "id": "48.902054,8.661044"
  },
  {
    "Gas Station Number": "0F549",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neuenstädter Straße 96",
    "Postcode": 74172,
    "City": "Neckarsulm",
    "State": "Baden-Württemberg",
    "Latitude": "049.1992710",
    "Longitude": "009.2329500",
    "Telephone Number": "07132980222",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.199271,9.23295",
    "IsOpen24Hours": true,
    "id": "49.199271,9.23295"
  },
  {
    "Gas Station Number": "0F551",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heidenheimer Straße 116",
    "Postcode": 73447,
    "City": "Oberkochen",
    "State": "Baden-Württemberg",
    "Latitude": "048.7756760",
    "Longitude": "010.0988910",
    "Telephone Number": "07364921880",
    "Week From": "06:30",
    "Week To": "21:30",
    "Saturdays From": "07:00",
    "Saturdays To": "21:30",
    "Sundays From": "08:00",
    "Sundays To": "21:30",
    "24 hour?": "Nein",
    "location": "48.775676,10.098891",
    "IsOpen24Hours": false,
    "id": "48.775676,10.098891"
  },
  {
    "Gas Station Number": "0F552",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rudolf-Diesel-Str. 2.",
    "Postcode": 85235,
    "City": "Odelzhausen",
    "State": "Bayern",
    "Latitude": "048.3070890",
    "Longitude": "011.2037790",
    "Telephone Number": "08134555673",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.307089,11.203779",
    "IsOpen24Hours": true,
    "id": "48.307089,11.203779"
  },
  {
    "Gas Station Number": "0F553",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Jahnstraße 40",
    "Postcode": 88214,
    "City": "Ravensburg",
    "State": "Baden-Württemberg",
    "Latitude": "047.7756660",
    "Longitude": "009.6039380",
    "Telephone Number": "07513529743",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.775666,9.603938",
    "IsOpen24Hours": true,
    "id": "47.775666,9.603938"
  },
  {
    "Gas Station Number": "0F554",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Nellinger Straße 24",
    "Postcode": 89188,
    "City": "Merklingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.5161010",
    "Longitude": "009.7596900",
    "Telephone Number": "073379233029",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.516101,9.75969",
    "IsOpen24Hours": true,
    "id": "48.516101,9.75969"
  },
  {
    "Gas Station Number": "0F555",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Robert-Bosch-Straße 9-11",
    "Postcode": 72622,
    "City": "Nürtingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.6156390",
    "Longitude": "009.3315260",
    "Telephone Number": "070229211645",
    "Week From": "06:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "08:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "48.615639,9.331526",
    "IsOpen24Hours": false,
    "id": "48.615639,9.331526"
  },
  {
    "Gas Station Number": "0FE71",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Marie-Curie-Straße 2",
    "Postcode": 73770,
    "City": "Denkendorf",
    "State": "Baden-Württemberg",
    "Latitude": "048.7072590",
    "Longitude": "009.3055270",
    "Telephone Number": "07113482625",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.707259,9.305527",
    "IsOpen24Hours": true,
    "id": "48.707259,9.305527"
  },
  {
    "Gas Station Number": "0F556",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mercedesstraße 51",
    "Postcode": 72108,
    "City": "Rottenburg/Ergenzing",
    "State": "Baden-Württemberg",
    "Latitude": "048.5053050",
    "Longitude": "008.8267440",
    "Telephone Number": "07457931293",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.505305,8.826744",
    "IsOpen24Hours": true,
    "id": "48.505305,8.826744"
  },
  {
    "Gas Station Number": "0FE86",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ulmer Straße 91",
    "Postcode": 88471,
    "City": "Laupheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.2375380",
    "Longitude": "009.8822250",
    "Telephone Number": "07392168623",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.237538,9.882225",
    "IsOpen24Hours": true,
    "id": "48.237538,9.882225"
  },
  {
    "Gas Station Number": "0F557",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Werderstraße 105",
    "Postcode": 79379,
    "City": "Müllheim",
    "State": "Baden-Württemberg",
    "Latitude": "047.8090640",
    "Longitude": "007.6395960",
    "Telephone Number": "076313882",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.809064,7.639596",
    "IsOpen24Hours": true,
    "id": "47.809064,7.639596"
  },
  {
    "Gas Station Number": "0FG63",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Zeppelinweg 2",
    "Postcode": 89542,
    "City": "Herbrechtingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.6276760",
    "Longitude": "010.1871800",
    "Telephone Number": "073249833735",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.627676,10.18718",
    "IsOpen24Hours": true,
    "id": "48.627676,10.18718"
  },
  {
    "Gas Station Number": "0FE91",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Besanconallee 30",
    "Postcode": 79111,
    "City": "Freiburg",
    "State": "Baden-Württemberg",
    "Latitude": "047.9906620",
    "Longitude": "007.7974610",
    "Telephone Number": "076145366413",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.990662,7.797461",
    "IsOpen24Hours": true,
    "id": "47.990662,7.797461"
  },
  {
    "Gas Station Number": "0FG29",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der Tagweide 2",
    "Postcode": 76139,
    "City": "Karlsruhe",
    "State": "Baden-Württemberg",
    "Latitude": "049.0202780",
    "Longitude": "008.4594290",
    "Telephone Number": "0721616522",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "18:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.020278,8.459429",
    "IsOpen24Hours": false,
    "id": "49.020278,8.459429"
  },
  {
    "Gas Station Number": "0F559",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Am Leineufer 52",
    "Postcode": 30419,
    "City": "Hannover",
    "State": "Niedersachsen",
    "Latitude": "052.4185320",
    "Longitude": "009.6353810",
    "Telephone Number": "0511794933",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.418532,9.635381",
    "IsOpen24Hours": true,
    "id": "52.418532,9.635381"
  },
  {
    "Gas Station Number": "0F560",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hannoversche Straße 41-43",
    "Postcode": 29664,
    "City": "Walsrode",
    "State": "Niedersachsen",
    "Latitude": "052.8547270",
    "Longitude": "009.5964440",
    "Telephone Number": "051615971",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Ja",
    "location": "52.854727,9.596444",
    "IsOpen24Hours": true,
    "id": "52.854727,9.596444"
  },
  {
    "Gas Station Number": "0F561",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schwarzer Weg 39",
    "Postcode": 31224,
    "City": "Peine",
    "State": "Niedersachsen",
    "Latitude": "052.3304120",
    "Longitude": "010.2349470",
    "Telephone Number": "051716108",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.330412,10.234947",
    "IsOpen24Hours": true,
    "id": "52.330412,10.234947"
  },
  {
    "Gas Station Number": "0F562",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Düshorner Straße 2 D",
    "Postcode": 29683,
    "City": "Fallingbostel",
    "State": "Niedersachsen",
    "Latitude": "052.8646890",
    "Longitude": "009.6907160",
    "Telephone Number": "051622183",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.864689,9.690716",
    "IsOpen24Hours": false,
    "id": "52.864689,9.690716"
  },
  {
    "Gas Station Number": "0F563",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Messeschnellweg-West",
    "Postcode": 30521,
    "City": "Hannover",
    "State": "Niedersachsen",
    "Latitude": "052.3296130",
    "Longitude": "009.8121870",
    "Telephone Number": "0511871830",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.329613,9.812187",
    "IsOpen24Hours": true,
    "id": "52.329613,9.812187"
  },
  {
    "Gas Station Number": "0F564",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Auf der Bünte 1",
    "Postcode": 31737,
    "City": "Rinteln",
    "State": "Niedersachsen",
    "Latitude": "052.1943340",
    "Longitude": "009.0813670",
    "Telephone Number": "057515275",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.194334,9.081367",
    "IsOpen24Hours": false,
    "id": "52.194334,9.081367"
  },
  {
    "Gas Station Number": "0F565",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Vornhäger Straße 35",
    "Postcode": 31655,
    "City": "Stadthagen",
    "State": "Niedersachsen",
    "Latitude": "052.3279770",
    "Longitude": "009.2173700",
    "Telephone Number": "057213360",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.327977,9.21737",
    "IsOpen24Hours": true,
    "id": "52.327977,9.21737"
  },
  {
    "Gas Station Number": "0F566",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Iltener Straße 8",
    "Postcode": 31319,
    "City": "Sehnde",
    "State": "Niedersachsen",
    "Latitude": "052.3173620",
    "Longitude": "009.9599050",
    "Telephone Number": "051384746",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.317362,9.959905",
    "IsOpen24Hours": true,
    "id": "52.317362,9.959905"
  },
  {
    "Gas Station Number": "0F567",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alte Heerstraße 18",
    "Postcode": 31863,
    "City": "Coppenbrügge",
    "State": "Niedersachsen",
    "Latitude": "052.1159590",
    "Longitude": "009.5552250",
    "Telephone Number": "05156780080",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "52.115959,9.555225",
    "IsOpen24Hours": false,
    "id": "52.115959,9.555225"
  },
  {
    "Gas Station Number": "0F570",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Robert-Koch-Straße 2",
    "Postcode": 30900,
    "City": "Wedemark",
    "State": "Niedersachsen",
    "Latitude": "052.5770710",
    "Longitude": "009.7281230",
    "Telephone Number": "051305845432",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "52.577071,9.728123",
    "IsOpen24Hours": false,
    "id": "52.577071,9.728123"
  },
  {
    "Gas Station Number": "0F569",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hann. Heerstraße 44",
    "Postcode": 29227,
    "City": "Celle",
    "State": "Niedersachsen",
    "Latitude": "052.6092790",
    "Longitude": "010.0758010",
    "Telephone Number": "0514124511",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.609279,10.075801",
    "IsOpen24Hours": true,
    "id": "52.609279,10.075801"
  },
  {
    "Gas Station Number": "0F571",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wunstorfer Straße 64",
    "Postcode": 31535,
    "City": "Neustadt a. Rbge.",
    "State": "Niedersachsen",
    "Latitude": "052.4953800",
    "Longitude": "009.4562420",
    "Telephone Number": "0503263993",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.49538,9.456242",
    "IsOpen24Hours": false,
    "id": "52.49538,9.456242"
  },
  {
    "Gas Station Number": "0F572",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 25",
    "Postcode": 31079,
    "City": "Sibbesse",
    "State": "Niedersachsen",
    "Latitude": "052.0548750",
    "Longitude": "009.9028590",
    "Telephone Number": "05065415",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.054875,9.902859",
    "IsOpen24Hours": false,
    "id": "52.054875,9.902859"
  },
  {
    "Gas Station Number": "0F573",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Göttinger Chaussee  80",
    "Postcode": 30459,
    "City": "Hannover",
    "State": "Niedersachsen",
    "Latitude": "052.3457380",
    "Longitude": "009.7169030",
    "Telephone Number": "0511421873",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.345738,9.716903",
    "IsOpen24Hours": true,
    "id": "52.345738,9.716903"
  },
  {
    "Gas Station Number": "0F574",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Deisterstraße 13",
    "Postcode": 31785,
    "City": "Hameln",
    "State": "Niedersachsen",
    "Latitude": "052.1043400",
    "Longitude": "009.3658860",
    "Telephone Number": "0515141626",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.10434,9.365886",
    "IsOpen24Hours": true,
    "id": "52.10434,9.365886"
  },
  {
    "Gas Station Number": "0F575",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wagenzeller Straße 14",
    "Postcode": 30855,
    "City": "Langenhagen",
    "State": "Niedersachsen",
    "Latitude": "052.4748110",
    "Longitude": "009.7346290",
    "Telephone Number": "0511778059",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "52.474811,9.734629",
    "IsOpen24Hours": false,
    "id": "52.474811,9.734629"
  },
  {
    "Gas Station Number": "0F577",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Welseder Straße 20",
    "Postcode": 31840,
    "City": "Hessisch Oldendorf",
    "State": "Niedersachsen",
    "Latitude": "052.1711370",
    "Longitude": "009.2380180",
    "Telephone Number": "051524715",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.171137,9.238018",
    "IsOpen24Hours": true,
    "id": "52.171137,9.238018"
  },
  {
    "Gas Station Number": "0F578",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gerhardstraße 54",
    "Postcode": 31241,
    "City": "Ilsede",
    "State": "Niedersachsen",
    "Latitude": "052.2631900",
    "Longitude": "010.2206370",
    "Telephone Number": "051728760",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "05:00",
    "Saturdays To": "23:00",
    "Sundays From": "05:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "52.26319,10.220637",
    "IsOpen24Hours": false,
    "id": "52.26319,10.220637"
  },
  {
    "Gas Station Number": "0F579",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gutenbergstraße 1",
    "Postcode": 30966,
    "City": "Hemmingen",
    "State": "Niedersachsen",
    "Latitude": "052.3227500",
    "Longitude": "009.7249280",
    "Telephone Number": "0511423980",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.32275,9.724928",
    "IsOpen24Hours": true,
    "id": "52.32275,9.724928"
  },
  {
    "Gas Station Number": "0F580",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hamelner Straße23",
    "Postcode": 31855,
    "City": "Aerzen",
    "State": "Niedersachsen",
    "Latitude": "052.0696670",
    "Longitude": "009.3084680",
    "Telephone Number": "051547098328",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.069667,9.308468",
    "IsOpen24Hours": false,
    "id": "52.069667,9.308468"
  },
  {
    "Gas Station Number": "0F581",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Otternhägener Straße 4-6",
    "Postcode": 30826,
    "City": "Garbsen",
    "State": "Niedersachsen",
    "Latitude": "052.4617990",
    "Longitude": "009.5342440",
    "Telephone Number": "0513152737",
    "Week From": "06:00",
    "Week To": "20:55",
    "Saturdays From": "07:00",
    "Saturdays To": "20:55",
    "Sundays From": "08:00",
    "Sundays To": "20:55",
    "24 hour?": "Nein",
    "location": "52.461799,9.534244",
    "IsOpen24Hours": false,
    "id": "52.461799,9.534244"
  },
  {
    "Gas Station Number": "0F582",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A7",
    "Postcode": 29690,
    "City": "Essel",
    "State": "Niedersachsen",
    "Latitude": "052.6958230",
    "Longitude": "009.6854610",
    "Telephone Number": "0507196260",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.695823,9.685461",
    "IsOpen24Hours": true,
    "id": "52.695823,9.685461"
  },
  {
    "Gas Station Number": "0F583",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Calenberger Allee 1",
    "Postcode": 31020,
    "City": "Salzhemmendorf",
    "State": "Niedersachsen",
    "Latitude": "052.0680240",
    "Longitude": "009.5849180",
    "Telephone Number": "051535262",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "52.068024,9.584918",
    "IsOpen24Hours": false,
    "id": "52.068024,9.584918"
  },
  {
    "Gas Station Number": "0F602",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bevenroder Straße 24",
    "Postcode": 38108,
    "City": "Braunschweig",
    "State": "Niedersachsen",
    "Latitude": "052.2896600",
    "Longitude": "010.5616110",
    "Telephone Number": "0531371472",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "52.28966,10.561611",
    "IsOpen24Hours": false,
    "id": "52.28966,10.561611"
  },
  {
    "Gas Station Number": "0F603",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wolfsburger Straße 41",
    "Postcode": 38448,
    "City": "Wolfsburg",
    "State": "Niedersachsen",
    "Latitude": "052.4382750",
    "Longitude": "010.8303180",
    "Telephone Number": "0536373136",
    "Week From": "04:30",
    "Week To": "22:00",
    "Saturdays From": "04:30",
    "Saturdays To": "22:00",
    "Sundays From": "05:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.438275,10.830318",
    "IsOpen24Hours": false,
    "id": "52.438275,10.830318"
  },
  {
    "Gas Station Number": "0F604",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Marientaler Straße 52 b",
    "Postcode": 38350,
    "City": "Helmstedt",
    "State": "Niedersachsen",
    "Latitude": "052.2387070",
    "Longitude": "011.0001500",
    "Telephone Number": "0535134766",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.238707,11.00015",
    "IsOpen24Hours": false,
    "id": "52.238707,11.00015"
  },
  {
    "Gas Station Number": "0F584",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hannoversche Straße 52",
    "Postcode": 30855,
    "City": "Langenhagen",
    "State": "Niedersachsen",
    "Latitude": "052.4365440",
    "Longitude": "009.6774260",
    "Telephone Number": "0511784433",
    "Week From": "05:30",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "52.436544,9.677426",
    "IsOpen24Hours": false,
    "id": "52.436544,9.677426"
  },
  {
    "Gas Station Number": "0F585",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Fuhrberger Landstraße 39",
    "Postcode": 29225,
    "City": "Celle",
    "State": "Niedersachsen",
    "Latitude": "052.6047070",
    "Longitude": "010.0187570",
    "Telephone Number": "0514141463",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.604707,10.018757",
    "IsOpen24Hours": true,
    "id": "52.604707,10.018757"
  },
  {
    "Gas Station Number": "0F586",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Pyrmonter Straße 32",
    "Postcode": 31789,
    "City": "Hameln",
    "State": "Niedersachsen",
    "Latitude": "052.0984970",
    "Longitude": "009.3506250",
    "Telephone Number": "05151980890",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "52.098497,9.350625",
    "IsOpen24Hours": false,
    "id": "52.098497,9.350625"
  },
  {
    "Gas Station Number": "0F587",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dorfstraße 7",
    "Postcode": 31303,
    "City": "Burgdorf",
    "State": "Niedersachsen",
    "Latitude": "052.4444090",
    "Longitude": "009.9819650",
    "Telephone Number": "051363920",
    "Week From": "04:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.444409,9.981965",
    "IsOpen24Hours": false,
    "id": "52.444409,9.981965"
  },
  {
    "Gas Station Number": "0F588",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Messeschnellweg Ost",
    "Postcode": 30539,
    "City": "Hannover",
    "State": "Niedersachsen",
    "Latitude": "052.3228200",
    "Longitude": "009.8141340",
    "Telephone Number": "0511861335",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.32282,9.814134",
    "IsOpen24Hours": true,
    "id": "52.32282,9.814134"
  },
  {
    "Gas Station Number": "0F589",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Auf der Horst 64",
    "Postcode": 30823,
    "City": "Garbsen",
    "State": "Niedersachsen",
    "Latitude": "052.4212190",
    "Longitude": "009.5954940",
    "Telephone Number": "0513771234",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.421219,9.595494",
    "IsOpen24Hours": false,
    "id": "52.421219,9.595494"
  },
  {
    "Gas Station Number": "0F590",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Celler Straße 57",
    "Postcode": 29308,
    "City": "Winsen",
    "State": "Niedersachsen",
    "Latitude": "052.6764500",
    "Longitude": "009.9200860",
    "Telephone Number": "051438187",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "52.67645,9.920086",
    "IsOpen24Hours": false,
    "id": "52.67645,9.920086"
  },
  {
    "Gas Station Number": "0F591",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lemmier Straße 16 A",
    "Postcode": 30989,
    "City": "Gehrden",
    "State": "Niedersachsen",
    "Latitude": "052.3075700",
    "Longitude": "009.6070530",
    "Telephone Number": "051084884",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.30757,9.607053",
    "IsOpen24Hours": false,
    "id": "52.30757,9.607053"
  },
  {
    "Gas Station Number": "0F594",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lehrter Str. 86 (B 65 Nor",
    "Postcode": 30559,
    "City": "Hannover",
    "State": "Niedersachsen",
    "Latitude": "052.3581180",
    "Longitude": "009.8618770",
    "Telephone Number": "0511527428",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.358118,9.861877",
    "IsOpen24Hours": true,
    "id": "52.358118,9.861877"
  },
  {
    "Gas Station Number": "0F595",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hildesheimer Straße 32",
    "Postcode": 38114,
    "City": "Braunschweig",
    "State": "Niedersachsen",
    "Latitude": "052.2718820",
    "Longitude": "010.4962790",
    "Telephone Number": "053152826",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.271882,10.496279",
    "IsOpen24Hours": true,
    "id": "52.271882,10.496279"
  },
  {
    "Gas Station Number": "0F596",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heinr.-Nordhoff-Str. 117",
    "Postcode": 38440,
    "City": "Wolfsburg",
    "State": "Niedersachsen",
    "Latitude": "052.4271960",
    "Longitude": "010.7715800",
    "Telephone Number": "0536113248",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.427196,10.77158",
    "IsOpen24Hours": true,
    "id": "52.427196,10.77158"
  },
  {
    "Gas Station Number": "0F597",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wolfenbütteler Straße 51",
    "Postcode": 38124,
    "City": "Braunschweig",
    "State": "Niedersachsen",
    "Latitude": "052.2349830",
    "Longitude": "010.5277500",
    "Telephone Number": "0531601278",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.234983,10.52775",
    "IsOpen24Hours": false,
    "id": "52.234983,10.52775"
  },
  {
    "Gas Station Number": "0F598",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schwarzer Weg 1",
    "Postcode": 38170,
    "City": "Schöppenstedt",
    "State": "Niedersachsen",
    "Latitude": "052.1518310",
    "Longitude": "010.7688430",
    "Telephone Number": "053329685327",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.151831,10.768843",
    "IsOpen24Hours": true,
    "id": "52.151831,10.768843"
  },
  {
    "Gas Station Number": "0F599",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Braunschweiger Straße 167",
    "Postcode": 38259,
    "City": "Salzgitter",
    "State": "Niedersachsen",
    "Latitude": "052.0538980",
    "Longitude": "010.3804850",
    "Telephone Number": "0534131260",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.053898,10.380485",
    "IsOpen24Hours": true,
    "id": "52.053898,10.380485"
  },
  {
    "Gas Station Number": "0F600",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wolfshagener Straße 2a",
    "Postcode": 38685,
    "City": "Langelsheim",
    "State": "Niedersachsen",
    "Latitude": "051.9334470",
    "Longitude": "010.3457520",
    "Telephone Number": "0532686086",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.933447,10.345752",
    "IsOpen24Hours": false,
    "id": "51.933447,10.345752"
  },
  {
    "Gas Station Number": "0F605",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Salzdahlumer Straße 188",
    "Postcode": 38126,
    "City": "Braunschweig",
    "State": "Niedersachsen",
    "Latitude": "052.2405070",
    "Longitude": "010.5374160",
    "Telephone Number": "053166961",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.240507,10.537416",
    "IsOpen24Hours": true,
    "id": "52.240507,10.537416"
  },
  {
    "Gas Station Number": "0F606",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lafferder Straße 7",
    "Postcode": 38268,
    "City": "Lengede",
    "State": "Niedersachsen",
    "Latitude": "052.2063600",
    "Longitude": "010.3037910",
    "Telephone Number": "053447666",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "52.20636,10.303791",
    "IsOpen24Hours": false,
    "id": "52.20636,10.303791"
  },
  {
    "Gas Station Number": "0F607",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sackring 8",
    "Postcode": 38118,
    "City": "Braunschweig",
    "State": "Niedersachsen",
    "Latitude": "052.2639960",
    "Longitude": "010.5008520",
    "Telephone Number": "0531577440",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "52.263996,10.500852",
    "IsOpen24Hours": false,
    "id": "52.263996,10.500852"
  },
  {
    "Gas Station Number": "0F608",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berliner Straße 48",
    "Postcode": 38165,
    "City": "Lehre",
    "State": "Niedersachsen",
    "Latitude": "052.3269170",
    "Longitude": "010.6660690",
    "Telephone Number": "053081588",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.326917,10.666069",
    "IsOpen24Hours": false,
    "id": "52.326917,10.666069"
  },
  {
    "Gas Station Number": "0F609",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Adersheimer Straße 25",
    "Postcode": 38304,
    "City": "Wolfenbüttel",
    "State": "Niedersachsen",
    "Latitude": "052.1612530",
    "Longitude": "010.5172950",
    "Telephone Number": "053314738",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.161253,10.517295",
    "IsOpen24Hours": false,
    "id": "52.161253,10.517295"
  },
  {
    "Gas Station Number": "0F610",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Helmstedter Straße 33",
    "Postcode": 38364,
    "City": "Schöningen",
    "State": "Niedersachsen",
    "Latitude": "052.1453060",
    "Longitude": "010.9674130",
    "Telephone Number": "0535259024",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.145306,10.967413",
    "IsOpen24Hours": true,
    "id": "52.145306,10.967413"
  },
  {
    "Gas Station Number": "0F611",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Vor dem Dorfe 30",
    "Postcode": 38229,
    "City": "Salzgitter",
    "State": "Niedersachsen",
    "Latitude": "052.1400240",
    "Longitude": "010.3349230",
    "Telephone Number": "0534141780",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.140024,10.334923",
    "IsOpen24Hours": true,
    "id": "52.140024,10.334923"
  },
  {
    "Gas Station Number": "0F614",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Reiseckenweg 7",
    "Postcode": 38640,
    "City": "Goslar",
    "State": "Niedersachsen",
    "Latitude": "051.9075430",
    "Longitude": "010.4386190",
    "Telephone Number": "0532122292",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.907543,10.438619",
    "IsOpen24Hours": false,
    "id": "51.907543,10.438619"
  },
  {
    "Gas Station Number": "0F615",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "B 65 -Südseite-",
    "Postcode": 30559,
    "City": "Hannover",
    "State": "Niedersachsen",
    "Latitude": "052.3577670",
    "Longitude": "009.8622470",
    "Telephone Number": "05119508955",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.357767,9.862247",
    "IsOpen24Hours": true,
    "id": "52.357767,9.862247"
  },
  {
    "Gas Station Number": "0F016",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kennedydamm 200 Ost",
    "Postcode": 31135,
    "City": "Hildesheim",
    "State": "Niedersachsen",
    "Latitude": "052.1795200",
    "Longitude": "009.9648430",
    "Telephone Number": "0512155951",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.17952,9.964843",
    "IsOpen24Hours": true,
    "id": "52.17952,9.964843"
  },
  {
    "Gas Station Number": "0F617",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Veerßer Straße 98",
    "Postcode": 29525,
    "City": "Uelzen",
    "State": "Niedersachsen",
    "Latitude": "052.9576350",
    "Longitude": "010.5511730",
    "Telephone Number": "058174212",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.957635,10.551173",
    "IsOpen24Hours": true,
    "id": "52.957635,10.551173"
  },
  {
    "Gas Station Number": "0F618",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bodenburger Straße 55",
    "Postcode": 31162,
    "City": "Bad Salzdetfurth",
    "State": "Niedersachsen",
    "Latitude": "052.0542950",
    "Longitude": "010.0027970",
    "Telephone Number": "050632573",
    "Week From": "05:30",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.054295,10.002797",
    "IsOpen24Hours": false,
    "id": "52.054295,10.002797"
  },
  {
    "Gas Station Number": "0F619",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dr.-Heinrich-Jasper-Str.",
    "Postcode": 38667,
    "City": "Bad Harzburg",
    "State": "Niedersachsen",
    "Latitude": "051.8904410",
    "Longitude": "010.5436030",
    "Telephone Number": "0532286471",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.890441,10.543603",
    "IsOpen24Hours": true,
    "id": "51.890441,10.543603"
  },
  {
    "Gas Station Number": "0F620",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hildesheimer Straße 18",
    "Postcode": 38640,
    "City": "Goslar",
    "State": "Niedersachsen",
    "Latitude": "051.9126960",
    "Longitude": "010.4219560",
    "Telephone Number": "0532122135",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.912696,10.421956",
    "IsOpen24Hours": true,
    "id": "51.912696,10.421956"
  },
  {
    "Gas Station Number": "0F622",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Welfenplatz 19",
    "Postcode": 30161,
    "City": "Hannover",
    "State": "Niedersachsen",
    "Latitude": "052.3834310",
    "Longitude": "009.7399190",
    "Telephone Number": "0511314425",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.383431,9.739919",
    "IsOpen24Hours": false,
    "id": "52.383431,9.739919"
  },
  {
    "Gas Station Number": "0F623",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Marienstraße 108",
    "Postcode": 30171,
    "City": "Hannover",
    "State": "Niedersachsen",
    "Latitude": "052.3701880",
    "Longitude": "009.7589190",
    "Telephone Number": "0511858508",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.370188,9.758919",
    "IsOpen24Hours": true,
    "id": "52.370188,9.758919"
  },
  {
    "Gas Station Number": "0F624",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Poststraße 5",
    "Postcode": 38350,
    "City": "Helmstedt",
    "State": "Niedersachsen",
    "Latitude": "052.2243470",
    "Longitude": "011.0138800",
    "Telephone Number": "053517442",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.224347,11.01388",
    "IsOpen24Hours": true,
    "id": "52.224347,11.01388"
  },
  {
    "Gas Station Number": "0F625",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alfelder Straße 99",
    "Postcode": 31139,
    "City": "Hildesheim",
    "State": "Niedersachsen",
    "Latitude": "052.1472310",
    "Longitude": "009.9385220",
    "Telephone Number": "0512143480",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.147231,9.938522",
    "IsOpen24Hours": true,
    "id": "52.147231,9.938522"
  },
  {
    "Gas Station Number": "0F626",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Allersheimer Straße 66",
    "Postcode": 37603,
    "City": "Holzminden",
    "State": "Niedersachsen",
    "Latitude": "051.8365180",
    "Longitude": "009.4635700",
    "Telephone Number": "0553193510",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "06:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "51.836518,9.46357",
    "IsOpen24Hours": false,
    "id": "51.836518,9.46357"
  },
  {
    "Gas Station Number": "0F627",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Burgdorfer Straße 115",
    "Postcode": 31275,
    "City": "Lehrte",
    "State": "Niedersachsen",
    "Latitude": "052.3873990",
    "Longitude": "009.9704790",
    "Telephone Number": "0513282960",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.387399,9.970479",
    "IsOpen24Hours": false,
    "id": "52.387399,9.970479"
  },
  {
    "Gas Station Number": "0F628",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wolfsburger Straße 24",
    "Postcode": 38554,
    "City": "Weyhausen",
    "State": "Niedersachsen",
    "Latitude": "052.4626120",
    "Longitude": "010.7188780",
    "Telephone Number": "053627479",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.462612,10.718878",
    "IsOpen24Hours": true,
    "id": "52.462612,10.718878"
  },
  {
    "Gas Station Number": "0F631",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dahlenburg. Landstraße 34",
    "Postcode": 21337,
    "City": "Lüneburg",
    "State": "Niedersachsen",
    "Latitude": "053.2442340",
    "Longitude": "010.4445900",
    "Telephone Number": "0413151560",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.244234,10.44459",
    "IsOpen24Hours": true,
    "id": "53.244234,10.44459"
  },
  {
    "Gas Station Number": "0F632",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bültenweg 40",
    "Postcode": 38106,
    "City": "Braunschweig",
    "State": "Niedersachsen",
    "Latitude": "052.2814980",
    "Longitude": "010.5369070",
    "Telephone Number": "0531347887",
    "Week From": "05:30",
    "Week To": "22:30",
    "Saturdays From": "07:00",
    "Saturdays To": "22:30",
    "Sundays From": "08:00",
    "Sundays To": "22:30",
    "24 hour?": "Nein",
    "location": "52.281498,10.536907",
    "IsOpen24Hours": false,
    "id": "52.281498,10.536907"
  },
  {
    "Gas Station Number": "0F633",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 58",
    "Postcode": 33102,
    "City": "Paderborn",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.7126690",
    "Longitude": "008.7363300",
    "Telephone Number": "0525137111",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.712669,8.73633",
    "IsOpen24Hours": true,
    "id": "51.712669,8.73633"
  },
  {
    "Gas Station Number": "0F634",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hannoversche Straße 142",
    "Postcode": 28309,
    "City": "Bremen",
    "State": "Bremen",
    "Latitude": "053.0484270",
    "Longitude": "008.8915300",
    "Telephone Number": "0421452290",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.048427,8.89153",
    "IsOpen24Hours": true,
    "id": "53.048427,8.89153"
  },
  {
    "Gas Station Number": "0F635",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Habichtstraße 72-78",
    "Postcode": 22305,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5923070",
    "Longitude": "010.0549160",
    "Telephone Number": "040611041",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.592307,10.054916",
    "IsOpen24Hours": true,
    "id": "53.592307,10.054916"
  },
  {
    "Gas Station Number": "0F636",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Stapelfeldtstraße 4",
    "Postcode": 28237,
    "City": "Bremen",
    "State": "Bremen",
    "Latitude": "053.1110030",
    "Longitude": "008.7532710",
    "Telephone Number": "04216163511",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.111003,8.753271",
    "IsOpen24Hours": true,
    "id": "53.111003,8.753271"
  },
  {
    "Gas Station Number": "0F637",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Herforder Str. 147 - 151",
    "Postcode": 32257,
    "City": "Bünde",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.1849920",
    "Longitude": "008.6042730",
    "Telephone Number": "05223878088",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.184992,8.604273",
    "IsOpen24Hours": true,
    "id": "52.184992,8.604273"
  },
  {
    "Gas Station Number": "0F638",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 205",
    "Postcode": 26639,
    "City": "Wiesmoor",
    "State": "Niedersachsen",
    "Latitude": "053.4094120",
    "Longitude": "007.7279030",
    "Telephone Number": "049445150",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "05:30",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.409412,7.727903",
    "IsOpen24Hours": false,
    "id": "53.409412,7.727903"
  },
  {
    "Gas Station Number": "0F639",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Vahrenwalder Str. 323",
    "Postcode": 30851,
    "City": "Langenhagen",
    "State": "Niedersachsen",
    "Latitude": "052.4238510",
    "Longitude": "009.7323020",
    "Telephone Number": "0511632212",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.423851,9.732302",
    "IsOpen24Hours": true,
    "id": "52.423851,9.732302"
  },
  {
    "Gas Station Number": "0F640",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Albaxer Straße 63",
    "Postcode": 37671,
    "City": "Höxter",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.7860200",
    "Longitude": "009.3870160",
    "Telephone Number": "05271920071",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.78602,9.387016",
    "IsOpen24Hours": true,
    "id": "51.78602,9.387016"
  },
  {
    "Gas Station Number": "0FE78",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Westerlange 2",
    "Postcode": 37154,
    "City": "Northeim",
    "State": "Niedersachsen",
    "Latitude": "051.7309020",
    "Longitude": "009.9728270",
    "Telephone Number": "05551909730",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.730902,9.972827",
    "IsOpen24Hours": true,
    "id": "51.730902,9.972827"
  },
  {
    "Gas Station Number": "0F641",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "B65 / Hauptstraße 1",
    "Postcode": 31683,
    "City": "Obernkirchen",
    "State": "Niedersachsen",
    "Latitude": "052.2842530",
    "Longitude": "009.1343290",
    "Telephone Number": "05724902984",
    "Week From": "00:00",
    "Week To": "22:00",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "52.284253,9.134329",
    "IsOpen24Hours": false,
    "id": "52.284253,9.134329"
  },
  {
    "Gas Station Number": "0F642",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kirchhorster Straße 24",
    "Postcode": 30659,
    "City": "Hannover",
    "State": "Niedersachsen",
    "Latitude": "052.4143720",
    "Longitude": "009.8261680",
    "Telephone Number": "05116139075",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.414372,9.826168",
    "IsOpen24Hours": true,
    "id": "52.414372,9.826168"
  },
  {
    "Gas Station Number": "0F643",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedrich-Tietjen-Str. 15",
    "Postcode": 27232,
    "City": "Sulingen",
    "State": "Niedersachsen",
    "Latitude": "052.6857600",
    "Longitude": "008.7830400",
    "Telephone Number": "0427193190",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.68576,8.78304",
    "IsOpen24Hours": true,
    "id": "52.68576,8.78304"
  },
  {
    "Gas Station Number": "0F645",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mindener Straße 17",
    "Postcode": 32602,
    "City": "Vlotho",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.1739330",
    "Longitude": "008.8685170",
    "Telephone Number": "0573380813",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.173933,8.868517",
    "IsOpen24Hours": false,
    "id": "52.173933,8.868517"
  },
  {
    "Gas Station Number": "0F647",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bielefelder Straße 56",
    "Postcode": 32051,
    "City": "Herford",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.1099470",
    "Longitude": "008.6580770",
    "Telephone Number": "05221169742",
    "Week From": "06:00",
    "Week To": "20:30",
    "Saturdays From": "08:00",
    "Saturdays To": "20:30",
    "Sundays From": "08:00",
    "Sundays To": "20:30",
    "24 hour?": "Nein",
    "location": "52.109947,8.658077",
    "IsOpen24Hours": false,
    "id": "52.109947,8.658077"
  },
  {
    "Gas Station Number": "0F648",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 48",
    "Postcode": 49439,
    "City": "Steinfeld",
    "State": "Niedersachsen",
    "Latitude": "052.5902030",
    "Longitude": "008.2032030",
    "Telephone Number": "054927608",
    "Week From": "06:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "08:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "52.590203,8.203203",
    "IsOpen24Hours": false,
    "id": "52.590203,8.203203"
  },
  {
    "Gas Station Number": "0F650",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Petershütter Allee 2",
    "Postcode": 37520,
    "City": "Osterode",
    "State": "Niedersachsen",
    "Latitude": "051.7324110",
    "Longitude": "010.2389260",
    "Telephone Number": "05522317986",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.732411,10.238926",
    "IsOpen24Hours": true,
    "id": "51.732411,10.238926"
  },
  {
    "Gas Station Number": "0FG23",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Hansestraße 47 A",
    "Postcode": 38112,
    "City": "Braunschweig",
    "State": "Niedersachsen",
    "Latitude": "052.3220110",
    "Longitude": "010.4788150",
    "Telephone Number": "05313102198",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.322011,10.478815",
    "IsOpen24Hours": true,
    "id": "52.322011,10.478815"
  },
  {
    "Gas Station Number": "0FG33",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Leipziger Straße 201",
    "Postcode": 37235,
    "City": "Hessisch Lichtenau",
    "State": "Hessen",
    "Latitude": "051.1981170",
    "Longitude": "009.7460100",
    "Telephone Number": "05602914088",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "51.198117,9.74601",
    "IsOpen24Hours": false,
    "id": "51.198117,9.74601"
  },
  {
    "Gas Station Number": "0FE85",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Am Mühlenhop  1",
    "Postcode": 38154,
    "City": "Königslutter",
    "State": "Niedersachsen",
    "Latitude": "052.3115570",
    "Longitude": "010.8238850",
    "Telephone Number": "05365941703",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.311557,10.823885",
    "IsOpen24Hours": true,
    "id": "52.311557,10.823885"
  },
  {
    "Gas Station Number": "0FE73",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lüneburger Straße 14a",
    "Postcode": 30880,
    "City": "Laatzen",
    "State": "Niedersachsen",
    "Latitude": "052.2941300",
    "Longitude": "009.8254850",
    "Telephone Number": "05102916693",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.29413,9.825485",
    "IsOpen24Hours": true,
    "id": "52.29413,9.825485"
  },
  {
    "Gas Station Number": "0F651",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Leuschner Straße 81",
    "Postcode": 34134,
    "City": "Kassel",
    "State": "Hessen",
    "Latitude": "051.2912700",
    "Longitude": "009.4556900",
    "Telephone Number": "0561402572",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "51.29127,9.45569",
    "IsOpen24Hours": false,
    "id": "51.29127,9.45569"
  },
  {
    "Gas Station Number": "0F652",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Oberrege 6",
    "Postcode": 26931,
    "City": "Elsfleth",
    "State": "Niedersachsen",
    "Latitude": "053.2355360",
    "Longitude": "008.4556680",
    "Telephone Number": "04404951488",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.235536,8.455668",
    "IsOpen24Hours": true,
    "id": "53.235536,8.455668"
  },
  {
    "Gas Station Number": "0F653",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sieker Landstraße 128",
    "Postcode": 22143,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.6085490",
    "Longitude": "010.1907140",
    "Telephone Number": "04067563128",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.608549,10.190714",
    "IsOpen24Hours": true,
    "id": "53.608549,10.190714"
  },
  {
    "Gas Station Number": "0F654",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der B5 3",
    "Postcode": 25920,
    "City": "Risum-Lindholm",
    "State": "Schleswig-Holstein",
    "Latitude": "054.7601990",
    "Longitude": "008.8780960",
    "Telephone Number": "04661941159",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "54.760199,8.878096",
    "IsOpen24Hours": false,
    "id": "54.760199,8.878096"
  },
  {
    "Gas Station Number": "0F657",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Korbacherstraße 2",
    "Postcode": 34454,
    "City": "Bad Arolsen",
    "State": "Hessen",
    "Latitude": "051.3811580",
    "Longitude": "008.9989410",
    "Telephone Number": "056913584",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.381158,8.998941",
    "IsOpen24Hours": true,
    "id": "51.381158,8.998941"
  },
  {
    "Gas Station Number": "0F658",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schwanallee 56",
    "Postcode": 35037,
    "City": "Marburg",
    "State": "Hessen",
    "Latitude": "050.7991010",
    "Longitude": "008.7624290",
    "Telephone Number": "06421167089",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.799101,8.762429",
    "IsOpen24Hours": true,
    "id": "50.799101,8.762429"
  },
  {
    "Gas Station Number": "0F659",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bredelarer Straße 50",
    "Postcode": 34431,
    "City": "Marsberg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4554810",
    "Longitude": "008.8406550",
    "Telephone Number": "029928040",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.455481,8.840655",
    "IsOpen24Hours": false,
    "id": "51.455481,8.840655"
  },
  {
    "Gas Station Number": "0F660",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Briloner Straße 47",
    "Postcode": 34508,
    "City": "Willingen",
    "State": "Hessen",
    "Latitude": "051.2939360",
    "Longitude": "008.6047940",
    "Telephone Number": "056326397",
    "Week From": "07:00",
    "Week To": "19:00",
    "Saturdays From": "07:00",
    "Saturdays To": "19:00",
    "Sundays From": "08:30",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "51.293936,8.604794",
    "IsOpen24Hours": false,
    "id": "51.293936,8.604794"
  },
  {
    "Gas Station Number": "0F661",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Burger Hauptstraße 10-12",
    "Postcode": 35745,
    "City": "Herborn-Burg",
    "State": "Hessen",
    "Latitude": "050.6935250",
    "Longitude": "008.3027550",
    "Telephone Number": "027722675",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.693525,8.302755",
    "IsOpen24Hours": false,
    "id": "50.693525,8.302755"
  },
  {
    "Gas Station Number": "0F662",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Königsallee 245",
    "Postcode": 37079,
    "City": "Göttingen",
    "State": "Niedersachsen",
    "Latitude": "051.5487550",
    "Longitude": "009.9094770",
    "Telephone Number": "055162445",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.548755,9.909477",
    "IsOpen24Hours": true,
    "id": "51.548755,9.909477"
  },
  {
    "Gas Station Number": "0F663",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Giessener Straße 29",
    "Postcode": 35423,
    "City": "Lich",
    "State": "Hessen",
    "Latitude": "050.5245350",
    "Longitude": "008.8174280",
    "Telephone Number": "06404950220",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.524535,8.817428",
    "IsOpen24Hours": true,
    "id": "50.524535,8.817428"
  },
  {
    "Gas Station Number": "0F664",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Niestetalstraße 10",
    "Postcode": 34266,
    "City": "Niestetal",
    "State": "Hessen",
    "Latitude": "051.3091050",
    "Longitude": "009.5706910",
    "Telephone Number": "05619209547",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.309105,9.570691",
    "IsOpen24Hours": false,
    "id": "51.309105,9.570691"
  },
  {
    "Gas Station Number": "0F665",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 32",
    "Postcode": 34593,
    "City": "Knüllwald",
    "State": "Hessen",
    "Latitude": "051.0066370",
    "Longitude": "009.4729940",
    "Telephone Number": "056812872",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.006637,9.472994",
    "IsOpen24Hours": true,
    "id": "51.006637,9.472994"
  },
  {
    "Gas Station Number": "0F666",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 85",
    "Postcode": 35236,
    "City": "Breidenbach",
    "State": "Hessen",
    "Latitude": "050.8901150",
    "Longitude": "008.4532100",
    "Telephone Number": "06465913004",
    "Week From": "06:00",
    "Week To": "21:30",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.890115,8.45321",
    "IsOpen24Hours": false,
    "id": "50.890115,8.45321"
  },
  {
    "Gas Station Number": "0F667",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Korbacher Straße 24",
    "Postcode": 34513,
    "City": "Waldeck-Sachsenhause",
    "State": "Hessen",
    "Latitude": "051.2467950",
    "Longitude": "009.0019470",
    "Telephone Number": "056349942479",
    "Week From": "06:00",
    "Week To": "21:30",
    "Saturdays From": "07:00",
    "Saturdays To": "21:30",
    "Sundays From": "08:00",
    "Sundays To": "21:30",
    "24 hour?": "Nein",
    "location": "51.246795,9.001947",
    "IsOpen24Hours": false,
    "id": "51.246795,9.001947"
  },
  {
    "Gas Station Number": "0F668",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hannoversche Str. 45",
    "Postcode": 37075,
    "City": "Göttingen",
    "State": "Niedersachsen",
    "Latitude": "051.5540090",
    "Longitude": "009.9309940",
    "Telephone Number": "055132518",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.554009,9.930994",
    "IsOpen24Hours": true,
    "id": "51.554009,9.930994"
  },
  {
    "Gas Station Number": "0F669",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ederstraße 18",
    "Postcode": 35066,
    "City": "Frankenberg",
    "State": "Hessen",
    "Latitude": "051.0642710",
    "Longitude": "008.8046000",
    "Telephone Number": "064514412",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.064271,8.8046",
    "IsOpen24Hours": false,
    "id": "51.064271,8.8046"
  },
  {
    "Gas Station Number": "0F670",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nürnberger Straße 104",
    "Postcode": 34123,
    "City": "Kassel",
    "State": "Hessen",
    "Latitude": "051.2944640",
    "Longitude": "009.5116550",
    "Telephone Number": "056159182",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.294464,9.511655",
    "IsOpen24Hours": true,
    "id": "51.294464,9.511655"
  },
  {
    "Gas Station Number": "0F671",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schlitzer Straße 105",
    "Postcode": 36039,
    "City": "Fulda",
    "State": "Hessen",
    "Latitude": "050.5655280",
    "Longitude": "009.6572950",
    "Telephone Number": "066151681",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.565528,9.657295",
    "IsOpen24Hours": false,
    "id": "50.565528,9.657295"
  },
  {
    "Gas Station Number": "0F672",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Löhrstraße 6",
    "Postcode": 35708,
    "City": "Haiger",
    "State": "Hessen",
    "Latitude": "050.7415880",
    "Longitude": "008.2076490",
    "Telephone Number": "027735895",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.741588,8.207649",
    "IsOpen24Hours": false,
    "id": "50.741588,8.207649"
  },
  {
    "Gas Station Number": "0F018",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Herzberger Landstraße 3",
    "Postcode": 37434,
    "City": "Gieboldehausen",
    "State": "Niedersachsen",
    "Latitude": "051.6156220",
    "Longitude": "010.2186760",
    "Telephone Number": "05528200891",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.615622,10.218676",
    "IsOpen24Hours": false,
    "id": "51.615622,10.218676"
  },
  {
    "Gas Station Number": "0F673",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hedemündener Straße 10",
    "Postcode": 34346,
    "City": "Hann. Münden",
    "State": "Niedersachsen",
    "Latitude": "051.4178400",
    "Longitude": "009.6677290",
    "Telephone Number": "055411859",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.41784,9.667729",
    "IsOpen24Hours": true,
    "id": "51.41784,9.667729"
  },
  {
    "Gas Station Number": "0F675",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alsfelder Straße 12",
    "Postcode": 36251,
    "City": "Bad Hersfeld",
    "State": "Hessen",
    "Latitude": "050.8361740",
    "Longitude": "009.6686640",
    "Telephone Number": "0662172294",
    "Week From": "05:00",
    "Week To": "19:30",
    "Saturdays From": "07:00",
    "Saturdays To": "19:30",
    "Sundays From": "08:00",
    "Sundays To": "19:30",
    "24 hour?": "Nein",
    "location": "50.836174,9.668664",
    "IsOpen24Hours": false,
    "id": "50.836174,9.668664"
  },
  {
    "Gas Station Number": "0F676",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Karl-Kellner-Ring 2",
    "Postcode": 35576,
    "City": "Wetzlar",
    "State": "Hessen",
    "Latitude": "050.5535140",
    "Longitude": "008.4980470",
    "Telephone Number": "06441921390",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.553514,8.498047",
    "IsOpen24Hours": false,
    "id": "50.553514,8.498047"
  },
  {
    "Gas Station Number": "0F678",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Marburger Straße 28",
    "Postcode": 35315,
    "City": "Homberg",
    "State": "Hessen",
    "Latitude": "050.7308890",
    "Longitude": "008.9946600",
    "Telephone Number": "06633363",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "07:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "50.730889,8.99466",
    "IsOpen24Hours": false,
    "id": "50.730889,8.99466"
  },
  {
    "Gas Station Number": "0F679",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Marburger Str. 15",
    "Postcode": 35745,
    "City": "Herborn",
    "State": "Hessen",
    "Latitude": "050.7004980",
    "Longitude": "008.3375950",
    "Telephone Number": "0277261240",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:30",
    "Saturdays To": "19:00",
    "Sundays From": "09:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "50.700498,8.337595",
    "IsOpen24Hours": false,
    "id": "50.700498,8.337595"
  },
  {
    "Gas Station Number": "0F680",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Marburger Straße 229",
    "Postcode": 35396,
    "City": "Gießen",
    "State": "Hessen",
    "Latitude": "050.6064530",
    "Longitude": "008.6957250",
    "Telephone Number": "06419502133",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.606453,8.695725",
    "IsOpen24Hours": false,
    "id": "50.606453,8.695725"
  },
  {
    "Gas Station Number": "0F681",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Leipziger Straße 266",
    "Postcode": 34260,
    "City": "Kaufungen",
    "State": "Hessen",
    "Latitude": "051.2842210",
    "Longitude": "009.6026280",
    "Telephone Number": "056052524",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.284221,9.602628",
    "IsOpen24Hours": false,
    "id": "51.284221,9.602628"
  },
  {
    "Gas Station Number": "0F682",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Fritzlarer Straße 61",
    "Postcode": 34212,
    "City": "Melsungen",
    "State": "Hessen",
    "Latitude": "051.1289550",
    "Longitude": "009.5374040",
    "Telephone Number": "05661920715",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.128955,9.537404",
    "IsOpen24Hours": false,
    "id": "51.128955,9.537404"
  },
  {
    "Gas Station Number": "0F683",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Langenbergstraße 9",
    "Postcode": 34225,
    "City": "Baunatal",
    "State": "Hessen",
    "Latitude": "051.2560900",
    "Longitude": "009.4132230",
    "Telephone Number": "0561492256",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "07:00",
    "Saturdays To": "23:00",
    "Sundays From": "08:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "51.25609,9.413223",
    "IsOpen24Hours": false,
    "id": "51.25609,9.413223"
  },
  {
    "Gas Station Number": "0F684",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Westerwaldstraße 26",
    "Postcode": 35745,
    "City": "Herborn",
    "State": "Hessen",
    "Latitude": "050.6784650",
    "Longitude": "008.3013500",
    "Telephone Number": "0277282520",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.678465,8.30135",
    "IsOpen24Hours": true,
    "id": "50.678465,8.30135"
  },
  {
    "Gas Station Number": "0F686",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Petersburg  6",
    "Postcode": 35075,
    "City": "Gladenbach",
    "State": "Hessen",
    "Latitude": "050.7532830",
    "Longitude": "008.5469590",
    "Telephone Number": "064621086",
    "Week From": "06:30",
    "Week To": "20:30",
    "Saturdays From": "07:00",
    "Saturdays To": "20:30",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.753283,8.546959",
    "IsOpen24Hours": false,
    "id": "50.753283,8.546959"
  },
  {
    "Gas Station Number": "0F687",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Fuldaer Straße 27",
    "Postcode": 36088,
    "City": "Hünfeld",
    "State": "Hessen",
    "Latitude": "050.6673820",
    "Longitude": "009.7621460",
    "Telephone Number": "066522705",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "05:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "50.667382,9.762146",
    "IsOpen24Hours": false,
    "id": "50.667382,9.762146"
  },
  {
    "Gas Station Number": "0F688",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kohlenstraße 60",
    "Postcode": 34121,
    "City": "Kassel",
    "State": "Hessen",
    "Latitude": "051.3092110",
    "Longitude": "009.4620220",
    "Telephone Number": "056124343",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.309211,9.462022",
    "IsOpen24Hours": false,
    "id": "51.309211,9.462022"
  },
  {
    "Gas Station Number": "0F689",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Herzberger Straße 1",
    "Postcode": 37115,
    "City": "Duderstadt",
    "State": "Niedersachsen",
    "Latitude": "051.5158540",
    "Longitude": "010.2607600",
    "Telephone Number": "055272838",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.515854,10.26076",
    "IsOpen24Hours": false,
    "id": "51.515854,10.26076"
  },
  {
    "Gas Station Number": "0F690",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kasseler Straße 21",
    "Postcode": 35091,
    "City": "Cölbe",
    "State": "Hessen",
    "Latitude": "050.8470070",
    "Longitude": "008.7760210",
    "Telephone Number": "0642182645",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.847007,8.776021",
    "IsOpen24Hours": false,
    "id": "50.847007,8.776021"
  },
  {
    "Gas Station Number": "0F691",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kasseler Straße 32",
    "Postcode": 35683,
    "City": "Dillenburg",
    "State": "Hessen",
    "Latitude": "050.7499560",
    "Longitude": "008.2768470",
    "Telephone Number": "02771812117",
    "Week From": "04:30",
    "Week To": "23:00",
    "Saturdays From": "05:00",
    "Saturdays To": "23:00",
    "Sundays From": "05:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "50.749956,8.276847",
    "IsOpen24Hours": false,
    "id": "50.749956,8.276847"
  },
  {
    "Gas Station Number": "0F692",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Westrandstraße 1",
    "Postcode": 34582,
    "City": "Borken",
    "State": "Hessen",
    "Latitude": "051.0491100",
    "Longitude": "009.2730200",
    "Telephone Number": "056822433",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "08:00",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "51.04911,9.27302",
    "IsOpen24Hours": false,
    "id": "51.04911,9.27302"
  },
  {
    "Gas Station Number": "0F693",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kasseler Straße 53",
    "Postcode": 34253,
    "City": "Lohfelden",
    "State": "Hessen",
    "Latitude": "051.2682220",
    "Longitude": "009.5616360",
    "Telephone Number": "0560891807",
    "Week From": "06:00",
    "Week To": "21:30",
    "Saturdays From": "07:00",
    "Saturdays To": "21:30",
    "Sundays From": "08:00",
    "Sundays To": "21:30",
    "24 hour?": "Nein",
    "location": "51.268222,9.561636",
    "IsOpen24Hours": false,
    "id": "51.268222,9.561636"
  },
  {
    "Gas Station Number": "0F695",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Brückenstraße 2",
    "Postcode": 35638,
    "City": "Leun",
    "State": "Hessen",
    "Latitude": "050.5480850",
    "Longitude": "008.3584710",
    "Telephone Number": "064738434",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.548085,8.358471",
    "IsOpen24Hours": false,
    "id": "50.548085,8.358471"
  },
  {
    "Gas Station Number": "0F696",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "BAB 49/Westseite",
    "Postcode": 34295,
    "City": "Edermünde",
    "State": "Hessen",
    "Latitude": "051.2237650",
    "Longitude": "009.4195530",
    "Telephone Number": "056656388",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.223765,9.419553",
    "IsOpen24Hours": true,
    "id": "51.223765,9.419553"
  },
  {
    "Gas Station Number": "0F698",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Driburger Straße 2",
    "Postcode": 33034,
    "City": "Brakel",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.7125460",
    "Longitude": "009.1779660",
    "Telephone Number": "052723920240",
    "Week From": "06:00",
    "Week To": "23:00",
    "Saturdays From": "08:00",
    "Saturdays To": "23:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.712546,9.177966",
    "IsOpen24Hours": false,
    "id": "51.712546,9.177966"
  },
  {
    "Gas Station Number": "0F699",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Edertalstraße 60",
    "Postcode": 35108,
    "City": "Allendorf",
    "State": "Hessen",
    "Latitude": "051.0215580",
    "Longitude": "008.6630960",
    "Telephone Number": "064529110251",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "19:00",
    "Sundays From": "09:00",
    "Sundays To": "13:00",
    "24 hour?": "Nein",
    "location": "51.021558,8.663096",
    "IsOpen24Hours": false,
    "id": "51.021558,8.663096"
  },
  {
    "Gas Station Number": "0F700",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Möhnestraße 22",
    "Postcode": 59929,
    "City": "Brilon",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4032200",
    "Longitude": "008.5711450",
    "Telephone Number": "029618601",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.40322,8.571145",
    "IsOpen24Hours": false,
    "id": "51.40322,8.571145"
  },
  {
    "Gas Station Number": "0F701",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstrasse 1",
    "Postcode": 38700,
    "City": "Braunlage",
    "State": "Niedersachsen",
    "Latitude": "051.7201890",
    "Longitude": "010.6124920",
    "Telephone Number": "05520923222",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.720189,10.612492",
    "IsOpen24Hours": false,
    "id": "51.720189,10.612492"
  },
  {
    "Gas Station Number": "0F702",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A7",
    "Postcode": 36148,
    "City": "Kalbach",
    "State": "Hessen",
    "Latitude": "050.4197850",
    "Longitude": "009.7354760",
    "Telephone Number": "09742204",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.419785,9.735476",
    "IsOpen24Hours": true,
    "id": "50.419785,9.735476"
  },
  {
    "Gas Station Number": "0F703",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Borchener Straße 42",
    "Postcode": 33098,
    "City": "Paderborn",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.7108600",
    "Longitude": "008.7463930",
    "Telephone Number": "0525172335",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.71086,8.746393",
    "IsOpen24Hours": true,
    "id": "51.71086,8.746393"
  },
  {
    "Gas Station Number": "0F704",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Röddenauer Straße 31",
    "Postcode": 35066,
    "City": "Frankenberg",
    "State": "Hessen",
    "Latitude": "051.0582420",
    "Longitude": "008.7839100",
    "Telephone Number": "064516152",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.058242,8.78391",
    "IsOpen24Hours": true,
    "id": "51.058242,8.78391"
  },
  {
    "Gas Station Number": "0F706",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Reinhäuser Landstraße 108",
    "Postcode": 37083,
    "City": "Göttingen",
    "State": "Niedersachsen",
    "Latitude": "051.5202180",
    "Longitude": "009.9404110",
    "Telephone Number": "055131705",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.520218,9.940411",
    "IsOpen24Hours": false,
    "id": "51.520218,9.940411"
  },
  {
    "Gas Station Number": "0F707",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Weender Landstraße 62A",
    "Postcode": 37075,
    "City": "Göttingen",
    "State": "Niedersachsen",
    "Latitude": "051.5444020",
    "Longitude": "009.9323060",
    "Telephone Number": "055131444",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.544402,9.932306",
    "IsOpen24Hours": true,
    "id": "51.544402,9.932306"
  },
  {
    "Gas Station Number": "0F709",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Veckerhagener Straße19",
    "Postcode": 34233,
    "City": "Fuldatal",
    "State": "Hessen",
    "Latitude": "051.3473650",
    "Longitude": "009.5239220",
    "Telephone Number": "0561811447",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.347365,9.523922",
    "IsOpen24Hours": false,
    "id": "51.347365,9.523922"
  },
  {
    "Gas Station Number": "0F710",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Frankfurter Straße 90",
    "Postcode": 34121,
    "City": "Kassel",
    "State": "Hessen",
    "Latitude": "051.3036970",
    "Longitude": "009.4837750",
    "Telephone Number": "056123104",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.303697,9.483775",
    "IsOpen24Hours": true,
    "id": "51.303697,9.483775"
  },
  {
    "Gas Station Number": "0F711",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Holländische Straße 142",
    "Postcode": 34127,
    "City": "Kassel",
    "State": "Hessen",
    "Latitude": "051.3337410",
    "Longitude": "009.4956710",
    "Telephone Number": "05619893813",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "51.333741,9.495671",
    "IsOpen24Hours": false,
    "id": "51.333741,9.495671"
  },
  {
    "Gas Station Number": "0F712",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Krummbogen 4",
    "Postcode": 35039,
    "City": "Marburg",
    "State": "Hessen",
    "Latitude": "050.8175940",
    "Longitude": "008.7743070",
    "Telephone Number": "0642161348",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.817594,8.774307",
    "IsOpen24Hours": true,
    "id": "50.817594,8.774307"
  },
  {
    "Gas Station Number": "0F713",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Großseelheimer Straße 93",
    "Postcode": 35039,
    "City": "Marburg",
    "State": "Hessen",
    "Latitude": "050.7996060",
    "Longitude": "008.7887180",
    "Telephone Number": "0642124595",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.799606,8.788718",
    "IsOpen24Hours": true,
    "id": "50.799606,8.788718"
  },
  {
    "Gas Station Number": "0F715",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ippinghäuser Straße 10",
    "Postcode": 34466,
    "City": "Wolfhagen",
    "State": "Hessen",
    "Latitude": "051.3234700",
    "Longitude": "009.1737340",
    "Telephone Number": "056929876100",
    "Week From": "07:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "51.32347,9.173734",
    "IsOpen24Hours": false,
    "id": "51.32347,9.173734"
  },
  {
    "Gas Station Number": "0F716",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Geseker Straße 21",
    "Postcode": 33154,
    "City": "Salzkotten",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6682360",
    "Longitude": "008.5940900",
    "Telephone Number": "05258935377",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "09:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.668236,8.59409",
    "IsOpen24Hours": false,
    "id": "51.668236,8.59409"
  },
  {
    "Gas Station Number": "0F717",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Paderborner Straße 22",
    "Postcode": 33161,
    "City": "Hövelhof",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.8187510",
    "Longitude": "008.6592430",
    "Telephone Number": "052572631",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "05:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "51.818751,8.659243",
    "IsOpen24Hours": false,
    "id": "51.818751,8.659243"
  },
  {
    "Gas Station Number": "0F718",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Scharzfelder Str. 6",
    "Postcode": 37412,
    "City": "Herzberg",
    "State": "Niedersachsen",
    "Latitude": "051.6496020",
    "Longitude": "010.3465670",
    "Telephone Number": "055213071",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.649602,10.346567",
    "IsOpen24Hours": true,
    "id": "51.649602,10.346567"
  },
  {
    "Gas Station Number": "0F719",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 116",
    "Postcode": 67346,
    "City": "Speyer",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.3272700",
    "Longitude": "008.4293580",
    "Telephone Number": "0623276218",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.32727,8.429358",
    "IsOpen24Hours": true,
    "id": "49.32727,8.429358"
  },
  {
    "Gas Station Number": "0F720",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Zweibrücker Straße 130",
    "Postcode": 66954,
    "City": "Pirmasens",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.2139390",
    "Longitude": "007.5974540",
    "Telephone Number": "0633165954",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.213939,7.597454",
    "IsOpen24Hours": true,
    "id": "49.213939,7.597454"
  },
  {
    "Gas Station Number": "0F721",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rheinstraße 69",
    "Postcode": 64295,
    "City": "Darmstadt",
    "State": "Hessen",
    "Latitude": "049.8715940",
    "Longitude": "008.6393910",
    "Telephone Number": "06151891309",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.871594,8.639391",
    "IsOpen24Hours": true,
    "id": "49.871594,8.639391"
  },
  {
    "Gas Station Number": "0F722",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bergheimer Straße 90",
    "Postcode": 69115,
    "City": "Heidelberg",
    "State": "Baden-Württemberg",
    "Latitude": "049.4080240",
    "Longitude": "008.6782800",
    "Telephone Number": "06221163048",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.408024,8.67828",
    "IsOpen24Hours": true,
    "id": "49.408024,8.67828"
  },
  {
    "Gas Station Number": "0F723",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nibelungenstraße 197",
    "Postcode": 68642,
    "City": "Bürstadt",
    "State": "Hessen",
    "Latitude": "049.6457250",
    "Longitude": "008.4732770",
    "Telephone Number": "06206963564",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.645725,8.473277",
    "IsOpen24Hours": true,
    "id": "49.645725,8.473277"
  },
  {
    "Gas Station Number": "0F724",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rheinstraße 47-49",
    "Postcode": 67574,
    "City": "Osthofen",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.7046440",
    "Longitude": "008.3334750",
    "Telephone Number": "062425028777",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.704644,8.333475",
    "IsOpen24Hours": false,
    "id": "49.704644,8.333475"
  },
  {
    "Gas Station Number": "0F725",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Saarbrücker Straße 56",
    "Postcode": 67742,
    "City": "Lauterecken",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.6500550",
    "Longitude": "007.5842300",
    "Telephone Number": "063824030325",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.650055,7.58423",
    "IsOpen24Hours": false,
    "id": "49.650055,7.58423"
  },
  {
    "Gas Station Number": "0F726",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 71",
    "Postcode": 67714,
    "City": "Waldfischbach-Burgal",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.2865310",
    "Longitude": "007.6496200",
    "Telephone Number": "06333274825",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.286531,7.64962",
    "IsOpen24Hours": false,
    "id": "49.286531,7.64962"
  },
  {
    "Gas Station Number": "0F727",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schaffhäuser Straße 2-6",
    "Postcode": 55232,
    "City": "Alzey",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.7507780",
    "Longitude": "008.1192110",
    "Telephone Number": "0673142299",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.750778,8.119211",
    "IsOpen24Hours": true,
    "id": "49.750778,8.119211"
  },
  {
    "Gas Station Number": "0F728",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ludwigstraße 21-25",
    "Postcode": 69483,
    "City": "Wald-Michelbach",
    "State": "Hessen",
    "Latitude": "049.5735540",
    "Longitude": "008.8233180",
    "Telephone Number": "06207941718",
    "Week From": "06:30",
    "Week To": "22:00",
    "Saturdays From": "07:30",
    "Saturdays To": "22:00",
    "Sundays From": "09:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.573554,8.823318",
    "IsOpen24Hours": false,
    "id": "49.573554,8.823318"
  },
  {
    "Gas Station Number": "0F731",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 77a",
    "Postcode": 67685,
    "City": "Weilerbach",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.4775540",
    "Longitude": "007.6379790",
    "Telephone Number": "063741285",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "07:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "49.477554,7.637979",
    "IsOpen24Hours": false,
    "id": "49.477554,7.637979"
  },
  {
    "Gas Station Number": "0F732",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Möhlstraße 1",
    "Postcode": 68165,
    "City": "Mannheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.4757100",
    "Longitude": "008.4879580",
    "Telephone Number": "0621407238",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.47571,8.487958",
    "IsOpen24Hours": true,
    "id": "49.47571,8.487958"
  },
  {
    "Gas Station Number": "0F729",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Aschaffenburger Straße 3",
    "Postcode": 64832,
    "City": "Babenhausen",
    "State": "Hessen",
    "Latitude": "049.9574280",
    "Longitude": "008.9580510",
    "Telephone Number": "0607362912",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.957428,8.958051",
    "IsOpen24Hours": false,
    "id": "49.957428,8.958051"
  },
  {
    "Gas Station Number": "0F730",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedrichsdorfer Landstra",
    "Postcode": 69412,
    "City": "Eberbach",
    "State": "Baden-Württemberg",
    "Latitude": "049.4705360",
    "Longitude": "008.9945480",
    "Telephone Number": "06271921821",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.470536,8.994548",
    "IsOpen24Hours": false,
    "id": "49.470536,8.994548"
  },
  {
    "Gas Station Number": "0F733",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Frankfurter Landstr. 257",
    "Postcode": 64291,
    "City": "Darmstadt",
    "State": "Hessen",
    "Latitude": "049.9181920",
    "Longitude": "008.6547590",
    "Telephone Number": "061511015810",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.918192,8.654759",
    "IsOpen24Hours": false,
    "id": "49.918192,8.654759"
  },
  {
    "Gas Station Number": "0F735",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neckarsteinacher Str. 42",
    "Postcode": 69250,
    "City": "Schönau",
    "State": "Baden-Württemberg",
    "Latitude": "049.4299920",
    "Longitude": "008.8122390",
    "Telephone Number": "06228913878",
    "Week From": "06:30",
    "Week To": "20:00",
    "Saturdays From": "07:30",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.429992,8.812239",
    "IsOpen24Hours": false,
    "id": "49.429992,8.812239"
  },
  {
    "Gas Station Number": "0F738",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Speyerer-Straße 105 a",
    "Postcode": 67117,
    "City": "Limburgerhof",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.4219610",
    "Longitude": "008.3940730",
    "Telephone Number": "0623667805",
    "Week From": "04:00",
    "Week To": "23:00",
    "Saturdays From": "05:00",
    "Saturdays To": "23:00",
    "Sundays From": "05:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "49.421961,8.394073",
    "IsOpen24Hours": false,
    "id": "49.421961,8.394073"
  },
  {
    "Gas Station Number": "0F739",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kirchheimer Straße 51-53",
    "Postcode": 67269,
    "City": "Grünstadt",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.5592890",
    "Longitude": "008.1702790",
    "Telephone Number": "063593194",
    "Week From": "04:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "05:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.559289,8.170279",
    "IsOpen24Hours": false,
    "id": "49.559289,8.170279"
  },
  {
    "Gas Station Number": "0F741",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Relaisstraße 1-3",
    "Postcode": 68219,
    "City": "Mannheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.4383930",
    "Longitude": "008.5237710",
    "Telephone Number": "0621891606",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.438393,8.523771",
    "IsOpen24Hours": true,
    "id": "49.438393,8.523771"
  },
  {
    "Gas Station Number": "0F742",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kaiserstraße 110",
    "Postcode": 66849,
    "City": "Landstuhl",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.4140050",
    "Longitude": "007.5824630",
    "Telephone Number": "0637112115",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:30",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.414005,7.582463",
    "IsOpen24Hours": false,
    "id": "49.414005,7.582463"
  },
  {
    "Gas Station Number": "0F743",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bischheimer Straße 9",
    "Postcode": 67292,
    "City": "Kirchheimbolanden",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.6685730",
    "Longitude": "008.0200800",
    "Telephone Number": "063525016",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.668573,8.02008",
    "IsOpen24Hours": true,
    "id": "49.668573,8.02008"
  },
  {
    "Gas Station Number": "0F744",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Frankenthaler Straße 86",
    "Postcode": 68307,
    "City": "Mannheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.5501150",
    "Longitude": "008.4490750",
    "Telephone Number": "0621771755",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.550115,8.449075",
    "IsOpen24Hours": true,
    "id": "49.550115,8.449075"
  },
  {
    "Gas Station Number": "0F745",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Trippstadter Straße 69",
    "Postcode": 67663,
    "City": "Kaiserslautern",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.4337530",
    "Longitude": "007.7574650",
    "Telephone Number": "063128680",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.433753,7.757465",
    "IsOpen24Hours": true,
    "id": "49.433753,7.757465"
  },
  {
    "Gas Station Number": "0F776",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Maximilianstraße 45",
    "Postcode": 76534,
    "City": "Baden-Baden",
    "State": "Baden-Württemberg",
    "Latitude": "048.7498440",
    "Longitude": "008.2545920",
    "Telephone Number": "072217438",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.749844,8.254592",
    "IsOpen24Hours": false,
    "id": "48.749844,8.254592"
  },
  {
    "Gas Station Number": "0F777",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Zeiskamer Straße 63",
    "Postcode": 76756,
    "City": "Bellheim",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.1955790",
    "Longitude": "008.2774980",
    "Telephone Number": "0727293110",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.195579,8.277498",
    "IsOpen24Hours": false,
    "id": "49.195579,8.277498"
  },
  {
    "Gas Station Number": "0F778",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Pforzheimer Straße69",
    "Postcode": 75015,
    "City": "Bretten",
    "State": "Baden-Württemberg",
    "Latitude": "049.0328370",
    "Longitude": "008.7066490",
    "Telephone Number": "072527327",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.032837,8.706649",
    "IsOpen24Hours": true,
    "id": "49.032837,8.706649"
  },
  {
    "Gas Station Number": "0F746",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Niederramstädter Str. 170",
    "Postcode": 64285,
    "City": "Darmstadt",
    "State": "Hessen",
    "Latitude": "049.8564130",
    "Longitude": "008.6692270",
    "Telephone Number": "0615148696",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.856413,8.669227",
    "IsOpen24Hours": true,
    "id": "49.856413,8.669227"
  },
  {
    "Gas Station Number": "0F747",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hochspeyerer-Straße 53",
    "Postcode": 67677,
    "City": "Enkenbach-Alsenborn",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.4836750",
    "Longitude": "007.9004070",
    "Telephone Number": "063034364",
    "Week From": "04:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.483675,7.900407",
    "IsOpen24Hours": false,
    "id": "49.483675,7.900407"
  },
  {
    "Gas Station Number": "0F748",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Darmstädter Straße122",
    "Postcode": 64331,
    "City": "Weiterstadt",
    "State": "Hessen",
    "Latitude": "049.9022500",
    "Longitude": "008.5947870",
    "Telephone Number": "061502190",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.90225,8.594787",
    "IsOpen24Hours": false,
    "id": "49.90225,8.594787"
  },
  {
    "Gas Station Number": "0F749",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 10",
    "Postcode": 67697,
    "City": "Otterberg",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.4992060",
    "Longitude": "007.7727720",
    "Telephone Number": "063019778",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.499206,7.772772",
    "IsOpen24Hours": false,
    "id": "49.499206,7.772772"
  },
  {
    "Gas Station Number": "0F750",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neckarstraße 40",
    "Postcode": 64711,
    "City": "Erbach",
    "State": "Hessen",
    "Latitude": "049.6524220",
    "Longitude": "008.9952970",
    "Telephone Number": "060623565",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.652422,8.995297",
    "IsOpen24Hours": false,
    "id": "49.652422,8.995297"
  },
  {
    "Gas Station Number": "0F751",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heidelberger Straße 9",
    "Postcode": 69207,
    "City": "Sandhausen",
    "State": "Baden-Württemberg",
    "Latitude": "049.3459160",
    "Longitude": "008.6600790",
    "Telephone Number": "062243159",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.345916,8.660079",
    "IsOpen24Hours": false,
    "id": "49.345916,8.660079"
  },
  {
    "Gas Station Number": "0F752",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gaustraße 156",
    "Postcode": 67549,
    "City": "Worms",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.6461550",
    "Longitude": "008.3494390",
    "Telephone Number": "0624152310",
    "Week From": "07:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:30",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.646155,8.349439",
    "IsOpen24Hours": false,
    "id": "49.646155,8.349439"
  },
  {
    "Gas Station Number": "0F753",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lauterstraße 1",
    "Postcode": 67731,
    "City": "Otterbach",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.4828370",
    "Longitude": "007.7308620",
    "Telephone Number": "063019877",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "23:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.482837,7.730862",
    "IsOpen24Hours": false,
    "id": "49.482837,7.730862"
  },
  {
    "Gas Station Number": "0F754",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wormser Straße 53",
    "Postcode": 64625,
    "City": "Bensheim",
    "State": "Hessen",
    "Latitude": "049.6762850",
    "Longitude": "008.6117320",
    "Telephone Number": "062512416",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.676285,8.611732",
    "IsOpen24Hours": true,
    "id": "49.676285,8.611732"
  },
  {
    "Gas Station Number": "0F755",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neustadter Straße 47",
    "Postcode": 68309,
    "City": "Mannheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.4996350",
    "Longitude": "008.5068950",
    "Telephone Number": "0621735151",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.499635,8.506895",
    "IsOpen24Hours": false,
    "id": "49.499635,8.506895"
  },
  {
    "Gas Station Number": "0F756",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Branchweilerhofstraße 104",
    "Postcode": 67433,
    "City": "Neustadt",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.3507100",
    "Longitude": "008.1639820",
    "Telephone Number": "0632112320",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.35071,8.163982",
    "IsOpen24Hours": false,
    "id": "49.35071,8.163982"
  },
  {
    "Gas Station Number": "0F757",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rüdesheimer Straße 114",
    "Postcode": 64295,
    "City": "Darmstadt",
    "State": "Hessen",
    "Latitude": "049.8540190",
    "Longitude": "008.6419890",
    "Telephone Number": "0615163545",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.854019,8.641989",
    "IsOpen24Hours": true,
    "id": "49.854019,8.641989"
  },
  {
    "Gas Station Number": "0F758",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Staatsstraße 101",
    "Postcode": 64668,
    "City": "Rimbach",
    "State": "Hessen",
    "Latitude": "049.6301540",
    "Longitude": "008.7599500",
    "Telephone Number": "062536484",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "05:30",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.630154,8.75995",
    "IsOpen24Hours": false,
    "id": "49.630154,8.75995"
  },
  {
    "Gas Station Number": "0F759",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Saar-Pfalz-Straße",
    "Postcode": 66440,
    "City": "Blieskastel-Aßweiler",
    "State": "Saarland",
    "Latitude": "049.2130160",
    "Longitude": "007.1817370",
    "Telephone Number": "068038433",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.213016,7.181737",
    "IsOpen24Hours": false,
    "id": "49.213016,7.181737"
  },
  {
    "Gas Station Number": "0F760",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Grülingstraße",
    "Postcode": 66280,
    "City": "Sulzbach",
    "State": "Saarland",
    "Latitude": "049.3065510",
    "Longitude": "007.0562070",
    "Telephone Number": "068972307",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.306551,7.056207",
    "IsOpen24Hours": false,
    "id": "49.306551,7.056207"
  },
  {
    "Gas Station Number": "0F761",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alsweiler Straße 50",
    "Postcode": 66646,
    "City": "Marpingen",
    "State": "Saarland",
    "Latitude": "049.4557960",
    "Longitude": "007.0607400",
    "Telephone Number": "068535107",
    "Week From": "07:00",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "09:30",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.455796,7.06074",
    "IsOpen24Hours": false,
    "id": "49.455796,7.06074"
  },
  {
    "Gas Station Number": "0F762",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Trierer Straße 90",
    "Postcode": 66839,
    "City": "Schmelz",
    "State": "Saarland",
    "Latitude": "049.4630000",
    "Longitude": "006.8516650",
    "Telephone Number": "0688788393",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.463,6.851665",
    "IsOpen24Hours": false,
    "id": "49.463,6.851665"
  },
  {
    "Gas Station Number": "0F763",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Glashüttenstraße 4",
    "Postcode": 66287,
    "City": "Quierschied",
    "State": "Saarland",
    "Latitude": "049.3297150",
    "Longitude": "007.0567140",
    "Telephone Number": "0689761902",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.329715,7.056714",
    "IsOpen24Hours": false,
    "id": "49.329715,7.056714"
  },
  {
    "Gas Station Number": "0F764",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wallerfanger Straße 69",
    "Postcode": 66780,
    "City": "Rehlingen-Siersburg",
    "State": "Saarland",
    "Latitude": "049.3650290",
    "Longitude": "006.6871490",
    "Telephone Number": "068357591",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.365029,6.687149",
    "IsOpen24Hours": true,
    "id": "49.365029,6.687149"
  },
  {
    "Gas Station Number": "0F765",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mommstraße 1",
    "Postcode": 66606,
    "City": "St. Wendel",
    "State": "Saarland",
    "Latitude": "049.4697930",
    "Longitude": "007.1674290",
    "Telephone Number": "068512753",
    "Week From": "06:30",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "01:30",
    "Sundays From": "07:00",
    "Sundays To": "01:30",
    "24 hour?": "Nein",
    "location": "49.469793,7.167429",
    "IsOpen24Hours": false,
    "id": "49.469793,7.167429"
  },
  {
    "Gas Station Number": "0F766",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Poststraße 33",
    "Postcode": 66687,
    "City": "Wadern",
    "State": "Saarland",
    "Latitude": "049.5350010",
    "Longitude": "006.8954060",
    "Telephone Number": "06871902924",
    "Week From": "06:30",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.535001,6.895406",
    "IsOpen24Hours": false,
    "id": "49.535001,6.895406"
  },
  {
    "Gas Station Number": "0F767",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Straße des 13. Januar 197",
    "Postcode": 66333,
    "City": "Völklingen",
    "State": "Saarland",
    "Latitude": "049.2490770",
    "Longitude": "006.8984750",
    "Telephone Number": "0689881668",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.249077,6.898475",
    "IsOpen24Hours": false,
    "id": "49.249077,6.898475"
  },
  {
    "Gas Station Number": "0F768",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Haustadter-Tal-Straße 110",
    "Postcode": 66701,
    "City": "Beckingen",
    "State": "Saarland",
    "Latitude": "049.4096170",
    "Longitude": "006.7177990",
    "Telephone Number": "068357569",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.409617,6.717799",
    "IsOpen24Hours": false,
    "id": "49.409617,6.717799"
  },
  {
    "Gas Station Number": "0F769",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 22",
    "Postcode": 66740,
    "City": "Saarlouis",
    "State": "Saarland",
    "Latitude": "049.3252160",
    "Longitude": "006.7577460",
    "Telephone Number": "0683181203",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.325216,6.757746",
    "IsOpen24Hours": true,
    "id": "49.325216,6.757746"
  },
  {
    "Gas Station Number": "0F770",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "von-Boch-Liebig-Straße 21",
    "Postcode": 66693,
    "City": "Mettlach",
    "State": "Saarland",
    "Latitude": "049.4950530",
    "Longitude": "006.5860360",
    "Telephone Number": "0686493532",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.495053,6.586036",
    "IsOpen24Hours": false,
    "id": "49.495053,6.586036"
  },
  {
    "Gas Station Number": "0F771",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Karolingerstraße 5",
    "Postcode": 66333,
    "City": "Völklingen",
    "State": "Saarland",
    "Latitude": "049.2475470",
    "Longitude": "006.8592460",
    "Telephone Number": "0689823327",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.247547,6.859246",
    "IsOpen24Hours": true,
    "id": "49.247547,6.859246"
  },
  {
    "Gas Station Number": "0F772",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lindenstraße 7",
    "Postcode": 66583,
    "City": "Spiesen-Elversberg",
    "State": "Saarland",
    "Latitude": "049.3178000",
    "Longitude": "007.1218400",
    "Telephone Number": "0682171436",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.3178,7.12184",
    "IsOpen24Hours": false,
    "id": "49.3178,7.12184"
  },
  {
    "Gas Station Number": "0F773",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "St. Johanner Straße 105",
    "Postcode": 66115,
    "City": "Saarbrücken",
    "State": "Saarland",
    "Latitude": "049.2390220",
    "Longitude": "006.9767910",
    "Telephone Number": "068143798",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.239022,6.976791",
    "IsOpen24Hours": true,
    "id": "49.239022,6.976791"
  },
  {
    "Gas Station Number": "0F774",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Höllerstraße 16",
    "Postcode": 55597,
    "City": "Wöllstein",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.8169590",
    "Longitude": "007.9607430",
    "Telephone Number": "06703960203",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.816959,7.960743",
    "IsOpen24Hours": true,
    "id": "49.816959,7.960743"
  },
  {
    "Gas Station Number": "0F779",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 129",
    "Postcode": 77815,
    "City": "Bühl",
    "State": "Baden-Württemberg",
    "Latitude": "048.7021000",
    "Longitude": "008.1393170",
    "Telephone Number": "072234364",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.7021,8.139317",
    "IsOpen24Hours": false,
    "id": "48.7021,8.139317"
  },
  {
    "Gas Station Number": "0F780",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 166 A",
    "Postcode": 76448,
    "City": "Durmersheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.9364790",
    "Longitude": "008.2723500",
    "Telephone Number": "072457956",
    "Week From": "05:30",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.936479,8.27235",
    "IsOpen24Hours": false,
    "id": "48.936479,8.27235"
  },
  {
    "Gas Station Number": "0F781",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Brettener Straße 68",
    "Postcode": 75031,
    "City": "Eppingen",
    "State": "Baden-Württemberg",
    "Latitude": "049.1349610",
    "Longitude": "008.9013090",
    "Telephone Number": "07262912086",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.134961,8.901309",
    "IsOpen24Hours": false,
    "id": "49.134961,8.901309"
  },
  {
    "Gas Station Number": "0F782",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wiesenstraße 30",
    "Postcode": 76228,
    "City": "Karlsruhe",
    "State": "Baden-Württemberg",
    "Latitude": "048.9527460",
    "Longitude": "008.4708570",
    "Telephone Number": "0721450101",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.952746,8.470857",
    "IsOpen24Hours": false,
    "id": "48.952746,8.470857"
  },
  {
    "Gas Station Number": "0F783",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedenstraße 2a",
    "Postcode": 76767,
    "City": "Hagenbach",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.0220480",
    "Longitude": "008.2515430",
    "Telephone Number": "072734518",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.022048,8.251543",
    "IsOpen24Hours": false,
    "id": "49.022048,8.251543"
  },
  {
    "Gas Station Number": "0F784",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hindenburgstraße  75",
    "Postcode": 75339,
    "City": "Höfen",
    "State": "Baden-Württemberg",
    "Latitude": "048.8080280",
    "Longitude": "008.5839700",
    "Telephone Number": "070815286",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.808028,8.58397",
    "IsOpen24Hours": false,
    "id": "48.808028,8.58397"
  },
  {
    "Gas Station Number": "0F785",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 1",
    "Postcode": 76549,
    "City": "Hügelsheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.7991020",
    "Longitude": "008.1158710",
    "Telephone Number": "07229181765",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.799102,8.115871",
    "IsOpen24Hours": false,
    "id": "48.799102,8.115871"
  },
  {
    "Gas Station Number": "0F786",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rheinstraße 128",
    "Postcode": 76870,
    "City": "Kandel",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.0852650",
    "Longitude": "008.2102040",
    "Telephone Number": "0727595670",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.085265,8.210204",
    "IsOpen24Hours": true,
    "id": "49.085265,8.210204"
  },
  {
    "Gas Station Number": "0F012",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Durlacher Allee 46",
    "Postcode": 76131,
    "City": "Karlsruhe",
    "State": "Baden-Württemberg",
    "Latitude": "049.0076310",
    "Longitude": "008.4228500",
    "Telephone Number": "0721696022",
    "Week From": "05:00",
    "Week To": "23:59",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "06:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "49.007631,8.42285",
    "IsOpen24Hours": false,
    "id": "49.007631,8.42285"
  },
  {
    "Gas Station Number": "0F787",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Amalienstraße 55/59",
    "Postcode": 76133,
    "City": "Karlsruhe",
    "State": "Baden-Württemberg",
    "Latitude": "049.0092150",
    "Longitude": "008.3908730",
    "Telephone Number": "072126224",
    "Week From": "06:00",
    "Week To": "23:59",
    "Saturdays From": "08:00",
    "Saturdays To": "02:00",
    "Sundays From": "08:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "49.009215,8.390873",
    "IsOpen24Hours": false,
    "id": "49.009215,8.390873"
  },
  {
    "Gas Station Number": "0F788",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kaiserallee 12 G",
    "Postcode": 76133,
    "City": "Karlsruhe",
    "State": "Baden-Württemberg",
    "Latitude": "049.0113160",
    "Longitude": "008.3750620",
    "Telephone Number": "0721842649",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.011316,8.375062",
    "IsOpen24Hours": true,
    "id": "49.011316,8.375062"
  },
  {
    "Gas Station Number": "0F789",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ebertstraße 32",
    "Postcode": 76137,
    "City": "Karlsruhe",
    "State": "Baden-Württemberg",
    "Latitude": "048.9951420",
    "Longitude": "008.3895390",
    "Telephone Number": "0721817516",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.995142,8.389539",
    "IsOpen24Hours": false,
    "id": "48.995142,8.389539"
  },
  {
    "Gas Station Number": "0F790",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ankerstraße 64",
    "Postcode": 75203,
    "City": "Königsbach-Stein",
    "State": "Baden-Württemberg",
    "Latitude": "048.9666650",
    "Longitude": "008.6036250",
    "Telephone Number": "072325595",
    "Week From": "07:00",
    "Week To": "20:30",
    "Saturdays From": "07:00",
    "Saturdays To": "20:30",
    "Sundays From": "08:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "48.966665,8.603625",
    "IsOpen24Hours": false,
    "id": "48.966665,8.603625"
  },
  {
    "Gas Station Number": "0F791",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ettlinger-Straße35",
    "Postcode": 76307,
    "City": "Karlsbad",
    "State": "Baden-Württemberg",
    "Latitude": "048.9173120",
    "Longitude": "008.4999410",
    "Telephone Number": "072029421913",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.917312,8.499941",
    "IsOpen24Hours": false,
    "id": "48.917312,8.499941"
  },
  {
    "Gas Station Number": "0F792",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Pforzheimer Straße 55",
    "Postcode": 75417,
    "City": "Mühlacker",
    "State": "Baden-Württemberg",
    "Latitude": "048.9482790",
    "Longitude": "008.8345840",
    "Telephone Number": "070414840",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.948279,8.834584",
    "IsOpen24Hours": false,
    "id": "48.948279,8.834584"
  },
  {
    "Gas Station Number": "0F793",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wildbader Straße 55",
    "Postcode": 75394,
    "City": "Oberreichenbach",
    "State": "Baden-Württemberg",
    "Latitude": "048.7341920",
    "Longitude": "008.6627840",
    "Telephone Number": "07051968683",
    "Week From": "06:45",
    "Week To": "19:00",
    "Saturdays From": "08:00",
    "Saturdays To": "19:00",
    "Sundays From": "08:30",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "48.734192,8.662784",
    "IsOpen24Hours": false,
    "id": "48.734192,8.662784"
  },
  {
    "Gas Station Number": "0F794",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Landauer Straße 11",
    "Postcode": 76877,
    "City": "Offenbach",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.1943760",
    "Longitude": "008.1875270",
    "Telephone Number": "063486473",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.194376,8.187527",
    "IsOpen24Hours": false,
    "id": "49.194376,8.187527"
  },
  {
    "Gas Station Number": "0F795",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kaiser-Friedrich-Straße 3",
    "Postcode": 75172,
    "City": "Pforzheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.8890790",
    "Longitude": "008.6924410",
    "Telephone Number": "07231927913",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.889079,8.692441",
    "IsOpen24Hours": true,
    "id": "48.889079,8.692441"
  },
  {
    "Gas Station Number": "0F796",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alexanderplatz",
    "Postcode": 75015,
    "City": "Bretten",
    "State": "Baden-Württemberg",
    "Latitude": "049.0378420",
    "Longitude": "008.6877450",
    "Telephone Number": "07252958220",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.037842,8.687745",
    "IsOpen24Hours": true,
    "id": "49.037842,8.687745"
  },
  {
    "Gas Station Number": "0F798",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße",
    "Postcode": 75056,
    "City": "Sulzfeld",
    "State": "Baden-Württemberg",
    "Latitude": "049.1095290",
    "Longitude": "008.8551310",
    "Telephone Number": "07269919130",
    "Week From": "05:30",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.109529,8.855131",
    "IsOpen24Hours": false,
    "id": "49.109529,8.855131"
  },
  {
    "Gas Station Number": "0F799",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Weingartener Straße 99",
    "Postcode": 76646,
    "City": "Bruchsal",
    "State": "Baden-Württemberg",
    "Latitude": "049.0801380",
    "Longitude": "008.5492520",
    "Telephone Number": "07257911412",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:30",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.080138,8.549252",
    "IsOpen24Hours": false,
    "id": "49.080138,8.549252"
  },
  {
    "Gas Station Number": "0F800",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße2",
    "Postcode": 75196,
    "City": "Remchingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.9529960",
    "Longitude": "008.5706880",
    "Telephone Number": "0723271202",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.952996,8.570688",
    "IsOpen24Hours": true,
    "id": "48.952996,8.570688"
  },
  {
    "Gas Station Number": "0F802",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stuttgarter Straße 139",
    "Postcode": 71665,
    "City": "Vaihingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.9224260",
    "Longitude": "008.9740540",
    "Telephone Number": "0704217154",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.922426,8.974054",
    "IsOpen24Hours": true,
    "id": "48.922426,8.974054"
  },
  {
    "Gas Station Number": "0F803",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Haiterbacher Straße 24-26",
    "Postcode": 72202,
    "City": "Nagold",
    "State": "Baden-Württemberg",
    "Latitude": "048.5488750",
    "Longitude": "008.7267870",
    "Telephone Number": "074523562",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.548875,8.726787",
    "IsOpen24Hours": true,
    "id": "48.548875,8.726787"
  },
  {
    "Gas Station Number": "0F804",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Groß-Umstädter Straße 1",
    "Postcode": 64807,
    "City": "Dieburg",
    "State": "Hessen",
    "Latitude": "049.8991000",
    "Longitude": "008.8467250",
    "Telephone Number": "06071499697",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.8991,8.846725",
    "IsOpen24Hours": false,
    "id": "49.8991,8.846725"
  },
  {
    "Gas Station Number": "0F806",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 90",
    "Postcode": 76669,
    "City": "Bad Schönborn",
    "State": "Baden-Württemberg",
    "Latitude": "049.2005820",
    "Longitude": "008.6462340",
    "Telephone Number": "072539609997",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.200582,8.646234",
    "IsOpen24Hours": false,
    "id": "49.200582,8.646234"
  },
  {
    "Gas Station Number": "0F807",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lange Straße 122",
    "Postcode": 76530,
    "City": "Baden-Baden",
    "State": "Baden-Württemberg",
    "Latitude": "048.7705450",
    "Longitude": "008.2284370",
    "Telephone Number": "0722125331",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.770545,8.228437",
    "IsOpen24Hours": true,
    "id": "48.770545,8.228437"
  },
  {
    "Gas Station Number": "0F808",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Baumholderstraße 47-51",
    "Postcode": 66629,
    "City": "Freisen",
    "State": "Saarland",
    "Latitude": "049.5546050",
    "Longitude": "007.2561430",
    "Telephone Number": "06855920017",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.554605,7.256143",
    "IsOpen24Hours": true,
    "id": "49.554605,7.256143"
  },
  {
    "Gas Station Number": "0F809",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 73",
    "Postcode": 67734,
    "City": "Katzweiler",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.5046510",
    "Longitude": "007.6923980",
    "Telephone Number": "063019140",
    "Week From": "06:30",
    "Week To": "20:00",
    "Saturdays From": "07:30",
    "Saturdays To": "19:00",
    "Sundays From": "09:00",
    "Sundays To": "18:00",
    "24 hour?": "Nein",
    "location": "49.504651,7.692398",
    "IsOpen24Hours": false,
    "id": "49.504651,7.692398"
  },
  {
    "Gas Station Number": "0F810",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kaiserstraße 1 E",
    "Postcode": 66459,
    "City": "Kirkel",
    "State": "Saarland",
    "Latitude": "049.2897440",
    "Longitude": "007.2384590",
    "Telephone Number": "06849551",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:30",
    "Saturdays To": "21:00",
    "Sundays From": "08:30",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.289744,7.238459",
    "IsOpen24Hours": false,
    "id": "49.289744,7.238459"
  },
  {
    "Gas Station Number": "0F811",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Brandenburger Str. 13-15",
    "Postcode": 68309,
    "City": "Mannheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.5120680",
    "Longitude": "008.5295420",
    "Telephone Number": "0621706602",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.512068,8.529542",
    "IsOpen24Hours": true,
    "id": "49.512068,8.529542"
  },
  {
    "Gas Station Number": "0F812",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Richard-Müller-Straße 7 a",
    "Postcode": 67823,
    "City": "Obermoschel",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.7294740",
    "Longitude": "007.7769790",
    "Telephone Number": "06362993170",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "19:00",
    "Sundays From": "07:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "49.729474,7.776979",
    "IsOpen24Hours": false,
    "id": "49.729474,7.776979"
  },
  {
    "Gas Station Number": "0F813",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Feldmannstraße 69-73",
    "Postcode": 66119,
    "City": "Saarbrücken",
    "State": "Saarland",
    "Latitude": "049.2235190",
    "Longitude": "006.9931050",
    "Telephone Number": "068156485",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.223519,6.993105",
    "IsOpen24Hours": false,
    "id": "49.223519,6.993105"
  },
  {
    "Gas Station Number": "0F814",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ensheimer Straße 152",
    "Postcode": 66386,
    "City": "St. Ingbert",
    "State": "Saarland",
    "Latitude": "049.2631350",
    "Longitude": "007.1028760",
    "Telephone Number": "068946874",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.263135,7.102876",
    "IsOpen24Hours": false,
    "id": "49.263135,7.102876"
  },
  {
    "Gas Station Number": "0F815",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neues Sträßel 4",
    "Postcode": 69168,
    "City": "Wiesloch",
    "State": "Baden-Württemberg",
    "Latitude": "049.2895300",
    "Longitude": "008.6784270",
    "Telephone Number": "062221038",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.28953,8.678427",
    "IsOpen24Hours": false,
    "id": "49.28953,8.678427"
  },
  {
    "Gas Station Number": "0F816",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Fautenbacher Straße 22",
    "Postcode": 77855,
    "City": "Achern",
    "State": "Baden-Württemberg",
    "Latitude": "048.6285140",
    "Longitude": "008.0633740",
    "Telephone Number": "0784124099",
    "Week From": "06:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "07:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "48.628514,8.063374",
    "IsOpen24Hours": false,
    "id": "48.628514,8.063374"
  },
  {
    "Gas Station Number": "0F817",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 114",
    "Postcode": 77833,
    "City": "Ottersweier",
    "State": "Baden-Württemberg",
    "Latitude": "048.6735900",
    "Longitude": "008.1206720",
    "Telephone Number": "072232818707",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.67359,8.120672",
    "IsOpen24Hours": false,
    "id": "48.67359,8.120672"
  },
  {
    "Gas Station Number": "0F818",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Strassburger Straße 11a",
    "Postcode": 77694,
    "City": "Kehl",
    "State": "Baden-Württemberg",
    "Latitude": "048.5760970",
    "Longitude": "007.8180070",
    "Telephone Number": "07851958081",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.576097,7.818007",
    "IsOpen24Hours": true,
    "id": "48.576097,7.818007"
  },
  {
    "Gas Station Number": "0F819",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A5",
    "Postcode": 77767,
    "City": "Appenweier",
    "State": "Baden-Württemberg",
    "Latitude": "048.5637910",
    "Longitude": "007.9569170",
    "Telephone Number": "078053402",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.563791,7.956917",
    "IsOpen24Hours": true,
    "id": "48.563791,7.956917"
  },
  {
    "Gas Station Number": "0F820",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Spießstraße 2",
    "Postcode": 66892,
    "City": "Bruchmühlbach-Miesau",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.3921770",
    "Longitude": "007.4379140",
    "Telephone Number": "06372993290",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.392177,7.437914",
    "IsOpen24Hours": true,
    "id": "49.392177,7.437914"
  },
  {
    "Gas Station Number": "0F821",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Saarlandstraße 2",
    "Postcode": 66482,
    "City": "Zweibrücken",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.2495680",
    "Longitude": "007.3701920",
    "Telephone Number": "06332472572",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.249568,7.370192",
    "IsOpen24Hours": true,
    "id": "49.249568,7.370192"
  },
  {
    "Gas Station Number": "0F822",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Götzenberg 13",
    "Postcode": 69126,
    "City": "Heidelberg",
    "State": "Baden-Württemberg",
    "Latitude": "049.3704010",
    "Longitude": "008.7012260",
    "Telephone Number": "06221383813",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.370401,8.701226",
    "IsOpen24Hours": false,
    "id": "49.370401,8.701226"
  },
  {
    "Gas Station Number": "0F823",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A81",
    "Postcode": 78661,
    "City": "Dietingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.2062180",
    "Longitude": "008.6227610",
    "Telephone Number": "07417453",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.206218,8.622761",
    "IsOpen24Hours": true,
    "id": "48.206218,8.622761"
  },
  {
    "Gas Station Number": "0F001",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lochäckerstraße / B 294",
    "Postcode": 75177,
    "City": "Pforzheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.9100010",
    "Longitude": "008.7189110",
    "Telephone Number": "072315660680",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.910001,8.718911",
    "IsOpen24Hours": true,
    "id": "48.910001,8.718911"
  },
  {
    "Gas Station Number": "0F824",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rosenheimer Straße 138",
    "Postcode": 81669,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1192060",
    "Longitude": "011.6077720",
    "Telephone Number": "089401258",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.119206,11.607772",
    "IsOpen24Hours": true,
    "id": "48.119206,11.607772"
  },
  {
    "Gas Station Number": "0F825",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Münchener Straße 7",
    "Postcode": 83512,
    "City": "Wasserburg",
    "State": "Bayern",
    "Latitude": "048.0612680",
    "Longitude": "012.2098080",
    "Telephone Number": "080719225808",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:30",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.061268,12.209808",
    "IsOpen24Hours": false,
    "id": "48.061268,12.209808"
  },
  {
    "Gas Station Number": "0F827",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Münchener Straße 13",
    "Postcode": 82054,
    "City": "Sauerlach",
    "State": "Bayern",
    "Latitude": "047.9736750",
    "Longitude": "011.6536540",
    "Telephone Number": "081049756",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "47.973675,11.653654",
    "IsOpen24Hours": false,
    "id": "47.973675,11.653654"
  },
  {
    "Gas Station Number": "0F829",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lochhausener Straße 229",
    "Postcode": 81249,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1789510",
    "Longitude": "011.4051460",
    "Telephone Number": "08989711640",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.178951,11.405146",
    "IsOpen24Hours": true,
    "id": "48.178951,11.405146"
  },
  {
    "Gas Station Number": "0F831",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der B20 Nr.2",
    "Postcode": 83404,
    "City": "Ainring",
    "State": "Bayern",
    "Latitude": "047.8055914",
    "Longitude": "012.9573657",
    "Telephone Number": "086547725240",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "05:00",
    "Saturdays To": "21:00",
    "Sundays From": "05:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "47.8055914,12.9573657",
    "IsOpen24Hours": false,
    "id": "47.8055914,12.9573657"
  },
  {
    "Gas Station Number": "0F833",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Riemer Straße 360",
    "Postcode": 81829,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1406310",
    "Longitude": "011.6839160",
    "Telephone Number": "089907116",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.140631,11.683916",
    "IsOpen24Hours": false,
    "id": "48.140631,11.683916"
  },
  {
    "Gas Station Number": "0F834",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Allgäuer Straße 34",
    "Postcode": 87700,
    "City": "Memmingen",
    "State": "Bayern",
    "Latitude": "047.9722880",
    "Longitude": "010.1826450",
    "Telephone Number": "0833189251",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.972288,10.182645",
    "IsOpen24Hours": false,
    "id": "47.972288,10.182645"
  },
  {
    "Gas Station Number": "0F835",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Aiblinger Au 52 a",
    "Postcode": 83059,
    "City": "Kolbermoor",
    "State": "Bayern",
    "Latitude": "047.8511460",
    "Longitude": "012.0174520",
    "Telephone Number": "080614695",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.851146,12.017452",
    "IsOpen24Hours": true,
    "id": "47.851146,12.017452"
  },
  {
    "Gas Station Number": "0F836",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kufsteiner Straße 57",
    "Postcode": 83022,
    "City": "Rosenheim",
    "State": "Bayern",
    "Latitude": "047.8487670",
    "Longitude": "012.1275390",
    "Telephone Number": "0803113256",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.848767,12.127539",
    "IsOpen24Hours": true,
    "id": "47.848767,12.127539"
  },
  {
    "Gas Station Number": "0F837",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schongauer Straße 19",
    "Postcode": 86971,
    "City": "Peiting",
    "State": "Bayern",
    "Latitude": "047.7986680",
    "Longitude": "010.9212030",
    "Telephone Number": "08861693041",
    "Week From": "06:30",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.798668,10.921203",
    "IsOpen24Hours": false,
    "id": "47.798668,10.921203"
  },
  {
    "Gas Station Number": "0F838",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Augsburger Straße 25",
    "Postcode": 86477,
    "City": "Adelsried",
    "State": "Bayern",
    "Latitude": "048.4236580",
    "Longitude": "010.7201730",
    "Telephone Number": "082948607430",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:30",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "48.423658,10.720173",
    "IsOpen24Hours": false,
    "id": "48.423658,10.720173"
  },
  {
    "Gas Station Number": "0F839",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schleißheimer Straße 257",
    "Postcode": 80809,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1787880",
    "Longitude": "011.5670970",
    "Telephone Number": "0893508370",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.178788,11.567097",
    "IsOpen24Hours": false,
    "id": "48.178788,11.567097"
  },
  {
    "Gas Station Number": "0F840",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Augsburger Straße 66",
    "Postcode": 86830,
    "City": "Schwabmünchen",
    "State": "Bayern",
    "Latitude": "048.1936570",
    "Longitude": "010.7546100",
    "Telephone Number": "082324790",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.193657,10.75461",
    "IsOpen24Hours": false,
    "id": "48.193657,10.75461"
  },
  {
    "Gas Station Number": "0F841",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Münchener Straße 42",
    "Postcode": 82131,
    "City": "Gauting",
    "State": "Bayern",
    "Latitude": "048.0679520",
    "Longitude": "011.3872370",
    "Telephone Number": "08996031470",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.067952,11.387237",
    "IsOpen24Hours": false,
    "id": "48.067952,11.387237"
  },
  {
    "Gas Station Number": "0F842",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Münchner Straße 10",
    "Postcode": 85635,
    "City": "Höhenkirchen-Siegert",
    "State": "Bayern",
    "Latitude": "048.0197260",
    "Longitude": "011.7098720",
    "Telephone Number": "081028284",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.019726,11.709872",
    "IsOpen24Hours": false,
    "id": "48.019726,11.709872"
  },
  {
    "Gas Station Number": "0F843",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Salzburger Straße 49",
    "Postcode": 83329,
    "City": "Waging",
    "State": "Bayern",
    "Latitude": "047.9341140",
    "Longitude": "012.7418120",
    "Telephone Number": "086814379",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "05:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.934114,12.741812",
    "IsOpen24Hours": false,
    "id": "47.934114,12.741812"
  },
  {
    "Gas Station Number": "0F844",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Haunstetter Straße 139 1/2",
    "Postcode": 86161,
    "City": "Augsburg",
    "State": "Bayern",
    "Latitude": "048.3337320",
    "Longitude": "010.9081190",
    "Telephone Number": "0821573303",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.333732,10.908119",
    "IsOpen24Hours": true,
    "id": "48.333732,10.908119"
  },
  {
    "Gas Station Number": "0F845",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Garmischer Straße 197",
    "Postcode": 81377,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1213460",
    "Longitude": "011.5188170",
    "Telephone Number": "0897608904",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.121346,11.518817",
    "IsOpen24Hours": true,
    "id": "48.121346,11.518817"
  },
  {
    "Gas Station Number": "0F846",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schongauer Straße 7",
    "Postcode": 86899,
    "City": "Landsberg",
    "State": "Bayern",
    "Latitude": "048.0389100",
    "Longitude": "010.8647850",
    "Telephone Number": "081912727",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.03891,10.864785",
    "IsOpen24Hours": true,
    "id": "48.03891,10.864785"
  },
  {
    "Gas Station Number": "0F847",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Plinganser Str. 70",
    "Postcode": 81369,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1122740",
    "Longitude": "011.5391820",
    "Telephone Number": "089761566",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.112274,11.539182",
    "IsOpen24Hours": true,
    "id": "48.112274,11.539182"
  },
  {
    "Gas Station Number": "0F848",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Münchener Straße 66",
    "Postcode": 87700,
    "City": "Memmingen",
    "State": "Bayern",
    "Latitude": "047.9963370",
    "Longitude": "010.1991430",
    "Telephone Number": "083315724",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.996337,10.199143",
    "IsOpen24Hours": false,
    "id": "47.996337,10.199143"
  },
  {
    "Gas Station Number": "0F849",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Untere Hauptstraße 11",
    "Postcode": 85386,
    "City": "Eching",
    "State": "Bayern",
    "Latitude": "048.2981790",
    "Longitude": "011.6250840",
    "Telephone Number": "0893192811",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.298179,11.625084",
    "IsOpen24Hours": true,
    "id": "48.298179,11.625084"
  },
  {
    "Gas Station Number": "0F850",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Manchinger Straße 76",
    "Postcode": 85053,
    "City": "Ingolstadt",
    "State": "Bayern",
    "Latitude": "048.7539330",
    "Longitude": "011.4498870",
    "Telephone Number": "084168555",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "05:30",
    "Saturdays To": "22:00",
    "Sundays From": "05:30",
    "Sundays To": "22:00",
    "24 hour?": "Ja",
    "location": "48.753933,11.449887",
    "IsOpen24Hours": true,
    "id": "48.753933,11.449887"
  },
  {
    "Gas Station Number": "0F851",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wasserburger Landstr. 51",
    "Postcode": 81825,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1197220",
    "Longitude": "011.6777340",
    "Telephone Number": "089424843",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.119722,11.677734",
    "IsOpen24Hours": true,
    "id": "48.119722,11.677734"
  },
  {
    "Gas Station Number": "0F852",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rosenheimer Straße 83",
    "Postcode": 83064,
    "City": "Raubling",
    "State": "Bayern",
    "Latitude": "047.8047910",
    "Longitude": "012.1198770",
    "Telephone Number": "080352766",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.804791,12.119877",
    "IsOpen24Hours": true,
    "id": "47.804791,12.119877"
  },
  {
    "Gas Station Number": "0F854",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Grüneckerstraße 6",
    "Postcode": 85375,
    "City": "Neufahrn",
    "State": "Bayern",
    "Latitude": "048.3130860",
    "Longitude": "011.6651980",
    "Telephone Number": "081654610",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.313086,11.665198",
    "IsOpen24Hours": true,
    "id": "48.313086,11.665198"
  },
  {
    "Gas Station Number": "0F853",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Salzburger Straße 64",
    "Postcode": 83071,
    "City": "Stephanskirchen",
    "State": "Bayern",
    "Latitude": "047.8603930",
    "Longitude": "012.1543400",
    "Telephone Number": "0803171081",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.860393,12.15434",
    "IsOpen24Hours": false,
    "id": "47.860393,12.15434"
  },
  {
    "Gas Station Number": "0F855",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Niedermayerstraße 54",
    "Postcode": 84036,
    "City": "Landshut",
    "State": "Bayern",
    "Latitude": "048.5462070",
    "Longitude": "012.1810830",
    "Telephone Number": "087152916",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.546207,12.181083",
    "IsOpen24Hours": true,
    "id": "48.546207,12.181083"
  },
  {
    "Gas Station Number": "0F856",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Augsburger Straße 32",
    "Postcode": 87600,
    "City": "Kaufbeuren",
    "State": "Bayern",
    "Latitude": "047.8845400",
    "Longitude": "010.6367120",
    "Telephone Number": "083414859",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.88454,10.636712",
    "IsOpen24Hours": false,
    "id": "47.88454,10.636712"
  },
  {
    "Gas Station Number": "0F857",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Landshuter Allee 163",
    "Postcode": 80637,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1635550",
    "Longitude": "011.5373430",
    "Telephone Number": "089156225",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.163555,11.537343",
    "IsOpen24Hours": true,
    "id": "48.163555,11.537343"
  },
  {
    "Gas Station Number": "0F858",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Miesbacher Straße 33",
    "Postcode": 83607,
    "City": "Holzkirchen",
    "State": "Bayern",
    "Latitude": "047.8758050",
    "Longitude": "011.7101660",
    "Telephone Number": "08024998184",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.875805,11.710166",
    "IsOpen24Hours": true,
    "id": "47.875805,11.710166"
  },
  {
    "Gas Station Number": "0F859",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Augsburger Straße 42",
    "Postcode": 86381,
    "City": "Krumbach",
    "State": "Bayern",
    "Latitude": "048.2439010",
    "Longitude": "010.3724050",
    "Telephone Number": "082823059",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.243901,10.372405",
    "IsOpen24Hours": false,
    "id": "48.243901,10.372405"
  },
  {
    "Gas Station Number": "0F860",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Loferer Straße 29",
    "Postcode": 83435,
    "City": "Bad Reichenhall",
    "State": "Bayern",
    "Latitude": "047.7338640",
    "Longitude": "012.8770830",
    "Telephone Number": "086512325",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.733864,12.877083",
    "IsOpen24Hours": true,
    "id": "47.733864,12.877083"
  },
  {
    "Gas Station Number": "0F861",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Donauwörther Straße 15",
    "Postcode": 86462,
    "City": "Langweid",
    "State": "Bayern",
    "Latitude": "048.4652980",
    "Longitude": "010.8660530",
    "Telephone Number": "0821492625",
    "Week From": "05:30",
    "Week To": "23:00",
    "Saturdays From": "05:30",
    "Saturdays To": "23:00",
    "Sundays From": "07:00",
    "Sundays To": "23:00",
    "24 hour?": "Ja",
    "location": "48.465298,10.866053",
    "IsOpen24Hours": true,
    "id": "48.465298,10.866053"
  },
  {
    "Gas Station Number": "0F863",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A9",
    "Postcode": 85301,
    "City": "Geisenhausen",
    "State": "Bayern",
    "Latitude": "048.5565500",
    "Longitude": "011.5879130",
    "Telephone Number": "08441801573",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.55655,11.587913",
    "IsOpen24Hours": true,
    "id": "48.55655,11.587913"
  },
  {
    "Gas Station Number": "0F864",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A9",
    "Postcode": 85120,
    "City": "Hepberg",
    "State": "Bayern",
    "Latitude": "048.8361480",
    "Longitude": "011.4711640",
    "Telephone Number": "084051336",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.836148,11.471164",
    "IsOpen24Hours": true,
    "id": "48.836148,11.471164"
  },
  {
    "Gas Station Number": "0F865",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schenkendorfstraße 15",
    "Postcode": 80807,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1772930",
    "Longitude": "011.5893530",
    "Telephone Number": "089364016",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.177293,11.589353",
    "IsOpen24Hours": true,
    "id": "48.177293,11.589353"
  },
  {
    "Gas Station Number": "0F878",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schumacherring 110",
    "Postcode": 87437,
    "City": "Kempten",
    "State": "Bayern",
    "Latitude": "047.7334690",
    "Longitude": "010.3253490",
    "Telephone Number": "083173262",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.733469,10.325349",
    "IsOpen24Hours": true,
    "id": "47.733469,10.325349"
  },
  {
    "Gas Station Number": "0F866",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rosenheimer Straße 30 a",
    "Postcode": 83059,
    "City": "Kolbermoor",
    "State": "Bayern",
    "Latitude": "047.8498760",
    "Longitude": "012.0677060",
    "Telephone Number": "0803191354",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.849876,12.067706",
    "IsOpen24Hours": false,
    "id": "47.849876,12.067706"
  },
  {
    "Gas Station Number": "0F868",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Boschetsrieder Straße 127",
    "Postcode": 81379,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.0984630",
    "Longitude": "011.5185780",
    "Telephone Number": "089786153",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.098463,11.518578",
    "IsOpen24Hours": false,
    "id": "48.098463,11.518578"
  },
  {
    "Gas Station Number": "0F869",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dülferstraße 3",
    "Postcode": 80933,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.2126490",
    "Longitude": "011.5601390",
    "Telephone Number": "08931207480",
    "Week From": "06:00",
    "Week To": "23:59",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "06:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "48.212649,11.560139",
    "IsOpen24Hours": false,
    "id": "48.212649,11.560139"
  },
  {
    "Gas Station Number": "0F870",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heinrich-Wieland-Straße 2",
    "Postcode": 81735,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1183280",
    "Longitude": "011.6310800",
    "Telephone Number": "089401320",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.118328,11.63108",
    "IsOpen24Hours": true,
    "id": "48.118328,11.63108"
  },
  {
    "Gas Station Number": "0F871",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Tegernseer Landstraße 174",
    "Postcode": 81539,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1077420",
    "Longitude": "011.5795320",
    "Telephone Number": "0896202810",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.107742,11.579532",
    "IsOpen24Hours": true,
    "id": "48.107742,11.579532"
  },
  {
    "Gas Station Number": "0F872",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Äussere Rosenheimerstr23",
    "Postcode": 83278,
    "City": "Traunstein",
    "State": "Bayern",
    "Latitude": "047.8670600",
    "Longitude": "012.6338730",
    "Telephone Number": "08613132",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "05:00",
    "Saturdays To": "23:00",
    "Sundays From": "05:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "47.86706,12.633873",
    "IsOpen24Hours": false,
    "id": "47.86706,12.633873"
  },
  {
    "Gas Station Number": "0F873",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wasserburger Landstraße 3",
    "Postcode": 85591,
    "City": "Vaterstetten",
    "State": "Bayern",
    "Latitude": "048.0962690",
    "Longitude": "011.7713610",
    "Telephone Number": "08106307446",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.096269,11.771361",
    "IsOpen24Hours": true,
    "id": "48.096269,11.771361"
  },
  {
    "Gas Station Number": "0F874",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Landshuter Straße 9",
    "Postcode": 84137,
    "City": "Vilsbiburg",
    "State": "Bayern",
    "Latitude": "048.4486630",
    "Longitude": "012.3510330",
    "Telephone Number": "08741926794",
    "Week From": "06:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "07:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "48.448663,12.351033",
    "IsOpen24Hours": false,
    "id": "48.448663,12.351033"
  },
  {
    "Gas Station Number": "0F875",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gilchingerstraße 31",
    "Postcode": 82239,
    "City": "Alling",
    "State": "Bayern",
    "Latitude": "048.1380990",
    "Longitude": "011.2993890",
    "Telephone Number": "0814171979",
    "Week From": "06:45",
    "Week To": "20:00",
    "Saturdays From": "07:30",
    "Saturdays To": "19:00",
    "Sundays From": "08:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "48.138099,11.299389",
    "IsOpen24Hours": false,
    "id": "48.138099,11.299389"
  },
  {
    "Gas Station Number": "0F876",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kemptener Straße 43",
    "Postcode": 87452,
    "City": "Altusried",
    "State": "Bayern",
    "Latitude": "047.8018870",
    "Longitude": "010.2250110",
    "Telephone Number": "08373935375",
    "Week From": "06:30",
    "Week To": "20:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "47.801887,10.225011",
    "IsOpen24Hours": false,
    "id": "47.801887,10.225011"
  },
  {
    "Gas Station Number": "0F877",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stätzlinger Straße 86",
    "Postcode": 86165,
    "City": "Augsburg",
    "State": "Bayern",
    "Latitude": "048.3782010",
    "Longitude": "010.9374710",
    "Telephone Number": "0821713575",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "48.378201,10.937471",
    "IsOpen24Hours": false,
    "id": "48.378201,10.937471"
  },
  {
    "Gas Station Number": "0F006",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sachsenkamer Straße 23",
    "Postcode": 83646,
    "City": "Bad Tölz",
    "State": "Bayern",
    "Latitude": "047.7595930",
    "Longitude": "011.5735660",
    "Telephone Number": "080415303",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.759593,11.573566",
    "IsOpen24Hours": true,
    "id": "47.759593,11.573566"
  },
  {
    "Gas Station Number": "0F879",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kemptener Straße 23",
    "Postcode": 87634,
    "City": "Obergünzburg",
    "State": "Bayern",
    "Latitude": "047.8430430",
    "Longitude": "010.4165260",
    "Telephone Number": "08372496",
    "Week From": "07:00",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "47.843043,10.416526",
    "IsOpen24Hours": false,
    "id": "47.843043,10.416526"
  },
  {
    "Gas Station Number": "0F880",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Allgäuerstraße 39",
    "Postcode": 87459,
    "City": "Pfronten",
    "State": "Bayern",
    "Latitude": "047.5846240",
    "Longitude": "010.5592780",
    "Telephone Number": "08363296",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.584624,10.559278",
    "IsOpen24Hours": false,
    "id": "47.584624,10.559278"
  },
  {
    "Gas Station Number": "0F881",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Freischützstraße 4",
    "Postcode": 81927,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1575690",
    "Longitude": "011.6422390",
    "Telephone Number": "089935312",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.157569,11.642239",
    "IsOpen24Hours": false,
    "id": "48.157569,11.642239"
  },
  {
    "Gas Station Number": "0F882",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Münchener Straße 16",
    "Postcode": 85540,
    "City": "Haar",
    "State": "Bayern",
    "Latitude": "048.1085380",
    "Longitude": "011.7221060",
    "Telephone Number": "089467300",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.108538,11.722106",
    "IsOpen24Hours": true,
    "id": "48.108538,11.722106"
  },
  {
    "Gas Station Number": "0F883",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Georg-Brauchle-Ring 27",
    "Postcode": 80992,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1761040",
    "Longitude": "011.5356160",
    "Telephone Number": "089155116",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.176104,11.535616",
    "IsOpen24Hours": true,
    "id": "48.176104,11.535616"
  },
  {
    "Gas Station Number": "0F884",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Richard Strauss-Straße 70",
    "Postcode": 81679,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1457180",
    "Longitude": "011.6163390",
    "Telephone Number": "089915079",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.145718,11.616339",
    "IsOpen24Hours": true,
    "id": "48.145718,11.616339"
  },
  {
    "Gas Station Number": "0F885",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Isarstraße 3",
    "Postcode": 85356,
    "City": "Freising",
    "State": "Bayern",
    "Latitude": "048.3974590",
    "Longitude": "011.7592900",
    "Telephone Number": "0816183048",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.397459,11.75929",
    "IsOpen24Hours": false,
    "id": "48.397459,11.75929"
  },
  {
    "Gas Station Number": "0F887",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Josef-Greschniok-Straße 1",
    "Postcode": 94051,
    "City": "Hauzenberg",
    "State": "Bayern",
    "Latitude": "048.6540140",
    "Longitude": "013.6206010",
    "Telephone Number": "085861342",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.654014,13.620601",
    "IsOpen24Hours": false,
    "id": "48.654014,13.620601"
  },
  {
    "Gas Station Number": "0F888",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 20",
    "Postcode": 82467,
    "City": "Garmisch-Partenkirch",
    "State": "Bayern",
    "Latitude": "047.5004530",
    "Longitude": "011.1014430",
    "Telephone Number": "0882152733",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.500453,11.101443",
    "IsOpen24Hours": false,
    "id": "47.500453,11.101443"
  },
  {
    "Gas Station Number": "0F889",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bernecker Straße 49",
    "Postcode": 95448,
    "City": "Bayreuth",
    "State": "Bayern",
    "Latitude": "049.9547970",
    "Longitude": "011.6000940",
    "Telephone Number": "092113046",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.954797,11.600094",
    "IsOpen24Hours": true,
    "id": "49.954797,11.600094"
  },
  {
    "Gas Station Number": "0F890",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Oberstenfelder Straße 24",
    "Postcode": 71717,
    "City": "Beilstein",
    "State": "Baden-Württemberg",
    "Latitude": "049.0348160",
    "Longitude": "009.3162730",
    "Telephone Number": "070625670",
    "Week From": "05:30",
    "Week To": "22:30",
    "Saturdays From": "05:30",
    "Saturdays To": "22:30",
    "Sundays From": "08:00",
    "Sundays To": "22:30",
    "24 hour?": "Nein",
    "location": "49.034816,9.316273",
    "IsOpen24Hours": false,
    "id": "49.034816,9.316273"
  },
  {
    "Gas Station Number": "0F891",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bärnzeller Straße 5",
    "Postcode": 94227,
    "City": "Zwiesel",
    "State": "Bayern",
    "Latitude": "049.0010610",
    "Longitude": "013.2203350",
    "Telephone Number": "09922802644",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.001061,13.220335",
    "IsOpen24Hours": true,
    "id": "49.001061,13.220335"
  },
  {
    "Gas Station Number": "0F893",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Teplitzer Straße 24",
    "Postcode": 84478,
    "City": "Waldkraiburg",
    "State": "Bayern",
    "Latitude": "048.2116360",
    "Longitude": "012.4173230",
    "Telephone Number": "086384185",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.211636,12.417323",
    "IsOpen24Hours": true,
    "id": "48.211636,12.417323"
  },
  {
    "Gas Station Number": "0F894",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Passauer Straße 39",
    "Postcode": 94081,
    "City": "Fürstenzell",
    "State": "Bayern",
    "Latitude": "048.5251870",
    "Longitude": "013.3270440",
    "Telephone Number": "085021313",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "05:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.525187,13.327044",
    "IsOpen24Hours": false,
    "id": "48.525187,13.327044"
  },
  {
    "Gas Station Number": "0F892",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gleiwitzer Straße 8",
    "Postcode": 93073,
    "City": "Neutraubling",
    "State": "Bayern",
    "Latitude": "048.9882260",
    "Longitude": "012.1893620",
    "Telephone Number": "0940179060",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.988226,12.189362",
    "IsOpen24Hours": true,
    "id": "48.988226,12.189362"
  },
  {
    "Gas Station Number": "0F895",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ingolstädter Str. 164-166",
    "Postcode": 80939,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.2017770",
    "Longitude": "011.5861490",
    "Telephone Number": "08931699843",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.201777,11.586149",
    "IsOpen24Hours": true,
    "id": "48.201777,11.586149"
  },
  {
    "Gas Station Number": "0F896",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Hofer Straße 2 / B 303",
    "Postcode": 95502,
    "City": "Himmelkron",
    "State": "Bayern",
    "Latitude": "050.0546130",
    "Longitude": "011.6227850",
    "Telephone Number": "0927357337",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.054613,11.622785",
    "IsOpen24Hours": true,
    "id": "50.054613,11.622785"
  },
  {
    "Gas Station Number": "0F897",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Freisinger Straße 21",
    "Postcode": 84048,
    "City": "Mainburg",
    "State": "Bayern",
    "Latitude": "048.6357650",
    "Longitude": "011.7791980",
    "Telephone Number": "087515838",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "07:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "48.635765,11.779198",
    "IsOpen24Hours": false,
    "id": "48.635765,11.779198"
  },
  {
    "Gas Station Number": "0F898",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heinrich-Lanz-Straße 1",
    "Postcode": 70825,
    "City": "Korntal-Münchingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.8505880",
    "Longitude": "009.1054710",
    "Telephone Number": "07150959920",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.850588,9.105471",
    "IsOpen24Hours": true,
    "id": "48.850588,9.105471"
  },
  {
    "Gas Station Number": "0F899",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Elbestraße 23",
    "Postcode": 82538,
    "City": "Geretsried",
    "State": "Bayern",
    "Latitude": "047.8804460",
    "Longitude": "011.4608220",
    "Telephone Number": "0817164255",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.880446,11.460822",
    "IsOpen24Hours": false,
    "id": "47.880446,11.460822"
  },
  {
    "Gas Station Number": "0F900",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Forstenrieder Straße 26",
    "Postcode": 82061,
    "City": "Neuried",
    "State": "Bayern",
    "Latitude": "048.0914060",
    "Longitude": "011.4682990",
    "Telephone Number": "08975070710",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.091406,11.468299",
    "IsOpen24Hours": true,
    "id": "48.091406,11.468299"
  },
  {
    "Gas Station Number": "0F901",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sigwolfstraße 2",
    "Postcode": 85435,
    "City": "Erding",
    "State": "Bayern",
    "Latitude": "048.3148700",
    "Longitude": "011.8925670",
    "Telephone Number": "08122902786",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.31487,11.892567",
    "IsOpen24Hours": true,
    "id": "48.31487,11.892567"
  },
  {
    "Gas Station Number": "0F902",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Hauptstraße 60",
    "Postcode": 74360,
    "City": "Ilsfeld",
    "State": "Baden-Württemberg",
    "Latitude": "049.0588820",
    "Longitude": "009.2712010",
    "Telephone Number": "0706295220",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.058882,9.271201",
    "IsOpen24Hours": true,
    "id": "49.058882,9.271201"
  },
  {
    "Gas Station Number": "0F903",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gottlieb-Daimler-Straße 4",
    "Postcode": 95666,
    "City": "Mitterteich",
    "State": "Bayern",
    "Latitude": "049.9367100",
    "Longitude": "012.2270680",
    "Telephone Number": "096334000029",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.93671,12.227068",
    "IsOpen24Hours": true,
    "id": "49.93671,12.227068"
  },
  {
    "Gas Station Number": "0F904",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Landshuter Straße 42",
    "Postcode": 85368,
    "City": "Moosburg",
    "State": "Bayern",
    "Latitude": "048.4639530",
    "Longitude": "011.9398140",
    "Telephone Number": "08761729846",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.463953,11.939814",
    "IsOpen24Hours": true,
    "id": "48.463953,11.939814"
  },
  {
    "Gas Station Number": "0F905",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Pragstraße 138 A",
    "Postcode": 70376,
    "City": "Stuttgart",
    "State": "Baden-Württemberg",
    "Latitude": "048.8075380",
    "Longitude": "009.1941540",
    "Telephone Number": "07118560500",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.807538,9.194154",
    "IsOpen24Hours": true,
    "id": "48.807538,9.194154"
  },
  {
    "Gas Station Number": "0F906",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stuttgarter Straße 119",
    "Postcode": 73054,
    "City": "Eislingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.6964190",
    "Longitude": "009.6902370",
    "Telephone Number": "07161990220",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.696419,9.690237",
    "IsOpen24Hours": true,
    "id": "48.696419,9.690237"
  },
  {
    "Gas Station Number": "0F907",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Plieninger Straße 35",
    "Postcode": 73765,
    "City": "Neuhausen",
    "State": "Baden-Württemberg",
    "Latitude": "048.6912510",
    "Longitude": "009.2657360",
    "Telephone Number": "071589857104",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "05:30",
    "Saturdays To": "22:00",
    "Sundays From": "05:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.691251,9.265736",
    "IsOpen24Hours": false,
    "id": "48.691251,9.265736"
  },
  {
    "Gas Station Number": "0F908",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Laimeringer Straße 2",
    "Postcode": 86453,
    "City": "Dasing",
    "State": "Bayern",
    "Latitude": "048.3899620",
    "Longitude": "011.0634020",
    "Telephone Number": "08205969083",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.389962,11.063402",
    "IsOpen24Hours": true,
    "id": "48.389962,11.063402"
  },
  {
    "Gas Station Number": "0F909",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Enkeringer Straße 7",
    "Postcode": 85125,
    "City": "Kinding",
    "State": "Bayern",
    "Latitude": "048.9981720",
    "Longitude": "011.3778870",
    "Telephone Number": "08467805892",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.998172,11.377887",
    "IsOpen24Hours": false,
    "id": "48.998172,11.377887"
  },
  {
    "Gas Station Number": "0FG39",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Zadarstrasse 11",
    "Postcode": 82256,
    "City": "Fürstenfeldbruck",
    "State": "Bayern",
    "Latitude": "048.1775340",
    "Longitude": "011.2272860",
    "Telephone Number": "08141355400",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.177534,11.227286",
    "IsOpen24Hours": true,
    "id": "48.177534,11.227286"
  },
  {
    "Gas Station Number": "0FG31",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alte Römerstraße 50",
    "Postcode": 85221,
    "City": "Dachau",
    "State": "Bayern",
    "Latitude": "048.2596860",
    "Longitude": "011.4699370",
    "Telephone Number": "08131333352",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.259686,11.469937",
    "IsOpen24Hours": true,
    "id": "48.259686,11.469937"
  },
  {
    "Gas Station Number": "0F910",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedrichstraße 160",
    "Postcode": 71638,
    "City": "Ludwigsburg",
    "State": "Baden-Württemberg",
    "Latitude": "048.8886610",
    "Longitude": "009.2135500",
    "Telephone Number": "07141890454",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.888661,9.21355",
    "IsOpen24Hours": true,
    "id": "48.888661,9.21355"
  },
  {
    "Gas Station Number": "0F911",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heilbronner Straße 289",
    "Postcode": 70469,
    "City": "Stuttgart",
    "State": "Baden-Württemberg",
    "Latitude": "048.8126110",
    "Longitude": "009.1799960",
    "Telephone Number": "07118560056",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.812611,9.179996",
    "IsOpen24Hours": true,
    "id": "48.812611,9.179996"
  },
  {
    "Gas Station Number": "0F912",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Binderfeld 2",
    "Postcode": 84518,
    "City": "Garching",
    "State": "Bayern",
    "Latitude": "048.1259350",
    "Longitude": "012.5741690",
    "Telephone Number": "08634400",
    "Week From": "06:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Ja",
    "location": "48.125935,12.574169",
    "IsOpen24Hours": true,
    "id": "48.125935,12.574169"
  },
  {
    "Gas Station Number": "0F913",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A8",
    "Postcode": 71067,
    "City": "Sindelfingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.7425780",
    "Longitude": "009.0341820",
    "Telephone Number": "0703170810",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.742578,9.034182",
    "IsOpen24Hours": true,
    "id": "48.742578,9.034182"
  },
  {
    "Gas Station Number": "0F914",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Münchener Straße 43",
    "Postcode": 83707,
    "City": "Bad Wiessee",
    "State": "Bayern",
    "Latitude": "047.7178370",
    "Longitude": "011.7189550",
    "Telephone Number": "08022857950",
    "Week From": "06:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "47.717837,11.718955",
    "IsOpen24Hours": false,
    "id": "47.717837,11.718955"
  },
  {
    "Gas Station Number": "0F915",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nördlinger Straße 18-22",
    "Postcode": 73450,
    "City": "Neresheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.7542950",
    "Longitude": "010.3366070",
    "Telephone Number": "073269644533",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.754295,10.336607",
    "IsOpen24Hours": false,
    "id": "48.754295,10.336607"
  },
  {
    "Gas Station Number": "0FG36",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Schwarzleite 2",
    "Postcode": 91220,
    "City": "Schnaittach",
    "State": "Bayern",
    "Latitude": "049.5672910",
    "Longitude": "011.3274950",
    "Telephone Number": "091539229892",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.567291,11.327495",
    "IsOpen24Hours": true,
    "id": "49.567291,11.327495"
  },
  {
    "Gas Station Number": "0F916",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Weißenbachstr. 2",
    "Postcode": 87730,
    "City": "Bad Grönenbach",
    "State": "Bayern",
    "Latitude": "047.8839130",
    "Longitude": "010.2482170",
    "Telephone Number": "08334986108",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.883913,10.248217",
    "IsOpen24Hours": true,
    "id": "47.883913,10.248217"
  },
  {
    "Gas Station Number": "0F917",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Südpark 2",
    "Postcode": 86633,
    "City": "Neuburg",
    "State": "Bayern",
    "Latitude": "048.7201880",
    "Longitude": "011.1720390",
    "Telephone Number": "08431430346",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "22:00",
    "24 hour?": "Ja",
    "location": "48.720188,11.172039",
    "IsOpen24Hours": true,
    "id": "48.720188,11.172039"
  },
  {
    "Gas Station Number": "0F918",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Max-Planck-Straße 8",
    "Postcode": 93055,
    "City": "Regensburg",
    "State": "Bayern",
    "Latitude": "049.0001640",
    "Longitude": "012.1483210",
    "Telephone Number": "09417995752",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.000164,12.148321",
    "IsOpen24Hours": true,
    "id": "49.000164,12.148321"
  },
  {
    "Gas Station Number": "0FG20",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Linderhof 8",
    "Postcode": 88079,
    "City": "Kressbronn",
    "State": "Baden-Württemberg",
    "Latitude": "047.6078140",
    "Longitude": "009.5869660",
    "Telephone Number": "07543953347",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.607814,9.586966",
    "IsOpen24Hours": true,
    "id": "47.607814,9.586966"
  },
  {
    "Gas Station Number": "0F919",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Arnbrucker Straße 1 a",
    "Postcode": 93444,
    "City": "Bad Kötzting",
    "State": "Bayern",
    "Latitude": "049.1712600",
    "Longitude": "012.8631880",
    "Telephone Number": "09941904901",
    "Week From": "00:00",
    "Week To": "22:00",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.17126,12.863188",
    "IsOpen24Hours": true,
    "id": "49.17126,12.863188"
  },
  {
    "Gas Station Number": "0F920",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Gewerbepark Spörer Au 1",
    "Postcode": 85368,
    "City": "Wang bei Moosburg",
    "State": "Bayern",
    "Latitude": "048.4827430",
    "Longitude": "012.0005790",
    "Telephone Number": "08709263168",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.482743,12.000579",
    "IsOpen24Hours": true,
    "id": "48.482743,12.000579"
  },
  {
    "Gas Station Number": "0F921",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Aindlinger Strasse 11",
    "Postcode": 86167,
    "City": "Augsburg",
    "State": "Bayern",
    "Latitude": "048.3926920",
    "Longitude": "010.9334180",
    "Telephone Number": "08217472450",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.392692,10.933418",
    "IsOpen24Hours": true,
    "id": "48.392692,10.933418"
  },
  {
    "Gas Station Number": "0F922",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rodezstraße 2 a",
    "Postcode": 96052,
    "City": "Bamberg",
    "State": "Bayern",
    "Latitude": "049.9141370",
    "Longitude": "010.9087610",
    "Telephone Number": "09513090155",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.914137,10.908761",
    "IsOpen24Hours": true,
    "id": "49.914137,10.908761"
  },
  {
    "Gas Station Number": "0F923",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Am Eichelberg 2",
    "Postcode": 91567,
    "City": "Herrieden",
    "State": "Bayern",
    "Latitude": "049.2587520",
    "Longitude": "010.5018710",
    "Telephone Number": "09825929790",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.258752,10.501871",
    "IsOpen24Hours": true,
    "id": "49.258752,10.501871"
  },
  {
    "Gas Station Number": "0FE67",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "An der Autobahn 1",
    "Postcode": 91126,
    "City": "Kammerstein",
    "State": "Bayern",
    "Latitude": "049.3113600",
    "Longitude": "011.0022430",
    "Telephone Number": "09122879822",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.31136,11.002243",
    "IsOpen24Hours": true,
    "id": "49.31136,11.002243"
  },
  {
    "Gas Station Number": "0F925",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ohmstraße 1",
    "Postcode": 86415,
    "City": "Mering",
    "State": "Bayern",
    "Latitude": "048.2741360",
    "Longitude": "010.9778980",
    "Telephone Number": "08233781227",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.274136,10.977898",
    "IsOpen24Hours": true,
    "id": "48.274136,10.977898"
  },
  {
    "Gas Station Number": "0F926",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hochstraße12",
    "Postcode": 91325,
    "City": "Adelsdorf",
    "State": "Bayern",
    "Latitude": "049.7035010",
    "Longitude": "010.8810120",
    "Telephone Number": "09195925772",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.703501,10.881012",
    "IsOpen24Hours": false,
    "id": "49.703501,10.881012"
  },
  {
    "Gas Station Number": "0F927",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gruber Straße 22",
    "Postcode": 96271,
    "City": "Grub am Forst",
    "State": "Bayern",
    "Latitude": "050.2226290",
    "Longitude": "011.0427670",
    "Telephone Number": "095609816715",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.222629,11.042767",
    "IsOpen24Hours": true,
    "id": "50.222629,11.042767"
  },
  {
    "Gas Station Number": "0F924",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schleißheimer Straße 124",
    "Postcode": 85748,
    "City": "Garching",
    "State": "Bayern",
    "Latitude": "048.2503990",
    "Longitude": "011.6067000",
    "Telephone Number": "08932666948",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.250399,11.6067",
    "IsOpen24Hours": true,
    "id": "48.250399,11.6067"
  },
  {
    "Gas Station Number": "0FG30",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schroßlacher Straße 2",
    "Postcode": 85391,
    "City": "Allershausen",
    "State": "Bayern",
    "Latitude": "048.4284260",
    "Longitude": "011.5854410",
    "Telephone Number": "08166993709",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.428426,11.585441",
    "IsOpen24Hours": true,
    "id": "48.428426,11.585441"
  },
  {
    "Gas Station Number": "0FE95",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Jackinger Berg",
    "Postcode": 94113,
    "City": "Tiefenbach",
    "State": "Bayern",
    "Latitude": "048.6204070",
    "Longitude": "013.4161740",
    "Telephone Number": "08509937774",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.620407,13.416174",
    "IsOpen24Hours": true,
    "id": "48.620407,13.416174"
  },
  {
    "Gas Station Number": "0FE96",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bruckerstraße 34 a",
    "Postcode": 82266,
    "City": "Inning",
    "State": "Bayern",
    "Latitude": "048.0887720",
    "Longitude": "011.1509870",
    "Telephone Number": "08143997503",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "06:00",
    "Sundays To": "21:00",
    "24 hour?": "Ja",
    "location": "48.088772,11.150987",
    "IsOpen24Hours": true,
    "id": "48.088772,11.150987"
  },
  {
    "Gas Station Number": "0FE77",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "Reichsgrafenstraße 35/A3",
    "Postcode": 94036,
    "City": "Passau",
    "State": "Bayern",
    "Latitude": "048.5875790",
    "Longitude": "013.3659380",
    "Telephone Number": "085175688831",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.587579,13.365938",
    "IsOpen24Hours": true,
    "id": "48.587579,13.365938"
  },
  {
    "Gas Station Number": "0FG67",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kobelweg 64 1/4",
    "Postcode": 86156,
    "City": "Augsburg",
    "State": "Bayern",
    "Latitude": "048.3856340",
    "Longitude": "010.8511880",
    "Telephone Number": "08212401234",
    "Week From": "05:30",
    "Week To": "23:00",
    "Saturdays From": "06:30",
    "Saturdays To": "23:00",
    "Sundays From": "07:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.385634,10.851188",
    "IsOpen24Hours": false,
    "id": "48.385634,10.851188"
  },
  {
    "Gas Station Number": "0FG62",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Inninger Straße 99",
    "Postcode": 86179,
    "City": "Augsburg",
    "State": "Bayern",
    "Latitude": "048.3095440",
    "Longitude": "010.8904550",
    "Telephone Number": "08216084769",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.309544,10.890455",
    "IsOpen24Hours": true,
    "id": "48.309544,10.890455"
  },
  {
    "Gas Station Number": "0FG38",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A3",
    "Postcode": 97084,
    "City": "Würzburg",
    "State": "Bayern",
    "Latitude": "049.7521560",
    "Longitude": "009.9627980",
    "Telephone Number": "093165907",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.752156,9.962798",
    "IsOpen24Hours": true,
    "id": "49.752156,9.962798"
  },
  {
    "Gas Station Number": "0F928",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Pingsdorfer Straße 106",
    "Postcode": 50321,
    "City": "Brühl",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8206120",
    "Longitude": "006.8953210",
    "Telephone Number": "02232941130",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.820612,6.895321",
    "IsOpen24Hours": false,
    "id": "50.820612,6.895321"
  },
  {
    "Gas Station Number": "0F929",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neusser Straße 45",
    "Postcode": 52428,
    "City": "Jülich",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9280460",
    "Longitude": "006.3652750",
    "Telephone Number": "024612180",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.928046,6.365275",
    "IsOpen24Hours": true,
    "id": "50.928046,6.365275"
  },
  {
    "Gas Station Number": "0F930",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Aachener Straße 25",
    "Postcode": 50126,
    "City": "Bergheim",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9504490",
    "Longitude": "006.6255080",
    "Telephone Number": "0227141568",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.950449,6.625508",
    "IsOpen24Hours": false,
    "id": "50.950449,6.625508"
  },
  {
    "Gas Station Number": "0F932",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schleidener Straße 152",
    "Postcode": 52076,
    "City": "Aachen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7063520",
    "Longitude": "006.1818280",
    "Telephone Number": "024081460290",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.706352,6.181828",
    "IsOpen24Hours": false,
    "id": "50.706352,6.181828"
  },
  {
    "Gas Station Number": "0F933",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Aachener Straße 1100",
    "Postcode": 50858,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9383900",
    "Longitude": "006.8484110",
    "Telephone Number": "02234943601",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.93839,6.848411",
    "IsOpen24Hours": true,
    "id": "50.93839,6.848411"
  },
  {
    "Gas Station Number": "0F934",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 275",
    "Postcode": 52379,
    "City": "Langerwehe",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8166280",
    "Longitude": "006.3719370",
    "Telephone Number": "024231745",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.816628,6.371937",
    "IsOpen24Hours": false,
    "id": "50.816628,6.371937"
  },
  {
    "Gas Station Number": "0F935",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berg.-Gladbacher Str. 417",
    "Postcode": 51067,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9677940",
    "Longitude": "007.0339210",
    "Telephone Number": "0221632555",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.967794,7.033921",
    "IsOpen24Hours": true,
    "id": "50.967794,7.033921"
  },
  {
    "Gas Station Number": "0F936",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Luxemburger Straße 58",
    "Postcode": 50354,
    "City": "Hürth",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8948560",
    "Longitude": "006.9050270",
    "Telephone Number": "0223365260",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.894856,6.905027",
    "IsOpen24Hours": true,
    "id": "50.894856,6.905027"
  },
  {
    "Gas Station Number": "0F938",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Geierstraße 55",
    "Postcode": 53881,
    "City": "Euskirchen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.6293930",
    "Longitude": "006.8456240",
    "Telephone Number": "022556284",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.629393,6.845624",
    "IsOpen24Hours": true,
    "id": "50.629393,6.845624"
  },
  {
    "Gas Station Number": "0F940",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Am Verteilerkreis Westsei",
    "Postcode": 50968,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8920890",
    "Longitude": "006.9663270",
    "Telephone Number": "0221381718",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.892089,6.966327",
    "IsOpen24Hours": true,
    "id": "50.892089,6.966327"
  },
  {
    "Gas Station Number": "0F941",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Potsdamer Platz 2",
    "Postcode": 53119,
    "City": "Bonn",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7430650",
    "Longitude": "007.0784590",
    "Telephone Number": "02287668731",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.743065,7.078459",
    "IsOpen24Hours": true,
    "id": "50.743065,7.078459"
  },
  {
    "Gas Station Number": "0F942",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstr. 43",
    "Postcode": 53783,
    "City": "Eitorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7737060",
    "Longitude": "007.4447560",
    "Telephone Number": "022433133",
    "Week From": "05:30",
    "Week To": "23:00",
    "Saturdays From": "06:30",
    "Saturdays To": "23:00",
    "Sundays From": "07:30",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "50.773706,7.444756",
    "IsOpen24Hours": false,
    "id": "50.773706,7.444756"
  },
  {
    "Gas Station Number": "0F943",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kölner Straße 74",
    "Postcode": 51429,
    "City": "Bergisch Gladbach",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9620310",
    "Longitude": "007.1483000",
    "Telephone Number": "0220453818",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.962031,7.1483",
    "IsOpen24Hours": false,
    "id": "50.962031,7.1483"
  },
  {
    "Gas Station Number": "0F944",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Trierer Straße 703",
    "Postcode": 52078,
    "City": "Aachen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7519800",
    "Longitude": "006.1593200",
    "Telephone Number": "0241522087",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.75198,6.15932",
    "IsOpen24Hours": false,
    "id": "50.75198,6.15932"
  },
  {
    "Gas Station Number": "0F945",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Römerstraße 102-110",
    "Postcode": 53111,
    "City": "Bonn",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7465750",
    "Longitude": "007.0996090",
    "Telephone Number": "0228636321",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.746575,7.099609",
    "IsOpen24Hours": false,
    "id": "50.746575,7.099609"
  },
  {
    "Gas Station Number": "0F946",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 384",
    "Postcode": 52146,
    "City": "Würselen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8093850",
    "Longitude": "006.1441590",
    "Telephone Number": "0240592738",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.809385,6.144159",
    "IsOpen24Hours": true,
    "id": "50.809385,6.144159"
  },
  {
    "Gas Station Number": "0F947",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rottbitzer Straße 32",
    "Postcode": 53604,
    "City": "Bad Honnef",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.6466970",
    "Longitude": "007.3140590",
    "Telephone Number": "0222480045",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "05:30",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.646697,7.314059",
    "IsOpen24Hours": false,
    "id": "50.646697,7.314059"
  },
  {
    "Gas Station Number": "0F949",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kölner Straße 13",
    "Postcode": 53937,
    "City": "Schleiden",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.5706950",
    "Longitude": "006.5061200",
    "Telephone Number": "024442395",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.570695,6.50612",
    "IsOpen24Hours": false,
    "id": "50.570695,6.50612"
  },
  {
    "Gas Station Number": "0F952",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Altenberger Dom-Straße 2",
    "Postcode": 51519,
    "City": "Odenthal",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0313890",
    "Longitude": "007.1113780",
    "Telephone Number": "0220271845",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.031389,7.111378",
    "IsOpen24Hours": false,
    "id": "51.031389,7.111378"
  },
  {
    "Gas Station Number": "0F950",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Odenthaler Straße 205",
    "Postcode": 51467,
    "City": "Bergisch Gladbach",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0001980",
    "Longitude": "007.1312040",
    "Telephone Number": "0220235627",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.000198,7.131204",
    "IsOpen24Hours": false,
    "id": "51.000198,7.131204"
  },
  {
    "Gas Station Number": "0F951",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Königswinterer Straße 273",
    "Postcode": 53639,
    "City": "Königswinter",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.6809750",
    "Longitude": "007.2726250",
    "Telephone Number": "02223900971",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.680975,7.272625",
    "IsOpen24Hours": false,
    "id": "50.680975,7.272625"
  },
  {
    "Gas Station Number": "0F953",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heerstraße 88",
    "Postcode": 53894,
    "City": "Mechernich",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.5883520",
    "Longitude": "006.6471330",
    "Telephone Number": "0244331365",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.588352,6.647133",
    "IsOpen24Hours": false,
    "id": "50.588352,6.647133"
  },
  {
    "Gas Station Number": "0F956",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Krefelder Straße 17",
    "Postcode": 52146,
    "City": "Würselen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8267540",
    "Longitude": "006.1280960",
    "Telephone Number": "0240582575",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.826754,6.128096",
    "IsOpen24Hours": true,
    "id": "50.826754,6.128096"
  },
  {
    "Gas Station Number": "0F957",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Landsberger Straße 2",
    "Postcode": 53119,
    "City": "Bonn",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7474920",
    "Longitude": "007.0673350",
    "Telephone Number": "0228661806",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.747492,7.067335",
    "IsOpen24Hours": false,
    "id": "50.747492,7.067335"
  },
  {
    "Gas Station Number": "0F959",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rösrather Straße 521B/ Lü",
    "Postcode": 51107,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9236070",
    "Longitude": "007.0792320",
    "Telephone Number": "0221868077",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.923607,7.079232",
    "IsOpen24Hours": false,
    "id": "50.923607,7.079232"
  },
  {
    "Gas Station Number": "0F960",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedrich-Ebert-Allee 97",
    "Postcode": 52066,
    "City": "Aachen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7627000",
    "Longitude": "006.0969540",
    "Telephone Number": "024166204",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.7627,6.096954",
    "IsOpen24Hours": false,
    "id": "50.7627,6.096954"
  },
  {
    "Gas Station Number": "0F962",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kölnstraße 1",
    "Postcode": 50999,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8697950",
    "Longitude": "007.0055830",
    "Telephone Number": "02236962590",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.869795,7.005583",
    "IsOpen24Hours": true,
    "id": "50.869795,7.005583"
  },
  {
    "Gas Station Number": "0F963",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Venloer Straße 39",
    "Postcode": 50259,
    "City": "Pulheim",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9953720",
    "Longitude": "006.8103290",
    "Telephone Number": "0223854747",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.995372,6.810329",
    "IsOpen24Hours": true,
    "id": "50.995372,6.810329"
  },
  {
    "Gas Station Number": "0F964",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Amsterdamer Straße 194",
    "Postcode": 50735,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9747470",
    "Longitude": "006.9703220",
    "Telephone Number": "02217127826",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.974747,6.970322",
    "IsOpen24Hours": true,
    "id": "50.974747,6.970322"
  },
  {
    "Gas Station Number": "0F965",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bonner Straße 2",
    "Postcode": 51379,
    "City": "Leverkusen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0668920",
    "Longitude": "006.9910810",
    "Telephone Number": "02171715830",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.066892,6.991081",
    "IsOpen24Hours": true,
    "id": "51.066892,6.991081"
  },
  {
    "Gas Station Number": "0F966",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dünweg 55",
    "Postcode": 51399,
    "City": "Burscheid",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0938020",
    "Longitude": "007.1450460",
    "Telephone Number": "02174786368",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.093802,7.145046",
    "IsOpen24Hours": false,
    "id": "51.093802,7.145046"
  },
  {
    "Gas Station Number": "0F967",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Römerstraße 30",
    "Postcode": 52382,
    "City": "Niederzier",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8666460",
    "Longitude": "006.4349190",
    "Telephone Number": "024283021",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.866646,6.434919",
    "IsOpen24Hours": true,
    "id": "50.866646,6.434919"
  },
  {
    "Gas Station Number": "0F969",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Longericher Straße 543",
    "Postcode": 50739,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9989090",
    "Longitude": "006.9120420",
    "Telephone Number": "02215992601",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.998909,6.912042",
    "IsOpen24Hours": false,
    "id": "50.998909,6.912042"
  },
  {
    "Gas Station Number": "0F971",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Willy-Brandt-Ring  101",
    "Postcode": 51375,
    "City": "Leverkusen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0273380",
    "Longitude": "007.0420830",
    "Telephone Number": "021453024",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.027338,7.042083",
    "IsOpen24Hours": false,
    "id": "51.027338,7.042083"
  },
  {
    "Gas Station Number": "0F972",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kerpener Straße  185",
    "Postcode": 50170,
    "City": "Kerpen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8973370",
    "Longitude": "006.6837570",
    "Telephone Number": "0227351765",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.897337,6.683757",
    "IsOpen24Hours": true,
    "id": "50.897337,6.683757"
  },
  {
    "Gas Station Number": "0F973",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Im Wingert 20",
    "Postcode": 53115,
    "City": "Bonn",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7180980",
    "Longitude": "007.0910640",
    "Telephone Number": "0228212348",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.718098,7.091064",
    "IsOpen24Hours": false,
    "id": "50.718098,7.091064"
  },
  {
    "Gas Station Number": "0F975",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Waldnieler Straße 60",
    "Postcode": 41068,
    "City": "Mönchengladbach",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1948290",
    "Longitude": "006.4196370",
    "Telephone Number": "02161837907",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.194829,6.419637",
    "IsOpen24Hours": true,
    "id": "51.194829,6.419637"
  },
  {
    "Gas Station Number": "0F976",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ringstraße 30",
    "Postcode": 45219,
    "City": "Essen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3613930",
    "Longitude": "006.9436230",
    "Telephone Number": "020545132",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.361393,6.943623",
    "IsOpen24Hours": true,
    "id": "51.361393,6.943623"
  },
  {
    "Gas Station Number": "0F977",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kölner Straße 107",
    "Postcode": 41199,
    "City": "Mönchengladbach",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1279340",
    "Longitude": "006.4535920",
    "Telephone Number": "02166601018",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.127934,6.453592",
    "IsOpen24Hours": false,
    "id": "51.127934,6.453592"
  },
  {
    "Gas Station Number": "0F978",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Eickener Straße 357",
    "Postcode": 41063,
    "City": "Mönchengladbach",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2123240",
    "Longitude": "006.4481930",
    "Telephone Number": "02161184615",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.212324,6.448193",
    "IsOpen24Hours": true,
    "id": "51.212324,6.448193"
  },
  {
    "Gas Station Number": "0F979",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Roermonder Straße 2",
    "Postcode": 41372,
    "City": "Niederkrüchten",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2107760",
    "Longitude": "006.1432330",
    "Telephone Number": "0216382397",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "51.210776,6.143233",
    "IsOpen24Hours": false,
    "id": "51.210776,6.143233"
  },
  {
    "Gas Station Number": "0F980",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lauerstraße 12",
    "Postcode": 41812,
    "City": "Erkelenz",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0963170",
    "Longitude": "006.2154890",
    "Telephone Number": "024326252",
    "Week From": "04:45",
    "Week To": "20:00",
    "Saturdays From": "05:30",
    "Saturdays To": "19:00",
    "Sundays From": "08:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "51.096317,6.215489",
    "IsOpen24Hours": false,
    "id": "51.096317,6.215489"
  },
  {
    "Gas Station Number": "0F981",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gladbacher Straße 21",
    "Postcode": 41849,
    "City": "Wassenberg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1072430",
    "Longitude": "006.1785860",
    "Telephone Number": "0243220725",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.107243,6.178586",
    "IsOpen24Hours": false,
    "id": "51.107243,6.178586"
  },
  {
    "Gas Station Number": "0F982",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Liedberger Straße 25",
    "Postcode": 41238,
    "City": "Mönchengladbach",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1563620",
    "Longitude": "006.5073810",
    "Telephone Number": "0216682959",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.156362,6.507381",
    "IsOpen24Hours": true,
    "id": "51.156362,6.507381"
  },
  {
    "Gas Station Number": "0F983",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Beecker Straße 79",
    "Postcode": 41844,
    "City": "Wegberg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1381920",
    "Longitude": "006.2873540",
    "Telephone Number": "024343371",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.138192,6.287354",
    "IsOpen24Hours": false,
    "id": "51.138192,6.287354"
  },
  {
    "Gas Station Number": "0F984",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Vorster Straße 384",
    "Postcode": 41169,
    "City": "Mönchengladbach",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2012650",
    "Longitude": "006.3486300",
    "Telephone Number": "02161559241",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.201265,6.34863",
    "IsOpen24Hours": false,
    "id": "51.201265,6.34863"
  },
  {
    "Gas Station Number": "0F987",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße2",
    "Postcode": 51674,
    "City": "Wiehl",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9492200",
    "Longitude": "007.5450730",
    "Telephone Number": "022627074607",
    "Week From": "06:00",
    "Week To": "21:50",
    "Saturdays From": "07:00",
    "Saturdays To": "21:50",
    "Sundays From": "07:00",
    "Sundays To": "21:50",
    "24 hour?": "Nein",
    "location": "50.94922,7.545073",
    "IsOpen24Hours": false,
    "id": "50.94922,7.545073"
  },
  {
    "Gas Station Number": "0F985",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Krefelder Straße 472",
    "Postcode": 41066,
    "City": "Mönchengladbach",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2182900",
    "Longitude": "006.4773630",
    "Telephone Number": "02161602426",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.21829,6.477363",
    "IsOpen24Hours": true,
    "id": "51.21829,6.477363"
  },
  {
    "Gas Station Number": "0F986",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Marienburger Straße 3",
    "Postcode": 41063,
    "City": "Mönchengladbach",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2031040",
    "Longitude": "006.4189790",
    "Telephone Number": "0216186849",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.203104,6.418979",
    "IsOpen24Hours": false,
    "id": "51.203104,6.418979"
  },
  {
    "Gas Station Number": "0F988",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Brückenstraße 83",
    "Postcode": 51643,
    "City": "Gummersbach",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0237030",
    "Longitude": "007.5554550",
    "Telephone Number": "0226165147",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.023703,7.555455",
    "IsOpen24Hours": false,
    "id": "51.023703,7.555455"
  },
  {
    "Gas Station Number": "0F990",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hardt 76",
    "Postcode": 40764,
    "City": "Langenfeld",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1194740",
    "Longitude": "006.9735700",
    "Telephone Number": "02173907790",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.119474,6.97357",
    "IsOpen24Hours": false,
    "id": "51.119474,6.97357"
  },
  {
    "Gas Station Number": "0F991",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ahnfeldstraße 60",
    "Postcode": 40239,
    "City": "Düsseldorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2415910",
    "Longitude": "006.7970760",
    "Telephone Number": "0211627610",
    "Week From": "07:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.241591,6.797076",
    "IsOpen24Hours": false,
    "id": "51.241591,6.797076"
  },
  {
    "Gas Station Number": "0F992",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Linde 19",
    "Postcode": 42287,
    "City": "Wuppertal",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2336800",
    "Longitude": "007.2362600",
    "Telephone Number": "0202464793",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.23368,7.23626",
    "IsOpen24Hours": false,
    "id": "51.23368,7.23626"
  },
  {
    "Gas Station Number": "0F994",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Westring 83",
    "Postcode": 42329,
    "City": "Wuppertal",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2298790",
    "Longitude": "007.0742730",
    "Telephone Number": "0202781423",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.229879,7.074273",
    "IsOpen24Hours": true,
    "id": "51.229879,7.074273"
  },
  {
    "Gas Station Number": "0F995",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Venloer Straße 143",
    "Postcode": 41569,
    "City": "Rommerskirchen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0365580",
    "Longitude": "006.6776270",
    "Telephone Number": "021837079",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.036558,6.677627",
    "IsOpen24Hours": true,
    "id": "51.036558,6.677627"
  },
  {
    "Gas Station Number": "0FE79",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Cronenberger Straße 388",
    "Postcode": 42349,
    "City": "Wuppertal",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2290100",
    "Longitude": "007.1493880",
    "Telephone Number": "02024087581",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Ja",
    "location": "51.22901,7.149388",
    "IsOpen24Hours": true,
    "id": "51.22901,7.149388"
  },
  {
    "Gas Station Number": "0FA16",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Königswinterer Straße 409",
    "Postcode": 53227,
    "City": "Bonn",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7244290",
    "Longitude": "007.1529560",
    "Telephone Number": "0228442219",
    "Week From": "06:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "08:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "50.724429,7.152956",
    "IsOpen24Hours": false,
    "id": "50.724429,7.152956"
  },
  {
    "Gas Station Number": "0F998",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kaarster Straße 1",
    "Postcode": 41564,
    "City": "Kaarst",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2184340",
    "Longitude": "006.6059880",
    "Telephone Number": "02131605076",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.218434,6.605988",
    "IsOpen24Hours": false,
    "id": "51.218434,6.605988"
  },
  {
    "Gas Station Number": "0F999",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "In den Kötten 9",
    "Postcode": 40627,
    "City": "Düsseldorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2124100",
    "Longitude": "006.8578210",
    "Telephone Number": "0211279993",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.21241,6.857821",
    "IsOpen24Hours": true,
    "id": "51.21241,6.857821"
  },
  {
    "Gas Station Number": "0FA01",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hochdahler Straße 130",
    "Postcode": 42781,
    "City": "Haan",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1937760",
    "Longitude": "006.9879600",
    "Telephone Number": "02129342673",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.193776,6.98796",
    "IsOpen24Hours": true,
    "id": "51.193776,6.98796"
  },
  {
    "Gas Station Number": "0FA02",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Volkardeyer Straße 54",
    "Postcode": 40878,
    "City": "Ratingen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2886630",
    "Longitude": "006.8275080",
    "Telephone Number": "0210243980",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.288663,6.827508",
    "IsOpen24Hours": true,
    "id": "51.288663,6.827508"
  },
  {
    "Gas Station Number": "0FA03",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Norfer Straße 54",
    "Postcode": 41468,
    "City": "Neuss",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1742760",
    "Longitude": "006.7379470",
    "Telephone Number": "02131364947",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.174276,6.737947",
    "IsOpen24Hours": false,
    "id": "51.174276,6.737947"
  },
  {
    "Gas Station Number": "0FA04",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schulstraße 83",
    "Postcode": 41363,
    "City": "Jüchen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1140590",
    "Longitude": "006.5501570",
    "Telephone Number": "021817069775",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.114059,6.550157",
    "IsOpen24Hours": false,
    "id": "51.114059,6.550157"
  },
  {
    "Gas Station Number": "0FA05",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Elberfelder Straße 99",
    "Postcode": 42553,
    "City": "Velbert",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3079140",
    "Longitude": "007.0876100",
    "Telephone Number": "02053923666",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.307914,7.08761",
    "IsOpen24Hours": false,
    "id": "51.307914,7.08761"
  },
  {
    "Gas Station Number": "0FA08",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A3",
    "Postcode": 42697,
    "City": "Solingen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1589610",
    "Longitude": "006.9610620",
    "Telephone Number": "021274210",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.158961,6.961062",
    "IsOpen24Hours": true,
    "id": "51.158961,6.961062"
  },
  {
    "Gas Station Number": "0FA09",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friesdorfer Straße 195",
    "Postcode": 53175,
    "City": "Bonn",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.6919470",
    "Longitude": "007.1369830",
    "Telephone Number": "0228310151",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.691947,7.136983",
    "IsOpen24Hours": false,
    "id": "50.691947,7.136983"
  },
  {
    "Gas Station Number": "0FA10",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dellbrücker Straße 2-4",
    "Postcode": 51469,
    "City": "Bergisch Gladbach",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9881560",
    "Longitude": "007.0874470",
    "Telephone Number": "0220251865",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.988156,7.087447",
    "IsOpen24Hours": false,
    "id": "50.988156,7.087447"
  },
  {
    "Gas Station Number": "0FA11",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ratinger Straße 19",
    "Postcode": 42579,
    "City": "Heiligenhaus",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3219820",
    "Longitude": "006.9556890",
    "Telephone Number": "0205693030",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.321982,6.955689",
    "IsOpen24Hours": true,
    "id": "51.321982,6.955689"
  },
  {
    "Gas Station Number": "0FA12",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Volksbadstraße 1",
    "Postcode": 41065,
    "City": "Mönchengladbach",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2036760",
    "Longitude": "006.4748360",
    "Telephone Number": "02161601788",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.203676,6.474836",
    "IsOpen24Hours": false,
    "id": "51.203676,6.474836"
  },
  {
    "Gas Station Number": "0FA13",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bergerstraße 108",
    "Postcode": 51145,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8909740",
    "Longitude": "007.0678500",
    "Telephone Number": "0220332882",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.890974,7.06785",
    "IsOpen24Hours": false,
    "id": "50.890974,7.06785"
  },
  {
    "Gas Station Number": "0FA14",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bonner Straße 134",
    "Postcode": 53757,
    "City": "Sankt Augustin",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7784050",
    "Longitude": "007.1908810",
    "Telephone Number": "02241203231",
    "Week From": "06:00",
    "Week To": "23:00",
    "Saturdays From": "07:00",
    "Saturdays To": "23:00",
    "Sundays From": "07:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "50.778405,7.190881",
    "IsOpen24Hours": false,
    "id": "50.778405,7.190881"
  },
  {
    "Gas Station Number": "0FA15",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Moselstraße 2",
    "Postcode": 53842,
    "City": "Troisdorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8100690",
    "Longitude": "007.1578050",
    "Telephone Number": "02241809276",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.810069,7.157805",
    "IsOpen24Hours": false,
    "id": "50.810069,7.157805"
  },
  {
    "Gas Station Number": "0FA17",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heinsberger Straße 77",
    "Postcode": 52511,
    "City": "Geilenkirchen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9707370",
    "Longitude": "006.1196670",
    "Telephone Number": "024519149696",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.970737,6.119667",
    "IsOpen24Hours": true,
    "id": "50.970737,6.119667"
  },
  {
    "Gas Station Number": "0FA19",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Frankfurter Straße 390",
    "Postcode": 51103,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9427470",
    "Longitude": "007.0272550",
    "Telephone Number": "0221871436",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.942747,7.027255",
    "IsOpen24Hours": true,
    "id": "50.942747,7.027255"
  },
  {
    "Gas Station Number": "0FA20",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neusser Straße 856",
    "Postcode": 50737,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9931980",
    "Longitude": "006.9405700",
    "Telephone Number": "02219742284",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.993198,6.94057",
    "IsOpen24Hours": true,
    "id": "50.993198,6.94057"
  },
  {
    "Gas Station Number": "0FA21",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Olpener Straße 1026",
    "Postcode": 51109,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9468810",
    "Longitude": "007.0852260",
    "Telephone Number": "0221840873",
    "Week From": "07:00",
    "Week To": "22:00",
    "Saturdays From": "08:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.946881,7.085226",
    "IsOpen24Hours": false,
    "id": "50.946881,7.085226"
  },
  {
    "Gas Station Number": "0FA22",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Riehler Straße 12-14",
    "Postcode": 50668,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9523790",
    "Longitude": "006.9616440",
    "Telephone Number": "02219726040",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.952379,6.961644",
    "IsOpen24Hours": true,
    "id": "50.952379,6.961644"
  },
  {
    "Gas Station Number": "0FA23",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rolshover Straße 183",
    "Postcode": 51105,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9272770",
    "Longitude": "007.0002140",
    "Telephone Number": "0221836527",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.927277,7.000214",
    "IsOpen24Hours": true,
    "id": "50.927277,7.000214"
  },
  {
    "Gas Station Number": "0FA24",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Siegburger Straße 116A",
    "Postcode": 50679,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9229940",
    "Longitude": "006.9806950",
    "Telephone Number": "0221812107",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.922994,6.980695",
    "IsOpen24Hours": true,
    "id": "50.922994,6.980695"
  },
  {
    "Gas Station Number": "0FA25",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Zollstockgürtel 39",
    "Postcode": 50969,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9057840",
    "Longitude": "006.9378630",
    "Telephone Number": "0221364366",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.905784,6.937863",
    "IsOpen24Hours": true,
    "id": "50.905784,6.937863"
  },
  {
    "Gas Station Number": "0FA27",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berghauser Straße 10",
    "Postcode": 42349,
    "City": "Wuppertal",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2026950",
    "Longitude": "007.1311140",
    "Telephone Number": "02022471654",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.202695,7.131114",
    "IsOpen24Hours": false,
    "id": "51.202695,7.131114"
  },
  {
    "Gas Station Number": "0FA28",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Roermonder Straße 41",
    "Postcode": 52525,
    "City": "Heinsberg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0941570",
    "Longitude": "006.0819700",
    "Telephone Number": "024527601",
    "Week From": "07:00",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.094157,6.08197",
    "IsOpen24Hours": false,
    "id": "51.094157,6.08197"
  },
  {
    "Gas Station Number": "0FA29",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Südring 115",
    "Postcode": 40221,
    "City": "Düsseldorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2010120",
    "Longitude": "006.7632590",
    "Telephone Number": "0211392503",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.201012,6.763259",
    "IsOpen24Hours": true,
    "id": "51.201012,6.763259"
  },
  {
    "Gas Station Number": "0FA30",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dormagener Straße 20",
    "Postcode": 50129,
    "City": "Bergheim",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9865010",
    "Longitude": "006.6716750",
    "Telephone Number": "022716785851",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.986501,6.671675",
    "IsOpen24Hours": false,
    "id": "50.986501,6.671675"
  },
  {
    "Gas Station Number": "0FA42",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nordheide 21",
    "Postcode": 27798,
    "City": "Hude",
    "State": "Niedersachsen",
    "Latitude": "053.1098470",
    "Longitude": "008.4660420",
    "Telephone Number": "04408939918",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "22:00",
    "Sundays From": "09:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "53.109847,8.466042",
    "IsOpen24Hours": false,
    "id": "53.109847,8.466042"
  },
  {
    "Gas Station Number": "0FA43",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Osnabrücker Straße 13",
    "Postcode": 49525,
    "City": "Lengerich",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.1860980",
    "Longitude": "007.8615570",
    "Telephone Number": "054814793",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.186098,7.861557",
    "IsOpen24Hours": true,
    "id": "52.186098,7.861557"
  },
  {
    "Gas Station Number": "0FA31",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bergische Landstraße 605",
    "Postcode": 40629,
    "City": "Düsseldorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2605090",
    "Longitude": "006.9087240",
    "Telephone Number": "02112913810",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.260509,6.908724",
    "IsOpen24Hours": true,
    "id": "51.260509,6.908724"
  },
  {
    "Gas Station Number": "0FA32",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bottroper Straße 298 a",
    "Postcode": 45356,
    "City": "Essen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4843660",
    "Longitude": "006.9757810",
    "Telephone Number": "02016143248",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "05:00",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "51.484366,6.975781",
    "IsOpen24Hours": false,
    "id": "51.484366,6.975781"
  },
  {
    "Gas Station Number": "0FG26",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wuppertaler Straße 110",
    "Postcode": 42653,
    "City": "Solingen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1993200",
    "Longitude": "007.0751240",
    "Telephone Number": "02122571905",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.19932,7.075124",
    "IsOpen24Hours": true,
    "id": "51.19932,7.075124"
  },
  {
    "Gas Station Number": "0FA33",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sachsstraße 2",
    "Postcode": 50259,
    "City": "Pulheim",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9574610",
    "Longitude": "006.8012790",
    "Telephone Number": "02234698914",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.957461,6.801279",
    "IsOpen24Hours": true,
    "id": "50.957461,6.801279"
  },
  {
    "Gas Station Number": "0FE83",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kempener Straße 83",
    "Postcode": 41334,
    "City": "Nettetal",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3104130",
    "Longitude": "006.2918140",
    "Telephone Number": "021532387",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.310413,6.291814",
    "IsOpen24Hours": true,
    "id": "51.310413,6.291814"
  },
  {
    "Gas Station Number": "0FF02",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nordstraße 176",
    "Postcode": 52353,
    "City": "Düren",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8324290",
    "Longitude": "006.4586060",
    "Telephone Number": "02421204542",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.832429,6.458606",
    "IsOpen24Hours": true,
    "id": "50.832429,6.458606"
  },
  {
    "Gas Station Number": "0FE80",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 61 Ost",
    "Postcode": 50181,
    "City": "Bedburg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0048630",
    "Longitude": "006.5221440",
    "Telephone Number": "02272904330",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.004863,6.522144",
    "IsOpen24Hours": true,
    "id": "51.004863,6.522144"
  },
  {
    "Gas Station Number": "0FA34",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kurfürstenallee 49",
    "Postcode": 28329,
    "City": "Bremen",
    "State": "Bremen",
    "Latitude": "053.0820460",
    "Longitude": "008.8551550",
    "Telephone Number": "0421444244",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.082046,8.855155",
    "IsOpen24Hours": true,
    "id": "53.082046,8.855155"
  },
  {
    "Gas Station Number": "0FA35",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bremer Straße 72",
    "Postcode": 26135,
    "City": "Oldenburg",
    "State": "Niedersachsen",
    "Latitude": "053.1292610",
    "Longitude": "008.2272420",
    "Telephone Number": "04419987199",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.129261,8.227242",
    "IsOpen24Hours": true,
    "id": "53.129261,8.227242"
  },
  {
    "Gas Station Number": "0FA36",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ostertorstraße 45",
    "Postcode": 26670,
    "City": "Uplengen-Remels",
    "State": "Niedersachsen",
    "Latitude": "053.3053170",
    "Longitude": "007.7463610",
    "Telephone Number": "04956927799",
    "Week From": "04:00",
    "Week To": "22:00",
    "Saturdays From": "04:00",
    "Saturdays To": "22:00",
    "Sundays From": "04:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.305317,7.746361",
    "IsOpen24Hours": false,
    "id": "53.305317,7.746361"
  },
  {
    "Gas Station Number": "0FA39",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Leerer Landstraße 27",
    "Postcode": 26603,
    "City": "Aurich",
    "State": "Niedersachsen",
    "Latitude": "053.4646770",
    "Longitude": "007.4877070",
    "Telephone Number": "049412385",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "08:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "53.464677,7.487707",
    "IsOpen24Hours": false,
    "id": "53.464677,7.487707"
  },
  {
    "Gas Station Number": "0FA40",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neuschanzer Straße 37",
    "Postcode": 26831,
    "City": "Bunde",
    "State": "Niedersachsen",
    "Latitude": "053.1821080",
    "Longitude": "007.2613360",
    "Telephone Number": "04953406",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.182108,7.261336",
    "IsOpen24Hours": false,
    "id": "53.182108,7.261336"
  },
  {
    "Gas Station Number": "0FA41",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Grosse Straße 2",
    "Postcode": 49134,
    "City": "Wallenhorst",
    "State": "Niedersachsen",
    "Latitude": "052.3473410",
    "Longitude": "008.0183240",
    "Telephone Number": "0540739522",
    "Week From": "06:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "08:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "52.347341,8.018324",
    "IsOpen24Hours": false,
    "id": "52.347341,8.018324"
  },
  {
    "Gas Station Number": "0FA44",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der alten Bundessstraße 1a",
    "Postcode": 26419,
    "City": "Schortens",
    "State": "Niedersachsen",
    "Latitude": "053.5422620",
    "Longitude": "007.9525220",
    "Telephone Number": "044618634",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "05:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "53.542262,7.952522",
    "IsOpen24Hours": false,
    "id": "53.542262,7.952522"
  },
  {
    "Gas Station Number": "0FA45",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Meller Landstraße 66",
    "Postcode": 49086,
    "City": "Osnabrück",
    "State": "Niedersachsen",
    "Latitude": "052.2507280",
    "Longitude": "008.1036040",
    "Telephone Number": "0541389458",
    "Week From": "06:00",
    "Week To": "21:30",
    "Saturdays From": "07:00",
    "Saturdays To": "21:30",
    "Sundays From": "08:00",
    "Sundays To": "21:30",
    "24 hour?": "Nein",
    "location": "52.250728,8.103604",
    "IsOpen24Hours": false,
    "id": "52.250728,8.103604"
  },
  {
    "Gas Station Number": "0FA46",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schlossallee 6",
    "Postcode": 49751,
    "City": "Sögel",
    "State": "Niedersachsen",
    "Latitude": "052.8397850",
    "Longitude": "007.5258260",
    "Telephone Number": "059521433",
    "Week From": "06:00",
    "Week To": "23:00",
    "Saturdays From": "07:00",
    "Saturdays To": "23:00",
    "Sundays From": "08:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "52.839785,7.525826",
    "IsOpen24Hours": false,
    "id": "52.839785,7.525826"
  },
  {
    "Gas Station Number": "0FA47",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Auricher Landstraße 16",
    "Postcode": 26629,
    "City": "Großefehn",
    "State": "Niedersachsen",
    "Latitude": "053.3967110",
    "Longitude": "007.5688140",
    "Telephone Number": "04943990303",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.396711,7.568814",
    "IsOpen24Hours": false,
    "id": "53.396711,7.568814"
  },
  {
    "Gas Station Number": "0FA48",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Jeverstraße 9",
    "Postcode": 26409,
    "City": "Wittmund",
    "State": "Niedersachsen",
    "Latitude": "053.5763780",
    "Longitude": "007.7923240",
    "Telephone Number": "044625511",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.576378,7.792324",
    "IsOpen24Hours": false,
    "id": "53.576378,7.792324"
  },
  {
    "Gas Station Number": "0FA49",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Huchtinger Heerstraße 4",
    "Postcode": 28259,
    "City": "Bremen",
    "State": "Bremen",
    "Latitude": "053.0537810",
    "Longitude": "008.7407790",
    "Telephone Number": "0421581445",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.053781,8.740779",
    "IsOpen24Hours": true,
    "id": "53.053781,8.740779"
  },
  {
    "Gas Station Number": "0FA50",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Malgartenerstraße 2",
    "Postcode": 49565,
    "City": "Bramsche",
    "State": "Niedersachsen",
    "Latitude": "052.4050210",
    "Longitude": "007.9884310",
    "Telephone Number": "054619951818",
    "Week From": "07:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.405021,7.988431",
    "IsOpen24Hours": false,
    "id": "52.405021,7.988431"
  },
  {
    "Gas Station Number": "0FA51",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lange Straße 65",
    "Postcode": 27318,
    "City": "Hoya",
    "State": "Niedersachsen",
    "Latitude": "052.8049580",
    "Longitude": "009.1358220",
    "Telephone Number": "042512800",
    "Week From": "06:00",
    "Week To": "23:59",
    "Saturdays From": "07:00",
    "Saturdays To": "23:59",
    "Sundays From": "07:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "52.804958,9.135822",
    "IsOpen24Hours": false,
    "id": "52.804958,9.135822"
  },
  {
    "Gas Station Number": "0FA52",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Weserstraße 46",
    "Postcode": 32547,
    "City": "Bad Oeynhausen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.1979940",
    "Longitude": "008.8093940",
    "Telephone Number": "057313848",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.197994,8.809394",
    "IsOpen24Hours": false,
    "id": "52.197994,8.809394"
  },
  {
    "Gas Station Number": "0FA53",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Esenser Straße 109-111",
    "Postcode": 26607,
    "City": "Aurich",
    "State": "Niedersachsen",
    "Latitude": "053.4824000",
    "Longitude": "007.4903950",
    "Telephone Number": "0494171435",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.4824,7.490395",
    "IsOpen24Hours": true,
    "id": "53.4824,7.490395"
  },
  {
    "Gas Station Number": "0FA54",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Meppener Straße 69",
    "Postcode": 49808,
    "City": "Lingen",
    "State": "Niedersachsen",
    "Latitude": "052.5313770",
    "Longitude": "007.3100460",
    "Telephone Number": "059163082",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.531377,7.310046",
    "IsOpen24Hours": true,
    "id": "52.531377,7.310046"
  },
  {
    "Gas Station Number": "0FA55",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Auricher Straße 2",
    "Postcode": 26835,
    "City": "Hesel",
    "State": "Niedersachsen",
    "Latitude": "053.3056650",
    "Longitude": "007.5938390",
    "Telephone Number": "049502034",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.305665,7.593839",
    "IsOpen24Hours": true,
    "id": "53.305665,7.593839"
  },
  {
    "Gas Station Number": "0FA56",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Grevener Damm 78",
    "Postcode": 48282,
    "City": "Emsdetten",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.1672470",
    "Longitude": "007.5422050",
    "Telephone Number": "025722540",
    "Week From": "05:30",
    "Week To": "23:00",
    "Saturdays From": "06:30",
    "Saturdays To": "23:00",
    "Sundays From": "06:30",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "52.167247,7.542205",
    "IsOpen24Hours": false,
    "id": "52.167247,7.542205"
  },
  {
    "Gas Station Number": "0FA57",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 204",
    "Postcode": 26842,
    "City": "Ostrhauderfehn",
    "State": "Niedersachsen",
    "Latitude": "053.1401100",
    "Longitude": "007.6380330",
    "Telephone Number": "049525236",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:30",
    "Saturdays To": "22:00",
    "Sundays From": "09:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "53.14011,7.638033",
    "IsOpen24Hours": false,
    "id": "53.14011,7.638033"
  },
  {
    "Gas Station Number": "0FA60",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stedinger Straße 174",
    "Postcode": 27753,
    "City": "Delmenhorst",
    "State": "Niedersachsen",
    "Latitude": "053.0654740",
    "Longitude": "008.6333120",
    "Telephone Number": "0422151566",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.065474,8.633312",
    "IsOpen24Hours": true,
    "id": "53.065474,8.633312"
  },
  {
    "Gas Station Number": "0FA62",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 78",
    "Postcode": 49824,
    "City": "Emlichheim",
    "State": "Niedersachsen",
    "Latitude": "052.6074290",
    "Longitude": "006.8390730",
    "Telephone Number": "059431946",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.607429,6.839073",
    "IsOpen24Hours": false,
    "id": "52.607429,6.839073"
  },
  {
    "Gas Station Number": "0FA63",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sudweyher Straße 78",
    "Postcode": 28844,
    "City": "Weyhe",
    "State": "Niedersachsen",
    "Latitude": "052.9830670",
    "Longitude": "008.8778030",
    "Telephone Number": "042036162",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.983067,8.877803",
    "IsOpen24Hours": false,
    "id": "52.983067,8.877803"
  },
  {
    "Gas Station Number": "0FA61",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gökerstraße/Ecke Peterstr",
    "Postcode": 26384,
    "City": "Wilhelmshaven",
    "State": "Niedersachsen",
    "Latitude": "053.5204220",
    "Longitude": "008.1235900",
    "Telephone Number": "04421994353",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.520422,8.12359",
    "IsOpen24Hours": true,
    "id": "53.520422,8.12359"
  },
  {
    "Gas Station Number": "0FA64",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dr.Warsing-Straße 209",
    "Postcode": 26802,
    "City": "Moormerland",
    "State": "Niedersachsen",
    "Latitude": "053.3086500",
    "Longitude": "007.5047250",
    "Telephone Number": "049544228",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.30865,7.504725",
    "IsOpen24Hours": false,
    "id": "53.30865,7.504725"
  },
  {
    "Gas Station Number": "0FA65",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Prinzessinweg  2",
    "Postcode": 26122,
    "City": "Oldenburg",
    "State": "Niedersachsen",
    "Latitude": "053.1433580",
    "Longitude": "008.1917740",
    "Telephone Number": "0441971971",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.143358,8.191774",
    "IsOpen24Hours": true,
    "id": "53.143358,8.191774"
  },
  {
    "Gas Station Number": "0FA66",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bünder Straße 343",
    "Postcode": 32120,
    "City": "Hiddenhausen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.1633290",
    "Longitude": "008.6348440",
    "Telephone Number": "0522161977",
    "Week From": "06:00",
    "Week To": "23:59",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "06:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "52.163329,8.634844",
    "IsOpen24Hours": false,
    "id": "52.163329,8.634844"
  },
  {
    "Gas Station Number": "0FA68",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 13",
    "Postcode": 31603,
    "City": "Diepenau-Lavelsloh",
    "State": "Niedersachsen",
    "Latitude": "052.4213010",
    "Longitude": "008.7396350",
    "Telephone Number": "057751020",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "08:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "52.421301,8.739635",
    "IsOpen24Hours": false,
    "id": "52.421301,8.739635"
  },
  {
    "Gas Station Number": "0FA69",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bremerhavener Heerstr. 48",
    "Postcode": 28717,
    "City": "Bremen",
    "State": "Bremen",
    "Latitude": "053.1731020",
    "Longitude": "008.7038320",
    "Telephone Number": "0421633622",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.173102,8.703832",
    "IsOpen24Hours": true,
    "id": "53.173102,8.703832"
  },
  {
    "Gas Station Number": "0FA70",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Osterfeuerberger Ring 65",
    "Postcode": 28219,
    "City": "Bremen",
    "State": "Bremen",
    "Latitude": "053.1027580",
    "Longitude": "008.7972150",
    "Telephone Number": "0421385309",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.102758,8.797215",
    "IsOpen24Hours": false,
    "id": "53.102758,8.797215"
  },
  {
    "Gas Station Number": "0FA71",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mindener Straße 89",
    "Postcode": 32547,
    "City": "Bad Oeynhausen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.2124910",
    "Longitude": "008.8152420",
    "Telephone Number": "0573129688",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.212491,8.815242",
    "IsOpen24Hours": true,
    "id": "52.212491,8.815242"
  },
  {
    "Gas Station Number": "0FA72",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Adelheider Straße 77",
    "Postcode": 27755,
    "City": "Delmenhorst",
    "State": "Niedersachsen",
    "Latitude": "053.0321450",
    "Longitude": "008.6216280",
    "Telephone Number": "0422123577",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.032145,8.621628",
    "IsOpen24Hours": true,
    "id": "53.032145,8.621628"
  },
  {
    "Gas Station Number": "0FA73",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bramscher Straße 267",
    "Postcode": 49090,
    "City": "Osnabrück",
    "State": "Niedersachsen",
    "Latitude": "052.3010980",
    "Longitude": "008.0346640",
    "Telephone Number": "054163204",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "07:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "52.301098,8.034664",
    "IsOpen24Hours": false,
    "id": "52.301098,8.034664"
  },
  {
    "Gas Station Number": "0FA75",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Oldenburger Straße 99",
    "Postcode": 28259,
    "City": "Bremen",
    "State": "Bremen",
    "Latitude": "053.0490930",
    "Longitude": "008.7137730",
    "Telephone Number": "0421583475",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.049093,8.713773",
    "IsOpen24Hours": true,
    "id": "53.049093,8.713773"
  },
  {
    "Gas Station Number": "0FA76",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Auricher Straße 216",
    "Postcode": 26721,
    "City": "Emden",
    "State": "Niedersachsen",
    "Latitude": "053.3910550",
    "Longitude": "007.2078460",
    "Telephone Number": "0492142563",
    "Week From": "04:00",
    "Week To": "22:00",
    "Saturdays From": "04:00",
    "Saturdays To": "22:00",
    "Sundays From": "05:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.391055,7.207846",
    "IsOpen24Hours": false,
    "id": "53.391055,7.207846"
  },
  {
    "Gas Station Number": "0FA77",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 72",
    "Postcode": 49509,
    "City": "Recke",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.3627430",
    "Longitude": "007.7187790",
    "Telephone Number": "054533410",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.362743,7.718779",
    "IsOpen24Hours": false,
    "id": "52.362743,7.718779"
  },
  {
    "Gas Station Number": "0FA78",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hundsmühler Straße 86",
    "Postcode": 26131,
    "City": "Oldenburg",
    "State": "Niedersachsen",
    "Latitude": "053.1258750",
    "Longitude": "008.1900650",
    "Telephone Number": "04419558927",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.125875,8.190065",
    "IsOpen24Hours": true,
    "id": "53.125875,8.190065"
  },
  {
    "Gas Station Number": "0FA79",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Glückaufstraße 153",
    "Postcode": 49124,
    "City": "Georgsmarienhütte",
    "State": "Niedersachsen",
    "Latitude": "052.2033800",
    "Longitude": "008.1098950",
    "Telephone Number": "054015469",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.20338,8.109895",
    "IsOpen24Hours": false,
    "id": "52.20338,8.109895"
  },
  {
    "Gas Station Number": "0FA80",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gütersloher Straße 123",
    "Postcode": 33649,
    "City": "Bielefeld",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.9904020",
    "Longitude": "008.4921460",
    "Telephone Number": "0521442240",
    "Week From": "05:00",
    "Week To": "23:30",
    "Saturdays From": "06:00",
    "Saturdays To": "23:30",
    "Sundays From": "07:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "51.990402,8.492146",
    "IsOpen24Hours": false,
    "id": "51.990402,8.492146"
  },
  {
    "Gas Station Number": "0FA81",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Paderborner Straße 324",
    "Postcode": 33689,
    "City": "Bielefeld",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.9321470",
    "Longitude": "008.6069170",
    "Telephone Number": "052057292118",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.932147,8.606917",
    "IsOpen24Hours": true,
    "id": "51.932147,8.606917"
  },
  {
    "Gas Station Number": "0FA83",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Münsterstraße63",
    "Postcode": 33775,
    "City": "Versmold",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.0386460",
    "Longitude": "008.1425150",
    "Telephone Number": "0542394330",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.038646,8.142515",
    "IsOpen24Hours": false,
    "id": "52.038646,8.142515"
  },
  {
    "Gas Station Number": "0FA84",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Enger Straße 14",
    "Postcode": 32139,
    "City": "Spenge",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.1408560",
    "Longitude": "008.4921130",
    "Telephone Number": "05225859898",
    "Week From": "06:30",
    "Week To": "21:00",
    "Saturdays From": "06:30",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "20:30",
    "24 hour?": "Nein",
    "location": "52.140856,8.492113",
    "IsOpen24Hours": false,
    "id": "52.140856,8.492113"
  },
  {
    "Gas Station Number": "0FA85",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Jöllenbecker Straße 565",
    "Postcode": 33739,
    "City": "Bielefeld",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.0950250",
    "Longitude": "008.5136640",
    "Telephone Number": "052063110",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.095025,8.513664",
    "IsOpen24Hours": false,
    "id": "52.095025,8.513664"
  },
  {
    "Gas Station Number": "0FA86",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 44",
    "Postcode": 33803,
    "City": "Steinhagen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.0091480",
    "Longitude": "008.4137930",
    "Telephone Number": "05204921937",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.009148,8.413793",
    "IsOpen24Hours": false,
    "id": "52.009148,8.413793"
  },
  {
    "Gas Station Number": "0FA87",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heeper Straße 77",
    "Postcode": 33607,
    "City": "Bielefeld",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.0217240",
    "Longitude": "008.5481290",
    "Telephone Number": "052169507",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.021724,8.548129",
    "IsOpen24Hours": true,
    "id": "52.021724,8.548129"
  },
  {
    "Gas Station Number": "0FA88",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lange Straße 100",
    "Postcode": 32139,
    "City": "Spenge",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.1480730",
    "Longitude": "008.4850030",
    "Telephone Number": "0522587720",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.148073,8.485003",
    "IsOpen24Hours": false,
    "id": "52.148073,8.485003"
  },
  {
    "Gas Station Number": "0FA89",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bielefelder Straße 108",
    "Postcode": 33790,
    "City": "Halle",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.0508650",
    "Longitude": "008.3768570",
    "Telephone Number": "052019641",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.050865,8.376857",
    "IsOpen24Hours": true,
    "id": "52.050865,8.376857"
  },
  {
    "Gas Station Number": "0FA90",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hellweg 2 b",
    "Postcode": 32825,
    "City": "Blomberg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.9395350",
    "Longitude": "009.0909210",
    "Telephone Number": "052358089",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:30",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.939535,9.090921",
    "IsOpen24Hours": false,
    "id": "51.939535,9.090921"
  },
  {
    "Gas Station Number": "0FA91",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bielefelder Straße 36",
    "Postcode": 33824,
    "City": "Werther",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.0703660",
    "Longitude": "008.4137670",
    "Telephone Number": "052033331",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.070366,8.413767",
    "IsOpen24Hours": true,
    "id": "52.070366,8.413767"
  },
  {
    "Gas Station Number": "0FA92",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Potsdamer Straße 7",
    "Postcode": 33719,
    "City": "Bielefeld",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.0278280",
    "Longitude": "008.6035130",
    "Telephone Number": "0521331355",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.027828,8.603513",
    "IsOpen24Hours": false,
    "id": "52.027828,8.603513"
  },
  {
    "Gas Station Number": "0FA93",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A1",
    "Postcode": 49545,
    "City": "Tecklenburg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.2325340",
    "Longitude": "007.8785470",
    "Telephone Number": "05456935801",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.232534,7.878547",
    "IsOpen24Hours": true,
    "id": "52.232534,7.878547"
  },
  {
    "Gas Station Number": "0FA94",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stieghorster Straße 42",
    "Postcode": 33605,
    "City": "Bielefeld",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.0018650",
    "Longitude": "008.5892850",
    "Telephone Number": "0521200809",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "52.001865,8.589285",
    "IsOpen24Hours": false,
    "id": "52.001865,8.589285"
  },
  {
    "Gas Station Number": "0FA95",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Lehester Deich 62",
    "Postcode": 28357,
    "City": "Bremen",
    "State": "Bremen",
    "Latitude": "053.1163670",
    "Longitude": "008.8999660",
    "Telephone Number": "0421270076",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.116367,8.899966",
    "IsOpen24Hours": false,
    "id": "53.116367,8.899966"
  },
  {
    "Gas Station Number": "0FA96",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heidenoldendorfer Str.16",
    "Postcode": 32758,
    "City": "Detmold",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.9413670",
    "Longitude": "008.8594170",
    "Telephone Number": "0523168884",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "51.941367,8.859417",
    "IsOpen24Hours": false,
    "id": "51.941367,8.859417"
  },
  {
    "Gas Station Number": "0FA97",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Burgstraße 89",
    "Postcode": 49413,
    "City": "Dinklage",
    "State": "Niedersachsen",
    "Latitude": "052.6602000",
    "Longitude": "008.1362450",
    "Telephone Number": "04443736",
    "Week From": "06:00",
    "Week To": "21:30",
    "Saturdays From": "07:00",
    "Saturdays To": "21:30",
    "Sundays From": "09:00",
    "Sundays To": "21:30",
    "24 hour?": "Nein",
    "location": "52.6602,8.136245",
    "IsOpen24Hours": false,
    "id": "52.6602,8.136245"
  },
  {
    "Gas Station Number": "0FA98",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heisfelder Straße 212",
    "Postcode": 26789,
    "City": "Leer",
    "State": "Niedersachsen",
    "Latitude": "053.2473460",
    "Longitude": "007.4584820",
    "Telephone Number": "04913584",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.247346,7.458482",
    "IsOpen24Hours": true,
    "id": "53.247346,7.458482"
  },
  {
    "Gas Station Number": "0FA99",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bremer Straße 20",
    "Postcode": 49624,
    "City": "Löningen",
    "State": "Niedersachsen",
    "Latitude": "052.7382010",
    "Longitude": "007.7604900",
    "Telephone Number": "054323228",
    "Week From": "06:30",
    "Week To": "21:30",
    "Saturdays From": "07:00",
    "Saturdays To": "21:30",
    "Sundays From": "08:30",
    "Sundays To": "21:30",
    "24 hour?": "Nein",
    "location": "52.738201,7.76049",
    "IsOpen24Hours": false,
    "id": "52.738201,7.76049"
  },
  {
    "Gas Station Number": "0FB01",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Denekamper Straße 15",
    "Postcode": 48529,
    "City": "Nordhorn",
    "State": "Niedersachsen",
    "Latitude": "052.4289750",
    "Longitude": "007.0623360",
    "Telephone Number": "059215341",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.428975,7.062336",
    "IsOpen24Hours": true,
    "id": "52.428975,7.062336"
  },
  {
    "Gas Station Number": "0FB02",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alte Poststraße 2",
    "Postcode": 49074,
    "City": "Osnabrück",
    "State": "Niedersachsen",
    "Latitude": "052.2769450",
    "Longitude": "008.0552640",
    "Telephone Number": "054121730",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.276945,8.055264",
    "IsOpen24Hours": true,
    "id": "52.276945,8.055264"
  },
  {
    "Gas Station Number": "0F462",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stolzenauer Landstraße 10",
    "Postcode": 31595,
    "City": "Steyerberg",
    "State": "Niedersachsen",
    "Latitude": "052.5637920",
    "Longitude": "009.0287060",
    "Telephone Number": "05764314",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:30",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "52.563792,9.028706",
    "IsOpen24Hours": false,
    "id": "52.563792,9.028706"
  },
  {
    "Gas Station Number": "0FB03",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bismarckstraße 147",
    "Postcode": 26382,
    "City": "Wilhelmshaven",
    "State": "Niedersachsen",
    "Latitude": "053.5282440",
    "Longitude": "008.1149080",
    "Telephone Number": "0442133455",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.528244,8.114908",
    "IsOpen24Hours": false,
    "id": "53.528244,8.114908"
  },
  {
    "Gas Station Number": "0FB04",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kirchdornberger Straße 85",
    "Postcode": 33619,
    "City": "Bielefeld",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.0489000",
    "Longitude": "008.4524100",
    "Telephone Number": "0521101266",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "52.0489,8.45241",
    "IsOpen24Hours": false,
    "id": "52.0489,8.45241"
  },
  {
    "Gas Station Number": "0FB05",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mindener Straße 115",
    "Postcode": 32049,
    "City": "Herford",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.1290690",
    "Longitude": "008.6867500",
    "Telephone Number": "0522124623",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.129069,8.68675",
    "IsOpen24Hours": true,
    "id": "52.129069,8.68675"
  },
  {
    "Gas Station Number": "0FB06",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Königstraße 57",
    "Postcode": 32584,
    "City": "Löhne",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.1911020",
    "Longitude": "008.7143150",
    "Telephone Number": "057322225",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.191102,8.714315",
    "IsOpen24Hours": false,
    "id": "52.191102,8.714315"
  },
  {
    "Gas Station Number": "0FB07",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wüllener Straße 85",
    "Postcode": 48683,
    "City": "Ahaus",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.0729000",
    "Longitude": "006.9958210",
    "Telephone Number": "02561971191",
    "Week From": "06:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "07:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "52.0729,6.995821",
    "IsOpen24Hours": false,
    "id": "52.0729,6.995821"
  },
  {
    "Gas Station Number": "0FB08",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Altenberger Straße 34",
    "Postcode": 48161,
    "City": "Münster",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.9997100",
    "Longitude": "007.5583830",
    "Telephone Number": "025339355140",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.99971,7.558383",
    "IsOpen24Hours": false,
    "id": "51.99971,7.558383"
  },
  {
    "Gas Station Number": "0FB09",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wisbyring 15",
    "Postcode": 59494,
    "City": "Soest",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5627340",
    "Longitude": "008.1029260",
    "Telephone Number": "0292173330",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.562734,8.102926",
    "IsOpen24Hours": false,
    "id": "51.562734,8.102926"
  },
  {
    "Gas Station Number": "0FB10",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Steinfurter Straße 1-3",
    "Postcode": 48149,
    "City": "Münster",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.9672650",
    "Longitude": "007.6143080",
    "Telephone Number": "025122822",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.967265,7.614308",
    "IsOpen24Hours": true,
    "id": "51.967265,7.614308"
  },
  {
    "Gas Station Number": "0FB11",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alleestraße 92",
    "Postcode": 59065,
    "City": "Hamm",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6721030",
    "Longitude": "007.8147060",
    "Telephone Number": "0238151773",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.672103,7.814706",
    "IsOpen24Hours": true,
    "id": "51.672103,7.814706"
  },
  {
    "Gas Station Number": "0FB12",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Werner Straße 25",
    "Postcode": 59387,
    "City": "Ascheberg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.7352520",
    "Longitude": "007.6628610",
    "Telephone Number": "02599449",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.735252,7.662861",
    "IsOpen24Hours": false,
    "id": "51.735252,7.662861"
  },
  {
    "Gas Station Number": "0FB14",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Werler Straße 390",
    "Postcode": 59069,
    "City": "Hamm",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6167900",
    "Longitude": "007.8489000",
    "Telephone Number": "023852393",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.61679,7.8489",
    "IsOpen24Hours": true,
    "id": "51.61679,7.8489"
  },
  {
    "Gas Station Number": "0FB15",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hammer Straße 231",
    "Postcode": 59075,
    "City": "Hamm",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6899950",
    "Longitude": "007.7751060",
    "Telephone Number": "02381788669",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.689995,7.775106",
    "IsOpen24Hours": true,
    "id": "51.689995,7.775106"
  },
  {
    "Gas Station Number": "0FB16",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lippstädter Straße 23",
    "Postcode": 59329,
    "City": "Wadersloh",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.7088400",
    "Longitude": "008.2601270",
    "Telephone Number": "025238474",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.70884,8.260127",
    "IsOpen24Hours": false,
    "id": "51.70884,8.260127"
  },
  {
    "Gas Station Number": "0FB17",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Münsterstraße 60",
    "Postcode": 48727,
    "City": "Billerbeck",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.9742260",
    "Longitude": "007.2980360",
    "Telephone Number": "02543232393",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "09:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.974226,7.298036",
    "IsOpen24Hours": false,
    "id": "51.974226,7.298036"
  },
  {
    "Gas Station Number": "0FB18",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Clarholzer Straße 73",
    "Postcode": 33442,
    "City": "Herzebrock-Clarholz",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.8834020",
    "Longitude": "008.2322160",
    "Telephone Number": "052452066",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "08:00",
    "Saturdays To": "20:00",
    "Sundays From": "09:30",
    "Sundays To": "14:00",
    "24 hour?": "Nein",
    "location": "51.883402,8.232216",
    "IsOpen24Hours": false,
    "id": "51.883402,8.232216"
  },
  {
    "Gas Station Number": "0FB19",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neubeckumer Straße 72",
    "Postcode": 59269,
    "City": "Beckum",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.7656580",
    "Longitude": "008.0424670",
    "Telephone Number": "025213605",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.765658,8.042467",
    "IsOpen24Hours": true,
    "id": "51.765658,8.042467"
  },
  {
    "Gas Station Number": "0FB21",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lünener Straße 31",
    "Postcode": 59368,
    "City": "Werne",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6575730",
    "Longitude": "007.6286480",
    "Telephone Number": "023894791",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.657573,7.628648",
    "IsOpen24Hours": false,
    "id": "51.657573,7.628648"
  },
  {
    "Gas Station Number": "0FB22",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Delbrücker Straße 22",
    "Postcode": 33397,
    "City": "Rietberg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.8041380",
    "Longitude": "008.4259950",
    "Telephone Number": "052448817",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "51.804138,8.425995",
    "IsOpen24Hours": false,
    "id": "51.804138,8.425995"
  },
  {
    "Gas Station Number": "0FE72",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bundesstraße 1",
    "Postcode": 59423,
    "City": "Unna",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5321060",
    "Longitude": "007.7011020",
    "Telephone Number": "02303772435",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.532106,7.701102",
    "IsOpen24Hours": true,
    "id": "51.532106,7.701102"
  },
  {
    "Gas Station Number": "0FB23",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berliner Ring 21",
    "Postcode": 59302,
    "City": "Oelde",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.8313440",
    "Longitude": "008.1509280",
    "Telephone Number": "025223621",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "06:30",
    "Saturdays To": "23:00",
    "Sundays From": "07:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.831344,8.150928",
    "IsOpen24Hours": false,
    "id": "51.831344,8.150928"
  },
  {
    "Gas Station Number": "0FB24",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bürener Straße 15",
    "Postcode": 59590,
    "City": "Geseke",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6310630",
    "Longitude": "008.5127230",
    "Telephone Number": "0294297863160",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "51.631063,8.512723",
    "IsOpen24Hours": false,
    "id": "51.631063,8.512723"
  },
  {
    "Gas Station Number": "0FB25",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wolfsberger Straße 39",
    "Postcode": 59348,
    "City": "Lüdinghausen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.7652580",
    "Longitude": "007.4428950",
    "Telephone Number": "0259122670",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.765258,7.442895",
    "IsOpen24Hours": false,
    "id": "51.765258,7.442895"
  },
  {
    "Gas Station Number": "0FB26",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Cappenberger Straße 101",
    "Postcode": 44534,
    "City": "Lünen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6297790",
    "Longitude": "007.5265790",
    "Telephone Number": "0230654268",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.629779,7.526579",
    "IsOpen24Hours": false,
    "id": "51.629779,7.526579"
  },
  {
    "Gas Station Number": "0FB28",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Beckumer Straße 109",
    "Postcode": 59229,
    "City": "Ahlen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.7649420",
    "Longitude": "007.9122920",
    "Telephone Number": "0238263484",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.764942,7.912292",
    "IsOpen24Hours": true,
    "id": "51.764942,7.912292"
  },
  {
    "Gas Station Number": "0FB29",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nürnberger Straße 131",
    "Postcode": 90762,
    "City": "Fürth",
    "State": "Bayern",
    "Latitude": "049.4665340",
    "Longitude": "011.0100820",
    "Telephone Number": "0911706607",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "49.466534,11.010082",
    "IsOpen24Hours": false,
    "id": "49.466534,11.010082"
  },
  {
    "Gas Station Number": "0FB30",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wörthstraße 1A",
    "Postcode": 97082,
    "City": "Würzburg",
    "State": "Bayern",
    "Latitude": "049.7930410",
    "Longitude": "009.9161930",
    "Telephone Number": "093142468",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.793041,9.916193",
    "IsOpen24Hours": false,
    "id": "49.793041,9.916193"
  },
  {
    "Gas Station Number": "0FB31",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Memmelsdorfer Straße 80",
    "Postcode": 96052,
    "City": "Bamberg",
    "State": "Bayern",
    "Latitude": "049.9056010",
    "Longitude": "010.8964850",
    "Telephone Number": "095131562",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.905601,10.896485",
    "IsOpen24Hours": false,
    "id": "49.905601,10.896485"
  },
  {
    "Gas Station Number": "0F011",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ansbacher Straße 35",
    "Postcode": 91560,
    "City": "Heilsbronn",
    "State": "Bayern",
    "Latitude": "049.3340260",
    "Longitude": "010.7825580",
    "Telephone Number": "09872955750",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:30",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.334026,10.782558",
    "IsOpen24Hours": false,
    "id": "49.334026,10.782558"
  },
  {
    "Gas Station Number": "0FB32",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Äußere Sulzbacher Straße 117",
    "Postcode": 90491,
    "City": "Nürnberg",
    "State": "Bayern",
    "Latitude": "049.4642480",
    "Longitude": "011.1179470",
    "Telephone Number": "09115979448",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.464248,11.117947",
    "IsOpen24Hours": true,
    "id": "49.464248,11.117947"
  },
  {
    "Gas Station Number": "0FB33",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nürnberger Straße 44",
    "Postcode": 91781,
    "City": "Weißenburg",
    "State": "Bayern",
    "Latitude": "049.0388260",
    "Longitude": "010.9709080",
    "Telephone Number": "0914182835",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.038826,10.970908",
    "IsOpen24Hours": true,
    "id": "49.038826,10.970908"
  },
  {
    "Gas Station Number": "0FB34",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Würzburger Straße 76",
    "Postcode": 96049,
    "City": "Bamberg",
    "State": "Bayern",
    "Latitude": "049.8776720",
    "Longitude": "010.8840290",
    "Telephone Number": "095153020",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.877672,10.884029",
    "IsOpen24Hours": false,
    "id": "49.877672,10.884029"
  },
  {
    "Gas Station Number": "0FB35",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ansbacher Straße 38",
    "Postcode": 91541,
    "City": "Rothenburg",
    "State": "Bayern",
    "Latitude": "049.3751020",
    "Longitude": "010.1907990",
    "Telephone Number": "098616052",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.375102,10.190799",
    "IsOpen24Hours": true,
    "id": "49.375102,10.190799"
  },
  {
    "Gas Station Number": "0FB37",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Oberstreuer Straße 13",
    "Postcode": 97638,
    "City": "Mellrichstadt",
    "State": "Bayern",
    "Latitude": "050.4244820",
    "Longitude": "010.2970770",
    "Telephone Number": "0977681501680",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.424482,10.297077",
    "IsOpen24Hours": false,
    "id": "50.424482,10.297077"
  },
  {
    "Gas Station Number": "0FB38",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nürnberger Str. 39",
    "Postcode": 91257,
    "City": "Pegnitz",
    "State": "Bayern",
    "Latitude": "049.7497010",
    "Longitude": "011.5397010",
    "Telephone Number": "092412713",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "08:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.749701,11.539701",
    "IsOpen24Hours": false,
    "id": "49.749701,11.539701"
  },
  {
    "Gas Station Number": "0FB40",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Tirschenreuther Straße 20",
    "Postcode": 92681,
    "City": "Erbendorf",
    "State": "Bayern",
    "Latitude": "049.8355480",
    "Longitude": "012.0547580",
    "Telephone Number": "096821733",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "12:00",
    "24 hour?": "Nein",
    "location": "49.835548,12.054758",
    "IsOpen24Hours": false,
    "id": "49.835548,12.054758"
  },
  {
    "Gas Station Number": "0FB41",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Regensburger Straße70 a",
    "Postcode": 92224,
    "City": "Amberg",
    "State": "Bayern",
    "Latitude": "049.4379840",
    "Longitude": "011.8788230",
    "Telephone Number": "0962183744",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.437984,11.878823",
    "IsOpen24Hours": true,
    "id": "49.437984,11.878823"
  },
  {
    "Gas Station Number": "0FB42",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gunzenhausener Straße 9",
    "Postcode": 91732,
    "City": "Merkendorf",
    "State": "Bayern",
    "Latitude": "049.1984630",
    "Longitude": "010.7019100",
    "Telephone Number": "09826655222",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.198463,10.70191",
    "IsOpen24Hours": true,
    "id": "49.198463,10.70191"
  },
  {
    "Gas Station Number": "0FB43",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Meininger Straße 1",
    "Postcode": 97702,
    "City": "Münnerstadt",
    "State": "Bayern",
    "Latitude": "050.2517380",
    "Longitude": "010.1958870",
    "Telephone Number": "097333130",
    "Week From": "07:30",
    "Week To": "21:00",
    "Saturdays From": "09:00",
    "Saturdays To": "21:00",
    "Sundays From": "11:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.251738,10.195887",
    "IsOpen24Hours": false,
    "id": "50.251738,10.195887"
  },
  {
    "Gas Station Number": "0FB44",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Coburger Straße 64",
    "Postcode": 96215,
    "City": "Lichtenfels",
    "State": "Bayern",
    "Latitude": "050.1510470",
    "Longitude": "011.0592600",
    "Telephone Number": "095712633",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.151047,11.05926",
    "IsOpen24Hours": false,
    "id": "50.151047,11.05926"
  },
  {
    "Gas Station Number": "0FB45",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 8",
    "Postcode": 85095,
    "City": "Denkendorf",
    "State": "Bayern",
    "Latitude": "048.9290230",
    "Longitude": "011.4592350",
    "Telephone Number": "084669519584",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.929023,11.459235",
    "IsOpen24Hours": false,
    "id": "48.929023,11.459235"
  },
  {
    "Gas Station Number": "0FB46",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Reuther Straße 58",
    "Postcode": 91301,
    "City": "Forchheim",
    "State": "Bayern",
    "Latitude": "049.7177540",
    "Longitude": "011.1044720",
    "Telephone Number": "0919194241",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.717754,11.104472",
    "IsOpen24Hours": false,
    "id": "49.717754,11.104472"
  },
  {
    "Gas Station Number": "0FB48",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 8",
    "Postcode": 97633,
    "City": "Saal",
    "State": "Bayern",
    "Latitude": "050.3174640",
    "Longitude": "010.3526960",
    "Telephone Number": "097629305130",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.317464,10.352696",
    "IsOpen24Hours": false,
    "id": "50.317464,10.352696"
  },
  {
    "Gas Station Number": "0FB49",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Herrnstraße 27",
    "Postcode": 90763,
    "City": "Fürth",
    "State": "Bayern",
    "Latitude": "049.4641580",
    "Longitude": "010.9879080",
    "Telephone Number": "0911775990",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.464158,10.987908",
    "IsOpen24Hours": true,
    "id": "49.464158,10.987908"
  },
  {
    "Gas Station Number": "0FB50",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Erlanger Straße 33",
    "Postcode": 91080,
    "City": "Uttenreuth",
    "State": "Bayern",
    "Latitude": "049.5962630",
    "Longitude": "011.0687970",
    "Telephone Number": "0913157088",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "08:00",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "17:00",
    "24 hour?": "Nein",
    "location": "49.596263,11.068797",
    "IsOpen24Hours": false,
    "id": "49.596263,11.068797"
  },
  {
    "Gas Station Number": "0FB52",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bamberger Straße 17",
    "Postcode": 97483,
    "City": "Eltmann",
    "State": "Bayern",
    "Latitude": "049.9711240",
    "Longitude": "010.6706040",
    "Telephone Number": "09522271",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "08:00",
    "Saturdays To": "20:00",
    "Sundays From": "10:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.971124,10.670604",
    "IsOpen24Hours": false,
    "id": "49.971124,10.670604"
  },
  {
    "Gas Station Number": "0FB54",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Klein-Nürnberg 24",
    "Postcode": 96106,
    "City": "Ebern",
    "State": "Bayern",
    "Latitude": "050.0905230",
    "Longitude": "010.7955520",
    "Telephone Number": "095315560",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.090523,10.795552",
    "IsOpen24Hours": false,
    "id": "50.090523,10.795552"
  },
  {
    "Gas Station Number": "0FB53",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schweinfurter Straße36",
    "Postcode": 97488,
    "City": "Stadtlauringen",
    "State": "Bayern",
    "Latitude": "050.1832130",
    "Longitude": "010.3550320",
    "Telephone Number": "09724545",
    "Week From": "07:00",
    "Week To": "19:00",
    "Saturdays From": "07:00",
    "Saturdays To": "19:00",
    "Sundays From": "09:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "50.183213,10.355032",
    "IsOpen24Hours": false,
    "id": "50.183213,10.355032"
  },
  {
    "Gas Station Number": "0FB57",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schweinfurter Straße  78",
    "Postcode": 97525,
    "City": "Schwebheim",
    "State": "Bayern",
    "Latitude": "050.0042660",
    "Longitude": "010.2423680",
    "Telephone Number": "097231569",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.004266,10.242368",
    "IsOpen24Hours": false,
    "id": "50.004266,10.242368"
  },
  {
    "Gas Station Number": "0FB58",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Erlanger Straße 98",
    "Postcode": 90765,
    "City": "Fürth",
    "State": "Bayern",
    "Latitude": "049.4857760",
    "Longitude": "010.9964850",
    "Telephone Number": "09117906217",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.485776,10.996485",
    "IsOpen24Hours": false,
    "id": "49.485776,10.996485"
  },
  {
    "Gas Station Number": "0FB59",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Würzburger Straße 5b",
    "Postcode": 97753,
    "City": "Karlstadt",
    "State": "Bayern",
    "Latitude": "049.9556340",
    "Longitude": "009.7724770",
    "Telephone Number": "093532489",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.955634,9.772477",
    "IsOpen24Hours": true,
    "id": "49.955634,9.772477"
  },
  {
    "Gas Station Number": "0FB60",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Äuss. Bayreuther Str. 137",
    "Postcode": 90411,
    "City": "Nürnberg",
    "State": "Bayern",
    "Latitude": "049.4746470",
    "Longitude": "011.1030740",
    "Telephone Number": "0911511470",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.474647,11.103074",
    "IsOpen24Hours": true,
    "id": "49.474647,11.103074"
  },
  {
    "Gas Station Number": "0FB61",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Fürther Straße 1",
    "Postcode": 91315,
    "City": "Höchstadt",
    "State": "Bayern",
    "Latitude": "049.6979800",
    "Longitude": "010.8031220",
    "Telephone Number": "091938286",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Ja",
    "location": "49.69798,10.803122",
    "IsOpen24Hours": true,
    "id": "49.69798,10.803122"
  },
  {
    "Gas Station Number": "0FB62",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ernst-Faber-Straße 19",
    "Postcode": 96450,
    "City": "Coburg",
    "State": "Bayern",
    "Latitude": "050.2481100",
    "Longitude": "010.9657530",
    "Telephone Number": "0956110345",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.24811,10.965753",
    "IsOpen24Hours": true,
    "id": "50.24811,10.965753"
  },
  {
    "Gas Station Number": "0FB63",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bamberger Straße 4",
    "Postcode": 97631,
    "City": "Bad Königshofen",
    "State": "Bayern",
    "Latitude": "050.2980950",
    "Longitude": "010.4684710",
    "Telephone Number": "097616451",
    "Week From": "07:30",
    "Week To": "21:00",
    "Saturdays From": "07:30",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.298095,10.468471",
    "IsOpen24Hours": false,
    "id": "50.298095,10.468471"
  },
  {
    "Gas Station Number": "0FB64",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stammbacher Straße 45",
    "Postcode": 95213,
    "City": "Münchberg",
    "State": "Bayern",
    "Latitude": "050.1902620",
    "Longitude": "011.7784000",
    "Telephone Number": "0925180710",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.190262,11.7784",
    "IsOpen24Hours": false,
    "id": "50.190262,11.7784"
  },
  {
    "Gas Station Number": "0FB65",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nürnberger Straße2",
    "Postcode": 92224,
    "City": "Amberg",
    "State": "Bayern",
    "Latitude": "049.4444890",
    "Longitude": "011.8409180",
    "Telephone Number": "09621320040",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "22:00",
    "24 hour?": "Ja",
    "location": "49.444489,11.840918",
    "IsOpen24Hours": true,
    "id": "49.444489,11.840918"
  },
  {
    "Gas Station Number": "0FB66",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Drausnickstraße 60",
    "Postcode": 91052,
    "City": "Erlangen",
    "State": "Bayern",
    "Latitude": "049.5957610",
    "Longitude": "011.0317010",
    "Telephone Number": "0913152370",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.595761,11.031701",
    "IsOpen24Hours": true,
    "id": "49.595761,11.031701"
  },
  {
    "Gas Station Number": "0FB84",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Äuß. Brucker Straße 171",
    "Postcode": 91058,
    "City": "Erlangen",
    "State": "Bayern",
    "Latitude": "049.5770070",
    "Longitude": "010.9865220",
    "Telephone Number": "09131640702",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.577007,10.986522",
    "IsOpen24Hours": true,
    "id": "49.577007,10.986522"
  },
  {
    "Gas Station Number": "0FB67",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rodacher Straße 69",
    "Postcode": 96450,
    "City": "Coburg",
    "State": "Bayern",
    "Latitude": "050.2740480",
    "Longitude": "010.9573760",
    "Telephone Number": "0956166036",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.274048,10.957376",
    "IsOpen24Hours": true,
    "id": "50.274048,10.957376"
  },
  {
    "Gas Station Number": "0FB68",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A3 /Am Katzenberg",
    "Postcode": 97084,
    "City": "Würzburg",
    "State": "Bayern",
    "Latitude": "049.7546680",
    "Longitude": "009.9614240",
    "Telephone Number": "09316158090",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.754668,9.961424",
    "IsOpen24Hours": true,
    "id": "49.754668,9.961424"
  },
  {
    "Gas Station Number": "0FB69",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Fabrikzeile 5",
    "Postcode": 95028,
    "City": "Hof",
    "State": "Bayern",
    "Latitude": "050.3193730",
    "Longitude": "011.9219870",
    "Telephone Number": "092818402310",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.319373,11.921987",
    "IsOpen24Hours": false,
    "id": "50.319373,11.921987"
  },
  {
    "Gas Station Number": "0FB70",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bamberger Straße 74",
    "Postcode": 91413,
    "City": "Neustadt a. d. Aisch",
    "State": "Bayern",
    "Latitude": "049.5840640",
    "Longitude": "010.6166710",
    "Telephone Number": "09161874225",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "08:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.584064,10.616671",
    "IsOpen24Hours": false,
    "id": "49.584064,10.616671"
  },
  {
    "Gas Station Number": "0FB71",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mainstraße",
    "Postcode": 97340,
    "City": "Marktbreit",
    "State": "Bayern",
    "Latitude": "049.6690330",
    "Longitude": "010.1452920",
    "Telephone Number": "093321303",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.669033,10.145292",
    "IsOpen24Hours": false,
    "id": "49.669033,10.145292"
  },
  {
    "Gas Station Number": "0FB72",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Maintalstraße 159",
    "Postcode": 95460,
    "City": "Bad Berneck",
    "State": "Bayern",
    "Latitude": "050.0376690",
    "Longitude": "011.6774590",
    "Telephone Number": "09273502042",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.037669,11.677459",
    "IsOpen24Hours": false,
    "id": "50.037669,11.677459"
  },
  {
    "Gas Station Number": "0FB73",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Amberger Straße 18",
    "Postcode": 92318,
    "City": "Neumarkt",
    "State": "Bayern",
    "Latitude": "049.2851910",
    "Longitude": "011.4652890",
    "Telephone Number": "0918143441",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.285191,11.465289",
    "IsOpen24Hours": true,
    "id": "49.285191,11.465289"
  },
  {
    "Gas Station Number": "0FB76",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Attelsdorf 19",
    "Postcode": 96132,
    "City": "Schlüsselfeld",
    "State": "Bayern",
    "Latitude": "049.7458090",
    "Longitude": "010.6342900",
    "Telephone Number": "09552444",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.745809,10.63429",
    "IsOpen24Hours": true,
    "id": "49.745809,10.63429"
  },
  {
    "Gas Station Number": "0FB77",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kitzinger Straße38",
    "Postcode": 97359,
    "City": "Schwarzach",
    "State": "Bayern",
    "Latitude": "049.7896180",
    "Longitude": "010.2156010",
    "Telephone Number": "0932499809",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.789618,10.215601",
    "IsOpen24Hours": false,
    "id": "49.789618,10.215601"
  },
  {
    "Gas Station Number": "0FB78",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nürnberger Straße 25",
    "Postcode": 90518,
    "City": "Altdorf",
    "State": "Bayern",
    "Latitude": "049.3839690",
    "Longitude": "011.3480500",
    "Telephone Number": "091871515",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.383969,11.34805",
    "IsOpen24Hours": true,
    "id": "49.383969,11.34805"
  },
  {
    "Gas Station Number": "0FB81",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Würzburger Straße 1u.3",
    "Postcode": 97241,
    "City": "Bergtheim",
    "State": "Bayern",
    "Latitude": "049.8974380",
    "Longitude": "010.0672110",
    "Telephone Number": "09367522",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "19:00",
    "Sundays From": "08:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "49.897438,10.067211",
    "IsOpen24Hours": false,
    "id": "49.897438,10.067211"
  },
  {
    "Gas Station Number": "0FB82",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bamberger Straße 23",
    "Postcode": 97337,
    "City": "Dettelbach",
    "State": "Bayern",
    "Latitude": "049.8015150",
    "Longitude": "010.1656310",
    "Telephone Number": "093241468",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.801515,10.165631",
    "IsOpen24Hours": false,
    "id": "49.801515,10.165631"
  },
  {
    "Gas Station Number": "0FB79",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lichtenfelser Straße 38",
    "Postcode": 96231,
    "City": "Bad Staffelstein",
    "State": "Bayern",
    "Latitude": "050.1038780",
    "Longitude": "011.0057940",
    "Telephone Number": "095735700",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.103878,11.005794",
    "IsOpen24Hours": false,
    "id": "50.103878,11.005794"
  },
  {
    "Gas Station Number": "0FB80",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Würzburger Straße 59",
    "Postcode": 63743,
    "City": "Aschaffenburg",
    "State": "Bayern",
    "Latitude": "049.9699230",
    "Longitude": "009.1630680",
    "Telephone Number": "06021930563",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.969923,9.163068",
    "IsOpen24Hours": true,
    "id": "49.969923,9.163068"
  },
  {
    "Gas Station Number": "0F015",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schweinauer Hauptstr. 92",
    "Postcode": 90441,
    "City": "Nürnberg",
    "State": "Bayern",
    "Latitude": "049.4293390",
    "Longitude": "011.0438510",
    "Telephone Number": "09116266537",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.429339,11.043851",
    "IsOpen24Hours": true,
    "id": "49.429339,11.043851"
  },
  {
    "Gas Station Number": "0FB85",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Röthenbacher Straße 4",
    "Postcode": 90571,
    "City": "Schwaig",
    "State": "Bayern",
    "Latitude": "049.4721080",
    "Longitude": "011.2016480",
    "Telephone Number": "0911500169",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.472108,11.201648",
    "IsOpen24Hours": true,
    "id": "49.472108,11.201648"
  },
  {
    "Gas Station Number": "0FB86",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Fichtelgebirgsstraße 2",
    "Postcode": 95709,
    "City": "Tröstau",
    "State": "Bayern",
    "Latitude": "050.0159590",
    "Longitude": "011.9587450",
    "Telephone Number": "092326193",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "08:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.015959,11.958745",
    "IsOpen24Hours": false,
    "id": "50.015959,11.958745"
  },
  {
    "Gas Station Number": "0FB87",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wodanstraße 27",
    "Postcode": 90461,
    "City": "Nürnberg",
    "State": "Bayern",
    "Latitude": "049.4371910",
    "Longitude": "011.0942020",
    "Telephone Number": "0911460780",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.437191,11.094202",
    "IsOpen24Hours": true,
    "id": "49.437191,11.094202"
  },
  {
    "Gas Station Number": "0FB88",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Römerstraße 98",
    "Postcode": 63785,
    "City": "Obernburg",
    "State": "Bayern",
    "Latitude": "049.8466270",
    "Longitude": "009.1515910",
    "Telephone Number": "0602272033",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.846627,9.151591",
    "IsOpen24Hours": false,
    "id": "49.846627,9.151591"
  },
  {
    "Gas Station Number": "0FB89",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 163",
    "Postcode": 63768,
    "City": "Hösbach",
    "State": "Bayern",
    "Latitude": "050.0051750",
    "Longitude": "009.2111580",
    "Telephone Number": "060215825180",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.005175,9.211158",
    "IsOpen24Hours": false,
    "id": "50.005175,9.211158"
  },
  {
    "Gas Station Number": "0FC80",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "B 169",
    "Postcode": 4910,
    "City": "Elsterwerda",
    "State": "Brandenburg",
    "Latitude": "051.4671800",
    "Longitude": "013.5378330",
    "Telephone Number": "035334281",
    "Week From": "04:00",
    "Week To": "23:00",
    "Saturdays From": "05:00",
    "Saturdays To": "23:00",
    "Sundays From": "05:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "51.46718,13.537833",
    "IsOpen24Hours": false,
    "id": "51.46718,13.537833"
  },
  {
    "Gas Station Number": "0FB90",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Aschaffenburger Straße 86",
    "Postcode": 63801,
    "City": "Kleinostheim",
    "State": "Bayern",
    "Latitude": "049.9946070",
    "Longitude": "009.0697100",
    "Telephone Number": "060279026",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.994607,9.06971",
    "IsOpen24Hours": false,
    "id": "49.994607,9.06971"
  },
  {
    "Gas Station Number": "0FB91",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mainzer Straße 42",
    "Postcode": 63897,
    "City": "Miltenberg",
    "State": "Bayern",
    "Latitude": "049.7042220",
    "Longitude": "009.2368410",
    "Telephone Number": "093712466",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.704222,9.236841",
    "IsOpen24Hours": false,
    "id": "49.704222,9.236841"
  },
  {
    "Gas Station Number": "0FB92",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wintersbacher Straße 7",
    "Postcode": 63874,
    "City": "Dammbach",
    "State": "Bayern",
    "Latitude": "049.8602610",
    "Longitude": "009.2913620",
    "Telephone Number": "060921539",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "07:30",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.860261,9.291362",
    "IsOpen24Hours": false,
    "id": "49.860261,9.291362"
  },
  {
    "Gas Station Number": "0FB93",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Aschaffenburger Straße33",
    "Postcode": 63762,
    "City": "Großostheim",
    "State": "Bayern",
    "Latitude": "049.9261980",
    "Longitude": "009.0793350",
    "Telephone Number": "060269779501",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.926198,9.079335",
    "IsOpen24Hours": false,
    "id": "49.926198,9.079335"
  },
  {
    "Gas Station Number": "0FB94",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kahlgrundstraße 137",
    "Postcode": 63776,
    "City": "Mömbris",
    "State": "Bayern",
    "Latitude": "050.0545110",
    "Longitude": "009.1943030",
    "Telephone Number": "060291654",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.054511,9.194303",
    "IsOpen24Hours": false,
    "id": "50.054511,9.194303"
  },
  {
    "Gas Station Number": "0FB95",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Georg-Ledebour-Straße 4",
    "Postcode": 90473,
    "City": "Nürnberg",
    "State": "Bayern",
    "Latitude": "049.4013340",
    "Longitude": "011.1508180",
    "Telephone Number": "091137856383",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.401334,11.150818",
    "IsOpen24Hours": true,
    "id": "49.401334,11.150818"
  },
  {
    "Gas Station Number": "0FB96",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Europakanal 26",
    "Postcode": 91056,
    "City": "Erlangen",
    "State": "Bayern",
    "Latitude": "049.5929600",
    "Longitude": "010.9700500",
    "Telephone Number": "0913147599",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.59296,10.97005",
    "IsOpen24Hours": true,
    "id": "49.59296,10.97005"
  },
  {
    "Gas Station Number": "0FB98",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kantstraße 24",
    "Postcode": 97074,
    "City": "Würzburg",
    "State": "Bayern",
    "Latitude": "049.7784930",
    "Longitude": "009.9426800",
    "Telephone Number": "0931883082",
    "Week From": "08:30",
    "Week To": "20:00",
    "Saturdays From": "09:00",
    "Saturdays To": "20:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.778493,9.94268",
    "IsOpen24Hours": false,
    "id": "49.778493,9.94268"
  },
  {
    "Gas Station Number": "0FB99",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kronenstraße 42",
    "Postcode": 79211,
    "City": "Denzlingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.0656140",
    "Longitude": "007.8680780",
    "Telephone Number": "076669132066",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.065614,7.868078",
    "IsOpen24Hours": false,
    "id": "48.065614,7.868078"
  },
  {
    "Gas Station Number": "0FC01",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Freiburger Straße 11",
    "Postcode": 77933,
    "City": "Lahr",
    "State": "Baden-Württemberg",
    "Latitude": "048.3405050",
    "Longitude": "007.8419340",
    "Telephone Number": "0782142263",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.340505,7.841934",
    "IsOpen24Hours": false,
    "id": "48.340505,7.841934"
  },
  {
    "Gas Station Number": "0FC02",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neckarstraße 68",
    "Postcode": 78056,
    "City": "V.S.-Schwenningen",
    "State": "Baden-Württemberg",
    "Latitude": "048.0577130",
    "Longitude": "008.5355150",
    "Telephone Number": "07720956960",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.057713,8.535515",
    "IsOpen24Hours": true,
    "id": "48.057713,8.535515"
  },
  {
    "Gas Station Number": "0FC03",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schönauer Straße 22",
    "Postcode": 79674,
    "City": "Todtnau",
    "State": "Baden-Württemberg",
    "Latitude": "047.8280350",
    "Longitude": "007.9450250",
    "Telephone Number": "076717499973",
    "Week From": "06:30",
    "Week To": "19:30",
    "Saturdays From": "07:00",
    "Saturdays To": "19:30",
    "Sundays From": "07:30",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "47.828035,7.945025",
    "IsOpen24Hours": false,
    "id": "47.828035,7.945025"
  },
  {
    "Gas Station Number": "0FC04",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Leutkircher Straße 21",
    "Postcode": 88410,
    "City": "Bad Wurzach",
    "State": "Baden-Württemberg",
    "Latitude": "047.9050770",
    "Longitude": "009.8956390",
    "Telephone Number": "075642364",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "47.905077,9.895639",
    "IsOpen24Hours": false,
    "id": "47.905077,9.895639"
  },
  {
    "Gas Station Number": "0FC05",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bregstraße  40a",
    "Postcode": 78120,
    "City": "Furtwangen",
    "State": "Baden-Württemberg",
    "Latitude": "048.0453530",
    "Longitude": "008.1967130",
    "Telephone Number": "077234460",
    "Week From": "05:30",
    "Week To": "19:00",
    "Saturdays From": "06:00",
    "Saturdays To": "19:00",
    "Sundays From": "06:30",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "48.045353,8.196713",
    "IsOpen24Hours": false,
    "id": "48.045353,8.196713"
  },
  {
    "Gas Station Number": "0FC06",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schrahöfe 3",
    "Postcode": 79215,
    "City": "Elzach",
    "State": "Baden-Württemberg",
    "Latitude": "048.1847210",
    "Longitude": "008.0856260",
    "Telephone Number": "076829209402",
    "Week From": "05:30",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.184721,8.085626",
    "IsOpen24Hours": false,
    "id": "48.184721,8.085626"
  },
  {
    "Gas Station Number": "0FC07",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 34",
    "Postcode": 78132,
    "City": "Hornberg",
    "State": "Baden-Württemberg",
    "Latitude": "048.2138200",
    "Longitude": "008.2274610",
    "Telephone Number": "07833363",
    "Week From": "06:30",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.21382,8.227461",
    "IsOpen24Hours": false,
    "id": "48.21382,8.227461"
  },
  {
    "Gas Station Number": "0FC08",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 106 A",
    "Postcode": 79336,
    "City": "Herbolzheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.2163320",
    "Longitude": "007.7792430",
    "Telephone Number": "076434230",
    "Week From": "06:00",
    "Week To": "21:30",
    "Saturdays From": "06:00",
    "Saturdays To": "21:30",
    "Sundays From": "07:00",
    "Sundays To": "21:30",
    "24 hour?": "Nein",
    "location": "48.216332,7.779243",
    "IsOpen24Hours": false,
    "id": "48.216332,7.779243"
  },
  {
    "Gas Station Number": "0FC09",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Freiburger Landstraße 23",
    "Postcode": 79112,
    "City": "Freiburg",
    "State": "Baden-Württemberg",
    "Latitude": "047.9819430",
    "Longitude": "007.7175520",
    "Telephone Number": "076644846",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.981943,7.717552",
    "IsOpen24Hours": false,
    "id": "47.981943,7.717552"
  },
  {
    "Gas Station Number": "0FC10",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 79",
    "Postcode": 88161,
    "City": "Lindenberg",
    "State": "Bayern",
    "Latitude": "047.5995930",
    "Longitude": "009.8842060",
    "Telephone Number": "08381927740",
    "Week From": "07:00",
    "Week To": "22:00",
    "Saturdays From": "07:30",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.599593,9.884206",
    "IsOpen24Hours": false,
    "id": "47.599593,9.884206"
  },
  {
    "Gas Station Number": "0FC11",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Turmstraße 33",
    "Postcode": 77933,
    "City": "Lahr",
    "State": "Baden-Württemberg",
    "Latitude": "048.3417460",
    "Longitude": "007.8769730",
    "Telephone Number": "0782125474",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.341746,7.876973",
    "IsOpen24Hours": true,
    "id": "48.341746,7.876973"
  },
  {
    "Gas Station Number": "0FC12",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Vöhrenbacherstraße 38",
    "Postcode": 78050,
    "City": "V.S.-Villingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.0580180",
    "Longitude": "008.4439370",
    "Telephone Number": "0772153511",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.058018,8.443937",
    "IsOpen24Hours": false,
    "id": "48.058018,8.443937"
  },
  {
    "Gas Station Number": "0FC13",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Tettnanger Straße 150",
    "Postcode": 88069,
    "City": "Tettnang",
    "State": "Baden-Württemberg",
    "Latitude": "047.6685370",
    "Longitude": "009.5611400",
    "Telephone Number": "075424073599",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.668537,9.56114",
    "IsOpen24Hours": false,
    "id": "47.668537,9.56114"
  },
  {
    "Gas Station Number": "0FC17",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lippertsreuterstraße33",
    "Postcode": 88662,
    "City": "Überlingen",
    "State": "Baden-Württemberg",
    "Latitude": "047.7717580",
    "Longitude": "009.1739770",
    "Telephone Number": "075514767",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "05:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.771758,9.173977",
    "IsOpen24Hours": false,
    "id": "47.771758,9.173977"
  },
  {
    "Gas Station Number": "0FC18",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bei der Pürschmark 4",
    "Postcode": 78532,
    "City": "Tuttlingen",
    "State": "Baden-Württemberg",
    "Latitude": "047.9679690",
    "Longitude": "008.7873340",
    "Telephone Number": "07461164574",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.967969,8.787334",
    "IsOpen24Hours": true,
    "id": "47.967969,8.787334"
  },
  {
    "Gas Station Number": "0FC19",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 49",
    "Postcode": 79591,
    "City": "Eimeldingen",
    "State": "Baden-Württemberg",
    "Latitude": "047.6338670",
    "Longitude": "007.5940610",
    "Telephone Number": "076216908035",
    "Week From": "07:30",
    "Week To": "18:00",
    "Saturdays From": "07:30",
    "Saturdays To": "12:30",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.633867,7.594061",
    "IsOpen24Hours": false,
    "id": "47.633867,7.594061"
  },
  {
    "Gas Station Number": "0FC20",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Titiseestraße 21",
    "Postcode": 79822,
    "City": "Titisee-Neustadt",
    "State": "Baden-Württemberg",
    "Latitude": "047.9195730",
    "Longitude": "008.2051130",
    "Telephone Number": "07651/9361864",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "05:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.919573,8.205113",
    "IsOpen24Hours": false,
    "id": "47.919573,8.205113"
  },
  {
    "Gas Station Number": "0FC21",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Überlinger Straße 58",
    "Postcode": 88630,
    "City": "Pfullendorf",
    "State": "Baden-Württemberg",
    "Latitude": "047.9276630",
    "Longitude": "009.2430760",
    "Telephone Number": "075528625",
    "Week From": "04:00",
    "Week To": "23:00",
    "Saturdays From": "04:00",
    "Saturdays To": "23:00",
    "Sundays From": "05:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "47.927663,9.243076",
    "IsOpen24Hours": false,
    "id": "47.927663,9.243076"
  },
  {
    "Gas Station Number": "0FC22",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schwarzwaldstrasse 246",
    "Postcode": 79117,
    "City": "Freiburg",
    "State": "Baden-Württemberg",
    "Latitude": "047.9870080",
    "Longitude": "007.8956890",
    "Telephone Number": "076169077",
    "Week From": "08:00",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "47.987008,7.895689",
    "IsOpen24Hours": false,
    "id": "47.987008,7.895689"
  },
  {
    "Gas Station Number": "0FC23",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Leo-Wohleb-Straße 2",
    "Postcode": 79098,
    "City": "Freiburg",
    "State": "Baden-Württemberg",
    "Latitude": "047.9903390",
    "Longitude": "007.8579090",
    "Telephone Number": "0761709731",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.990339,7.857909",
    "IsOpen24Hours": true,
    "id": "47.990339,7.857909"
  },
  {
    "Gas Station Number": "0FC24",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Waldseer Straße 64",
    "Postcode": 88250,
    "City": "Weingarten",
    "State": "Baden-Württemberg",
    "Latitude": "047.8177070",
    "Longitude": "009.6423180",
    "Telephone Number": "0751561310",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.817707,9.642318",
    "IsOpen24Hours": true,
    "id": "47.817707,9.642318"
  },
  {
    "Gas Station Number": "0FC25",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nordwerk 10",
    "Postcode": 78176,
    "City": "Blumberg",
    "State": "Baden-Württemberg",
    "Latitude": "047.8462090",
    "Longitude": "008.5548950",
    "Telephone Number": "077025783",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.846209,8.554895",
    "IsOpen24Hours": true,
    "id": "47.846209,8.554895"
  },
  {
    "Gas Station Number": "0FC26",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedrichstraße 57",
    "Postcode": 79618,
    "City": "Rheinfelden",
    "State": "Baden-Württemberg",
    "Latitude": "047.5628240",
    "Longitude": "007.7922690",
    "Telephone Number": "076231286",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.562824,7.792269",
    "IsOpen24Hours": true,
    "id": "47.562824,7.792269"
  },
  {
    "Gas Station Number": "0FC27",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bundesstraße 7",
    "Postcode": 79780,
    "City": "Stühlingen",
    "State": "Baden-Württemberg",
    "Latitude": "047.7510540",
    "Longitude": "008.4580300",
    "Telephone Number": "077445211",
    "Week From": "07:30",
    "Week To": "20:00",
    "Saturdays From": "08:00",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "47.751054,8.45803",
    "IsOpen24Hours": false,
    "id": "47.751054,8.45803"
  },
  {
    "Gas Station Number": "0FC28",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Augsburger Straße 32",
    "Postcode": 93051,
    "City": "Regensburg",
    "State": "Bayern",
    "Latitude": "049.0031720",
    "Longitude": "012.0814050",
    "Telephone Number": "094196564",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "49.003172,12.081405",
    "IsOpen24Hours": false,
    "id": "49.003172,12.081405"
  },
  {
    "Gas Station Number": "0FC29",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Amberger Straße 31",
    "Postcode": 92521,
    "City": "Schwarzenfeld",
    "State": "Bayern",
    "Latitude": "049.3881140",
    "Longitude": "012.1340070",
    "Telephone Number": "09435501210",
    "Week From": "06:00",
    "Week To": "19:30",
    "Saturdays From": "07:00",
    "Saturdays To": "19:30",
    "Sundays From": "08:00",
    "Sundays To": "19:30",
    "24 hour?": "Nein",
    "location": "49.388114,12.134007",
    "IsOpen24Hours": false,
    "id": "49.388114,12.134007"
  },
  {
    "Gas Station Number": "0FC30",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Viechtacher Straße 4",
    "Postcode": 94239,
    "City": "Ruhmannsfelden",
    "State": "Bayern",
    "Latitude": "048.9847640",
    "Longitude": "012.9808590",
    "Telephone Number": "099291041",
    "Week From": "06:30",
    "Week To": "19:00",
    "Saturdays From": "07:00",
    "Saturdays To": "19:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.984764,12.980859",
    "IsOpen24Hours": false,
    "id": "48.984764,12.980859"
  },
  {
    "Gas Station Number": "0FC31",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Chamer Straße 52",
    "Postcode": 93426,
    "City": "Roding",
    "State": "Bayern",
    "Latitude": "049.1992590",
    "Longitude": "012.5243140",
    "Telephone Number": "094612109",
    "Week From": "04:30",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.199259,12.524314",
    "IsOpen24Hours": false,
    "id": "49.199259,12.524314"
  },
  {
    "Gas Station Number": "0FC32",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bernauer Straße 20",
    "Postcode": 94356,
    "City": "Kirchroth",
    "State": "Bayern",
    "Latitude": "048.9473630",
    "Longitude": "012.5453090",
    "Telephone Number": "09428226",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.947363,12.545309",
    "IsOpen24Hours": false,
    "id": "48.947363,12.545309"
  },
  {
    "Gas Station Number": "0FE68",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Landshuter Straße 20",
    "Postcode": 84061,
    "City": "Ergoldsbach",
    "State": "Bayern",
    "Latitude": "048.6847200",
    "Longitude": "012.2060870",
    "Telephone Number": "087711345",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.68472,12.206087",
    "IsOpen24Hours": true,
    "id": "48.68472,12.206087"
  },
  {
    "Gas Station Number": "0FC33",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Puricellistraße 3",
    "Postcode": 93049,
    "City": "Regensburg",
    "State": "Bayern",
    "Latitude": "049.0224980",
    "Longitude": "012.0626940",
    "Telephone Number": "094123371",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.022498,12.062694",
    "IsOpen24Hours": true,
    "id": "49.022498,12.062694"
  },
  {
    "Gas Station Number": "0FC34",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 18",
    "Postcode": 84130,
    "City": "Dingolfing",
    "State": "Bayern",
    "Latitude": "048.6362430",
    "Longitude": "012.4959420",
    "Telephone Number": "0873173537",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.636243,12.495942",
    "IsOpen24Hours": true,
    "id": "48.636243,12.495942"
  },
  {
    "Gas Station Number": "0FC35",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Passauer Straße 18",
    "Postcode": 94121,
    "City": "Salzweg",
    "State": "Bayern",
    "Latitude": "048.6182970",
    "Longitude": "013.4830340",
    "Telephone Number": "085142693",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "19:30",
    "Sundays From": "08:00",
    "Sundays To": "19:30",
    "24 hour?": "Nein",
    "location": "48.618297,13.483034",
    "IsOpen24Hours": false,
    "id": "48.618297,13.483034"
  },
  {
    "Gas Station Number": "0FC37",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 61",
    "Postcode": 92345,
    "City": "Dietfurt",
    "State": "Bayern",
    "Latitude": "049.0331310",
    "Longitude": "011.5929520",
    "Telephone Number": "08464602953",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.033131,11.592952",
    "IsOpen24Hours": false,
    "id": "49.033131,11.592952"
  },
  {
    "Gas Station Number": "0FC39",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berliner Straße 1",
    "Postcode": 1979,
    "City": "Lauchhammer",
    "State": "Brandenburg",
    "Latitude": "051.4685660",
    "Longitude": "013.7392940",
    "Telephone Number": "035747258",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "06:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.468566,13.739294",
    "IsOpen24Hours": false,
    "id": "51.468566,13.739294"
  },
  {
    "Gas Station Number": "0FC38",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nürnbergerstraße 35",
    "Postcode": 92533,
    "City": "Wernberg-Köblitz",
    "State": "Bayern",
    "Latitude": "049.5379680",
    "Longitude": "012.1560440",
    "Telephone Number": "096042272",
    "Week From": "06:30",
    "Week To": "20:00",
    "Saturdays From": "07:30",
    "Saturdays To": "19:00",
    "Sundays From": "08:30",
    "Sundays To": "18:00",
    "24 hour?": "Nein",
    "location": "49.537968,12.156044",
    "IsOpen24Hours": false,
    "id": "49.537968,12.156044"
  },
  {
    "Gas Station Number": "0FC40",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dornbergsweg 49",
    "Postcode": 38855,
    "City": "Wernigerode",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.8528730",
    "Longitude": "010.7899730",
    "Telephone Number": "03943533318",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.852873,10.789973",
    "IsOpen24Hours": true,
    "id": "51.852873,10.789973"
  },
  {
    "Gas Station Number": "0FC41",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berliner Straße 39",
    "Postcode": 6886,
    "City": "Lutherstadt Wittenbe",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.8786650",
    "Longitude": "012.6518710",
    "Telephone Number": "03491481100",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.878665,12.651871",
    "IsOpen24Hours": true,
    "id": "51.878665,12.651871"
  },
  {
    "Gas Station Number": "0FC42",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dessauer Straße 274 a",
    "Postcode": 6886,
    "City": "Lutherstadt Wittenbe",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.8656450",
    "Longitude": "012.6216980",
    "Telephone Number": "03491667838",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "06:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.865645,12.621698",
    "IsOpen24Hours": false,
    "id": "51.865645,12.621698"
  },
  {
    "Gas Station Number": "0F024",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Helmestraße 109",
    "Postcode": 99734,
    "City": "Nordhausen",
    "State": "Thüringen",
    "Latitude": "051.4772660",
    "Longitude": "010.8084410",
    "Telephone Number": "03631602241",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.477266,10.808441",
    "IsOpen24Hours": true,
    "id": "51.477266,10.808441"
  },
  {
    "Gas Station Number": "0FC44",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berliner Allee / B 2",
    "Postcode": 16303,
    "City": "Schwedt",
    "State": "Brandenburg",
    "Latitude": "053.0463140",
    "Longitude": "014.2658410",
    "Telephone Number": "0333223206",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "53.046314,14.265841",
    "IsOpen24Hours": false,
    "id": "53.046314,14.265841"
  },
  {
    "Gas Station Number": "0FC45",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Zollberg 70",
    "Postcode": 6449,
    "City": "Aschersleben",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.7574770",
    "Longitude": "011.4420810",
    "Telephone Number": "03473226798",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.757477,11.442081",
    "IsOpen24Hours": true,
    "id": "51.757477,11.442081"
  },
  {
    "Gas Station Number": "0FC46",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Zehistaer Straße 1",
    "Postcode": 1796,
    "City": "Pirna",
    "State": "Sachsen",
    "Latitude": "050.9531590",
    "Longitude": "013.9384410",
    "Telephone Number": "03501769123",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "19:00",
    "Sundays From": "07:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "50.953159,13.938441",
    "IsOpen24Hours": false,
    "id": "50.953159,13.938441"
  },
  {
    "Gas Station Number": "0FC47",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Castrop-Rauxel-Allee 2",
    "Postcode": 16792,
    "City": "Zehdenick",
    "State": "Brandenburg",
    "Latitude": "052.9795580",
    "Longitude": "013.3445900",
    "Telephone Number": "033072291",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.979558,13.34459",
    "IsOpen24Hours": true,
    "id": "52.979558,13.34459"
  },
  {
    "Gas Station Number": "0FC48",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stralsunder Chaussee 23",
    "Postcode": 18528,
    "City": "Bergen",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "054.4075200",
    "Longitude": "013.4161350",
    "Telephone Number": "03838251820",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "54.40752,13.416135",
    "IsOpen24Hours": false,
    "id": "54.40752,13.416135"
  },
  {
    "Gas Station Number": "0FC49",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neubrandenburger Str. 10",
    "Postcode": 17098,
    "City": "Friedland",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.6604910",
    "Longitude": "013.5419730",
    "Telephone Number": "03960120313",
    "Week From": "05:00",
    "Week To": "19:00",
    "Saturdays From": "05:00",
    "Saturdays To": "19:00",
    "Sundays From": "05:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "53.660491,13.541973",
    "IsOpen24Hours": false,
    "id": "53.660491,13.541973"
  },
  {
    "Gas Station Number": "0FC50",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schönwalder Landstraße 1",
    "Postcode": 17489,
    "City": "Greifswald",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "054.0728640",
    "Longitude": "013.3833310",
    "Telephone Number": "03834500709",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "06:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "54.072864,13.383331",
    "IsOpen24Hours": false,
    "id": "54.072864,13.383331"
  },
  {
    "Gas Station Number": "0FC51",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der Karlskuppe 1",
    "Postcode": 99817,
    "City": "Eisenach",
    "State": "Thüringen",
    "Latitude": "050.9941860",
    "Longitude": "010.2929660",
    "Telephone Number": "03691818095",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.994186,10.292966",
    "IsOpen24Hours": false,
    "id": "50.994186,10.292966"
  },
  {
    "Gas Station Number": "0FC52",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Olvenstedter Graseweg",
    "Postcode": 39128,
    "City": "Magdeburg",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.1566390",
    "Longitude": "011.5880910",
    "Telephone Number": "03917226129",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.156639,11.588091",
    "IsOpen24Hours": true,
    "id": "52.156639,11.588091"
  },
  {
    "Gas Station Number": "0FC53",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Industriestraße 1",
    "Postcode": 37339,
    "City": "Worbis",
    "State": "Thüringen",
    "Latitude": "051.4132260",
    "Longitude": "010.3578250",
    "Telephone Number": "036074633214",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.413226,10.357825",
    "IsOpen24Hours": true,
    "id": "51.413226,10.357825"
  },
  {
    "Gas Station Number": "0FC78",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alte Klockenhäger Landstraße 1",
    "Postcode": 18311,
    "City": "Ribnitz-Damgarten",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "054.2393770",
    "Longitude": "012.4079080",
    "Telephone Number": "03821811922",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "05:00",
    "Sundays To": "22:00",
    "24 hour?": "Ja",
    "location": "54.239377,12.407908",
    "IsOpen24Hours": true,
    "id": "54.239377,12.407908"
  },
  {
    "Gas Station Number": "0FC54",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hohensteiner Chaussee",
    "Postcode": 15344,
    "City": "Strausberg",
    "State": "Brandenburg",
    "Latitude": "052.5716170",
    "Longitude": "013.9029830",
    "Telephone Number": "0334122321",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.571617,13.902983",
    "IsOpen24Hours": false,
    "id": "52.571617,13.902983"
  },
  {
    "Gas Station Number": "0FC55",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lychener Straße 15",
    "Postcode": 17268,
    "City": "Templin",
    "State": "Brandenburg",
    "Latitude": "053.1289370",
    "Longitude": "013.4894750",
    "Telephone Number": "039876822",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.128937,13.489475",
    "IsOpen24Hours": true,
    "id": "53.128937,13.489475"
  },
  {
    "Gas Station Number": "0FC56",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der B 167 Nr. 4",
    "Postcode": 16244,
    "City": "Finowfurt",
    "State": "Brandenburg",
    "Latitude": "052.8498580",
    "Longitude": "013.6849410",
    "Telephone Number": "033354510819",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.849858,13.684941",
    "IsOpen24Hours": true,
    "id": "52.849858,13.684941"
  },
  {
    "Gas Station Number": "0FC57",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Freienhufener Straße 21",
    "Postcode": 1983,
    "City": "Großräschen",
    "State": "Brandenburg",
    "Latitude": "051.5857750",
    "Longitude": "014.0026840",
    "Telephone Number": "0357533140",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.585775,14.002684",
    "IsOpen24Hours": true,
    "id": "51.585775,14.002684"
  },
  {
    "Gas Station Number": "0FC58",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Thomas-Müntzer-Strasse 2",
    "Postcode": 6217,
    "City": "Merseburg",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.3427870",
    "Longitude": "011.9892000",
    "Telephone Number": "03461525570",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.342787,11.9892",
    "IsOpen24Hours": true,
    "id": "51.342787,11.9892"
  },
  {
    "Gas Station Number": "0FC59",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedländer Landstr. 21 a",
    "Postcode": 17389,
    "City": "Anklam",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.8431320",
    "Longitude": "013.6847180",
    "Telephone Number": "03971214444",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.843132,13.684718",
    "IsOpen24Hours": false,
    "id": "53.843132,13.684718"
  },
  {
    "Gas Station Number": "0FC60",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Poststrasse 21",
    "Postcode": 17139,
    "City": "Malchin",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.7416300",
    "Longitude": "012.7665960",
    "Telephone Number": "03994222308",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.74163,12.766596",
    "IsOpen24Hours": true,
    "id": "53.74163,12.766596"
  },
  {
    "Gas Station Number": "0FC61",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Berstetal/A 13",
    "Postcode": 15926,
    "City": "Luckau",
    "State": "Brandenburg",
    "Latitude": "051.9109000",
    "Longitude": "013.7965000",
    "Telephone Number": "0354566820",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.9109,13.7965",
    "IsOpen24Hours": true,
    "id": "51.9109,13.7965"
  },
  {
    "Gas Station Number": "0FC62",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Rüblingsheide/A 13",
    "Postcode": 15926,
    "City": "Luckau",
    "State": "Brandenburg",
    "Latitude": "051.8887490",
    "Longitude": "013.8371910",
    "Telephone Number": "0354566830",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.888749,13.837191",
    "IsOpen24Hours": true,
    "id": "51.888749,13.837191"
  },
  {
    "Gas Station Number": "0FC63",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Laugkfeld 30",
    "Postcode": 1968,
    "City": "Senftenberg",
    "State": "Brandenburg",
    "Latitude": "051.5276460",
    "Longitude": "014.0113020",
    "Telephone Number": "03573794952",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.527646,14.011302",
    "IsOpen24Hours": false,
    "id": "51.527646,14.011302"
  },
  {
    "Gas Station Number": "0FC64",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Camburger Straße 64",
    "Postcode": 7743,
    "City": "Jena",
    "State": "Thüringen",
    "Latitude": "050.9425580",
    "Longitude": "011.5970680",
    "Telephone Number": "03641424000",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.942558,11.597068",
    "IsOpen24Hours": true,
    "id": "50.942558,11.597068"
  },
  {
    "Gas Station Number": "0FC65",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Triftstraße 36",
    "Postcode": 15517,
    "City": "Fürstenwalde",
    "State": "Brandenburg",
    "Latitude": "052.3783540",
    "Longitude": "014.0734920",
    "Telephone Number": "03361349966",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.378354,14.073492",
    "IsOpen24Hours": true,
    "id": "52.378354,14.073492"
  },
  {
    "Gas Station Number": "0FC66",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dahlener Straße 3",
    "Postcode": 4860,
    "City": "Torgau",
    "State": "Sachsen",
    "Latitude": "051.5551180",
    "Longitude": "013.0008170",
    "Telephone Number": "03421712851",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.555118,13.000817",
    "IsOpen24Hours": true,
    "id": "51.555118,13.000817"
  },
  {
    "Gas Station Number": "0FC67",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berliner Chaussee 22",
    "Postcode": 15234,
    "City": "Frankfurt",
    "State": "Brandenburg",
    "Latitude": "052.3651690",
    "Longitude": "014.5223790",
    "Telephone Number": "033564190",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.365169,14.522379",
    "IsOpen24Hours": false,
    "id": "52.365169,14.522379"
  },
  {
    "Gas Station Number": "0FC68",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Doberaner Straße/Gewerbegebiet",
    "Postcode": 18225,
    "City": "Kühlungsborn",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "054.1443930",
    "Longitude": "011.7688010",
    "Telephone Number": "0382936111",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "54.144393,11.768801",
    "IsOpen24Hours": false,
    "id": "54.144393,11.768801"
  },
  {
    "Gas Station Number": "0FC69",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Langensalzaer Landstraße",
    "Postcode": 99974,
    "City": "Mühlhausen",
    "State": "Thüringen",
    "Latitude": "051.1985770",
    "Longitude": "010.4783810",
    "Telephone Number": "03601440450",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.198577,10.478381",
    "IsOpen24Hours": true,
    "id": "51.198577,10.478381"
  },
  {
    "Gas Station Number": "0FC70",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Pritzwalker Straße",
    "Postcode": 16909,
    "City": "Wittstock",
    "State": "Brandenburg",
    "Latitude": "053.1603600",
    "Longitude": "012.4674200",
    "Telephone Number": "03394433198",
    "Week From": "04:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.16036,12.46742",
    "IsOpen24Hours": false,
    "id": "53.16036,12.46742"
  },
  {
    "Gas Station Number": "0FC75",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Grünen Tal 10",
    "Postcode": 19063,
    "City": "Schwerin",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.5979790",
    "Longitude": "011.4383700",
    "Telephone Number": "03853941059",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.597979,11.43837",
    "IsOpen24Hours": true,
    "id": "53.597979,11.43837"
  },
  {
    "Gas Station Number": "0FC71",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Münsaer Straße 65  /B 180",
    "Postcode": 4600,
    "City": "Altenburg",
    "State": "Thüringen",
    "Latitude": "050.9832980",
    "Longitude": "012.4561160",
    "Telephone Number": "03447579249",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.983298,12.456116",
    "IsOpen24Hours": true,
    "id": "50.983298,12.456116"
  },
  {
    "Gas Station Number": "0FC73",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Glauchauer Landstraße 70",
    "Postcode": 8451,
    "City": "Crimmitschau",
    "State": "Sachsen",
    "Latitude": "050.8195650",
    "Longitude": "012.4050280",
    "Telephone Number": "037625374",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.819565,12.405028",
    "IsOpen24Hours": false,
    "id": "50.819565,12.405028"
  },
  {
    "Gas Station Number": "0FC74",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Äußere Dresdner Straße 23A",
    "Postcode": 8066,
    "City": "Zwickau",
    "State": "Sachsen",
    "Latitude": "050.7186710",
    "Longitude": "012.5156120",
    "Telephone Number": "0375477462",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "50.718671,12.515612",
    "IsOpen24Hours": false,
    "id": "50.718671,12.515612"
  },
  {
    "Gas Station Number": "0FC76",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Plauer Chaussee 18",
    "Postcode": 19386,
    "City": "Lübz",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.4632600",
    "Longitude": "012.0469550",
    "Telephone Number": "03873122205",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "23:00",
    "Sundays From": "05:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.46326,12.046955",
    "IsOpen24Hours": false,
    "id": "53.46326,12.046955"
  },
  {
    "Gas Station Number": "0FC77",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rövershäger Chaussee 11",
    "Postcode": 18146,
    "City": "Rostock",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "054.0995530",
    "Longitude": "012.1760540",
    "Telephone Number": "0381690210",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.099553,12.176054",
    "IsOpen24Hours": true,
    "id": "54.099553,12.176054"
  },
  {
    "Gas Station Number": "0FC81",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sternstraße 10",
    "Postcode": 38820,
    "City": "Halberstadt",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.8941670",
    "Longitude": "011.0349960",
    "Telephone Number": "03941441180",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.894167,11.034996",
    "IsOpen24Hours": true,
    "id": "51.894167,11.034996"
  },
  {
    "Gas Station Number": "0FC82",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neue Halberstädter Str.67",
    "Postcode": 38889,
    "City": "Blankenburg",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.7998140",
    "Longitude": "010.9490710",
    "Telephone Number": "03944365438",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.799814,10.949071",
    "IsOpen24Hours": true,
    "id": "51.799814,10.949071"
  },
  {
    "Gas Station Number": "0FC83",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ohrdrufer Straße 2a",
    "Postcode": 99867,
    "City": "Gotha",
    "State": "Thüringen",
    "Latitude": "050.9365110",
    "Longitude": "010.7160860",
    "Telephone Number": "03621709631",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.936511,10.716086",
    "IsOpen24Hours": true,
    "id": "50.936511,10.716086"
  },
  {
    "Gas Station Number": "0FC84",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stadtrodaer Straße 102",
    "Postcode": 7747,
    "City": "Jena",
    "State": "Thüringen",
    "Latitude": "050.8773870",
    "Longitude": "011.6219810",
    "Telephone Number": "03641334142",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.877387,11.621981",
    "IsOpen24Hours": true,
    "id": "50.877387,11.621981"
  },
  {
    "Gas Station Number": "0FC85",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gothaer Straße 158",
    "Postcode": 98528,
    "City": "Suhl",
    "State": "Thüringen",
    "Latitude": "050.6364890",
    "Longitude": "010.6974850",
    "Telephone Number": "03681461595",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.636489,10.697485",
    "IsOpen24Hours": true,
    "id": "50.636489,10.697485"
  },
  {
    "Gas Station Number": "0FC86",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Essener Straße 31",
    "Postcode": 4357,
    "City": "Leipzig",
    "State": "Sachsen",
    "Latitude": "051.3765760",
    "Longitude": "012.3990260",
    "Telephone Number": "03416022765",
    "Week From": "04:30",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.376576,12.399026",
    "IsOpen24Hours": false,
    "id": "51.376576,12.399026"
  },
  {
    "Gas Station Number": "0FC87",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hammerstraße 65",
    "Postcode": 8523,
    "City": "Plauen",
    "State": "Sachsen",
    "Latitude": "050.4997870",
    "Longitude": "012.1473880",
    "Telephone Number": "03741223173",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.499787,12.147388",
    "IsOpen24Hours": true,
    "id": "50.499787,12.147388"
  },
  {
    "Gas Station Number": "0FC88",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Chemnitzer Straße 8a",
    "Postcode": 8294,
    "City": "Lößnitz",
    "State": "Sachsen",
    "Latitude": "050.6315170",
    "Longitude": "012.7367950",
    "Telephone Number": "0377135357",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.631517,12.736795",
    "IsOpen24Hours": false,
    "id": "50.631517,12.736795"
  },
  {
    "Gas Station Number": "0FC89",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hallesche Straße 101",
    "Postcode": 99734,
    "City": "Nordhausen",
    "State": "Thüringen",
    "Latitude": "051.4936730",
    "Longitude": "010.8162180",
    "Telephone Number": "03631603191",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.493673,10.816218",
    "IsOpen24Hours": true,
    "id": "51.493673,10.816218"
  },
  {
    "Gas Station Number": "0FC90",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 45/47",
    "Postcode": 15806,
    "City": "Zossen",
    "State": "Brandenburg",
    "Latitude": "052.2171030",
    "Longitude": "013.4411340",
    "Telephone Number": "03377302403",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.217103,13.441134",
    "IsOpen24Hours": true,
    "id": "52.217103,13.441134"
  },
  {
    "Gas Station Number": "0FC91",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Poggestraße 17",
    "Postcode": 17166,
    "City": "Teterow",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.7769530",
    "Longitude": "012.5634960",
    "Telephone Number": "03996187931",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.776953,12.563496",
    "IsOpen24Hours": true,
    "id": "53.776953,12.563496"
  },
  {
    "Gas Station Number": "0FD07",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heeresbergstraße 9",
    "Postcode": 7549,
    "City": "Gera",
    "State": "Thüringen",
    "Latitude": "050.8422310",
    "Longitude": "012.0767880",
    "Telephone Number": "036533022",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.842231,12.076788",
    "IsOpen24Hours": true,
    "id": "50.842231,12.076788"
  },
  {
    "Gas Station Number": "0FD08",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Weimarische Straße 26",
    "Postcode": 99438,
    "City": "Bad Berka",
    "State": "Thüringen",
    "Latitude": "050.9044590",
    "Longitude": "011.2782680",
    "Telephone Number": "03645841155",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.904459,11.278268",
    "IsOpen24Hours": false,
    "id": "50.904459,11.278268"
  },
  {
    "Gas Station Number": "0FC92",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hohenzieritzer Straße 34",
    "Postcode": 17235,
    "City": "Neustrelitz",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.3724150",
    "Longitude": "013.0742420",
    "Telephone Number": "03981203295",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.372415,13.074242",
    "IsOpen24Hours": true,
    "id": "53.372415,13.074242"
  },
  {
    "Gas Station Number": "0FC93",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Salzunger Straße 27",
    "Postcode": 36433,
    "City": "Leimbach",
    "State": "Thüringen",
    "Latitude": "050.8135890",
    "Longitude": "010.2041700",
    "Telephone Number": "03695628240",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.813589,10.20417",
    "IsOpen24Hours": true,
    "id": "50.813589,10.20417"
  },
  {
    "Gas Station Number": "0FC94",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Potsdamer Straße 82",
    "Postcode": 14542,
    "City": "Werder",
    "State": "Brandenburg",
    "Latitude": "052.3644910",
    "Longitude": "012.9406110",
    "Telephone Number": "0332745835",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.364491,12.940611",
    "IsOpen24Hours": true,
    "id": "52.364491,12.940611"
  },
  {
    "Gas Station Number": "0FC95",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Plauensche Straße 65",
    "Postcode": 8606,
    "City": "Oelsnitz",
    "State": "Sachsen",
    "Latitude": "050.4262450",
    "Longitude": "012.1766790",
    "Telephone Number": "03742122180",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "50.426245,12.176679",
    "IsOpen24Hours": false,
    "id": "50.426245,12.176679"
  },
  {
    "Gas Station Number": "0FC96",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Löderburger Straße",
    "Postcode": 39418,
    "City": "Staßfurt",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.8674050",
    "Longitude": "011.5568050",
    "Telephone Number": "03925621597",
    "Week From": "04:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "05:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.867405,11.556805",
    "IsOpen24Hours": false,
    "id": "51.867405,11.556805"
  },
  {
    "Gas Station Number": "0FC97",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Eislebener Chaussee 200",
    "Postcode": 6126,
    "City": "Halle",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.4715570",
    "Longitude": "011.9049810",
    "Telephone Number": "03456903263",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.471557,11.904981",
    "IsOpen24Hours": true,
    "id": "51.471557,11.904981"
  },
  {
    "Gas Station Number": "0FC98",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stralsunder Straße 46",
    "Postcode": 18507,
    "City": "Grimmen",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "054.1177680",
    "Longitude": "013.0436360",
    "Telephone Number": "0383262443",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "54.117768,13.043636",
    "IsOpen24Hours": false,
    "id": "54.117768,13.043636"
  },
  {
    "Gas Station Number": "0FC99",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Leipziger  Straße 43",
    "Postcode": 9599,
    "City": "Freiberg",
    "State": "Sachsen",
    "Latitude": "050.9288400",
    "Longitude": "013.3279740",
    "Telephone Number": "0373133699",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.92884,13.327974",
    "IsOpen24Hours": false,
    "id": "50.92884,13.327974"
  },
  {
    "Gas Station Number": "0FD02",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "HalberstädterChaussee 196",
    "Postcode": 39116,
    "City": "Magdeburg",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.1053440",
    "Longitude": "011.5900980",
    "Telephone Number": "039161193822",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.105344,11.590098",
    "IsOpen24Hours": true,
    "id": "52.105344,11.590098"
  },
  {
    "Gas Station Number": "0FD03",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schilddorf 10",
    "Postcode": 39606,
    "City": "Osterburg",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.7681330",
    "Longitude": "011.7548840",
    "Telephone Number": "0393783756",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.768133,11.754884",
    "IsOpen24Hours": true,
    "id": "52.768133,11.754884"
  },
  {
    "Gas Station Number": "0FD04",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ratzeburger Chaussee 3",
    "Postcode": 19205,
    "City": "Gadebusch",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.6970760",
    "Longitude": "011.1022480",
    "Telephone Number": "03886712717",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.697076,11.102248",
    "IsOpen24Hours": true,
    "id": "53.697076,11.102248"
  },
  {
    "Gas Station Number": "0FD05",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kreckwitzer Straße 2",
    "Postcode": 2625,
    "City": "Bautzen",
    "State": "Sachsen",
    "Latitude": "051.1901780",
    "Longitude": "014.4516730",
    "Telephone Number": "03591211090",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.190178,14.451673",
    "IsOpen24Hours": true,
    "id": "51.190178,14.451673"
  },
  {
    "Gas Station Number": "0FD06",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Annaberger Straße 94",
    "Postcode": 9120,
    "City": "Chemnitz",
    "State": "Sachsen",
    "Latitude": "050.8145930",
    "Longitude": "012.9130100",
    "Telephone Number": "01724102820",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "50.814593,12.91301",
    "IsOpen24Hours": false,
    "id": "50.814593,12.91301"
  },
  {
    "Gas Station Number": "0FD09",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedenstraße 36",
    "Postcode": 15517,
    "City": "Fürstenwalde",
    "State": "Brandenburg",
    "Latitude": "052.3356680",
    "Longitude": "014.0735320",
    "Telephone Number": "0336157368",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.335668,14.073532",
    "IsOpen24Hours": true,
    "id": "52.335668,14.073532"
  },
  {
    "Gas Station Number": "0FD10",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kamenzer Bogen 16",
    "Postcode": 2977,
    "City": "Hoyerswerda",
    "State": "Sachsen",
    "Latitude": "051.4244530",
    "Longitude": "014.2250920",
    "Telephone Number": "03571406537",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.424453,14.225092",
    "IsOpen24Hours": true,
    "id": "51.424453,14.225092"
  },
  {
    "Gas Station Number": "0FD11",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Triptiser Straße 36",
    "Postcode": 7806,
    "City": "Neustadt",
    "State": "Thüringen",
    "Latitude": "050.7387850",
    "Longitude": "011.7681530",
    "Telephone Number": "03648122249",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.738785,11.768153",
    "IsOpen24Hours": true,
    "id": "50.738785,11.768153"
  },
  {
    "Gas Station Number": "0FD12",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Freienwalder Straße",
    "Postcode": 16356,
    "City": "Werneuchen",
    "State": "Brandenburg",
    "Latitude": "052.6356770",
    "Longitude": "013.7448710",
    "Telephone Number": "0333987407",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.635677,13.744871",
    "IsOpen24Hours": true,
    "id": "52.635677,13.744871"
  },
  {
    "Gas Station Number": "0FD13",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rostocker Straße 15",
    "Postcode": 17213,
    "City": "Malchow",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.4814030",
    "Longitude": "012.4214780",
    "Telephone Number": "03993214757",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.481403,12.421478",
    "IsOpen24Hours": true,
    "id": "53.481403,12.421478"
  },
  {
    "Gas Station Number": "0FD14",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nieskyer Straße 9",
    "Postcode": 2828,
    "City": "Görlitz",
    "State": "Sachsen",
    "Latitude": "051.1634700",
    "Longitude": "014.9726850",
    "Telephone Number": "03581316410",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.16347,14.972685",
    "IsOpen24Hours": true,
    "id": "51.16347,14.972685"
  },
  {
    "Gas Station Number": "0FD15",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Anderslebener Straße 42 a",
    "Postcode": 39387,
    "City": "Oschersleben",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.0288230",
    "Longitude": "011.2459800",
    "Telephone Number": "0394995959",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.028823,11.24598",
    "IsOpen24Hours": true,
    "id": "52.028823,11.24598"
  },
  {
    "Gas Station Number": "0FD16",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Frankfurter Straße 125",
    "Postcode": 15299,
    "City": "Müllrose",
    "State": "Brandenburg",
    "Latitude": "052.2583660",
    "Longitude": "014.4267410",
    "Telephone Number": "0336064662",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.258366,14.426741",
    "IsOpen24Hours": false,
    "id": "52.258366,14.426741"
  },
  {
    "Gas Station Number": "0FD17",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kummersdorfer Straße 13 d",
    "Postcode": 15859,
    "City": "Storkow",
    "State": "Brandenburg",
    "Latitude": "052.2636180",
    "Longitude": "013.9355840",
    "Telephone Number": "03367867991",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.263618,13.935584",
    "IsOpen24Hours": true,
    "id": "52.263618,13.935584"
  },
  {
    "Gas Station Number": "0FD18",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße",
    "Postcode": 99831,
    "City": "Creuzburg",
    "State": "Thüringen",
    "Latitude": "051.0504540",
    "Longitude": "010.2264570",
    "Telephone Number": "03692698488",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.050454,10.226457",
    "IsOpen24Hours": false,
    "id": "51.050454,10.226457"
  },
  {
    "Gas Station Number": "0FD19",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Karl-Marx-Straße 67",
    "Postcode": 15890,
    "City": "Eisenhüttenstadt",
    "State": "Brandenburg",
    "Latitude": "052.1401650",
    "Longitude": "014.6342480",
    "Telephone Number": "0336444953",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.140165,14.634248",
    "IsOpen24Hours": true,
    "id": "52.140165,14.634248"
  },
  {
    "Gas Station Number": "0FD20",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alvensleber Landstraße 5",
    "Postcode": 39340,
    "City": "Haldensleben",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.2763060",
    "Longitude": "011.3897880",
    "Telephone Number": "0390443232",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.276306,11.389788",
    "IsOpen24Hours": true,
    "id": "52.276306,11.389788"
  },
  {
    "Gas Station Number": "0FD21",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neustrelitzer Straße 118",
    "Postcode": 17033,
    "City": "Neubrandenburg",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.5302130",
    "Longitude": "013.2618180",
    "Telephone Number": "03953687733",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.530213,13.261818",
    "IsOpen24Hours": true,
    "id": "53.530213,13.261818"
  },
  {
    "Gas Station Number": "0FD22",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neustadter Straße 137",
    "Postcode": 96515,
    "City": "Sonneberg",
    "State": "Thüringen",
    "Latitude": "050.3491460",
    "Longitude": "011.1528110",
    "Telephone Number": "03675802960",
    "Week From": "05:30",
    "Week To": "22:30",
    "Saturdays From": "05:30",
    "Saturdays To": "22:30",
    "Sundays From": "05:30",
    "Sundays To": "22:30",
    "24 hour?": "Nein",
    "location": "50.349146,11.152811",
    "IsOpen24Hours": false,
    "id": "50.349146,11.152811"
  },
  {
    "Gas Station Number": "0FD25",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Altenburger Straße 79",
    "Postcode": 4539,
    "City": "Groitzsch",
    "State": "Sachsen",
    "Latitude": "051.1510180",
    "Longitude": "012.2865980",
    "Telephone Number": "03429642265",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "05:00",
    "Saturdays To": "21:00",
    "Sundays From": "05:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.151018,12.286598",
    "IsOpen24Hours": false,
    "id": "51.151018,12.286598"
  },
  {
    "Gas Station Number": "0FD23",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Luckenwalder Strasse 111",
    "Postcode": 15711,
    "City": "Königs Wusterhausen",
    "State": "Brandenburg",
    "Latitude": "052.2838200",
    "Longitude": "013.6145330",
    "Telephone Number": "03375242010",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.28382,13.614533",
    "IsOpen24Hours": true,
    "id": "52.28382,13.614533"
  },
  {
    "Gas Station Number": "0FD24",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ludwigsluster Chaussee 24",
    "Postcode": 19370,
    "City": "Parchim",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.4264460",
    "Longitude": "011.8192700",
    "Telephone Number": "03871443141",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.426446,11.81927",
    "IsOpen24Hours": false,
    "id": "53.426446,11.81927"
  },
  {
    "Gas Station Number": "0FD26",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Zschopauer Straße 234",
    "Postcode": 9126,
    "City": "Chemnitz",
    "State": "Sachsen",
    "Latitude": "050.8141580",
    "Longitude": "012.9548280",
    "Telephone Number": "037133471632",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.814158,12.954828",
    "IsOpen24Hours": true,
    "id": "50.814158,12.954828"
  },
  {
    "Gas Station Number": "0FD27",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Suhler Straße 5 a",
    "Postcode": 99885,
    "City": "Ohrdruf",
    "State": "Thüringen",
    "Latitude": "050.8165700",
    "Longitude": "010.7292920",
    "Telephone Number": "03624312755",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.81657,10.729292",
    "IsOpen24Hours": true,
    "id": "50.81657,10.729292"
  },
  {
    "Gas Station Number": "0FD28",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dresdener Straße 30 t",
    "Postcode": 4720,
    "City": "Döbeln",
    "State": "Sachsen",
    "Latitude": "051.1204790",
    "Longitude": "013.1498260",
    "Telephone Number": "03431702392",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.120479,13.149826",
    "IsOpen24Hours": true,
    "id": "51.120479,13.149826"
  },
  {
    "Gas Station Number": "0FD29",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Leipziger Straße 95",
    "Postcode": 4668,
    "City": "Grimma",
    "State": "Sachsen",
    "Latitude": "051.2313180",
    "Longitude": "012.7039640",
    "Telephone Number": "03437917940",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "05:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "51.231318,12.703964",
    "IsOpen24Hours": false,
    "id": "51.231318,12.703964"
  },
  {
    "Gas Station Number": "0FD30",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Jenaer Straße 13",
    "Postcode": 7607,
    "City": "Eisenberg",
    "State": "Thüringen",
    "Latitude": "050.9701060",
    "Longitude": "011.8900350",
    "Telephone Number": "03669152900",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.970106,11.890035",
    "IsOpen24Hours": true,
    "id": "50.970106,11.890035"
  },
  {
    "Gas Station Number": "0FD31",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berkaer Straße 71",
    "Postcode": 99425,
    "City": "Weimar",
    "State": "Thüringen",
    "Latitude": "050.9607260",
    "Longitude": "011.3164040",
    "Telephone Number": "03643905891",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.960726,11.316404",
    "IsOpen24Hours": true,
    "id": "50.960726,11.316404"
  },
  {
    "Gas Station Number": "0FD32",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Magdeburg-Leipziger Chaus",
    "Postcode": 39443,
    "City": "Staßfurt",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.9171860",
    "Longitude": "011.6106090",
    "Telephone Number": "03926650130",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.917186,11.610609",
    "IsOpen24Hours": true,
    "id": "51.917186,11.610609"
  },
  {
    "Gas Station Number": "0FD33",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Badstraße 69",
    "Postcode": 1454,
    "City": "Radeberg",
    "State": "Sachsen",
    "Latitude": "051.1309910",
    "Longitude": "013.9166890",
    "Telephone Number": "03528442320",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.130991,13.916689",
    "IsOpen24Hours": true,
    "id": "51.130991,13.916689"
  },
  {
    "Gas Station Number": "0FD34",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Steinfurther Straße/B 184",
    "Postcode": 6766,
    "City": "Wolfen",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.6833790",
    "Longitude": "012.2737570",
    "Telephone Number": "0349422926",
    "Week From": "05:00",
    "Week To": "20:00",
    "Saturdays From": "06:00",
    "Saturdays To": "20:00",
    "Sundays From": "06:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "51.683379,12.273757",
    "IsOpen24Hours": false,
    "id": "51.683379,12.273757"
  },
  {
    "Gas Station Number": "0FD35",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Woldegker Straße 39",
    "Postcode": 17036,
    "City": "Neubrandenburg",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.5571020",
    "Longitude": "013.3109350",
    "Telephone Number": "03957792285",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.557102,13.310935",
    "IsOpen24Hours": true,
    "id": "53.557102,13.310935"
  },
  {
    "Gas Station Number": "0FD36",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Leipziger Straße 20",
    "Postcode": 4916,
    "City": "Herzberg",
    "State": "Brandenburg",
    "Latitude": "051.6902450",
    "Longitude": "013.2209480",
    "Telephone Number": "0353521440",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.690245,13.220948",
    "IsOpen24Hours": true,
    "id": "51.690245,13.220948"
  },
  {
    "Gas Station Number": "0FD37",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Fischhausstraße 15",
    "Postcode": 1099,
    "City": "Dresden",
    "State": "Sachsen",
    "Latitude": "051.0704600",
    "Longitude": "013.7887810",
    "Telephone Number": "03518043050",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.07046,13.788781",
    "IsOpen24Hours": true,
    "id": "51.07046,13.788781"
  },
  {
    "Gas Station Number": "0FD38",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Eicheneck 7",
    "Postcode": 18273,
    "City": "Güstrow",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.8095940",
    "Longitude": "012.1898350",
    "Telephone Number": "0384322122",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.809594,12.189835",
    "IsOpen24Hours": true,
    "id": "53.809594,12.189835"
  },
  {
    "Gas Station Number": "0FD39",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der Hochstraße 16",
    "Postcode": 2906,
    "City": "Niesky",
    "State": "Sachsen",
    "Latitude": "051.2800370",
    "Longitude": "014.8185020",
    "Telephone Number": "03588206921",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.280037,14.818502",
    "IsOpen24Hours": false,
    "id": "51.280037,14.818502"
  },
  {
    "Gas Station Number": "0FD40",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Wilhelmschacht 17",
    "Postcode": 4552,
    "City": "Borna",
    "State": "Sachsen",
    "Latitude": "051.1161920",
    "Longitude": "012.4859270",
    "Telephone Number": "03433208803",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.116192,12.485927",
    "IsOpen24Hours": true,
    "id": "51.116192,12.485927"
  },
  {
    "Gas Station Number": "0FD42",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Weinböhlaer Straße 46",
    "Postcode": 1640,
    "City": "Coswig",
    "State": "Sachsen",
    "Latitude": "051.1354770",
    "Longitude": "013.5768790",
    "Telephone Number": "0352373629",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.135477,13.576879",
    "IsOpen24Hours": true,
    "id": "51.135477,13.576879"
  },
  {
    "Gas Station Number": "0FD43",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der Crivitzer Chaussee",
    "Postcode": 19063,
    "City": "Schwerin",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.6003180",
    "Longitude": "011.4650760",
    "Telephone Number": "03852072484",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.600318,11.465076",
    "IsOpen24Hours": true,
    "id": "53.600318,11.465076"
  },
  {
    "Gas Station Number": "0FD45",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Geuzer Straße 32M",
    "Postcode": 6366,
    "City": "Köthen",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.7546000",
    "Longitude": "011.9488100",
    "Telephone Number": "03496550124",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.7546,11.94881",
    "IsOpen24Hours": true,
    "id": "51.7546,11.94881"
  },
  {
    "Gas Station Number": "0FD46",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Augustenstraße 88",
    "Postcode": 6493,
    "City": "Harzgerode",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.6345990",
    "Longitude": "011.1471290",
    "Telephone Number": "0394843130",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.634599,11.147129",
    "IsOpen24Hours": false,
    "id": "51.634599,11.147129"
  },
  {
    "Gas Station Number": "0FD47",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schwarzenberger Straße 4",
    "Postcode": 8340,
    "City": "Schwarzenberg",
    "State": "Sachsen",
    "Latitude": "050.5311560",
    "Longitude": "012.8117050",
    "Telephone Number": "0377486350",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.531156,12.811705",
    "IsOpen24Hours": true,
    "id": "50.531156,12.811705"
  },
  {
    "Gas Station Number": "0FD48",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Arneburger Straße 47/B 18",
    "Postcode": 39576,
    "City": "Stendal",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.6180610",
    "Longitude": "011.8686610",
    "Telephone Number": "03931796822",
    "Week From": "04:00",
    "Week To": "22:00",
    "Saturdays From": "04:00",
    "Saturdays To": "22:00",
    "Sundays From": "04:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.618061,11.868661",
    "IsOpen24Hours": false,
    "id": "52.618061,11.868661"
  },
  {
    "Gas Station Number": "0FD49",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kösener Straße",
    "Postcode": 6618,
    "City": "Naumburg",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.1509730",
    "Longitude": "011.7839960",
    "Telephone Number": "03445778420",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.150973,11.783996",
    "IsOpen24Hours": true,
    "id": "51.150973,11.783996"
  },
  {
    "Gas Station Number": "0FD50",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Brielower Landstraße 17",
    "Postcode": 14772,
    "City": "Brandenburg",
    "State": "Brandenburg",
    "Latitude": "052.4303020",
    "Longitude": "012.5533480",
    "Telephone Number": "03381700164",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.430302,12.553348",
    "IsOpen24Hours": true,
    "id": "52.430302,12.553348"
  },
  {
    "Gas Station Number": "0FD51",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wittenberger Straße",
    "Postcode": 6749,
    "City": "Bitterfeld",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.6271090",
    "Longitude": "012.3366670",
    "Telephone Number": "0349324717",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "05:00",
    "Saturdays To": "21:00",
    "Sundays From": "06:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.627109,12.336667",
    "IsOpen24Hours": false,
    "id": "51.627109,12.336667"
  },
  {
    "Gas Station Number": "0FD52",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Vor dem Rittertor",
    "Postcode": 39164,
    "City": "Wanzleben",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.0614500",
    "Longitude": "011.4329630",
    "Telephone Number": "03920942078",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.06145,11.432963",
    "IsOpen24Hours": true,
    "id": "52.06145,11.432963"
  },
  {
    "Gas Station Number": "0FD54",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "R.-Luxemburg-Straße 105",
    "Postcode": 6917,
    "City": "Jessen",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.7966730",
    "Longitude": "012.9740080",
    "Telephone Number": "03537213597",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.796673,12.974008",
    "IsOpen24Hours": true,
    "id": "51.796673,12.974008"
  },
  {
    "Gas Station Number": "0FD56",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Leipziger Straße 206",
    "Postcode": 9114,
    "City": "Chemnitz",
    "State": "Sachsen",
    "Latitude": "050.8568680",
    "Longitude": "012.8752180",
    "Telephone Number": "0371372415",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.856868,12.875218",
    "IsOpen24Hours": true,
    "id": "50.856868,12.875218"
  },
  {
    "Gas Station Number": "0FD59",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Genthiner Straße 65 a",
    "Postcode": 14712,
    "City": "Rathenow",
    "State": "Brandenburg",
    "Latitude": "052.5995030",
    "Longitude": "012.2992600",
    "Telephone Number": "03385516278",
    "Week From": "05:30",
    "Week To": "21:00",
    "Saturdays From": "06:30",
    "Saturdays To": "21:00",
    "Sundays From": "06:30",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "52.599503,12.29926",
    "IsOpen24Hours": false,
    "id": "52.599503,12.29926"
  },
  {
    "Gas Station Number": "0FD57",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "McDonalds-Straße 1",
    "Postcode": 16356,
    "City": "Blumberg",
    "State": "Brandenburg",
    "Latitude": "052.5824240",
    "Longitude": "013.5872250",
    "Telephone Number": "0333945150",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.582424,13.587225",
    "IsOpen24Hours": true,
    "id": "52.582424,13.587225"
  },
  {
    "Gas Station Number": "0FD58",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Prenzlauer Chaussee 76",
    "Postcode": 16348,
    "City": "Wandlitz",
    "State": "Brandenburg",
    "Latitude": "052.7431480",
    "Longitude": "013.4584500",
    "Telephone Number": "03339781748",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.743148,13.45845",
    "IsOpen24Hours": true,
    "id": "52.743148,13.45845"
  },
  {
    "Gas Station Number": "0FD60",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Harzweg 19",
    "Postcode": 6484,
    "City": "Quedlinburg",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.7830420",
    "Longitude": "011.1467660",
    "Telephone Number": "03946701128",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.783042,11.146766",
    "IsOpen24Hours": true,
    "id": "51.783042,11.146766"
  },
  {
    "Gas Station Number": "0FD61",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Straße des Friedens 40",
    "Postcode": 3222,
    "City": "Lübbenau",
    "State": "Brandenburg",
    "Latitude": "051.8533340",
    "Longitude": "013.9492920",
    "Telephone Number": "0354244848",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.853334,13.949292",
    "IsOpen24Hours": true,
    "id": "51.853334,13.949292"
  },
  {
    "Gas Station Number": "0FD62",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Röxer Straße 16",
    "Postcode": 39576,
    "City": "Stendal",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.5945950",
    "Longitude": "011.8425580",
    "Telephone Number": "03931715620",
    "Week From": "04:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.594595,11.842558",
    "IsOpen24Hours": false,
    "id": "52.594595,11.842558"
  },
  {
    "Gas Station Number": "0FD63",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Heerweg 33",
    "Postcode": 7768,
    "City": "Kahla",
    "State": "Thüringen",
    "Latitude": "050.8093090",
    "Longitude": "011.5774490",
    "Telephone Number": "03642424005",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.809309,11.577449",
    "IsOpen24Hours": false,
    "id": "50.809309,11.577449"
  },
  {
    "Gas Station Number": "0FD64",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Zwickauer Straße 240",
    "Postcode": 8468,
    "City": "Reichenbach",
    "State": "Sachsen",
    "Latitude": "050.6362260",
    "Longitude": "012.3237080",
    "Telephone Number": "0376512016",
    "Week From": "04:30",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.636226,12.323708",
    "IsOpen24Hours": false,
    "id": "50.636226,12.323708"
  },
  {
    "Gas Station Number": "0FD65",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Trockentalstraße 64",
    "Postcode": 8527,
    "City": "Plauen",
    "State": "Sachsen",
    "Latitude": "050.4901600",
    "Longitude": "012.1309480",
    "Telephone Number": "03741137066",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.49016,12.130948",
    "IsOpen24Hours": false,
    "id": "50.49016,12.130948"
  },
  {
    "Gas Station Number": "0FD66",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bockauer Talstraße 20",
    "Postcode": 8280,
    "City": "Aue",
    "State": "Sachsen",
    "Latitude": "050.5795740",
    "Longitude": "012.6920090",
    "Telephone Number": "0377120368",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.579574,12.692009",
    "IsOpen24Hours": false,
    "id": "50.579574,12.692009"
  },
  {
    "Gas Station Number": "0FD67",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Plauensche Straße 70",
    "Postcode": 8223,
    "City": "Falkenstein",
    "State": "Sachsen",
    "Latitude": "050.4741740",
    "Longitude": "012.3543680",
    "Telephone Number": "0374571566",
    "Week From": "05:30",
    "Week To": "20:00",
    "Saturdays From": "06:00",
    "Saturdays To": "20:00",
    "Sundays From": "06:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.474174,12.354368",
    "IsOpen24Hours": false,
    "id": "50.474174,12.354368"
  },
  {
    "Gas Station Number": "0FD68",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der Stadtautobahn 60",
    "Postcode": 18119,
    "City": "Rostock",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "054.1684220",
    "Longitude": "012.0799580",
    "Telephone Number": "03815016",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "54.168422,12.079958",
    "IsOpen24Hours": false,
    "id": "54.168422,12.079958"
  },
  {
    "Gas Station Number": "0FD69",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Chemnitzer Straße 34",
    "Postcode": 9456,
    "City": "Annaberg-Buchholz",
    "State": "Sachsen",
    "Latitude": "050.5911240",
    "Longitude": "013.0110430",
    "Telephone Number": "0373351015",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.591124,13.011043",
    "IsOpen24Hours": true,
    "id": "50.591124,13.011043"
  },
  {
    "Gas Station Number": "0FD70",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Magdeburger Chaussee 2",
    "Postcode": 6118,
    "City": "Halle",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.5218780",
    "Longitude": "011.9537430",
    "Telephone Number": "03455210836",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.521878,11.953743",
    "IsOpen24Hours": true,
    "id": "51.521878,11.953743"
  },
  {
    "Gas Station Number": "0FD71",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Reichenbacher Straße 89",
    "Postcode": 2827,
    "City": "Görlitz",
    "State": "Sachsen",
    "Latitude": "051.1474880",
    "Longitude": "014.9414480",
    "Telephone Number": "0358178180",
    "Week From": "05:00",
    "Week To": "21:30",
    "Saturdays From": "06:00",
    "Saturdays To": "21:30",
    "Sundays From": "06:00",
    "Sundays To": "21:30",
    "24 hour?": "Nein",
    "location": "51.147488,14.941448",
    "IsOpen24Hours": false,
    "id": "51.147488,14.941448"
  },
  {
    "Gas Station Number": "0FD72",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Chemnitzer Straße 47 d",
    "Postcode": 9380,
    "City": "Thalheim",
    "State": "Sachsen",
    "Latitude": "050.7100530",
    "Longitude": "012.8657680",
    "Telephone Number": "0372138992",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "06:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.710053,12.865768",
    "IsOpen24Hours": false,
    "id": "50.710053,12.865768"
  },
  {
    "Gas Station Number": "0FD73",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Merzdorfer Weg 1 B",
    "Postcode": 3042,
    "City": "Cottbus",
    "State": "Brandenburg",
    "Latitude": "051.7672490",
    "Longitude": "014.3590600",
    "Telephone Number": "0355722208",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.767249,14.35906",
    "IsOpen24Hours": true,
    "id": "51.767249,14.35906"
  },
  {
    "Gas Station Number": "0FD74",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berliner Straße 25a",
    "Postcode": 3226,
    "City": "Vetschau",
    "State": "Brandenburg",
    "Latitude": "051.7847790",
    "Longitude": "014.0669660",
    "Telephone Number": "03543371111",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.784779,14.066966",
    "IsOpen24Hours": true,
    "id": "51.784779,14.066966"
  },
  {
    "Gas Station Number": "0FD75",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Arthur-Scheunert-Allee 73",
    "Postcode": 14558,
    "City": "Nuthetal",
    "State": "Brandenburg",
    "Latitude": "052.3509420",
    "Longitude": "013.1034560",
    "Telephone Number": "033200508777",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.350942,13.103456",
    "IsOpen24Hours": true,
    "id": "52.350942,13.103456"
  },
  {
    "Gas Station Number": "0FD76",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rumburger Straße 38",
    "Postcode": 2730,
    "City": "Ebersbach",
    "State": "Sachsen",
    "Latitude": "050.9979680",
    "Longitude": "014.6165200",
    "Telephone Number": "03586300091",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "05:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.997968,14.61652",
    "IsOpen24Hours": false,
    "id": "50.997968,14.61652"
  },
  {
    "Gas Station Number": "0FD77",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Weißenfelser Straße 98",
    "Postcode": 6712,
    "City": "Zeitz",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.0693620",
    "Longitude": "012.1219170",
    "Telephone Number": "03441214095",
    "Week From": "05:30",
    "Week To": "21:30",
    "Saturdays From": "07:00",
    "Saturdays To": "21:30",
    "Sundays From": "07:00",
    "Sundays To": "21:30",
    "24 hour?": "Nein",
    "location": "51.069362,12.121917",
    "IsOpen24Hours": false,
    "id": "51.069362,12.121917"
  },
  {
    "Gas Station Number": "0FD79",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kranichfelder Straße 2",
    "Postcode": 99097,
    "City": "Erfurt",
    "State": "Thüringen",
    "Latitude": "050.9584480",
    "Longitude": "011.0531050",
    "Telephone Number": "03613465366",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.958448,11.053105",
    "IsOpen24Hours": true,
    "id": "50.958448,11.053105"
  },
  {
    "Gas Station Number": "0FD80",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ludwig-Hupfeld-Straße 15",
    "Postcode": 4178,
    "City": "Leipzig",
    "State": "Sachsen",
    "Latitude": "051.3485330",
    "Longitude": "012.3014730",
    "Telephone Number": "03414418938",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "07:00",
    "Saturdays To": "23:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.348533,12.301473",
    "IsOpen24Hours": false,
    "id": "51.348533,12.301473"
  },
  {
    "Gas Station Number": "0FD81",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Merseburger Straße",
    "Postcode": 6242,
    "City": "Braunsbedra",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.2886180",
    "Longitude": "011.9046740",
    "Telephone Number": "03463321881",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.288618,11.904674",
    "IsOpen24Hours": true,
    "id": "51.288618,11.904674"
  },
  {
    "Gas Station Number": "0FD82",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Erfurter Straße 35",
    "Postcode": 6526,
    "City": "Sangerhausen",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.4565130",
    "Longitude": "011.3002160",
    "Telephone Number": "03464517900",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.456513,11.300216",
    "IsOpen24Hours": true,
    "id": "51.456513,11.300216"
  },
  {
    "Gas Station Number": "0FD83",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Merseburger Straße 111",
    "Postcode": 4177,
    "City": "Leipzig",
    "State": "Sachsen",
    "Latitude": "051.3381680",
    "Longitude": "012.3255990",
    "Telephone Number": "03414801898",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.338168,12.325599",
    "IsOpen24Hours": true,
    "id": "51.338168,12.325599"
  },
  {
    "Gas Station Number": "0FD84",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Löbauer Straße 65",
    "Postcode": 2625,
    "City": "Bautzen",
    "State": "Sachsen",
    "Latitude": "051.1786300",
    "Longitude": "014.4530800",
    "Telephone Number": "03591211102",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.17863,14.45308",
    "IsOpen24Hours": false,
    "id": "51.17863,14.45308"
  },
  {
    "Gas Station Number": "0FD85",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Pasewalker Straße 110",
    "Postcode": 13127,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5861530",
    "Longitude": "013.4299280",
    "Telephone Number": "0304767080",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.586153,13.429928",
    "IsOpen24Hours": true,
    "id": "52.586153,13.429928"
  },
  {
    "Gas Station Number": "0FD86",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mansfelder Straße 72a",
    "Postcode": 6333,
    "City": "Hettstedt",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.6353020",
    "Longitude": "011.5061060",
    "Telephone Number": "03476810278",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.635302,11.506106",
    "IsOpen24Hours": true,
    "id": "51.635302,11.506106"
  },
  {
    "Gas Station Number": "0FD87",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bitterfelder Straße 51",
    "Postcode": 6780,
    "City": "Zörbig",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.6254840",
    "Longitude": "012.1337210",
    "Telephone Number": "034956399202",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "05:00",
    "Saturdays To": "21:00",
    "Sundays From": "06:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.625484,12.133721",
    "IsOpen24Hours": false,
    "id": "51.625484,12.133721"
  },
  {
    "Gas Station Number": "0FD88",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Leipziger Straße 63 a",
    "Postcode": 6231,
    "City": "Bad Dürrenberg",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.2908990",
    "Longitude": "012.0762980",
    "Telephone Number": "0346282328",
    "Week From": "00:00",
    "Week To": "22:30",
    "Saturdays From": "04:30",
    "Saturdays To": "22:30",
    "Sundays From": "06:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "51.290899,12.076298",
    "IsOpen24Hours": false,
    "id": "51.290899,12.076298"
  },
  {
    "Gas Station Number": "0FD89",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Frankfurter Straße 101",
    "Postcode": 15907,
    "City": "Lübben",
    "State": "Brandenburg",
    "Latitude": "051.9424190",
    "Longitude": "013.9108700",
    "Telephone Number": "035468456",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.942419,13.91087",
    "IsOpen24Hours": true,
    "id": "51.942419,13.91087"
  },
  {
    "Gas Station Number": "0FD90",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Albrechtstraße 35",
    "Postcode": 6844,
    "City": "Dessau-Rosslau",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.8483430",
    "Longitude": "012.2418530",
    "Telephone Number": "03402210103",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.848343,12.241853",
    "IsOpen24Hours": true,
    "id": "51.848343,12.241853"
  },
  {
    "Gas Station Number": "0FD91",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lüttgenröder Straße 1",
    "Postcode": 38835,
    "City": "Osterwieck",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.9686840",
    "Longitude": "010.6997070",
    "Telephone Number": "0394216140",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "19:00",
    "Sundays From": "08:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "51.968684,10.699707",
    "IsOpen24Hours": false,
    "id": "51.968684,10.699707"
  },
  {
    "Gas Station Number": "0FD94",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lübsche Straße 161",
    "Postcode": 23966,
    "City": "Wismar",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.8944850",
    "Longitude": "011.4402390",
    "Telephone Number": "03841704718",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.894485,11.440239",
    "IsOpen24Hours": true,
    "id": "53.894485,11.440239"
  },
  {
    "Gas Station Number": "0FD95",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Leipziger Straße 208",
    "Postcode": 8058,
    "City": "Zwickau",
    "State": "Sachsen",
    "Latitude": "050.7426860",
    "Longitude": "012.4881690",
    "Telephone Number": "0375282684",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.742686,12.488169",
    "IsOpen24Hours": true,
    "id": "50.742686,12.488169"
  },
  {
    "Gas Station Number": "0FD96",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Maximilianallee 70",
    "Postcode": 4129,
    "City": "Leipzig",
    "State": "Sachsen",
    "Latitude": "051.3858000",
    "Longitude": "012.3897450",
    "Telephone Number": "03419129096",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.3858,12.389745",
    "IsOpen24Hours": true,
    "id": "51.3858,12.389745"
  },
  {
    "Gas Station Number": "0FD98",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Planitzer Straße 3-5",
    "Postcode": 8056,
    "City": "Zwickau",
    "State": "Sachsen",
    "Latitude": "050.7044390",
    "Longitude": "012.4938270",
    "Telephone Number": "0375282580",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "06:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.704439,12.493827",
    "IsOpen24Hours": false,
    "id": "50.704439,12.493827"
  },
  {
    "Gas Station Number": "0FD99",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rosenfelder Straße 13",
    "Postcode": 6116,
    "City": "Halle",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.5022480",
    "Longitude": "012.0336820",
    "Telephone Number": "03455606492",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.502248,12.033682",
    "IsOpen24Hours": true,
    "id": "51.502248,12.033682"
  },
  {
    "Gas Station Number": "0FE01",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berliner Straße 11",
    "Postcode": 15537,
    "City": "Erkner",
    "State": "Brandenburg",
    "Latitude": "052.4280550",
    "Longitude": "013.7441910",
    "Telephone Number": "0336224701",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "23:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.428055,13.744191",
    "IsOpen24Hours": false,
    "id": "52.428055,13.744191"
  },
  {
    "Gas Station Number": "0FE02",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der B 100 32",
    "Postcode": 6796,
    "City": "Brehna",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.5537360",
    "Longitude": "012.1966590",
    "Telephone Number": "03495448837",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "06:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.553736,12.196659",
    "IsOpen24Hours": false,
    "id": "51.553736,12.196659"
  },
  {
    "Gas Station Number": "0FE03",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ernst-Thälmann-Straße 2a",
    "Postcode": 29410,
    "City": "Salzwedel",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.8486530",
    "Longitude": "011.1628700",
    "Telephone Number": "0390134000",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.848653,11.16287",
    "IsOpen24Hours": true,
    "id": "52.848653,11.16287"
  },
  {
    "Gas Station Number": "0FE04",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Straße des 17. Juni 9",
    "Postcode": 1257,
    "City": "Dresden",
    "State": "Sachsen",
    "Latitude": "051.0066260",
    "Longitude": "013.8194070",
    "Telephone Number": "03512015155",
    "Week From": "05:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "07:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "51.006626,13.819407",
    "IsOpen24Hours": false,
    "id": "51.006626,13.819407"
  },
  {
    "Gas Station Number": "0FE05",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Brander Straße 55",
    "Postcode": 9599,
    "City": "Freiberg",
    "State": "Sachsen",
    "Latitude": "050.9031640",
    "Longitude": "013.3379290",
    "Telephone Number": "03731767710",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "05:00",
    "Saturdays To": "23:00",
    "Sundays From": "05:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "50.903164,13.337929",
    "IsOpen24Hours": false,
    "id": "50.903164,13.337929"
  },
  {
    "Gas Station Number": "0FE07",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Toitenwinkler Allee 1",
    "Postcode": 18147,
    "City": "Rostock",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "054.1135350",
    "Longitude": "012.1614860",
    "Telephone Number": "0381690347",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.113535,12.161486",
    "IsOpen24Hours": true,
    "id": "54.113535,12.161486"
  },
  {
    "Gas Station Number": "0FE08",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hamburger Straße 88 c",
    "Postcode": 1157,
    "City": "Dresden",
    "State": "Sachsen",
    "Latitude": "051.0628070",
    "Longitude": "013.6797350",
    "Telephone Number": "03514226969",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.062807,13.679735",
    "IsOpen24Hours": true,
    "id": "51.062807,13.679735"
  },
  {
    "Gas Station Number": "0FE09",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Potsdamer Straße 55",
    "Postcode": 14469,
    "City": "Potsdam",
    "State": "Brandenburg",
    "Latitude": "052.4195780",
    "Longitude": "013.0127950",
    "Telephone Number": "033155079450",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.419578,13.012795",
    "IsOpen24Hours": true,
    "id": "52.419578,13.012795"
  },
  {
    "Gas Station Number": "0FE10",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Permoserstraße 32a",
    "Postcode": 4318,
    "City": "Leipzig",
    "State": "Sachsen",
    "Latitude": "051.3509640",
    "Longitude": "012.4341850",
    "Telephone Number": "03412323185",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "07:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "51.350964,12.434185",
    "IsOpen24Hours": false,
    "id": "51.350964,12.434185"
  },
  {
    "Gas Station Number": "0FE11",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wittenburger Straße 121",
    "Postcode": 19059,
    "City": "Schwerin",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.6284920",
    "Longitude": "011.3897900",
    "Telephone Number": "03857851496",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.628492,11.38979",
    "IsOpen24Hours": true,
    "id": "53.628492,11.38979"
  },
  {
    "Gas Station Number": "0FE13",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mühlenstraße 26",
    "Postcode": 13187,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5638350",
    "Longitude": "013.4080010",
    "Telephone Number": "03047302020",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.563835,13.408001",
    "IsOpen24Hours": true,
    "id": "52.563835,13.408001"
  },
  {
    "Gas Station Number": "0FE14",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Köpenicker Landstr. 294,",
    "Postcode": 12437,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4647040",
    "Longitude": "013.4969920",
    "Telephone Number": "03063979990",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.464704,13.496992",
    "IsOpen24Hours": true,
    "id": "52.464704,13.496992"
  },
  {
    "Gas Station Number": "0FE15",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Werftstraße 13",
    "Postcode": 1139,
    "City": "Dresden",
    "State": "Sachsen",
    "Latitude": "051.0689930",
    "Longitude": "013.6899750",
    "Telephone Number": "03518496290",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.068993,13.689975",
    "IsOpen24Hours": true,
    "id": "51.068993,13.689975"
  },
  {
    "Gas Station Number": "0FE16",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Oranienburger Chaussee 34",
    "Postcode": 16548,
    "City": "Glienicke",
    "State": "Brandenburg",
    "Latitude": "052.6362840",
    "Longitude": "013.3068940",
    "Telephone Number": "03305677500",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.636284,13.306894",
    "IsOpen24Hours": true,
    "id": "52.636284,13.306894"
  },
  {
    "Gas Station Number": "0FE17",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Tonnaer Straße 30 b",
    "Postcode": 99947,
    "City": "Bad Langensalza",
    "State": "Thüringen",
    "Latitude": "051.1040000",
    "Longitude": "010.6638110",
    "Telephone Number": "03603815726",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.104,10.663811",
    "IsOpen24Hours": true,
    "id": "51.104,10.663811"
  },
  {
    "Gas Station Number": "0FE18",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Adlergestell 748",
    "Postcode": 12527,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.3788100",
    "Longitude": "013.6441910",
    "Telephone Number": "03067549184",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.37881,13.644191",
    "IsOpen24Hours": true,
    "id": "52.37881,13.644191"
  },
  {
    "Gas Station Number": "0FE19",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "FINKENKRUGER STRAßE 57",
    "Postcode": 14612,
    "City": "FALKENSEE",
    "State": "Brandenburg",
    "Latitude": "052.5626970",
    "Longitude": "013.0744240",
    "Telephone Number": "03322240134",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.562697,13.074424",
    "IsOpen24Hours": false,
    "id": "52.562697,13.074424"
  },
  {
    "Gas Station Number": "0FE20",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hamburger Straße 34",
    "Postcode": 14641,
    "City": "Nauen",
    "State": "Brandenburg",
    "Latitude": "052.6076820",
    "Longitude": "012.8677910",
    "Telephone Number": "03321450373",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.607682,12.867791",
    "IsOpen24Hours": true,
    "id": "52.607682,12.867791"
  },
  {
    "Gas Station Number": "0FE21",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Max-Liebermann-Straße 101",
    "Postcode": 4157,
    "City": "Leipzig",
    "State": "Sachsen",
    "Latitude": "051.3760730",
    "Longitude": "012.3776600",
    "Telephone Number": "03419121670",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.376073,12.37766",
    "IsOpen24Hours": true,
    "id": "51.376073,12.37766"
  },
  {
    "Gas Station Number": "0FE22",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Blankenauer Straße 70",
    "Postcode": 9113,
    "City": "Chemnitz",
    "State": "Sachsen",
    "Latitude": "050.8563290",
    "Longitude": "012.9294800",
    "Telephone Number": "0371449293",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.856329,12.92948",
    "IsOpen24Hours": true,
    "id": "50.856329,12.92948"
  },
  {
    "Gas Station Number": "0FE23",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wildenfelser Straße 15",
    "Postcode": 8056,
    "City": "Zwickau",
    "State": "Sachsen",
    "Latitude": "050.7020930",
    "Longitude": "012.5083050",
    "Telephone Number": "0375287020",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.702093,12.508305",
    "IsOpen24Hours": false,
    "id": "50.702093,12.508305"
  },
  {
    "Gas Station Number": "0FE24",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Siemensstraße 58",
    "Postcode": 7546,
    "City": "Gera",
    "State": "Thüringen",
    "Latitude": "050.9122590",
    "Longitude": "012.0648240",
    "Telephone Number": "036577349500",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.912259,12.064824",
    "IsOpen24Hours": true,
    "id": "50.912259,12.064824"
  },
  {
    "Gas Station Number": "0FE25",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dresdner Straße 164",
    "Postcode": 1705,
    "City": "Freital",
    "State": "Sachsen",
    "Latitude": "051.0017630",
    "Longitude": "013.6514850",
    "Telephone Number": "03514601502",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.001763,13.651485",
    "IsOpen24Hours": true,
    "id": "51.001763,13.651485"
  },
  {
    "Gas Station Number": "0FE26",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Osdorfer Ring 33-35",
    "Postcode": 14979,
    "City": "Großbeeren",
    "State": "Brandenburg",
    "Latitude": "052.3779696",
    "Longitude": "013.3121690",
    "Telephone Number": "03370190587",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.3779696,13.312169",
    "IsOpen24Hours": true,
    "id": "52.3779696,13.312169"
  },
  {
    "Gas Station Number": "0FE28",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Aralallee 1",
    "Postcode": 99441,
    "City": "Mellingen",
    "State": "Thüringen",
    "Latitude": "050.9346700",
    "Longitude": "011.3896350",
    "Telephone Number": "03645380630",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.93467,11.389635",
    "IsOpen24Hours": true,
    "id": "50.93467,11.389635"
  },
  {
    "Gas Station Number": "0FE27",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alter Spandauer Weg 5",
    "Postcode": 14641,
    "City": "Wustermark",
    "State": "Brandenburg",
    "Latitude": "052.5400850",
    "Longitude": "012.9776010",
    "Telephone Number": "03323488174",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.540085,12.977601",
    "IsOpen24Hours": true,
    "id": "52.540085,12.977601"
  },
  {
    "Gas Station Number": "0FE29",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Hallesche Straße 41/Schwa",
    "Postcode": 6686,
    "City": "Lützen / OT Zorbau",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.1777070",
    "Longitude": "012.0101840",
    "Telephone Number": "03444192234",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.177707,12.010184",
    "IsOpen24Hours": true,
    "id": "51.177707,12.010184"
  },
  {
    "Gas Station Number": "0FE30",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Frankfurter Chaussee 68 /",
    "Postcode": 15370,
    "City": "Vogelsdorf",
    "State": "Brandenburg",
    "Latitude": "052.4995560",
    "Longitude": "013.7438010",
    "Telephone Number": "03343965601",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.499556,13.743801",
    "IsOpen24Hours": true,
    "id": "52.499556,13.743801"
  },
  {
    "Gas Station Number": "0FE31",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Märkische Allee 155",
    "Postcode": 12681,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5320260",
    "Longitude": "013.5363050",
    "Telephone Number": "03054397011",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "06:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "52.532026,13.536305",
    "IsOpen24Hours": false,
    "id": "52.532026,13.536305"
  },
  {
    "Gas Station Number": "0FE32",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Spenglerstr. 2",
    "Postcode": 16356,
    "City": "Ahrensfelde",
    "State": "Brandenburg",
    "Latitude": "052.6081080",
    "Longitude": "013.5288800",
    "Telephone Number": "03094113252",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.608108,13.52888",
    "IsOpen24Hours": true,
    "id": "52.608108,13.52888"
  },
  {
    "Gas Station Number": "0FE33",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Rudolf-Diesel-Straße 02",
    "Postcode": 19243,
    "City": "Wittenburg",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.5022510",
    "Longitude": "011.0979560",
    "Telephone Number": "03885252182",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.502251,11.097956",
    "IsOpen24Hours": true,
    "id": "53.502251,11.097956"
  },
  {
    "Gas Station Number": "0FE34",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Güstrower Straße 32",
    "Postcode": 19055,
    "City": "Schwerin",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.6434480",
    "Longitude": "011.4247850",
    "Telephone Number": "03855815854",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.643448,11.424785",
    "IsOpen24Hours": true,
    "id": "53.643448,11.424785"
  },
  {
    "Gas Station Number": "0FE35",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Nordstr. 1/B 71",
    "Postcode": 6420,
    "City": "Könnern",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.6890580",
    "Longitude": "011.7672140",
    "Telephone Number": "03469151256",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.689058,11.767214",
    "IsOpen24Hours": true,
    "id": "51.689058,11.767214"
  },
  {
    "Gas Station Number": "0FE36",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lichtenseer Str.  18",
    "Postcode": 1619,
    "City": "Zeithain",
    "State": "Sachsen",
    "Latitude": "051.3404080",
    "Longitude": "013.3477220",
    "Telephone Number": "03525761326",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.340408,13.347722",
    "IsOpen24Hours": true,
    "id": "51.340408,13.347722"
  },
  {
    "Gas Station Number": "0FE37",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bergstr. 121",
    "Postcode": 1217,
    "City": "Dresden",
    "State": "Sachsen",
    "Latitude": "051.0198330",
    "Longitude": "013.7300030",
    "Telephone Number": "03514016150",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.019833,13.730003",
    "IsOpen24Hours": true,
    "id": "51.019833,13.730003"
  },
  {
    "Gas Station Number": "0FE38",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Grabower Straße 2",
    "Postcode": 39126,
    "City": "Magdeburg",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.2048780",
    "Longitude": "011.6722120",
    "Telephone Number": "039125443057",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.204878,11.672212",
    "IsOpen24Hours": true,
    "id": "52.204878,11.672212"
  },
  {
    "Gas Station Number": "0FE39",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Weißenseer Straße 68",
    "Postcode": 99610,
    "City": "Sömmerda",
    "State": "Thüringen",
    "Latitude": "051.1631510",
    "Longitude": "011.1068330",
    "Telephone Number": "03634612704",
    "Week From": "04:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "06:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.163151,11.106833",
    "IsOpen24Hours": false,
    "id": "51.163151,11.106833"
  },
  {
    "Gas Station Number": "0FE82",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schützenstraße 11",
    "Postcode": 12529,
    "City": "Schönefeld",
    "State": "Berlin",
    "Latitude": "052.3948260",
    "Longitude": "013.5336240",
    "Telephone Number": "03063497445",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.394826,13.533624",
    "IsOpen24Hours": true,
    "id": "52.394826,13.533624"
  },
  {
    "Gas Station Number": "0FE40",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Treuenbrietzener Straße 64",
    "Postcode": 14913,
    "City": "Niedergörsdorf",
    "State": "Brandenburg",
    "Latitude": "052.0089940",
    "Longitude": "013.0120000",
    "Telephone Number": "033724429633",
    "Week From": "05:00",
    "Week To": "20:00",
    "Saturdays From": "06:00",
    "Saturdays To": "20:00",
    "Sundays From": "07:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "52.008994,13.012",
    "IsOpen24Hours": false,
    "id": "52.008994,13.012"
  },
  {
    "Gas Station Number": "0FE41",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Lückefeld 2",
    "Postcode": 15831,
    "City": "Mahlow",
    "State": "Brandenburg",
    "Latitude": "052.3581720",
    "Longitude": "013.4323880",
    "Telephone Number": "03379379701",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.358172,13.432388",
    "IsOpen24Hours": true,
    "id": "52.358172,13.432388"
  },
  {
    "Gas Station Number": "0FE42",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Im Gewerbegebiet 1",
    "Postcode": 14778,
    "City": "Wollin b.Brandenburg",
    "State": "Brandenburg",
    "Latitude": "052.2890880",
    "Longitude": "012.4643090",
    "Telephone Number": "03383371843",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.289088,12.464309",
    "IsOpen24Hours": true,
    "id": "52.289088,12.464309"
  },
  {
    "Gas Station Number": "0FG28",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Robert-Guthmann-Straße 1",
    "Postcode": 15713,
    "City": "Königs Wusterhausen",
    "State": "Brandenburg",
    "Latitude": "052.3144960",
    "Longitude": "013.6627010",
    "Telephone Number": "03375507127",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.314496,13.662701",
    "IsOpen24Hours": true,
    "id": "52.314496,13.662701"
  },
  {
    "Gas Station Number": "0FE43",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berliner Allee 41",
    "Postcode": 15806,
    "City": "Zossen / OT Wünsdorf",
    "State": "Brandenburg",
    "Latitude": "052.1709550",
    "Longitude": "013.4714560",
    "Telephone Number": "03370266125",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.170955,13.471456",
    "IsOpen24Hours": true,
    "id": "52.170955,13.471456"
  },
  {
    "Gas Station Number": "0FE44",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Ahornstraße 1",
    "Postcode": 19077,
    "City": "Rastow",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.4389690",
    "Longitude": "011.4895840",
    "Telephone Number": "03875388842",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.438969,11.489584",
    "IsOpen24Hours": true,
    "id": "53.438969,11.489584"
  },
  {
    "Gas Station Number": "0FE84",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Altlandsberger Chaussee27",
    "Postcode": 15366,
    "City": "Hoppegarten",
    "State": "Brandenburg",
    "Latitude": "052.5440510",
    "Longitude": "013.6469900",
    "Telephone Number": "03342423763",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.544051,13.64699",
    "IsOpen24Hours": true,
    "id": "52.544051,13.64699"
  },
  {
    "Gas Station Number": "0FM13",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Zuckerfabrik 1",
    "Postcode": 39343,
    "City": "Alleringersleben",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.2094170",
    "Longitude": "011.1330190",
    "Telephone Number": "03940092246",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.209417,11.133019",
    "IsOpen24Hours": true,
    "id": "52.209417,11.133019"
  },
  {
    "Gas Station Number": "0FE45",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Hengstbergstraße 11",
    "Postcode": 4668,
    "City": "Grimma",
    "State": "Sachsen",
    "Latitude": "051.2561470",
    "Longitude": "012.7253920",
    "Telephone Number": "034379407130",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.256147,12.725392",
    "IsOpen24Hours": true,
    "id": "51.256147,12.725392"
  },
  {
    "Gas Station Number": "0FE46",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Suhler Straße 5 b",
    "Postcode": 99885,
    "City": "Ohrdruf",
    "State": "Thüringen",
    "Latitude": "050.8151650",
    "Longitude": "010.7296850",
    "Telephone Number": "03624313753",
    "Week From": "06:00",
    "Week To": "19:00",
    "Saturdays From": "08:00",
    "Saturdays To": "18:00",
    "Sundays From": "08:00",
    "Sundays To": "18:00",
    "24 hour?": "Nein",
    "location": "50.815165,10.729685",
    "IsOpen24Hours": false,
    "id": "50.815165,10.729685"
  },
  {
    "Gas Station Number": "0FE47",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "SIEDLERWEG 1",
    "Postcode": 2923,
    "City": "Kodersdorf",
    "State": "Sachsen",
    "Latitude": "051.2256740",
    "Longitude": "014.9020960",
    "Telephone Number": "03582562337",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.225674,14.902096",
    "IsOpen24Hours": true,
    "id": "51.225674,14.902096"
  },
  {
    "Gas Station Number": "0FE48",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Neue Schichtstraße 16",
    "Postcode": 9366,
    "City": "Niederdorf",
    "State": "Sachsen",
    "Latitude": "050.7244860",
    "Longitude": "012.7788260",
    "Telephone Number": "03729692441",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.724486,12.778826",
    "IsOpen24Hours": true,
    "id": "50.724486,12.778826"
  },
  {
    "Gas Station Number": "0FE49",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Brücker Landstraße 22 a",
    "Postcode": 14806,
    "City": "Belzig",
    "State": "Brandenburg",
    "Latitude": "052.1446990",
    "Longitude": "012.6128560",
    "Telephone Number": "03384130222",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.144699,12.612856",
    "IsOpen24Hours": false,
    "id": "52.144699,12.612856"
  },
  {
    "Gas Station Number": "0FE50",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Geltestraße 1",
    "Postcode": 6184,
    "City": "Dölbau",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.4763330",
    "Longitude": "012.0862640",
    "Telephone Number": "03460252411",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.476333,12.086264",
    "IsOpen24Hours": true,
    "id": "51.476333,12.086264"
  },
  {
    "Gas Station Number": "0FG66",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Liebenwalder Straße 10 a",
    "Postcode": 16567,
    "City": "Mühlenbeck",
    "State": "Brandenburg",
    "Latitude": "052.6692960",
    "Longitude": "013.3775380",
    "Telephone Number": "03305682856",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.669296,13.377538",
    "IsOpen24Hours": false,
    "id": "52.669296,13.377538"
  },
  {
    "Gas Station Number": "0FG13",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A4",
    "Postcode": 9244,
    "City": "Oberlichtenau",
    "State": "Sachsen",
    "Latitude": "050.8934690",
    "Longitude": "012.9459900",
    "Telephone Number": "03720883523",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.893469,12.94599",
    "IsOpen24Hours": true,
    "id": "50.893469,12.94599"
  },
  {
    "Gas Station Number": "0FG24",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Graf-York-Straße 23",
    "Postcode": 19061,
    "City": "Schwerin",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.5908340",
    "Longitude": "011.4306410",
    "Telephone Number": "03853921301",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.590834,11.430641",
    "IsOpen24Hours": true,
    "id": "53.590834,11.430641"
  },
  {
    "Gas Station Number": "0FG32",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Werner-von-Siemens-Straße",
    "Postcode": 16321,
    "City": "Bernau",
    "State": "Brandenburg",
    "Latitude": "052.6861210",
    "Longitude": "013.5701940",
    "Telephone Number": "03338758845",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.686121,13.570194",
    "IsOpen24Hours": true,
    "id": "52.686121,13.570194"
  },
  {
    "Gas Station Number": "0FM05",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "BAB  A 20 - Fuchsberg Nor",
    "Postcode": 23992,
    "City": "Glasin",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.9107840",
    "Longitude": "011.7585710",
    "Telephone Number": "038429446611",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.910784,11.758571",
    "IsOpen24Hours": true,
    "id": "53.910784,11.758571"
  },
  {
    "Gas Station Number": "0FM04",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "BAB A 20 - Fuchsberg Süd",
    "Postcode": 23992,
    "City": "Glasin",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.9089770",
    "Longitude": "011.7567380",
    "Telephone Number": "038429446620",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.908977,11.756738",
    "IsOpen24Hours": true,
    "id": "53.908977,11.756738"
  },
  {
    "Gas Station Number": "0FE51",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Holzmarktstraße 12/14",
    "Postcode": 10179,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5141510",
    "Longitude": "013.4214870",
    "Telephone Number": "03024720748",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.514151,13.421487",
    "IsOpen24Hours": true,
    "id": "52.514151,13.421487"
  },
  {
    "Gas Station Number": "0FE52",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Elsterwerdaer Straße 19 a",
    "Postcode": 1558,
    "City": "Großenhain",
    "State": "Sachsen",
    "Latitude": "051.2980170",
    "Longitude": "013.5337960",
    "Telephone Number": "0352238555",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.298017,13.533796",
    "IsOpen24Hours": true,
    "id": "51.298017,13.533796"
  },
  {
    "Gas Station Number": "0FE53",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hochuferstraße 11",
    "Postcode": 1662,
    "City": "Meißen",
    "State": "Sachsen",
    "Latitude": "051.1691110",
    "Longitude": "013.4713230",
    "Telephone Number": "03521453101",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.169111,13.471323",
    "IsOpen24Hours": true,
    "id": "51.169111,13.471323"
  },
  {
    "Gas Station Number": "0FE54",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Radeberger Straße 18",
    "Postcode": 1471,
    "City": "Radeburg",
    "State": "Sachsen",
    "Latitude": "051.2141910",
    "Longitude": "013.7305800",
    "Telephone Number": "0352082321",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.214191,13.73058",
    "IsOpen24Hours": true,
    "id": "51.214191,13.73058"
  },
  {
    "Gas Station Number": "0FE55",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Dresdner Tor Nord / A 4",
    "Postcode": 1723,
    "City": "Wilsdruff",
    "State": "Sachsen",
    "Latitude": "051.0625050",
    "Longitude": "013.5750560",
    "Telephone Number": "0352049420",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.062505,13.575056",
    "IsOpen24Hours": true,
    "id": "51.062505,13.575056"
  },
  {
    "Gas Station Number": "0FE56",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Dresdner Tor Süd / A 4",
    "Postcode": 1723,
    "City": "Wilsdruff",
    "State": "Sachsen",
    "Latitude": "051.0611360",
    "Longitude": "013.5688270",
    "Telephone Number": "0352049430",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.061136,13.568827",
    "IsOpen24Hours": true,
    "id": "51.061136,13.568827"
  },
  {
    "Gas Station Number": "0FE57",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alte Altenberger Str. 40A",
    "Postcode": 1744,
    "City": "Dippoldiswalde",
    "State": "Sachsen",
    "Latitude": "050.8868760",
    "Longitude": "013.6709730",
    "Telephone Number": "03504612652",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.886876,13.670973",
    "IsOpen24Hours": true,
    "id": "50.886876,13.670973"
  },
  {
    "Gas Station Number": "0FE58",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schopperstraße 83",
    "Postcode": 7937,
    "City": "Zeulenroda",
    "State": "Thüringen",
    "Latitude": "050.6556510",
    "Longitude": "011.9932550",
    "Telephone Number": "03662882710",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.655651,11.993255",
    "IsOpen24Hours": true,
    "id": "50.655651,11.993255"
  },
  {
    "Gas Station Number": "0FE59",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Demminer Straße 7",
    "Postcode": 99091,
    "City": "Erfurt",
    "State": "Thüringen",
    "Latitude": "051.0131670",
    "Longitude": "010.9875430",
    "Telephone Number": "03617914006",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.013167,10.987543",
    "IsOpen24Hours": true,
    "id": "51.013167,10.987543"
  },
  {
    "Gas Station Number": "0FE60",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Marschnerstraße 50",
    "Postcode": 4109,
    "City": "Leipzig",
    "State": "Sachsen",
    "Latitude": "051.3402810",
    "Longitude": "012.3543410",
    "Telephone Number": "03419808809",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.340281,12.354341",
    "IsOpen24Hours": true,
    "id": "51.340281,12.354341"
  },
  {
    "Gas Station Number": "0FE61",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Straße des Friedens",
    "Postcode": 4668,
    "City": "Grimma",
    "State": "Sachsen",
    "Latitude": "051.2377150",
    "Longitude": "012.7232900",
    "Telephone Number": "03437910258",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.237715,12.72329",
    "IsOpen24Hours": true,
    "id": "51.237715,12.72329"
  },
  {
    "Gas Station Number": "0FE62",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der Tabaksmühle",
    "Postcode": 4277,
    "City": "Leipzig",
    "State": "Sachsen",
    "Latitude": "051.3135770",
    "Longitude": "012.4046120",
    "Telephone Number": "0341868480",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.313577,12.404612",
    "IsOpen24Hours": true,
    "id": "51.313577,12.404612"
  },
  {
    "Gas Station Number": "0FE63",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bautzener Straße 78",
    "Postcode": 2681,
    "City": "Schirgiswalde-Kirsch",
    "State": "Sachsen",
    "Latitude": "051.0965520",
    "Longitude": "014.4306560",
    "Telephone Number": "03592380130",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.096552,14.430656",
    "IsOpen24Hours": false,
    "id": "51.096552,14.430656"
  },
  {
    "Gas Station Number": "0FE64",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Elbstraße 8",
    "Postcode": 1814,
    "City": "Rathmannsdorf",
    "State": "Sachsen",
    "Latitude": "050.9235190",
    "Longitude": "014.1347110",
    "Telephone Number": "03502243088",
    "Week From": "04:00",
    "Week To": "23:00",
    "Saturdays From": "04:00",
    "Saturdays To": "23:00",
    "Sundays From": "04:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "50.923519,14.134711",
    "IsOpen24Hours": false,
    "id": "50.923519,14.134711"
  },
  {
    "Gas Station Number": "0FE65",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Außenring 8",
    "Postcode": 4860,
    "City": "Torgau",
    "State": "Sachsen",
    "Latitude": "051.5613060",
    "Longitude": "012.9736150",
    "Telephone Number": "03421711855",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.561306,12.973615",
    "IsOpen24Hours": true,
    "id": "51.561306,12.973615"
  },
  {
    "Gas Station Number": "0FE66",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Leipziger Straße 31",
    "Postcode": 4758,
    "City": "Oschatz",
    "State": "Sachsen",
    "Latitude": "051.3040550",
    "Longitude": "013.0937890",
    "Telephone Number": "03435926583",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.304055,13.093789",
    "IsOpen24Hours": true,
    "id": "51.304055,13.093789"
  },
  {
    "Gas Station Number": "0FG69",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ostring 250-254",
    "Postcode": 24148,
    "City": "Kiel",
    "State": "Schleswig-Holstein",
    "Latitude": "054.3135580",
    "Longitude": "010.1628860",
    "Telephone Number": "0431724260",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.313558,10.162886",
    "IsOpen24Hours": true,
    "id": "54.313558,10.162886"
  },
  {
    "Gas Station Number": "0FG70",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hamburger Straße 35",
    "Postcode": 22941,
    "City": "Bargteheide",
    "State": "Schleswig-Holstein",
    "Latitude": "053.7242560",
    "Longitude": "010.2553790",
    "Telephone Number": "04532501590",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.724256,10.255379",
    "IsOpen24Hours": true,
    "id": "53.724256,10.255379"
  },
  {
    "Gas Station Number": "0FG71",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schoenningstedter Str. 1",
    "Postcode": 21521,
    "City": "Aumühle",
    "State": "Schleswig-Holstein",
    "Latitude": "053.5300830",
    "Longitude": "010.3119460",
    "Telephone Number": "04104969968",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.530083,10.311946",
    "IsOpen24Hours": false,
    "id": "53.530083,10.311946"
  },
  {
    "Gas Station Number": "0FG72",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Flensburger Straße 1",
    "Postcode": 24340,
    "City": "Eckernförde",
    "State": "Schleswig-Holstein",
    "Latitude": "054.4650460",
    "Longitude": "009.8342870",
    "Telephone Number": "04351470645",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.465046,9.834287",
    "IsOpen24Hours": true,
    "id": "54.465046,9.834287"
  },
  {
    "Gas Station Number": "0FG73",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Husumer Straße 30",
    "Postcode": 24941,
    "City": "Flensburg",
    "State": "Schleswig-Holstein",
    "Latitude": "054.7750360",
    "Longitude": "009.4260410",
    "Telephone Number": "046197312",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.775036,9.426041",
    "IsOpen24Hours": true,
    "id": "54.775036,9.426041"
  },
  {
    "Gas Station Number": "0FG74",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Geesthachter Straße 24-28",
    "Postcode": 21502,
    "City": "Geesthacht",
    "State": "Schleswig-Holstein",
    "Latitude": "053.4406000",
    "Longitude": "010.3656220",
    "Telephone Number": "041522591",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.4406,10.365622",
    "IsOpen24Hours": true,
    "id": "53.4406,10.365622"
  },
  {
    "Gas Station Number": "0FG75",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hansastraße 44",
    "Postcode": 21502,
    "City": "Geesthacht",
    "State": "Schleswig-Holstein",
    "Latitude": "053.4404350",
    "Longitude": "010.3930300",
    "Telephone Number": "04152847546",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "53.440435,10.39303",
    "IsOpen24Hours": false,
    "id": "53.440435,10.39303"
  },
  {
    "Gas Station Number": "0FG76",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sieker Landstraße 235",
    "Postcode": 22927,
    "City": "Großhansdorf",
    "State": "Schleswig-Holstein",
    "Latitude": "053.6442590",
    "Longitude": "010.2787940",
    "Telephone Number": "04102697052",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.644259,10.278794",
    "IsOpen24Hours": true,
    "id": "53.644259,10.278794"
  },
  {
    "Gas Station Number": "0FG77",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Steenbeker Weg 58",
    "Postcode": 24106,
    "City": "Kiel",
    "State": "Schleswig-Holstein",
    "Latitude": "054.3557760",
    "Longitude": "010.1050310",
    "Telephone Number": "0431331991",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "08:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "54.355776,10.105031",
    "IsOpen24Hours": false,
    "id": "54.355776,10.105031"
  },
  {
    "Gas Station Number": "0FG82",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rautenbergstraße 37A",
    "Postcode": 24306,
    "City": "Plön",
    "State": "Schleswig-Holstein",
    "Latitude": "054.1571660",
    "Longitude": "010.4409250",
    "Telephone Number": "04522760176",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.157166,10.440925",
    "IsOpen24Hours": true,
    "id": "54.157166,10.440925"
  },
  {
    "Gas Station Number": "0FG84",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Goehrdestraße 1 A",
    "Postcode": 29549,
    "City": "Bad Bevensen",
    "State": "Niedersachsen",
    "Latitude": "053.0794690",
    "Longitude": "010.5888830",
    "Telephone Number": "058212724",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "53.079469,10.588883",
    "IsOpen24Hours": false,
    "id": "53.079469,10.588883"
  },
  {
    "Gas Station Number": "0FG86",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lueneburger Straße 4",
    "Postcode": 21354,
    "City": "Bleckede",
    "State": "Niedersachsen",
    "Latitude": "053.2896970",
    "Longitude": "010.7304390",
    "Telephone Number": "05852453",
    "Week From": "05:30",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "53.289697,10.730439",
    "IsOpen24Hours": false,
    "id": "53.289697,10.730439"
  },
  {
    "Gas Station Number": "0FG88",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hoheluftchaussee 78",
    "Postcode": 20253,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5828960",
    "Longitude": "009.9712920",
    "Telephone Number": "040483601",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.582896,9.971292",
    "IsOpen24Hours": true,
    "id": "53.582896,9.971292"
  },
  {
    "Gas Station Number": "0FG89",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bundesstraße 38",
    "Postcode": 20146,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5659700",
    "Longitude": "009.9796680",
    "Telephone Number": "040456460",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.56597,9.979668",
    "IsOpen24Hours": true,
    "id": "53.56597,9.979668"
  },
  {
    "Gas Station Number": "0FG90",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kieler Straße 314",
    "Postcode": 22525,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5858600",
    "Longitude": "009.9333050",
    "Telephone Number": "04054766700",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.58586,9.933305",
    "IsOpen24Hours": true,
    "id": "53.58586,9.933305"
  },
  {
    "Gas Station Number": "0FG91",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Otto-Brenner-Straße 6",
    "Postcode": 21109,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.4900150",
    "Longitude": "010.0190050",
    "Telephone Number": "0407542478",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.490015,10.019005",
    "IsOpen24Hours": true,
    "id": "53.490015,10.019005"
  },
  {
    "Gas Station Number": "0FG92",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kieler Straße 560",
    "Postcode": 22525,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5999400",
    "Longitude": "009.9173730",
    "Telephone Number": "040545652",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.59994,9.917373",
    "IsOpen24Hours": false,
    "id": "53.59994,9.917373"
  },
  {
    "Gas Station Number": "0FG93",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sülldorfer Landstraße 91",
    "Postcode": 22589,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5779610",
    "Longitude": "009.8095360",
    "Telephone Number": "040873813",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.577961,9.809536",
    "IsOpen24Hours": true,
    "id": "53.577961,9.809536"
  },
  {
    "Gas Station Number": "0FG94",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Königstraße 9",
    "Postcode": 22767,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5495910",
    "Longitude": "009.9527180",
    "Telephone Number": "040313481",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.549591,9.952718",
    "IsOpen24Hours": true,
    "id": "53.549591,9.952718"
  },
  {
    "Gas Station Number": "0FG95",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Amsinckstraße 48",
    "Postcode": 20097,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5459690",
    "Longitude": "010.0151210",
    "Telephone Number": "040230646",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.545969,10.015121",
    "IsOpen24Hours": true,
    "id": "53.545969,10.015121"
  },
  {
    "Gas Station Number": "0FG96",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dehnhaide 49",
    "Postcode": 22081,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5793130",
    "Longitude": "010.0452450",
    "Telephone Number": "040295490",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.579313,10.045245",
    "IsOpen24Hours": true,
    "id": "53.579313,10.045245"
  },
  {
    "Gas Station Number": "0FG97",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Meiendorfer Mühlenweg 6A",
    "Postcode": 22393,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.6444970",
    "Longitude": "010.1224450",
    "Telephone Number": "0406018712",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.644497,10.122445",
    "IsOpen24Hours": true,
    "id": "53.644497,10.122445"
  },
  {
    "Gas Station Number": "0FG98",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rahlstedter Straße 87",
    "Postcode": 22149,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5977350",
    "Longitude": "010.1529240",
    "Telephone Number": "04067561379",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.597735,10.152924",
    "IsOpen24Hours": true,
    "id": "53.597735,10.152924"
  },
  {
    "Gas Station Number": "0FG99",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hummelsbuettler Hauptstraße 14",
    "Postcode": 22339,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.6372090",
    "Longitude": "010.0347990",
    "Telephone Number": "04053897550",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.637209,10.034799",
    "IsOpen24Hours": true,
    "id": "53.637209,10.034799"
  },
  {
    "Gas Station Number": "0FH01",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Habichtstraße 39",
    "Postcode": 22305,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5909630",
    "Longitude": "010.0565180",
    "Telephone Number": "040615874",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.590963,10.056518",
    "IsOpen24Hours": false,
    "id": "53.590963,10.056518"
  },
  {
    "Gas Station Number": "0FH02",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Soltauer Straße 22",
    "Postcode": 21335,
    "City": "Lüneburg",
    "State": "Niedersachsen",
    "Latitude": "053.2410300",
    "Longitude": "010.4008140",
    "Telephone Number": "04131732511",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.24103,10.400814",
    "IsOpen24Hours": true,
    "id": "53.24103,10.400814"
  },
  {
    "Gas Station Number": "0FH03",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Universitätsallee 4",
    "Postcode": 21335,
    "City": "Lüneburg",
    "State": "Niedersachsen",
    "Latitude": "053.2267200",
    "Longitude": "010.4059720",
    "Telephone Number": "04131732501",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "53.22672,10.405972",
    "IsOpen24Hours": false,
    "id": "53.22672,10.405972"
  },
  {
    "Gas Station Number": "0FH05",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bismarckstraße 355",
    "Postcode": 28205,
    "City": "Bremen",
    "State": "Bremen",
    "Latitude": "053.0735990",
    "Longitude": "008.8515970",
    "Telephone Number": "0421447691",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.073599,8.851597",
    "IsOpen24Hours": true,
    "id": "53.073599,8.851597"
  },
  {
    "Gas Station Number": "0FH06",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bremer Straße 53",
    "Postcode": 28816,
    "City": "Stuhr",
    "State": "Niedersachsen",
    "Latitude": "053.0178930",
    "Longitude": "008.7893420",
    "Telephone Number": "0421891063",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.017893,8.789342",
    "IsOpen24Hours": true,
    "id": "53.017893,8.789342"
  },
  {
    "Gas Station Number": "0FH08",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Norddeicher Straße 31-32",
    "Postcode": 26506,
    "City": "Norden",
    "State": "Niedersachsen",
    "Latitude": "053.6000100",
    "Longitude": "007.1925540",
    "Telephone Number": "049314256",
    "Week From": "06:00",
    "Week To": "23:59",
    "Saturdays From": "07:00",
    "Saturdays To": "23:59",
    "Sundays From": "07:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "53.60001,7.192554",
    "IsOpen24Hours": false,
    "id": "53.60001,7.192554"
  },
  {
    "Gas Station Number": "0FH09",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alexanderstraße 202",
    "Postcode": 26121,
    "City": "Oldenburg",
    "State": "Niedersachsen",
    "Latitude": "053.1613070",
    "Longitude": "008.2077150",
    "Telephone Number": "0441883268",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.161307,8.207715",
    "IsOpen24Hours": true,
    "id": "53.161307,8.207715"
  },
  {
    "Gas Station Number": "0FH10",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nadorster Straße 288",
    "Postcode": 26125,
    "City": "Oldenburg",
    "State": "Niedersachsen",
    "Latitude": "053.1665280",
    "Longitude": "008.2272130",
    "Telephone Number": "044137770",
    "Week From": "06:30",
    "Week To": "21:00",
    "Saturdays From": "07:30",
    "Saturdays To": "21:00",
    "Sundays From": "08:30",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "53.166528,8.227213",
    "IsOpen24Hours": false,
    "id": "53.166528,8.227213"
  },
  {
    "Gas Station Number": "0FH11",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Herforder Straße 432",
    "Postcode": 33609,
    "City": "Bielefeld",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.0497470",
    "Longitude": "008.5871090",
    "Telephone Number": "05219729855",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.049747,8.587109",
    "IsOpen24Hours": true,
    "id": "52.049747,8.587109"
  },
  {
    "Gas Station Number": "0FH12",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Jöllenbecker Straße 284",
    "Postcode": 33613,
    "City": "Bielefeld",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.0523930",
    "Longitude": "008.5202090",
    "Telephone Number": "0521888330",
    "Week From": "06:00",
    "Week To": "23:00",
    "Saturdays From": "07:00",
    "Saturdays To": "23:00",
    "Sundays From": "07:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "52.052393,8.520209",
    "IsOpen24Hours": false,
    "id": "52.052393,8.520209"
  },
  {
    "Gas Station Number": "0FH13",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Detmolder Straße 490",
    "Postcode": 33605,
    "City": "Bielefeld",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.9956870",
    "Longitude": "008.5947260",
    "Telephone Number": "0521203090",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.995687,8.594726",
    "IsOpen24Hours": true,
    "id": "51.995687,8.594726"
  },
  {
    "Gas Station Number": "0FH14",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Guetersloher Straße 240",
    "Postcode": 33649,
    "City": "Bielefeld",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.9801690",
    "Longitude": "008.4775160",
    "Telephone Number": "0521412962",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.980169,8.477516",
    "IsOpen24Hours": true,
    "id": "51.980169,8.477516"
  },
  {
    "Gas Station Number": "0FH15",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wasserbreite 105",
    "Postcode": 32257,
    "City": "Bünde",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.2038560",
    "Longitude": "008.5887000",
    "Telephone Number": "052235538",
    "Week From": "06:00",
    "Week To": "23:59",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "07:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "52.203856,8.5887",
    "IsOpen24Hours": false,
    "id": "52.203856,8.5887"
  },
  {
    "Gas Station Number": "0FH16",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nordring 107",
    "Postcode": 33330,
    "City": "Gütersloh",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.9127800",
    "Longitude": "008.3667540",
    "Telephone Number": "0524114110",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.91278,8.366754",
    "IsOpen24Hours": true,
    "id": "51.91278,8.366754"
  },
  {
    "Gas Station Number": "0FH17",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gildehauser Straße 154",
    "Postcode": 48599,
    "City": "Gronau",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.2214130",
    "Longitude": "007.0421370",
    "Telephone Number": "025629919331",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.221413,7.042137",
    "IsOpen24Hours": false,
    "id": "52.221413,7.042137"
  },
  {
    "Gas Station Number": "0FH18",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bielefelder Straße 23",
    "Postcode": 33813,
    "City": "Oerlinghausen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.9689230",
    "Longitude": "008.6917720",
    "Telephone Number": "052024079",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.968923,8.691772",
    "IsOpen24Hours": false,
    "id": "51.968923,8.691772"
  },
  {
    "Gas Station Number": "0FH19",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Salzufler Straße 111",
    "Postcode": 32052,
    "City": "Herford",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.1073780",
    "Longitude": "008.6903860",
    "Telephone Number": "05221981593",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "08:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.107378,8.690386",
    "IsOpen24Hours": false,
    "id": "52.107378,8.690386"
  },
  {
    "Gas Station Number": "0FH20",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hitzepohl-West 1",
    "Postcode": 32457,
    "City": "Porta- Westfalica",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.2192820",
    "Longitude": "008.8847900",
    "Telephone Number": "057171208",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "52.219282,8.88479",
    "IsOpen24Hours": false,
    "id": "52.219282,8.88479"
  },
  {
    "Gas Station Number": "0FH21",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Coesfelder Straße 10",
    "Postcode": 48653,
    "City": "Coesfeld",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.9037020",
    "Longitude": "007.1872800",
    "Telephone Number": "02546340",
    "Week From": "07:00",
    "Week To": "20:30",
    "Saturdays From": "08:00",
    "Saturdays To": "20:00",
    "Sundays From": "10:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "51.903702,7.18728",
    "IsOpen24Hours": false,
    "id": "51.903702,7.18728"
  },
  {
    "Gas Station Number": "0FH22",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Osnabrücker Straße 82",
    "Postcode": 32312,
    "City": "Lübbecke",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.3020680",
    "Longitude": "008.5965800",
    "Telephone Number": "057418470",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.302068,8.59658",
    "IsOpen24Hours": false,
    "id": "52.302068,8.59658"
  },
  {
    "Gas Station Number": "0FH23",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wolbecker Straße 140 A",
    "Postcode": 48155,
    "City": "Münster",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.9546670",
    "Longitude": "007.6521060",
    "Telephone Number": "025165364",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.954667,7.652106",
    "IsOpen24Hours": true,
    "id": "51.954667,7.652106"
  },
  {
    "Gas Station Number": "0FH26",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hammer Straße 311-313",
    "Postcode": 48153,
    "City": "Münster",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.9317360",
    "Longitude": "007.6268740",
    "Telephone Number": "025178109",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.931736,7.626874",
    "IsOpen24Hours": true,
    "id": "51.931736,7.626874"
  },
  {
    "Gas Station Number": "0FH27",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lemfoerder Straße 4",
    "Postcode": 32369,
    "City": "Rahden",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.4328530",
    "Longitude": "008.6098950",
    "Telephone Number": "05771968092",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "08:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "52.432853,8.609895",
    "IsOpen24Hours": false,
    "id": "52.432853,8.609895"
  },
  {
    "Gas Station Number": "0FH29",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sassenberger Straße 71",
    "Postcode": 48231,
    "City": "Warendorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.9572840",
    "Longitude": "008.0079750",
    "Telephone Number": "025818091",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:30",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.957284,8.007975",
    "IsOpen24Hours": false,
    "id": "51.957284,8.007975"
  },
  {
    "Gas Station Number": "0FH30",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Osnabruecker Straße 304",
    "Postcode": 49152,
    "City": "Bad Essen",
    "State": "Niedersachsen",
    "Latitude": "052.3323650",
    "Longitude": "008.3144820",
    "Telephone Number": "054722150",
    "Week From": "05:30",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "07:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "52.332365,8.314482",
    "IsOpen24Hours": false,
    "id": "52.332365,8.314482"
  },
  {
    "Gas Station Number": "0FH31",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "August-Kuempers-Straße 6",
    "Postcode": 48493,
    "City": "Wettringen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.2109660",
    "Longitude": "007.3254450",
    "Telephone Number": "02557927752",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "05:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "52.210966,7.325445",
    "IsOpen24Hours": false,
    "id": "52.210966,7.325445"
  },
  {
    "Gas Station Number": "0FH32",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Harburger Straße 74",
    "Postcode": 29223,
    "City": "Celle",
    "State": "Niedersachsen",
    "Latitude": "052.6341770",
    "Longitude": "010.0809980",
    "Telephone Number": "05141930909",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.634177,10.080998",
    "IsOpen24Hours": true,
    "id": "52.634177,10.080998"
  },
  {
    "Gas Station Number": "0FH33",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Goslarsche Straße 64",
    "Postcode": 38678,
    "City": "Clausthal-Zellerfeld",
    "State": "Niedersachsen",
    "Latitude": "051.8211260",
    "Longitude": "010.3420640",
    "Telephone Number": "05323982818",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.821126,10.342064",
    "IsOpen24Hours": false,
    "id": "51.821126,10.342064"
  },
  {
    "Gas Station Number": "0FH35",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hinterm Hagen 21",
    "Postcode": 38442,
    "City": "Wolfsburg",
    "State": "Niedersachsen",
    "Latitude": "052.4191170",
    "Longitude": "010.7212990",
    "Telephone Number": "0536262204",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.419117,10.721299",
    "IsOpen24Hours": false,
    "id": "52.419117,10.721299"
  },
  {
    "Gas Station Number": "0FH36",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Robert-Koch-Straße 1",
    "Postcode": 38642,
    "City": "Goslar",
    "State": "Niedersachsen",
    "Latitude": "051.9220030",
    "Longitude": "010.4380660",
    "Telephone Number": "0532181468",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.922003,10.438066",
    "IsOpen24Hours": false,
    "id": "51.922003,10.438066"
  },
  {
    "Gas Station Number": "0FH38",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Marienburger Straße 85",
    "Postcode": 31141,
    "City": "Hildesheim",
    "State": "Niedersachsen",
    "Latitude": "052.1365670",
    "Longitude": "009.9703870",
    "Telephone Number": "0512184438",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.136567,9.970387",
    "IsOpen24Hours": false,
    "id": "52.136567,9.970387"
  },
  {
    "Gas Station Number": "0FH37",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ferdinand-Wallbrecht-Stra",
    "Postcode": 30163,
    "City": "Hannover",
    "State": "Niedersachsen",
    "Latitude": "052.3982490",
    "Longitude": "009.7413570",
    "Telephone Number": "05113946245",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.398249,9.741357",
    "IsOpen24Hours": true,
    "id": "52.398249,9.741357"
  },
  {
    "Gas Station Number": "0FH39",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Münsterstraße 208",
    "Postcode": 46397,
    "City": "Bocholt",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.8372690",
    "Longitude": "006.6427150",
    "Telephone Number": "02871227206",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "07:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "51.837269,6.642715",
    "IsOpen24Hours": false,
    "id": "51.837269,6.642715"
  },
  {
    "Gas Station Number": "0FH41",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Duisburger Straße 121",
    "Postcode": 47166,
    "City": "Duisburg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4893250",
    "Longitude": "006.7841940",
    "Telephone Number": "02039948050",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.489325,6.784194",
    "IsOpen24Hours": true,
    "id": "51.489325,6.784194"
  },
  {
    "Gas Station Number": "0FH42",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Weseler Straße 261",
    "Postcode": 47169,
    "City": "Duisburg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5139270",
    "Longitude": "006.7418630",
    "Telephone Number": "0203990888",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.513927,6.741863",
    "IsOpen24Hours": true,
    "id": "51.513927,6.741863"
  },
  {
    "Gas Station Number": "0FH43",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alfredstraße 156-160",
    "Postcode": 45131,
    "City": "Essen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4285740",
    "Longitude": "007.0003170",
    "Telephone Number": "0201411545",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.428574,7.000317",
    "IsOpen24Hours": true,
    "id": "51.428574,7.000317"
  },
  {
    "Gas Station Number": "0FH44",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Vom-Stein-Straße 40",
    "Postcode": 45894,
    "City": "Gelsenkirchen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5743770",
    "Longitude": "007.0601920",
    "Telephone Number": "020932900",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.574377,7.060192",
    "IsOpen24Hours": true,
    "id": "51.574377,7.060192"
  },
  {
    "Gas Station Number": "0FH45",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dorstener Straße 666",
    "Postcode": 45721,
    "City": "Haltern am See",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.7140050",
    "Longitude": "007.0943330",
    "Telephone Number": "02360901910",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "08:00",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "51.714005,7.094333",
    "IsOpen24Hours": false,
    "id": "51.714005,7.094333"
  },
  {
    "Gas Station Number": "0FH46",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Aktienstraße 226",
    "Postcode": 45473,
    "City": "Mülheim",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4444630",
    "Longitude": "006.9054060",
    "Telephone Number": "02089901013",
    "Week From": "07:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.444463,6.905406",
    "IsOpen24Hours": false,
    "id": "51.444463,6.905406"
  },
  {
    "Gas Station Number": "0FH47",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bottroper Straße 211",
    "Postcode": 46117,
    "City": "Oberhausen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5027260",
    "Longitude": "006.8921610",
    "Telephone Number": "0208890648",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.502726,6.892161",
    "IsOpen24Hours": false,
    "id": "51.502726,6.892161"
  },
  {
    "Gas Station Number": "0FH48",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Vestische Straße 157",
    "Postcode": 46117,
    "City": "Oberhausen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5094980",
    "Longitude": "006.8652550",
    "Telephone Number": "020898975792",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.509498,6.865255",
    "IsOpen24Hours": false,
    "id": "51.509498,6.865255"
  },
  {
    "Gas Station Number": "0FH49",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 139",
    "Postcode": 46562,
    "City": "Voerde",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6007300",
    "Longitude": "006.6970790",
    "Telephone Number": "0285593022",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.60073,6.697079",
    "IsOpen24Hours": true,
    "id": "51.60073,6.697079"
  },
  {
    "Gas Station Number": "0FH50",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bochumer Straße 6",
    "Postcode": 44866,
    "City": "Bochum",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4794430",
    "Longitude": "007.1498800",
    "Telephone Number": "0232788617",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.479443,7.14988",
    "IsOpen24Hours": false,
    "id": "51.479443,7.14988"
  },
  {
    "Gas Station Number": "0FH51",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sprockhoeveler Straße 1",
    "Postcode": 58455,
    "City": "Witten",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4504270",
    "Longitude": "007.3256000",
    "Telephone Number": "0230255410",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.450427,7.3256",
    "IsOpen24Hours": true,
    "id": "51.450427,7.3256"
  },
  {
    "Gas Station Number": "0FH52",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Reeser Landstraße 20",
    "Postcode": 46483,
    "City": "Wesel",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6642520",
    "Longitude": "006.6098660",
    "Telephone Number": "0281331402",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.664252,6.609866",
    "IsOpen24Hours": true,
    "id": "51.664252,6.609866"
  },
  {
    "Gas Station Number": "0FH53",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Duelmener Straße 8",
    "Postcode": 46286,
    "City": "Dorsten",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.7216430",
    "Longitude": "007.0127920",
    "Telephone Number": "023694240",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.721643,7.012792",
    "IsOpen24Hours": false,
    "id": "51.721643,7.012792"
  },
  {
    "Gas Station Number": "0FH54",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Steinfurter Straße 72",
    "Postcode": 59387,
    "City": "Ascheberg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.8004170",
    "Longitude": "007.6495960",
    "Telephone Number": "025935536",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.800417,7.649596",
    "IsOpen24Hours": false,
    "id": "51.800417,7.649596"
  },
  {
    "Gas Station Number": "0FH55",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Werler Straße 56",
    "Postcode": 59469,
    "City": "Ense",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5038100",
    "Longitude": "007.9694850",
    "Telephone Number": "029389795557",
    "Week From": "06:30",
    "Week To": "19:00",
    "Saturdays From": "07:30",
    "Saturdays To": "18:00",
    "Sundays From": "10:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "51.50381,7.969485",
    "IsOpen24Hours": false,
    "id": "51.50381,7.969485"
  },
  {
    "Gas Station Number": "0FH57",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stockumer Straße 168",
    "Postcode": 44225,
    "City": "Dortmund",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4816260",
    "Longitude": "007.4389670",
    "Telephone Number": "0231711234",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.481626,7.438967",
    "IsOpen24Hours": true,
    "id": "51.481626,7.438967"
  },
  {
    "Gas Station Number": "0FH58",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heiliger Weg / Löwenstr.",
    "Postcode": 44135,
    "City": "Dortmund",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5094970",
    "Longitude": "007.4739470",
    "Telephone Number": "0231524830",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.509497,7.473947",
    "IsOpen24Hours": true,
    "id": "51.509497,7.473947"
  },
  {
    "Gas Station Number": "0FH60",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mengeder Straße 432",
    "Postcode": 44359,
    "City": "Dortmund",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5594250",
    "Longitude": "007.3971220",
    "Telephone Number": "0231350380",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.559425,7.397122",
    "IsOpen24Hours": false,
    "id": "51.559425,7.397122"
  },
  {
    "Gas Station Number": "0FH62",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Erwitterstraße 13",
    "Postcode": 59590,
    "City": "Geseke",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6357040",
    "Longitude": "008.5055030",
    "Telephone Number": "029421371",
    "Week From": "07:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "09:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.635704,8.505503",
    "IsOpen24Hours": false,
    "id": "51.635704,8.505503"
  },
  {
    "Gas Station Number": "0FH63",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Herdecker Straße 14",
    "Postcode": 58089,
    "City": "Hagen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3858320",
    "Longitude": "007.4473280",
    "Telephone Number": "02331932809",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.385832,7.447328",
    "IsOpen24Hours": true,
    "id": "51.385832,7.447328"
  },
  {
    "Gas Station Number": "0FH64",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neuenrader Straße 2",
    "Postcode": 58802,
    "City": "Balve",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3146590",
    "Longitude": "007.8750740",
    "Telephone Number": "02375910443",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.314659,7.875074",
    "IsOpen24Hours": false,
    "id": "51.314659,7.875074"
  },
  {
    "Gas Station Number": "0FH65",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dortmunder Straße 51",
    "Postcode": 44536,
    "City": "Lünen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6099420",
    "Longitude": "007.5084810",
    "Telephone Number": "0230612366",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.609942,7.508481",
    "IsOpen24Hours": true,
    "id": "51.609942,7.508481"
  },
  {
    "Gas Station Number": "0FH66",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Briloner Straße 44",
    "Postcode": 59872,
    "City": "Meschede",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3468720",
    "Longitude": "008.2966520",
    "Telephone Number": "029151412",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.346872,8.296652",
    "IsOpen24Hours": true,
    "id": "51.346872,8.296652"
  },
  {
    "Gas Station Number": "0FH67",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Marienloher Straße 87",
    "Postcode": 33104,
    "City": "Paderborn",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.7457070",
    "Longitude": "008.7416180",
    "Telephone Number": "052542430",
    "Week From": "05:00",
    "Week To": "22:30",
    "Saturdays From": "05:00",
    "Saturdays To": "22:30",
    "Sundays From": "07:00",
    "Sundays To": "22:30",
    "24 hour?": "Nein",
    "location": "51.745707,8.741618",
    "IsOpen24Hours": false,
    "id": "51.745707,8.741618"
  },
  {
    "Gas Station Number": "0FH68",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kleiner Hellweg 2",
    "Postcode": 33154,
    "City": "Salzkotten",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6412870",
    "Longitude": "008.6648170",
    "Telephone Number": "05258940605",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.641287,8.664817",
    "IsOpen24Hours": false,
    "id": "51.641287,8.664817"
  },
  {
    "Gas Station Number": "0FH69",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Warburger Straße 120",
    "Postcode": 33100,
    "City": "Paderborn",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.7062640",
    "Longitude": "008.7771210",
    "Telephone Number": "0525162703",
    "Week From": "06:30",
    "Week To": "21:00",
    "Saturdays From": "07:30",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.706264,8.777121",
    "IsOpen24Hours": false,
    "id": "51.706264,8.777121"
  },
  {
    "Gas Station Number": "0FH70",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hellweg 34 A",
    "Postcode": 59192,
    "City": "Bergkamen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6513350",
    "Longitude": "007.6627840",
    "Telephone Number": "023899260320",
    "Week From": "05:00",
    "Week To": "23:59",
    "Saturdays From": "05:00",
    "Saturdays To": "23:59",
    "Sundays From": "05:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.651335,7.662784",
    "IsOpen24Hours": true,
    "id": "51.651335,7.662784"
  },
  {
    "Gas Station Number": "0FH71",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hansastraße 145",
    "Postcode": 59427,
    "City": "Unna",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5402800",
    "Longitude": "007.6557110",
    "Telephone Number": "02303591025",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "08:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.54028,7.655711",
    "IsOpen24Hours": false,
    "id": "51.54028,7.655711"
  },
  {
    "Gas Station Number": "0FH72",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Engelbertstraße",
    "Postcode": 41462,
    "City": "Neuss",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2071040",
    "Longitude": "006.6711110",
    "Telephone Number": "02131561102",
    "Week From": "06:00",
    "Week To": "23:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.207104,6.671111",
    "IsOpen24Hours": false,
    "id": "51.207104,6.671111"
  },
  {
    "Gas Station Number": "0FH73",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Freiligrathplatz 32",
    "Postcode": 40474,
    "City": "Düsseldorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2650860",
    "Longitude": "006.7534240",
    "Telephone Number": "02114350080",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.265086,6.753424",
    "IsOpen24Hours": true,
    "id": "51.265086,6.753424"
  },
  {
    "Gas Station Number": "0FH74",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Brehmstraße 90",
    "Postcode": 40239,
    "City": "Düsseldorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2479190",
    "Longitude": "006.8015480",
    "Telephone Number": "0211627301",
    "Week From": "05:00",
    "Week To": "23:59",
    "Saturdays From": "05:00",
    "Saturdays To": "23:59",
    "Sundays From": "05:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "51.247919,6.801548",
    "IsOpen24Hours": false,
    "id": "51.247919,6.801548"
  },
  {
    "Gas Station Number": "0FH75",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Pariser Straße 82",
    "Postcode": 40549,
    "City": "Düsseldorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2333340",
    "Longitude": "006.7278580",
    "Telephone Number": "0211501372",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.233334,6.727858",
    "IsOpen24Hours": true,
    "id": "51.233334,6.727858"
  },
  {
    "Gas Station Number": "0FH78",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gerresheimer Straße 122",
    "Postcode": 40721,
    "City": "Hilden",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1765660",
    "Longitude": "006.9275300",
    "Telephone Number": "02103390049",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.176566,6.92753",
    "IsOpen24Hours": false,
    "id": "51.176566,6.92753"
  },
  {
    "Gas Station Number": "0FH79",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Haaner Straße 77",
    "Postcode": 40699,
    "City": "Erkrath",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2044070",
    "Longitude": "006.9702300",
    "Telephone Number": "02104948379",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.204407,6.97023",
    "IsOpen24Hours": false,
    "id": "51.204407,6.97023"
  },
  {
    "Gas Station Number": "0FH81",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gladbacher Straße 313-331",
    "Postcode": 47805,
    "City": "Krefeld",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3144660",
    "Longitude": "006.5577820",
    "Telephone Number": "02151399027",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.314466,6.557782",
    "IsOpen24Hours": true,
    "id": "51.314466,6.557782"
  },
  {
    "Gas Station Number": "0FH82",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Krummenweg 9",
    "Postcode": 40885,
    "City": "Ratingen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3384510",
    "Longitude": "006.8690880",
    "Telephone Number": "0210218051",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "51.338451,6.869088",
    "IsOpen24Hours": false,
    "id": "51.338451,6.869088"
  },
  {
    "Gas Station Number": "0FH83",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bergheimer Straße 252",
    "Postcode": 41464,
    "City": "Neuss",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1816400",
    "Longitude": "006.6882580",
    "Telephone Number": "0213141655",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.18164,6.688258",
    "IsOpen24Hours": true,
    "id": "51.18164,6.688258"
  },
  {
    "Gas Station Number": "0FH84",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nievenheimer Straße 6",
    "Postcode": 41469,
    "City": "Neuss",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1577180",
    "Longitude": "006.7311790",
    "Telephone Number": "021372257",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.157718,6.731179",
    "IsOpen24Hours": false,
    "id": "51.157718,6.731179"
  },
  {
    "Gas Station Number": "0FH85",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heidestraße 116-120",
    "Postcode": 42549,
    "City": "Velbert",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3318620",
    "Longitude": "007.0385490",
    "Telephone Number": "02051255786",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.331862,7.038549",
    "IsOpen24Hours": true,
    "id": "51.331862,7.038549"
  },
  {
    "Gas Station Number": "0FH86",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Krefelder Straße 136",
    "Postcode": 47877,
    "City": "Willich",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2733480",
    "Longitude": "006.5550310",
    "Telephone Number": "021543045",
    "Week From": "07:00",
    "Week To": "21:00",
    "Saturdays From": "07:30",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.273348,6.555031",
    "IsOpen24Hours": false,
    "id": "51.273348,6.555031"
  },
  {
    "Gas Station Number": "0FH89",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Langerfelder Straße 121",
    "Postcode": 42389,
    "City": "Wuppertal",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2741350",
    "Longitude": "007.2389010",
    "Telephone Number": "02022602549",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.274135,7.238901",
    "IsOpen24Hours": true,
    "id": "51.274135,7.238901"
  },
  {
    "Gas Station Number": "0FH90",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Uellendahler Straße 264",
    "Postcode": 42109,
    "City": "Wuppertal",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2760990",
    "Longitude": "007.1554940",
    "Telephone Number": "0202750411",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "07:00",
    "Saturdays To": "23:59",
    "Sundays From": "07:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.276099,7.155494",
    "IsOpen24Hours": true,
    "id": "51.276099,7.155494"
  },
  {
    "Gas Station Number": "0FH91",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nevigeser Straße 31",
    "Postcode": 42113,
    "City": "Wuppertal",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2687260",
    "Longitude": "007.1268510",
    "Telephone Number": "02022761355",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.268726,7.126851",
    "IsOpen24Hours": true,
    "id": "51.268726,7.126851"
  },
  {
    "Gas Station Number": "0FH92",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Carnaper Straße 70",
    "Postcode": 42283,
    "City": "Wuppertal",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2763120",
    "Longitude": "007.1898700",
    "Telephone Number": "0202507341",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.276312,7.18987",
    "IsOpen24Hours": true,
    "id": "51.276312,7.18987"
  },
  {
    "Gas Station Number": "0FH93",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Roermonder Straße 33",
    "Postcode": 52072,
    "City": "Aachen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7839040",
    "Longitude": "006.0745910",
    "Telephone Number": "0241872088",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.783904,6.074591",
    "IsOpen24Hours": true,
    "id": "50.783904,6.074591"
  },
  {
    "Gas Station Number": "0FH94",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Joseph-von-Görres Str. 53",
    "Postcode": 52068,
    "City": "Aachen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7791930",
    "Longitude": "006.1097240",
    "Telephone Number": "0241502672",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.779193,6.109724",
    "IsOpen24Hours": true,
    "id": "50.779193,6.109724"
  },
  {
    "Gas Station Number": "0FH95",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der Bundesstraße 258",
    "Postcode": 53534,
    "City": "Barweiler",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.3574590",
    "Longitude": "006.8571730",
    "Telephone Number": "02691930070",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "06:00",
    "Saturdays To": "20:00",
    "Sundays From": "06:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.357459,6.857173",
    "IsOpen24Hours": false,
    "id": "50.357459,6.857173"
  },
  {
    "Gas Station Number": "0FH96",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Markt 2",
    "Postcode": 54634,
    "City": "Bitburg",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.9730650",
    "Longitude": "006.5214330",
    "Telephone Number": "065619464660",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.973065,6.521433",
    "IsOpen24Hours": false,
    "id": "49.973065,6.521433"
  },
  {
    "Gas Station Number": "0FH97",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 26",
    "Postcode": 56823,
    "City": "Büchel",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.1739230",
    "Longitude": "007.0823130",
    "Telephone Number": "02678801",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.173923,7.082313",
    "IsOpen24Hours": false,
    "id": "50.173923,7.082313"
  },
  {
    "Gas Station Number": "0FH98",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bonner Straße 16",
    "Postcode": 54550,
    "City": "Daun",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.2032250",
    "Longitude": "006.8267130",
    "Telephone Number": "06592985318",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.203225,6.826713",
    "IsOpen24Hours": false,
    "id": "50.203225,6.826713"
  },
  {
    "Gas Station Number": "0FH99",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rochusstraße 46",
    "Postcode": 53123,
    "City": "Bonn",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7191920",
    "Longitude": "007.0567800",
    "Telephone Number": "0228624902",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.719192,7.05678",
    "IsOpen24Hours": true,
    "id": "50.719192,7.05678"
  },
  {
    "Gas Station Number": "0FI02",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kölner Straße 177",
    "Postcode": 53879,
    "City": "Euskirchen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.6622700",
    "Longitude": "006.8058350",
    "Telephone Number": "022512190",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.66227,6.805835",
    "IsOpen24Hours": true,
    "id": "50.66227,6.805835"
  },
  {
    "Gas Station Number": "0FI03",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gerolsteiner Straße 47",
    "Postcode": 54570,
    "City": "Pelm",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.2245960",
    "Longitude": "006.6787830",
    "Telephone Number": "065913700",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.224596,6.678783",
    "IsOpen24Hours": false,
    "id": "50.224596,6.678783"
  },
  {
    "Gas Station Number": "0FI04",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Godesberger Allee 131",
    "Postcode": 53175,
    "City": "Bonn",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7014070",
    "Longitude": "007.1365860",
    "Telephone Number": "0228376016",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.701407,7.136586",
    "IsOpen24Hours": true,
    "id": "50.701407,7.136586"
  },
  {
    "Gas Station Number": "0FI05",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hengebachstr. 1",
    "Postcode": 52396,
    "City": "Heimbach",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.6353870",
    "Longitude": "006.4741890",
    "Telephone Number": "02446444",
    "Week From": "05:30",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.635387,6.474189",
    "IsOpen24Hours": false,
    "id": "50.635387,6.474189"
  },
  {
    "Gas Station Number": "0FI06",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wittlicher Straße 1",
    "Postcode": 54523,
    "City": "Hetzerath",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.8789670",
    "Longitude": "006.8147090",
    "Telephone Number": "06508918825",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.878967,6.814709",
    "IsOpen24Hours": false,
    "id": "49.878967,6.814709"
  },
  {
    "Gas Station Number": "0FI07",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 144",
    "Postcode": 52156,
    "City": "Monschau",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.5297190",
    "Longitude": "006.2626840",
    "Telephone Number": "024723401",
    "Week From": "07:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.529719,6.262684",
    "IsOpen24Hours": false,
    "id": "50.529719,6.262684"
  },
  {
    "Gas Station Number": "0FI08",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Erftstraße 127",
    "Postcode": 50170,
    "City": "Kerpen-Sindorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9116720",
    "Longitude": "006.6834050",
    "Telephone Number": "02273570436",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.911672,6.683405",
    "IsOpen24Hours": true,
    "id": "50.911672,6.683405"
  },
  {
    "Gas Station Number": "0FI09",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Moselstraße 6",
    "Postcode": 56253,
    "City": "Treis-Karden",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.1854710",
    "Longitude": "007.3052530",
    "Telephone Number": "026722682",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.185471,7.305253",
    "IsOpen24Hours": false,
    "id": "50.185471,7.305253"
  },
  {
    "Gas Station Number": "0FI11",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berg.-Gladbacher Str. 781",
    "Postcode": 51069,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9745590",
    "Longitude": "007.0578470",
    "Telephone Number": "0221681487",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.974559,7.057847",
    "IsOpen24Hours": true,
    "id": "50.974559,7.057847"
  },
  {
    "Gas Station Number": "0FI13",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Olpener Straße 498",
    "Postcode": 51109,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9427450",
    "Longitude": "007.0509810",
    "Telephone Number": "0221895143",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "50.942745,7.050981",
    "IsOpen24Hours": false,
    "id": "50.942745,7.050981"
  },
  {
    "Gas Station Number": "0FI12",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mülheimer Zubringer 140/S",
    "Postcode": 51063,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9771270",
    "Longitude": "007.0070430",
    "Telephone Number": "02219641905",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.977127,7.007043",
    "IsOpen24Hours": true,
    "id": "50.977127,7.007043"
  },
  {
    "Gas Station Number": "0FI14",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Frankfurter Straße 198",
    "Postcode": 51065,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9504850",
    "Longitude": "007.0165650",
    "Telephone Number": "0221693137",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.950485,7.016565",
    "IsOpen24Hours": true,
    "id": "50.950485,7.016565"
  },
  {
    "Gas Station Number": "0FI15",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wingert 43",
    "Postcode": 53894,
    "City": "Mechernich",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.6181080",
    "Longitude": "006.6518670",
    "Telephone Number": "02443911405",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.618108,6.651867",
    "IsOpen24Hours": false,
    "id": "50.618108,6.651867"
  },
  {
    "Gas Station Number": "0FI16",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bonner Straße 6",
    "Postcode": 53340,
    "City": "Meckenheim",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.6262340",
    "Longitude": "007.0203450",
    "Telephone Number": "022255013",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "08:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.626234,7.020345",
    "IsOpen24Hours": false,
    "id": "50.626234,7.020345"
  },
  {
    "Gas Station Number": "0FI17",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heerstraße 51",
    "Postcode": 53474,
    "City": "Bad Neuenahr-Ahr.",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.5481850",
    "Longitude": "007.1389100",
    "Telephone Number": "026411594",
    "Week From": "05:00",
    "Week To": "23:45",
    "Saturdays From": "06:00",
    "Saturdays To": "23:45",
    "Sundays From": "06:00",
    "Sundays To": "23:45",
    "24 hour?": "Nein",
    "location": "50.548185,7.13891",
    "IsOpen24Hours": false,
    "id": "50.548185,7.13891"
  },
  {
    "Gas Station Number": "0FI18",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Im Altwerk 27",
    "Postcode": 52385,
    "City": "Nideggen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.6922280",
    "Longitude": "006.4839950",
    "Telephone Number": "024271309",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.692228,6.483995",
    "IsOpen24Hours": false,
    "id": "50.692228,6.483995"
  },
  {
    "Gas Station Number": "0FI19",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Koblenzer Straße 36",
    "Postcode": 56299,
    "City": "Ochtendung",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.3505250",
    "Longitude": "007.3944500",
    "Telephone Number": "026259592411",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.350525,7.39445",
    "IsOpen24Hours": false,
    "id": "50.350525,7.39445"
  },
  {
    "Gas Station Number": "0FI20",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heerlener Straße 145",
    "Postcode": 52531,
    "City": "Übach-Palenberg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9283360",
    "Longitude": "006.0672470",
    "Telephone Number": "02451909327",
    "Week From": "07:00",
    "Week To": "19:00",
    "Saturdays From": "08:00",
    "Saturdays To": "18:00",
    "Sundays From": "08:00",
    "Sundays To": "18:00",
    "24 hour?": "Nein",
    "location": "50.928336,6.067247",
    "IsOpen24Hours": false,
    "id": "50.928336,6.067247"
  },
  {
    "Gas Station Number": "0FI21",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Auelstraße 27-29",
    "Postcode": 54589,
    "City": "Stadtkyll",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.3536110",
    "Longitude": "006.5241250",
    "Telephone Number": "06597961106",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "06:30",
    "Saturdays To": "20:00",
    "Sundays From": "07:30",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.353611,6.524125",
    "IsOpen24Hours": false,
    "id": "50.353611,6.524125"
  },
  {
    "Gas Station Number": "0FI22",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Brandenburg 1",
    "Postcode": 56856,
    "City": "Zell",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.0170490",
    "Longitude": "007.1804650",
    "Telephone Number": "06542960225",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.017049,7.180465",
    "IsOpen24Hours": true,
    "id": "50.017049,7.180465"
  },
  {
    "Gas Station Number": "0FI23",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Wassertor 17",
    "Postcode": 57439,
    "City": "Attendorn",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1227400",
    "Longitude": "007.9072540",
    "Telephone Number": "027224323",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.12274,7.907254",
    "IsOpen24Hours": true,
    "id": "51.12274,7.907254"
  },
  {
    "Gas Station Number": "0FI24",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 170",
    "Postcode": 56170,
    "City": "Bendorf",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.4268680",
    "Longitude": "007.5716920",
    "Telephone Number": "02622923413",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.426868,7.571692",
    "IsOpen24Hours": false,
    "id": "50.426868,7.571692"
  },
  {
    "Gas Station Number": "0FI25",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kölner Straße 290",
    "Postcode": 51702,
    "City": "Bergneustadt",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0216790",
    "Longitude": "007.6588990",
    "Telephone Number": "01753325167",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.021679,7.658899",
    "IsOpen24Hours": false,
    "id": "51.021679,7.658899"
  },
  {
    "Gas Station Number": "0FI26",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Burscheider Straße 154",
    "Postcode": 51381,
    "City": "Leverkusen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0793710",
    "Longitude": "007.0372600",
    "Telephone Number": "02171734035",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.079371,7.03726",
    "IsOpen24Hours": false,
    "id": "51.079371,7.03726"
  },
  {
    "Gas Station Number": "0FI27",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Großen Garten 7a / B 2",
    "Postcode": 56462,
    "City": "Höhn",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.6191650",
    "Longitude": "007.9906040",
    "Telephone Number": "02661949598",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.619165,7.990604",
    "IsOpen24Hours": false,
    "id": "50.619165,7.990604"
  },
  {
    "Gas Station Number": "0FI28",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hoehestraße 51",
    "Postcode": 51399,
    "City": "Burscheid",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0842050",
    "Longitude": "007.1275360",
    "Telephone Number": "02174789535",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.084205,7.127536",
    "IsOpen24Hours": true,
    "id": "51.084205,7.127536"
  },
  {
    "Gas Station Number": "0FI29",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hagener Straße 67",
    "Postcode": 57489,
    "City": "Drolshagen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0278810",
    "Longitude": "007.7724200",
    "Telephone Number": "02761979408",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.027881,7.77242",
    "IsOpen24Hours": false,
    "id": "51.027881,7.77242"
  },
  {
    "Gas Station Number": "0FI30",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Koblenzer Straße 3",
    "Postcode": 57629,
    "City": "Höchstenbach",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.6337370",
    "Longitude": "007.7460700",
    "Telephone Number": "02680442",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.633737,7.74607",
    "IsOpen24Hours": false,
    "id": "50.633737,7.74607"
  },
  {
    "Gas Station Number": "0FI31",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Römerstraße 221",
    "Postcode": 56075,
    "City": "Koblenz",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.3342870",
    "Longitude": "007.5829170",
    "Telephone Number": "026132520",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.334287,7.582917",
    "IsOpen24Hours": true,
    "id": "50.334287,7.582917"
  },
  {
    "Gas Station Number": "0FI32",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Willy-Brandt-Ring 20",
    "Postcode": 51373,
    "City": "Leverkusen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0229900",
    "Longitude": "007.0036460",
    "Telephone Number": "0214830450",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.02299,7.003646",
    "IsOpen24Hours": true,
    "id": "51.02299,7.003646"
  },
  {
    "Gas Station Number": "0FI33",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Solinger Straße 67",
    "Postcode": 51371,
    "City": "Leverkusen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0560730",
    "Longitude": "006.9538970",
    "Telephone Number": "021421349",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.056073,6.953897",
    "IsOpen24Hours": true,
    "id": "51.056073,6.953897"
  },
  {
    "Gas Station Number": "0FI36",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Koelner Straße 23",
    "Postcode": 51789,
    "City": "Lindlar",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0176270",
    "Longitude": "007.3721770",
    "Telephone Number": "022666075",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.017627,7.372177",
    "IsOpen24Hours": false,
    "id": "51.017627,7.372177"
  },
  {
    "Gas Station Number": "0FI37",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rheinstraße 35",
    "Postcode": 56424,
    "City": "Mogendorf",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.4889430",
    "Longitude": "007.7560600",
    "Telephone Number": "026235662",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "05:00",
    "Saturdays To": "23:00",
    "Sundays From": "07:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "50.488943,7.75606",
    "IsOpen24Hours": false,
    "id": "50.488943,7.75606"
  },
  {
    "Gas Station Number": "0FI38",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gartzenweg 58",
    "Postcode": 40789,
    "City": "Monheim",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0916760",
    "Longitude": "006.8973960",
    "Telephone Number": "0217356997",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "06:30",
    "Saturdays To": "23:59",
    "Sundays From": "07:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.091676,6.897396",
    "IsOpen24Hours": false,
    "id": "51.091676,6.897396"
  },
  {
    "Gas Station Number": "0FI39",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Waldbrölerstraße 61",
    "Postcode": 51597,
    "City": "Morsbach",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8684760",
    "Longitude": "007.7260460",
    "Telephone Number": "022948197",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.868476,7.726046",
    "IsOpen24Hours": false,
    "id": "50.868476,7.726046"
  },
  {
    "Gas Station Number": "0FI40",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Marienfelder Straße 2",
    "Postcode": 53804,
    "City": "Much",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9051900",
    "Longitude": "007.4115840",
    "Telephone Number": "022455580",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.90519,7.411584",
    "IsOpen24Hours": false,
    "id": "50.90519,7.411584"
  },
  {
    "Gas Station Number": "0FI42",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Broelstraße 29",
    "Postcode": 53809,
    "City": "Ruppichteroth",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8477410",
    "Longitude": "007.5006410",
    "Telephone Number": "02295920120",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.847741,7.500641",
    "IsOpen24Hours": false,
    "id": "50.847741,7.500641"
  },
  {
    "Gas Station Number": "0FI43",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Steinbuecheler Straße 48",
    "Postcode": 51377,
    "City": "Leverkusen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0479450",
    "Longitude": "007.0604230",
    "Telephone Number": "02148505923",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.047945,7.060423",
    "IsOpen24Hours": false,
    "id": "51.047945,7.060423"
  },
  {
    "Gas Station Number": "0FI44",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Barmer Straße 70",
    "Postcode": 42899,
    "City": "Remscheid",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2194490",
    "Longitude": "007.2411420",
    "Telephone Number": "02191953342",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.219449,7.241142",
    "IsOpen24Hours": false,
    "id": "51.219449,7.241142"
  },
  {
    "Gas Station Number": "0FI45",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bonner Straße 32-34",
    "Postcode": 53721,
    "City": "Siegburg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7913510",
    "Longitude": "007.2046060",
    "Telephone Number": "0224162409",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.791351,7.204606",
    "IsOpen24Hours": true,
    "id": "50.791351,7.204606"
  },
  {
    "Gas Station Number": "0FI46",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schlagbaumer Straße 66",
    "Postcode": 42653,
    "City": "Solingen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1850830",
    "Longitude": "007.0797380",
    "Telephone Number": "02122541192",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.185083,7.079738",
    "IsOpen24Hours": true,
    "id": "51.185083,7.079738"
  },
  {
    "Gas Station Number": "0FI47",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hohe Straße 1",
    "Postcode": 56244,
    "City": "Steinen",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.5747750",
    "Longitude": "007.8097390",
    "Telephone Number": "02666632",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.574775,7.809739",
    "IsOpen24Hours": false,
    "id": "50.574775,7.809739"
  },
  {
    "Gas Station Number": "0FI49",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Willmenroder Straße 32",
    "Postcode": 56457,
    "City": "Westerburg",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.5566850",
    "Longitude": "007.9730470",
    "Telephone Number": "026634844",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.556685,7.973047",
    "IsOpen24Hours": false,
    "id": "50.556685,7.973047"
  },
  {
    "Gas Station Number": "0FI50",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Siegener Straße 19",
    "Postcode": 51580,
    "City": "Reichshof",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9408170",
    "Longitude": "007.7440510",
    "Telephone Number": "02297359",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.940817,7.744051",
    "IsOpen24Hours": false,
    "id": "50.940817,7.744051"
  },
  {
    "Gas Station Number": "0FI51",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Leiersmühle 5",
    "Postcode": 51688,
    "City": "Wipperfürth",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1218470",
    "Longitude": "007.4103050",
    "Telephone Number": "022671847",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.121847,7.410305",
    "IsOpen24Hours": false,
    "id": "51.121847,7.410305"
  },
  {
    "Gas Station Number": "0FI52",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 89",
    "Postcode": 42799,
    "City": "Leichlingen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1181330",
    "Longitude": "007.1250800",
    "Telephone Number": "0217438058",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:30",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.118133,7.12508",
    "IsOpen24Hours": false,
    "id": "51.118133,7.12508"
  },
  {
    "Gas Station Number": "0FI54",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Leipziger Straße 126",
    "Postcode": 37235,
    "City": "Hessisch Lichtenau",
    "State": "Hessen",
    "Latitude": "051.1991490",
    "Longitude": "009.7268510",
    "Telephone Number": "056022462",
    "Week From": "07:30",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.199149,9.726851",
    "IsOpen24Hours": false,
    "id": "51.199149,9.726851"
  },
  {
    "Gas Station Number": "0FI55",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Fuldatalstraße 1",
    "Postcode": 34125,
    "City": "Kassel",
    "State": "Hessen",
    "Latitude": "051.3235190",
    "Longitude": "009.5134590",
    "Telephone Number": "0561873851",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.323519,9.513459",
    "IsOpen24Hours": true,
    "id": "51.323519,9.513459"
  },
  {
    "Gas Station Number": "0FI56",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Northeimer Straße 31",
    "Postcode": 37186,
    "City": "Moringen",
    "State": "Niedersachsen",
    "Latitude": "051.7031140",
    "Longitude": "009.8750960",
    "Telephone Number": "05554400",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "07:30",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "51.703114,9.875096",
    "IsOpen24Hours": false,
    "id": "51.703114,9.875096"
  },
  {
    "Gas Station Number": "0FI57",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lindenplatz 1",
    "Postcode": 36119,
    "City": "Neuhof",
    "State": "Hessen",
    "Latitude": "050.4547470",
    "Longitude": "009.6141620",
    "Telephone Number": "066552241",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.454747,9.614162",
    "IsOpen24Hours": false,
    "id": "50.454747,9.614162"
  },
  {
    "Gas Station Number": "0FI58",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Niederrheinische Str. 27",
    "Postcode": 34626,
    "City": "Neukirchen",
    "State": "Hessen",
    "Latitude": "050.8719940",
    "Longitude": "009.3371720",
    "Telephone Number": "066947878",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.871994,9.337172",
    "IsOpen24Hours": false,
    "id": "50.871994,9.337172"
  },
  {
    "Gas Station Number": "0FI59",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Marburger Straße 42",
    "Postcode": 35279,
    "City": "Neustadt",
    "State": "Hessen",
    "Latitude": "050.8511630",
    "Longitude": "009.1070860",
    "Telephone Number": "06692919662",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.851163,9.107086",
    "IsOpen24Hours": false,
    "id": "50.851163,9.107086"
  },
  {
    "Gas Station Number": "0FI60",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berliner Straße 2",
    "Postcode": 34286,
    "City": "Spangenberg",
    "State": "Hessen",
    "Latitude": "051.1164390",
    "Longitude": "009.6573530",
    "Telephone Number": "056636310",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "06:00",
    "Saturdays To": "20:00",
    "Sundays From": "07:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "51.116439,9.657353",
    "IsOpen24Hours": false,
    "id": "51.116439,9.657353"
  },
  {
    "Gas Station Number": "0FI61",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Landgrafenstraße 5",
    "Postcode": 34590,
    "City": "Wabern",
    "State": "Hessen",
    "Latitude": "051.1021650",
    "Longitude": "009.3496910",
    "Telephone Number": "05683930315",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.102165,9.349691",
    "IsOpen24Hours": false,
    "id": "51.102165,9.349691"
  },
  {
    "Gas Station Number": "0FI62",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neckarstraße 19",
    "Postcode": 64283,
    "City": "Darmstadt",
    "State": "Hessen",
    "Latitude": "049.8685300",
    "Longitude": "008.6455110",
    "Telephone Number": "06151315733",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.86853,8.645511",
    "IsOpen24Hours": true,
    "id": "49.86853,8.645511"
  },
  {
    "Gas Station Number": "0FI63",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Reuterallee 51",
    "Postcode": 64297,
    "City": "Darmstadt",
    "State": "Hessen",
    "Latitude": "049.8217030",
    "Longitude": "008.6415930",
    "Telephone Number": "0615152527",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.821703,8.641593",
    "IsOpen24Hours": false,
    "id": "49.821703,8.641593"
  },
  {
    "Gas Station Number": "0FI64",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heinrichstraße 241",
    "Postcode": 64287,
    "City": "Darmstadt",
    "State": "Hessen",
    "Latitude": "049.8677490",
    "Longitude": "008.6819540",
    "Telephone Number": "0615147843",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.867749,8.681954",
    "IsOpen24Hours": true,
    "id": "49.867749,8.681954"
  },
  {
    "Gas Station Number": "0FI65",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hanauer Landstraße 34-40",
    "Postcode": 60314,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.1129220",
    "Longitude": "008.6997320",
    "Telephone Number": "069491523",
    "Week From": "06:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "50.112922,8.699732",
    "IsOpen24Hours": false,
    "id": "50.112922,8.699732"
  },
  {
    "Gas Station Number": "0FI66",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Oberer Kalbacher Weg 35",
    "Postcode": 60437,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.1835020",
    "Longitude": "008.6598460",
    "Telephone Number": "06995059127",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.183502,8.659846",
    "IsOpen24Hours": false,
    "id": "50.183502,8.659846"
  },
  {
    "Gas Station Number": "0FI67",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Grueneburgweg 67",
    "Postcode": 60323,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.1219700",
    "Longitude": "008.6690960",
    "Telephone Number": "069723810",
    "Week From": "06:00",
    "Week To": "21:45",
    "Saturdays From": "07:00",
    "Saturdays To": "21:45",
    "Sundays From": "08:00",
    "Sundays To": "21:45",
    "24 hour?": "Nein",
    "location": "50.12197,8.669096",
    "IsOpen24Hours": false,
    "id": "50.12197,8.669096"
  },
  {
    "Gas Station Number": "0FI68",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lauterbacher Straße 68",
    "Postcode": 63688,
    "City": "Gedern",
    "State": "Hessen",
    "Latitude": "050.4291390",
    "Longitude": "009.1967060",
    "Telephone Number": "060451369",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.429139,9.196706",
    "IsOpen24Hours": false,
    "id": "50.429139,9.196706"
  },
  {
    "Gas Station Number": "0FI69",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Waldgirmeser Straße 6",
    "Postcode": 35633,
    "City": "Lahnau",
    "State": "Hessen",
    "Latitude": "050.5781940",
    "Longitude": "008.5643240",
    "Telephone Number": "0644196470",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.578194,8.564324",
    "IsOpen24Hours": false,
    "id": "50.578194,8.564324"
  },
  {
    "Gas Station Number": "0FI70",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lichtenberger Straße 66",
    "Postcode": 64401,
    "City": "Groß-Bieberau",
    "State": "Hessen",
    "Latitude": "049.7917300",
    "Longitude": "008.8213020",
    "Telephone Number": "06162931427",
    "Week From": "05:45",
    "Week To": "20:30",
    "Saturdays From": "07:00",
    "Saturdays To": "20:30",
    "Sundays From": "08:00",
    "Sundays To": "20:30",
    "24 hour?": "Nein",
    "location": "49.79173,8.821302",
    "IsOpen24Hours": false,
    "id": "49.79173,8.821302"
  },
  {
    "Gas Station Number": "0FI71",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Darmstaedter Straße 49A",
    "Postcode": 64846,
    "City": "Groß-Zimmern",
    "State": "Hessen",
    "Latitude": "049.8731310",
    "Longitude": "008.8208140",
    "Telephone Number": "06071951360",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.873131,8.820814",
    "IsOpen24Hours": false,
    "id": "49.873131,8.820814"
  },
  {
    "Gas Station Number": "0FI72",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Frankfurter Straße 12",
    "Postcode": 35625,
    "City": "Hüttenberg",
    "State": "Hessen",
    "Latitude": "050.5218680",
    "Longitude": "008.5723810",
    "Telephone Number": "0644174273",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.521868,8.572381",
    "IsOpen24Hours": false,
    "id": "50.521868,8.572381"
  },
  {
    "Gas Station Number": "0FI73",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Saalburgstraße 114",
    "Postcode": 61350,
    "City": "Bad Homburg",
    "State": "Hessen",
    "Latitude": "050.2369950",
    "Longitude": "008.5930140",
    "Telephone Number": "0617237205",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.236995,8.593014",
    "IsOpen24Hours": true,
    "id": "50.236995,8.593014"
  },
  {
    "Gas Station Number": "0FI74",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Frankfurter Straße 19",
    "Postcode": 63225,
    "City": "Langen",
    "State": "Hessen",
    "Latitude": "049.9919240",
    "Longitude": "008.6805950",
    "Telephone Number": "0610321524",
    "Week From": "06:00",
    "Week To": "23:59",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "07:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "49.991924,8.680595",
    "IsOpen24Hours": false,
    "id": "49.991924,8.680595"
  },
  {
    "Gas Station Number": "0FI75",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Limburger Straße 34",
    "Postcode": 65232,
    "City": "Taunusstein",
    "State": "Hessen",
    "Latitude": "050.1703950",
    "Longitude": "008.2115910",
    "Telephone Number": "0612872462",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:30",
    "Saturdays To": "21:00",
    "Sundays From": "10:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.170395,8.211591",
    "IsOpen24Hours": false,
    "id": "50.170395,8.211591"
  },
  {
    "Gas Station Number": "0FI76",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedhofstraße 11",
    "Postcode": 63263,
    "City": "Neu-Isenburg",
    "State": "Hessen",
    "Latitude": "050.0479760",
    "Longitude": "008.6973370",
    "Telephone Number": "0610239307",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.047976,8.697337",
    "IsOpen24Hours": false,
    "id": "50.047976,8.697337"
  },
  {
    "Gas Station Number": "0FI77",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Limburger Straße 15-19",
    "Postcode": 65611,
    "City": "Brechen",
    "State": "Hessen",
    "Latitude": "050.3615370",
    "Longitude": "008.1705620",
    "Telephone Number": "06438836974",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.361537,8.170562",
    "IsOpen24Hours": false,
    "id": "50.361537,8.170562"
  },
  {
    "Gas Station Number": "0FI78",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Odenwaldstraße 13",
    "Postcode": 64367,
    "City": "Mühltal",
    "State": "Hessen",
    "Latitude": "049.8298680",
    "Longitude": "008.7060720",
    "Telephone Number": "0615114269",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.829868,8.706072",
    "IsOpen24Hours": false,
    "id": "49.829868,8.706072"
  },
  {
    "Gas Station Number": "0FI79",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sodener Straße 29",
    "Postcode": 61476,
    "City": "Kronberg",
    "State": "Hessen",
    "Latitude": "050.1799070",
    "Longitude": "008.5388940",
    "Telephone Number": "0617364545",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.179907,8.538894",
    "IsOpen24Hours": true,
    "id": "50.179907,8.538894"
  },
  {
    "Gas Station Number": "0FI81",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kurt-Schumacher-Ring 138",
    "Postcode": 63303,
    "City": "Dreieich",
    "State": "Hessen",
    "Latitude": "050.0345620",
    "Longitude": "008.6944910",
    "Telephone Number": "06103373437",
    "Week From": "05:30",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "50.034562,8.694491",
    "IsOpen24Hours": false,
    "id": "50.034562,8.694491"
  },
  {
    "Gas Station Number": "0FI82",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bergstraße 4",
    "Postcode": 35578,
    "City": "Wetzlar",
    "State": "Hessen",
    "Latitude": "050.5507640",
    "Longitude": "008.5026490",
    "Telephone Number": "0644122440",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.550764,8.502649",
    "IsOpen24Hours": true,
    "id": "50.550764,8.502649"
  },
  {
    "Gas Station Number": "0FI83",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofsplatz",
    "Postcode": 65189,
    "City": "Wiesbaden",
    "State": "Hessen",
    "Latitude": "050.0714710",
    "Longitude": "008.2455630",
    "Telephone Number": "0611719188",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.071471,8.245563",
    "IsOpen24Hours": true,
    "id": "50.071471,8.245563"
  },
  {
    "Gas Station Number": "0FI86",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Weinstraße 90",
    "Postcode": 67278,
    "City": "Bockenheim",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.6084690",
    "Longitude": "008.1816430",
    "Telephone Number": "063599615906",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.608469,8.181643",
    "IsOpen24Hours": false,
    "id": "49.608469,8.181643"
  },
  {
    "Gas Station Number": "0FI87",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Rathausplatz 22",
    "Postcode": 67125,
    "City": "Dannstadt-Schauernheim",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.4318670",
    "Longitude": "008.3092470",
    "Telephone Number": "062319396510",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.431867,8.309247",
    "IsOpen24Hours": false,
    "id": "49.431867,8.309247"
  },
  {
    "Gas Station Number": "0FI88",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mannheimer Straße 112",
    "Postcode": 67098,
    "City": "Bad Dürkheim",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.4632290",
    "Longitude": "008.1866160",
    "Telephone Number": "06322943317",
    "Week From": "06:00",
    "Week To": "23:59",
    "Saturdays From": "07:00",
    "Saturdays To": "23:59",
    "Sundays From": "08:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "49.463229,8.186616",
    "IsOpen24Hours": false,
    "id": "49.463229,8.186616"
  },
  {
    "Gas Station Number": "0FI89",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Karlsruher Straße 50a",
    "Postcode": 76275,
    "City": "Ettlingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.9573740",
    "Longitude": "008.4066250",
    "Telephone Number": "0724312372",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.957374,8.406625",
    "IsOpen24Hours": true,
    "id": "48.957374,8.406625"
  },
  {
    "Gas Station Number": "0FI90",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mahlastraße 37",
    "Postcode": 67227,
    "City": "Frankenthal",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.5260500",
    "Longitude": "008.3549320",
    "Telephone Number": "06233349208",
    "Week From": "04:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.52605,8.354932",
    "IsOpen24Hours": true,
    "id": "49.52605,8.354932"
  },
  {
    "Gas Station Number": "0FI91",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 6",
    "Postcode": 68766,
    "City": "Hockenheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.3152620",
    "Longitude": "008.5776300",
    "Telephone Number": "0620513513",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.315262,8.57763",
    "IsOpen24Hours": true,
    "id": "49.315262,8.57763"
  },
  {
    "Gas Station Number": "0FI92",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mannheimer Straße 300-302",
    "Postcode": 69123,
    "City": "Heidelberg",
    "State": "Baden-Württemberg",
    "Latitude": "049.4295850",
    "Longitude": "008.6454050",
    "Telephone Number": "06221831614",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.429585,8.645405",
    "IsOpen24Hours": false,
    "id": "49.429585,8.645405"
  },
  {
    "Gas Station Number": "0FI93",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Haid-und-Neu-Straße 60",
    "Postcode": 76131,
    "City": "Karlsruhe",
    "State": "Baden-Württemberg",
    "Latitude": "049.0152080",
    "Longitude": "008.4353650",
    "Telephone Number": "0721615570",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.015208,8.435365",
    "IsOpen24Hours": true,
    "id": "49.015208,8.435365"
  },
  {
    "Gas Station Number": "0FI94",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Siegfriedstraße 64",
    "Postcode": 64385,
    "City": "Reichelsheim",
    "State": "Hessen",
    "Latitude": "049.7224960",
    "Longitude": "008.8681940",
    "Telephone Number": "061641214",
    "Week From": "05:30",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.722496,8.868194",
    "IsOpen24Hours": false,
    "id": "49.722496,8.868194"
  },
  {
    "Gas Station Number": "0FI96",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hechtsheimer Straße 4",
    "Postcode": 55131,
    "City": "Mainz",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.9827900",
    "Longitude": "008.2801890",
    "Telephone Number": "06131985789",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.98279,8.280189",
    "IsOpen24Hours": false,
    "id": "49.98279,8.280189"
  },
  {
    "Gas Station Number": "0FI97",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hafenstraße 19-21",
    "Postcode": 68159,
    "City": "Mannheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.4940560",
    "Longitude": "008.4570630",
    "Telephone Number": "062124207",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.494056,8.457063",
    "IsOpen24Hours": true,
    "id": "49.494056,8.457063"
  },
  {
    "Gas Station Number": "0FI98",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Oppauer Straße 125",
    "Postcode": 67069,
    "City": "Ludwigshafen",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.5340190",
    "Longitude": "008.3888570",
    "Telephone Number": "0621661459",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.534019,8.388857",
    "IsOpen24Hours": true,
    "id": "49.534019,8.388857"
  },
  {
    "Gas Station Number": "0FI99",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heinigstraße 69",
    "Postcode": 67059,
    "City": "Ludwigshafen",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.4815010",
    "Longitude": "008.4393710",
    "Telephone Number": "0621514933",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.481501,8.439371",
    "IsOpen24Hours": true,
    "id": "49.481501,8.439371"
  },
  {
    "Gas Station Number": "0FJ01",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedrich-Ebert-Straße 12",
    "Postcode": 68167,
    "City": "Mannheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.4965830",
    "Longitude": "008.4877060",
    "Telephone Number": "0621332213",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.496583,8.487706",
    "IsOpen24Hours": true,
    "id": "49.496583,8.487706"
  },
  {
    "Gas Station Number": "0FJ02",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Zuzenhaeuser Straße 2",
    "Postcode": 74909,
    "City": "Meckesheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.3198610",
    "Longitude": "008.8143780",
    "Telephone Number": "06226990562",
    "Week From": "04:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "49.319861,8.814378",
    "IsOpen24Hours": false,
    "id": "49.319861,8.814378"
  },
  {
    "Gas Station Number": "0FJ03",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Steubenstraße 48",
    "Postcode": 68163,
    "City": "Mannheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.4607390",
    "Longitude": "008.4781270",
    "Telephone Number": "06218322965",
    "Week From": "06:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Ja",
    "location": "49.460739,8.478127",
    "IsOpen24Hours": true,
    "id": "49.460739,8.478127"
  },
  {
    "Gas Station Number": "0FJ05",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Zellerstraße 34",
    "Postcode": 56288,
    "City": "Kastellaun",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.0688680",
    "Longitude": "007.4393970",
    "Telephone Number": "067625599",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.068868,7.439397",
    "IsOpen24Hours": false,
    "id": "50.068868,7.439397"
  },
  {
    "Gas Station Number": "0FJ06",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Römerstraße 1",
    "Postcode": 69198,
    "City": "Schriesheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.4749390",
    "Longitude": "008.6585180",
    "Telephone Number": "0620361422",
    "Week From": "04:00",
    "Week To": "23:00",
    "Saturdays From": "04:00",
    "Saturdays To": "23:00",
    "Sundays From": "04:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "49.474939,8.658518",
    "IsOpen24Hours": false,
    "id": "49.474939,8.658518"
  },
  {
    "Gas Station Number": "0FJ07",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Landauer Straße 65",
    "Postcode": 67346,
    "City": "Speyer",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.3089350",
    "Longitude": "008.4239430",
    "Telephone Number": "0623275427",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "05:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.308935,8.423943",
    "IsOpen24Hours": true,
    "id": "49.308935,8.423943"
  },
  {
    "Gas Station Number": "0FJ08",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mannheimerstraße 77",
    "Postcode": 69469,
    "City": "Weinheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.5494260",
    "Longitude": "008.6547390",
    "Telephone Number": "0620114295",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.549426,8.654739",
    "IsOpen24Hours": true,
    "id": "49.549426,8.654739"
  },
  {
    "Gas Station Number": "0FJ09",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alzeyer Straße 58",
    "Postcode": 67549,
    "City": "Worms",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.6319110",
    "Longitude": "008.3473080",
    "Telephone Number": "0624158314",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "07:00",
    "Saturdays To": "23:00",
    "Sundays From": "07:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "49.631911,8.347308",
    "IsOpen24Hours": false,
    "id": "49.631911,8.347308"
  },
  {
    "Gas Station Number": "0FJ10",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hochwaldstraße 2",
    "Postcode": 55765,
    "City": "Birkenfeld",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.6507580",
    "Longitude": "007.1574860",
    "Telephone Number": "0678299390",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.650758,7.157486",
    "IsOpen24Hours": false,
    "id": "49.650758,7.157486"
  },
  {
    "Gas Station Number": "0FJ11",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lindenstraße 14",
    "Postcode": 66583,
    "City": "Spiesen-Elversberg",
    "State": "Saarland",
    "Latitude": "049.3176890",
    "Longitude": "007.1224820",
    "Telephone Number": "06821790981",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.317689,7.122482",
    "IsOpen24Hours": true,
    "id": "49.317689,7.122482"
  },
  {
    "Gas Station Number": "0FJ12",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Merziger Straße 106",
    "Postcode": 66763,
    "City": "Dillingen",
    "State": "Saarland",
    "Latitude": "049.3566890",
    "Longitude": "006.7206030",
    "Telephone Number": "0683171417",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.356689,6.720603",
    "IsOpen24Hours": true,
    "id": "49.356689,6.720603"
  },
  {
    "Gas Station Number": "0FJ13",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Im Erfenbacher Tal 15",
    "Postcode": 67661,
    "City": "Kaiserslautern",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.4214750",
    "Longitude": "007.7036520",
    "Telephone Number": "06313503528",
    "Week From": "05:15",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "19:00",
    "Sundays From": "09:30",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.421475,7.703652",
    "IsOpen24Hours": false,
    "id": "49.421475,7.703652"
  },
  {
    "Gas Station Number": "0FJ14",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Primsweiler Straße 9",
    "Postcode": 66839,
    "City": "Schmelz",
    "State": "Saarland",
    "Latitude": "049.4186010",
    "Longitude": "006.8450310",
    "Telephone Number": "068877474",
    "Week From": "08:00",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.418601,6.845031",
    "IsOpen24Hours": false,
    "id": "49.418601,6.845031"
  },
  {
    "Gas Station Number": "0FJ15",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Saarbruecker Straße 100",
    "Postcode": 66424,
    "City": "Homburg",
    "State": "Saarland",
    "Latitude": "049.3186780",
    "Longitude": "007.3278160",
    "Telephone Number": "06841120865",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.318678,7.327816",
    "IsOpen24Hours": true,
    "id": "49.318678,7.327816"
  },
  {
    "Gas Station Number": "0FJ17",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kreisstraße 26",
    "Postcode": 66578,
    "City": "Schiffweiler",
    "State": "Saarland",
    "Latitude": "049.3555930",
    "Longitude": "007.1296240",
    "Telephone Number": "06821963739",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.355593,7.129624",
    "IsOpen24Hours": true,
    "id": "49.355593,7.129624"
  },
  {
    "Gas Station Number": "0FJ18",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 243",
    "Postcode": 66589,
    "City": "Merchweiler",
    "State": "Saarland",
    "Latitude": "049.3510480",
    "Longitude": "007.0508320",
    "Telephone Number": "068255037",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.351048,7.050832",
    "IsOpen24Hours": false,
    "id": "49.351048,7.050832"
  },
  {
    "Gas Station Number": "0FJ19",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mainzer Straße 273",
    "Postcode": 66121,
    "City": "Saarbrücken",
    "State": "Saarland",
    "Latitude": "049.2255000",
    "Longitude": "007.0296550",
    "Telephone Number": "068165358",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.2255,7.029655",
    "IsOpen24Hours": true,
    "id": "49.2255,7.029655"
  },
  {
    "Gas Station Number": "0FJ20",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Metzer Straße 44",
    "Postcode": 66740,
    "City": "Saarlouis",
    "State": "Saarland",
    "Latitude": "049.3054590",
    "Longitude": "006.7400800",
    "Telephone Number": "0683140414",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.305459,6.74008",
    "IsOpen24Hours": true,
    "id": "49.305459,6.74008"
  },
  {
    "Gas Station Number": "0FJ21",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Saarbrücker Straße 6 a",
    "Postcode": 66386,
    "City": "St. Ingbert",
    "State": "Saarland",
    "Latitude": "049.2756110",
    "Longitude": "007.1105750",
    "Telephone Number": "068944468",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.275611,7.110575",
    "IsOpen24Hours": true,
    "id": "49.275611,7.110575"
  },
  {
    "Gas Station Number": "0FJ22",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 2 C",
    "Postcode": 66802,
    "City": "Überherrn",
    "State": "Saarland",
    "Latitude": "049.2486090",
    "Longitude": "006.6971890",
    "Telephone Number": "0683692570",
    "Week From": "05:30",
    "Week To": "23:30",
    "Saturdays From": "05:30",
    "Saturdays To": "23:30",
    "Sundays From": "05:30",
    "Sundays To": "23:30",
    "24 hour?": "Nein",
    "location": "49.248609,6.697189",
    "IsOpen24Hours": false,
    "id": "49.248609,6.697189"
  },
  {
    "Gas Station Number": "0FJ23",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 242",
    "Postcode": 55743,
    "City": "Idar-Oberstein",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.7095120",
    "Longitude": "007.3153560",
    "Telephone Number": "0678125120",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.709512,7.315356",
    "IsOpen24Hours": true,
    "id": "49.709512,7.315356"
  },
  {
    "Gas Station Number": "0FJ24",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Trossinger Straße 80",
    "Postcode": 78554,
    "City": "Aldingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.0917470",
    "Longitude": "008.6904190",
    "Telephone Number": "074241717",
    "Week From": "07:00",
    "Week To": "18:00",
    "Saturdays From": "07:00",
    "Saturdays To": "14:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.091747,8.690419",
    "IsOpen24Hours": false,
    "id": "48.091747,8.690419"
  },
  {
    "Gas Station Number": "0FJ25",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 41",
    "Postcode": 77781,
    "City": "Biberach",
    "State": "Baden-Württemberg",
    "Latitude": "048.3403200",
    "Longitude": "008.0246650",
    "Telephone Number": "078353925",
    "Week From": "07:00",
    "Week To": "19:00",
    "Saturdays From": "08:00",
    "Saturdays To": "18:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.34032,8.024665",
    "IsOpen24Hours": false,
    "id": "48.34032,8.024665"
  },
  {
    "Gas Station Number": "0FJ26",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lenzkircher Straße 24",
    "Postcode": 79848,
    "City": "Bonndorf",
    "State": "Baden-Württemberg",
    "Latitude": "047.8183360",
    "Longitude": "008.3306620",
    "Telephone Number": "0770393780",
    "Week From": "06:00",
    "Week To": "19:00",
    "Saturdays From": "06:00",
    "Saturdays To": "19:00",
    "Sundays From": "08:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "47.818336,8.330662",
    "IsOpen24Hours": false,
    "id": "47.818336,8.330662"
  },
  {
    "Gas Station Number": "0FJ27",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Koenigschaffhauser Straße",
    "Postcode": 79346,
    "City": "Endingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.1435050",
    "Longitude": "007.6953440",
    "Telephone Number": "07642920880",
    "Week From": "07:30",
    "Week To": "19:00",
    "Saturdays From": "07:30",
    "Saturdays To": "18:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.143505,7.695344",
    "IsOpen24Hours": false,
    "id": "48.143505,7.695344"
  },
  {
    "Gas Station Number": "0FJ28",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Adlerstraße 29",
    "Postcode": 77948,
    "City": "Friesenheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.3706850",
    "Longitude": "007.8726070",
    "Telephone Number": "07821997535",
    "Week From": "06:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "07:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "48.370685,7.872607",
    "IsOpen24Hours": false,
    "id": "48.370685,7.872607"
  },
  {
    "Gas Station Number": "0FJ29",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Steinacher Straße 7",
    "Postcode": 77716,
    "City": "Haslach",
    "State": "Baden-Württemberg",
    "Latitude": "048.2777310",
    "Longitude": "008.0842920",
    "Telephone Number": "078322404",
    "Week From": "07:00",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "48.277731,8.084292",
    "IsOpen24Hours": false,
    "id": "48.277731,8.084292"
  },
  {
    "Gas Station Number": "0FJ30",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Talheimer Straße 21",
    "Postcode": 72393,
    "City": "Burladingen - Melchi",
    "State": "Baden-Württemberg",
    "Latitude": "048.3610610",
    "Longitude": "009.1452840",
    "Telephone Number": "071261583",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.361061,9.145284",
    "IsOpen24Hours": false,
    "id": "48.361061,9.145284"
  },
  {
    "Gas Station Number": "0FJ31",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hanauer Straße 64",
    "Postcode": 63739,
    "City": "Aschaffenburg",
    "State": "Bayern",
    "Latitude": "049.9804520",
    "Longitude": "009.1324440",
    "Telephone Number": "0602127874",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.980452,9.132444",
    "IsOpen24Hours": true,
    "id": "49.980452,9.132444"
  },
  {
    "Gas Station Number": "0FJ32",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Luitpoldhain 1 A",
    "Postcode": 96050,
    "City": "Bamberg",
    "State": "Bayern",
    "Latitude": "049.8866650",
    "Longitude": "010.9063910",
    "Telephone Number": "0951130725",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.886665,10.906391",
    "IsOpen24Hours": true,
    "id": "49.886665,10.906391"
  },
  {
    "Gas Station Number": "0FJ33",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der Saale 1a",
    "Postcode": 97717,
    "City": "Euerdorf",
    "State": "Bayern",
    "Latitude": "050.1519240",
    "Longitude": "010.0265640",
    "Telephone Number": "097047580",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "07:30",
    "Saturdays To": "19:00",
    "Sundays From": "09:30",
    "Sundays To": "18:30",
    "24 hour?": "Nein",
    "location": "50.151924,10.026564",
    "IsOpen24Hours": false,
    "id": "50.151924,10.026564"
  },
  {
    "Gas Station Number": "0FJ34",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Erlanger Straße 41",
    "Postcode": 91074,
    "City": "Herzogenaurach",
    "State": "Bayern",
    "Latitude": "049.5669820",
    "Longitude": "010.8928250",
    "Telephone Number": "09132797981",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.566982,10.892825",
    "IsOpen24Hours": false,
    "id": "49.566982,10.892825"
  },
  {
    "Gas Station Number": "0FJ35",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Forststraße 3 - 5",
    "Postcode": 63796,
    "City": "Kahl",
    "State": "Bayern",
    "Latitude": "050.0752660",
    "Longitude": "009.0016230",
    "Telephone Number": "06188900819",
    "Week From": "05:00",
    "Week To": "22:30",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.075266,9.001623",
    "IsOpen24Hours": false,
    "id": "50.075266,9.001623"
  },
  {
    "Gas Station Number": "0FJ36",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kanzleistraße 1",
    "Postcode": 96328,
    "City": "Küps",
    "State": "Bayern",
    "Latitude": "050.2107240",
    "Longitude": "011.2909230",
    "Telephone Number": "092649959073",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.210724,11.290923",
    "IsOpen24Hours": true,
    "id": "50.210724,11.290923"
  },
  {
    "Gas Station Number": "0FJ37",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mergentheimer Straße 126",
    "Postcode": 74653,
    "City": "Künzelsau",
    "State": "Baden-Württemberg",
    "Latitude": "049.2844470",
    "Longitude": "009.6844910",
    "Telephone Number": "07940910821",
    "Week From": "07:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.284447,9.684491",
    "IsOpen24Hours": false,
    "id": "49.284447,9.684491"
  },
  {
    "Gas Station Number": "0FJ38",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kronacher Straße 40",
    "Postcode": 96268,
    "City": "Mitwitz",
    "State": "Bayern",
    "Latitude": "050.2497300",
    "Longitude": "011.2146300",
    "Telephone Number": "09266254",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "18:30",
    "24 hour?": "Nein",
    "location": "50.24973,11.21463",
    "IsOpen24Hours": false,
    "id": "50.24973,11.21463"
  },
  {
    "Gas Station Number": "0FJ39",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 7",
    "Postcode": 69427,
    "City": "Mudau",
    "State": "Baden-Württemberg",
    "Latitude": "049.5299720",
    "Longitude": "009.2047450",
    "Telephone Number": "0628495025",
    "Week From": "07:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.529972,9.204745",
    "IsOpen24Hours": false,
    "id": "49.529972,9.204745"
  },
  {
    "Gas Station Number": "0FJ41",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Würzburger Straße 44",
    "Postcode": 97424,
    "City": "Schweinfurt",
    "State": "Bayern",
    "Latitude": "050.0244440",
    "Longitude": "010.1961020",
    "Telephone Number": "0972181466",
    "Week From": "05:00",
    "Week To": "22:59",
    "Saturdays From": "05:00",
    "Saturdays To": "22:59",
    "Sundays From": "05:00",
    "Sundays To": "22:59",
    "24 hour?": "Nein",
    "location": "50.024444,10.196102",
    "IsOpen24Hours": false,
    "id": "50.024444,10.196102"
  },
  {
    "Gas Station Number": "0FJ42",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wuerzburger Straße 34",
    "Postcode": 97215,
    "City": "Uffenheim",
    "State": "Bayern",
    "Latitude": "049.5466880",
    "Longitude": "010.2270660",
    "Telephone Number": "09842415",
    "Week From": "07:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.546688,10.227066",
    "IsOpen24Hours": false,
    "id": "49.546688,10.227066"
  },
  {
    "Gas Station Number": "0FJ43",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sommeracher Straße 35",
    "Postcode": 97332,
    "City": "Volkach",
    "State": "Bayern",
    "Latitude": "049.8601870",
    "Longitude": "010.2273480",
    "Telephone Number": "09381809616",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.860187,10.227348",
    "IsOpen24Hours": false,
    "id": "49.860187,10.227348"
  },
  {
    "Gas Station Number": "0FJ45",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nuernberger Straße 23",
    "Postcode": 90518,
    "City": "Altdorf",
    "State": "Bayern",
    "Latitude": "049.3844280",
    "Longitude": "011.3488470",
    "Telephone Number": "0918790180",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.384428,11.348847",
    "IsOpen24Hours": false,
    "id": "49.384428,11.348847"
  },
  {
    "Gas Station Number": "0FJ48",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Forther Hauptstraße 2",
    "Postcode": 90542,
    "City": "Eckental",
    "State": "Bayern",
    "Latitude": "049.5892560",
    "Longitude": "011.2170150",
    "Telephone Number": "09126259515",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.589256,11.217015",
    "IsOpen24Hours": false,
    "id": "49.589256,11.217015"
  },
  {
    "Gas Station Number": "0FJ49",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bamberger Straße 91",
    "Postcode": 91301,
    "City": "Forchheim",
    "State": "Bayern",
    "Latitude": "049.7339000",
    "Longitude": "011.0569360",
    "Telephone Number": "09191727212",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.7339,11.056936",
    "IsOpen24Hours": true,
    "id": "49.7339,11.056936"
  },
  {
    "Gas Station Number": "0FJ50",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nuernberger Straße 126",
    "Postcode": 90762,
    "City": "Fürth",
    "State": "Bayern",
    "Latitude": "049.4675630",
    "Longitude": "011.0057290",
    "Telephone Number": "0911706709",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.467563,11.005729",
    "IsOpen24Hours": true,
    "id": "49.467563,11.005729"
  },
  {
    "Gas Station Number": "0FJ52",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ansbacher Straße 34",
    "Postcode": 91586,
    "City": "Lichtenau",
    "State": "Bayern",
    "Latitude": "049.2811710",
    "Longitude": "010.6797450",
    "Telephone Number": "09827250",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "08:00",
    "Saturdays To": "19:00",
    "Sundays From": "09:00",
    "Sundays To": "12:00",
    "24 hour?": "Nein",
    "location": "49.281171,10.679745",
    "IsOpen24Hours": false,
    "id": "49.281171,10.679745"
  },
  {
    "Gas Station Number": "0FJ53",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Münchener Straße 401",
    "Postcode": 90471,
    "City": "Nürnberg",
    "State": "Bayern",
    "Latitude": "049.4018530",
    "Longitude": "011.1154670",
    "Telephone Number": "0911806266",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.401853,11.115467",
    "IsOpen24Hours": true,
    "id": "49.401853,11.115467"
  },
  {
    "Gas Station Number": "0FJ55",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Allersberger Straße 33",
    "Postcode": 91154,
    "City": "Roth",
    "State": "Bayern",
    "Latitude": "049.2497780",
    "Longitude": "011.0955880",
    "Telephone Number": "091712530",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.249778,11.095588",
    "IsOpen24Hours": false,
    "id": "49.249778,11.095588"
  },
  {
    "Gas Station Number": "0FJ56",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schwandorfer Straße 10",
    "Postcode": 93426,
    "City": "Roding",
    "State": "Bayern",
    "Latitude": "049.2058210",
    "Longitude": "012.4863660",
    "Telephone Number": "094611233",
    "Week From": "05:30",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "20:00",
    "Sundays From": "07:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.205821,12.486366",
    "IsOpen24Hours": false,
    "id": "49.205821,12.486366"
  },
  {
    "Gas Station Number": "0FJ59",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Deggendorfer Straße 48",
    "Postcode": 94491,
    "City": "Hengersberg",
    "State": "Bayern",
    "Latitude": "048.7801680",
    "Longitude": "013.0528300",
    "Telephone Number": "0990193470",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "48.780168,13.05283",
    "IsOpen24Hours": false,
    "id": "48.780168,13.05283"
  },
  {
    "Gas Station Number": "0FJ61",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neuburger Straße 141",
    "Postcode": 94036,
    "City": "Passau",
    "State": "Bayern",
    "Latitude": "048.5589930",
    "Longitude": "013.4216070",
    "Telephone Number": "085153174",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.558993,13.421607",
    "IsOpen24Hours": false,
    "id": "48.558993,13.421607"
  },
  {
    "Gas Station Number": "0FJ62",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ruselstraße 37",
    "Postcode": 94209,
    "City": "Regen",
    "State": "Bayern",
    "Latitude": "048.9664080",
    "Longitude": "013.1188740",
    "Telephone Number": "099212431",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.966408,13.118874",
    "IsOpen24Hours": false,
    "id": "48.966408,13.118874"
  },
  {
    "Gas Station Number": "0FJ64",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Muenchner Straße 50",
    "Postcode": 84359,
    "City": "Simbach",
    "State": "Bayern",
    "Latitude": "048.2629760",
    "Longitude": "013.0164490",
    "Telephone Number": "085712006",
    "Week From": "05:30",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:30",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "48.262976,13.016449",
    "IsOpen24Hours": false,
    "id": "48.262976,13.016449"
  },
  {
    "Gas Station Number": "0FJ65",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Landshuter Straße 16",
    "Postcode": 84149,
    "City": "Velden",
    "State": "Bayern",
    "Latitude": "048.3686260",
    "Longitude": "012.2512420",
    "Telephone Number": "08742919288",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.368626,12.251242",
    "IsOpen24Hours": false,
    "id": "48.368626,12.251242"
  },
  {
    "Gas Station Number": "0FJ67",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sauerlacher Straße 64",
    "Postcode": 82515,
    "City": "Wolfratshausen",
    "State": "Bayern",
    "Latitude": "047.9139150",
    "Longitude": "011.4323730",
    "Telephone Number": "0817120793",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.913915,11.432373",
    "IsOpen24Hours": false,
    "id": "47.913915,11.432373"
  },
  {
    "Gas Station Number": "0FJ69",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nymphenburger Straße 205",
    "Postcode": 80639,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1577310",
    "Longitude": "011.5282730",
    "Telephone Number": "089160379",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.157731,11.528273",
    "IsOpen24Hours": false,
    "id": "48.157731,11.528273"
  },
  {
    "Gas Station Number": "0FJ70",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kapuzinerstraße 46",
    "Postcode": 80469,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1238900",
    "Longitude": "011.5648310",
    "Telephone Number": "089761224",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.12389,11.564831",
    "IsOpen24Hours": true,
    "id": "48.12389,11.564831"
  },
  {
    "Gas Station Number": "0FJ72",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Waldwiesenstraße 31",
    "Postcode": 81375,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1190090",
    "Longitude": "011.4782350",
    "Telephone Number": "08974029166",
    "Week From": "05:00",
    "Week To": "23:59",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "48.119009,11.478235",
    "IsOpen24Hours": false,
    "id": "48.119009,11.478235"
  },
  {
    "Gas Station Number": "0FJ73",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berg-am-Laim-Straße 60",
    "Postcode": 81673,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1305090",
    "Longitude": "011.6171730",
    "Telephone Number": "08945409628",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.130509,11.617173",
    "IsOpen24Hours": true,
    "id": "48.130509,11.617173"
  },
  {
    "Gas Station Number": "0FJ74",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Grünwalder Straße 175 C",
    "Postcode": 81545,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.0963810",
    "Longitude": "011.5641140",
    "Telephone Number": "08964270493",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.096381,11.564114",
    "IsOpen24Hours": true,
    "id": "48.096381,11.564114"
  },
  {
    "Gas Station Number": "0FJ77",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Aichacher Straße 26",
    "Postcode": 86316,
    "City": "Friedberg",
    "State": "Bayern",
    "Latitude": "048.3614050",
    "Longitude": "010.9877900",
    "Telephone Number": "0821601885",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.361405,10.98779",
    "IsOpen24Hours": true,
    "id": "48.361405,10.98779"
  },
  {
    "Gas Station Number": "0FJ78",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lachener Straße 56",
    "Postcode": 86911,
    "City": "Dießen",
    "State": "Bayern",
    "Latitude": "047.9579670",
    "Longitude": "011.1021960",
    "Telephone Number": "088078776",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:30",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "47.957967,11.102196",
    "IsOpen24Hours": false,
    "id": "47.957967,11.102196"
  },
  {
    "Gas Station Number": "0FJ79",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Münchener Straße 37",
    "Postcode": 85567,
    "City": "Grafing",
    "State": "Bayern",
    "Latitude": "048.0494020",
    "Longitude": "011.9658360",
    "Telephone Number": "080921822",
    "Week From": "07:00",
    "Week To": "19:00",
    "Saturdays From": "08:00",
    "Saturdays To": "15:00",
    "Sundays From": "10:00",
    "Sundays To": "14:00",
    "24 hour?": "Nein",
    "location": "48.049402,11.965836",
    "IsOpen24Hours": false,
    "id": "48.049402,11.965836"
  },
  {
    "Gas Station Number": "0FJ80",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schondorfer Straße 12",
    "Postcode": 86919,
    "City": "Utting am Ammersee",
    "State": "Bayern",
    "Latitude": "048.0277080",
    "Longitude": "011.0855830",
    "Telephone Number": "088067267",
    "Week From": "07:00",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.027708,11.085583",
    "IsOpen24Hours": false,
    "id": "48.027708,11.085583"
  },
  {
    "Gas Station Number": "0FJ81",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stuttgarter Straße 141",
    "Postcode": 71522,
    "City": "Backnang",
    "State": "Baden-Württemberg",
    "Latitude": "048.9360690",
    "Longitude": "009.4377890",
    "Telephone Number": "0719163813",
    "Week From": "05:00",
    "Week To": "23:59",
    "Saturdays From": "05:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "48.936069,9.437789",
    "IsOpen24Hours": false,
    "id": "48.936069,9.437789"
  },
  {
    "Gas Station Number": "0FJ82",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Cannstatter Straße 46",
    "Postcode": 70190,
    "City": "Stuttgart",
    "State": "Baden-Württemberg",
    "Latitude": "048.7892190",
    "Longitude": "009.1923240",
    "Telephone Number": "07112625648",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.789219,9.192324",
    "IsOpen24Hours": true,
    "id": "48.789219,9.192324"
  },
  {
    "Gas Station Number": "0FJ83",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Deinbacher Straße 5",
    "Postcode": 73527,
    "City": "Schwäbisch Gmünd",
    "State": "Baden-Württemberg",
    "Latitude": "048.8164560",
    "Longitude": "009.7780750",
    "Telephone Number": "0717174516",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.816456,9.778075",
    "IsOpen24Hours": false,
    "id": "48.816456,9.778075"
  },
  {
    "Gas Station Number": "0FJ84",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Remsstraße 10",
    "Postcode": 73525,
    "City": "Schwäbisch Gmünd",
    "State": "Baden-Württemberg",
    "Latitude": "048.8027950",
    "Longitude": "009.7975670",
    "Telephone Number": "0717136288",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.802795,9.797567",
    "IsOpen24Hours": true,
    "id": "48.802795,9.797567"
  },
  {
    "Gas Station Number": "0FJ85",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 4-8",
    "Postcode": 73342,
    "City": "Bad Ditzenbach",
    "State": "Baden-Württemberg",
    "Latitude": "048.5816520",
    "Longitude": "009.6842120",
    "Telephone Number": "073355304",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:30",
    "Saturdays To": "22:00",
    "Sundays From": "07:30",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "48.581652,9.684212",
    "IsOpen24Hours": false,
    "id": "48.581652,9.684212"
  },
  {
    "Gas Station Number": "0FJ86",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Oststraße 72",
    "Postcode": 74072,
    "City": "Heilbronn",
    "State": "Baden-Württemberg",
    "Latitude": "049.1400130",
    "Longitude": "009.2299140",
    "Telephone Number": "07131177475",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.140013,9.229914",
    "IsOpen24Hours": true,
    "id": "49.140013,9.229914"
  },
  {
    "Gas Station Number": "0FJ87",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Theodor Heuss Straße 20",
    "Postcode": 72636,
    "City": "Frickenhausen",
    "State": "Baden-Württemberg",
    "Latitude": "048.5823090",
    "Longitude": "009.3686840",
    "Telephone Number": "070252555",
    "Week From": "06:30",
    "Week To": "21:30",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:30",
    "Sundays To": "21:30",
    "24 hour?": "Nein",
    "location": "48.582309,9.368684",
    "IsOpen24Hours": false,
    "id": "48.582309,9.368684"
  },
  {
    "Gas Station Number": "0FJ88",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heilbronner Straße 2",
    "Postcode": 74535,
    "City": "Mainhardt",
    "State": "Baden-Württemberg",
    "Latitude": "049.0768720",
    "Longitude": "009.5632530",
    "Telephone Number": "079031081",
    "Week From": "07:30",
    "Week To": "19:30",
    "Saturdays From": "08:00",
    "Saturdays To": "18:00",
    "Sundays From": "09:00",
    "Sundays To": "18:00",
    "24 hour?": "Nein",
    "location": "49.076872,9.563253",
    "IsOpen24Hours": false,
    "id": "49.076872,9.563253"
  },
  {
    "Gas Station Number": "0FJ89",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 2",
    "Postcode": 73563,
    "City": "Mögglingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.8233850",
    "Longitude": "009.9578000",
    "Telephone Number": "07174316",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "07:30",
    "Saturdays To": "20:00",
    "Sundays From": "07:30",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "48.823385,9.9578",
    "IsOpen24Hours": false,
    "id": "48.823385,9.9578"
  },
  {
    "Gas Station Number": "0FJ90",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Plieninger Straße 96",
    "Postcode": 70567,
    "City": "Stuttgart",
    "State": "Baden-Württemberg",
    "Latitude": "048.7232020",
    "Longitude": "009.1598870",
    "Telephone Number": "07117285215",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.723202,9.159887",
    "IsOpen24Hours": true,
    "id": "48.723202,9.159887"
  },
  {
    "Gas Station Number": "0FJ91",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wiener Straße 129",
    "Postcode": 70469,
    "City": "Stuttgart",
    "State": "Baden-Württemberg",
    "Latitude": "048.8100960",
    "Longitude": "009.1541950",
    "Telephone Number": "0711852430",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.810096,9.154195",
    "IsOpen24Hours": true,
    "id": "48.810096,9.154195"
  },
  {
    "Gas Station Number": "0FJ92",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Robert-Koch-Straße 64",
    "Postcode": 70563,
    "City": "Stuttgart",
    "State": "Baden-Württemberg",
    "Latitude": "048.7248770",
    "Longitude": "009.1065600",
    "Telephone Number": "07119019518",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.724877,9.10656",
    "IsOpen24Hours": false,
    "id": "48.724877,9.10656"
  },
  {
    "Gas Station Number": "0FJ94",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rollinstraße 2",
    "Postcode": 88400,
    "City": "Biberach",
    "State": "Baden-Württemberg",
    "Latitude": "048.0969170",
    "Longitude": "009.7919010",
    "Telephone Number": "073519320",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.096917,9.791901",
    "IsOpen24Hours": true,
    "id": "48.096917,9.791901"
  },
  {
    "Gas Station Number": "0FJ96",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Messkircher Straße 31",
    "Postcode": 88512,
    "City": "Mengen",
    "State": "Baden-Württemberg",
    "Latitude": "048.0442910",
    "Longitude": "009.3220060",
    "Telephone Number": "075725670",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.044291,9.322006",
    "IsOpen24Hours": false,
    "id": "48.044291,9.322006"
  },
  {
    "Gas Station Number": "0FJ97",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Illerstraße 2",
    "Postcode": 89077,
    "City": "Ulm",
    "State": "Baden-Württemberg",
    "Latitude": "048.3890880",
    "Longitude": "009.9781950",
    "Telephone Number": "073130306",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.389088,9.978195",
    "IsOpen24Hours": true,
    "id": "48.389088,9.978195"
  },
  {
    "Gas Station Number": "0FJ98",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Karlstraße 77",
    "Postcode": 89073,
    "City": "Ulm",
    "State": "Baden-Württemberg",
    "Latitude": "048.4064030",
    "Longitude": "009.9962630",
    "Telephone Number": "073123019",
    "Week From": "06:00",
    "Week To": "23:59",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "06:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "48.406403,9.996263",
    "IsOpen24Hours": false,
    "id": "48.406403,9.996263"
  },
  {
    "Gas Station Number": "0FJ99",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bismarckstraße 2",
    "Postcode": 10625,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5128110",
    "Longitude": "013.3197420",
    "Telephone Number": "03034707002",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.512811,13.319742",
    "IsOpen24Hours": true,
    "id": "52.512811,13.319742"
  },
  {
    "Gas Station Number": "0FK01",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kaiser-Friedrich-Str. 45 B",
    "Postcode": 10627,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5077800",
    "Longitude": "013.3015860",
    "Telephone Number": "03032701495",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.50778,13.301586",
    "IsOpen24Hours": false,
    "id": "52.50778,13.301586"
  },
  {
    "Gas Station Number": "0FK02",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Messedamm 6",
    "Postcode": 14057,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5096030",
    "Longitude": "013.2813250",
    "Telephone Number": "0303025484",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.509603,13.281325",
    "IsOpen24Hours": true,
    "id": "52.509603,13.281325"
  },
  {
    "Gas Station Number": "0FK03",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heerstraße 136",
    "Postcode": 14055,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5070790",
    "Longitude": "013.2277810",
    "Telephone Number": "03030810202",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.507079,13.227781",
    "IsOpen24Hours": true,
    "id": "52.507079,13.227781"
  },
  {
    "Gas Station Number": "0FK04",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Skalitzer Straße 26",
    "Postcode": 10999,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4987030",
    "Longitude": "013.4225380",
    "Telephone Number": "03061702190",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.498703,13.422538",
    "IsOpen24Hours": true,
    "id": "52.498703,13.422538"
  },
  {
    "Gas Station Number": "0FK05",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neukoellner Str. 250-256",
    "Postcode": 12357,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4257890",
    "Longitude": "013.4823540",
    "Telephone Number": "0306613947",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.425789,13.482354",
    "IsOpen24Hours": true,
    "id": "52.425789,13.482354"
  },
  {
    "Gas Station Number": "0FK06",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berliner Straße 67",
    "Postcode": 13507,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5813860",
    "Longitude": "013.2913770",
    "Telephone Number": "03043551049",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.581386,13.291377",
    "IsOpen24Hours": true,
    "id": "52.581386,13.291377"
  },
  {
    "Gas Station Number": "0FK08",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kaiser-Wilhelm-Straße 117",
    "Postcode": 12247,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4398320",
    "Longitude": "013.3512520",
    "Telephone Number": "03076680128",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.439832,13.351252",
    "IsOpen24Hours": true,
    "id": "52.439832,13.351252"
  },
  {
    "Gas Station Number": "0FK09",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Drakestraße 26a",
    "Postcode": 12205,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4408490",
    "Longitude": "013.2994310",
    "Telephone Number": "03084309770",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.440849,13.299431",
    "IsOpen24Hours": true,
    "id": "52.440849,13.299431"
  },
  {
    "Gas Station Number": "0FK10",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dudenstraße 19",
    "Postcode": 10965,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4848930",
    "Longitude": "013.3813660",
    "Telephone Number": "03078913831",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.484893,13.381366",
    "IsOpen24Hours": true,
    "id": "52.484893,13.381366"
  },
  {
    "Gas Station Number": "0FK11",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mariendorfer Damm 341",
    "Postcode": 12107,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4230450",
    "Longitude": "013.3967420",
    "Telephone Number": "0307411920",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.423045,13.396742",
    "IsOpen24Hours": false,
    "id": "52.423045,13.396742"
  },
  {
    "Gas Station Number": "0FK12",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Beusselstraße 55",
    "Postcode": 10553,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5308550",
    "Longitude": "013.3283940",
    "Telephone Number": "0303914404",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.530855,13.328394",
    "IsOpen24Hours": true,
    "id": "52.530855,13.328394"
  },
  {
    "Gas Station Number": "0FK13",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Suedwestkorso 36 A",
    "Postcode": 14197,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4684390",
    "Longitude": "013.3112890",
    "Telephone Number": "0308211291",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.468439,13.311289",
    "IsOpen24Hours": true,
    "id": "52.468439,13.311289"
  },
  {
    "Gas Station Number": "0FK14",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Blissestraße 21",
    "Postcode": 10713,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4843250",
    "Longitude": "013.3206710",
    "Telephone Number": "0308738323",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.484325,13.320671",
    "IsOpen24Hours": true,
    "id": "52.484325,13.320671"
  },
  {
    "Gas Station Number": "0FK15",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hohenzollerndamm 97",
    "Postcode": 14199,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4758870",
    "Longitude": "013.2791620",
    "Telephone Number": "0308256179",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.475887,13.279162",
    "IsOpen24Hours": true,
    "id": "52.475887,13.279162"
  },
  {
    "Gas Station Number": "0FK16",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Westfaelische Straße 9-10",
    "Postcode": 10709,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4925130",
    "Longitude": "013.3092850",
    "Telephone Number": "03086409379",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.492513,13.309285",
    "IsOpen24Hours": true,
    "id": "52.492513,13.309285"
  },
  {
    "Gas Station Number": "0FK17",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kurfuerstendamm 128",
    "Postcode": 10711,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4958040",
    "Longitude": "013.2876050",
    "Telephone Number": "03089096972",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.495804,13.287605",
    "IsOpen24Hours": true,
    "id": "52.495804,13.287605"
  },
  {
    "Gas Station Number": "0FK18",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Königstraße 58",
    "Postcode": 14109,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4192100",
    "Longitude": "013.1585420",
    "Telephone Number": "0308051018",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.41921,13.158542",
    "IsOpen24Hours": true,
    "id": "52.41921,13.158542"
  },
  {
    "Gas Station Number": "0FK19",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Angermuehle 8",
    "Postcode": 94469,
    "City": "Deggendorf",
    "State": "Bayern",
    "Latitude": "048.8351130",
    "Longitude": "012.9589760",
    "Telephone Number": "099138300112",
    "Week From": "05:30",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.835113,12.958976",
    "IsOpen24Hours": false,
    "id": "48.835113,12.958976"
  },
  {
    "Gas Station Number": "0FK20",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Konrad-Adenauer-Damm 100",
    "Postcode": 24143,
    "City": "Kiel",
    "State": "Schleswig-Holstein",
    "Latitude": "054.2969630",
    "Longitude": "010.1648910",
    "Telephone Number": "0431782778",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.296963,10.164891",
    "IsOpen24Hours": true,
    "id": "54.296963,10.164891"
  },
  {
    "Gas Station Number": "0FK22",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Weisendorfer Straße 16",
    "Postcode": 91056,
    "City": "Erlangen",
    "State": "Bayern",
    "Latitude": "049.6256820",
    "Longitude": "010.9406430",
    "Telephone Number": "09135729895",
    "Week From": "04:00",
    "Week To": "22:00",
    "Saturdays From": "04:00",
    "Saturdays To": "22:00",
    "Sundays From": "04:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.625682,10.940643",
    "IsOpen24Hours": false,
    "id": "49.625682,10.940643"
  },
  {
    "Gas Station Number": "0FK24",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Erlanger Straße 40",
    "Postcode": 90425,
    "City": "Nürnberg",
    "State": "Bayern",
    "Latitude": "049.4736850",
    "Longitude": "011.0648630",
    "Telephone Number": "0911341045",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.473685,11.064863",
    "IsOpen24Hours": true,
    "id": "49.473685,11.064863"
  },
  {
    "Gas Station Number": "0FK26",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schiffbeker Weg 228",
    "Postcode": 22119,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5619800",
    "Longitude": "010.1197750",
    "Telephone Number": "0406532765",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.56198,10.119775",
    "IsOpen24Hours": true,
    "id": "53.56198,10.119775"
  },
  {
    "Gas Station Number": "0FK27",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gewerkenstraße 63",
    "Postcode": 45881,
    "City": "Gelsenkirchen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5196560",
    "Longitude": "007.0818890",
    "Telephone Number": "02094082034",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.519656,7.081889",
    "IsOpen24Hours": true,
    "id": "51.519656,7.081889"
  },
  {
    "Gas Station Number": "0FK29",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Buschkrugallee 84-92",
    "Postcode": 12359,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4558910",
    "Longitude": "013.4496410",
    "Telephone Number": "0306061011",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.455891,13.449641",
    "IsOpen24Hours": false,
    "id": "52.455891,13.449641"
  },
  {
    "Gas Station Number": "0FK30",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 32",
    "Postcode": 58762,
    "City": "Altena",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3023460",
    "Longitude": "007.6670490",
    "Telephone Number": "0235225355",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.302346,7.667049",
    "IsOpen24Hours": true,
    "id": "51.302346,7.667049"
  },
  {
    "Gas Station Number": "0FK31",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Regensburger Straße 30",
    "Postcode": 85088,
    "City": "Vohburg",
    "State": "Bayern",
    "Latitude": "048.7687660",
    "Longitude": "011.6234620",
    "Telephone Number": "0845792960",
    "Week From": "07:00",
    "Week To": "18:30",
    "Saturdays From": "07:00",
    "Saturdays To": "13:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.768766,11.623462",
    "IsOpen24Hours": false,
    "id": "48.768766,11.623462"
  },
  {
    "Gas Station Number": "0FK32",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schlossstraße 63-64",
    "Postcode": 12165,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4530790",
    "Longitude": "013.3150760",
    "Telephone Number": "0308344870",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.453079,13.315076",
    "IsOpen24Hours": true,
    "id": "52.453079,13.315076"
  },
  {
    "Gas Station Number": "0FK33",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kölner Straße 356",
    "Postcode": 40227,
    "City": "Düsseldorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2095360",
    "Longitude": "006.8100530",
    "Telephone Number": "021199614011",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.209536,6.810053",
    "IsOpen24Hours": true,
    "id": "51.209536,6.810053"
  },
  {
    "Gas Station Number": "0FK34",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stresemannstraße 310",
    "Postcode": 27580,
    "City": "Bremerhaven",
    "State": "Bremen",
    "Latitude": "053.5705730",
    "Longitude": "008.5988230",
    "Telephone Number": "047185150",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.570573,8.598823",
    "IsOpen24Hours": true,
    "id": "53.570573,8.598823"
  },
  {
    "Gas Station Number": "0FK35",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Vogelsbergstraße 25",
    "Postcode": 63679,
    "City": "Schotten",
    "State": "Hessen",
    "Latitude": "050.4955860",
    "Longitude": "009.1133830",
    "Telephone Number": "060441524",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "23:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.495586,9.113383",
    "IsOpen24Hours": false,
    "id": "50.495586,9.113383"
  },
  {
    "Gas Station Number": "0FK36",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 2",
    "Postcode": 31275,
    "City": "Lehrte",
    "State": "Niedersachsen",
    "Latitude": "052.3882970",
    "Longitude": "009.9978080",
    "Telephone Number": "051324888",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.388297,9.997808",
    "IsOpen24Hours": true,
    "id": "52.388297,9.997808"
  },
  {
    "Gas Station Number": "0FK37",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lagesche Straße 60-64",
    "Postcode": 32657,
    "City": "Lemgo",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.0225350",
    "Longitude": "008.8858770",
    "Telephone Number": "0526117044",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "52.022535,8.885877",
    "IsOpen24Hours": false,
    "id": "52.022535,8.885877"
  },
  {
    "Gas Station Number": "0FK39",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mergentheimer Straße 31",
    "Postcode": 97084,
    "City": "Würzburg",
    "State": "Bayern",
    "Latitude": "049.7681260",
    "Longitude": "009.9404640",
    "Telephone Number": "0931611213",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.768126,9.940464",
    "IsOpen24Hours": true,
    "id": "49.768126,9.940464"
  },
  {
    "Gas Station Number": "0FK40",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Westend 52",
    "Postcode": 46399,
    "City": "Bocholt",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.8386270",
    "Longitude": "006.6013420",
    "Telephone Number": "02871233642",
    "Week From": "05:00",
    "Week To": "23:30",
    "Saturdays From": "06:00",
    "Saturdays To": "23:30",
    "Sundays From": "07:00",
    "Sundays To": "23:30",
    "24 hour?": "Nein",
    "location": "51.838627,6.601342",
    "IsOpen24Hours": false,
    "id": "51.838627,6.601342"
  },
  {
    "Gas Station Number": "0FK41",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wittenduener Allee 11",
    "Postcode": 25826,
    "City": "St Peter-Ording",
    "State": "Schleswig-Holstein",
    "Latitude": "054.3022440",
    "Longitude": "008.6511250",
    "Telephone Number": "048633007",
    "Week From": "07:00",
    "Week To": "19:00",
    "Saturdays From": "08:00",
    "Saturdays To": "19:00",
    "Sundays From": "09:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "54.302244,8.651125",
    "IsOpen24Hours": false,
    "id": "54.302244,8.651125"
  },
  {
    "Gas Station Number": "0FK42",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnstraße 131",
    "Postcode": 46147,
    "City": "Oberhausen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5295830",
    "Longitude": "006.7979490",
    "Telephone Number": "0208680549",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "10:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.529583,6.797949",
    "IsOpen24Hours": false,
    "id": "51.529583,6.797949"
  },
  {
    "Gas Station Number": "0FK43",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Weismainer Straße 58",
    "Postcode": 96264,
    "City": "Altenkunstadt",
    "State": "Bayern",
    "Latitude": "050.1279960",
    "Longitude": "011.2397430",
    "Telephone Number": "095721627",
    "Week From": "06:30",
    "Week To": "22:00",
    "Saturdays From": "06:30",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.127996,11.239743",
    "IsOpen24Hours": false,
    "id": "50.127996,11.239743"
  },
  {
    "Gas Station Number": "0FK44",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Curslacker Neuer Deich 34",
    "Postcode": 21029,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.4817750",
    "Longitude": "010.2081220",
    "Telephone Number": "04072698676",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.481775,10.208122",
    "IsOpen24Hours": true,
    "id": "53.481775,10.208122"
  },
  {
    "Gas Station Number": "0FK45",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Charlottenburger Straße 9",
    "Postcode": 37115,
    "City": "Duderstadt",
    "State": "Niedersachsen",
    "Latitude": "051.5163670",
    "Longitude": "010.2429810",
    "Telephone Number": "05527941535",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "06:30",
    "Saturdays To": "22:00",
    "Sundays From": "06:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.516367,10.242981",
    "IsOpen24Hours": false,
    "id": "51.516367,10.242981"
  },
  {
    "Gas Station Number": "0FK46",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wilhelmstraße 24",
    "Postcode": 42489,
    "City": "Wülfrath",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2853290",
    "Longitude": "007.0474940",
    "Telephone Number": "0205874220",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.285329,7.047494",
    "IsOpen24Hours": true,
    "id": "51.285329,7.047494"
  },
  {
    "Gas Station Number": "0FK47",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Regentalstraße 10",
    "Postcode": 93474,
    "City": "Arrach",
    "State": "Bayern",
    "Latitude": "049.1966110",
    "Longitude": "013.0080350",
    "Telephone Number": "099433657",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "20:00",
    "Sundays From": "07:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.196611,13.008035",
    "IsOpen24Hours": false,
    "id": "49.196611,13.008035"
  },
  {
    "Gas Station Number": "0FK48",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Erdinger Straße 25",
    "Postcode": 85570,
    "City": "Markt Schwaben",
    "State": "Bayern",
    "Latitude": "048.1932740",
    "Longitude": "011.8722680",
    "Telephone Number": "081216010",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.193274,11.872268",
    "IsOpen24Hours": false,
    "id": "48.193274,11.872268"
  },
  {
    "Gas Station Number": "0FK49",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Frankfurter Straße 323",
    "Postcode": 40595,
    "City": "Düsseldorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1380050",
    "Longitude": "006.9049630",
    "Telephone Number": "02117053523",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.138005,6.904963",
    "IsOpen24Hours": true,
    "id": "51.138005,6.904963"
  },
  {
    "Gas Station Number": "0FK50",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Donatusstraße 20",
    "Postcode": 50767,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0034040",
    "Longitude": "006.8750870",
    "Telephone Number": "02219591032",
    "Week From": "06:00",
    "Week To": "23:59",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "08:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "51.003404,6.875087",
    "IsOpen24Hours": false,
    "id": "51.003404,6.875087"
  },
  {
    "Gas Station Number": "0FK52",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "In der Schwärzgrub 20",
    "Postcode": 55774,
    "City": "Baumholder",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.6093300",
    "Longitude": "007.3287580",
    "Telephone Number": "067837701",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.60933,7.328758",
    "IsOpen24Hours": false,
    "id": "49.60933,7.328758"
  },
  {
    "Gas Station Number": "0FK53",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Brühler Straße 160",
    "Postcode": 50389,
    "City": "Wesseling",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8308950",
    "Longitude": "006.9565590",
    "Telephone Number": "02232942668",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.830895,6.956559",
    "IsOpen24Hours": true,
    "id": "50.830895,6.956559"
  },
  {
    "Gas Station Number": "0FK54",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schönberger Straße 7-16",
    "Postcode": 74405,
    "City": "Gaildorf",
    "State": "Baden-Württemberg",
    "Latitude": "048.9793460",
    "Longitude": "009.7793300",
    "Telephone Number": "079716005",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:30",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.979346,9.77933",
    "IsOpen24Hours": false,
    "id": "48.979346,9.77933"
  },
  {
    "Gas Station Number": "0FK55",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Krempelsdorfer Allee 63",
    "Postcode": 23556,
    "City": "Lübeck",
    "State": "Schleswig-Holstein",
    "Latitude": "053.8844750",
    "Longitude": "010.6538040",
    "Telephone Number": "0451492396",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.884475,10.653804",
    "IsOpen24Hours": true,
    "id": "53.884475,10.653804"
  },
  {
    "Gas Station Number": "0FK56",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kölner Straße 255",
    "Postcode": 51149,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9035920",
    "Longitude": "007.0207290",
    "Telephone Number": "0220317951",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.903592,7.020729",
    "IsOpen24Hours": true,
    "id": "50.903592,7.020729"
  },
  {
    "Gas Station Number": "0FK57",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Untermühlaustraße 50",
    "Postcode": 68169,
    "City": "Mannheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.5061780",
    "Longitude": "008.4639670",
    "Telephone Number": "0621316970",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.506178,8.463967",
    "IsOpen24Hours": true,
    "id": "49.506178,8.463967"
  },
  {
    "Gas Station Number": "0FK58",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Holsatenring 38",
    "Postcode": 24539,
    "City": "Neumünster",
    "State": "Schleswig-Holstein",
    "Latitude": "054.0633870",
    "Longitude": "009.9837430",
    "Telephone Number": "0432141297",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.063387,9.983743",
    "IsOpen24Hours": true,
    "id": "54.063387,9.983743"
  },
  {
    "Gas Station Number": "0FK60",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mühlheimer Straße111-115",
    "Postcode": 63075,
    "City": "Offenbach",
    "State": "Hessen",
    "Latitude": "050.1055890",
    "Longitude": "008.7857810",
    "Telephone Number": "06986781191",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.105589,8.785781",
    "IsOpen24Hours": true,
    "id": "50.105589,8.785781"
  },
  {
    "Gas Station Number": "0FK61",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hagenburger Straße 8",
    "Postcode": 31515,
    "City": "Wunstorf",
    "State": "Niedersachsen",
    "Latitude": "052.4295900",
    "Longitude": "009.4230010",
    "Telephone Number": "05031913719",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.42959,9.423001",
    "IsOpen24Hours": true,
    "id": "52.42959,9.423001"
  },
  {
    "Gas Station Number": "0FK62",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stader Straße 294-296",
    "Postcode": 21075,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.4708720",
    "Longitude": "009.9237330",
    "Telephone Number": "0407928704",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.470872,9.923733",
    "IsOpen24Hours": true,
    "id": "53.470872,9.923733"
  },
  {
    "Gas Station Number": "0FK63",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Werler Straße 30",
    "Postcode": 32105,
    "City": "Bad Salzuflen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.0843860",
    "Longitude": "008.7364960",
    "Telephone Number": "052224410",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.084386,8.736496",
    "IsOpen24Hours": false,
    "id": "52.084386,8.736496"
  },
  {
    "Gas Station Number": "0FK64",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schollstraße 2",
    "Postcode": 85055,
    "City": "Ingolstadt",
    "State": "Bayern",
    "Latitude": "048.7752510",
    "Longitude": "011.4593520",
    "Telephone Number": "08412294",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.775251,11.459352",
    "IsOpen24Hours": true,
    "id": "48.775251,11.459352"
  },
  {
    "Gas Station Number": "0FK65",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dekkers Waide 1",
    "Postcode": 46419,
    "City": "Isselburg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.8183410",
    "Longitude": "006.4426880",
    "Telephone Number": "0287495672",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.818341,6.442688",
    "IsOpen24Hours": true,
    "id": "51.818341,6.442688"
  },
  {
    "Gas Station Number": "0FK67",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 32",
    "Postcode": 24217,
    "City": "Schönberg",
    "State": "Schleswig-Holstein",
    "Latitude": "054.3887880",
    "Longitude": "010.3731990",
    "Telephone Number": "043444107075",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "06:30",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "54.388788,10.373199",
    "IsOpen24Hours": false,
    "id": "54.388788,10.373199"
  },
  {
    "Gas Station Number": "0FK66",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kurt-Schumacher-Straße155",
    "Postcode": 45881,
    "City": "Gelsenkirchen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5328840",
    "Longitude": "007.0775370",
    "Telephone Number": "02099443052",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.532884,7.077537",
    "IsOpen24Hours": true,
    "id": "51.532884,7.077537"
  },
  {
    "Gas Station Number": "0FK68",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ploener Landstraße 7",
    "Postcode": 23701,
    "City": "Eutin",
    "State": "Schleswig-Holstein",
    "Latitude": "054.1289600",
    "Longitude": "010.5800200",
    "Telephone Number": "045214016302",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "54.12896,10.58002",
    "IsOpen24Hours": false,
    "id": "54.12896,10.58002"
  },
  {
    "Gas Station Number": "0FK69",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Victoriastraße 174",
    "Postcode": 45772,
    "City": "Marl",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6656410",
    "Longitude": "007.1506520",
    "Telephone Number": "02365415204",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.665641,7.150652",
    "IsOpen24Hours": true,
    "id": "51.665641,7.150652"
  },
  {
    "Gas Station Number": "0FK70",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alte Bundesstraße 25",
    "Postcode": 71332,
    "City": "Waiblingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.8248630",
    "Longitude": "009.3197700",
    "Telephone Number": "07151905014",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "48.824863,9.31977",
    "IsOpen24Hours": false,
    "id": "48.824863,9.31977"
  },
  {
    "Gas Station Number": "0FK71",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nikolaus-Otto-Straße 7-9",
    "Postcode": 65582,
    "City": "Diez",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.3723920",
    "Longitude": "008.0360920",
    "Telephone Number": "06432910915",
    "Week From": "05:00",
    "Week To": "23:59",
    "Saturdays From": "07:00",
    "Saturdays To": "23:59",
    "Sundays From": "08:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "50.372392,8.036092",
    "IsOpen24Hours": false,
    "id": "50.372392,8.036092"
  },
  {
    "Gas Station Number": "0FK73",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neuwieder Straße 38-40",
    "Postcode": 56269,
    "City": "Dierdorf",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.5475500",
    "Longitude": "007.6474190",
    "Telephone Number": "026892038",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.54755,7.647419",
    "IsOpen24Hours": true,
    "id": "50.54755,7.647419"
  },
  {
    "Gas Station Number": "0FK74",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Otto-Renner-Straße 1",
    "Postcode": 89231,
    "City": "Neu-Ulm",
    "State": "Bayern",
    "Latitude": "048.3864720",
    "Longitude": "010.0357430",
    "Telephone Number": "07317253429",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.386472,10.035743",
    "IsOpen24Hours": true,
    "id": "48.386472,10.035743"
  },
  {
    "Gas Station Number": "0FK75",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hoehenweg 23-25",
    "Postcode": 46147,
    "City": "Oberhausen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5483810",
    "Longitude": "006.8511530",
    "Telephone Number": "0208627088",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.548381,6.851153",
    "IsOpen24Hours": true,
    "id": "51.548381,6.851153"
  },
  {
    "Gas Station Number": "0FK76",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Forstmeisterstraße 53",
    "Postcode": 97705,
    "City": "Burkardroth",
    "State": "Bayern",
    "Latitude": "050.2631280",
    "Longitude": "009.9943780",
    "Telephone Number": "097349293",
    "Week From": "06:30",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.263128,9.994378",
    "IsOpen24Hours": false,
    "id": "50.263128,9.994378"
  },
  {
    "Gas Station Number": "0FK77",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schorndorfer Straße 49",
    "Postcode": 73660,
    "City": "Urbach",
    "State": "Baden-Württemberg",
    "Latitude": "048.8091260",
    "Longitude": "009.5705300",
    "Telephone Number": "07181981808",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.809126,9.57053",
    "IsOpen24Hours": true,
    "id": "48.809126,9.57053"
  },
  {
    "Gas Station Number": "0FK78",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alleestraße 18",
    "Postcode": 56410,
    "City": "Montabaur",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.4401100",
    "Longitude": "007.8303850",
    "Telephone Number": "02602917369",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.44011,7.830385",
    "IsOpen24Hours": true,
    "id": "50.44011,7.830385"
  },
  {
    "Gas Station Number": "0FK79",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Augarten 1",
    "Postcode": 35435,
    "City": "Wettenberg",
    "State": "Hessen",
    "Latitude": "050.6113910",
    "Longitude": "008.6481710",
    "Telephone Number": "06419805045",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.611391,8.648171",
    "IsOpen24Hours": true,
    "id": "50.611391,8.648171"
  },
  {
    "Gas Station Number": "0FK80",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Fischbacher Straße 1",
    "Postcode": 55743,
    "City": "Idar-Oberstein",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.7389780",
    "Longitude": "007.4080380",
    "Telephone Number": "0678499800",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.738978,7.408038",
    "IsOpen24Hours": true,
    "id": "49.738978,7.408038"
  },
  {
    "Gas Station Number": "0FK81",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Osnabruecker Straße 36 A",
    "Postcode": 49205,
    "City": "Hasbergen",
    "State": "Niedersachsen",
    "Latitude": "052.2368500",
    "Longitude": "007.9628400",
    "Telephone Number": "054053525",
    "Week From": "07:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "52.23685,7.96284",
    "IsOpen24Hours": false,
    "id": "52.23685,7.96284"
  },
  {
    "Gas Station Number": "0FK82",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Waldstraße 48-56",
    "Postcode": 68305,
    "City": "Mannheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.5282290",
    "Longitude": "008.4899840",
    "Telephone Number": "0621751361",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.528229,8.489984",
    "IsOpen24Hours": false,
    "id": "49.528229,8.489984"
  },
  {
    "Gas Station Number": "0FK83",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rue de Wattrelos 11",
    "Postcode": 52249,
    "City": "Eschweiler",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8253200",
    "Longitude": "006.2471520",
    "Telephone Number": "02403830005",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.82532,6.247152",
    "IsOpen24Hours": true,
    "id": "50.82532,6.247152"
  },
  {
    "Gas Station Number": "0FK84",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Barkhausenstraße 50",
    "Postcode": 27568,
    "City": "Bremerhaven",
    "State": "Bremen",
    "Latitude": "053.5518780",
    "Longitude": "008.5684980",
    "Telephone Number": "04719413835",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.551878,8.568498",
    "IsOpen24Hours": true,
    "id": "53.551878,8.568498"
  },
  {
    "Gas Station Number": "0FK85",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kappeler Straße 50",
    "Postcode": 55481,
    "City": "Kirchberg",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.9504770",
    "Longitude": "007.4040950",
    "Telephone Number": "06763960706",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.950477,7.404095",
    "IsOpen24Hours": false,
    "id": "49.950477,7.404095"
  },
  {
    "Gas Station Number": "0FK86",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Industriestraße 43",
    "Postcode": 47652,
    "City": "Weeze",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6201770",
    "Longitude": "006.2099680",
    "Telephone Number": "02837962551",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.620177,6.209968",
    "IsOpen24Hours": true,
    "id": "51.620177,6.209968"
  },
  {
    "Gas Station Number": "0FK87",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Industriestraße 1",
    "Postcode": 64546,
    "City": "Mörfelden",
    "State": "Hessen",
    "Latitude": "049.9790760",
    "Longitude": "008.5815390",
    "Telephone Number": "06105921766",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.979076,8.581539",
    "IsOpen24Hours": true,
    "id": "49.979076,8.581539"
  },
  {
    "Gas Station Number": "0FK89",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Koblenzer Straße 237",
    "Postcode": 56154,
    "City": "Boppard",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.2367400",
    "Longitude": "007.5770540",
    "Telephone Number": "067422447",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.23674,7.577054",
    "IsOpen24Hours": false,
    "id": "50.23674,7.577054"
  },
  {
    "Gas Station Number": "0FK90",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Boeblinger Straße 69",
    "Postcode": 71088,
    "City": "Holzgerlingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.6456360",
    "Longitude": "009.0097760",
    "Telephone Number": "07031605288",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "05:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "48.645636,9.009776",
    "IsOpen24Hours": false,
    "id": "48.645636,9.009776"
  },
  {
    "Gas Station Number": "0FK91",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kirchmeierstraße 20",
    "Postcode": 93051,
    "City": "Regensburg",
    "State": "Bayern",
    "Latitude": "049.0110240",
    "Longitude": "012.0712350",
    "Telephone Number": "09413998303",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.011024,12.071235",
    "IsOpen24Hours": true,
    "id": "49.011024,12.071235"
  },
  {
    "Gas Station Number": "0FK92",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Baerenbruch 135",
    "Postcode": 44379,
    "City": "Dortmund",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5197440",
    "Longitude": "007.3725630",
    "Telephone Number": "02316102302",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.519744,7.372563",
    "IsOpen24Hours": true,
    "id": "51.519744,7.372563"
  },
  {
    "Gas Station Number": "0FK93",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schöneberger Str. / Alboi",
    "Postcode": 12103,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4700260",
    "Longitude": "013.3710430",
    "Telephone Number": "03075446987",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.470026,13.371043",
    "IsOpen24Hours": true,
    "id": "52.470026,13.371043"
  },
  {
    "Gas Station Number": "0FK94",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Garmischer Straße 138",
    "Postcode": 80686,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1271500",
    "Longitude": "011.5222330",
    "Telephone Number": "08957868298",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.12715,11.522233",
    "IsOpen24Hours": true,
    "id": "48.12715,11.522233"
  },
  {
    "Gas Station Number": "0FM09",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Obernburger Straße127-135",
    "Postcode": 63811,
    "City": "Stockstadt",
    "State": "Bayern",
    "Latitude": "049.9651950",
    "Longitude": "009.0751680",
    "Telephone Number": "06027401675",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.965195,9.075168",
    "IsOpen24Hours": false,
    "id": "49.965195,9.075168"
  },
  {
    "Gas Station Number": "0FK95",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rübhofstraße 27",
    "Postcode": 27711,
    "City": "Osterholz-Scharmbeck",
    "State": "Niedersachsen",
    "Latitude": "053.2280020",
    "Longitude": "008.8110510",
    "Telephone Number": "04791982119",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "53.228002,8.811051",
    "IsOpen24Hours": false,
    "id": "53.228002,8.811051"
  },
  {
    "Gas Station Number": "0FK98",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Radeburger Straße/Hellerhof",
    "Postcode": 1129,
    "City": "Dresden",
    "State": "Sachsen",
    "Latitude": "051.0945320",
    "Longitude": "013.7361350",
    "Telephone Number": "03518498840",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.094532,13.736135",
    "IsOpen24Hours": true,
    "id": "51.094532,13.736135"
  },
  {
    "Gas Station Number": "0FK99",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "BAB24 Nordseite",
    "Postcode": 16833,
    "City": "Linum",
    "State": "Brandenburg",
    "Latitude": "052.7545410",
    "Longitude": "012.8553900",
    "Telephone Number": "03392250538",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.754541,12.85539",
    "IsOpen24Hours": true,
    "id": "52.754541,12.85539"
  },
  {
    "Gas Station Number": "0FL01",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berliner Straße 197",
    "Postcode": 16515,
    "City": "Oranienburg",
    "State": "Brandenburg",
    "Latitude": "052.7283380",
    "Longitude": "013.2462810",
    "Telephone Number": "03301530720",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.728338,13.246281",
    "IsOpen24Hours": false,
    "id": "52.728338,13.246281"
  },
  {
    "Gas Station Number": "0FL02",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Leinastraße 80",
    "Postcode": 99867,
    "City": "Gotha",
    "State": "Thüringen",
    "Latitude": "050.9341200",
    "Longitude": "010.6760270",
    "Telephone Number": "03621702636",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.93412,10.676027",
    "IsOpen24Hours": false,
    "id": "50.93412,10.676027"
  },
  {
    "Gas Station Number": "0FL03",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dresdener Straße 49C",
    "Postcode": 2625,
    "City": "Bautzen",
    "State": "Sachsen",
    "Latitude": "051.1792290",
    "Longitude": "014.4083960",
    "Telephone Number": "03591301677",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.179229,14.408396",
    "IsOpen24Hours": true,
    "id": "51.179229,14.408396"
  },
  {
    "Gas Station Number": "0FL04",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Theodor-Koerner-Allee 2",
    "Postcode": 2763,
    "City": "Zittau",
    "State": "Sachsen",
    "Latitude": "050.8938530",
    "Longitude": "014.8018100",
    "Telephone Number": "03583510275",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.893853,14.80181",
    "IsOpen24Hours": true,
    "id": "50.893853,14.80181"
  },
  {
    "Gas Station Number": "0FL05",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sonnenstraße 1",
    "Postcode": 9648,
    "City": "Mittweida",
    "State": "Sachsen",
    "Latitude": "050.9934010",
    "Longitude": "012.9655520",
    "Telephone Number": "0372790602",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.993401,12.965552",
    "IsOpen24Hours": true,
    "id": "50.993401,12.965552"
  },
  {
    "Gas Station Number": "0FL06",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Buttstaedter Straße 50",
    "Postcode": 99510,
    "City": "Apolda",
    "State": "Thüringen",
    "Latitude": "051.0400820",
    "Longitude": "011.5112370",
    "Telephone Number": "03644555516",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.040082,11.511237",
    "IsOpen24Hours": true,
    "id": "51.040082,11.511237"
  },
  {
    "Gas Station Number": "0FL08",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gelsenkirchener Allee 30",
    "Postcode": 3050,
    "City": "Cottbus",
    "State": "Brandenburg",
    "Latitude": "051.7265830",
    "Longitude": "014.3361330",
    "Telephone Number": "0355524021",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.726583,14.336133",
    "IsOpen24Hours": false,
    "id": "51.726583,14.336133"
  },
  {
    "Gas Station Number": "0FL09",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rudolstaedter Straße 58",
    "Postcode": 99099,
    "City": "Erfurt",
    "State": "Thüringen",
    "Latitude": "050.9690680",
    "Longitude": "011.0612600",
    "Telephone Number": "03614211782",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "05:00",
    "Saturdays To": "21:00",
    "Sundays From": "06:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.969068,11.06126",
    "IsOpen24Hours": false,
    "id": "50.969068,11.06126"
  },
  {
    "Gas Station Number": "0FL10",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Leipziger Str. 129",
    "Postcode": 4425,
    "City": "Taucha",
    "State": "Sachsen",
    "Latitude": "051.3721200",
    "Longitude": "012.4749880",
    "Telephone Number": "03429834595",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.37212,12.474988",
    "IsOpen24Hours": true,
    "id": "51.37212,12.474988"
  },
  {
    "Gas Station Number": "0FL11",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Jerichower Straße 24",
    "Postcode": 39114,
    "City": "Magdeburg",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.1319390",
    "Longitude": "011.6651820",
    "Telephone Number": "039183807333",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.131939,11.665182",
    "IsOpen24Hours": true,
    "id": "52.131939,11.665182"
  },
  {
    "Gas Station Number": "0FL12",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Leipziger Straße 257",
    "Postcode": 9114,
    "City": "Chemnitz",
    "State": "Sachsen",
    "Latitude": "050.8581330",
    "Longitude": "012.8691320",
    "Telephone Number": "03713301680",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.858133,12.869132",
    "IsOpen24Hours": true,
    "id": "50.858133,12.869132"
  },
  {
    "Gas Station Number": "0FL13",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Merseburger Straße 355",
    "Postcode": 6132,
    "City": "Halle",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.4401220",
    "Longitude": "011.9845170",
    "Telephone Number": "03457758492",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.440122,11.984517",
    "IsOpen24Hours": true,
    "id": "51.440122,11.984517"
  },
  {
    "Gas Station Number": "0FL14",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "BAB24 Südseite",
    "Postcode": 16833,
    "City": "Linum",
    "State": "Brandenburg",
    "Latitude": "052.7533240",
    "Longitude": "012.8526290",
    "Telephone Number": "03392250546",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.753324,12.852629",
    "IsOpen24Hours": true,
    "id": "52.753324,12.852629"
  },
  {
    "Gas Station Number": "0FL15",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Eberswalder Straße 37",
    "Postcode": 16227,
    "City": "Eberswalde-Finow",
    "State": "Brandenburg",
    "Latitude": "052.8390570",
    "Longitude": "013.7546000",
    "Telephone Number": "03334352814",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.839057,13.7546",
    "IsOpen24Hours": true,
    "id": "52.839057,13.7546"
  },
  {
    "Gas Station Number": "0FL16",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Potsdamer Straße 22",
    "Postcode": 14776,
    "City": "Brandenburg",
    "State": "Brandenburg",
    "Latitude": "052.4061070",
    "Longitude": "012.5759590",
    "Telephone Number": "03381524079",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.406107,12.575959",
    "IsOpen24Hours": true,
    "id": "52.406107,12.575959"
  },
  {
    "Gas Station Number": "0FL17",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Koburger Straße 52",
    "Postcode": 4416,
    "City": "Markkleeberg",
    "State": "Sachsen",
    "Latitude": "051.2860890",
    "Longitude": "012.3660980",
    "Telephone Number": "03413582448",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.286089,12.366098",
    "IsOpen24Hours": true,
    "id": "51.286089,12.366098"
  },
  {
    "Gas Station Number": "0FL18",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Krietzschwitzer Straße 7",
    "Postcode": 1796,
    "City": "Pirna",
    "State": "Sachsen",
    "Latitude": "050.9578010",
    "Longitude": "013.9494560",
    "Telephone Number": "03501761642",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.957801,13.949456",
    "IsOpen24Hours": true,
    "id": "50.957801,13.949456"
  },
  {
    "Gas Station Number": "0FL19",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gerlachstraße 8",
    "Postcode": 14480,
    "City": "Potsdam",
    "State": "Brandenburg",
    "Latitude": "052.3678520",
    "Longitude": "013.1285700",
    "Telephone Number": "0331613452",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.367852,13.12857",
    "IsOpen24Hours": true,
    "id": "52.367852,13.12857"
  },
  {
    "Gas Station Number": "0FL20",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nikolaus-Otto-Straße 1",
    "Postcode": 6667,
    "City": "Weißenfels",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.1882400",
    "Longitude": "011.9891250",
    "Telephone Number": "03443302222",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.18824,11.989125",
    "IsOpen24Hours": true,
    "id": "51.18824,11.989125"
  },
  {
    "Gas Station Number": "0FL21",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Drewitzer Straße 20 A",
    "Postcode": 14478,
    "City": "Potsdam",
    "State": "Brandenburg",
    "Latitude": "052.3741100",
    "Longitude": "013.0881520",
    "Telephone Number": "0331864635",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.37411,13.088152",
    "IsOpen24Hours": true,
    "id": "52.37411,13.088152"
  },
  {
    "Gas Station Number": "0FL22",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schnellerstraße 20",
    "Postcode": 12439,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4598190",
    "Longitude": "013.5044150",
    "Telephone Number": "0306316974",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.459819,13.504415",
    "IsOpen24Hours": true,
    "id": "52.459819,13.504415"
  },
  {
    "Gas Station Number": "0FL23",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Riestedter Straße 55",
    "Postcode": 6526,
    "City": "Sangerhausen",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.4803740",
    "Longitude": "011.3178810",
    "Telephone Number": "03464572843",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.480374,11.317881",
    "IsOpen24Hours": true,
    "id": "51.480374,11.317881"
  },
  {
    "Gas Station Number": "0FL24",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Magdeburger Chaussee 7 A",
    "Postcode": 38820,
    "City": "Halberstadt",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.9010650",
    "Longitude": "011.0739880",
    "Telephone Number": "03941600245",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.901065,11.073988",
    "IsOpen24Hours": true,
    "id": "51.901065,11.073988"
  },
  {
    "Gas Station Number": "0FL25",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kötzschenbroder Str. 188",
    "Postcode": 1139,
    "City": "Dresden",
    "State": "Sachsen",
    "Latitude": "051.0906150",
    "Longitude": "013.6755740",
    "Telephone Number": "03518301510",
    "Week From": "05:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "07:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "51.090615,13.675574",
    "IsOpen24Hours": false,
    "id": "51.090615,13.675574"
  },
  {
    "Gas Station Number": "0FL26",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neefestraße 145",
    "Postcode": 9116,
    "City": "Chemnitz",
    "State": "Sachsen",
    "Latitude": "050.8164500",
    "Longitude": "012.8829900",
    "Telephone Number": "03712806239",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.81645,12.88299",
    "IsOpen24Hours": true,
    "id": "50.81645,12.88299"
  },
  {
    "Gas Station Number": "0FL27",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Märkische Allee 248",
    "Postcode": 12679,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5537320",
    "Longitude": "013.5515870",
    "Telephone Number": "0309312140",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "05:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.553732,13.551587",
    "IsOpen24Hours": false,
    "id": "52.553732,13.551587"
  },
  {
    "Gas Station Number": "0FL28",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nordpark 3",
    "Postcode": 6237,
    "City": "Leuna OT Kötschlitz",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.3476090",
    "Longitude": "012.1752120",
    "Telephone Number": "03463820984",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.347609,12.175212",
    "IsOpen24Hours": true,
    "id": "51.347609,12.175212"
  },
  {
    "Gas Station Number": "0FL29",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stadtrodaer Straße 11",
    "Postcode": 7749,
    "City": "Jena",
    "State": "Thüringen",
    "Latitude": "050.9060340",
    "Longitude": "011.5898750",
    "Telephone Number": "03641394893",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.906034,11.589875",
    "IsOpen24Hours": true,
    "id": "50.906034,11.589875"
  },
  {
    "Gas Station Number": "0FL30",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ichtershaeuser Straße 84",
    "Postcode": 99310,
    "City": "Arnstadt",
    "State": "Thüringen",
    "Latitude": "050.8567690",
    "Longitude": "010.9589260",
    "Telephone Number": "0362878340",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.856769,10.958926",
    "IsOpen24Hours": false,
    "id": "50.856769,10.958926"
  },
  {
    "Gas Station Number": "0FL31",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dessauer Platz 4",
    "Postcode": 6118,
    "City": "Halle",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.4974110",
    "Longitude": "011.9819500",
    "Telephone Number": "03452909012",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.497411,11.98195",
    "IsOpen24Hours": true,
    "id": "51.497411,11.98195"
  },
  {
    "Gas Station Number": "0FL33",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Tessiner Straße 68",
    "Postcode": 18055,
    "City": "Rostock",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "054.0811970",
    "Longitude": "012.1943070",
    "Telephone Number": "0381690324",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "54.081197,12.194307",
    "IsOpen24Hours": false,
    "id": "54.081197,12.194307"
  },
  {
    "Gas Station Number": "0FL34",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Marienthaler Straße 2",
    "Postcode": 8060,
    "City": "Zwickau",
    "State": "Sachsen",
    "Latitude": "050.7206280",
    "Longitude": "012.4730560",
    "Telephone Number": "0375523928",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.720628,12.473056",
    "IsOpen24Hours": true,
    "id": "50.720628,12.473056"
  },
  {
    "Gas Station Number": "0FL35",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Junkersstraße 28",
    "Postcode": 6847,
    "City": "Dessau-Rosslau",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.8261710",
    "Longitude": "012.2149410",
    "Telephone Number": "0340517101",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.826171,12.214941",
    "IsOpen24Hours": true,
    "id": "51.826171,12.214941"
  },
  {
    "Gas Station Number": "0FL36",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "BAB A10 Nordseite",
    "Postcode": 15749,
    "City": "Brusendorf",
    "State": "Brandenburg",
    "Latitude": "052.3184540",
    "Longitude": "013.4983190",
    "Telephone Number": "033764268105",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.318454,13.498319",
    "IsOpen24Hours": true,
    "id": "52.318454,13.498319"
  },
  {
    "Gas Station Number": "0FL37",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "BAB A10 Suedseite",
    "Postcode": 15749,
    "City": "Brusendorf",
    "State": "Brandenburg",
    "Latitude": "052.3156230",
    "Longitude": "013.4937130",
    "Telephone Number": "033764726022",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.315623,13.493713",
    "IsOpen24Hours": true,
    "id": "52.315623,13.493713"
  },
  {
    "Gas Station Number": "0FL38",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Grossbeerenstraße 121",
    "Postcode": 14482,
    "City": "Potsdam",
    "State": "Brandenburg",
    "Latitude": "052.3860850",
    "Longitude": "013.1066550",
    "Telephone Number": "03317482335",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.386085,13.106655",
    "IsOpen24Hours": true,
    "id": "52.386085,13.106655"
  },
  {
    "Gas Station Number": "0FL39",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Frankfurter Allee 214",
    "Postcode": 10365,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5114390",
    "Longitude": "013.4938610",
    "Telephone Number": "0305578472",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.511439,13.493861",
    "IsOpen24Hours": true,
    "id": "52.511439,13.493861"
  },
  {
    "Gas Station Number": "0FL40",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Greifswalder Chaussee 62",
    "Postcode": 18439,
    "City": "Stralsund",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "054.2766880",
    "Longitude": "013.1068700",
    "Telephone Number": "03831270126",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "06:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "54.276688,13.10687",
    "IsOpen24Hours": false,
    "id": "54.276688,13.10687"
  },
  {
    "Gas Station Number": "0FL41",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schoenebecker Straße 74",
    "Postcode": 39104,
    "City": "Magdeburg",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.0991750",
    "Longitude": "011.6477690",
    "Telephone Number": "03914015407",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.099175,11.647769",
    "IsOpen24Hours": true,
    "id": "52.099175,11.647769"
  },
  {
    "Gas Station Number": "0FL42",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Zeppelinstraße 2",
    "Postcode": 12529,
    "City": "Schönefeld",
    "State": "Berlin",
    "Latitude": "052.3678120",
    "Longitude": "013.5607660",
    "Telephone Number": "03063311794",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.367812,13.560766",
    "IsOpen24Hours": false,
    "id": "52.367812,13.560766"
  },
  {
    "Gas Station Number": "0FL44",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kasseler Straße 50",
    "Postcode": 99817,
    "City": "Eisenach",
    "State": "Thüringen",
    "Latitude": "050.9811210",
    "Longitude": "010.3021720",
    "Telephone Number": "0369177024",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "06:30",
    "Saturdays To": "22:00",
    "Sundays From": "06:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.981121,10.302172",
    "IsOpen24Hours": false,
    "id": "50.981121,10.302172"
  },
  {
    "Gas Station Number": "0FL45",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alt-Friedrichsfelde 64",
    "Postcode": 12683,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5103140",
    "Longitude": "013.5384870",
    "Telephone Number": "0305133078",
    "Week From": "04:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Ja",
    "location": "52.510314,13.538487",
    "IsOpen24Hours": true,
    "id": "52.510314,13.538487"
  },
  {
    "Gas Station Number": "0FL46",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ziolkowskistraße 14",
    "Postcode": 39126,
    "City": "Magdeburg",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.1754530",
    "Longitude": "011.6291030",
    "Telephone Number": "03912515281",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.175453,11.629103",
    "IsOpen24Hours": true,
    "id": "52.175453,11.629103"
  },
  {
    "Gas Station Number": "0FL47",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Großenhainer Straße 41",
    "Postcode": 1662,
    "City": "Meißen",
    "State": "Sachsen",
    "Latitude": "051.1657990",
    "Longitude": "013.4867260",
    "Telephone Number": "03521739910",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.165799,13.486726",
    "IsOpen24Hours": true,
    "id": "51.165799,13.486726"
  },
  {
    "Gas Station Number": "0FL48",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der Wuhlheide 240-242",
    "Postcode": 12459,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4552110",
    "Longitude": "013.5557740",
    "Telephone Number": "03053790502",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.455211,13.555774",
    "IsOpen24Hours": false,
    "id": "52.455211,13.555774"
  },
  {
    "Gas Station Number": "0FL49",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Landsberger Straße 116",
    "Postcode": 4157,
    "City": "Leipzig",
    "State": "Sachsen",
    "Latitude": "051.3794950",
    "Longitude": "012.3586350",
    "Telephone Number": "03419129891",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "05:00",
    "Saturdays To": "23:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.379495,12.358635",
    "IsOpen24Hours": false,
    "id": "51.379495,12.358635"
  },
  {
    "Gas Station Number": "0FL50",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Volkmannstraße 19",
    "Postcode": 6112,
    "City": "Halle",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.4879140",
    "Longitude": "011.9841890",
    "Telephone Number": "03455126523",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.487914,11.984189",
    "IsOpen24Hours": true,
    "id": "51.487914,11.984189"
  },
  {
    "Gas Station Number": "0FL51",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Groepelinger Heerstr. 237",
    "Postcode": 28239,
    "City": "Bremen",
    "State": "Bremen",
    "Latitude": "053.1182190",
    "Longitude": "008.7568860",
    "Telephone Number": "0421612980",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.118219,8.756886",
    "IsOpen24Hours": true,
    "id": "53.118219,8.756886"
  },
  {
    "Gas Station Number": "0FL54",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berliner Straße 170",
    "Postcode": 45144,
    "City": "Essen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4524520",
    "Longitude": "006.9727930",
    "Telephone Number": "02018761280",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.452452,6.972793",
    "IsOpen24Hours": false,
    "id": "51.452452,6.972793"
  },
  {
    "Gas Station Number": "0FL55",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Siemensstraße 37",
    "Postcode": 60594,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.1023600",
    "Longitude": "008.6955240",
    "Telephone Number": "069622953",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.10236,8.695524",
    "IsOpen24Hours": true,
    "id": "50.10236,8.695524"
  },
  {
    "Gas Station Number": "0FL56",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bundesstraße 150",
    "Postcode": 52538,
    "City": "Gangelt",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9886320",
    "Longitude": "006.0330850",
    "Telephone Number": "024547458",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "07:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.988632,6.033085",
    "IsOpen24Hours": false,
    "id": "50.988632,6.033085"
  },
  {
    "Gas Station Number": "0FL58",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kaiserstraße 145",
    "Postcode": 45699,
    "City": "Herten",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5992080",
    "Longitude": "007.1482330",
    "Telephone Number": "0236637188",
    "Week From": "04:00",
    "Week To": "23:59",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "06:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "51.599208,7.148233",
    "IsOpen24Hours": false,
    "id": "51.599208,7.148233"
  },
  {
    "Gas Station Number": "0FL59",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Saarbruecker Straße 51",
    "Postcode": 66822,
    "City": "Lebach",
    "State": "Saarland",
    "Latitude": "049.4025460",
    "Longitude": "006.9163480",
    "Telephone Number": "068813253",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.402546,6.916348",
    "IsOpen24Hours": true,
    "id": "49.402546,6.916348"
  },
  {
    "Gas Station Number": "0FL61",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ostlandstraße 65",
    "Postcode": 59556,
    "City": "Lippstadt",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.7054430",
    "Longitude": "008.3404920",
    "Telephone Number": "0294180139",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.705443,8.340492",
    "IsOpen24Hours": false,
    "id": "51.705443,8.340492"
  },
  {
    "Gas Station Number": "0FL62",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Luebbecker Straße 166",
    "Postcode": 32584,
    "City": "Löhne",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.2249070",
    "Longitude": "008.7063770",
    "Telephone Number": "05732972802",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.224907,8.706377",
    "IsOpen24Hours": true,
    "id": "52.224907,8.706377"
  },
  {
    "Gas Station Number": "0FL64",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 166",
    "Postcode": 69242,
    "City": "Mühlhausen",
    "State": "Baden-Württemberg",
    "Latitude": "049.2480490",
    "Longitude": "008.7355460",
    "Telephone Number": "0622262546",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:30",
    "Saturdays To": "21:00",
    "Sundays From": "07:30",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.248049,8.735546",
    "IsOpen24Hours": false,
    "id": "49.248049,8.735546"
  },
  {
    "Gas Station Number": "0FL65",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Grossenbaumer Straße 67",
    "Postcode": 45481,
    "City": "Mülheim",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4095120",
    "Longitude": "006.8539480",
    "Telephone Number": "0208423854",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.409512,6.853948",
    "IsOpen24Hours": false,
    "id": "51.409512,6.853948"
  },
  {
    "Gas Station Number": "0FL66",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Niendorfer Straße 50",
    "Postcode": 22848,
    "City": "Norderstedt",
    "State": "Schleswig-Holstein",
    "Latitude": "053.6725000",
    "Longitude": "009.9683690",
    "Telephone Number": "04052876152",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.6725,9.968369",
    "IsOpen24Hours": false,
    "id": "53.6725,9.968369"
  },
  {
    "Gas Station Number": "0FL67",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kreuzwiese 1",
    "Postcode": 67806,
    "City": "Rockenhausen",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.6423020",
    "Longitude": "007.8269090",
    "Telephone Number": "063619946820",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.642302,7.826909",
    "IsOpen24Hours": false,
    "id": "49.642302,7.826909"
  },
  {
    "Gas Station Number": "0FL68",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mainzer Straße 97",
    "Postcode": 66121,
    "City": "Saarbrücken",
    "State": "Saarland",
    "Latitude": "049.2294800",
    "Longitude": "007.0078180",
    "Telephone Number": "068161887",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.22948,7.007818",
    "IsOpen24Hours": true,
    "id": "49.22948,7.007818"
  },
  {
    "Gas Station Number": "0FL69",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Großblittersdorfer Straße 285",
    "Postcode": 66119,
    "City": "Saarbrücken",
    "State": "Saarland",
    "Latitude": "049.1952400",
    "Longitude": "007.0235900",
    "Telephone Number": "0681872270",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.19524,7.02359",
    "IsOpen24Hours": true,
    "id": "49.19524,7.02359"
  },
  {
    "Gas Station Number": "0FL70",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heiligenbronner Straße 10",
    "Postcode": 78713,
    "City": "Schramberg",
    "State": "Baden-Württemberg",
    "Latitude": "048.2289110",
    "Longitude": "008.4214620",
    "Telephone Number": "074229921710",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.228911,8.421462",
    "IsOpen24Hours": false,
    "id": "48.228911,8.421462"
  },
  {
    "Gas Station Number": "0FL74",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kaltenweider Straße 10",
    "Postcode": 30900,
    "City": "Wedemark",
    "State": "Niedersachsen",
    "Latitude": "052.5423590",
    "Longitude": "009.7274440",
    "Telephone Number": "051303554",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "08:00",
    "Saturdays To": "20:00",
    "Sundays From": "10:00",
    "Sundays To": "18:00",
    "24 hour?": "Nein",
    "location": "52.542359,9.727444",
    "IsOpen24Hours": false,
    "id": "52.542359,9.727444"
  },
  {
    "Gas Station Number": "0FL75",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Cranger Straße 243",
    "Postcode": 45891,
    "City": "Gelsenkirchen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5622240",
    "Longitude": "007.0875870",
    "Telephone Number": "020975561",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.562224,7.087587",
    "IsOpen24Hours": true,
    "id": "51.562224,7.087587"
  },
  {
    "Gas Station Number": "0FL76",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kirchheimer Straße 97",
    "Postcode": 73230,
    "City": "Kirchheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.6416950",
    "Longitude": "009.4760370",
    "Telephone Number": "0702181775",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.641695,9.476037",
    "IsOpen24Hours": false,
    "id": "48.641695,9.476037"
  },
  {
    "Gas Station Number": "0FL77",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Luetticher Straße 100",
    "Postcode": 40547,
    "City": "Düsseldorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2398491",
    "Longitude": "006.7440739",
    "Telephone Number": "0211552322",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.2398491,6.7440739",
    "IsOpen24Hours": true,
    "id": "51.2398491,6.7440739"
  },
  {
    "Gas Station Number": "0FL78",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schullendamm 51",
    "Postcode": 49716,
    "City": "Meppen",
    "State": "Niedersachsen",
    "Latitude": "052.6916590",
    "Longitude": "007.2753800",
    "Telephone Number": "0593112459",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "52.691659,7.27538",
    "IsOpen24Hours": false,
    "id": "52.691659,7.27538"
  },
  {
    "Gas Station Number": "0FL79",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Moselstraße 1",
    "Postcode": 56332,
    "City": "Löf",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.2395890",
    "Longitude": "007.4418240",
    "Telephone Number": "02605952325",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "08:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.239589,7.441824",
    "IsOpen24Hours": false,
    "id": "50.239589,7.441824"
  },
  {
    "Gas Station Number": "0FL80",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Trautenfeldstraße 38",
    "Postcode": 68535,
    "City": "Edingen-Neckarhausen",
    "State": "Baden-Württemberg",
    "Latitude": "049.4452870",
    "Longitude": "008.5803300",
    "Telephone Number": "0621471739",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.445287,8.58033",
    "IsOpen24Hours": false,
    "id": "49.445287,8.58033"
  },
  {
    "Gas Station Number": "0FL81",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Albtalstraße 18",
    "Postcode": 76359,
    "City": "Marxzell",
    "State": "Baden-Württemberg",
    "Latitude": "048.8638180",
    "Longitude": "008.4478640",
    "Telephone Number": "072486939",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.863818,8.447864",
    "IsOpen24Hours": false,
    "id": "48.863818,8.447864"
  },
  {
    "Gas Station Number": "0FL82",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Darmstaedter Landstr. 63",
    "Postcode": 64331,
    "City": "Weiterstadt",
    "State": "Hessen",
    "Latitude": "049.9264790",
    "Longitude": "008.6026130",
    "Telephone Number": "061505457360",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.926479,8.602613",
    "IsOpen24Hours": false,
    "id": "49.926479,8.602613"
  },
  {
    "Gas Station Number": "0FL83",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Luebberstedter Straße 36",
    "Postcode": 21272,
    "City": "Egestorf",
    "State": "Niedersachsen",
    "Latitude": "053.1940460",
    "Longitude": "010.0721260",
    "Telephone Number": "04175587",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "53.194046,10.072126",
    "IsOpen24Hours": false,
    "id": "53.194046,10.072126"
  },
  {
    "Gas Station Number": "0FL84",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Burgstraße 2",
    "Postcode": 26736,
    "City": "Krummhörn",
    "State": "Niedersachsen",
    "Latitude": "053.4361150",
    "Longitude": "007.0933330",
    "Telephone Number": "04923990108",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.436115,7.093333",
    "IsOpen24Hours": false,
    "id": "53.436115,7.093333"
  },
  {
    "Gas Station Number": "0FL85",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lange Straße 32",
    "Postcode": 26935,
    "City": "Stadland-Rodenkirche",
    "State": "Niedersachsen",
    "Latitude": "053.4057340",
    "Longitude": "008.4597580",
    "Telephone Number": "047322663",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:30",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "53.405734,8.459758",
    "IsOpen24Hours": false,
    "id": "53.405734,8.459758"
  },
  {
    "Gas Station Number": "0FL86",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bremer Straße 34",
    "Postcode": 34388,
    "City": "Trendelburg",
    "State": "Hessen",
    "Latitude": "051.5907210",
    "Longitude": "009.4090880",
    "Telephone Number": "05675725292",
    "Week From": "08:00",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.590721,9.409088",
    "IsOpen24Hours": false,
    "id": "51.590721,9.409088"
  },
  {
    "Gas Station Number": "0FL87",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schuelperstraße 27",
    "Postcode": 25764,
    "City": "Wesselburen",
    "State": "Schleswig-Holstein",
    "Latitude": "054.2143610",
    "Longitude": "008.9234170",
    "Telephone Number": "048332373",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "05:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "54.214361,8.923417",
    "IsOpen24Hours": false,
    "id": "54.214361,8.923417"
  },
  {
    "Gas Station Number": "0FL88",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Würzburger Straße 190",
    "Postcode": 63743,
    "City": "Aschaffenburg",
    "State": "Bayern",
    "Latitude": "049.9614640",
    "Longitude": "009.1779720",
    "Telephone Number": "060218629290",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:30",
    "Saturdays To": "22:00",
    "Sundays From": "06:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.961464,9.177972",
    "IsOpen24Hours": false,
    "id": "49.961464,9.177972"
  },
  {
    "Gas Station Number": "0FL89",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ulmer Straße 33",
    "Postcode": 89547,
    "City": "Gerstetten",
    "State": "Baden-Württemberg",
    "Latitude": "048.6174950",
    "Longitude": "010.0247070",
    "Telephone Number": "0732396240",
    "Week From": "06:30",
    "Week To": "19:00",
    "Saturdays From": "06:30",
    "Saturdays To": "19:00",
    "Sundays From": "07:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "48.617495,10.024707",
    "IsOpen24Hours": false,
    "id": "48.617495,10.024707"
  },
  {
    "Gas Station Number": "0FL90",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedrichstraße 23",
    "Postcode": 89568,
    "City": "Hermaringen",
    "State": "Baden-Württemberg",
    "Latitude": "048.5976070",
    "Longitude": "010.2562530",
    "Telephone Number": "07322919572",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "48.597607,10.256253",
    "IsOpen24Hours": false,
    "id": "48.597607,10.256253"
  },
  {
    "Gas Station Number": "0FL91",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Soegeler Straße 7",
    "Postcode": 49762,
    "City": "Lathen",
    "State": "Niedersachsen",
    "Latitude": "052.8608820",
    "Longitude": "007.3285030",
    "Telephone Number": "059331341",
    "Week From": "07:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "09:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.860882,7.328503",
    "IsOpen24Hours": false,
    "id": "52.860882,7.328503"
  },
  {
    "Gas Station Number": "0FL92",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schwetzinger Straße 4",
    "Postcode": 69214,
    "City": "Eppelheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.3991300",
    "Longitude": "008.6247070",
    "Telephone Number": "06221763291",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.39913,8.624707",
    "IsOpen24Hours": false,
    "id": "49.39913,8.624707"
  },
  {
    "Gas Station Number": "0FL93",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Blumenstraße 39",
    "Postcode": 71106,
    "City": "Magstadt",
    "State": "Baden-Württemberg",
    "Latitude": "048.7448290",
    "Longitude": "008.9711710",
    "Telephone Number": "07159949619",
    "Week From": "07:00",
    "Week To": "18:30",
    "Saturdays From": "08:00",
    "Saturdays To": "13:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.744829,8.971171",
    "IsOpen24Hours": false,
    "id": "48.744829,8.971171"
  },
  {
    "Gas Station Number": "0FM10",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Karnaper Straße 44",
    "Postcode": 45329,
    "City": "Essen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5173700",
    "Longitude": "007.0075580",
    "Telephone Number": "02014366875",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.51737,7.007558",
    "IsOpen24Hours": true,
    "id": "51.51737,7.007558"
  },
  {
    "Gas Station Number": "0FL95",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wittener Straße 68",
    "Postcode": 45549,
    "City": "Sprockhövel",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3403920",
    "Longitude": "007.2897350",
    "Telephone Number": "023392796",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.340392,7.289735",
    "IsOpen24Hours": true,
    "id": "51.340392,7.289735"
  },
  {
    "Gas Station Number": "0FL96",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Aachener Straße 203-209",
    "Postcode": 50931,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9363680",
    "Longitude": "006.9223410",
    "Telephone Number": "02219402524",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.936368,6.922341",
    "IsOpen24Hours": false,
    "id": "50.936368,6.922341"
  },
  {
    "Gas Station Number": "0FL97",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Homburger Landstraße 42",
    "Postcode": 60435,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.1429780",
    "Longitude": "008.6964520",
    "Telephone Number": "069541237",
    "Week From": "06:30",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.142978,8.696452",
    "IsOpen24Hours": false,
    "id": "50.142978,8.696452"
  },
  {
    "Gas Station Number": "0FL98",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hofheimer Straße 24",
    "Postcode": 65439,
    "City": "Flörsheim",
    "State": "Hessen",
    "Latitude": "050.0477550",
    "Longitude": "008.4344820",
    "Telephone Number": "0614531103",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.047755,8.434482",
    "IsOpen24Hours": false,
    "id": "50.047755,8.434482"
  },
  {
    "Gas Station Number": "0FM02",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dubelohstraße 143",
    "Postcode": 33104,
    "City": "Paderborn",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.7456790",
    "Longitude": "008.7312830",
    "Telephone Number": "05254939178",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.745679,8.731283",
    "IsOpen24Hours": true,
    "id": "51.745679,8.731283"
  },
  {
    "Gas Station Number": "0FM43",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "B 49/Nordseite",
    "Postcode": 65614,
    "City": "Beselich",
    "State": "Hessen",
    "Latitude": "050.4691200",
    "Longitude": "008.1372420",
    "Telephone Number": "06484890071",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.46912,8.137242",
    "IsOpen24Hours": true,
    "id": "50.46912,8.137242"
  },
  {
    "Gas Station Number": "0FM44",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Werner-von-Siemens-Straße",
    "Postcode": 25337,
    "City": "Elmshorn",
    "State": "Schleswig-Holstein",
    "Latitude": "053.7455240",
    "Longitude": "009.7057940",
    "Telephone Number": "041215796687",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.745524,9.705794",
    "IsOpen24Hours": true,
    "id": "53.745524,9.705794"
  },
  {
    "Gas Station Number": "0FM03",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kölnstraße 272",
    "Postcode": 50321,
    "City": "Brühl",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8453040",
    "Longitude": "006.9155690",
    "Telephone Number": "02232411019",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.845304,6.915569",
    "IsOpen24Hours": true,
    "id": "50.845304,6.915569"
  },
  {
    "Gas Station Number": "0FM48",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 5",
    "Postcode": 79576,
    "City": "Weil am Rhein",
    "State": "Baden-Württemberg",
    "Latitude": "047.6002070",
    "Longitude": "007.6030250",
    "Telephone Number": "0762116361980",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.600207,7.603025",
    "IsOpen24Hours": true,
    "id": "47.600207,7.603025"
  },
  {
    "Gas Station Number": "0FC72",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dresdner Straße 36",
    "Postcode": 1936,
    "City": "Königsbrück",
    "State": "Sachsen",
    "Latitude": "051.2577010",
    "Longitude": "013.8914250",
    "Telephone Number": "03579536372",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.257701,13.891425",
    "IsOpen24Hours": true,
    "id": "51.257701,13.891425"
  },
  {
    "Gas Station Number": "0FH40",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Herner Straße 403",
    "Postcode": 44807,
    "City": "Bochum",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5125460",
    "Longitude": "007.2101510",
    "Telephone Number": "0234532070",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.512546,7.210151",
    "IsOpen24Hours": true,
    "id": "51.512546,7.210151"
  },
  {
    "Gas Station Number": "0FL53",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mainzer Straße 116a",
    "Postcode": 64572,
    "City": "Büttelborn",
    "State": "Hessen",
    "Latitude": "049.9065510",
    "Longitude": "008.5030330",
    "Telephone Number": "061529879262",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.906551,8.503033",
    "IsOpen24Hours": true,
    "id": "49.906551,8.503033"
  },
  {
    "Gas Station Number": "0FN94",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Westring 19",
    "Postcode": 24850,
    "City": "Schuby",
    "State": "Schleswig-Holstein",
    "Latitude": "054.5188520",
    "Longitude": "009.4647480",
    "Telephone Number": "04621949406",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.518852,9.464748",
    "IsOpen24Hours": true,
    "id": "54.518852,9.464748"
  },
  {
    "Gas Station Number": "0FO34",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 263",
    "Postcode": 89343,
    "City": "Jettingen-Scheppach",
    "State": "Bayern",
    "Latitude": "048.4094520",
    "Longitude": "010.4447410",
    "Telephone Number": "08225959090",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.409452,10.444741",
    "IsOpen24Hours": false,
    "id": "48.409452,10.444741"
  },
  {
    "Gas Station Number": "0FP19",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kiewer Straße 31",
    "Postcode": "04205",
    "City": "Leipzig",
    "State": "Sachsen",
    "Latitude": "051.3202270",
    "Longitude": "012.2765730",
    "Telephone Number": "03414229713",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.320227,12.276573",
    "IsOpen24Hours": false,
    "id": "51.320227,12.276573"
  },
  {
    "Gas Station Number": "0FP21",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Zeppelinstraße 2",
    "Postcode": 91187,
    "City": "Röttenbach",
    "State": "Bayern",
    "Latitude": "049.1574150",
    "Longitude": "011.0411530",
    "Telephone Number": "091726859440",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.157415,11.041153",
    "IsOpen24Hours": true,
    "id": "49.157415,11.041153"
  },
  {
    "Gas Station Number": "0FP22",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ulmer Straße 50",
    "Postcode": 89269,
    "City": "Vöhringen",
    "State": "Bayern",
    "Latitude": "048.2833590",
    "Longitude": "010.0780620",
    "Telephone Number": "073069533212",
    "Week From": "06:30",
    "Week To": "22:00",
    "Saturdays From": "07:30",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.283359,10.078062",
    "IsOpen24Hours": false,
    "id": "48.283359,10.078062"
  },
  {
    "Gas Station Number": "0FP04",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Fritzlarer Straße 39",
    "Postcode": 34281,
    "City": "Gudensberg",
    "State": "Hessen",
    "Latitude": "051.1760500",
    "Longitude": "009.3563930",
    "Telephone Number": "056032302",
    "Week From": "08:00",
    "Week To": "19:00",
    "Saturdays From": "09:00",
    "Saturdays To": "14:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.17605,9.356393",
    "IsOpen24Hours": false,
    "id": "51.17605,9.356393"
  },
  {
    "Gas Station Number": "0FP24",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Römerallee 78",
    "Postcode": 53909,
    "City": "Zülpich",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7036440",
    "Longitude": "006.6666990",
    "Telephone Number": "022528306140",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.703644,6.666699",
    "IsOpen24Hours": true,
    "id": "50.703644,6.666699"
  },
  {
    "Gas Station Number": "0FP18",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hedelfinger Straße 25 A",
    "Postcode": 70327,
    "City": "Stuttgart",
    "State": "Baden-Württemberg",
    "Latitude": "048.7695260",
    "Longitude": "009.2481990",
    "Telephone Number": "07114077791",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.769526,9.248199",
    "IsOpen24Hours": true,
    "id": "48.769526,9.248199"
  },
  {
    "Gas Station Number": "0FP23",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stuttgarter Straße 80",
    "Postcode": 73033,
    "City": "Göppingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.7089730",
    "Longitude": "009.6339050",
    "Telephone Number": "07161922495",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.708973,9.633905",
    "IsOpen24Hours": true,
    "id": "48.708973,9.633905"
  },
  {
    "Gas Station Number": "0FP25",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Würzburger Straße 1",
    "Postcode": 97230,
    "City": "Estenfeld",
    "State": "Bayern",
    "Latitude": "049.8286860",
    "Longitude": "009.9993430",
    "Telephone Number": "09305218",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.828686,9.999343",
    "IsOpen24Hours": false,
    "id": "49.828686,9.999343"
  },
  {
    "Gas Station Number": "0FP36",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Grifter Straße 2",
    "Postcode": 34302,
    "City": "Guxhagen",
    "State": "Hessen",
    "Latitude": "051.2030500",
    "Longitude": "009.4685900",
    "Telephone Number": "05665800070",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.20305,9.46859",
    "IsOpen24Hours": true,
    "id": "51.20305,9.46859"
  },
  {
    "Gas Station Number": "0FP29",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Ovelgönne 20",
    "Postcode": 49356,
    "City": "Diepholz",
    "State": "Niedersachsen",
    "Latitude": "052.6182990",
    "Longitude": "008.3653180",
    "Telephone Number": "05441929980",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.618299,8.365318",
    "IsOpen24Hours": true,
    "id": "52.618299,8.365318"
  },
  {
    "Gas Station Number": "0FP30",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Landsberger Straße 423-425",
    "Postcode": 81241,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1451100",
    "Longitude": "011.4786150",
    "Telephone Number": "08982072936",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "48.14511,11.478615",
    "IsOpen24Hours": false,
    "id": "48.14511,11.478615"
  },
  {
    "Gas Station Number": "0FP32",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berghauptener Straße 21b",
    "Postcode": 77723,
    "City": "Gengenbach",
    "State": "Baden-Württemberg",
    "Latitude": "048.3997320",
    "Longitude": "008.0078180",
    "Telephone Number": "078037759",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.399732,8.007818",
    "IsOpen24Hours": true,
    "id": "48.399732,8.007818"
  },
  {
    "Gas Station Number": "0FP31",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Zeilstraße 27",
    "Postcode": 72793,
    "City": "Pfullingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.4732600",
    "Longitude": "009.2335110",
    "Telephone Number": "07121312580",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.47326,9.233511",
    "IsOpen24Hours": true,
    "id": "48.47326,9.233511"
  },
  {
    "Gas Station Number": "0FP35",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "an der B 413",
    "Postcode": 56269,
    "City": "Dierdorf",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.5493350",
    "Longitude": "007.6563070",
    "Telephone Number": "026893558",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.549335,7.656307",
    "IsOpen24Hours": false,
    "id": "50.549335,7.656307"
  },
  {
    "Gas Station Number": "0FP37",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 14",
    "Postcode": 51588,
    "City": "Nümbrecht",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9062300",
    "Longitude": "007.5425810",
    "Telephone Number": "022937380",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.90623,7.542581",
    "IsOpen24Hours": false,
    "id": "50.90623,7.542581"
  },
  {
    "Gas Station Number": "0FP38",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 1",
    "Postcode": 27367,
    "City": "Sottrum",
    "State": "Niedersachsen",
    "Latitude": "053.0901090",
    "Longitude": "009.1796230",
    "Telephone Number": "04205491",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.090109,9.179623",
    "IsOpen24Hours": true,
    "id": "53.090109,9.179623"
  },
  {
    "Gas Station Number": "0FP39",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Knückel 1",
    "Postcode": 63843,
    "City": "Niedernberg",
    "State": "Bayern",
    "Latitude": "049.9145530",
    "Longitude": "009.1217420",
    "Telephone Number": "060283078950",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.914553,9.121742",
    "IsOpen24Hours": false,
    "id": "49.914553,9.121742"
  },
  {
    "Gas Station Number": "0FP40",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Max-Pollin-Strasse 2",
    "Postcode": 85104,
    "City": "Pförring",
    "State": "Bayern",
    "Latitude": "048.8167670",
    "Longitude": "011.6935720",
    "Telephone Number": "08403927825",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.816767,11.693572",
    "IsOpen24Hours": false,
    "id": "48.816767,11.693572"
  },
  {
    "Gas Station Number": "0FP45",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Aurea 1-3",
    "Postcode": 33378,
    "City": "Rheda-Wiedenbrück",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.8312320",
    "Longitude": "008.2184660",
    "Telephone Number": "025228343732",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.831232,8.218466",
    "IsOpen24Hours": true,
    "id": "51.831232,8.218466"
  },
  {
    "Gas Station Number": "0FP43",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stolberger Straße 64",
    "Postcode": 52249,
    "City": "Eschweiler",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7990150",
    "Longitude": "006.2362510",
    "Telephone Number": "02403504360",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.799015,6.236251",
    "IsOpen24Hours": false,
    "id": "50.799015,6.236251"
  },
  {
    "Gas Station Number": "0FP48",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Overhagener Straße 78",
    "Postcode": 59557,
    "City": "Lippstadt",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6613990",
    "Longitude": "008.3380590",
    "Telephone Number": "0294110515",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.661399,8.338059",
    "IsOpen24Hours": false,
    "id": "51.661399,8.338059"
  },
  {
    "Gas Station Number": "0FP44",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gressenicher Straße 85",
    "Postcode": 52224,
    "City": "Stolberg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7613430",
    "Longitude": "006.2828330",
    "Telephone Number": "02402764757",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.761343,6.282833",
    "IsOpen24Hours": false,
    "id": "50.761343,6.282833"
  },
  {
    "Gas Station Number": "0FP49",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 4",
    "Postcode": 89143,
    "City": "Blaubeuren",
    "State": "Baden-Württemberg",
    "Latitude": "048.4001890",
    "Longitude": "009.7969130",
    "Telephone Number": "073449528412",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.400189,9.796913",
    "IsOpen24Hours": false,
    "id": "48.400189,9.796913"
  },
  {
    "Gas Station Number": "0FP51",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Konrad-Adenauer-Straße 25",
    "Postcode": 35745,
    "City": "Herborn",
    "State": "Hessen",
    "Latitude": "050.6753340",
    "Longitude": "008.3065220",
    "Telephone Number": "02772957192",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.675334,8.306522",
    "IsOpen24Hours": false,
    "id": "50.675334,8.306522"
  },
  {
    "Gas Station Number": "0FP41",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schlossstraße 1",
    "Postcode": 57537,
    "City": "Wissen",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.7814790",
    "Longitude": "007.7422020",
    "Telephone Number": "027422101",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.781479,7.742202",
    "IsOpen24Hours": true,
    "id": "50.781479,7.742202"
  },
  {
    "Gas Station Number": "0FP12",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Graf-Heinrich-Straße 18",
    "Postcode": 57627,
    "City": "Hachenburg",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.6637240",
    "Longitude": "007.8180360",
    "Telephone Number": "026624455",
    "Week From": "04:00",
    "Week To": "23:00",
    "Saturdays From": "05:00",
    "Saturdays To": "23:59",
    "Sundays From": "05:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "50.663724,7.818036",
    "IsOpen24Hours": false,
    "id": "50.663724,7.818036"
  },
  {
    "Gas Station Number": "0FP53",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gottlieb-Braun-Straße 33 / B 295",
    "Postcode": 75382,
    "City": "Althengstett",
    "State": "Baden-Württemberg",
    "Latitude": "048.7267510",
    "Longitude": "008.7861090",
    "Telephone Number": "07051922290",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.726751,8.786109",
    "IsOpen24Hours": true,
    "id": "48.726751,8.786109"
  },
  {
    "Gas Station Number": "0FY31",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 67",
    "Postcode": 64319,
    "City": "Pfungstadt",
    "State": "Hessen",
    "Latitude": "049.8107009",
    "Longitude": "008.5776706",
    "Telephone Number": "061573201",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.8107009,8.5776706",
    "IsOpen24Hours": true,
    "id": "49.8107009,8.5776706"
  },
  {
    "Gas Station Number": "0FQ09",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "August-Horch-Straße 1-3",
    "Postcode": 56076,
    "City": "Koblenz",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.3892760",
    "Longitude": "007.5664030",
    "Telephone Number": "026198859626",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.389276,7.566403",
    "IsOpen24Hours": true,
    "id": "50.389276,7.566403"
  },
  {
    "Gas Station Number": "0FG08",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Nickerner Weg 8",
    "Postcode": 1257,
    "City": "Dresden",
    "State": "Sachsen",
    "Latitude": "050.9938780",
    "Longitude": "013.7973700",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.993878,13.79737",
    "IsOpen24Hours": true,
    "id": "50.993878,13.79737"
  },
  {
    "Gas Station Number": "0FF98",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Böttcherweg/Sattlerweg",
    "Postcode": 4158,
    "City": "Leipzig",
    "State": "Sachsen",
    "Latitude": "051.4081020",
    "Longitude": "012.3034320",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.408102,12.303432",
    "IsOpen24Hours": true,
    "id": "51.408102,12.303432"
  },
  {
    "Gas Station Number": "0FG06",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Kossaer Str. 2",
    "Postcode": 4356,
    "City": "Leipzig",
    "State": "Sachsen",
    "Latitude": "051.4109670",
    "Longitude": "012.3880240",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.410967,12.388024",
    "IsOpen24Hours": true,
    "id": "51.410967,12.388024"
  },
  {
    "Gas Station Number": "0FO32",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Haunstetter Straße 75",
    "Postcode": 86161,
    "City": "Augsburg",
    "State": "Bayern",
    "Latitude": "048.3462770",
    "Longitude": "010.9053440",
    "Telephone Number": "082132856518",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.346277,10.905344",
    "IsOpen24Hours": false,
    "id": "48.346277,10.905344"
  },
  {
    "Gas Station Number": "0FN09",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hindenburgstraße 41",
    "Postcode": 89129,
    "City": "Langenau",
    "State": "Baden-Württemberg",
    "Latitude": "048.4956470",
    "Longitude": "010.1174450",
    "Telephone Number": "073457150",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.495647,10.117445",
    "IsOpen24Hours": false,
    "id": "48.495647,10.117445"
  },
  {
    "Gas Station Number": "0FQ68",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 2",
    "Postcode": 33719,
    "City": "Bielefeld",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.0099590",
    "Longitude": "008.6299970",
    "Telephone Number": "052089592215",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.009959,8.629997",
    "IsOpen24Hours": true,
    "id": "52.009959,8.629997"
  },
  {
    "Gas Station Number": "0FV48",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schulenburger Landstraße 128",
    "Postcode": 30165,
    "City": "Hannover",
    "State": "Niedersachsen",
    "Latitude": "052.4065500",
    "Longitude": "009.7111800",
    "Telephone Number": "051167662626",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.40655,9.71118",
    "IsOpen24Hours": true,
    "id": "52.40655,9.71118"
  },
  {
    "Gas Station Number": "0FV91",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "Broichhofstrasse 8 (A52)",
    "Postcode": 40880,
    "City": "Ratingen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2905480",
    "Longitude": "006.8044000",
    "Telephone Number": "021029422656",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.290548,6.8044",
    "IsOpen24Hours": true,
    "id": "51.290548,6.8044"
  },
  {
    "Gas Station Number": "0FN36",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berchtesgardener Straße 2",
    "Postcode": 83435,
    "City": "Bad Reichenhall",
    "State": "Bayern",
    "Latitude": "047.7176010",
    "Longitude": "012.8752010",
    "Telephone Number": "0865167220",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "47.717601,12.875201",
    "IsOpen24Hours": false,
    "id": "47.717601,12.875201"
  },
  {
    "Gas Station Number": "0FG09",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Junkersstraße",
    "Postcode": 4435,
    "City": "Schkeuditz-Glesien",
    "State": "Sachsen",
    "Latitude": "051.4440670",
    "Longitude": "012.2391320",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.444067,12.239132",
    "IsOpen24Hours": true,
    "id": "51.444067,12.239132"
  },
  {
    "Gas Station Number": "0FM31",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Giessener Straße 10",
    "Postcode": 35325,
    "City": "Mücke",
    "State": "Hessen",
    "Latitude": "050.6130420",
    "Longitude": "009.0273330",
    "Telephone Number": "06400951223",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "08:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:30",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "50.613042,9.027333",
    "IsOpen24Hours": false,
    "id": "50.613042,9.027333"
  },
  {
    "Gas Station Number": "0FG02",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Ringstraße 3",
    "Postcode": 4827,
    "City": "Gerichshain",
    "State": "Sachsen",
    "Latitude": "051.3555420",
    "Longitude": "012.5692140",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.355542,12.569214",
    "IsOpen24Hours": true,
    "id": "51.355542,12.569214"
  },
  {
    "Gas Station Number": "0FG05",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Albert-Vietel-Straße 4",
    "Postcode": 9217,
    "City": "Burgstädt",
    "State": "Sachsen",
    "Latitude": "050.8984320",
    "Longitude": "012.8162120",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.898432,12.816212",
    "IsOpen24Hours": true,
    "id": "50.898432,12.816212"
  },
  {
    "Gas Station Number": "0FO31",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Edisonstraße 14 a",
    "Postcode": 86199,
    "City": "Augsburg",
    "State": "Bayern",
    "Latitude": "048.3447370",
    "Longitude": "010.8852790",
    "Telephone Number": "08215998707",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.344737,10.885279",
    "IsOpen24Hours": true,
    "id": "48.344737,10.885279"
  },
  {
    "Gas Station Number": "0FO49",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Seefelder Straße 28",
    "Postcode": 82211,
    "City": "Herrsching",
    "State": "Bayern",
    "Latitude": "048.0023390",
    "Longitude": "011.1818720",
    "Telephone Number": "081528861",
    "Week From": "06:30",
    "Week To": "21:00",
    "Saturdays From": "06:30",
    "Saturdays To": "21:00",
    "Sundays From": "08:30",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.002339,11.181872",
    "IsOpen24Hours": false,
    "id": "48.002339,11.181872"
  },
  {
    "Gas Station Number": "0FG07",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Rostockerstr.",
    "Postcode": 14641,
    "City": "Wustermark",
    "State": "Brandenburg",
    "Latitude": "052.5601120",
    "Longitude": "012.9708500",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.560112,12.97085",
    "IsOpen24Hours": true,
    "id": "52.560112,12.97085"
  },
  {
    "Gas Station Number": "0FT80",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Freiherr-von-Varnbüler Str. 1",
    "Postcode": 71282,
    "City": "Hemmingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.8648150",
    "Longitude": "009.0393890",
    "Telephone Number": "071509185010",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.864815,9.039389",
    "IsOpen24Hours": false,
    "id": "48.864815,9.039389"
  },
  {
    "Gas Station Number": "0FS20",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dürener Straße 407",
    "Postcode": 50858,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9233180",
    "Longitude": "006.8695270",
    "Telephone Number": "022117092660",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.923318,6.869527",
    "IsOpen24Hours": false,
    "id": "50.923318,6.869527"
  },
  {
    "Gas Station Number": "0FP86",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Forchheimer Str. 15",
    "Postcode": 79359,
    "City": "Riegel am Kaiserstuhl",
    "State": "Baden-Württemberg",
    "Latitude": "048.1567710",
    "Longitude": "007.7446260",
    "Telephone Number": "076429078674",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.156771,7.744626",
    "IsOpen24Hours": true,
    "id": "48.156771,7.744626"
  },
  {
    "Gas Station Number": "0F361",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Essener Straße 225",
    "Postcode": 45472,
    "City": "Mülheim",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4306860",
    "Longitude": "006.9235810",
    "Telephone Number": "0208431410",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.430686,6.923581",
    "IsOpen24Hours": true,
    "id": "51.430686,6.923581"
  },
  {
    "Gas Station Number": "0FG21",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Jahnstraße 88",
    "Postcode": 73037,
    "City": "Göppingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.6969040",
    "Longitude": "009.6597920",
    "Telephone Number": "0716173548",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.696904,9.659792",
    "IsOpen24Hours": true,
    "id": "48.696904,9.659792"
  },
  {
    "Gas Station Number": "0FO84",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nordstraße 2",
    "Postcode": 57578,
    "City": "Elkenroth",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.7356900",
    "Longitude": "007.8779130",
    "Telephone Number": "027479129748",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "05:30",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.73569,7.877913",
    "IsOpen24Hours": false,
    "id": "50.73569,7.877913"
  },
  {
    "Gas Station Number": "0FU89",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 44",
    "Postcode": 59457,
    "City": "Werl",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5323830",
    "Longitude": "007.8612640",
    "Telephone Number": "029225399",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.532383,7.861264",
    "IsOpen24Hours": true,
    "id": "51.532383,7.861264"
  },
  {
    "Gas Station Number": "0FT78",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Heidfuchsberg 1",
    "Postcode": 39443,
    "City": "Staßfurt / OT Brumby",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.8964680",
    "Longitude": "011.6919750",
    "Telephone Number": "039291463920",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.896468,11.691975",
    "IsOpen24Hours": true,
    "id": "51.896468,11.691975"
  },
  {
    "Gas Station Number": "0FV49",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rotweg 28 - 32",
    "Postcode": 70437,
    "City": "Stuttgart",
    "State": "Baden-Württemberg",
    "Latitude": "048.8337310",
    "Longitude": "009.1825660",
    "Telephone Number": "071186052603",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "07:00",
    "Saturdays To": "23:00",
    "Sundays From": "08:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "48.833731,9.182566",
    "IsOpen24Hours": false,
    "id": "48.833731,9.182566"
  },
  {
    "Gas Station Number": "0FN52",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Landsberger Straße 20",
    "Postcode": 86807,
    "City": "Buchloe",
    "State": "Bayern",
    "Latitude": "048.0359400",
    "Longitude": "010.7324830",
    "Telephone Number": "08241911291",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.03594,10.732483",
    "IsOpen24Hours": false,
    "id": "48.03594,10.732483"
  },
  {
    "Gas Station Number": "0FQ66",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Johann-G.-Gutenbergstraße 1",
    "Postcode": 82140,
    "City": "Olching",
    "State": "Bayern",
    "Latitude": "048.1995100",
    "Longitude": "011.3359410",
    "Telephone Number": "081423060",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.19951,11.335941",
    "IsOpen24Hours": false,
    "id": "48.19951,11.335941"
  },
  {
    "Gas Station Number": "0FC43",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Chausseestraße 71 a",
    "Postcode": 17373,
    "City": "Ueckermünde",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.7325700",
    "Longitude": "014.0299770",
    "Telephone Number": "03977127044",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.73257,14.029977",
    "IsOpen24Hours": false,
    "id": "53.73257,14.029977"
  },
  {
    "Gas Station Number": "0FO52",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Haydnstraße 3",
    "Postcode": 89250,
    "City": "Senden",
    "State": "Bayern",
    "Latitude": "048.3282980",
    "Longitude": "010.0420080",
    "Telephone Number": "07307939282",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.328298,10.042008",
    "IsOpen24Hours": false,
    "id": "48.328298,10.042008"
  },
  {
    "Gas Station Number": "0FN93",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lindenstraße 60",
    "Postcode": 99718,
    "City": "Greußen",
    "State": "Thüringen",
    "Latitude": "051.2229670",
    "Longitude": "010.9471460",
    "Telephone Number": "03636701255",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.222967,10.947146",
    "IsOpen24Hours": false,
    "id": "51.222967,10.947146"
  },
  {
    "Gas Station Number": "0FS21",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Pariser Straße 140",
    "Postcode": 55268,
    "City": "Nieder-Olm",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.9072360",
    "Longitude": "008.1989300",
    "Telephone Number": "061369538615",
    "Week From": "05:00",
    "Week To": "23:59",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "06:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "49.907236,8.19893",
    "IsOpen24Hours": false,
    "id": "49.907236,8.19893"
  },
  {
    "Gas Station Number": "0FV04",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Daimlerstraße 2",
    "Postcode": 35260,
    "City": "Stadtallendorf",
    "State": "Hessen",
    "Latitude": "050.8352620",
    "Longitude": "009.0383660",
    "Telephone Number": "064284483606",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.835262,9.038366",
    "IsOpen24Hours": true,
    "id": "50.835262,9.038366"
  },
  {
    "Gas Station Number": "0FG56",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Theresienstraße 2",
    "Postcode": 83278,
    "City": "Traunstein",
    "State": "Bayern",
    "Latitude": "047.8721080",
    "Longitude": "012.6382330",
    "Telephone Number": "08612681",
    "Week From": "06:30",
    "Week To": "21:00",
    "Saturdays From": "06:30",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "18:00",
    "24 hour?": "Nein",
    "location": "47.872108,12.638233",
    "IsOpen24Hours": false,
    "id": "47.872108,12.638233"
  },
  {
    "Gas Station Number": "0FM46",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Märkische Straße 14",
    "Postcode": 58675,
    "City": "Hemer",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3931130",
    "Longitude": "007.7584870",
    "Telephone Number": "0237210628",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.393113,7.758487",
    "IsOpen24Hours": true,
    "id": "51.393113,7.758487"
  },
  {
    "Gas Station Number": "0FO26",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neckaraue 2",
    "Postcode": 71686,
    "City": "Remseck",
    "State": "Baden-Württemberg",
    "Latitude": "048.8901960",
    "Longitude": "009.2712130",
    "Telephone Number": "0714643532",
    "Week From": "07:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "09:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.890196,9.271213",
    "IsOpen24Hours": false,
    "id": "48.890196,9.271213"
  },
  {
    "Gas Station Number": "0FN92",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alpenstraße 4",
    "Postcode": 83242,
    "City": "Reit im Winkl",
    "State": "Bayern",
    "Latitude": "047.6688390",
    "Longitude": "012.4946520",
    "Telephone Number": "086408237",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.668839,12.494652",
    "IsOpen24Hours": true,
    "id": "47.668839,12.494652"
  },
  {
    "Gas Station Number": "0FM66",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sandweierer Straße 47",
    "Postcode": 76532,
    "City": "Baden-Baden",
    "State": "Baden-Württemberg",
    "Latitude": "048.8112010",
    "Longitude": "008.1953910",
    "Telephone Number": "07221809171",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.811201,8.195391",
    "IsOpen24Hours": false,
    "id": "48.811201,8.195391"
  },
  {
    "Gas Station Number": "0FG03",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Am Bahnhof",
    "Postcode": 15562,
    "City": "Rüdersdorf",
    "State": "Brandenburg",
    "Latitude": "052.4890080",
    "Longitude": "013.7800630",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.489008,13.780063",
    "IsOpen24Hours": true,
    "id": "52.489008,13.780063"
  },
  {
    "Gas Station Number": "0FQ08",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Äußere Moosburger Str. 1",
    "Postcode": 85276,
    "City": "Pfaffenhofen",
    "State": "Bayern",
    "Latitude": "048.5223530",
    "Longitude": "011.5436340",
    "Telephone Number": "08441499190",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.522353,11.543634",
    "IsOpen24Hours": false,
    "id": "48.522353,11.543634"
  },
  {
    "Gas Station Number": "0FF99",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Am Weidendamm",
    "Postcode": 15831,
    "City": "Groß Kienitz",
    "State": "Brandenburg",
    "Latitude": "052.3189930",
    "Longitude": "013.4709110",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.318993,13.470911",
    "IsOpen24Hours": true,
    "id": "52.318993,13.470911"
  },
  {
    "Gas Station Number": "0FN34",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Chiemseestraße 50",
    "Postcode": 83278,
    "City": "Traunstein",
    "State": "Bayern",
    "Latitude": "047.8681010",
    "Longitude": "012.6222010",
    "Telephone Number": "086112973",
    "Week From": "06:00",
    "Week To": "21:30",
    "Saturdays From": "06:00",
    "Saturdays To": "21:30",
    "Sundays From": "07:00",
    "Sundays To": "21:30",
    "24 hour?": "Nein",
    "location": "47.868101,12.622201",
    "IsOpen24Hours": false,
    "id": "47.868101,12.622201"
  },
  {
    "Gas Station Number": "0FU19",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gothaer Straße 40",
    "Postcode": 99094,
    "City": "Erfurt",
    "State": "Thüringen",
    "Latitude": "050.9649820",
    "Longitude": "010.9991200",
    "Telephone Number": "036165319607",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.964982,10.99912",
    "IsOpen24Hours": false,
    "id": "50.964982,10.99912"
  },
  {
    "Gas Station Number": "0FO19",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nebelhornstraße 8",
    "Postcode": 87719,
    "City": "Mindelheim",
    "State": "Bayern",
    "Latitude": "048.0360850",
    "Longitude": "010.5013710",
    "Telephone Number": "0826120100",
    "Week From": "06:00",
    "Week To": "23:59",
    "Saturdays From": "07:00",
    "Saturdays To": "23:59",
    "Sundays From": "07:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "48.036085,10.501371",
    "IsOpen24Hours": false,
    "id": "48.036085,10.501371"
  },
  {
    "Gas Station Number": "0FK72",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "An der Autobahn 1",
    "Postcode": 29690,
    "City": "Buchholz/Aller",
    "State": "Niedersachsen",
    "Latitude": "052.6738090",
    "Longitude": "009.6890940",
    "Telephone Number": "050718060",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.673809,9.689094",
    "IsOpen24Hours": true,
    "id": "52.673809,9.689094"
  },
  {
    "Gas Station Number": "0FO22",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wasseralfinger Straße 66",
    "Postcode": 73434,
    "City": "Aalen-Fachsenfeld",
    "State": "Baden-Württemberg",
    "Latitude": "048.8784870",
    "Longitude": "010.0499960",
    "Telephone Number": "07366922998",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.878487,10.049996",
    "IsOpen24Hours": false,
    "id": "48.878487,10.049996"
  },
  {
    "Gas Station Number": "0FO71",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Scherzachstraße 4",
    "Postcode": 88281,
    "City": "Schlier",
    "State": "Baden-Württemberg",
    "Latitude": "047.7720720",
    "Longitude": "009.6706400",
    "Telephone Number": "07529912202",
    "Week From": "06:30",
    "Week To": "20:00",
    "Saturdays From": "06:30",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "18:00",
    "24 hour?": "Nein",
    "location": "47.772072,9.67064",
    "IsOpen24Hours": false,
    "id": "47.772072,9.67064"
  },
  {
    "Gas Station Number": "0FP64",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Monzenbend 8",
    "Postcode": 53894,
    "City": "Mechernich",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.6186820",
    "Longitude": "006.6517070",
    "Telephone Number": "02443310120",
    "Week From": "04:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "23:55",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.618682,6.651707",
    "IsOpen24Hours": false,
    "id": "50.618682,6.651707"
  },
  {
    "Gas Station Number": "0FU51",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "BILDECHINGER STEIGE 64",
    "Postcode": 72160,
    "City": "HORB AM NECKAR",
    "State": "Baden-Württemberg",
    "Latitude": "048.4555750",
    "Longitude": "008.6990150",
    "Telephone Number": "074515506927",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.455575,8.699015",
    "IsOpen24Hours": true,
    "id": "48.455575,8.699015"
  },
  {
    "Gas Station Number": "0FV66",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Werner-von-Siemens-Straße 2",
    "Postcode": 63150,
    "City": "Heusenstamm",
    "State": "Hessen",
    "Latitude": "050.0484490",
    "Longitude": "008.7992380",
    "Telephone Number": "06104/8008838",
    "Week From": "07:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.048449,8.799238",
    "IsOpen24Hours": false,
    "id": "50.048449,8.799238"
  },
  {
    "Gas Station Number": "0FP90",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A44",
    "Postcode": 59457,
    "City": "Werl",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5334290",
    "Longitude": "007.8633550",
    "Telephone Number": "029225299",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.533429,7.863355",
    "IsOpen24Hours": true,
    "id": "51.533429,7.863355"
  },
  {
    "Gas Station Number": "0FM12",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Im langen Feld 17",
    "Postcode": 71711,
    "City": "Murr",
    "State": "Baden-Württemberg",
    "Latitude": "048.9571290",
    "Longitude": "009.2435260",
    "Telephone Number": "0714429235",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.957129,9.243526",
    "IsOpen24Hours": true,
    "id": "48.957129,9.243526"
  },
  {
    "Gas Station Number": "0FV60",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kleiststraße 22",
    "Postcode": 65232,
    "City": "Taunusstein",
    "State": "Hessen",
    "Latitude": "050.1383500",
    "Longitude": "008.1546820",
    "Telephone Number": "06128/9372380",
    "Week From": "07:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.13835,8.154682",
    "IsOpen24Hours": false,
    "id": "50.13835,8.154682"
  },
  {
    "Gas Station Number": "0FO92",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Auf der Held 2",
    "Postcode": 56305,
    "City": "Puderbach",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.5933210",
    "Longitude": "007.6074900",
    "Telephone Number": "02684978878",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.593321,7.60749",
    "IsOpen24Hours": false,
    "id": "50.593321,7.60749"
  },
  {
    "Gas Station Number": "0FN07",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wörishofer Straße 23",
    "Postcode": 86842,
    "City": "Türkheim",
    "State": "Bayern",
    "Latitude": "048.0554260",
    "Longitude": "010.6369160",
    "Telephone Number": "08245962111",
    "Week From": "06:30",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:30",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "48.055426,10.636916",
    "IsOpen24Hours": false,
    "id": "48.055426,10.636916"
  },
  {
    "Gas Station Number": "0FN10",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Weißenhorner Straße 56",
    "Postcode": 89297,
    "City": "Roggenburg-Biberach",
    "State": "Bayern",
    "Latitude": "048.2880640",
    "Longitude": "010.2139910",
    "Telephone Number": "073005184",
    "Week From": "06:30",
    "Week To": "20:00",
    "Saturdays From": "07:30",
    "Saturdays To": "20:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.288064,10.213991",
    "IsOpen24Hours": false,
    "id": "48.288064,10.213991"
  },
  {
    "Gas Station Number": "0FF38",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Otto-Brenner-Straße 8",
    "Postcode": 21337,
    "City": "Lüneburg",
    "State": "Niedersachsen",
    "Latitude": "053.2487910",
    "Longitude": "010.4741550",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.248791,10.474155",
    "IsOpen24Hours": true,
    "id": "53.248791,10.474155"
  },
  {
    "Gas Station Number": "0FF36",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Ferdinand-Porsche-Straße",
    "Postcode": 21684,
    "City": "Stade",
    "State": "Niedersachsen",
    "Latitude": "053.5772120",
    "Longitude": "009.4956910",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.577212,9.495691",
    "IsOpen24Hours": true,
    "id": "53.577212,9.495691"
  },
  {
    "Gas Station Number": "0FF68",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Bertha-Benz-Straße",
    "Postcode": 26160,
    "City": "Bad Zwischenahn",
    "State": "Niedersachsen",
    "Latitude": "053.1829250",
    "Longitude": "008.0735170",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.182925,8.073517",
    "IsOpen24Hours": true,
    "id": "53.182925,8.073517"
  },
  {
    "Gas Station Number": "0FF30",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Schehnberger Weg 11",
    "Postcode": 26203,
    "City": "Wardenburg",
    "State": "Niedersachsen",
    "Latitude": "053.0505240",
    "Longitude": "008.2113010",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.050524,8.211301",
    "IsOpen24Hours": true,
    "id": "53.050524,8.211301"
  },
  {
    "Gas Station Number": "0F460",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wittener Straße 66",
    "Postcode": 44789,
    "City": "Bochum",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4774850",
    "Longitude": "007.2277670",
    "Telephone Number": "0234335915",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.477485,7.227767",
    "IsOpen24Hours": true,
    "id": "51.477485,7.227767"
  },
  {
    "Gas Station Number": "0FF29",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Ilexstraße",
    "Postcode": 26639,
    "City": "Wiesmoor",
    "State": "Niedersachsen",
    "Latitude": "053.3756280",
    "Longitude": "007.7498060",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.375628,7.749806",
    "IsOpen24Hours": true,
    "id": "53.375628,7.749806"
  },
  {
    "Gas Station Number": "0FF32",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Neukamp 4",
    "Postcode": 26655,
    "City": "Westerstede",
    "State": "Niedersachsen",
    "Latitude": "053.2685010",
    "Longitude": "007.9451080",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.268501,7.945108",
    "IsOpen24Hours": true,
    "id": "53.268501,7.945108"
  },
  {
    "Gas Station Number": "0FM29",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Raiffeisenstraße 2",
    "Postcode": 86720,
    "City": "Nördlingen",
    "State": "Bayern",
    "Latitude": "048.8568530",
    "Longitude": "010.4784280",
    "Telephone Number": "09081801092",
    "Week From": "06:00",
    "Week To": "20:30",
    "Saturdays From": "06:30",
    "Saturdays To": "20:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.856853,10.478428",
    "IsOpen24Hours": false,
    "id": "48.856853,10.478428"
  },
  {
    "Gas Station Number": "0FN06",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße. 34",
    "Postcode": 86459,
    "City": "Gessertshausen",
    "State": "Bayern",
    "Latitude": "048.3293720",
    "Longitude": "010.7303300",
    "Telephone Number": "0823896130",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.329372,10.73033",
    "IsOpen24Hours": false,
    "id": "48.329372,10.73033"
  },
  {
    "Gas Station Number": "0FY15",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der alten Bundesstraße 3",
    "Postcode": 26441,
    "City": "Jever",
    "State": "Niedersachsen",
    "Latitude": "053.5605690",
    "Longitude": "007.9158800",
    "Telephone Number": "044613002",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "08:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.560569,7.91588",
    "IsOpen24Hours": false,
    "id": "53.560569,7.91588"
  },
  {
    "Gas Station Number": "0FU82",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A7",
    "Postcode": 97262,
    "City": "Hausen bei Würzburg",
    "State": "Bayern",
    "Latitude": "049.9468460",
    "Longitude": "010.0210160",
    "Telephone Number": "093639966968",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.946846,10.021016",
    "IsOpen24Hours": true,
    "id": "49.946846,10.021016"
  },
  {
    "Gas Station Number": "0FU97",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Manfred-Roth-Straße 3",
    "Postcode": 18196,
    "City": "Dummerstorf",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "054.0217010",
    "Longitude": "012.2318560",
    "Telephone Number": "038208828266",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.021701,12.231856",
    "IsOpen24Hours": true,
    "id": "54.021701,12.231856"
  },
  {
    "Gas Station Number": "0FO64",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Marie-Curie-Straße 2-6",
    "Postcode": 46446,
    "City": "Emmerich",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.8424210",
    "Longitude": "006.2752390",
    "Telephone Number": "0282297016",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.842421,6.275239",
    "IsOpen24Hours": true,
    "id": "51.842421,6.275239"
  },
  {
    "Gas Station Number": "0FM16",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ingolstädter Straße 59",
    "Postcode": 80939,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1929590",
    "Longitude": "011.5836400",
    "Telephone Number": "0893185530",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.192959,11.58364",
    "IsOpen24Hours": true,
    "id": "48.192959,11.58364"
  },
  {
    "Gas Station Number": "0FN79",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schmidener Straße 255",
    "Postcode": 70374,
    "City": "Stuttgart",
    "State": "Baden-Württemberg",
    "Latitude": "048.8197590",
    "Longitude": "009.2434940",
    "Telephone Number": "015229888629",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.819759,9.243494",
    "IsOpen24Hours": false,
    "id": "48.819759,9.243494"
  },
  {
    "Gas Station Number": "0FF31",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Neukamp 4",
    "Postcode": 26655,
    "City": "Westerstede",
    "State": "Niedersachsen",
    "Latitude": "053.2570680",
    "Longitude": "007.9279450",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.257068,7.927945",
    "IsOpen24Hours": true,
    "id": "53.257068,7.927945"
  },
  {
    "Gas Station Number": "0FF28",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Industriestraße",
    "Postcode": 26892,
    "City": "Dörpen",
    "State": "Niedersachsen",
    "Latitude": "052.9700230",
    "Longitude": "007.3608130",
    "Telephone Number": "0496391280",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "52.970023,7.360813",
    "IsOpen24Hours": true,
    "id": "52.970023,7.360813"
  },
  {
    "Gas Station Number": "0FY21",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A48",
    "Postcode": 56729,
    "City": "Kehrig",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.2675517",
    "Longitude": "007.2266494",
    "Telephone Number": "02651900522",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.2675517,7.2266494",
    "IsOpen24Hours": true,
    "id": "50.2675517,7.2266494"
  },
  {
    "Gas Station Number": "0FP56",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Cloppenburger Straße 239",
    "Postcode": 26133,
    "City": "Oldenburg",
    "State": "Niedersachsen",
    "Latitude": "053.1165740",
    "Longitude": "008.2143180",
    "Telephone Number": "044142648",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.116574,8.214318",
    "IsOpen24Hours": true,
    "id": "53.116574,8.214318"
  },
  {
    "Gas Station Number": "0FO30",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Oberstdorfer Straße 13",
    "Postcode": 87435,
    "City": "Kempten",
    "State": "Bayern",
    "Latitude": "047.7061300",
    "Longitude": "010.3116610",
    "Telephone Number": "0831523530",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "47.70613,10.311661",
    "IsOpen24Hours": false,
    "id": "47.70613,10.311661"
  },
  {
    "Gas Station Number": "0FF27",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Ludwig-Erhard-Straße 25",
    "Postcode": 28197,
    "City": "Bremen",
    "State": "Bremen",
    "Latitude": "053.0876310",
    "Longitude": "008.7121690",
    "Telephone Number": "0421-529580",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.087631,8.712169",
    "IsOpen24Hours": true,
    "id": "53.087631,8.712169"
  },
  {
    "Gas Station Number": "0FF26",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Ludwig-Erhard-Straße 25",
    "Postcode": 28197,
    "City": "Bremen",
    "State": "Bremen",
    "Latitude": "053.0874390",
    "Longitude": "008.7123510",
    "Telephone Number": "0421529580",
    "Week From": "05:00",
    "Week To": "23:59",
    "Saturdays From": "05:00",
    "Saturdays To": "18:00",
    "Sundays From": "12:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "53.087439,8.712351",
    "IsOpen24Hours": false,
    "id": "53.087439,8.712351"
  },
  {
    "Gas Station Number": "0FF58",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am alten Bahnhof 1",
    "Postcode": 51645,
    "City": "Gummersbach",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0033450",
    "Longitude": "007.5758830",
    "Telephone Number": "02261789440",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.003345,7.575883",
    "IsOpen24Hours": true,
    "id": "51.003345,7.575883"
  },
  {
    "Gas Station Number": "0FN30",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Saarbrücker Straße 7 c",
    "Postcode": 66292,
    "City": "Riegelsberg",
    "State": "Saarland",
    "Latitude": "049.2975400",
    "Longitude": "006.9479700",
    "Telephone Number": "0680644748",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.29754,6.94797",
    "IsOpen24Hours": false,
    "id": "49.29754,6.94797"
  },
  {
    "Gas Station Number": "0FV53",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neue Regensburger Straße 37",
    "Postcode": 84030,
    "City": "Ergolding",
    "State": "Bayern",
    "Latitude": "048.5588570",
    "Longitude": "012.1654830",
    "Telephone Number": "087197471620",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.558857,12.165483",
    "IsOpen24Hours": false,
    "id": "48.558857,12.165483"
  },
  {
    "Gas Station Number": "0FT81",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wilhelm-Hauff-Straße 43",
    "Postcode": 74906,
    "City": "Bad Rappenau",
    "State": "Baden-Württemberg",
    "Latitude": "049.2108290",
    "Longitude": "009.0701050",
    "Telephone Number": "07066915353",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.210829,9.070105",
    "IsOpen24Hours": false,
    "id": "49.210829,9.070105"
  },
  {
    "Gas Station Number": "0FF66",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Gutenbergstraße 2",
    "Postcode": 28816,
    "City": "Stuhr",
    "State": "Niedersachsen",
    "Latitude": "053.0196130",
    "Longitude": "008.7713480",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.019613,8.771348",
    "IsOpen24Hours": true,
    "id": "53.019613,8.771348"
  },
  {
    "Gas Station Number": "0FV97",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Frankfurter Straße 7a",
    "Postcode": 53773,
    "City": "Hennef",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7710640",
    "Longitude": "007.2959230",
    "Telephone Number": "02242/2914",
    "Week From": "05:45",
    "Week To": "23:59",
    "Saturdays From": "05:45",
    "Saturdays To": "23:59",
    "Sundays From": "07:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "50.771064,7.295923",
    "IsOpen24Hours": false,
    "id": "50.771064,7.295923"
  },
  {
    "Gas Station Number": "0FV33",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Martin-Schleyer-Straße 6",
    "Postcode": 78465,
    "City": "Konstanz",
    "State": "Baden-Württemberg",
    "Latitude": "047.7123230",
    "Longitude": "009.1734290",
    "Telephone Number": "07531581073",
    "Week From": "07:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "47.712323,9.173429",
    "IsOpen24Hours": false,
    "id": "47.712323,9.173429"
  },
  {
    "Gas Station Number": "0FF37",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Werner-von-Siemens-Str.",
    "Postcode": 28832,
    "City": "Achim",
    "State": "Niedersachsen",
    "Latitude": "053.0230480",
    "Longitude": "009.0668200",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.023048,9.06682",
    "IsOpen24Hours": true,
    "id": "53.023048,9.06682"
  },
  {
    "Gas Station Number": "0FJ71",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schwere-Reiter-Straße 26b",
    "Postcode": 80797,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1610030",
    "Longitude": "011.5585040",
    "Telephone Number": "089188503",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.161003,11.558504",
    "IsOpen24Hours": true,
    "id": "48.161003,11.558504"
  },
  {
    "Gas Station Number": "0FP66",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Frankfurter Straße 102 A",
    "Postcode": 63500,
    "City": "Seligenstadt",
    "State": "Hessen",
    "Latitude": "050.0451040",
    "Longitude": "008.9612010",
    "Telephone Number": "061828206288",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "07:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "50.045104,8.961201",
    "IsOpen24Hours": false,
    "id": "50.045104,8.961201"
  },
  {
    "Gas Station Number": "0FV61",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Walther-Rathenau-Allee 24",
    "Postcode": 64720,
    "City": "Michelstadt",
    "State": "Hessen",
    "Latitude": "049.6910730",
    "Longitude": "009.0037040",
    "Telephone Number": "015161573988",
    "Week From": "07:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.691073,9.003704",
    "IsOpen24Hours": false,
    "id": "49.691073,9.003704"
  },
  {
    "Gas Station Number": "0FN62",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Österreicher Straße 64",
    "Postcode": 1279,
    "City": "Dresden",
    "State": "Sachsen",
    "Latitude": "051.0210010",
    "Longitude": "013.8405010",
    "Telephone Number": "03512514937",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.021001,13.840501",
    "IsOpen24Hours": false,
    "id": "51.021001,13.840501"
  },
  {
    "Gas Station Number": "0FP60",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Siemensstraße 9",
    "Postcode": 71263,
    "City": "Weil der Stadt",
    "State": "Baden-Württemberg",
    "Latitude": "048.7561230",
    "Longitude": "008.8674360",
    "Telephone Number": "070336005",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.756123,8.867436",
    "IsOpen24Hours": false,
    "id": "48.756123,8.867436"
  },
  {
    "Gas Station Number": "0FQ65",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hallerstraße 1",
    "Postcode": 66333,
    "City": "Völklingen-Wehrden",
    "State": "Saarland",
    "Latitude": "049.2438260",
    "Longitude": "006.8397800",
    "Telephone Number": "0689829375",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.243826,6.83978",
    "IsOpen24Hours": true,
    "id": "49.243826,6.83978"
  },
  {
    "Gas Station Number": "0FM99",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hörnleinsgrund 1",
    "Postcode": 96450,
    "City": "Coburg",
    "State": "Bayern",
    "Latitude": "050.2676030",
    "Longitude": "010.9477550",
    "Telephone Number": "0956130721",
    "Week From": "07:00",
    "Week To": "21:00",
    "Saturdays From": "07:30",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.267603,10.947755",
    "IsOpen24Hours": false,
    "id": "50.267603,10.947755"
  },
  {
    "Gas Station Number": "0FO28",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Zeller Straße 15",
    "Postcode": 73235,
    "City": "Weilheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.6203710",
    "Longitude": "009.5402330",
    "Telephone Number": "070239564184",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.620371,9.540233",
    "IsOpen24Hours": false,
    "id": "48.620371,9.540233"
  },
  {
    "Gas Station Number": "0FG40",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Bierweg 6",
    "Postcode": 4435,
    "City": "Schkeuditz",
    "State": "Sachsen",
    "Latitude": "051.3994670",
    "Longitude": "012.1906460",
    "Telephone Number": "03420465724",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.399467,12.190646",
    "IsOpen24Hours": true,
    "id": "51.399467,12.190646"
  },
  {
    "Gas Station Number": "0FO56",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Düsseldorfer Straße 491",
    "Postcode": 51061,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9895420",
    "Longitude": "006.9988220",
    "Telephone Number": "0221664829",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.989542,6.998822",
    "IsOpen24Hours": true,
    "id": "50.989542,6.998822"
  },
  {
    "Gas Station Number": "0FM95",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alpenstraße 5",
    "Postcode": 82362,
    "City": "Weilheim",
    "State": "Bayern",
    "Latitude": "047.8356710",
    "Longitude": "011.1437470",
    "Telephone Number": "0881417593",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.835671,11.143747",
    "IsOpen24Hours": false,
    "id": "47.835671,11.143747"
  },
  {
    "Gas Station Number": "0FO23",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Illertisser Straße 40",
    "Postcode": 89165,
    "City": "Dietenheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.2104910",
    "Longitude": "010.0773650",
    "Telephone Number": "073477593",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.210491,10.077365",
    "IsOpen24Hours": false,
    "id": "48.210491,10.077365"
  },
  {
    "Gas Station Number": "0FO27",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Günzburger Straße 23",
    "Postcode": 89362,
    "City": "Offingen",
    "State": "Bayern",
    "Latitude": "048.4785210",
    "Longitude": "010.3630520",
    "Telephone Number": "08224967490",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.478521,10.363052",
    "IsOpen24Hours": false,
    "id": "48.478521,10.363052"
  },
  {
    "Gas Station Number": "0FG64",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "BAB A 19 - West",
    "Postcode": 18276,
    "City": "Recknitz",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.8400370",
    "Longitude": "012.2912490",
    "Telephone Number": "03845520962",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.840037,12.291249",
    "IsOpen24Hours": true,
    "id": "53.840037,12.291249"
  },
  {
    "Gas Station Number": "0FP85",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dieselstraße 16",
    "Postcode": 84069,
    "City": "Schierling",
    "State": "Bayern",
    "Latitude": "048.8382730",
    "Longitude": "012.1230050",
    "Telephone Number": "094519489202",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.838273,12.123005",
    "IsOpen24Hours": true,
    "id": "48.838273,12.123005"
  },
  {
    "Gas Station Number": "0FX61",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A3",
    "Postcode": 65549,
    "City": "Limburg",
    "State": "Hessen",
    "Latitude": "050.3853509",
    "Longitude": "008.0808480",
    "Telephone Number": "0643171026",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.3853509,8.080848",
    "IsOpen24Hours": true,
    "id": "50.3853509,8.080848"
  },
  {
    "Gas Station Number": "0FO73",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wolfegger Straße 2",
    "Postcode": 88267,
    "City": "Vogt",
    "State": "Baden-Württemberg",
    "Latitude": "047.7756320",
    "Longitude": "009.7709190",
    "Telephone Number": "07529912991",
    "Week From": "06:30",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "47.775632,9.770919",
    "IsOpen24Hours": false,
    "id": "47.775632,9.770919"
  },
  {
    "Gas Station Number": "0FM67",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kurt-Schumacher-Damm 12",
    "Postcode": 49078,
    "City": "Osnabrück",
    "State": "Niedersachsen",
    "Latitude": "052.2706230",
    "Longitude": "008.0248330",
    "Telephone Number": "05414095309",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.270623,8.024833",
    "IsOpen24Hours": false,
    "id": "52.270623,8.024833"
  },
  {
    "Gas Station Number": "0FN78",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Solmser Gewerbepark 4",
    "Postcode": 35606,
    "City": "Solms",
    "State": "Hessen",
    "Latitude": "050.5434900",
    "Longitude": "008.4111220",
    "Telephone Number": "06442927192",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.54349,8.411122",
    "IsOpen24Hours": false,
    "id": "50.54349,8.411122"
  },
  {
    "Gas Station Number": "0FF69",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Wiesenauer Str. 12",
    "Postcode": 30851,
    "City": "Langenhagen",
    "State": "Niedersachsen",
    "Latitude": "052.4251040",
    "Longitude": "009.7172570",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.425104,9.717257",
    "IsOpen24Hours": true,
    "id": "52.425104,9.717257"
  },
  {
    "Gas Station Number": "0FF81",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Bismarckstraße 7",
    "Postcode": 33775,
    "City": "Versmold",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.0238360",
    "Longitude": "008.1224200",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.023836,8.12242",
    "IsOpen24Hours": true,
    "id": "52.023836,8.12242"
  },
  {
    "Gas Station Number": "0FS49",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "In der Quelle 74",
    "Postcode": 63303,
    "City": "Dreieich-Offenthal",
    "State": "Hessen",
    "Latitude": "049.9802220",
    "Longitude": "008.7365300",
    "Telephone Number": "060748035221",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.980222,8.73653",
    "IsOpen24Hours": true,
    "id": "49.980222,8.73653"
  },
  {
    "Gas Station Number": "0FP16",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bliesaue 3",
    "Postcode": 66440,
    "City": "Blieskastel",
    "State": "Saarland",
    "Latitude": "049.2356570",
    "Longitude": "007.2697830",
    "Telephone Number": "068429219763",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.235657,7.269783",
    "IsOpen24Hours": false,
    "id": "49.235657,7.269783"
  },
  {
    "Gas Station Number": "0FQ64",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Rudolf-Diesel-Str.",
    "Postcode": 34123,
    "City": "Fuldabrueck",
    "State": "Hessen",
    "Latitude": "051.2709198",
    "Longitude": "009.5173123",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.2709198,9.5173123",
    "IsOpen24Hours": true,
    "id": "51.2709198,9.5173123"
  },
  {
    "Gas Station Number": "0FR07",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kraftsdorfer Straße 2",
    "Postcode": 7629,
    "City": "Hermsdorf",
    "State": "Thüringen",
    "Latitude": "050.8892950",
    "Longitude": "011.8715180",
    "Telephone Number": "03660154012",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.889295,11.871518",
    "IsOpen24Hours": true,
    "id": "50.889295,11.871518"
  },
  {
    "Gas Station Number": "0FQ01",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Luisenstraße 77",
    "Postcode": 52477,
    "City": "Alsdorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8743260",
    "Longitude": "006.1697320",
    "Telephone Number": "024046777544",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.874326,6.169732",
    "IsOpen24Hours": true,
    "id": "50.874326,6.169732"
  },
  {
    "Gas Station Number": "0FQ71",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 41",
    "Postcode": 19230,
    "City": "Bandenitz",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.4913400",
    "Longitude": "011.2822500",
    "Telephone Number": "0388505344",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.49134,11.28225",
    "IsOpen24Hours": true,
    "id": "53.49134,11.28225"
  },
  {
    "Gas Station Number": "0FO54",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 104",
    "Postcode": 86381,
    "City": "Krumbach",
    "State": "Bayern",
    "Latitude": "048.2517870",
    "Longitude": "010.3566840",
    "Telephone Number": "082822492",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.251787,10.356684",
    "IsOpen24Hours": false,
    "id": "48.251787,10.356684"
  },
  {
    "Gas Station Number": "0FT75",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heedfelder Straße 92",
    "Postcode": 58509,
    "City": "Lüdenscheid",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2293660",
    "Longitude": "007.6118570",
    "Telephone Number": "023516913",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.229366,7.611857",
    "IsOpen24Hours": false,
    "id": "51.229366,7.611857"
  },
  {
    "Gas Station Number": "0FL07",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Siemensstraße 57",
    "Postcode": 7546,
    "City": "Gera",
    "State": "Thüringen",
    "Latitude": "050.9077940",
    "Longitude": "012.0665300",
    "Telephone Number": "0365415045",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.907794,12.06653",
    "IsOpen24Hours": true,
    "id": "50.907794,12.06653"
  },
  {
    "Gas Station Number": "0FI85",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 46-48",
    "Postcode": 69488,
    "City": "Birkenau",
    "State": "Hessen",
    "Latitude": "049.5616590",
    "Longitude": "008.6996990",
    "Telephone Number": "0620131714",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.561659,8.699699",
    "IsOpen24Hours": false,
    "id": "49.561659,8.699699"
  },
  {
    "Gas Station Number": "0FP83",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bundesstraße 42",
    "Postcode": 53572,
    "City": "Unkel",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.6019430",
    "Longitude": "007.2219230",
    "Telephone Number": "022249879024",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.601943,7.221923",
    "IsOpen24Hours": true,
    "id": "50.601943,7.221923"
  },
  {
    "Gas Station Number": "0FF67",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Hannoversche Straße 2 a",
    "Postcode": 34225,
    "City": "Baunatal",
    "State": "Hessen",
    "Latitude": "051.2421480",
    "Longitude": "009.4383140",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.242148,9.438314",
    "IsOpen24Hours": true,
    "id": "51.242148,9.438314"
  },
  {
    "Gas Station Number": "0FM21",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neurieder Weg 33",
    "Postcode": 86609,
    "City": "Donauwörth",
    "State": "Bayern",
    "Latitude": "048.7197820",
    "Longitude": "010.7614650",
    "Telephone Number": "09063060",
    "Week From": "06:30",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "48.719782,10.761465",
    "IsOpen24Hours": false,
    "id": "48.719782,10.761465"
  },
  {
    "Gas Station Number": "0FQ02",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A1",
    "Postcode": 27801,
    "City": "Dötlingen",
    "State": "Niedersachsen",
    "Latitude": "052.9149350",
    "Longitude": "008.4260350",
    "Telephone Number": "0443193920",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.914935,8.426035",
    "IsOpen24Hours": true,
    "id": "52.914935,8.426035"
  },
  {
    "Gas Station Number": "0FV74",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Clemensstraße 19",
    "Postcode": 99817,
    "City": "Eisenach",
    "State": "Thüringen",
    "Latitude": "050.9779900",
    "Longitude": "010.3313600",
    "Telephone Number": "036918819798",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.97799,10.33136",
    "IsOpen24Hours": true,
    "id": "50.97799,10.33136"
  },
  {
    "Gas Station Number": "0FY22",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A5",
    "Postcode": 64331,
    "City": "Weiterstadt",
    "State": "Hessen",
    "Latitude": "049.9455299",
    "Longitude": "008.6109209",
    "Telephone Number": "06150134933",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "49.9455299,8.6109209",
    "IsOpen24Hours": false,
    "id": "49.9455299,8.6109209"
  },
  {
    "Gas Station Number": "0FV78",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Freudenberger Str. 517",
    "Postcode": 57072,
    "City": "Siegen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8842090",
    "Longitude": "007.9622240",
    "Telephone Number": "027131766324",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.884209,7.962224",
    "IsOpen24Hours": false,
    "id": "50.884209,7.962224"
  },
  {
    "Gas Station Number": "0FF94",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Kleine Eichen 1 a",
    "Postcode": 34355,
    "City": "Staufenberg",
    "State": "Niedersachsen",
    "Latitude": "051.3753570",
    "Longitude": "009.6287740",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.375357,9.628774",
    "IsOpen24Hours": true,
    "id": "51.375357,9.628774"
  },
  {
    "Gas Station Number": "0FO67",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Industriestraße 2",
    "Postcode": 94559,
    "City": "Niederwinkling",
    "State": "Bayern",
    "Latitude": "048.8939330",
    "Longitude": "012.8005730",
    "Telephone Number": "09962912020",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.893933,12.800573",
    "IsOpen24Hours": true,
    "id": "48.893933,12.800573"
  },
  {
    "Gas Station Number": "0FO21",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kaufbeurer Straße 5",
    "Postcode": 86830,
    "City": "Schwabmünchen",
    "State": "Bayern",
    "Latitude": "048.1758810",
    "Longitude": "010.7576880",
    "Telephone Number": "082324773",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.175881,10.757688",
    "IsOpen24Hours": false,
    "id": "48.175881,10.757688"
  },
  {
    "Gas Station Number": "0FM17",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Amalienburgstraße 20",
    "Postcode": 81247,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1643180",
    "Longitude": "011.4885410",
    "Telephone Number": "08989160247",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.164318,11.488541",
    "IsOpen24Hours": true,
    "id": "48.164318,11.488541"
  },
  {
    "Gas Station Number": "0FN76",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Tuttlinger Straße 38",
    "Postcode": 78628,
    "City": "Rottweil",
    "State": "Baden-Württemberg",
    "Latitude": "048.1533090",
    "Longitude": "008.6396250",
    "Telephone Number": "074121398",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.153309,8.639625",
    "IsOpen24Hours": false,
    "id": "48.153309,8.639625"
  },
  {
    "Gas Station Number": "0FV35",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bregenzer Straße 43 - 45",
    "Postcode": 88131,
    "City": "Lindau",
    "State": "Bayern",
    "Latitude": "047.5530180",
    "Longitude": "009.7014450",
    "Telephone Number": "",
    "Week From": "06:00",
    "Week To": "22:30",
    "Saturdays From": "06:00",
    "Saturdays To": "22:30",
    "Sundays From": "06:30",
    "Sundays To": "22:30",
    "24 hour?": "Nein",
    "location": "47.553018,9.701445",
    "IsOpen24Hours": false,
    "id": "47.553018,9.701445"
  },
  {
    "Gas Station Number": "0FV17",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Athener Strasse 1",
    "Postcode": 48455,
    "City": "Bad Bentheim-Gildehaus",
    "State": "Niedersachsen",
    "Latitude": "052.3202800",
    "Longitude": "007.0676900",
    "Telephone Number": "059248087766",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.32028,7.06769",
    "IsOpen24Hours": true,
    "id": "52.32028,7.06769"
  },
  {
    "Gas Station Number": "0FH76",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Luetticher Straße 108",
    "Postcode": 40547,
    "City": "Düsseldorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2411200",
    "Longitude": "006.7436430",
    "Telephone Number": "0211552422",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.24112,6.743643",
    "IsOpen24Hours": true,
    "id": "51.24112,6.743643"
  },
  {
    "Gas Station Number": "0FF63",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Otto-Hahn-Str. 16",
    "Postcode": 35510,
    "City": "Butzbach",
    "State": "Hessen",
    "Latitude": "050.4306710",
    "Longitude": "008.6876230",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.430671,8.687623",
    "IsOpen24Hours": true,
    "id": "50.430671,8.687623"
  },
  {
    "Gas Station Number": "0FN64",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schneeberger Straße 30",
    "Postcode": 8309,
    "City": "Eibenstock",
    "State": "Sachsen",
    "Latitude": "050.5013010",
    "Longitude": "012.6031010",
    "Telephone Number": "0377523145",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.501301,12.603101",
    "IsOpen24Hours": false,
    "id": "50.501301,12.603101"
  },
  {
    "Gas Station Number": "0FF70",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Peter-Hurst-Str.",
    "Postcode": 38448,
    "City": "Wolfsburg",
    "State": "Niedersachsen",
    "Latitude": "052.3718460",
    "Longitude": "010.7372530",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.371846,10.737253",
    "IsOpen24Hours": true,
    "id": "52.371846,10.737253"
  },
  {
    "Gas Station Number": "0FO94",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 1",
    "Postcode": 54421,
    "City": "Reinsfeld",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.6897050",
    "Longitude": "006.8999900",
    "Telephone Number": "065861011",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.689705,6.89999",
    "IsOpen24Hours": true,
    "id": "49.689705,6.89999"
  },
  {
    "Gas Station Number": "0FU46",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "HAUPTSTRAßE 100",
    "Postcode": 44894,
    "City": "Bochum",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4807630",
    "Longitude": "007.3239500",
    "Telephone Number": "023441747848",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.480763,7.32395",
    "IsOpen24Hours": true,
    "id": "51.480763,7.32395"
  },
  {
    "Gas Station Number": "0FX62",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A4",
    "Postcode": 2625,
    "City": "Bautzen",
    "State": "Sachsen",
    "Latitude": "051.1931848",
    "Longitude": "014.4185958",
    "Telephone Number": "03593780308",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.1931848,14.4185958",
    "IsOpen24Hours": true,
    "id": "51.1931848,14.4185958"
  },
  {
    "Gas Station Number": "0FM73",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Münchener Straße 300 / It",
    "Postcode": 40589,
    "City": "Düsseldorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1690510",
    "Longitude": "006.8227340",
    "Telephone Number": "021170089550",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.169051,6.822734",
    "IsOpen24Hours": true,
    "id": "51.169051,6.822734"
  },
  {
    "Gas Station Number": "0FY17",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A9",
    "Postcode": 95180,
    "City": "Berg-Rudolphstein",
    "State": "Bayern",
    "Latitude": "050.4042030",
    "Longitude": "011.7735990",
    "Telephone Number": "09293940910",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.404203,11.773599",
    "IsOpen24Hours": true,
    "id": "50.404203,11.773599"
  },
  {
    "Gas Station Number": "0FN15",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Donauwörther Straße 7",
    "Postcode": 86405,
    "City": "Meitingen",
    "State": "Bayern",
    "Latitude": "048.5481030",
    "Longitude": "010.8530670",
    "Telephone Number": "082718132409",
    "Week From": "05:00",
    "Week To": "23:59",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "07:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "48.548103,10.853067",
    "IsOpen24Hours": false,
    "id": "48.548103,10.853067"
  },
  {
    "Gas Station Number": "0FF95",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Lange Göhren",
    "Postcode": 39171,
    "City": "Osterweddingen",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.0525270",
    "Longitude": "011.5890950",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.052527,11.589095",
    "IsOpen24Hours": true,
    "id": "52.052527,11.589095"
  },
  {
    "Gas Station Number": "0FF96",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Lange Göhren",
    "Postcode": 39171,
    "City": "Osterweddingen",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.0512120",
    "Longitude": "011.6005940",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.051212,11.600594",
    "IsOpen24Hours": true,
    "id": "52.051212,11.600594"
  },
  {
    "Gas Station Number": "0FM50",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Königswinterer Straße 106",
    "Postcode": 53639,
    "City": "Königswinter",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.6989080",
    "Longitude": "007.2777070",
    "Telephone Number": "02244876519",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.698908,7.277707",
    "IsOpen24Hours": true,
    "id": "50.698908,7.277707"
  },
  {
    "Gas Station Number": "0FM52",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berghamer Straße 3",
    "Postcode": 85435,
    "City": "Erding",
    "State": "Bayern",
    "Latitude": "048.2766040",
    "Longitude": "011.8991830",
    "Telephone Number": "0812293447",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "06:00",
    "Saturdays To": "20:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.276604,11.899183",
    "IsOpen24Hours": false,
    "id": "48.276604,11.899183"
  },
  {
    "Gas Station Number": "0FF97",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "In der Lehmkuhlenbreite 32",
    "Postcode": 39179,
    "City": "Barleben",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.2055570",
    "Longitude": "011.6065820",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.205557,11.606582",
    "IsOpen24Hours": true,
    "id": "52.205557,11.606582"
  },
  {
    "Gas Station Number": "0FN69",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gartenallee 2",
    "Postcode": 4827,
    "City": "Machern",
    "State": "Sachsen",
    "Latitude": "051.3599010",
    "Longitude": "012.6169760",
    "Telephone Number": "034292869790",
    "Week From": "05:00",
    "Week To": "20:00",
    "Saturdays From": "06:00",
    "Saturdays To": "20:00",
    "Sundays From": "07:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "51.359901,12.616976",
    "IsOpen24Hours": false,
    "id": "51.359901,12.616976"
  },
  {
    "Gas Station Number": "0FN26",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wöllsteiner Straße 11",
    "Postcode": 55599,
    "City": "Gau-Bickelheim",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.8349170",
    "Longitude": "008.0158780",
    "Telephone Number": "06701961510",
    "Week From": "08:00",
    "Week To": "20:00",
    "Saturdays From": "08:00",
    "Saturdays To": "19:00",
    "Sundays From": "08:00",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "49.834917,8.015878",
    "IsOpen24Hours": false,
    "id": "49.834917,8.015878"
  },
  {
    "Gas Station Number": "0FP01",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Zum Autohof 4",
    "Postcode": 32457,
    "City": "Porta Westfalica",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.2095620",
    "Longitude": "008.8722770",
    "Telephone Number": "05731156930",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.209562,8.872277",
    "IsOpen24Hours": true,
    "id": "52.209562,8.872277"
  },
  {
    "Gas Station Number": "0F970",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Escher Straße 273",
    "Postcode": 50739,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9694430",
    "Longitude": "006.9271760",
    "Telephone Number": "0221171612",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.969443,6.927176",
    "IsOpen24Hours": true,
    "id": "50.969443,6.927176"
  },
  {
    "Gas Station Number": "0FU77",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "Kronprinzessinnenweg 120",
    "Postcode": 14129,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4341780",
    "Longitude": "013.1907530",
    "Telephone Number": "0308037233",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.434178,13.190753",
    "IsOpen24Hours": true,
    "id": "52.434178,13.190753"
  },
  {
    "Gas Station Number": "0FG43",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heilbronner Straße 14-15",
    "Postcode": 10711,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5018310",
    "Longitude": "013.2943880",
    "Telephone Number": "03089000949",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "18:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.501831,13.294388",
    "IsOpen24Hours": false,
    "id": "52.501831,13.294388"
  },
  {
    "Gas Station Number": "0FF78",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Hamburger Str. 5",
    "Postcode": 40221,
    "City": "Düsseldorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2150200",
    "Longitude": "006.7387730",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.21502,6.738773",
    "IsOpen24Hours": true,
    "id": "51.21502,6.738773"
  },
  {
    "Gas Station Number": "0FF86",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Königsberger Str.",
    "Postcode": 41460,
    "City": "Neuss",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2152150",
    "Longitude": "006.7035290",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.215215,6.703529",
    "IsOpen24Hours": true,
    "id": "51.215215,6.703529"
  },
  {
    "Gas Station Number": "0FF47",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Im Taubental 50",
    "Postcode": 41468,
    "City": "Neuss",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1697060",
    "Longitude": "006.7471620",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.169706,6.747162",
    "IsOpen24Hours": true,
    "id": "51.169706,6.747162"
  },
  {
    "Gas Station Number": "0FF50",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Nikolaus-Otto-Str.",
    "Postcode": 41515,
    "City": "Grevenbroich",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0856600",
    "Longitude": "006.6188670",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.08566,6.618867",
    "IsOpen24Hours": true,
    "id": "51.08566,6.618867"
  },
  {
    "Gas Station Number": "0FM74",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedberger Landstr. 325",
    "Postcode": 60389,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.1403790",
    "Longitude": "008.6985320",
    "Telephone Number": "06913025453",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.140379,8.698532",
    "IsOpen24Hours": true,
    "id": "50.140379,8.698532"
  },
  {
    "Gas Station Number": "0FR41",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sonnenfeld 2",
    "Postcode": 6766,
    "City": "Bitterfeld",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.6355740",
    "Longitude": "012.1996390",
    "Telephone Number": "0349397570",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.635574,12.199639",
    "IsOpen24Hours": true,
    "id": "51.635574,12.199639"
  },
  {
    "Gas Station Number": "0FO96",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Carl Benz Straße 11",
    "Postcode": 57299,
    "City": "Burbach",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7504000",
    "Longitude": "008.1000610",
    "Telephone Number": "027366891",
    "Week From": "05:30",
    "Week To": "19:00",
    "Saturdays From": "07:00",
    "Saturdays To": "17:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.7504,8.100061",
    "IsOpen24Hours": false,
    "id": "50.7504,8.100061"
  },
  {
    "Gas Station Number": "0FN67",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Zum Apelstein 1",
    "Postcode": 4158,
    "City": "Leipzig / Lindenthal",
    "State": "Sachsen",
    "Latitude": "051.3935510",
    "Longitude": "012.3419640",
    "Telephone Number": "03414618761",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "05:00",
    "Saturdays To": "21:00",
    "Sundays From": "05:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.393551,12.341964",
    "IsOpen24Hours": false,
    "id": "51.393551,12.341964"
  },
  {
    "Gas Station Number": "0FM36",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Zur Oschütz 1",
    "Postcode": 7338,
    "City": "Kaulsdorf",
    "State": "Thüringen",
    "Latitude": "050.6172370",
    "Longitude": "011.4280400",
    "Telephone Number": "03673332630",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.617237,11.42804",
    "IsOpen24Hours": false,
    "id": "50.617237,11.42804"
  },
  {
    "Gas Station Number": "0FM88",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A3",
    "Postcode": 91056,
    "City": "Erlangen",
    "State": "Bayern",
    "Latitude": "049.5803010",
    "Longitude": "010.9300010",
    "Telephone Number": "09131758670",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.580301,10.930001",
    "IsOpen24Hours": true,
    "id": "49.580301,10.930001"
  },
  {
    "Gas Station Number": "0FG57",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Unterhachinger Straße 28a",
    "Postcode": 85521,
    "City": "Ottobrunn",
    "State": "Bayern",
    "Latitude": "048.0631450",
    "Longitude": "011.6520120",
    "Telephone Number": "0896093130",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.063145,11.652012",
    "IsOpen24Hours": false,
    "id": "48.063145,11.652012"
  },
  {
    "Gas Station Number": "0FN71",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Portitzer Straße 71",
    "Postcode": 4425,
    "City": "Taucha",
    "State": "Sachsen",
    "Latitude": "051.3820790",
    "Longitude": "012.4776120",
    "Telephone Number": "03429863646",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "20:00",
    "Sundays From": "06:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "51.382079,12.477612",
    "IsOpen24Hours": false,
    "id": "51.382079,12.477612"
  },
  {
    "Gas Station Number": "0FN03",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lindauer Straße 26",
    "Postcode": 88069,
    "City": "Tettnang",
    "State": "Baden-Württemberg",
    "Latitude": "047.6685180",
    "Longitude": "009.5912570",
    "Telephone Number": "075428456",
    "Week From": "06:30",
    "Week To": "22:00",
    "Saturdays From": "07:30",
    "Saturdays To": "22:00",
    "Sundays From": "07:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.668518,9.591257",
    "IsOpen24Hours": false,
    "id": "47.668518,9.591257"
  },
  {
    "Gas Station Number": "0FV46",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der Autobahn 8",
    "Postcode": 49733,
    "City": "Haren",
    "State": "Niedersachsen",
    "Latitude": "052.7558520",
    "Longitude": "007.1643200",
    "Telephone Number": "059358099050",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.755852,7.16432",
    "IsOpen24Hours": true,
    "id": "52.755852,7.16432"
  },
  {
    "Gas Station Number": "0FH59",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rheinlanddamm 103",
    "Postcode": 44139,
    "City": "Dortmund",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4993530",
    "Longitude": "007.4717200",
    "Telephone Number": "02319128872",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.499353,7.47172",
    "IsOpen24Hours": true,
    "id": "51.499353,7.47172"
  },
  {
    "Gas Station Number": "0FR80",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Holsterfeld 2",
    "Postcode": 48499,
    "City": "Salzbergen",
    "State": "Niedersachsen",
    "Latitude": "052.3267200",
    "Longitude": "007.4294400",
    "Telephone Number": "0597197260",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.32672,7.42944",
    "IsOpen24Hours": true,
    "id": "52.32672,7.42944"
  },
  {
    "Gas Station Number": "0FX57",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A31",
    "Postcode": 49835,
    "City": "Wietmarschen",
    "State": "Niedersachsen",
    "Latitude": "052.5017970",
    "Longitude": "007.2012551",
    "Telephone Number": "05925905968",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.501797,7.2012551",
    "IsOpen24Hours": true,
    "id": "52.501797,7.2012551"
  },
  {
    "Gas Station Number": "0FX54",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 38",
    "Postcode": 6542,
    "City": "Allstedt",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.4293125",
    "Longitude": "011.3938494",
    "Telephone Number": "03465267690",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.4293125,11.3938494",
    "IsOpen24Hours": true,
    "id": "51.4293125,11.3938494"
  },
  {
    "Gas Station Number": "0FV62",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Tiergartenstraße 5",
    "Postcode": 64646,
    "City": "Heppenheim",
    "State": "Hessen",
    "Latitude": "049.6370950",
    "Longitude": "008.6187000",
    "Telephone Number": "06252/9429340",
    "Week From": "08:00",
    "Week To": "22:00",
    "Saturdays From": "08:00",
    "Saturdays To": "22:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.637095,8.6187",
    "IsOpen24Hours": false,
    "id": "49.637095,8.6187"
  },
  {
    "Gas Station Number": "0FP97",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 27",
    "Postcode": 27299,
    "City": "Langwedel",
    "State": "Niedersachsen",
    "Latitude": "052.9994750",
    "Longitude": "009.1818220",
    "Telephone Number": "04232306040",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.999475,9.181822",
    "IsOpen24Hours": true,
    "id": "52.999475,9.181822"
  },
  {
    "Gas Station Number": "0FQ58",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Amandus-Stubbe-Straße 6",
    "Postcode": 22113,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.5057430",
    "Longitude": "010.0896650",
    "Telephone Number": "04082245990",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.505743,10.089665",
    "IsOpen24Hours": true,
    "id": "53.505743,10.089665"
  },
  {
    "Gas Station Number": "0FF83",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Hölker Feld 4",
    "Postcode": 42279,
    "City": "Wuppertal",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2953590",
    "Longitude": "007.2477040",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.295359,7.247704",
    "IsOpen24Hours": true,
    "id": "51.295359,7.247704"
  },
  {
    "Gas Station Number": "0FQ39",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Otto-Perutz-Straße 1-3",
    "Postcode": 81829,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1379328",
    "Longitude": "011.7118510",
    "Telephone Number": "089460990620",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.1379328,11.711851",
    "IsOpen24Hours": false,
    "id": "48.1379328,11.711851"
  },
  {
    "Gas Station Number": "0FS01",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "A 39 Salzgitterhüttenblick Nord",
    "Postcode": 38239,
    "City": "Salzgitter",
    "State": "Niedersachsen",
    "Latitude": "052.1913010",
    "Longitude": "010.3992070",
    "Telephone Number": "053009304490",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.191301,10.399207",
    "IsOpen24Hours": true,
    "id": "52.191301,10.399207"
  },
  {
    "Gas Station Number": "0FT74",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Elseyer Straße 43",
    "Postcode": 58119,
    "City": "Hagen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3641320",
    "Longitude": "007.5533500",
    "Telephone Number": "0233451418",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "08:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "51.364132,7.55335",
    "IsOpen24Hours": false,
    "id": "51.364132,7.55335"
  },
  {
    "Gas Station Number": "0FF57",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Siemensstr. 5",
    "Postcode": 42551,
    "City": "Velbert",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3478620",
    "Longitude": "007.0632370",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.347862,7.063237",
    "IsOpen24Hours": true,
    "id": "51.347862,7.063237"
  },
  {
    "Gas Station Number": "0FM25",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bochumer Straße 1",
    "Postcode": 45663,
    "City": "Recklinghausen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5827870",
    "Longitude": "007.2130930",
    "Telephone Number": "0236134084",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.582787,7.213093",
    "IsOpen24Hours": true,
    "id": "51.582787,7.213093"
  },
  {
    "Gas Station Number": "0FF90",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Dycker Feld",
    "Postcode": 42653,
    "City": "Solingen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2013200",
    "Longitude": "007.0776900",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.20132,7.07769",
    "IsOpen24Hours": true,
    "id": "51.20132,7.07769"
  },
  {
    "Gas Station Number": "0FN98",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Im Seewinkel 26",
    "Postcode": 77652,
    "City": "Offenburg",
    "State": "Baden-Württemberg",
    "Latitude": "048.4815750",
    "Longitude": "007.9368810",
    "Telephone Number": "078193226252",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:30",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.481575,7.936881",
    "IsOpen24Hours": false,
    "id": "48.481575,7.936881"
  },
  {
    "Gas Station Number": "0FN63",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bautzner Landstraße 246",
    "Postcode": 1328,
    "City": "Dresden OT Weißig",
    "State": "Sachsen",
    "Latitude": "051.0603530",
    "Longitude": "013.8747020",
    "Telephone Number": "03512690772",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.060353,13.874702",
    "IsOpen24Hours": false,
    "id": "51.060353,13.874702"
  },
  {
    "Gas Station Number": "0FX59",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A7",
    "Postcode": 34253,
    "City": "Lohfelden",
    "State": "Hessen",
    "Latitude": "051.2774049",
    "Longitude": "009.5318486",
    "Telephone Number": "056158580000",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.2774049,9.5318486",
    "IsOpen24Hours": true,
    "id": "51.2774049,9.5318486"
  },
  {
    "Gas Station Number": "0FV16",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gewerbestraße 1",
    "Postcode": 18233,
    "City": "Neubukow",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "054.0390410",
    "Longitude": "011.6911520",
    "Telephone Number": "03829416840",
    "Week From": "06:00",
    "Week To": "23:59",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "07:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "54.039041,11.691152",
    "IsOpen24Hours": false,
    "id": "54.039041,11.691152"
  },
  {
    "Gas Station Number": "0FV27",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schäferstrasse 51",
    "Postcode": 44147,
    "City": "Dortmund",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5314120",
    "Longitude": "007.4407370",
    "Telephone Number": "023118983645",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "51.531412,7.440737",
    "IsOpen24Hours": false,
    "id": "51.531412,7.440737"
  },
  {
    "Gas Station Number": "0FU88",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A3",
    "Postcode": 63879,
    "City": "Weibersbrunn",
    "State": "Bayern",
    "Latitude": "049.8963650",
    "Longitude": "009.3955830",
    "Telephone Number": "06094",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.896365,9.395583",
    "IsOpen24Hours": true,
    "id": "49.896365,9.395583"
  },
  {
    "Gas Station Number": "0FV63",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Flughafenstraße 7",
    "Postcode": 64347,
    "City": "Griesheim",
    "State": "Hessen",
    "Latitude": "049.8617750",
    "Longitude": "008.5871390",
    "Telephone Number": "061558319360",
    "Week From": "07:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.861775,8.587139",
    "IsOpen24Hours": false,
    "id": "49.861775,8.587139"
  },
  {
    "Gas Station Number": "0FO29",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heisinger Straße 25",
    "Postcode": 87463,
    "City": "Dietmannsried",
    "State": "Bayern",
    "Latitude": "047.8068430",
    "Longitude": "010.2962860",
    "Telephone Number": "083746710",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "47.806843,10.296286",
    "IsOpen24Hours": false,
    "id": "47.806843,10.296286"
  },
  {
    "Gas Station Number": "0FF42",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Dreher Str. 18",
    "Postcode": 42899,
    "City": "Remscheid",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2228350",
    "Longitude": "007.2292290",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.222835,7.229229",
    "IsOpen24Hours": true,
    "id": "51.222835,7.229229"
  },
  {
    "Gas Station Number": "0FN55",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Werner-von-Siemens-Straße 3",
    "Postcode": 83301,
    "City": "Traunreut",
    "State": "Bayern",
    "Latitude": "047.9663210",
    "Longitude": "012.5915630",
    "Telephone Number": "086692368",
    "Week From": "05:30",
    "Week To": "22:30",
    "Saturdays From": "05:30",
    "Saturdays To": "23:59",
    "Sundays From": "06:30",
    "Sundays To": "22:30",
    "24 hour?": "Nein",
    "location": "47.966321,12.591563",
    "IsOpen24Hours": false,
    "id": "47.966321,12.591563"
  },
  {
    "Gas Station Number": "0FN74",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 73",
    "Postcode": 67487,
    "City": "Maikammer",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.3064610",
    "Longitude": "008.1421640",
    "Telephone Number": "063215222",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.306461,8.142164",
    "IsOpen24Hours": false,
    "id": "49.306461,8.142164"
  },
  {
    "Gas Station Number": "0FF77",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Dechenstraße 17",
    "Postcode": 44147,
    "City": "Dortmund",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5198280",
    "Longitude": "007.4400830",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.519828,7.440083",
    "IsOpen24Hours": true,
    "id": "51.519828,7.440083"
  },
  {
    "Gas Station Number": "0FN60",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Beuchaer Straße 73",
    "Postcode": 4821,
    "City": "Brandis",
    "State": "Sachsen",
    "Latitude": "051.3299140",
    "Longitude": "012.5928260",
    "Telephone Number": "03429274221",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.329914,12.592826",
    "IsOpen24Hours": false,
    "id": "51.329914,12.592826"
  },
  {
    "Gas Station Number": "0FS56",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bamenohler Straße 113",
    "Postcode": 57413,
    "City": "Finnentrop",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1684720",
    "Longitude": "007.9770230",
    "Telephone Number": "0272150879",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.168472,7.977023",
    "IsOpen24Hours": false,
    "id": "51.168472,7.977023"
  },
  {
    "Gas Station Number": "0FY18",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A4",
    "Postcode": "02625",
    "City": "Bautzen",
    "State": "Sachsen",
    "Latitude": "051.1986290",
    "Longitude": "014.3282770",
    "Telephone Number": "03593788198",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.198629,14.328277",
    "IsOpen24Hours": true,
    "id": "51.198629,14.328277"
  },
  {
    "Gas Station Number": "0FU02",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Westerwaldstraße 8",
    "Postcode": 56587,
    "City": "Oberhonnefeld-Gierend",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.5607180",
    "Longitude": "007.5273420",
    "Telephone Number": "026349231614",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.560718,7.527342",
    "IsOpen24Hours": false,
    "id": "50.560718,7.527342"
  },
  {
    "Gas Station Number": "0FQ11",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A8",
    "Postcode": 83101,
    "City": "Rohrdorf",
    "State": "Bayern",
    "Latitude": "047.8029570",
    "Longitude": "012.1781360",
    "Telephone Number": "08032956910",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.802957,12.178136",
    "IsOpen24Hours": true,
    "id": "47.802957,12.178136"
  },
  {
    "Gas Station Number": "0FV79",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bonner Straße 70",
    "Postcode": 53919,
    "City": "Weilerswist",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7492100",
    "Longitude": "006.8501800",
    "Telephone Number": "022549690747",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "50.74921,6.85018",
    "IsOpen24Hours": false,
    "id": "50.74921,6.85018"
  },
  {
    "Gas Station Number": "0FM26",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Münchener Straße 32",
    "Postcode": 83629,
    "City": "Weyarn",
    "State": "Bayern",
    "Latitude": "047.8639830",
    "Longitude": "011.7957960",
    "Telephone Number": "08020904680",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.863983,11.795796",
    "IsOpen24Hours": true,
    "id": "47.863983,11.795796"
  },
  {
    "Gas Station Number": "0FQ05",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A7 - Ost",
    "Postcode": 36151,
    "City": "Burghaun",
    "State": "Hessen",
    "Latitude": "050.7129690",
    "Longitude": "009.6625510",
    "Telephone Number": "06653292",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.712969,9.662551",
    "IsOpen24Hours": true,
    "id": "50.712969,9.662551"
  },
  {
    "Gas Station Number": "0FN04",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bodenseestraße 40",
    "Postcode": 87700,
    "City": "Memmingen",
    "State": "Bayern",
    "Latitude": "047.9799690",
    "Longitude": "010.1718860",
    "Telephone Number": "0833171532",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.979969,10.171886",
    "IsOpen24Hours": false,
    "id": "47.979969,10.171886"
  },
  {
    "Gas Station Number": "0FM58",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 2/Ostendorfstraße 60",
    "Postcode": 59069,
    "City": "Hamm",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6323120",
    "Longitude": "007.8739460",
    "Telephone Number": "023852577",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.632312,7.873946",
    "IsOpen24Hours": true,
    "id": "51.632312,7.873946"
  },
  {
    "Gas Station Number": "0FO76",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Günzburger Straße 54",
    "Postcode": 89264,
    "City": "Weißenhorn",
    "State": "Bayern",
    "Latitude": "048.3118290",
    "Longitude": "010.1578520",
    "Telephone Number": "07309969820",
    "Week From": "06:30",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:30",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.311829,10.157852",
    "IsOpen24Hours": false,
    "id": "48.311829,10.157852"
  },
  {
    "Gas Station Number": "0FV34",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Blaubeurer Straße 21",
    "Postcode": 89077,
    "City": "Ulm",
    "State": "Baden-Württemberg",
    "Latitude": "048.4016220",
    "Longitude": "009.9755660",
    "Telephone Number": "07313988710",
    "Week From": "09:00",
    "Week To": "20:00",
    "Saturdays From": "09:00",
    "Saturdays To": "20:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.401622,9.975566",
    "IsOpen24Hours": false,
    "id": "48.401622,9.975566"
  },
  {
    "Gas Station Number": "0FO86",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kölner Straße 112",
    "Postcode": 57368,
    "City": "Lennestadt-Grevenbrü",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1429290",
    "Longitude": "008.0178140",
    "Telephone Number": "02721718258",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:30",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.142929,8.017814",
    "IsOpen24Hours": false,
    "id": "51.142929,8.017814"
  },
  {
    "Gas Station Number": "0FN45",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Amselstraße 50",
    "Postcode": 75180,
    "City": "Pforzheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.8617900",
    "Longitude": "008.6619480",
    "Telephone Number": "0723171240",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.86179,8.661948",
    "IsOpen24Hours": false,
    "id": "48.86179,8.661948"
  },
  {
    "Gas Station Number": "0FX99",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bötzinger Straße 19",
    "Postcode": 79111,
    "City": "Freiburg im Breisgau",
    "State": "Baden-Württemberg",
    "Latitude": "047.9918289",
    "Longitude": "007.7890291",
    "Telephone Number": "0761/21720504",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.9918289,7.7890291",
    "IsOpen24Hours": true,
    "id": "47.9918289,7.7890291"
  },
  {
    "Gas Station Number": "0FP68",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kronprinzenstraße 23",
    "Postcode": 57250,
    "City": "Netphen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9152350",
    "Longitude": "008.0961350",
    "Telephone Number": "027381263",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "08:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.915235,8.096135",
    "IsOpen24Hours": false,
    "id": "50.915235,8.096135"
  },
  {
    "Gas Station Number": "0FX90",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Egelseestraße 33",
    "Postcode": 88339,
    "City": "Bad Waldsee",
    "State": "Baden-Württemberg",
    "Latitude": "047.8776300",
    "Longitude": "009.7046200",
    "Telephone Number": "075244644900",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Ja",
    "location": "47.87763,9.70462",
    "IsOpen24Hours": true,
    "id": "47.87763,9.70462"
  },
  {
    "Gas Station Number": "0FQ19",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 2",
    "Postcode": 38350,
    "City": "Helmstedt",
    "State": "Niedersachsen",
    "Latitude": "052.2202120",
    "Longitude": "011.0583350",
    "Telephone Number": "053512077",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.220212,11.058335",
    "IsOpen24Hours": true,
    "id": "52.220212,11.058335"
  },
  {
    "Gas Station Number": "0FF55",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Niedersachsenweg/Unterste",
    "Postcode": 44309,
    "City": "Dortmund",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5290890",
    "Longitude": "007.5276600",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.529089,7.52766",
    "IsOpen24Hours": true,
    "id": "51.529089,7.52766"
  },
  {
    "Gas Station Number": "0FY19",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 57",
    "Postcode": 47652,
    "City": "Weeze",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6532600",
    "Longitude": "006.1984100",
    "Telephone Number": "02837664727",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.65326,6.19841",
    "IsOpen24Hours": true,
    "id": "51.65326,6.19841"
  },
  {
    "Gas Station Number": "0FM20",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Landsberger Straße 38a",
    "Postcode": 86179,
    "City": "Augsburg",
    "State": "Bayern",
    "Latitude": "048.3123760",
    "Longitude": "010.9078570",
    "Telephone Number": "08218150158",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.312376,10.907857",
    "IsOpen24Hours": false,
    "id": "48.312376,10.907857"
  },
  {
    "Gas Station Number": "0FF84",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Gernotstr. 3",
    "Postcode": 44319,
    "City": "Dortmund",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5320550",
    "Longitude": "007.6286900",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.532055,7.62869",
    "IsOpen24Hours": true,
    "id": "51.532055,7.62869"
  },
  {
    "Gas Station Number": "0FG37",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Geisental",
    "Postcode": 44791,
    "City": "Bochum",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4977120",
    "Longitude": "007.2606800",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.497712,7.26068",
    "IsOpen24Hours": true,
    "id": "51.497712,7.26068"
  },
  {
    "Gas Station Number": "0FM35",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Jenaische Straße 103c",
    "Postcode": 7407,
    "City": "Rudolstadt",
    "State": "Thüringen",
    "Latitude": "050.7271960",
    "Longitude": "011.3643090",
    "Telephone Number": "03672424471",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.727196,11.364309",
    "IsOpen24Hours": false,
    "id": "50.727196,11.364309"
  },
  {
    "Gas Station Number": "0FO68",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schomburger Straße 1",
    "Postcode": 88279,
    "City": "Amtzell",
    "State": "Baden-Württemberg",
    "Latitude": "047.6953720",
    "Longitude": "009.7589860",
    "Telephone Number": "075206126",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.695372,9.758986",
    "IsOpen24Hours": false,
    "id": "47.695372,9.758986"
  },
  {
    "Gas Station Number": "0FG25",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Stader Straße 30",
    "Postcode": 27419,
    "City": "Sittensen",
    "State": "Niedersachsen",
    "Latitude": "053.2885590",
    "Longitude": "009.5077400",
    "Telephone Number": "04282592874",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.288559,9.50774",
    "IsOpen24Hours": true,
    "id": "53.288559,9.50774"
  },
  {
    "Gas Station Number": "0FU79",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A3",
    "Postcode": 56307,
    "City": "Dernbach",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.5479780",
    "Longitude": "007.5852300",
    "Telephone Number": "02689979680",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.547978,7.58523",
    "IsOpen24Hours": true,
    "id": "50.547978,7.58523"
  },
  {
    "Gas Station Number": "0FF40",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Rombacher Hütte 6-10",
    "Postcode": 44795,
    "City": "Bochum",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4655850",
    "Longitude": "007.1927020",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.465585,7.192702",
    "IsOpen24Hours": true,
    "id": "51.465585,7.192702"
  },
  {
    "Gas Station Number": "0FG60",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lützner Straße 535",
    "Postcode": "04205",
    "City": "Leipzig",
    "State": "Sachsen",
    "Latitude": "051.3173360",
    "Longitude": "012.2706070",
    "Telephone Number": "03414225520",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.317336,12.270607",
    "IsOpen24Hours": true,
    "id": "51.317336,12.270607"
  },
  {
    "Gas Station Number": "0FQ06",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A5",
    "Postcode": 77972,
    "City": "Mahlberg / Baden",
    "State": "Baden-Württemberg",
    "Latitude": "048.3094740",
    "Longitude": "007.7891560",
    "Telephone Number": "07825877611",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.309474,7.789156",
    "IsOpen24Hours": true,
    "id": "48.309474,7.789156"
  },
  {
    "Gas Station Number": "0FN81",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stettener Straße 112",
    "Postcode": 73732,
    "City": "Esslingen-Wäldenbron",
    "State": "Baden-Württemberg",
    "Latitude": "048.7594010",
    "Longitude": "009.3173810",
    "Telephone Number": "07119385399",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.759401,9.317381",
    "IsOpen24Hours": false,
    "id": "48.759401,9.317381"
  },
  {
    "Gas Station Number": "0FO11",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Siegener Straße 58",
    "Postcode": 57610,
    "City": "Altenkirchen",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.6934030",
    "Longitude": "007.6543540",
    "Telephone Number": "026815186",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "08:30",
    "Saturdays To": "19:00",
    "Sundays From": "09:30",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "50.693403,7.654354",
    "IsOpen24Hours": false,
    "id": "50.693403,7.654354"
  },
  {
    "Gas Station Number": "0FG55",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Zielstattstraße 8a",
    "Postcode": 81379,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1032440",
    "Longitude": "011.5334750",
    "Telephone Number": "089788232",
    "Week From": "07:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "48.103244,11.533475",
    "IsOpen24Hours": false,
    "id": "48.103244,11.533475"
  },
  {
    "Gas Station Number": "0FN75",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedberger Straße 47 1/4",
    "Postcode": 86161,
    "City": "Augsburg",
    "State": "Bayern",
    "Latitude": "048.3556280",
    "Longitude": "010.9190020",
    "Telephone Number": "0821551677",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.355628,10.919002",
    "IsOpen24Hours": true,
    "id": "48.355628,10.919002"
  },
  {
    "Gas Station Number": "0FQ55",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Titiseestraße 26",
    "Postcode": 79822,
    "City": "Titisee-Neustadt",
    "State": "Baden-Württemberg",
    "Latitude": "047.9181800",
    "Longitude": "008.2069300",
    "Telephone Number": "076519361997",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.91818,8.20693",
    "IsOpen24Hours": true,
    "id": "47.91818,8.20693"
  },
  {
    "Gas Station Number": "0FS86",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Trochtelfinger Straße 38",
    "Postcode": 72829,
    "City": "Engstingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.3832717",
    "Longitude": "009.2827525",
    "Telephone Number": "071299362420",
    "Week From": "06:00",
    "Week To": "20:30",
    "Saturdays From": "07:30",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "48.3832717,9.2827525",
    "IsOpen24Hours": false,
    "id": "48.3832717,9.2827525"
  },
  {
    "Gas Station Number": "0FN28",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alzeyer Straße 71",
    "Postcode": 67549,
    "City": "Worms",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.6322920",
    "Longitude": "008.3419610",
    "Telephone Number": "06241209415",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.632292,8.341961",
    "IsOpen24Hours": false,
    "id": "49.632292,8.341961"
  },
  {
    "Gas Station Number": "0FM28",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Knorrstr. 24",
    "Postcode": 34134,
    "City": "Kassel",
    "State": "Hessen",
    "Latitude": "051.2891840",
    "Longitude": "009.4746620",
    "Telephone Number": "05619413797",
    "Week From": "07:00",
    "Week To": "22:00",
    "Saturdays From": "07:30",
    "Saturdays To": "20:30",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.289184,9.474662",
    "IsOpen24Hours": false,
    "id": "51.289184,9.474662"
  },
  {
    "Gas Station Number": "0F038",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sonnenallee 113",
    "Postcode": 12045,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4814790",
    "Longitude": "013.4413700",
    "Telephone Number": "0306811313",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.481479,13.44137",
    "IsOpen24Hours": true,
    "id": "52.481479,13.44137"
  },
  {
    "Gas Station Number": "0FP02",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Lachbach 1",
    "Postcode": 63546,
    "City": "Hammersbach",
    "State": "Hessen",
    "Latitude": "050.2430660",
    "Longitude": "008.9979570",
    "Telephone Number": "06185893027",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.243066,8.997957",
    "IsOpen24Hours": true,
    "id": "50.243066,8.997957"
  },
  {
    "Gas Station Number": "0FV23",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A38, Kirchworbiser Wiesen 2",
    "Postcode": 37339,
    "City": "Kirchworbis",
    "State": "Thüringen",
    "Latitude": "051.4027860",
    "Longitude": "010.4146470",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.402786,10.414647",
    "IsOpen24Hours": false,
    "id": "51.402786,10.414647"
  },
  {
    "Gas Station Number": "0FO48",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 57",
    "Postcode": 52388,
    "City": "Nörvenich",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8097330",
    "Longitude": "006.6366350",
    "Telephone Number": "02426901818",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.809733,6.636635",
    "IsOpen24Hours": false,
    "id": "50.809733,6.636635"
  },
  {
    "Gas Station Number": "0FN68",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Göteborger Straße 12 a",
    "Postcode": 6686,
    "City": "Lützen",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.2517340",
    "Longitude": "012.1378860",
    "Telephone Number": "03444420030",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.251734,12.137886",
    "IsOpen24Hours": true,
    "id": "51.251734,12.137886"
  },
  {
    "Gas Station Number": "0FR72",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Im Schiffels 1",
    "Postcode": 55491,
    "City": "Büchenbeuren",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.9272600",
    "Longitude": "007.2795840",
    "Telephone Number": "065435005438",
    "Week From": "05:00",
    "Week To": "23:59",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "06:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "49.92726,7.279584",
    "IsOpen24Hours": false,
    "id": "49.92726,7.279584"
  },
  {
    "Gas Station Number": "0FN11",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Donauwörther Straße 62",
    "Postcode": 86633,
    "City": "Neuburg",
    "State": "Bayern",
    "Latitude": "048.7335580",
    "Longitude": "011.1671110",
    "Telephone Number": "084312790",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.733558,11.167111",
    "IsOpen24Hours": false,
    "id": "48.733558,11.167111"
  },
  {
    "Gas Station Number": "0FF56",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Burggrafenstraße",
    "Postcode": 45139,
    "City": "Essen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4621670",
    "Longitude": "007.0301210",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.462167,7.030121",
    "IsOpen24Hours": true,
    "id": "51.462167,7.030121"
  },
  {
    "Gas Station Number": "0FF91",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Pferdebahnstr. 40",
    "Postcode": 45141,
    "City": "Essen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4657850",
    "Longitude": "006.9919100",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.465785,6.99191",
    "IsOpen24Hours": true,
    "id": "51.465785,6.99191"
  },
  {
    "Gas Station Number": "0FV73",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bremer Straße 32",
    "Postcode": 27367,
    "City": "Sottrum",
    "State": "Niedersachsen",
    "Latitude": "053.1136450",
    "Longitude": "009.2313250",
    "Telephone Number": "042648366330",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.113645,9.231325",
    "IsOpen24Hours": false,
    "id": "53.113645,9.231325"
  },
  {
    "Gas Station Number": "0FP71",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "An der B 7",
    "Postcode": 7580,
    "City": "Ronneburg",
    "State": "Thüringen",
    "Latitude": "050.8687250",
    "Longitude": "012.2161670",
    "Telephone Number": "036602519387",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.868725,12.216167",
    "IsOpen24Hours": true,
    "id": "50.868725,12.216167"
  },
  {
    "Gas Station Number": "0FO75",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hirschorner Straße 41",
    "Postcode": 69239,
    "City": "Neckarsteinach",
    "State": "Hessen",
    "Latitude": "049.3990820",
    "Longitude": "008.8455760",
    "Telephone Number": "06229930635",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.399082,8.845576",
    "IsOpen24Hours": false,
    "id": "49.399082,8.845576"
  },
  {
    "Gas Station Number": "0FV18",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Flurgraben 1-11",
    "Postcode": 65462,
    "City": "Ginsheim-Gustavsburg",
    "State": "Hessen",
    "Latitude": "049.9875330",
    "Longitude": "008.3277490",
    "Telephone Number": "061345678996",
    "Week From": "04:30",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "49.987533,8.327749",
    "IsOpen24Hours": false,
    "id": "49.987533,8.327749"
  },
  {
    "Gas Station Number": "0FF39",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Daniel-Eckhard-Str. 8",
    "Postcode": 45356,
    "City": "Essen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4998620",
    "Longitude": "006.9813550",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.499862,6.981355",
    "IsOpen24Hours": true,
    "id": "51.499862,6.981355"
  },
  {
    "Gas Station Number": "0FN77",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Göttinger Straße 45",
    "Postcode": 37120,
    "City": "Bovenden",
    "State": "Niedersachsen",
    "Latitude": "051.5865680",
    "Longitude": "009.9265900",
    "Telephone Number": "05518768",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.586568,9.92659",
    "IsOpen24Hours": false,
    "id": "51.586568,9.92659"
  },
  {
    "Gas Station Number": "0FC79",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stettiner Chaussee 5",
    "Postcode": 17309,
    "City": "Pasewalk",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.5033410",
    "Longitude": "014.0003960",
    "Telephone Number": "03973214214",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.503341,14.000396",
    "IsOpen24Hours": false,
    "id": "53.503341,14.000396"
  },
  {
    "Gas Station Number": "0FU86",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A7",
    "Postcode": 37124,
    "City": "Rosdorf",
    "State": "Niedersachsen",
    "Latitude": "051.4893340",
    "Longitude": "009.8776290",
    "Telephone Number": "0550992500",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.489334,9.877629",
    "IsOpen24Hours": true,
    "id": "51.489334,9.877629"
  },
  {
    "Gas Station Number": "0FP05",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Eppelheimer Straße 78",
    "Postcode": 69123,
    "City": "Heidelberg",
    "State": "Baden-Württemberg",
    "Latitude": "049.4034310",
    "Longitude": "008.6461970",
    "Telephone Number": "062217569",
    "Week From": "07:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "09:00",
    "Sundays To": "18:00",
    "24 hour?": "Nein",
    "location": "49.403431,8.646197",
    "IsOpen24Hours": false,
    "id": "49.403431,8.646197"
  },
  {
    "Gas Station Number": "0FF51",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Im Emscherbruch 10",
    "Postcode": 45699,
    "City": "Herten",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5544330",
    "Longitude": "007.1634810",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.554433,7.163481",
    "IsOpen24Hours": true,
    "id": "51.554433,7.163481"
  },
  {
    "Gas Station Number": "0FV52",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rosenheimer Landstraße 26",
    "Postcode": 85521,
    "City": "Ottobrunn",
    "State": "Bayern",
    "Latitude": "048.0685060",
    "Longitude": "011.6579630",
    "Telephone Number": "08960850875",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.068506,11.657963",
    "IsOpen24Hours": false,
    "id": "48.068506,11.657963"
  },
  {
    "Gas Station Number": "0FO59",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Boltensternstraße 350",
    "Postcode": 50735,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9805050",
    "Longitude": "006.9690380",
    "Telephone Number": "02219775353",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.980505,6.969038",
    "IsOpen24Hours": true,
    "id": "50.980505,6.969038"
  },
  {
    "Gas Station Number": "0FM60",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Königsteiner Straße 23",
    "Postcode": 65812,
    "City": "Bad Soden",
    "State": "Hessen",
    "Latitude": "050.1402400",
    "Longitude": "008.5050940",
    "Telephone Number": "06196671777",
    "Week From": "05:00",
    "Week To": "23:59",
    "Saturdays From": "05:00",
    "Saturdays To": "23:59",
    "Sundays From": "05:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "50.14024,8.505094",
    "IsOpen24Hours": false,
    "id": "50.14024,8.505094"
  },
  {
    "Gas Station Number": "0F989",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedr.-Engels-Allee 237",
    "Postcode": 42285,
    "City": "Wuppertal",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2634290",
    "Longitude": "007.1777950",
    "Telephone Number": "020281457",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.263429,7.177795",
    "IsOpen24Hours": true,
    "id": "51.263429,7.177795"
  },
  {
    "Gas Station Number": "0FN72",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Greizer Straße 1",
    "Postcode": 8412,
    "City": "Werdau",
    "State": "Sachsen",
    "Latitude": "050.7235010",
    "Longitude": "012.3742330",
    "Telephone Number": "037615209",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.723501,12.374233",
    "IsOpen24Hours": false,
    "id": "50.723501,12.374233"
  },
  {
    "Gas Station Number": "0FO69",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 88",
    "Postcode": 88074,
    "City": "Meckenbeuren",
    "State": "Baden-Württemberg",
    "Latitude": "047.6933770",
    "Longitude": "009.5595510",
    "Telephone Number": "075424251",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "08:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.693377,9.559551",
    "IsOpen24Hours": false,
    "id": "47.693377,9.559551"
  },
  {
    "Gas Station Number": "0FF49",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Am Kruppwald",
    "Postcode": 46238,
    "City": "Bottrop",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5094570",
    "Longitude": "006.9556180",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.509457,6.955618",
    "IsOpen24Hours": true,
    "id": "51.509457,6.955618"
  },
  {
    "Gas Station Number": "0FO72",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nußdorfer Straße 101",
    "Postcode": 88662,
    "City": "Überlingen",
    "State": "Baden-Württemberg",
    "Latitude": "047.7540910",
    "Longitude": "009.1901970",
    "Telephone Number": "075513550",
    "Week From": "07:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.754091,9.190197",
    "IsOpen24Hours": false,
    "id": "47.754091,9.190197"
  },
  {
    "Gas Station Number": "0FP96",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A29",
    "Postcode": 26203,
    "City": "Wardenburg",
    "State": "Niedersachsen",
    "Latitude": "053.0409520",
    "Longitude": "008.2208620",
    "Telephone Number": "0440791640",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.040952,8.220862",
    "IsOpen24Hours": true,
    "id": "53.040952,8.220862"
  },
  {
    "Gas Station Number": "0FN54",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ulmer Straße 13",
    "Postcode": 89340,
    "City": "Leipheim",
    "State": "Bayern",
    "Latitude": "048.4458830",
    "Longitude": "010.2125400",
    "Telephone Number": "0822171065",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.445883,10.21254",
    "IsOpen24Hours": false,
    "id": "48.445883,10.21254"
  },
  {
    "Gas Station Number": "0FO93",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Raiffeisenstraße 17",
    "Postcode": 56594,
    "City": "Willroth",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.5659680",
    "Longitude": "007.5265350",
    "Telephone Number": "02687929100",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.565968,7.526535",
    "IsOpen24Hours": true,
    "id": "50.565968,7.526535"
  },
  {
    "Gas Station Number": "0FV96",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Harburger Straße 39",
    "Postcode": 27383,
    "City": "Scheeßel",
    "State": "Niedersachsen",
    "Latitude": "053.1756100",
    "Longitude": "009.4933400",
    "Telephone Number": "",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "05:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.17561,9.49334",
    "IsOpen24Hours": false,
    "id": "53.17561,9.49334"
  },
  {
    "Gas Station Number": "0FN57",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Riedlinger Straße 67",
    "Postcode": 88422,
    "City": "Bad Buchau",
    "State": "Baden-Württemberg",
    "Latitude": "048.0598960",
    "Longitude": "009.5945750",
    "Telephone Number": "0758291015",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "07:30",
    "Saturdays To": "20:00",
    "Sundays From": "09:30",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "48.059896,9.594575",
    "IsOpen24Hours": false,
    "id": "48.059896,9.594575"
  },
  {
    "Gas Station Number": "0FF41",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Am Schornacker 47",
    "Postcode": 46485,
    "City": "Wesel",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6619990",
    "Longitude": "006.7014070",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.661999,6.701407",
    "IsOpen24Hours": true,
    "id": "51.661999,6.701407"
  },
  {
    "Gas Station Number": "0FJ54",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Münchener Straße 400",
    "Postcode": 90471,
    "City": "Nürnberg",
    "State": "Bayern",
    "Latitude": "049.4020820",
    "Longitude": "011.1148330",
    "Telephone Number": "0911805503",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.402082,11.114833",
    "IsOpen24Hours": true,
    "id": "49.402082,11.114833"
  },
  {
    "Gas Station Number": "0FP06",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kientenstraße 6",
    "Postcode": 72458,
    "City": "Albstadt-Ebingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.2099770",
    "Longitude": "009.0187050",
    "Telephone Number": "0743158816",
    "Week From": "07:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.209977,9.018705",
    "IsOpen24Hours": false,
    "id": "48.209977,9.018705"
  },
  {
    "Gas Station Number": "0FG59",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Evinger Straße 156",
    "Postcode": 44339,
    "City": "Dortmund",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5462770",
    "Longitude": "007.4603790",
    "Telephone Number": "02318091592",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.546277,7.460379",
    "IsOpen24Hours": true,
    "id": "51.546277,7.460379"
  },
  {
    "Gas Station Number": "0FO53",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 51",
    "Postcode": 86470,
    "City": "Thannhausen",
    "State": "Bayern",
    "Latitude": "048.2861520",
    "Longitude": "010.4676770",
    "Telephone Number": "082814763",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.286152,10.467677",
    "IsOpen24Hours": false,
    "id": "48.286152,10.467677"
  },
  {
    "Gas Station Number": "0FM71",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Max-Holder-Straße 2",
    "Postcode": 60437,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.1892060",
    "Longitude": "008.6514280",
    "Telephone Number": "06995091947",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.189206,8.651428",
    "IsOpen24Hours": true,
    "id": "50.189206,8.651428"
  },
  {
    "Gas Station Number": "0FM22",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Augsburger Straße 38",
    "Postcode": 86899,
    "City": "Landsberg",
    "State": "Bayern",
    "Latitude": "048.0590290",
    "Longitude": "010.8680680",
    "Telephone Number": "0819159655",
    "Week From": "05:15",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.059029,10.868068",
    "IsOpen24Hours": false,
    "id": "48.059029,10.868068"
  },
  {
    "Gas Station Number": "0FM91",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neuenkirchener Straße  44",
    "Postcode": 48431,
    "City": "Rheine",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.2792820",
    "Longitude": "007.4293300",
    "Telephone Number": "059713626",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "08:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "52.279282,7.42933",
    "IsOpen24Hours": false,
    "id": "52.279282,7.42933"
  },
  {
    "Gas Station Number": "0FS88",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "A 39 Salzgitterhüttenblick Süd",
    "Postcode": 38239,
    "City": "Salzgitter",
    "State": "Niedersachsen",
    "Latitude": "052.1888970",
    "Longitude": "010.3962150",
    "Telephone Number": "053009338093",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.188897,10.396215",
    "IsOpen24Hours": true,
    "id": "52.188897,10.396215"
  },
  {
    "Gas Station Number": "0FQ69",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am halben Weg 2",
    "Postcode": 91301,
    "City": "Forchheim",
    "State": "Bayern",
    "Latitude": "049.6983830",
    "Longitude": "011.0649749",
    "Telephone Number": "091916219620",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.698383,11.0649749",
    "IsOpen24Hours": true,
    "id": "49.698383,11.0649749"
  },
  {
    "Gas Station Number": "0FT43",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Eugen-Sänger-Ring 1",
    "Postcode": 85649,
    "City": "Brunnthal",
    "State": "Bayern",
    "Latitude": "048.0430350",
    "Longitude": "011.6643180",
    "Telephone Number": "08960807989",
    "Week From": "06:00",
    "Week To": "22:30",
    "Saturdays From": "06:00",
    "Saturdays To": "22:30",
    "Sundays From": "08:00",
    "Sundays To": "22:30",
    "24 hour?": "Nein",
    "location": "48.043035,11.664318",
    "IsOpen24Hours": false,
    "id": "48.043035,11.664318"
  },
  {
    "Gas Station Number": "0FX56",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A8",
    "Postcode": 86368,
    "City": "Gersthofen-Edenberge",
    "State": "Bayern",
    "Latitude": "048.4249832",
    "Longitude": "010.8081220",
    "Telephone Number": "082148685416",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.4249832,10.808122",
    "IsOpen24Hours": true,
    "id": "48.4249832,10.808122"
  },
  {
    "Gas Station Number": "0FN12",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Günzburger Straße 63",
    "Postcode": 89423,
    "City": "Gundelfingen",
    "State": "Bayern",
    "Latitude": "048.5439050",
    "Longitude": "010.3675340",
    "Telephone Number": "09073800590",
    "Week From": "06:45",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "48.543905,10.367534",
    "IsOpen24Hours": false,
    "id": "48.543905,10.367534"
  },
  {
    "Gas Station Number": "0FO60",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Altenberger Straße 93",
    "Postcode": 42929,
    "City": "Wermelskirchen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0911550",
    "Longitude": "007.1868710",
    "Telephone Number": "021934691",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "10:00",
    "Sundays To": "18:00",
    "24 hour?": "Nein",
    "location": "51.091155,7.186871",
    "IsOpen24Hours": false,
    "id": "51.091155,7.186871"
  },
  {
    "Gas Station Number": "0FU15",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Oberwerrner Weg 10",
    "Postcode": 97502,
    "City": "Euerbach",
    "State": "Bayern",
    "Latitude": "050.0628460",
    "Longitude": "010.1445270",
    "Telephone Number": "09726/9052785",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.062846,10.144527",
    "IsOpen24Hours": true,
    "id": "50.062846,10.144527"
  },
  {
    "Gas Station Number": "0FV38",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Zeppelinstraße 14 - 16",
    "Postcode": 88239,
    "City": "Wangen",
    "State": "Baden-Württemberg",
    "Latitude": "047.6901810",
    "Longitude": "009.8288760",
    "Telephone Number": "07522707464",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "06:00",
    "Saturdays To": "20:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.690181,9.828876",
    "IsOpen24Hours": false,
    "id": "47.690181,9.828876"
  },
  {
    "Gas Station Number": "0FU96",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bernburger Str. 4a",
    "Postcode": 39439,
    "City": "Güsten",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.8015570",
    "Longitude": "011.6208350",
    "Telephone Number": "03926260005",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.801557,11.620835",
    "IsOpen24Hours": true,
    "id": "51.801557,11.620835"
  },
  {
    "Gas Station Number": "0FP80",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Otto-Hahn-Strasse 3",
    "Postcode": 46569,
    "City": "Hünxe",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6309950",
    "Longitude": "006.6902820",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.630995,6.690282",
    "IsOpen24Hours": true,
    "id": "51.630995,6.690282"
  },
  {
    "Gas Station Number": "0FQ17",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A5",
    "Postcode": 61239,
    "City": "Ober-Mörlen",
    "State": "Hessen",
    "Latitude": "050.3556600",
    "Longitude": "008.6945600",
    "Telephone Number": "060027915",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.35566,8.69456",
    "IsOpen24Hours": true,
    "id": "50.35566,8.69456"
  },
  {
    "Gas Station Number": "0FP11",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rhein-Taunus-Straße 52",
    "Postcode": 56340,
    "City": "Dachsenhausen",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.2535510",
    "Longitude": "007.7331140",
    "Telephone Number": "067761356",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.253551,7.733114",
    "IsOpen24Hours": false,
    "id": "50.253551,7.733114"
  },
  {
    "Gas Station Number": "0FO88",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Saynstraße 44",
    "Postcode": 57627,
    "City": "Hachenburg",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.6671750",
    "Longitude": "007.8088670",
    "Telephone Number": "02662939200",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.667175,7.808867",
    "IsOpen24Hours": false,
    "id": "50.667175,7.808867"
  },
  {
    "Gas Station Number": "0FG11",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wegelnburgstraße 27",
    "Postcode": 67065,
    "City": "Ludwigshafen",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.4571060",
    "Longitude": "008.4318210",
    "Telephone Number": "062157240520",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.457106,8.431821",
    "IsOpen24Hours": true,
    "id": "49.457106,8.431821"
  },
  {
    "Gas Station Number": "0FE99",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "An der Autobahn 1",
    "Postcode": 95512,
    "City": "Neudrossenfeld",
    "State": "Bayern",
    "Latitude": "050.0373470",
    "Longitude": "011.4906330",
    "Telephone Number": "09203688996",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.037347,11.490633",
    "IsOpen24Hours": true,
    "id": "50.037347,11.490633"
  },
  {
    "Gas Station Number": "0FN96",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 71",
    "Postcode": 97638,
    "City": "Mellrichstadt",
    "State": "Bayern",
    "Latitude": "050.4236020",
    "Longitude": "010.3518440",
    "Telephone Number": "097767094943",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.423602,10.351844",
    "IsOpen24Hours": true,
    "id": "50.423602,10.351844"
  },
  {
    "Gas Station Number": "0FS23",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Engesserstraße 13",
    "Postcode": 79108,
    "City": "Freiburg im Breisgau",
    "State": "Baden-Württemberg",
    "Latitude": "048.0186701",
    "Longitude": "007.8422270",
    "Telephone Number": "076168002380",
    "Week From": "04:30",
    "Week To": "23:00",
    "Saturdays From": "05:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "48.0186701,7.842227",
    "IsOpen24Hours": false,
    "id": "48.0186701,7.842227"
  },
  {
    "Gas Station Number": "0FO95",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Wetzlarer Straße 32",
    "Postcode": 57250,
    "City": "Netphen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8620370",
    "Longitude": "008.2001720",
    "Telephone Number": "027372185959",
    "Week From": "07:00",
    "Week To": "19:00",
    "Saturdays From": "08:00",
    "Saturdays To": "18:00",
    "Sundays From": "09:00",
    "Sundays To": "18:00",
    "24 hour?": "Nein",
    "location": "50.862037,8.200172",
    "IsOpen24Hours": false,
    "id": "50.862037,8.200172"
  },
  {
    "Gas Station Number": "0FP58",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 62",
    "Postcode": 35428,
    "City": "Langgöns-Oberkleen",
    "State": "Hessen",
    "Latitude": "050.4616720",
    "Longitude": "008.5846460",
    "Telephone Number": "06447887360",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.461672,8.584646",
    "IsOpen24Hours": false,
    "id": "50.461672,8.584646"
  },
  {
    "Gas Station Number": "0FP70",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bamberger Straße 27",
    "Postcode": 96328,
    "City": "Küps",
    "State": "Bayern",
    "Latitude": "050.1916160",
    "Longitude": "011.2681190",
    "Telephone Number": "0926480269",
    "Week From": "07:00",
    "Week To": "19:00",
    "Saturdays From": "08:00",
    "Saturdays To": "18:00",
    "Sundays From": "09:00",
    "Sundays To": "18:00",
    "24 hour?": "Nein",
    "location": "50.191616,11.268119",
    "IsOpen24Hours": false,
    "id": "50.191616,11.268119"
  },
  {
    "Gas Station Number": "0FP67",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Aiterhofener Straße 215",
    "Postcode": 94315,
    "City": "Straubing",
    "State": "Bayern",
    "Latitude": "048.8728910",
    "Longitude": "012.6316750",
    "Telephone Number": "094211862815",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.872891,12.631675",
    "IsOpen24Hours": true,
    "id": "48.872891,12.631675"
  },
  {
    "Gas Station Number": "0FN66",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dieskaustraße 483 a",
    "Postcode": 4249,
    "City": "Leipzig",
    "State": "Sachsen",
    "Latitude": "051.2728110",
    "Longitude": "012.3083770",
    "Telephone Number": "03414240640",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.272811,12.308377",
    "IsOpen24Hours": false,
    "id": "51.272811,12.308377"
  },
  {
    "Gas Station Number": "0FO97",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schützstraße 2",
    "Postcode": 56242,
    "City": "Selters",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.5376260",
    "Longitude": "007.7595100",
    "Telephone Number": "02626921605",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "05:00",
    "Saturdays To": "23:00",
    "Sundays From": "07:30",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "50.537626,7.75951",
    "IsOpen24Hours": false,
    "id": "50.537626,7.75951"
  },
  {
    "Gas Station Number": "0FF75",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Am Nienhaushof 18",
    "Postcode": 47139,
    "City": "Duisburg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4772640",
    "Longitude": "006.7195090",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.477264,6.719509",
    "IsOpen24Hours": true,
    "id": "51.477264,6.719509"
  },
  {
    "Gas Station Number": "0FM63",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 7",
    "Postcode": 38723,
    "City": "Seesen",
    "State": "Niedersachsen",
    "Latitude": "051.9255880",
    "Longitude": "010.1429020",
    "Telephone Number": "05384969800",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.925588,10.142902",
    "IsOpen24Hours": true,
    "id": "51.925588,10.142902"
  },
  {
    "Gas Station Number": "0FO04",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Traunwalchner Straße 12",
    "Postcode": 83301,
    "City": "Traunreut",
    "State": "Bayern",
    "Latitude": "047.9570430",
    "Longitude": "012.5962480",
    "Telephone Number": "086697897373",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.957043,12.596248",
    "IsOpen24Hours": false,
    "id": "47.957043,12.596248"
  },
  {
    "Gas Station Number": "0FP93",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A7",
    "Postcode": 38723,
    "City": "Seesen",
    "State": "Niedersachsen",
    "Latitude": "051.9285280",
    "Longitude": "010.1423060",
    "Telephone Number": "05384969502",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.928528,10.142306",
    "IsOpen24Hours": true,
    "id": "51.928528,10.142306"
  },
  {
    "Gas Station Number": "0FN40",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bodenseestraße 317",
    "Postcode": 81249,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1411840",
    "Longitude": "011.4139550",
    "Telephone Number": "08985796463",
    "Week From": "05:00",
    "Week To": "23:45",
    "Saturdays From": "05:00",
    "Saturdays To": "23:45",
    "Sundays From": "05:00",
    "Sundays To": "23:45",
    "24 hour?": "Nein",
    "location": "48.141184,11.413955",
    "IsOpen24Hours": false,
    "id": "48.141184,11.413955"
  },
  {
    "Gas Station Number": "0FF88",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Hochstraße / Bergheimer S",
    "Postcode": 47228,
    "City": "Duisburg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4196170",
    "Longitude": "006.6961590",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.419617,6.696159",
    "IsOpen24Hours": true,
    "id": "51.419617,6.696159"
  },
  {
    "Gas Station Number": "0F996",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gerresheimer Landstr. 67",
    "Postcode": 40627,
    "City": "Düsseldorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2016100",
    "Longitude": "006.9047970",
    "Telephone Number": "0211201996",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.20161,6.904797",
    "IsOpen24Hours": true,
    "id": "51.20161,6.904797"
  },
  {
    "Gas Station Number": "0FF85",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Bruchfeld 80",
    "Postcode": 47809,
    "City": "Krefeld",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3429240",
    "Longitude": "006.6278850",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.342924,6.627885",
    "IsOpen24Hours": true,
    "id": "51.342924,6.627885"
  },
  {
    "Gas Station Number": "0FR11",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Carl-Bosch Straße 1A",
    "Postcode": 76829,
    "City": "Landau",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.1925560",
    "Longitude": "008.1425160",
    "Telephone Number": "063415590250",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.192556,8.142516",
    "IsOpen24Hours": true,
    "id": "49.192556,8.142516"
  },
  {
    "Gas Station Number": "0FU84",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A7",
    "Postcode": 91620,
    "City": "Ohrenbach",
    "State": "Bayern",
    "Latitude": "049.4860300",
    "Longitude": "010.2118150",
    "Telephone Number": "0986597770",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.48603,10.211815",
    "IsOpen24Hours": true,
    "id": "49.48603,10.211815"
  },
  {
    "Gas Station Number": "0FM40",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Limburger Straße 12",
    "Postcode": 35759,
    "City": "Driedorf",
    "State": "Hessen",
    "Latitude": "050.6181800",
    "Longitude": "008.1921960",
    "Telephone Number": "027757224",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.61818,8.192196",
    "IsOpen24Hours": false,
    "id": "50.61818,8.192196"
  },
  {
    "Gas Station Number": "0FN83",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Robert-Bosch-Straße 10",
    "Postcode": 71701,
    "City": "Schwieberdingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.8819010",
    "Longitude": "009.0792810",
    "Telephone Number": "0715033403",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "20:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.881901,9.079281",
    "IsOpen24Hours": false,
    "id": "48.881901,9.079281"
  },
  {
    "Gas Station Number": "0FN43",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bödigheimer Straße 24",
    "Postcode": 74722,
    "City": "Buchen",
    "State": "Baden-Württemberg",
    "Latitude": "049.5152870",
    "Longitude": "009.3215440",
    "Telephone Number": "0628152150",
    "Week From": "06:45",
    "Week To": "20:00",
    "Saturdays From": "06:45",
    "Saturdays To": "20:00",
    "Sundays From": "10:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.515287,9.321544",
    "IsOpen24Hours": false,
    "id": "49.515287,9.321544"
  },
  {
    "Gas Station Number": "0FM33",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heerstraße 41",
    "Postcode": 29693,
    "City": "Hodenhagen",
    "State": "Niedersachsen",
    "Latitude": "052.7627150",
    "Longitude": "009.5830250",
    "Telephone Number": "05164682",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.762715,9.583025",
    "IsOpen24Hours": false,
    "id": "52.762715,9.583025"
  },
  {
    "Gas Station Number": "0FO90",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "In der Pützgewann 3 b",
    "Postcode": 56218,
    "City": "Mülheim Kärlich",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.3964880",
    "Longitude": "007.5127660",
    "Telephone Number": "026309669283",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.396488,7.512766",
    "IsOpen24Hours": true,
    "id": "50.396488,7.512766"
  },
  {
    "Gas Station Number": "0FP13",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "An der Haune 1 a",
    "Postcode": 36251,
    "City": "Bad Hersfeld",
    "State": "Hessen",
    "Latitude": "050.8621710",
    "Longitude": "009.7228130",
    "Telephone Number": "066217940447",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.862171,9.722813",
    "IsOpen24Hours": true,
    "id": "50.862171,9.722813"
  },
  {
    "Gas Station Number": "0FT37",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Carl-Benz-Str. 10",
    "Postcode": 60386,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.1217640",
    "Longitude": "008.7491240",
    "Telephone Number": "06940809098",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.121764,8.749124",
    "IsOpen24Hours": true,
    "id": "50.121764,8.749124"
  },
  {
    "Gas Station Number": "0F532",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der Bundesstraße 5",
    "Postcode": 71543,
    "City": "Wüstenrot",
    "State": "Baden-Württemberg",
    "Latitude": "049.0904510",
    "Longitude": "009.4909950",
    "Telephone Number": "0794591910",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:30",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.090451,9.490995",
    "IsOpen24Hours": false,
    "id": "49.090451,9.490995"
  },
  {
    "Gas Station Number": "0FU60",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Daimler Straße 2",
    "Postcode": 48308,
    "City": "Senden",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.8467380",
    "Longitude": "007.4846890",
    "Telephone Number": "02597960750",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "05:30",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.846738,7.484689",
    "IsOpen24Hours": false,
    "id": "51.846738,7.484689"
  },
  {
    "Gas Station Number": "0FT73",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Baarstraße 162",
    "Postcode": 58636,
    "City": "Iserlohn",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4004650",
    "Longitude": "007.6977380",
    "Telephone Number": "0237142200",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "05:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "51.400465,7.697738",
    "IsOpen24Hours": false,
    "id": "51.400465,7.697738"
  },
  {
    "Gas Station Number": "0FX83",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A44",
    "Postcode": 34289,
    "City": "Zierenberg",
    "State": "Hessen",
    "Latitude": "051.3568504",
    "Longitude": "009.2789292",
    "Telephone Number": "056068124",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.3568504,9.2789292",
    "IsOpen24Hours": true,
    "id": "51.3568504,9.2789292"
  },
  {
    "Gas Station Number": "0FQ47",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Im Mühlenbruch 2",
    "Postcode": 53639,
    "City": "Königswinter",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7025600",
    "Longitude": "007.1771070",
    "Telephone Number": "022237009181",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.70256,7.177107",
    "IsOpen24Hours": true,
    "id": "50.70256,7.177107"
  },
  {
    "Gas Station Number": "0FN65",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Pulsnitzer Straße 31",
    "Postcode": 1900,
    "City": "Großröhrsdorf",
    "State": "Sachsen",
    "Latitude": "051.1513550",
    "Longitude": "014.0202870",
    "Telephone Number": "03595231554",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:30",
    "Saturdays To": "21:00",
    "Sundays From": "06:30",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.151355,14.020287",
    "IsOpen24Hours": false,
    "id": "51.151355,14.020287"
  },
  {
    "Gas Station Number": "0FT56",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Im breiten Pfuhl 5",
    "Postcode": 67365,
    "City": "Schwegenheim",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.2719380",
    "Longitude": "008.3385240",
    "Telephone Number": "063445074933",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.271938,8.338524",
    "IsOpen24Hours": true,
    "id": "49.271938,8.338524"
  },
  {
    "Gas Station Number": "0FR98",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "GREBENSTEINER STRAßE",
    "Postcode": 34369,
    "City": "HOFGEISMAR",
    "State": "Hessen",
    "Latitude": "051.4814320",
    "Longitude": "009.3899300",
    "Telephone Number": "056715070246",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.481432,9.38993",
    "IsOpen24Hours": true,
    "id": "51.481432,9.38993"
  },
  {
    "Gas Station Number": "0FP75",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 44",
    "Postcode": 88175,
    "City": "Scheidegg",
    "State": "Bayern",
    "Latitude": "047.5847830",
    "Longitude": "009.8544330",
    "Telephone Number": "0838181717",
    "Week From": "06:30",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "47.584783,9.854433",
    "IsOpen24Hours": false,
    "id": "47.584783,9.854433"
  },
  {
    "Gas Station Number": "0FP57",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 155",
    "Postcode": 57518,
    "City": "Alsdorf",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.7812660",
    "Longitude": "007.8906000",
    "Telephone Number": "027419374781",
    "Week From": "05:30",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.781266,7.8906",
    "IsOpen24Hours": false,
    "id": "50.781266,7.8906"
  },
  {
    "Gas Station Number": "0FO89",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Jungenthaler Straße 75",
    "Postcode": 57548,
    "City": "Kirchen",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.8109480",
    "Longitude": "007.8648850",
    "Telephone Number": "027417278",
    "Week From": "05:30",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.810948,7.864885",
    "IsOpen24Hours": false,
    "id": "50.810948,7.864885"
  },
  {
    "Gas Station Number": "0FN37",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 11",
    "Postcode": 83128,
    "City": "Halfing",
    "State": "Bayern",
    "Latitude": "047.9526980",
    "Longitude": "012.2789750",
    "Telephone Number": "08681477900",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.952698,12.278975",
    "IsOpen24Hours": false,
    "id": "47.952698,12.278975"
  },
  {
    "Gas Station Number": "0FU78",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A115",
    "Postcode": 14055,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5018390",
    "Longitude": "013.2781170",
    "Telephone Number": "0303024929",
    "Week From": "06:00",
    "Week To": "21:59",
    "Saturdays From": "07:00",
    "Saturdays To": "16:59",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.501839,13.278117",
    "IsOpen24Hours": false,
    "id": "52.501839,13.278117"
  },
  {
    "Gas Station Number": "0FQ59",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Fittingstraße 99",
    "Postcode": 78224,
    "City": "Singen",
    "State": "Baden-Württemberg",
    "Latitude": "047.7541340",
    "Longitude": "008.8526830",
    "Telephone Number": "077319269262",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.754134,8.852683",
    "IsOpen24Hours": true,
    "id": "47.754134,8.852683"
  },
  {
    "Gas Station Number": "0FV98",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Michelsrombacher Straße 6",
    "Postcode": 36039,
    "City": "Fulda",
    "State": "Hessen",
    "Latitude": "050.5811827",
    "Longitude": "009.7059969",
    "Telephone Number": "066196211314",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.5811827,9.7059969",
    "IsOpen24Hours": true,
    "id": "50.5811827,9.7059969"
  },
  {
    "Gas Station Number": "0FN38",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Brannenburger Straße 12",
    "Postcode": 83131,
    "City": "Nußdorf",
    "State": "Bayern",
    "Latitude": "047.7433580",
    "Longitude": "012.1542070",
    "Telephone Number": "08034904618",
    "Week From": "10:00",
    "Week To": "18:00",
    "Saturdays From": "09:00",
    "Saturdays To": "17:00",
    "Sundays From": "10:00",
    "Sundays To": "17:00",
    "24 hour?": "Nein",
    "location": "47.743358,12.154207",
    "IsOpen24Hours": false,
    "id": "47.743358,12.154207"
  },
  {
    "Gas Station Number": "0FQ60",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Albert-Einstein-Straße",
    "Postcode": 31135,
    "City": "Hildesheim",
    "State": "Niedersachsen",
    "Latitude": "052.1603120",
    "Longitude": "009.9973760",
    "Telephone Number": "051216908322",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.160312,9.997376",
    "IsOpen24Hours": true,
    "id": "52.160312,9.997376"
  },
  {
    "Gas Station Number": "0FF80",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Siemensring 41",
    "Postcode": 47877,
    "City": "Willich",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.2701180",
    "Longitude": "006.5120130",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.270118,6.512013",
    "IsOpen24Hours": true,
    "id": "51.270118,6.512013"
  },
  {
    "Gas Station Number": "0FM37",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Monschauer Straße 33",
    "Postcode": 41068,
    "City": "Mönchengladbach",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1928400",
    "Longitude": "006.4090790",
    "Telephone Number": "021614770840",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.19284,6.409079",
    "IsOpen24Hours": true,
    "id": "51.19284,6.409079"
  },
  {
    "Gas Station Number": "0FF92",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Heinestr. 10-12",
    "Postcode": 48703,
    "City": "Stadtlohn",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.9928010",
    "Longitude": "006.8896090",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.992801,6.889609",
    "IsOpen24Hours": true,
    "id": "51.992801,6.889609"
  },
  {
    "Gas Station Number": "0FM54",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Grenzallee 6",
    "Postcode": 12057,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.4690420",
    "Longitude": "013.4618750",
    "Telephone Number": "03063227487",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.469042,13.461875",
    "IsOpen24Hours": true,
    "id": "52.469042,13.461875"
  },
  {
    "Gas Station Number": "0FF64",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Industriering 5",
    "Postcode": 49393,
    "City": "Lohne",
    "State": "Niedersachsen",
    "Latitude": "052.6583320",
    "Longitude": "008.2007400",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.658332,8.20074",
    "IsOpen24Hours": true,
    "id": "52.658332,8.20074"
  },
  {
    "Gas Station Number": "0FM15",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Tilsiter Straße 15",
    "Postcode": 71065,
    "City": "Sindelfingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.7031600",
    "Longitude": "009.0298660",
    "Telephone Number": "07031633658",
    "Week From": "07:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.70316,9.029866",
    "IsOpen24Hours": false,
    "id": "48.70316,9.029866"
  },
  {
    "Gas Station Number": "0FO70",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedrichshafener Straße 73",
    "Postcode": 88214,
    "City": "Ravensburg",
    "State": "Baden-Württemberg",
    "Latitude": "047.7385710",
    "Longitude": "009.5983750",
    "Telephone Number": "075162384",
    "Week From": "07:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.738571,9.598375",
    "IsOpen24Hours": false,
    "id": "47.738571,9.598375"
  },
  {
    "Gas Station Number": "0FO15",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schalmeistraße 2",
    "Postcode": 86399,
    "City": "Bobingen",
    "State": "Bayern",
    "Latitude": "048.2791620",
    "Longitude": "010.8407430",
    "Telephone Number": "082348771",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.279162,10.840743",
    "IsOpen24Hours": false,
    "id": "48.279162,10.840743"
  },
  {
    "Gas Station Number": "0FG65",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "BAB A 19 - Ost",
    "Postcode": 18276,
    "City": "Recknitz",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.8399430",
    "Longitude": "012.2878090",
    "Telephone Number": "03845520952",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.839943,12.287809",
    "IsOpen24Hours": true,
    "id": "53.839943,12.287809"
  },
  {
    "Gas Station Number": "0FN02",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Südstraße 74",
    "Postcode": 71404,
    "City": "Korb",
    "State": "Baden-Württemberg",
    "Latitude": "048.8384090",
    "Longitude": "009.3660560",
    "Telephone Number": "0715131100",
    "Week From": "07:00",
    "Week To": "19:00",
    "Saturdays From": "07:30",
    "Saturdays To": "14:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.838409,9.366056",
    "IsOpen24Hours": false,
    "id": "48.838409,9.366056"
  },
  {
    "Gas Station Number": "0FH80",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Moerser Straße 470-472",
    "Postcode": 47475,
    "City": "Kamp-Lintfort",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4991490",
    "Longitude": "006.5681160",
    "Telephone Number": "0284293567",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.499149,6.568116",
    "IsOpen24Hours": true,
    "id": "51.499149,6.568116"
  },
  {
    "Gas Station Number": "0FU85",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A67",
    "Postcode": 64319,
    "City": "Pfungstadt",
    "State": "Hessen",
    "Latitude": "049.8134220",
    "Longitude": "008.5780550",
    "Telephone Number": "0615785531",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.813422,8.578055",
    "IsOpen24Hours": true,
    "id": "49.813422,8.578055"
  },
  {
    "Gas Station Number": "0FO13",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Robert-Bosch-Straße 40",
    "Postcode": 88131,
    "City": "Lindau",
    "State": "Bayern",
    "Latitude": "047.5476940",
    "Longitude": "009.7280790",
    "Telephone Number": "0838278767",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.547694,9.728079",
    "IsOpen24Hours": true,
    "id": "47.547694,9.728079"
  },
  {
    "Gas Station Number": "0FF34",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Gewerbeweg 8",
    "Postcode": 49751,
    "City": "Sögel",
    "State": "Niedersachsen",
    "Latitude": "052.8342410",
    "Longitude": "007.5153210",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.834241,7.515321",
    "IsOpen24Hours": true,
    "id": "52.834241,7.515321"
  },
  {
    "Gas Station Number": "0F805",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Darmstädter Straße 2",
    "Postcode": 64319,
    "City": "Pfungstadt",
    "State": "Hessen",
    "Latitude": "049.8074230",
    "Longitude": "008.5641810",
    "Telephone Number": "061579896791",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.807423,8.564181",
    "IsOpen24Hours": false,
    "id": "49.807423,8.564181"
  },
  {
    "Gas Station Number": "0FP72",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der Aspe 1",
    "Postcode": 36137,
    "City": "Großenlüder",
    "State": "Hessen",
    "Latitude": "050.5820540",
    "Longitude": "009.5420412",
    "Telephone Number": "066487128",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.582054,9.5420412",
    "IsOpen24Hours": false,
    "id": "50.582054,9.5420412"
  },
  {
    "Gas Station Number": "0FF33",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Friedrich-Ebert-Str. 100",
    "Postcode": 49811,
    "City": "Lingen",
    "State": "Niedersachsen",
    "Latitude": "052.5436770",
    "Longitude": "007.3428160",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.543677,7.342816",
    "IsOpen24Hours": true,
    "id": "52.543677,7.342816"
  },
  {
    "Gas Station Number": "0FC15",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Freiburger Strasse 9",
    "Postcode": 79189,
    "City": "Bad Krozingen",
    "State": "Baden-Württemberg",
    "Latitude": "047.9199277",
    "Longitude": "007.7045613",
    "Telephone Number": "076333536",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "47.9199277,7.7045613",
    "IsOpen24Hours": false,
    "id": "47.9199277,7.7045613"
  },
  {
    "Gas Station Number": "0FM55",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Carl-Schurz-Straße 160",
    "Postcode": 50374,
    "City": "Erftstadt",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8103980",
    "Longitude": "006.8085120",
    "Telephone Number": "0223545711",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "08:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.810398,6.808512",
    "IsOpen24Hours": false,
    "id": "50.810398,6.808512"
  },
  {
    "Gas Station Number": "0FS61",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Manfred-von-Ardenne-Allee 2",
    "Postcode": 71522,
    "City": "Backnang",
    "State": "Baden-Württemberg",
    "Latitude": "048.9592110",
    "Longitude": "009.4231260",
    "Telephone Number": "071913455060",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.959211,9.423126",
    "IsOpen24Hours": false,
    "id": "48.959211,9.423126"
  },
  {
    "Gas Station Number": "0FF48",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Heisenbergstraße 3-9",
    "Postcode": 50169,
    "City": "Kerpen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8698240",
    "Longitude": "006.7563190",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.869824,6.756319",
    "IsOpen24Hours": true,
    "id": "50.869824,6.756319"
  },
  {
    "Gas Station Number": "0FP15",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kieler Straße 51-53",
    "Postcode": 24568,
    "City": "Kaltenkirchen",
    "State": "Schleswig-Holstein",
    "Latitude": "053.8407260",
    "Longitude": "009.9476190",
    "Telephone Number": "041919528830",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "05:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.840726,9.947619",
    "IsOpen24Hours": false,
    "id": "53.840726,9.947619"
  },
  {
    "Gas Station Number": "0FT77",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Im Höning",
    "Postcode": 63820,
    "City": "Elsenfeld",
    "State": "Bayern",
    "Latitude": "049.8560720",
    "Longitude": "009.1677020",
    "Telephone Number": "060225062265",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.856072,9.167702",
    "IsOpen24Hours": false,
    "id": "49.856072,9.167702"
  },
  {
    "Gas Station Number": "0FO07",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Landstraße 16",
    "Postcode": 76547,
    "City": "Sinzheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.7601110",
    "Longitude": "008.1688600",
    "Telephone Number": "072218035625",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.760111,8.16886",
    "IsOpen24Hours": false,
    "id": "48.760111,8.16886"
  },
  {
    "Gas Station Number": "0FF82",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Europaallee 16-18",
    "Postcode": 50226,
    "City": "Frechen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9207620",
    "Longitude": "006.8334480",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.920762,6.833448",
    "IsOpen24Hours": true,
    "id": "50.920762,6.833448"
  },
  {
    "Gas Station Number": "0FT03",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neuenhöhe 20",
    "Postcode": 42929,
    "City": "Wermelskirchen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1461570",
    "Longitude": "007.2360310",
    "Telephone Number": "02196731850",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.146157,7.236031",
    "IsOpen24Hours": true,
    "id": "51.146157,7.236031"
  },
  {
    "Gas Station Number": "0FM18",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Zehentbauernstraße 7",
    "Postcode": 81539,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1142780",
    "Longitude": "011.5762830",
    "Telephone Number": "0896972174",
    "Week From": "06:00",
    "Week To": "23:45",
    "Saturdays From": "07:00",
    "Saturdays To": "23:45",
    "Sundays From": "07:00",
    "Sundays To": "23:45",
    "24 hour?": "Nein",
    "location": "48.114278,11.576283",
    "IsOpen24Hours": false,
    "id": "48.114278,11.576283"
  },
  {
    "Gas Station Number": "0FN84",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ulmer Straße 39",
    "Postcode": 73066,
    "City": "Uhingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.7073010",
    "Longitude": "009.5897910",
    "Telephone Number": "071619838726",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.707301,9.589791",
    "IsOpen24Hours": false,
    "id": "48.707301,9.589791"
  },
  {
    "Gas Station Number": "0FN48",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bezirksstraße 16",
    "Postcode": 67122,
    "City": "Altrip",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.4349660",
    "Longitude": "008.4845310",
    "Telephone Number": "062362685",
    "Week From": "07:00",
    "Week To": "20:30",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.434966,8.484531",
    "IsOpen24Hours": false,
    "id": "49.434966,8.484531"
  },
  {
    "Gas Station Number": "0FT41",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Innstraße 16",
    "Postcode": 84453,
    "City": "Mühldorf",
    "State": "Bayern",
    "Latitude": "048.2417770",
    "Longitude": "012.5192610",
    "Telephone Number": "086319866933",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.241777,12.519261",
    "IsOpen24Hours": false,
    "id": "48.241777,12.519261"
  },
  {
    "Gas Station Number": "0FT88",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Forchheimer Straße 44",
    "Postcode": 91320,
    "City": "Ebermannstadt",
    "State": "Bayern",
    "Latitude": "049.7769010",
    "Longitude": "011.1778960",
    "Telephone Number": "09194737016",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.776901,11.177896",
    "IsOpen24Hours": false,
    "id": "49.776901,11.177896"
  },
  {
    "Gas Station Number": "0FY32",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 5",
    "Postcode": 35305,
    "City": "Grünberg",
    "State": "Hessen",
    "Latitude": "050.6223890",
    "Longitude": "008.8953670",
    "Telephone Number": "0640191450",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "50.622389,8.895367",
    "IsOpen24Hours": false,
    "id": "50.622389,8.895367"
  },
  {
    "Gas Station Number": "0FT59",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nikola-Tesla-Straße 1",
    "Postcode": 77955,
    "City": "Ettenheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.2747960",
    "Longitude": "007.7855640",
    "Telephone Number": "07822/3005688",
    "Week From": "04:30",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.274796,7.785564",
    "IsOpen24Hours": false,
    "id": "48.274796,7.785564"
  },
  {
    "Gas Station Number": "0FY23",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 5",
    "Postcode": 64665,
    "City": "Alsbach-Hähnlein",
    "State": "Hessen",
    "Latitude": "049.7330630",
    "Longitude": "008.5919640",
    "Telephone Number": "062573922",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.733063,8.591964",
    "IsOpen24Hours": true,
    "id": "49.733063,8.591964"
  },
  {
    "Gas Station Number": "0FF45",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Max-Planck-Str. 28",
    "Postcode": 50354,
    "City": "Hürth",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8846540",
    "Longitude": "006.9069170",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.884654,6.906917",
    "IsOpen24Hours": true,
    "id": "50.884654,6.906917"
  },
  {
    "Gas Station Number": "0FN82",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hohberger Straße 19",
    "Postcode": 71106,
    "City": "Magstadt",
    "State": "Baden-Württemberg",
    "Latitude": "048.7386010",
    "Longitude": "008.9662610",
    "Telephone Number": "0715994740",
    "Week From": "07:00",
    "Week To": "18:00",
    "Saturdays From": "08:00",
    "Saturdays To": "13:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.738601,8.966261",
    "IsOpen24Hours": false,
    "id": "48.738601,8.966261"
  },
  {
    "Gas Station Number": "0FG10",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hans-Böckler-Allee 33",
    "Postcode": 30173,
    "City": "Hannover",
    "State": "Niedersachsen",
    "Latitude": "052.3713920",
    "Longitude": "009.7737650",
    "Telephone Number": "05118112116",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.371392,9.773765",
    "IsOpen24Hours": true,
    "id": "52.371392,9.773765"
  },
  {
    "Gas Station Number": "0FO55",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kölner Straße 36-40",
    "Postcode": 50226,
    "City": "Frechen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9105910",
    "Longitude": "006.8178820",
    "Telephone Number": "0223414646",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.910591,6.817882",
    "IsOpen24Hours": true,
    "id": "50.910591,6.817882"
  },
  {
    "Gas Station Number": "0FV37",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Erzbergerstraße 61",
    "Postcode": 88239,
    "City": "Wangen",
    "State": "Baden-Württemberg",
    "Latitude": "047.6776380",
    "Longitude": "009.8405850",
    "Telephone Number": "075226400",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.677638,9.840585",
    "IsOpen24Hours": false,
    "id": "47.677638,9.840585"
  },
  {
    "Gas Station Number": "0FU92",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "BAB A 1",
    "Postcode": 27367,
    "City": "Sottrum",
    "State": "Niedersachsen",
    "Latitude": "053.0908870",
    "Longitude": "009.1779420",
    "Telephone Number": "04205494",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.090887,9.177942",
    "IsOpen24Hours": true,
    "id": "53.090887,9.177942"
  },
  {
    "Gas Station Number": "0FF52",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Longericher Str. 207",
    "Postcode": 50739,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9824580",
    "Longitude": "006.9198100",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.982458,6.91981",
    "IsOpen24Hours": true,
    "id": "50.982458,6.91981"
  },
  {
    "Gas Station Number": "0FF53",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Gerberstraße",
    "Postcode": 51789,
    "City": "Lindlar",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0264870",
    "Longitude": "007.4161760",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.026487,7.416176",
    "IsOpen24Hours": true,
    "id": "51.026487,7.416176"
  },
  {
    "Gas Station Number": "0FE76",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Borchener Straße336",
    "Postcode": 33106,
    "City": "Paderborn",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6832750",
    "Longitude": "008.7211090",
    "Telephone Number": "05251892905",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.683275,8.721109",
    "IsOpen24Hours": true,
    "id": "51.683275,8.721109"
  },
  {
    "Gas Station Number": "0FX05",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A2",
    "Postcode": 33334,
    "City": "Gütersloh",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.8758618",
    "Longitude": "008.4180650",
    "Telephone Number": "0524151600",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.8758618,8.418065",
    "IsOpen24Hours": true,
    "id": "51.8758618,8.418065"
  },
  {
    "Gas Station Number": "0FU08",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Carl-Benz-Straße 1",
    "Postcode": 61191,
    "City": "Rosbach vor der Höhe",
    "State": "Hessen",
    "Latitude": "050.2934520",
    "Longitude": "008.6936990",
    "Telephone Number": "060038269661",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "08:00",
    "Saturdays To": "23:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.293452,8.693699",
    "IsOpen24Hours": false,
    "id": "50.293452,8.693699"
  },
  {
    "Gas Station Number": "0FF43",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Paradiesstr. 17",
    "Postcode": 52349,
    "City": "Düren",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8061120",
    "Longitude": "006.4721910",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.806112,6.472191",
    "IsOpen24Hours": true,
    "id": "50.806112,6.472191"
  },
  {
    "Gas Station Number": "0FF89",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Königskamp 4",
    "Postcode": 52428,
    "City": "Jülich",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9122760",
    "Longitude": "006.3787800",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.912276,6.37878",
    "IsOpen24Hours": true,
    "id": "50.912276,6.37878"
  },
  {
    "Gas Station Number": "0FF87",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Maurerstraße 37",
    "Postcode": 52477,
    "City": "Alsdorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8790330",
    "Longitude": "006.1836910",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.879033,6.183691",
    "IsOpen24Hours": true,
    "id": "50.879033,6.183691"
  },
  {
    "Gas Station Number": "0FO58",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Äußere-Kanal-Straße 90",
    "Postcode": 50827,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9585360",
    "Longitude": "006.9052120",
    "Telephone Number": "0221551000",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.958536,6.905212",
    "IsOpen24Hours": true,
    "id": "50.958536,6.905212"
  },
  {
    "Gas Station Number": "0FF19",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Im Seifental",
    "Postcode": 53498,
    "City": "Bad Breisig",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.5343530",
    "Longitude": "007.2683820",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.534353,7.268382",
    "IsOpen24Hours": true,
    "id": "50.534353,7.268382"
  },
  {
    "Gas Station Number": "0FF46",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Lütticher Str. 1",
    "Postcode": 53842,
    "City": "Troisdorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8384680",
    "Longitude": "007.1135180",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.838468,7.113518",
    "IsOpen24Hours": true,
    "id": "50.838468,7.113518"
  },
  {
    "Gas Station Number": "0FP84",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Siegener Straße 10",
    "Postcode": 57548,
    "City": "Kirchen",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.8121990",
    "Longitude": "007.8801530",
    "Telephone Number": "0274161497",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.812199,7.880153",
    "IsOpen24Hours": false,
    "id": "50.812199,7.880153"
  },
  {
    "Gas Station Number": "0FV59",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hermann-Böcker-Straße 13",
    "Postcode": 82140,
    "City": "Olching",
    "State": "Bayern",
    "Latitude": "048.2213700",
    "Longitude": "011.3299260",
    "Telephone Number": "081424101927",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.22137,11.329926",
    "IsOpen24Hours": false,
    "id": "48.22137,11.329926"
  },
  {
    "Gas Station Number": "0FM39",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Alter Postweg 4-6",
    "Postcode": 68309,
    "City": "Mannheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.5159640",
    "Longitude": "008.5205710",
    "Telephone Number": "06217163939",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.515964,8.520571",
    "IsOpen24Hours": true,
    "id": "49.515964,8.520571"
  },
  {
    "Gas Station Number": "0FF93",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Bonner Str. 49",
    "Postcode": 53919,
    "City": "Weilerswist",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7498630",
    "Longitude": "006.8506060",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.749863,6.850606",
    "IsOpen24Hours": true,
    "id": "50.749863,6.850606"
  },
  {
    "Gas Station Number": "0FM53",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Carl-Benz-Straße 20-24",
    "Postcode": 78467,
    "City": "Konstanz",
    "State": "Baden-Württemberg",
    "Latitude": "047.6775860",
    "Longitude": "009.1470510",
    "Telephone Number": "07531694090",
    "Week From": "05:00",
    "Week To": "23:59",
    "Saturdays From": "05:00",
    "Saturdays To": "23:59",
    "Sundays From": "06:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "47.677586,9.147051",
    "IsOpen24Hours": false,
    "id": "47.677586,9.147051"
  },
  {
    "Gas Station Number": "0FO57",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berliner Straße 111",
    "Postcode": 51063,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9737970",
    "Longitude": "007.0125540",
    "Telephone Number": "022196426619",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.973797,7.012554",
    "IsOpen24Hours": false,
    "id": "50.973797,7.012554"
  },
  {
    "Gas Station Number": "0FV30",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 79-81",
    "Postcode": 69151,
    "City": "Neckargemünd",
    "State": "Baden-Württemberg",
    "Latitude": "049.3924580",
    "Longitude": "008.7798260",
    "Telephone Number": "062239725144",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "05:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.392458,8.779826",
    "IsOpen24Hours": false,
    "id": "49.392458,8.779826"
  },
  {
    "Gas Station Number": "0FF09",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Europa-Allee 75",
    "Postcode": 54343,
    "City": "Föhren",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.8634700",
    "Longitude": "006.7918180",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.86347,6.791818",
    "IsOpen24Hours": true,
    "id": "49.86347,6.791818"
  },
  {
    "Gas Station Number": "0FN32",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 1",
    "Postcode": 83324,
    "City": "Ruhpolding",
    "State": "Bayern",
    "Latitude": "047.7661010",
    "Longitude": "012.6408010",
    "Telephone Number": "08663419603",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "47.766101,12.640801",
    "IsOpen24Hours": false,
    "id": "47.766101,12.640801"
  },
  {
    "Gas Station Number": "0F655",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gießener Straße 60",
    "Postcode": 34560,
    "City": "Fritzlar",
    "State": "Hessen",
    "Latitude": "051.1208460",
    "Longitude": "009.2776410",
    "Telephone Number": "05622930418",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.120846,9.277641",
    "IsOpen24Hours": false,
    "id": "51.120846,9.277641"
  },
  {
    "Gas Station Number": "0FF03",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "August-Horch-Straße 15",
    "Postcode": 56070,
    "City": "Koblenz",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.3930440",
    "Longitude": "007.5719370",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.393044,7.571937",
    "IsOpen24Hours": true,
    "id": "50.393044,7.571937"
  },
  {
    "Gas Station Number": "0FV65",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kurt-Schumacher-Ring 4",
    "Postcode": 63329,
    "City": "Egelsbach",
    "State": "Hessen",
    "Latitude": "049.9611080",
    "Longitude": "008.6714700",
    "Telephone Number": "06103/8311830",
    "Week From": "07:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.961108,8.67147",
    "IsOpen24Hours": false,
    "id": "49.961108,8.67147"
  },
  {
    "Gas Station Number": "0FO85",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rheinstraße 50",
    "Postcode": 57632,
    "City": "Flammersfeld",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.6482580",
    "Longitude": "007.5271390",
    "Telephone Number": "026851508",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.648258,7.527139",
    "IsOpen24Hours": false,
    "id": "50.648258,7.527139"
  },
  {
    "Gas Station Number": "0FF13",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Rudolf-Diesel-Str. 13",
    "Postcode": 56220,
    "City": "Urmitz",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.4004980",
    "Longitude": "007.5167200",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.400498,7.51672",
    "IsOpen24Hours": true,
    "id": "50.400498,7.51672"
  },
  {
    "Gas Station Number": "0FR90",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der Kleinbahn 5",
    "Postcode": 41334,
    "City": "Nettetal",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3286470",
    "Longitude": "006.1963330",
    "Telephone Number": "021578958775",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.328647,6.196333",
    "IsOpen24Hours": true,
    "id": "51.328647,6.196333"
  },
  {
    "Gas Station Number": "0FN58",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Donauwörther Straße 63",
    "Postcode": 86653,
    "City": "Monheim",
    "State": "Bayern",
    "Latitude": "048.8367260",
    "Longitude": "010.8451950",
    "Telephone Number": "090911863",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "07:30",
    "Saturdays To": "20:00",
    "Sundays From": "08:30",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "48.836726,10.845195",
    "IsOpen24Hours": false,
    "id": "48.836726,10.845195"
  },
  {
    "Gas Station Number": "0FQ70",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gewerbepark B 3",
    "Postcode": 93086,
    "City": "Wörth an der Donau",
    "State": "Bayern",
    "Latitude": "049.0006400",
    "Longitude": "012.3641570",
    "Telephone Number": "094829080210",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.00064,12.364157",
    "IsOpen24Hours": true,
    "id": "49.00064,12.364157"
  },
  {
    "Gas Station Number": "0FV81",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedrich-Pogge-Weg 60",
    "Postcode": 18273,
    "City": "Güstrow",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.7728720",
    "Longitude": "012.1665080",
    "Telephone Number": "03843334848",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "53.772872,12.166508",
    "IsOpen24Hours": false,
    "id": "53.772872,12.166508"
  },
  {
    "Gas Station Number": "0FX55",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A8",
    "Postcode": 83451,
    "City": "Piding",
    "State": "Bayern",
    "Latitude": "047.7680540",
    "Longitude": "012.9421770",
    "Telephone Number": "086512750",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.768054,12.942177",
    "IsOpen24Hours": true,
    "id": "47.768054,12.942177"
  },
  {
    "Gas Station Number": "0FS75",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "ALBERT-EINSTEIN-STR. 1 a",
    "Postcode": 97080,
    "City": "WÜRZBURG",
    "State": "Bayern",
    "Latitude": "049.8084270",
    "Longitude": "009.8863230",
    "Telephone Number": "093140409720",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.808427,9.886323",
    "IsOpen24Hours": true,
    "id": "49.808427,9.886323"
  },
  {
    "Gas Station Number": "0FN14",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ingolstädter Straße 31",
    "Postcode": 86633,
    "City": "Neuburg",
    "State": "Bayern",
    "Latitude": "048.7427480",
    "Longitude": "011.1824270",
    "Telephone Number": "084318363",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.742748,11.182427",
    "IsOpen24Hours": false,
    "id": "48.742748,11.182427"
  },
  {
    "Gas Station Number": "0FQ07",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A2 / Brockweg 300",
    "Postcode": 33334,
    "City": "Gütersloh",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.8663030",
    "Longitude": "008.3692090",
    "Telephone Number": "0524151100",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.866303,8.369209",
    "IsOpen24Hours": true,
    "id": "51.866303,8.369209"
  },
  {
    "Gas Station Number": "0FN49",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Buxheimer Straße 113",
    "Postcode": 87700,
    "City": "Memmingen",
    "State": "Bayern",
    "Latitude": "047.9916880",
    "Longitude": "010.1606230",
    "Telephone Number": "0833161722",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "23:00",
    "Sundays From": "07:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.991688,10.160623",
    "IsOpen24Hours": false,
    "id": "47.991688,10.160623"
  },
  {
    "Gas Station Number": "0FF08",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Nicolaus-Otto-Straße",
    "Postcode": 56727,
    "City": "Mayen",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.3330970",
    "Longitude": "007.2618150",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.333097,7.261815",
    "IsOpen24Hours": true,
    "id": "50.333097,7.261815"
  },
  {
    "Gas Station Number": "0FX86",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 71",
    "Postcode": 98716,
    "City": "Geraberg",
    "State": "Thüringen",
    "Latitude": "050.7253110",
    "Longitude": "010.8458110",
    "Telephone Number": "036776894953",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.725311,10.845811",
    "IsOpen24Hours": true,
    "id": "50.725311,10.845811"
  },
  {
    "Gas Station Number": "0FO66",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Siemensstraße 2",
    "Postcode": 84109,
    "City": "Wörth a. d. Isar",
    "State": "Bayern",
    "Latitude": "048.6251070",
    "Longitude": "012.3226280",
    "Telephone Number": "08702949351",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.625107,12.322628",
    "IsOpen24Hours": true,
    "id": "48.625107,12.322628"
  },
  {
    "Gas Station Number": "0FM94",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Saarstraße 22-26",
    "Postcode": 61169,
    "City": "Friedberg",
    "State": "Hessen",
    "Latitude": "050.3315720",
    "Longitude": "008.7562270",
    "Telephone Number": "060311660487",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.331572,8.756227",
    "IsOpen24Hours": false,
    "id": "50.331572,8.756227"
  },
  {
    "Gas Station Number": "0FM07",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Profilstr. 7",
    "Postcode": 58091,
    "City": "Hagen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3889650",
    "Longitude": "007.5124630",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.388965,7.512463",
    "IsOpen24Hours": true,
    "id": "51.388965,7.512463"
  },
  {
    "Gas Station Number": "0FU37",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Henleinstraße 35",
    "Postcode": 86368,
    "City": "Gersthofen",
    "State": "Bayern",
    "Latitude": "048.4422230",
    "Longitude": "010.8740330",
    "Telephone Number": "082142092000",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "05:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.442223,10.874033",
    "IsOpen24Hours": false,
    "id": "48.442223,10.874033"
  },
  {
    "Gas Station Number": "0FF76",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Brauckstraße 41",
    "Postcode": 58454,
    "City": "Witten",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4584590",
    "Longitude": "007.3908430",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.458459,7.390843",
    "IsOpen24Hours": true,
    "id": "51.458459,7.390843"
  },
  {
    "Gas Station Number": "0FU72",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 49",
    "Postcode": 53506,
    "City": "Ahrbrück",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.4855440",
    "Longitude": "006.9738780",
    "Telephone Number": "02643/9048899",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "08:00",
    "Saturdays To": "23:59",
    "Sundays From": "08:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "50.485544,6.973878",
    "IsOpen24Hours": false,
    "id": "50.485544,6.973878"
  },
  {
    "Gas Station Number": "0FU65",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 80",
    "Postcode": 64401,
    "City": "Groß-Bieberau",
    "State": "Hessen",
    "Latitude": "049.8073140",
    "Longitude": "008.8252200",
    "Telephone Number": "06162940610",
    "Week From": "05:00",
    "Week To": "23:59",
    "Saturdays From": "05:00",
    "Saturdays To": "23:59",
    "Sundays From": "07:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "49.807314,8.82522",
    "IsOpen24Hours": false,
    "id": "49.807314,8.82522"
  },
  {
    "Gas Station Number": "0FO74",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schussenstraße 10",
    "Postcode": 88250,
    "City": "Weingarten",
    "State": "Baden-Württemberg",
    "Latitude": "047.8120500",
    "Longitude": "009.6407600",
    "Telephone Number": "075145623",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.81205,9.64076",
    "IsOpen24Hours": false,
    "id": "47.81205,9.64076"
  },
  {
    "Gas Station Number": "0FF54",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Hauptstraße 287",
    "Postcode": 58675,
    "City": "Hemer",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3909500",
    "Longitude": "007.7620340",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.39095,7.762034",
    "IsOpen24Hours": true,
    "id": "51.39095,7.762034"
  },
  {
    "Gas Station Number": "0FX26",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dibberser Strasse 30",
    "Postcode": 21244,
    "City": "Buchholz",
    "State": "Niedersachsen",
    "Latitude": "053.3627120",
    "Longitude": "009.8731210",
    "Telephone Number": "041815110",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.362712,9.873121",
    "IsOpen24Hours": true,
    "id": "53.362712,9.873121"
  },
  {
    "Gas Station Number": "0FM23",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bornstraße 221",
    "Postcode": 44145,
    "City": "Dortmund",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.5316790",
    "Longitude": "007.4736190",
    "Telephone Number": "0231810342",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.531679,7.473619",
    "IsOpen24Hours": true,
    "id": "51.531679,7.473619"
  },
  {
    "Gas Station Number": "0FP10",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Essener Straße 29",
    "Postcode": 57234,
    "City": "Wilnsdorf",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8236820",
    "Longitude": "008.0785000",
    "Telephone Number": "027394793763",
    "Week From": "06:30",
    "Week To": "18:00",
    "Saturdays From": "08:00",
    "Saturdays To": "12:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.823682,8.0785",
    "IsOpen24Hours": false,
    "id": "50.823682,8.0785"
  },
  {
    "Gas Station Number": "0FF65",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Bräukerweg 132",
    "Postcode": 58708,
    "City": "Menden",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4367930",
    "Longitude": "007.7580820",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.436793,7.758082",
    "IsOpen24Hours": true,
    "id": "51.436793,7.758082"
  },
  {
    "Gas Station Number": "0FF44",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Hafenstr. 124",
    "Postcode": 59067,
    "City": "Hamm",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6767120",
    "Longitude": "007.7754680",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.676712,7.775468",
    "IsOpen24Hours": true,
    "id": "51.676712,7.775468"
  },
  {
    "Gas Station Number": "0FU87",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A7 / E45",
    "Postcode": 31174,
    "City": "Schellerten",
    "State": "Niedersachsen",
    "Latitude": "052.1261990",
    "Longitude": "010.0518260",
    "Telephone Number": "0512133322",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.126199,10.051826",
    "IsOpen24Hours": true,
    "id": "52.126199,10.051826"
  },
  {
    "Gas Station Number": "0FP65",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Asbacher Straße 17 b",
    "Postcode": 98574,
    "City": "Schmalkalden",
    "State": "Thüringen",
    "Latitude": "050.7193440",
    "Longitude": "010.4734890",
    "Telephone Number": "036834650680",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "05:00",
    "Saturdays To": "22:00",
    "Sundays From": "05:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.719344,10.473489",
    "IsOpen24Hours": false,
    "id": "50.719344,10.473489"
  },
  {
    "Gas Station Number": "0FO47",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Maarweg 126",
    "Postcode": 50825,
    "City": "Köln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.9430890",
    "Longitude": "006.8987960",
    "Telephone Number": "0221541909",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "50.943089,6.898796",
    "IsOpen24Hours": false,
    "id": "50.943089,6.898796"
  },
  {
    "Gas Station Number": "0FN41",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ober-Saulheimer Straße 17",
    "Postcode": 55286,
    "City": "Wörrstadt",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.8526480",
    "Longitude": "008.1231930",
    "Telephone Number": "067321403",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.852648,8.123193",
    "IsOpen24Hours": false,
    "id": "49.852648,8.123193"
  },
  {
    "Gas Station Number": "0FF25",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Franziusstr. 25",
    "Postcode": 60314,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.1115920",
    "Longitude": "008.7286890",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.111592,8.728689",
    "IsOpen24Hours": true,
    "id": "50.111592,8.728689"
  },
  {
    "Gas Station Number": "0FN39",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 23",
    "Postcode": 83339,
    "City": "Chieming",
    "State": "Bayern",
    "Latitude": "047.8903520",
    "Longitude": "012.5372000",
    "Telephone Number": "08664927124",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "47.890352,12.5372",
    "IsOpen24Hours": false,
    "id": "47.890352,12.5372"
  },
  {
    "Gas Station Number": "0FQ57",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Grotwisch 2",
    "Postcode": 24589,
    "City": "Dätgen",
    "State": "Schleswig-Holstein",
    "Latitude": "054.1800570",
    "Longitude": "009.9441700",
    "Telephone Number": "043299136038",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "54.180057,9.94417",
    "IsOpen24Hours": true,
    "id": "54.180057,9.94417"
  },
  {
    "Gas Station Number": "0FO18",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Brückenstraße 6",
    "Postcode": 87616,
    "City": "Marktoberdorf-Thalho",
    "State": "Bayern",
    "Latitude": "047.7735770",
    "Longitude": "010.6035840",
    "Telephone Number": "083425352",
    "Week From": "06:30",
    "Week To": "20:00",
    "Saturdays From": "06:30",
    "Saturdays To": "20:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "47.773577,10.603584",
    "IsOpen24Hours": false,
    "id": "47.773577,10.603584"
  },
  {
    "Gas Station Number": "0FV68",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Warthweg 1-5",
    "Postcode": 61184,
    "City": "Karben",
    "State": "Hessen",
    "Latitude": "050.2380270",
    "Longitude": "008.7486880",
    "Telephone Number": "06039/9391083",
    "Week From": "08:00",
    "Week To": "22:00",
    "Saturdays From": "08:00",
    "Saturdays To": "22:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.238027,8.748688",
    "IsOpen24Hours": false,
    "id": "50.238027,8.748688"
  },
  {
    "Gas Station Number": "0FN97",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Eisenbahnstraße 8",
    "Postcode": 78315,
    "City": "Radolfzell",
    "State": "Baden-Württemberg",
    "Latitude": "047.7415940",
    "Longitude": "008.9547800",
    "Telephone Number": "07732988268",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "47.741594,8.95478",
    "IsOpen24Hours": false,
    "id": "47.741594,8.95478"
  },
  {
    "Gas Station Number": "0FP55",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Züttlinger Straße 5",
    "Postcode": 74219,
    "City": "Möckmühl",
    "State": "Baden-Württemberg",
    "Latitude": "049.3219300",
    "Longitude": "009.3638250",
    "Telephone Number": "062982509",
    "Week From": "04:00",
    "Week To": "22:00",
    "Saturdays From": "04:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.32193,9.363825",
    "IsOpen24Hours": false,
    "id": "49.32193,9.363825"
  },
  {
    "Gas Station Number": "0FN35",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Burghausener Straße 7",
    "Postcode": 84558,
    "City": "Kirchweidach",
    "State": "Bayern",
    "Latitude": "048.0877530",
    "Longitude": "012.6457670",
    "Telephone Number": "08623240",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "08:00",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "48.087753,12.645767",
    "IsOpen24Hours": false,
    "id": "48.087753,12.645767"
  },
  {
    "Gas Station Number": "0FN20",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lessingstraße 2 a",
    "Postcode": "08294",
    "City": "Lößnitz",
    "State": "Sachsen",
    "Latitude": "050.6115230",
    "Longitude": "012.7200640",
    "Telephone Number": "0377133411",
    "Week From": "05:30",
    "Week To": "21:00",
    "Saturdays From": "06:30",
    "Saturdays To": "21:00",
    "Sundays From": "07:30",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.611523,12.720064",
    "IsOpen24Hours": false,
    "id": "50.611523,12.720064"
  },
  {
    "Gas Station Number": "0FV03",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Geschwister-Scholl-Straße 32",
    "Postcode": 39307,
    "City": "Genthin",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.4087850",
    "Longitude": "012.1627450",
    "Telephone Number": "039339482699",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.408785,12.162745",
    "IsOpen24Hours": false,
    "id": "52.408785,12.162745"
  },
  {
    "Gas Station Number": "0FP92",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A31",
    "Postcode": 49835,
    "City": "Wietmarschen",
    "State": "Niedersachsen",
    "Latitude": "052.5286110",
    "Longitude": "007.1968890",
    "Telephone Number": "05925998264",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.528611,7.196889",
    "IsOpen24Hours": true,
    "id": "52.528611,7.196889"
  },
  {
    "Gas Station Number": "0FY20",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 72",
    "Postcode": "08527",
    "City": "Plauen",
    "State": "Sachsen",
    "Latitude": "050.4673088",
    "Longitude": "012.1852238",
    "Telephone Number": "0374141580",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.4673088,12.1852238",
    "IsOpen24Hours": true,
    "id": "50.4673088,12.1852238"
  },
  {
    "Gas Station Number": "0FF05",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Ferdinand-Porsche-Str. 9",
    "Postcode": 60386,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.1219410",
    "Longitude": "008.7570440",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.121941,8.757044",
    "IsOpen24Hours": true,
    "id": "50.121941,8.757044"
  },
  {
    "Gas Station Number": "0FK28",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Altenessener Straße 288",
    "Postcode": 45326,
    "City": "Essen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4923650",
    "Longitude": "007.0098310",
    "Telephone Number": "02018378311",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.492365,7.009831",
    "IsOpen24Hours": true,
    "id": "51.492365,7.009831"
  },
  {
    "Gas Station Number": "0FY05",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Brüder-Grimm-Straße 24",
    "Postcode": 63450,
    "City": "Hanau",
    "State": "Hessen",
    "Latitude": "050.1282830",
    "Longitude": "008.9174510",
    "Telephone Number": "0618124410",
    "Week From": "05:00",
    "Week To": "23:59",
    "Saturdays From": "07:00",
    "Saturdays To": "23:59",
    "Sundays From": "07:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "50.128283,8.917451",
    "IsOpen24Hours": false,
    "id": "50.128283,8.917451"
  },
  {
    "Gas Station Number": "0FU38",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ganseburg 7",
    "Postcode": 35418,
    "City": "Buseck",
    "State": "Hessen",
    "Latitude": "050.5915180",
    "Longitude": "008.7816150",
    "Telephone Number": "064085034120",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.591518,8.781615",
    "IsOpen24Hours": false,
    "id": "50.591518,8.781615"
  },
  {
    "Gas Station Number": "0FS58",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Würzburger Straße 64",
    "Postcode": 74736,
    "City": "Hardheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.6117740",
    "Longitude": "009.4873010",
    "Telephone Number": "0628322390",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.611774,9.487301",
    "IsOpen24Hours": false,
    "id": "49.611774,9.487301"
  },
  {
    "Gas Station Number": "0FN80",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stuttgarter Straße 112 (B",
    "Postcode": 73054,
    "City": "Eislingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.6960900",
    "Longitude": "009.6950350",
    "Telephone Number": "07161821404",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.69609,9.695035",
    "IsOpen24Hours": false,
    "id": "48.69609,9.695035"
  },
  {
    "Gas Station Number": "0FV99",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A6",
    "Postcode": 92278,
    "City": "Illschwang",
    "State": "Bayern",
    "Latitude": "049.4237000",
    "Longitude": "011.6863500",
    "Telephone Number": "09666951680",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.4237,11.68635",
    "IsOpen24Hours": true,
    "id": "49.4237,11.68635"
  },
  {
    "Gas Station Number": "0FN05",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hauptstraße 12",
    "Postcode": 87656,
    "City": "Germaringen",
    "State": "Bayern",
    "Latitude": "047.9242240",
    "Longitude": "010.6689280",
    "Telephone Number": "0834162283",
    "Week From": "06:30",
    "Week To": "20:30",
    "Saturdays From": "07:30",
    "Saturdays To": "20:30",
    "Sundays From": "08:00",
    "Sundays To": "20:30",
    "24 hour?": "Nein",
    "location": "47.924224,10.668928",
    "IsOpen24Hours": false,
    "id": "47.924224,10.668928"
  },
  {
    "Gas Station Number": "0FP03",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Lindenstraße 5",
    "Postcode": 38312,
    "City": "Flöthe",
    "State": "Niedersachsen",
    "Latitude": "052.0762150",
    "Longitude": "010.4954590",
    "Telephone Number": "05339928366",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.076215,10.495459",
    "IsOpen24Hours": false,
    "id": "52.076215,10.495459"
  },
  {
    "Gas Station Number": "0FN70",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lindenstraße 2",
    "Postcode": 9241,
    "City": "Mühlau",
    "State": "Sachsen",
    "Latitude": "050.8985670",
    "Longitude": "012.7742610",
    "Telephone Number": "037226157",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.898567,12.774261",
    "IsOpen24Hours": false,
    "id": "50.898567,12.774261"
  },
  {
    "Gas Station Number": "0FO35",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Molkerei-/Kervenheimer St",
    "Postcode": 47589,
    "City": "Uedem",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.6582440",
    "Longitude": "006.2752040",
    "Telephone Number": "02825539955",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.658244,6.275204",
    "IsOpen24Hours": false,
    "id": "51.658244,6.275204"
  },
  {
    "Gas Station Number": "0FF11",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Dieselstr. 3",
    "Postcode": 63110,
    "City": "Rodgau-Niederroden",
    "State": "Hessen",
    "Latitude": "049.9879060",
    "Longitude": "008.8680040",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.987906,8.868004",
    "IsOpen24Hours": true,
    "id": "49.987906,8.868004"
  },
  {
    "Gas Station Number": "0FF14",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Ferdinand-Porsche-Ring 18",
    "Postcode": 63110,
    "City": "Rodgau",
    "State": "Hessen",
    "Latitude": "050.0193790",
    "Longitude": "008.8946310",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.019379,8.894631",
    "IsOpen24Hours": true,
    "id": "50.019379,8.894631"
  },
  {
    "Gas Station Number": "0FM38",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Salinenstraße 56",
    "Postcode": 99085,
    "City": "Erfurt",
    "State": "Thüringen",
    "Latitude": "051.0068400",
    "Longitude": "011.0454090",
    "Telephone Number": "036126232610",
    "Week From": "04:30",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "07:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "51.00684,11.045409",
    "IsOpen24Hours": false,
    "id": "51.00684,11.045409"
  },
  {
    "Gas Station Number": "0FM24",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hörder Phoenixseeallee 105-109",
    "Postcode": 44263,
    "City": "Dortmund",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4873910",
    "Longitude": "007.5104370",
    "Telephone Number": "0231422063",
    "Week From": "06:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "51.487391,7.510437",
    "IsOpen24Hours": false,
    "id": "51.487391,7.510437"
  },
  {
    "Gas Station Number": "0FU18",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Drachenloch 3",
    "Postcode": 78052,
    "City": "Villingen-Schwenningen",
    "State": "Baden-Württemberg",
    "Latitude": "048.0757660",
    "Longitude": "008.4916480",
    "Telephone Number": "07721/9959187",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.075766,8.491648",
    "IsOpen24Hours": true,
    "id": "48.075766,8.491648"
  },
  {
    "Gas Station Number": "0FO98",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedrich-Engels-Straße 2",
    "Postcode": 51545,
    "City": "Waldbröl",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8905700",
    "Longitude": "007.6474130",
    "Telephone Number": "022912244",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.89057,7.647413",
    "IsOpen24Hours": true,
    "id": "50.89057,7.647413"
  },
  {
    "Gas Station Number": "0FU20",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Ober-Eschbacher Straße 142",
    "Postcode": 61352,
    "City": "Bad Homburg",
    "State": "Hessen",
    "Latitude": "050.2106840",
    "Longitude": "008.6572740",
    "Telephone Number": "061729237530",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "50.210684,8.657274",
    "IsOpen24Hours": false,
    "id": "50.210684,8.657274"
  },
  {
    "Gas Station Number": "0FO02",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kocheler Straße 26",
    "Postcode": 83670,
    "City": "Bad Heilbrunn",
    "State": "Bayern",
    "Latitude": "047.7413270",
    "Longitude": "011.4529680",
    "Telephone Number": "08046188045",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.741327,11.452968",
    "IsOpen24Hours": false,
    "id": "47.741327,11.452968"
  },
  {
    "Gas Station Number": "0FM92",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Naheweinstraße 182",
    "Postcode": 55450,
    "City": "Langenlonsheim",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.9011010",
    "Longitude": "007.8993610",
    "Telephone Number": "06704961595",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.901101,7.899361",
    "IsOpen24Hours": false,
    "id": "49.901101,7.899361"
  },
  {
    "Gas Station Number": "0FU30",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Schellweilerstraße 1",
    "Postcode": 66871,
    "City": "Konken",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.5137800",
    "Longitude": "007.3720800",
    "Telephone Number": "063849933155",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.51378,7.37208",
    "IsOpen24Hours": true,
    "id": "49.51378,7.37208"
  },
  {
    "Gas Station Number": "0FF18",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Josef-Bautz-Str. 34",
    "Postcode": 63450,
    "City": "Hanau",
    "State": "Hessen",
    "Latitude": "050.1143930",
    "Longitude": "008.9268560",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.114393,8.926856",
    "IsOpen24Hours": true,
    "id": "50.114393,8.926856"
  },
  {
    "Gas Station Number": "0FT42",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der Westumgehung 1",
    "Postcode": 85521,
    "City": "Ottobrunn",
    "State": "Bayern",
    "Latitude": "048.0720330",
    "Longitude": "011.6522640",
    "Telephone Number": "08962749631",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "48.072033,11.652264",
    "IsOpen24Hours": false,
    "id": "48.072033,11.652264"
  },
  {
    "Gas Station Number": "0FX58",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A1",
    "Postcode": 21109,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.4938103",
    "Longitude": "010.0339526",
    "Telephone Number": "0407544131",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "53.4938103,10.0339526",
    "IsOpen24Hours": true,
    "id": "53.4938103,10.0339526"
  },
  {
    "Gas Station Number": "0FG35",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A5",
    "Postcode": 77767,
    "City": "Appenweier",
    "State": "Baden-Württemberg",
    "Latitude": "048.5617170",
    "Longitude": "007.9568880",
    "Telephone Number": "078052949",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.561717,7.956888",
    "IsOpen24Hours": true,
    "id": "48.561717,7.956888"
  },
  {
    "Gas Station Number": "0FU93",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A7",
    "Postcode": 21077,
    "City": "Hamburg",
    "State": "Hamburg",
    "Latitude": "053.4234690",
    "Longitude": "009.9670880",
    "Telephone Number": "04076115317",
    "Week From": "00:00",
    "Week To": "23:50",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:50",
    "24 hour?": "Ja",
    "location": "53.423469,9.967088",
    "IsOpen24Hours": true,
    "id": "53.423469,9.967088"
  },
  {
    "Gas Station Number": "0FR23",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "IM BIRKENGRUND",
    "Postcode": 63179,
    "City": "OBERTSHAUSEN",
    "State": "Hessen",
    "Latitude": "050.0625760",
    "Longitude": "008.8297000",
    "Telephone Number": "06104/4052680",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.062576,8.8297",
    "IsOpen24Hours": true,
    "id": "50.062576,8.8297"
  },
  {
    "Gas Station Number": "0FN24",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hixberger Straße 28 a",
    "Postcode": 66292,
    "City": "Riegelsberg",
    "State": "Saarland",
    "Latitude": "049.2952760",
    "Longitude": "006.9342980",
    "Telephone Number": "068063060416",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.295276,6.934298",
    "IsOpen24Hours": false,
    "id": "49.295276,6.934298"
  },
  {
    "Gas Station Number": "0FU68",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Berliner Allee 7",
    "Postcode": 39326,
    "City": "Hohenwarsleben",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.1770710",
    "Longitude": "011.4901250",
    "Telephone Number": "03920460849",
    "Week From": "05:00",
    "Week To": "23:59",
    "Saturdays From": "07:00",
    "Saturdays To": "23:59",
    "Sundays From": "08:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.177071,11.490125",
    "IsOpen24Hours": true,
    "id": "52.177071,11.490125"
  },
  {
    "Gas Station Number": "0FV70",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Viktoriastraße 5",
    "Postcode": 42929,
    "City": "Wermelskirchen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.1348840",
    "Longitude": "007.1998460",
    "Telephone Number": "01786334749",
    "Week From": "08:00",
    "Week To": "22:00",
    "Saturdays From": "08:00",
    "Saturdays To": "22:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.134884,7.199846",
    "IsOpen24Hours": false,
    "id": "51.134884,7.199846"
  },
  {
    "Gas Station Number": "0FM62",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 30",
    "Postcode": 49328,
    "City": "Melle",
    "State": "Niedersachsen",
    "Latitude": "052.1960530",
    "Longitude": "008.3843810",
    "Telephone Number": "0542242010",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.196053,8.384381",
    "IsOpen24Hours": true,
    "id": "52.196053,8.384381"
  },
  {
    "Gas Station Number": "0FF16",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Lindigstraße 6",
    "Postcode": 63801,
    "City": "Kleinostheim",
    "State": "Bayern",
    "Latitude": "050.0244150",
    "Longitude": "009.0416510",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.024415,9.041651",
    "IsOpen24Hours": true,
    "id": "50.024415,9.041651"
  },
  {
    "Gas Station Number": "0FM42",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "B 3 / Am Krekel 3",
    "Postcode": 35039,
    "City": "Marburg",
    "State": "Hessen",
    "Latitude": "050.7937050",
    "Longitude": "008.7607600",
    "Telephone Number": "06421165036",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.793705,8.76076",
    "IsOpen24Hours": true,
    "id": "50.793705,8.76076"
  },
  {
    "Gas Station Number": "0FM68",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gewerbepark 2",
    "Postcode": 91350,
    "City": "Gremsdorf",
    "State": "Bayern",
    "Latitude": "049.6984920",
    "Longitude": "010.8547340",
    "Telephone Number": "09193504495",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.698492,10.854734",
    "IsOpen24Hours": true,
    "id": "49.698492,10.854734"
  },
  {
    "Gas Station Number": "0FN51",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Illerstraße 12",
    "Postcode": 87527,
    "City": "Sonthofen",
    "State": "Bayern",
    "Latitude": "047.5237160",
    "Longitude": "010.2623060",
    "Telephone Number": "083213322",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.523716,10.262306",
    "IsOpen24Hours": false,
    "id": "47.523716,10.262306"
  },
  {
    "Gas Station Number": "0FN16",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Meraner Straße 11",
    "Postcode": 86165,
    "City": "Augsburg",
    "State": "Bayern",
    "Latitude": "048.3816230",
    "Longitude": "010.9324250",
    "Telephone Number": "08217292985",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.381623,10.932425",
    "IsOpen24Hours": false,
    "id": "48.381623,10.932425"
  },
  {
    "Gas Station Number": "0FF10",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Industriestraße 12",
    "Postcode": 63920,
    "City": "Großheubach",
    "State": "Bayern",
    "Latitude": "049.7184820",
    "Longitude": "009.2305650",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.718482,9.230565",
    "IsOpen24Hours": true,
    "id": "49.718482,9.230565"
  },
  {
    "Gas Station Number": "0FU22",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Magdeburger Landstraße 14 a",
    "Postcode": 39638,
    "City": "Gardelegen",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.5178360",
    "Longitude": "011.4124740",
    "Telephone Number": "039077799377",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.517836,11.412474",
    "IsOpen24Hours": true,
    "id": "52.517836,11.412474"
  },
  {
    "Gas Station Number": "0FO33",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lindenstraße 1",
    "Postcode": 89284,
    "City": "Pfaffenhofen",
    "State": "Bayern",
    "Latitude": "048.3587010",
    "Longitude": "010.1601480",
    "Telephone Number": "07302919086",
    "Week From": "06:30",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "20:30",
    "24 hour?": "Nein",
    "location": "48.358701,10.160148",
    "IsOpen24Hours": false,
    "id": "48.358701,10.160148"
  },
  {
    "Gas Station Number": "0FN27",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Breslauer Straße 2",
    "Postcode": 67245,
    "City": "Lambsheim",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.5048230",
    "Longitude": "008.2913970",
    "Telephone Number": "06233579820",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.504823,8.291397",
    "IsOpen24Hours": false,
    "id": "49.504823,8.291397"
  },
  {
    "Gas Station Number": "0FM56",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 1",
    "Postcode": 50354,
    "City": "Hürth",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.8571010",
    "Longitude": "006.8061510",
    "Telephone Number": "022371335",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.857101,6.806151",
    "IsOpen24Hours": true,
    "id": "50.857101,6.806151"
  },
  {
    "Gas Station Number": "0FF20",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Robert-Bosch-Str. 4",
    "Postcode": 64319,
    "City": "Pfungstadt",
    "State": "Hessen",
    "Latitude": "049.8145910",
    "Longitude": "008.6144260",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.814591,8.614426",
    "IsOpen24Hours": true,
    "id": "49.814591,8.614426"
  },
  {
    "Gas Station Number": "0FS59",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Junginger Straße 11",
    "Postcode": 89081,
    "City": "Ulm",
    "State": "Baden-Württemberg",
    "Latitude": "048.4364430",
    "Longitude": "009.9709890",
    "Telephone Number": "07319609671",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "19:30",
    "Sundays From": "08:00",
    "Sundays To": "18:30",
    "24 hour?": "Nein",
    "location": "48.436443,9.970989",
    "IsOpen24Hours": false,
    "id": "48.436443,9.970989"
  },
  {
    "Gas Station Number": "0FF06",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Feldstraße 2",
    "Postcode": 64347,
    "City": "Griesheim",
    "State": "Hessen",
    "Latitude": "049.8674870",
    "Longitude": "008.5545320",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.867487,8.554532",
    "IsOpen24Hours": true,
    "id": "49.867487,8.554532"
  },
  {
    "Gas Station Number": "0FS10",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "SIMBACHER STRAßE 36",
    "Postcode": 84524,
    "City": "NEUÖTTING",
    "State": "Bayern",
    "Latitude": "048.2398640",
    "Longitude": "012.7105280",
    "Telephone Number": "086718839794",
    "Week From": "06:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Ja",
    "location": "48.239864,12.710528",
    "IsOpen24Hours": true,
    "id": "48.239864,12.710528"
  },
  {
    "Gas Station Number": "0FV50",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Riemer Straße 339",
    "Postcode": 81829,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1405380",
    "Longitude": "011.6798480",
    "Telephone Number": "089908681",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.140538,11.679848",
    "IsOpen24Hours": false,
    "id": "48.140538,11.679848"
  },
  {
    "Gas Station Number": "0FU28",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rheiner Str. 176 A",
    "Postcode": 49809,
    "City": "Lingen",
    "State": "Niedersachsen",
    "Latitude": "052.4971750",
    "Longitude": "007.3289700",
    "Telephone Number": "059161047865",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.497175,7.32897",
    "IsOpen24Hours": true,
    "id": "52.497175,7.32897"
  },
  {
    "Gas Station Number": "0FQ91",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Zeche Crone 2",
    "Postcode": 44265,
    "City": "Dortmund",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4723300",
    "Longitude": "007.4853910",
    "Telephone Number": "023142788770",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.47233,7.485391",
    "IsOpen24Hours": true,
    "id": "51.47233,7.485391"
  },
  {
    "Gas Station Number": "0FN73",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schneeberger Straße 83 a",
    "Postcode": 8112,
    "City": "Wilkau-Haßlau",
    "State": "Sachsen",
    "Latitude": "050.6730660",
    "Longitude": "012.5282720",
    "Telephone Number": "0375661265",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.673066,12.528272",
    "IsOpen24Hours": false,
    "id": "50.673066,12.528272"
  },
  {
    "Gas Station Number": "0FY16",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Hietzinger Wiesen 4",
    "Postcode": 94431,
    "City": "Pilsting",
    "State": "Bayern",
    "Latitude": "048.6929000",
    "Longitude": "012.6733700",
    "Telephone Number": "099539807970",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.6929,12.67337",
    "IsOpen24Hours": true,
    "id": "48.6929,12.67337"
  },
  {
    "Gas Station Number": "0FQ85",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Lütgendortmunder Hellweg 200/a.d A4",
    "Postcode": 44892,
    "City": "Bochum",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4940240",
    "Longitude": "007.3394350",
    "Telephone Number": "023432589808",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.494024,7.339435",
    "IsOpen24Hours": true,
    "id": "51.494024,7.339435"
  },
  {
    "Gas Station Number": "0FP88",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Gewerbegebiet Am Dachsberg 8",
    "Postcode": 53604,
    "City": "Bad Honnef",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.6479840",
    "Longitude": "007.3346060",
    "Telephone Number": "022249897088",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.647984,7.334606",
    "IsOpen24Hours": true,
    "id": "50.647984,7.334606"
  },
  {
    "Gas Station Number": "0FV51",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Situlistraße 36",
    "Postcode": 80939,
    "City": "München",
    "State": "Bayern",
    "Latitude": "048.1906680",
    "Longitude": "011.6165570",
    "Telephone Number": "08932195426",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.190668,11.616557",
    "IsOpen24Hours": false,
    "id": "48.190668,11.616557"
  },
  {
    "Gas Station Number": "0FO83",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Langenbacher Straße 18",
    "Postcode": 56470,
    "City": "Bad Marienberg",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.6480290",
    "Longitude": "007.9530690",
    "Telephone Number": "02661931012",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.648029,7.953069",
    "IsOpen24Hours": false,
    "id": "50.648029,7.953069"
  },
  {
    "Gas Station Number": "0FO08",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Moosbronner Straße 37",
    "Postcode": 76275,
    "City": "Ettlingen OT Schöllb",
    "State": "Baden-Württemberg",
    "Latitude": "048.8949900",
    "Longitude": "008.4208430",
    "Telephone Number": "072431855041",
    "Week From": "06:30",
    "Week To": "21:00",
    "Saturdays From": "08:30",
    "Saturdays To": "19:00",
    "Sundays From": "08:30",
    "Sundays To": "19:00",
    "24 hour?": "Nein",
    "location": "48.89499,8.420843",
    "IsOpen24Hours": false,
    "id": "48.89499,8.420843"
  },
  {
    "Gas Station Number": "0FG12",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "BAB 659",
    "Postcode": 68519,
    "City": "Viernheim",
    "State": "Hessen",
    "Latitude": "049.5424970",
    "Longitude": "008.6087540",
    "Telephone Number": "06204919183",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.542497,8.608754",
    "IsOpen24Hours": true,
    "id": "49.542497,8.608754"
  },
  {
    "Gas Station Number": "0FN31",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Odenwaldstraße 7",
    "Postcode": 67551,
    "City": "Worms-Pfeddersheim",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.6343360",
    "Longitude": "008.2809310",
    "Telephone Number": "06247905314",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.634336,8.280931",
    "IsOpen24Hours": false,
    "id": "49.634336,8.280931"
  },
  {
    "Gas Station Number": "0FV15",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Römerfeld 1",
    "Postcode": 71149,
    "City": "Bondorf",
    "State": "Baden-Württemberg",
    "Latitude": "048.5067600",
    "Longitude": "008.8358500",
    "Telephone Number": "074575959090",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.50676,8.83585",
    "IsOpen24Hours": true,
    "id": "48.50676,8.83585"
  },
  {
    "Gas Station Number": "0FG52",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Friedberger Straße 22",
    "Postcode": 35410,
    "City": "Hungen",
    "State": "Hessen",
    "Latitude": "050.4723480",
    "Longitude": "008.8983780",
    "Telephone Number": "064028093512",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.472348,8.898378",
    "IsOpen24Hours": true,
    "id": "50.472348,8.898378"
  },
  {
    "Gas Station Number": "0FF17",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Benzstraße 7",
    "Postcode": 64646,
    "City": "Heppenheim",
    "State": "Hessen",
    "Latitude": "049.6475190",
    "Longitude": "008.6246980",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.647519,8.624698",
    "IsOpen24Hours": true,
    "id": "49.647519,8.624698"
  },
  {
    "Gas Station Number": "0FQ21",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A93",
    "Postcode": 92670,
    "City": "Windischeschenbach",
    "State": "Bayern",
    "Latitude": "049.8169690",
    "Longitude": "012.1706662",
    "Telephone Number": "096819199770",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "49.816969,12.1706662",
    "IsOpen24Hours": true,
    "id": "49.816969,12.1706662"
  },
  {
    "Gas Station Number": "0FN46",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stuttgarter Straße 85",
    "Postcode": 73061,
    "City": "Ebersbach a. d. Fils",
    "State": "Baden-Württemberg",
    "Latitude": "048.7136980",
    "Longitude": "009.5131780",
    "Telephone Number": "07163532228",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.713698,9.513178",
    "IsOpen24Hours": false,
    "id": "48.713698,9.513178"
  },
  {
    "Gas Station Number": "0FF04",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Landdammstr. 44-48",
    "Postcode": 65462,
    "City": "Ginsheim",
    "State": "Hessen",
    "Latitude": "049.9906730",
    "Longitude": "008.3138660",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.990673,8.313866",
    "IsOpen24Hours": true,
    "id": "49.990673,8.313866"
  },
  {
    "Gas Station Number": "0FF12",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Im Schiffelland 24",
    "Postcode": 66386,
    "City": "St. Ingbert",
    "State": "Saarland",
    "Latitude": "049.2692970",
    "Longitude": "007.1307150",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.269297,7.130715",
    "IsOpen24Hours": true,
    "id": "49.269297,7.130715"
  },
  {
    "Gas Station Number": "0FN53",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Seestraße 1",
    "Postcode": 83471,
    "City": "Schönau am Königssee",
    "State": "Bayern",
    "Latitude": "047.5927610",
    "Longitude": "012.9874410",
    "Telephone Number": "0865295630",
    "Week From": "06:30",
    "Week To": "21:00",
    "Saturdays From": "06:30",
    "Saturdays To": "21:00",
    "Sundays From": "06:30",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "47.592761,12.987441",
    "IsOpen24Hours": false,
    "id": "47.592761,12.987441"
  },
  {
    "Gas Station Number": "0FO50",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kattegatweg 1",
    "Postcode": 46446,
    "City": "Emmerich",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.8802660",
    "Longitude": "006.1736780",
    "Telephone Number": "02828903520",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.880266,6.173678",
    "IsOpen24Hours": true,
    "id": "51.880266,6.173678"
  },
  {
    "Gas Station Number": "0FR43",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Pflaumenallee 1",
    "Postcode": 39343,
    "City": "Uhrsleben",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.1938050",
    "Longitude": "011.2634910",
    "Telephone Number": "0390529600",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.193805,11.263491",
    "IsOpen24Hours": true,
    "id": "52.193805,11.263491"
  },
  {
    "Gas Station Number": "0FO91",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Konrad-Adenauer-Straße 192",
    "Postcode": 57572,
    "City": "Niederfischbach",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.8621150",
    "Longitude": "007.8856190",
    "Telephone Number": "02734571767",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.862115,7.885619",
    "IsOpen24Hours": false,
    "id": "50.862115,7.885619"
  },
  {
    "Gas Station Number": "0FG45",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 19",
    "Postcode": 63691,
    "City": "Ranstadt",
    "State": "Hessen",
    "Latitude": "050.3550520",
    "Longitude": "008.9828980",
    "Telephone Number": "06041828272",
    "Week From": "04:30",
    "Week To": "21:30",
    "Saturdays From": "06:00",
    "Saturdays To": "21:30",
    "Sundays From": "07:00",
    "Sundays To": "21:30",
    "24 hour?": "Nein",
    "location": "50.355052,8.982898",
    "IsOpen24Hours": false,
    "id": "50.355052,8.982898"
  },
  {
    "Gas Station Number": "0FO03",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Herzog-Otto-Straße 11",
    "Postcode": 83308,
    "City": "Trostberg",
    "State": "Bayern",
    "Latitude": "048.0252800",
    "Longitude": "012.5416660",
    "Telephone Number": "086216482287",
    "Week From": "07:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "09:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.02528,12.541666",
    "IsOpen24Hours": false,
    "id": "48.02528,12.541666"
  },
  {
    "Gas Station Number": "0FM47",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Schlesische Straße 75",
    "Postcode": 58636,
    "City": "Iserlohn",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3779950",
    "Longitude": "007.7284090",
    "Telephone Number": "0237162222",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "05:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Ja",
    "location": "51.377995,7.728409",
    "IsOpen24Hours": true,
    "id": "51.377995,7.728409"
  },
  {
    "Gas Station Number": "0FM65",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Am Tannenwald 4",
    "Postcode": 66459,
    "City": "Kirkel",
    "State": "Saarland",
    "Latitude": "049.2878000",
    "Longitude": "007.2178600",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.2878,7.21786",
    "IsOpen24Hours": true,
    "id": "49.2878,7.21786"
  },
  {
    "Gas Station Number": "0FM51",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Grünwalder Weg 42",
    "Postcode": 82008,
    "City": "Unterhaching",
    "State": "Bayern",
    "Latitude": "048.0568470",
    "Longitude": "011.5995910",
    "Telephone Number": "08961100544",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "48.056847,11.599591",
    "IsOpen24Hours": false,
    "id": "48.056847,11.599591"
  },
  {
    "Gas Station Number": "0FN42",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Poststraße 40",
    "Postcode": 76534,
    "City": "Baden-Baden",
    "State": "Baden-Württemberg",
    "Latitude": "048.7292950",
    "Longitude": "008.1560180",
    "Telephone Number": "0722396420",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.729295,8.156018",
    "IsOpen24Hours": false,
    "id": "48.729295,8.156018"
  },
  {
    "Gas Station Number": "0FN01",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Krumbacher Straße 54",
    "Postcode": 89335,
    "City": "Ichenhausen",
    "State": "Bayern",
    "Latitude": "048.3658070",
    "Longitude": "010.3059670",
    "Telephone Number": "082235222",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.365807,10.305967",
    "IsOpen24Hours": false,
    "id": "48.365807,10.305967"
  },
  {
    "Gas Station Number": "0FN25",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kaiserstraße 11",
    "Postcode": 67816,
    "City": "Standenbühl",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.5940530",
    "Longitude": "007.9852730",
    "Telephone Number": "06357989117",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "18:00",
    "Sundays From": "08:00",
    "Sundays To": "18:00",
    "24 hour?": "Nein",
    "location": "49.594053,7.985273",
    "IsOpen24Hours": false,
    "id": "49.594053,7.985273"
  },
  {
    "Gas Station Number": "0FT79",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Vardeler Weg 1/B69",
    "Postcode": 49377,
    "City": "Vechta",
    "State": "Niedersachsen",
    "Latitude": "052.7560300",
    "Longitude": "008.2741480",
    "Telephone Number": "044418545212",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.75603,8.274148",
    "IsOpen24Hours": true,
    "id": "52.75603,8.274148"
  },
  {
    "Gas Station Number": "0FQ83",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Gustav-Stresemann-Straße",
    "Postcode": 55411,
    "City": "Bingen",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.9250730",
    "Longitude": "007.9367500",
    "Telephone Number": "067219628623",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.925073,7.93675",
    "IsOpen24Hours": true,
    "id": "49.925073,7.93675"
  },
  {
    "Gas Station Number": "0FV69",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Weidenbach 31",
    "Postcode": 53229,
    "City": "Bonn-Beuel",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.7429040",
    "Longitude": "007.1580380",
    "Telephone Number": "017672608265",
    "Week From": "07:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.742904,7.158038",
    "IsOpen24Hours": false,
    "id": "50.742904,7.158038"
  },
  {
    "Gas Station Number": "0FV67",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Feldstraße 46",
    "Postcode": 63110,
    "City": "Rodgau",
    "State": "Hessen",
    "Latitude": "050.0029870",
    "Longitude": "008.8851980",
    "Telephone Number": "06106/7795031",
    "Week From": "07:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.002987,8.885198",
    "IsOpen24Hours": false,
    "id": "50.002987,8.885198"
  },
  {
    "Gas Station Number": "0FP78",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "In den Siebenmorgen 2c",
    "Postcode": 56077,
    "City": "Koblenz",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.3712520",
    "Longitude": "007.6454310",
    "Telephone Number": "02616710307",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "50.371252,7.645431",
    "IsOpen24Hours": false,
    "id": "50.371252,7.645431"
  },
  {
    "Gas Station Number": "0FP82",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Im Stöckmädle 29",
    "Postcode": 76307,
    "City": "Karlsbad-Ittersbach",
    "State": "Baden-Württemberg",
    "Latitude": "048.8804200",
    "Longitude": "008.5100050",
    "Telephone Number": "07248934163",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "48.88042,8.510005",
    "IsOpen24Hours": false,
    "id": "48.88042,8.510005"
  },
  {
    "Gas Station Number": "0F041",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Roedernallee 179",
    "Postcode": 13407,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.5791290",
    "Longitude": "013.3463920",
    "Telephone Number": "03040910920",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.579129,13.346392",
    "IsOpen24Hours": true,
    "id": "52.579129,13.346392"
  },
  {
    "Gas Station Number": "0FN23",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Neunkirchener Straße 2",
    "Postcode": 7952,
    "City": "Pausa",
    "State": "Thüringen",
    "Latitude": "050.5879230",
    "Longitude": "011.9981850",
    "Telephone Number": "0374327739",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "09:00",
    "Sundays To": "17:00",
    "24 hour?": "Nein",
    "location": "50.587923,11.998185",
    "IsOpen24Hours": false,
    "id": "50.587923,11.998185"
  },
  {
    "Gas Station Number": "0FT92",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "An der B9",
    "Postcode": 56656,
    "City": "Brohl-Lützing",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.4862870",
    "Longitude": "007.3290600",
    "Telephone Number": "026334729318",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.486287,7.32906",
    "IsOpen24Hours": false,
    "id": "50.486287,7.32906"
  },
  {
    "Gas Station Number": "0FU29",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Steinkampstraße 50",
    "Postcode": 45476,
    "City": "Mülheim an der Ruhr",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4474880",
    "Longitude": "006.8459530",
    "Telephone Number": "020837749841",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.447488,6.845953",
    "IsOpen24Hours": true,
    "id": "51.447488,6.845953"
  },
  {
    "Gas Station Number": "0FN44",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Stuttgarter Straße 54",
    "Postcode": 75417,
    "City": "Mühlacker",
    "State": "Baden-Württemberg",
    "Latitude": "048.9465310",
    "Longitude": "008.8464220",
    "Telephone Number": "070413100",
    "Week From": "05:30",
    "Week To": "22:00",
    "Saturdays From": "05:30",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.946531,8.846422",
    "IsOpen24Hours": false,
    "id": "48.946531,8.846422"
  },
  {
    "Gas Station Number": "0FT84",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Im Gewerbepark 2",
    "Postcode": 49326,
    "City": "Melle",
    "State": "Niedersachsen",
    "Latitude": "052.2191270",
    "Longitude": "008.2726100",
    "Telephone Number": "05422942012",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.219127,8.27261",
    "IsOpen24Hours": true,
    "id": "52.219127,8.27261"
  },
  {
    "Gas Station Number": "0FU66",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Salzer Straße 18 d",
    "Postcode": 39240,
    "City": "Calbe",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.9124020",
    "Longitude": "011.7775680",
    "Telephone Number": "03929177800",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.912402,11.777568",
    "IsOpen24Hours": true,
    "id": "51.912402,11.777568"
  },
  {
    "Gas Station Number": "0FG47",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Fauerbacher Straße 95",
    "Postcode": 61169,
    "City": "Friedberg-Fauerbach",
    "State": "Hessen",
    "Latitude": "050.3302240",
    "Longitude": "008.7685190",
    "Telephone Number": "06031719730",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:00",
    "Sundays From": "06:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "50.330224,8.768519",
    "IsOpen24Hours": false,
    "id": "50.330224,8.768519"
  },
  {
    "Gas Station Number": "0FF23",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Kaiserwörthdamm 3",
    "Postcode": 67065,
    "City": "Ludwigshafen",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.4613590",
    "Longitude": "008.4345610",
    "Telephone Number": "-",
    "Week From": "07:00",
    "Week To": "17:00",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.461359,8.434561",
    "IsOpen24Hours": false,
    "id": "49.461359,8.434561"
  },
  {
    "Gas Station Number": "0FU49",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Weinstraße 4",
    "Postcode": 67480,
    "City": "Edenkoben",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.2784640",
    "Longitude": "008.1193830",
    "Telephone Number": "063232700",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "07:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.278464,8.119383",
    "IsOpen24Hours": false,
    "id": "49.278464,8.119383"
  },
  {
    "Gas Station Number": "0FP62",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mockstädter Straße 2",
    "Postcode": 61197,
    "City": "Florstadt-Staden",
    "State": "Hessen",
    "Latitude": "050.3299550",
    "Longitude": "008.9141250",
    "Telephone Number": "060357114",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.329955,8.914125",
    "IsOpen24Hours": true,
    "id": "50.329955,8.914125"
  },
  {
    "Gas Station Number": "0FF07",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Stockholmer Str. 17",
    "Postcode": 67346,
    "City": "Speyer",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.2938140",
    "Longitude": "008.4772640",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.293814,8.477264",
    "IsOpen24Hours": true,
    "id": "49.293814,8.477264"
  },
  {
    "Gas Station Number": "0FM08",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 7",
    "Postcode": 34593,
    "City": "Knüllwald",
    "State": "Hessen",
    "Latitude": "051.0368350",
    "Longitude": "009.4877600",
    "Telephone Number": "05685922290",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.036835,9.48776",
    "IsOpen24Hours": true,
    "id": "51.036835,9.48776"
  },
  {
    "Gas Station Number": "0FO17",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Landsberger Straße 2",
    "Postcode": 86836,
    "City": "Graben",
    "State": "Bayern",
    "Latitude": "048.1986740",
    "Longitude": "010.8539490",
    "Telephone Number": "082322230",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.198674,10.853949",
    "IsOpen24Hours": true,
    "id": "48.198674,10.853949"
  },
  {
    "Gas Station Number": "0FT48",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hoeber-u.Mandelbaumstr. 1",
    "Postcode": 68794,
    "City": "Oberhausen-Rheinhaus",
    "State": "Baden-Württemberg",
    "Latitude": "049.2504550",
    "Longitude": "008.5008380",
    "Telephone Number": "072542031920",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.250455,8.500838",
    "IsOpen24Hours": true,
    "id": "49.250455,8.500838"
  },
  {
    "Gas Station Number": "0FO09",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Auf der Gänsmatt 14",
    "Postcode": 79650,
    "City": "Schopfheim",
    "State": "Baden-Württemberg",
    "Latitude": "047.6469010",
    "Longitude": "007.8071440",
    "Telephone Number": "076224900",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "47.646901,7.807144",
    "IsOpen24Hours": false,
    "id": "47.646901,7.807144"
  },
  {
    "Gas Station Number": "0FO82",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kölner Straße 35",
    "Postcode": 57610,
    "City": "Altenkirchen",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.6868080",
    "Longitude": "007.6340160",
    "Telephone Number": "02681987630",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.686808,7.634016",
    "IsOpen24Hours": true,
    "id": "50.686808,7.634016"
  },
  {
    "Gas Station Number": "0FN21",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Colditzer Straße 7 c",
    "Postcode": 9306,
    "City": "Rochlitz",
    "State": "Sachsen",
    "Latitude": "051.0611480",
    "Longitude": "012.7868070",
    "Telephone Number": "0373741351",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "07:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.061148,12.786807",
    "IsOpen24Hours": false,
    "id": "51.061148,12.786807"
  },
  {
    "Gas Station Number": "0FG58",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Von-Ketteler-Straße 4",
    "Postcode": 96049,
    "City": "Bamberg",
    "State": "Bayern",
    "Latitude": "049.8865990",
    "Longitude": "010.9218480",
    "Telephone Number": "09519172722",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.886599,10.921848",
    "IsOpen24Hours": true,
    "id": "49.886599,10.921848"
  },
  {
    "Gas Station Number": "0FO16",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Pasinger Straße 50",
    "Postcode": 82166,
    "City": "Gräfelfing",
    "State": "Bayern",
    "Latitude": "048.1180980",
    "Longitude": "011.4426650",
    "Telephone Number": "0898543576",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.118098,11.442665",
    "IsOpen24Hours": false,
    "id": "48.118098,11.442665"
  },
  {
    "Gas Station Number": "0FN08",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Memminger Straße 182",
    "Postcode": 89231,
    "City": "Neu-Ulm",
    "State": "Bayern",
    "Latitude": "048.3662500",
    "Longitude": "010.0098520",
    "Telephone Number": "073183034",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.36625,10.009852",
    "IsOpen24Hours": false,
    "id": "48.36625,10.009852"
  },
  {
    "Gas Station Number": "0FO20",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mindelheimer Straße 19",
    "Postcode": 87772,
    "City": "Pfaffenhausen",
    "State": "Bayern",
    "Latitude": "048.1203660",
    "Longitude": "010.4620150",
    "Telephone Number": "082657332333",
    "Week From": "06:30",
    "Week To": "22:00",
    "Saturdays From": "06:30",
    "Saturdays To": "22:00",
    "Sundays From": "07:30",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.120366,10.462015",
    "IsOpen24Hours": false,
    "id": "48.120366,10.462015"
  },
  {
    "Gas Station Number": "0FN13",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dillinger Straße 4 1/2",
    "Postcode": 89415,
    "City": "Lauingen",
    "State": "Bayern",
    "Latitude": "048.5732230",
    "Longitude": "010.4343930",
    "Telephone Number": "090722423",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "07:00",
    "Saturdays To": "23:59",
    "Sundays From": "07:30",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "48.573223,10.434393",
    "IsOpen24Hours": false,
    "id": "48.573223,10.434393"
  },
  {
    "Gas Station Number": "0FM59",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dammfeldstraße 1",
    "Postcode": 31275,
    "City": "Lehrte",
    "State": "Niedersachsen",
    "Latitude": "052.3982600",
    "Longitude": "009.9736000",
    "Telephone Number": "051323523",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.39826,9.9736",
    "IsOpen24Hours": false,
    "id": "52.39826,9.9736"
  },
  {
    "Gas Station Number": "0FM32",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Zeppelinstraße 9-11",
    "Postcode": 61118,
    "City": "Bad Vilbel",
    "State": "Hessen",
    "Latitude": "050.1939440",
    "Longitude": "008.7249660",
    "Telephone Number": "06101557525",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.193944,8.724966",
    "IsOpen24Hours": true,
    "id": "50.193944,8.724966"
  },
  {
    "Gas Station Number": "0FU99",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sondershäuser Straße 2",
    "Postcode": 99947,
    "City": "Bad Langensalza",
    "State": "Thüringen",
    "Latitude": "051.1134950",
    "Longitude": "010.6592040",
    "Telephone Number": "036038953177",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "51.113495,10.659204",
    "IsOpen24Hours": false,
    "id": "51.113495,10.659204"
  },
  {
    "Gas Station Number": "0FP81",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Beisenbusch 2",
    "Postcode": 48301,
    "City": "Nottuln",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.9121180",
    "Longitude": "007.3992780",
    "Telephone Number": "02509990460",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.912118,7.399278",
    "IsOpen24Hours": true,
    "id": "51.912118,7.399278"
  },
  {
    "Gas Station Number": "0FU05",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Opelstraße 1",
    "Postcode": 78467,
    "City": "Konstanz",
    "State": "Baden-Württemberg",
    "Latitude": "047.6760900",
    "Longitude": "009.1531300",
    "Telephone Number": "07531/1272460",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "47.67609,9.15313",
    "IsOpen24Hours": false,
    "id": "47.67609,9.15313"
  },
  {
    "Gas Station Number": "0FM14",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Königsbrücker Straße 25",
    "Postcode": 1561,
    "City": "Schönfeld",
    "State": "Sachsen",
    "Latitude": "051.2954160",
    "Longitude": "013.7300640",
    "Telephone Number": "03524888447",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.295416,13.730064",
    "IsOpen24Hours": true,
    "id": "51.295416,13.730064"
  },
  {
    "Gas Station Number": "0FV71",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Fischbecker Landstr. 91 (B83)",
    "Postcode": 31787,
    "City": "Hameln",
    "State": "Niedersachsen",
    "Latitude": "052.1212520",
    "Longitude": "009.3347040",
    "Telephone Number": "051511079824",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.121252,9.334704",
    "IsOpen24Hours": true,
    "id": "52.121252,9.334704"
  },
  {
    "Gas Station Number": "0FV85",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nürtinger Straße 24",
    "Postcode": 72622,
    "City": "Nürtingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.6180640",
    "Longitude": "009.3131560",
    "Telephone Number": "070225029310",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "07:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.618064,9.313156",
    "IsOpen24Hours": false,
    "id": "48.618064,9.313156"
  },
  {
    "Gas Station Number": "0FS60",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Dormettinger Straße 13",
    "Postcode": 72359,
    "City": "Dotternhausen",
    "State": "Baden-Württemberg",
    "Latitude": "048.2273960",
    "Longitude": "008.7833170",
    "Telephone Number": "0742791015",
    "Week From": "04:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.227396,8.783317",
    "IsOpen24Hours": false,
    "id": "48.227396,8.783317"
  },
  {
    "Gas Station Number": "0FN99",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Rosensteinstraße 1",
    "Postcode": 70806,
    "City": "Kornwestheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.8563010",
    "Longitude": "009.1855610",
    "Telephone Number": "0715422060",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.856301,9.185561",
    "IsOpen24Hours": false,
    "id": "48.856301,9.185561"
  },
  {
    "Gas Station Number": "0FV07",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Glanstraße 44",
    "Postcode": 66869,
    "City": "Kusel",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.5397600",
    "Longitude": "007.4131750",
    "Telephone Number": "06381/429715",
    "Week From": "05:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "07:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "49.53976,7.413175",
    "IsOpen24Hours": false,
    "id": "49.53976,7.413175"
  },
  {
    "Gas Station Number": "0FM30",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Oppelner Straße 203",
    "Postcode": 90473,
    "City": "Nürnberg",
    "State": "Bayern",
    "Latitude": "049.4022380",
    "Longitude": "011.1335970",
    "Telephone Number": "09119898948",
    "Week From": "08:00",
    "Week To": "21:00",
    "Saturdays From": "09:00",
    "Saturdays To": "21:00",
    "Sundays From": "09:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "49.402238,11.133597",
    "IsOpen24Hours": false,
    "id": "49.402238,11.133597"
  },
  {
    "Gas Station Number": "0FG68",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Hamburger Straße 65",
    "Postcode": 38114,
    "City": "Braunschweig",
    "State": "Niedersachsen",
    "Latitude": "052.2853700",
    "Longitude": "010.5185500",
    "Telephone Number": "05312396801",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "52.28537,10.51855",
    "IsOpen24Hours": true,
    "id": "52.28537,10.51855"
  },
  {
    "Gas Station Number": "0FF61",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Motorstr. 21",
    "Postcode": 70499,
    "City": "Stuttgart",
    "State": "Baden-Württemberg",
    "Latitude": "048.8281180",
    "Longitude": "009.1091000",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.828118,9.1091",
    "IsOpen24Hours": true,
    "id": "48.828118,9.1091"
  },
  {
    "Gas Station Number": "0FM19",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Südliche Ringstraße 62",
    "Postcode": 85053,
    "City": "Ingolstadt",
    "State": "Bayern",
    "Latitude": "048.7613520",
    "Longitude": "011.4404930",
    "Telephone Number": "084162593",
    "Week From": "05:00",
    "Week To": "23:00",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "07:00",
    "Sundays To": "23:00",
    "24 hour?": "Nein",
    "location": "48.761352,11.440493",
    "IsOpen24Hours": false,
    "id": "48.761352,11.440493"
  },
  {
    "Gas Station Number": "0FV02",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Baukreativstraße 7",
    "Postcode": 91628,
    "City": "Steinsfeld-Endsee",
    "State": "Bayern",
    "Latitude": "049.4446600",
    "Longitude": "010.2509310",
    "Telephone Number": "",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.44466,10.250931",
    "IsOpen24Hours": true,
    "id": "49.44466,10.250931"
  },
  {
    "Gas Station Number": "0FT40",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Nienhagener Straße 13",
    "Postcode": 29339,
    "City": "Wathlingen",
    "State": "Niedersachsen",
    "Latitude": "052.5408030",
    "Longitude": "010.1415190",
    "Telephone Number": "05144681",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "52.540803,10.141519",
    "IsOpen24Hours": false,
    "id": "52.540803,10.141519"
  },
  {
    "Gas Station Number": "0FO87",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Robert-Bosch-Str. 1",
    "Postcode": 73257,
    "City": "Köngen",
    "State": "Baden-Württemberg",
    "Latitude": "048.6850890",
    "Longitude": "009.3736760",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.685089,9.373676",
    "IsOpen24Hours": true,
    "id": "48.685089,9.373676"
  },
  {
    "Gas Station Number": "0FV94",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Magdeburger Straße 16",
    "Postcode": 6484,
    "City": "Quedlinburg",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.7900600",
    "Longitude": "011.1691400",
    "Telephone Number": "039469072182",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.79006,11.16914",
    "IsOpen24Hours": true,
    "id": "51.79006,11.16914"
  },
  {
    "Gas Station Number": "0FT93",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Oldenburger Str. 290",
    "Postcode": 26203,
    "City": "Wardenburg",
    "State": "Niedersachsen",
    "Latitude": "053.0569020",
    "Longitude": "008.1995960",
    "Telephone Number": "04407998070",
    "Week From": "06:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "53.056902,8.199596",
    "IsOpen24Hours": false,
    "id": "53.056902,8.199596"
  },
  {
    "Gas Station Number": "0FV76",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Buehlweg 1",
    "Postcode": 95482,
    "City": "Gefrees",
    "State": "Bayern",
    "Latitude": "050.0940510",
    "Longitude": "011.7316390",
    "Telephone Number": "092549616926",
    "Week From": "05:00",
    "Week To": "23:59",
    "Saturdays From": "06:00",
    "Saturdays To": "23:59",
    "Sundays From": "06:00",
    "Sundays To": "23:59",
    "24 hour?": "Nein",
    "location": "50.094051,11.731639",
    "IsOpen24Hours": false,
    "id": "50.094051,11.731639"
  },
  {
    "Gas Station Number": "0FP77",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bitscher Straße 20a",
    "Postcode": 66482,
    "City": "Zweibrücken",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.2355240",
    "Longitude": "007.3619550",
    "Telephone Number": "06332470736",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "07:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.235524,7.361955",
    "IsOpen24Hours": false,
    "id": "49.235524,7.361955"
  },
  {
    "Gas Station Number": "0FV55",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Holderäcker 1",
    "Postcode": 73527,
    "City": "Schwäbisch Gmünd",
    "State": "Baden-Württemberg",
    "Latitude": "048.8263000",
    "Longitude": "009.8540070",
    "Telephone Number": "071719718440",
    "Week From": "05:30",
    "Week To": "21:00",
    "Saturdays From": "06:30",
    "Saturdays To": "21:00",
    "Sundays From": "08:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "48.8263,9.854007",
    "IsOpen24Hours": false,
    "id": "48.8263,9.854007"
  },
  {
    "Gas Station Number": "0FG54",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Porschestraße 3",
    "Postcode": 71634,
    "City": "Ludwigsburg",
    "State": "Baden-Württemberg",
    "Latitude": "048.9205040",
    "Longitude": "009.1505730",
    "Telephone Number": "071416432890",
    "Week From": "07:00",
    "Week To": "22:00",
    "Saturdays From": "08:00",
    "Saturdays To": "22:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.920504,9.150573",
    "IsOpen24Hours": false,
    "id": "48.920504,9.150573"
  },
  {
    "Gas Station Number": "0FM61",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A6",
    "Postcode": 66914,
    "City": "Waldmohr",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.3728080",
    "Longitude": "007.3754100",
    "Telephone Number": "063737514",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.372808,7.37541",
    "IsOpen24Hours": true,
    "id": "49.372808,7.37541"
  },
  {
    "Gas Station Number": "0FF59",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Bertha-Benz-Str.",
    "Postcode": 74343,
    "City": "Sachsenheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.9578790",
    "Longitude": "009.0817670",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.957879,9.081767",
    "IsOpen24Hours": true,
    "id": "48.957879,9.081767"
  },
  {
    "Gas Station Number": "0FN61",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kötzschenbroder Str. 193",
    "Postcode": 1139,
    "City": "Dresden",
    "State": "Sachsen",
    "Latitude": "051.0926010",
    "Longitude": "013.6705010",
    "Telephone Number": "03518371511",
    "Week From": "06:00",
    "Week To": "20:00",
    "Saturdays From": "06:00",
    "Saturdays To": "20:00",
    "Sundays From": "07:00",
    "Sundays To": "20:00",
    "24 hour?": "Nein",
    "location": "51.092601,13.670501",
    "IsOpen24Hours": false,
    "id": "51.092601,13.670501"
  },
  {
    "Gas Station Number": "0FT82",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Industriestraße",
    "Postcode": 84130,
    "City": "Dingolfing",
    "State": "Bayern",
    "Latitude": "048.6477200",
    "Longitude": "012.4534540",
    "Telephone Number": "",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.64772,12.453454",
    "IsOpen24Hours": true,
    "id": "48.64772,12.453454"
  },
  {
    "Gas Station Number": "0FN33",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Sonnenfeld 2",
    "Postcode": 83395,
    "City": "Freilassing",
    "State": "Bayern",
    "Latitude": "047.8429010",
    "Longitude": "012.9709010",
    "Telephone Number": "086547778780",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.842901,12.970901",
    "IsOpen24Hours": false,
    "id": "47.842901,12.970901"
  },
  {
    "Gas Station Number": "0FP17",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Kleinsendelbacher Str. 12",
    "Postcode": 91077,
    "City": "Neunkirchen am Brand",
    "State": "Bayern",
    "Latitude": "049.6041420",
    "Longitude": "011.1380340",
    "Telephone Number": "09134906906",
    "Week From": "06:00",
    "Week To": "21:30",
    "Saturdays From": "07:00",
    "Saturdays To": "21:30",
    "Sundays From": "08:00",
    "Sundays To": "21:30",
    "24 hour?": "Nein",
    "location": "49.604142,11.138034",
    "IsOpen24Hours": false,
    "id": "49.604142,11.138034"
  },
  {
    "Gas Station Number": "0FP91",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 61 West",
    "Postcode": 50181,
    "City": "Bedburg",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.0051410",
    "Longitude": "006.5222700",
    "Telephone Number": "02272407650",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.005141,6.52227",
    "IsOpen24Hours": true,
    "id": "51.005141,6.52227"
  },
  {
    "Gas Station Number": "0FF71",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Bunsenstr. 3",
    "Postcode": 85053,
    "City": "Ingolstadt",
    "State": "Bayern",
    "Latitude": "048.7426060",
    "Longitude": "011.4739100",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.742606,11.47391",
    "IsOpen24Hours": true,
    "id": "48.742606,11.47391"
  },
  {
    "Gas Station Number": "0FV58",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Leibnizstraße 14",
    "Postcode": 97204,
    "City": "Höchberg",
    "State": "Bayern",
    "Latitude": "049.7686830",
    "Longitude": "009.8657800",
    "Telephone Number": "0931/61943190",
    "Week From": "07:00",
    "Week To": "20:00",
    "Saturdays From": "07:00",
    "Saturdays To": "20:00",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.768683,9.86578",
    "IsOpen24Hours": false,
    "id": "49.768683,9.86578"
  },
  {
    "Gas Station Number": "0FP89",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "An der BAB 5",
    "Postcode": 79258,
    "City": "Hartheim-Bremgarten",
    "State": "Baden-Württemberg",
    "Latitude": "047.9082590",
    "Longitude": "007.5923190",
    "Telephone Number": "076339202116",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.908259,7.592319",
    "IsOpen24Hours": true,
    "id": "47.908259,7.592319"
  },
  {
    "Gas Station Number": "0FU76",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Industriestraße 7a",
    "Postcode": 36275,
    "City": "Kirchheim",
    "State": "Hessen",
    "Latitude": "050.8333193",
    "Longitude": "009.5703069",
    "Telephone Number": "06625632",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "06:00",
    "Saturdays To": "22:00",
    "Sundays From": "06:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.8333193,9.5703069",
    "IsOpen24Hours": false,
    "id": "50.8333193,9.5703069"
  },
  {
    "Gas Station Number": "0FU80",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A7",
    "Postcode": 88451,
    "City": "Dettingen an der Iller",
    "State": "Baden-Württemberg",
    "Latitude": "048.1238570",
    "Longitude": "010.1125590",
    "Telephone Number": "07354576",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.123857,10.112559",
    "IsOpen24Hours": true,
    "id": "48.123857,10.112559"
  },
  {
    "Gas Station Number": "0FF72",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Freisinger Str. 27",
    "Postcode": 85386,
    "City": "Eching",
    "State": "Bayern",
    "Latitude": "048.3076560",
    "Longitude": "011.6470540",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.307656,11.647054",
    "IsOpen24Hours": true,
    "id": "48.307656,11.647054"
  },
  {
    "Gas Station Number": "0FG61",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Winckeläckerstr.",
    "Postcode": 86807,
    "City": "Buchloe",
    "State": "Bayern",
    "Latitude": "048.0283080",
    "Longitude": "010.7067700",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.028308,10.70677",
    "IsOpen24Hours": true,
    "id": "48.028308,10.70677"
  },
  {
    "Gas Station Number": "0FU73",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Teuringer Straße 16",
    "Postcode": 88045,
    "City": "Friedrichshafen",
    "State": "Baden-Württemberg",
    "Latitude": "047.6706750",
    "Longitude": "009.4802510",
    "Telephone Number": "0754155599",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "47.670675,9.480251",
    "IsOpen24Hours": false,
    "id": "47.670675,9.480251"
  },
  {
    "Gas Station Number": "0FU53",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A66",
    "Postcode": 36381,
    "City": "Schlüchtern",
    "State": "Hessen",
    "Latitude": "050.3875520",
    "Longitude": "009.5261440",
    "Telephone Number": "066619164695",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "50.387552,9.526144",
    "IsOpen24Hours": true,
    "id": "50.387552,9.526144"
  },
  {
    "Gas Station Number": "0FN56",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Günzburger Straße 55",
    "Postcode": 89335,
    "City": "Ichenhausen",
    "State": "Bayern",
    "Latitude": "048.3740010",
    "Longitude": "010.3113010",
    "Telephone Number": "082231281",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "48.374001,10.311301",
    "IsOpen24Hours": false,
    "id": "48.374001,10.311301"
  },
  {
    "Gas Station Number": "0FF60",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Peter-Henlein-Str.",
    "Postcode": 89312,
    "City": "Günzburg",
    "State": "Bayern",
    "Latitude": "048.4734330",
    "Longitude": "010.2733520",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.473433,10.273352",
    "IsOpen24Hours": true,
    "id": "48.473433,10.273352"
  },
  {
    "Gas Station Number": "0FQ67",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Werner-v.-Siemens-Str. 31",
    "Postcode": 89415,
    "City": "Lauingen an der Donau",
    "State": "Bayern",
    "Latitude": "048.5754830",
    "Longitude": "010.4546100",
    "Telephone Number": "090727017866",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "48.575483,10.45461",
    "IsOpen24Hours": true,
    "id": "48.575483,10.45461"
  },
  {
    "Gas Station Number": "0FT72",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Heronger Feld  9",
    "Postcode": 47638,
    "City": "Straelen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.3825630",
    "Longitude": "006.2453130",
    "Telephone Number": "028395682873",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.382563,6.245313",
    "IsOpen24Hours": true,
    "id": "51.382563,6.245313"
  },
  {
    "Gas Station Number": "0FY04",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Landstraße 6 a",
    "Postcode": 63939,
    "City": "Wörth",
    "State": "Bayern",
    "Latitude": "049.7978400",
    "Longitude": "009.1567650",
    "Telephone Number": "093728873",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.79784,9.156765",
    "IsOpen24Hours": false,
    "id": "49.79784,9.156765"
  },
  {
    "Gas Station Number": "0FY26",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A 3",
    "Postcode": 65207,
    "City": "Wiesbaden",
    "State": "Hessen",
    "Latitude": "050.0996040",
    "Longitude": "008.3552116",
    "Telephone Number": "06122507985",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.099604,8.3552116",
    "IsOpen24Hours": true,
    "id": "50.099604,8.3552116"
  },
  {
    "Gas Station Number": "0FF74",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Rheinstr. 9",
    "Postcode": 96052,
    "City": "Bamberg",
    "State": "Bayern",
    "Latitude": "049.9102140",
    "Longitude": "010.8557070",
    "Telephone Number": 220578157,
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.910214,10.855707",
    "IsOpen24Hours": true,
    "id": "49.910214,10.855707"
  },
  {
    "Gas Station Number": "0FR40",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Amperestraße 3",
    "Postcode": 63225,
    "City": "Langen",
    "State": "Hessen",
    "Latitude": "049.9985920",
    "Longitude": "008.6529960",
    "Telephone Number": "061038037198",
    "Week From": "05:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "49.998592,8.652996",
    "IsOpen24Hours": false,
    "id": "49.998592,8.652996"
  },
  {
    "Gas Station Number": "0FF24",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Frankenstr.",
    "Postcode": 97762,
    "City": "Hammelburg",
    "State": "Bayern",
    "Latitude": "050.1280790",
    "Longitude": "009.9363640",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.128079,9.936364",
    "IsOpen24Hours": true,
    "id": "50.128079,9.936364"
  },
  {
    "Gas Station Number": "0FT96",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Am Wattengraben 2",
    "Postcode": 55276,
    "City": "Oppenheim",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.8456640",
    "Longitude": "008.3601250",
    "Telephone Number": "061333869197",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.845664,8.360125",
    "IsOpen24Hours": true,
    "id": "49.845664,8.360125"
  },
  {
    "Gas Station Number": "0FN59",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Görschlitzer Str. 7",
    "Postcode": 4849,
    "City": "Bad Düben",
    "State": "Sachsen",
    "Latitude": "051.5899690",
    "Longitude": "012.6019520",
    "Telephone Number": "03424325037",
    "Week From": "05:00",
    "Week To": "21:00",
    "Saturdays From": "06:00",
    "Saturdays To": "21:00",
    "Sundays From": "06:00",
    "Sundays To": "21:00",
    "24 hour?": "Nein",
    "location": "51.589969,12.601952",
    "IsOpen24Hours": false,
    "id": "51.589969,12.601952"
  },
  {
    "Gas Station Number": "0FO78",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Gladbecker Str. 398 A",
    "Postcode": 45326,
    "City": "Essen",
    "State": "Nordrhein-Westfalen",
    "Latitude": "051.4861590",
    "Longitude": "006.9992990",
    "Telephone Number": "020143644416",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "51.486159,6.999299",
    "IsOpen24Hours": true,
    "id": "51.486159,6.999299"
  },
  {
    "Gas Station Number": "0FX98",
    "Brand": "ARAL",
    "Description": "Autohof",
    "Address": "Rudolf-Diesel-Straße 1",
    "Postcode": 91625,
    "City": "Schnelldorf",
    "State": "Bayern",
    "Latitude": "049.1715600",
    "Longitude": "010.2409800",
    "Telephone Number": "079509262810",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "49.17156,10.24098",
    "IsOpen24Hours": true,
    "id": "49.17156,10.24098"
  },
  {
    "Gas Station Number": "0FG53",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstraße 140",
    "Postcode": 63477,
    "City": "Maintal",
    "State": "Hessen",
    "Latitude": "050.1502390",
    "Longitude": "008.8360010",
    "Telephone Number": "061814980046",
    "Week From": "06:00",
    "Week To": "22:00",
    "Saturdays From": "07:00",
    "Saturdays To": "22:00",
    "Sundays From": "08:00",
    "Sundays To": "22:00",
    "24 hour?": "Nein",
    "location": "50.150239,8.836001",
    "IsOpen24Hours": false,
    "id": "50.150239,8.836001"
  },
  {
    "Gas Station Number": "0FP94",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A5",
    "Postcode": 79415,
    "City": "Bad Bellingen",
    "State": "Baden-Württemberg",
    "Latitude": "047.7382050",
    "Longitude": "007.5519520",
    "Telephone Number": "0763581100",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "47.738205,7.551952",
    "IsOpen24Hours": true,
    "id": "47.738205,7.551952"
  },
  {
    "Gas Station Number": "0FU83",
    "Brand": "ARAL",
    "Description": "Autobahntankstelle",
    "Address": "A72",
    "Postcode": "08606",
    "City": "Oelsnitz/Vogtl.",
    "State": "Sachsen",
    "Latitude": "050.4336250",
    "Longitude": "012.1296790",
    "Telephone Number": "03742125420",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.433625,12.129679",
    "IsOpen24Hours": true,
    "id": "50.433625,12.129679"
  },
  {
    "Gas Station Number": "0FG19",
    "Brand": "ARAL",
    "Description": "Strassentankstelle",
    "Address": "Mülheimer Heide 1",
    "Postcode": 53945,
    "City": "Blankenheim",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.4499590",
    "Longitude": "006.6678960",
    "Telephone Number": "02449919251",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.449959,6.667896",
    "IsOpen24Hours": true,
    "id": "50.449959,6.667896"
  },
  {
    "Gas Station Number": "0FG04",
    "Brand": "ARAL",
    "Description": "ADS Station",
    "Address": "Am Sülzenbrückener Weg",
    "Postcode": 99334,
    "City": "Ichtershausen",
    "State": "Thüringen",
    "Latitude": "050.8856090",
    "Longitude": "010.9316470",
    "Telephone Number": "-",
    "Week From": "00:00",
    "Week To": "23:59",
    "Saturdays From": "00:00",
    "Saturdays To": "23:59",
    "Sundays From": "00:00",
    "Sundays To": "23:59",
    "24 hour?": "Ja",
    "location": "50.885609,10.931647",
    "IsOpen24Hours": true,
    "id": "50.885609,10.931647"
  },
  {
    "Gas Station Number": 3615,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Hafenstrasse 7",
    "Postcode": 91301,
    "City": "Forchheim",
    "State": "Bayern",
    "Latitude": "049.70558000",
    "Longitude": "011.05819000",
    "Telephone Number": "09191/9762849",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.70558,11.05819",
    "IsOpen24Hours": false,
    "id": "49.70558,11.05819"
  },
  {
    "Gas Station Number": 3161,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Vilsecker Str. 2",
    "Postcode": 93057,
    "City": "Regensburg",
    "State": "Bayern",
    "Latitude": "049.01643000",
    "Longitude": "012.10176000",
    "Telephone Number": "0941/46705847",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "49.01643,12.10176",
    "IsOpen24Hours": true,
    "id": "49.01643,12.10176"
  },
  {
    "Gas Station Number": 2153,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Umgehungsstrasse 65",
    "Postcode": 71144,
    "City": "Steinenbronn",
    "State": "Baden-Württemberg",
    "Latitude": "048.66626000",
    "Longitude": "009.12409000",
    "Telephone Number": "+49 (0) 71572750",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.66626,9.12409",
    "IsOpen24Hours": false,
    "id": "48.66626,9.12409"
  },
  {
    "Gas Station Number": 4704,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Siemensstrasse 1",
    "Postcode": 35460,
    "City": "Staufenberg",
    "State": "Hessen",
    "Latitude": "050.65500224",
    "Longitude": "008.73271060",
    "Telephone Number": "+49 (0) 64037785",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "50.65500224,8.7327106",
    "IsOpen24Hours": true,
    "id": "50.65500224,8.7327106"
  },
  {
    "Gas Station Number": 2170,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Friedrich-August-Str. 22",
    "Postcode": 55765,
    "City": "Birkenfeld",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.64907000",
    "Longitude": "007.16119000",
    "Telephone Number": "+49 (0) 67825529",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.64907,7.16119",
    "IsOpen24Hours": false,
    "id": "49.64907,7.16119"
  },
  {
    "Gas Station Number": 1186,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Muenchner Strasse 54",
    "Postcode": 82131,
    "City": "Gauting",
    "State": "Bayern",
    "Latitude": "048.06895850",
    "Longitude": "011.38861737",
    "Telephone Number": "+49 (0) 89850512",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.0689585,11.38861737",
    "IsOpen24Hours": false,
    "id": "48.0689585,11.38861737"
  },
  {
    "Gas Station Number": 3159,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Julius-Echter-Str. 26",
    "Postcode": 97450,
    "City": "Arnstein-Heugrumbach",
    "State": "Bayern",
    "Latitude": "049.97848000",
    "Longitude": "009.95374000",
    "Telephone Number": "+49 (0) 93631212",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.97848,9.95374",
    "IsOpen24Hours": false,
    "id": "49.97848,9.95374"
  },
  {
    "Gas Station Number": 1635,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Oberwinkl 4",
    "Postcode": 83355,
    "City": "Grabenstaett",
    "State": "Bayern",
    "Latitude": "047.82775000",
    "Longitude": "012.52103000",
    "Telephone Number": "08661/982510",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "47.82775,12.52103",
    "IsOpen24Hours": true,
    "id": "47.82775,12.52103"
  },
  {
    "Gas Station Number": 2109,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Stuttgarter Strasse 92",
    "Postcode": 75365,
    "City": "Calw",
    "State": "Baden-Württemberg",
    "Latitude": "048.71118239",
    "Longitude": "008.75631913",
    "Telephone Number": "+49 (0) 70513027",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.71118239,8.75631913",
    "IsOpen24Hours": false,
    "id": "48.71118239,8.75631913"
  },
  {
    "Gas Station Number": 1679,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Gewerbegebiet Baerndorf 4",
    "Postcode": 94327,
    "City": "Bogen",
    "State": "Bayern",
    "Latitude": "048.91135000",
    "Longitude": "012.68916000",
    "Telephone Number": "+49 (0)944228099",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.91135,12.68916",
    "IsOpen24Hours": false,
    "id": "48.91135,12.68916"
  },
  {
    "Gas Station Number": 5030,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "An der B 4",
    "Postcode": 98673,
    "City": "Eisfeld (Guest)",
    "State": "Thüringen",
    "Latitude": "050.39444221",
    "Longitude": "010.92150216",
    "Telephone Number": "+49 (0) 36863092",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "50.39444221,10.92150216",
    "IsOpen24Hours": true,
    "id": "50.39444221,10.92150216"
  },
  {
    "Gas Station Number": 4232,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Brandenburger Strasse 1",
    "Postcode": 67663,
    "City": "Kaiserslautern",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.43350156",
    "Longitude": "007.75022410",
    "Telephone Number": "+49 (0) 63130395",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "49.43350156,7.7502241",
    "IsOpen24Hours": true,
    "id": "49.43350156,7.7502241"
  },
  {
    "Gas Station Number": 1615,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Neuburger Str. 104",
    "Postcode": 94036,
    "City": "Passau",
    "State": "Bayern",
    "Latitude": "048.56378270",
    "Longitude": "013.42923552",
    "Telephone Number": "+49 (0) 85158991",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.5637827,13.42923552",
    "IsOpen24Hours": false,
    "id": "48.5637827,13.42923552"
  },
  {
    "Gas Station Number": 1610,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Rathausstr. 1",
    "Postcode": 83734,
    "City": "Hausham",
    "State": "Bayern",
    "Latitude": "047.75969702",
    "Longitude": "011.82836075",
    "Telephone Number": "+49 (0) 80269226",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.75969702,11.82836075",
    "IsOpen24Hours": false,
    "id": "47.75969702,11.82836075"
  },
  {
    "Gas Station Number": 2136,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Freiburger Strasse 9",
    "Postcode": 77933,
    "City": "Lahr",
    "State": "Baden-Württemberg",
    "Latitude": "048.34151034",
    "Longitude": "007.84149881",
    "Telephone Number": "+49 (0) 78219818",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.34151034,7.84149881",
    "IsOpen24Hours": true,
    "id": "48.34151034,7.84149881"
  },
  {
    "Gas Station Number": 4167,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Bruchkoebeler Landstrasse 53",
    "Postcode": 63452,
    "City": "Hanau",
    "State": "Hessen",
    "Latitude": "050.14617500",
    "Longitude": "008.90929000",
    "Telephone Number": "+49 (0) 61818408",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.146175,8.90929",
    "IsOpen24Hours": false,
    "id": "50.146175,8.90929"
  },
  {
    "Gas Station Number": 5045,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "An Der A 9",
    "Postcode": 7927,
    "City": "Hirschberg",
    "State": "Thüringen",
    "Latitude": "050.43021873",
    "Longitude": "011.79881430",
    "Telephone Number": "+49 (0) 36644260",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "50.43021873,11.7988143",
    "IsOpen24Hours": true,
    "id": "50.43021873,11.7988143"
  },
  {
    "Gas Station Number": 1066,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Kemptener Strasse 61",
    "Postcode": 87600,
    "City": "Kaufbeuren",
    "State": "Bayern",
    "Latitude": "047.87827520",
    "Longitude": "010.61169268",
    "Telephone Number": "+49 (0) 83418281",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.8782752,10.61169268",
    "IsOpen24Hours": false,
    "id": "47.8782752,10.61169268"
  },
  {
    "Gas Station Number": 1298,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Mercedesring 2",
    "Postcode": 86368,
    "City": "Gersthofen",
    "State": "Bayern",
    "Latitude": "048.43679000",
    "Longitude": "010.86898000",
    "Telephone Number": "+49 (0) 82146094",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.43679,10.86898",
    "IsOpen24Hours": true,
    "id": "48.43679,10.86898"
  },
  {
    "Gas Station Number": 1159,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Wittstrasse 15",
    "Postcode": 84036,
    "City": "Landshut",
    "State": "Bayern",
    "Latitude": "048.52888000",
    "Longitude": "012.14103000",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.52888,12.14103",
    "IsOpen24Hours": false,
    "id": "48.52888,12.14103"
  },
  {
    "Gas Station Number": 1619,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Zellers 6",
    "Postcode": 87509,
    "City": "Immenstadt",
    "State": "Bayern",
    "Latitude": "047.60201567",
    "Longitude": "010.25526052",
    "Telephone Number": "+49 (0) 83797176",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.60201567,10.25526052",
    "IsOpen24Hours": false,
    "id": "47.60201567,10.25526052"
  },
  {
    "Gas Station Number": 1113,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Burgstr. 16",
    "Postcode": 82467,
    "City": "Garmisch-Partenk.",
    "State": "Bayern",
    "Latitude": "047.49893076",
    "Longitude": "011.08798154",
    "Telephone Number": "+49 (0) 88219470",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.49893076,11.08798154",
    "IsOpen24Hours": false,
    "id": "47.49893076,11.08798154"
  },
  {
    "Gas Station Number": 1297,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Am Hochfeldweg 2",
    "Postcode": 85051,
    "City": "Ingolstadt-Zuchering",
    "State": "Bayern",
    "Latitude": "048.70331310",
    "Longitude": "011.41864491",
    "Telephone Number": "+49 (0) 84509286",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.7033131,11.41864491",
    "IsOpen24Hours": true,
    "id": "48.7033131,11.41864491"
  },
  {
    "Gas Station Number": 1229,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Schwindgraben 2",
    "Postcode": 93083,
    "City": "Obertraubling",
    "State": "Bayern",
    "Latitude": "048.97125000",
    "Longitude": "012.17505000",
    "Telephone Number": "+49 (0) 94015102",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.97125,12.17505",
    "IsOpen24Hours": false,
    "id": "48.97125,12.17505"
  },
  {
    "Gas Station Number": 2008,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Hofgartenstrasse 10",
    "Postcode": 72379,
    "City": "Hechingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.35692051",
    "Longitude": "008.96465209",
    "Telephone Number": "+49 (0) 74715315",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.35692051,8.96465209",
    "IsOpen24Hours": true,
    "id": "48.35692051,8.96465209"
  },
  {
    "Gas Station Number": 2619,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Robert-Bosch-Str. 2-4",
    "Postcode": 72525,
    "City": "Muensingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.41539000",
    "Longitude": "009.47401000",
    "Telephone Number": "+49 (0) 73815013",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.41539,9.47401",
    "IsOpen24Hours": true,
    "id": "48.41539,9.47401"
  },
  {
    "Gas Station Number": 3043,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Lappersdorfer Strasse 110",
    "Postcode": 93059,
    "City": "Regensburg",
    "State": "Bayern",
    "Latitude": "049.04051000",
    "Longitude": "012.09464000",
    "Telephone Number": "+49 (0) 94182903",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "49.04051,12.09464",
    "IsOpen24Hours": true,
    "id": "49.04051,12.09464"
  },
  {
    "Gas Station Number": 3356,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Fuchsgrabenweg 1",
    "Postcode": 91586,
    "City": "Lichtenau",
    "State": "Bayern",
    "Latitude": "049.28013000",
    "Longitude": "010.71278800",
    "Telephone Number": "+49 (0) 9827/928",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "49.28013,10.712788",
    "IsOpen24Hours": true,
    "id": "49.28013,10.712788"
  },
  {
    "Gas Station Number": 4172,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Hohleichenrain 8",
    "Postcode": 35708,
    "City": "Haiger",
    "State": "Hessen",
    "Latitude": "050.74165000",
    "Longitude": "008.22374000",
    "Telephone Number": "+49 (0) 2773/747",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.74165,8.22374",
    "IsOpen24Hours": false,
    "id": "50.74165,8.22374"
  },
  {
    "Gas Station Number": 5088,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Guelzower Str. 15A",
    "Postcode": 12619,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.51444203",
    "Longitude": "013.58190072",
    "Telephone Number": "+49 (0) 30562931",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "52.51444203,13.58190072",
    "IsOpen24Hours": true,
    "id": "52.51444203,13.58190072"
  },
  {
    "Gas Station Number": 3164,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Bernecker Str.52",
    "Postcode": 95448,
    "City": "Bayreuth",
    "State": "Bayern",
    "Latitude": "049.95470000",
    "Longitude": "011.60095000",
    "Telephone Number": "0921/78519303",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "49.9547,11.60095",
    "IsOpen24Hours": true,
    "id": "49.9547,11.60095"
  },
  {
    "Gas Station Number": 3165,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Triefensteiner Str. 1",
    "Postcode": 97828,
    "City": "Marktheidenfeld",
    "State": "Bayern",
    "Latitude": "049.82291000",
    "Longitude": "009.56295000",
    "Telephone Number": "09391/8101857",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.82291,9.56295",
    "IsOpen24Hours": false,
    "id": "49.82291,9.56295"
  },
  {
    "Gas Station Number": 5130,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Thomas Muentzer Str. 74",
    "Postcode": 6217,
    "City": "Merseburg",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.34864000",
    "Longitude": "011.98288000",
    "Telephone Number": "034618219700",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "51.34864,11.98288",
    "IsOpen24Hours": true,
    "id": "51.34864,11.98288"
  },
  {
    "Gas Station Number": 1196,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Bayerstrasse 4",
    "Postcode": 83308,
    "City": "Trostberg",
    "State": "Bayern",
    "Latitude": "048.02707070",
    "Longitude": "012.55643400",
    "Telephone Number": "+49 (0) 86212419",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.0270707,12.556434",
    "IsOpen24Hours": false,
    "id": "48.0270707,12.556434"
  },
  {
    "Gas Station Number": 5022,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Kamenzer Strasse 34",
    "Postcode": 1896,
    "City": "Pulsnitz",
    "State": "Sachsen",
    "Latitude": "051.20022925",
    "Longitude": "014.02258282",
    "Telephone Number": "+49 (0) 35955442",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.20022925,14.02258282",
    "IsOpen24Hours": false,
    "id": "51.20022925,14.02258282"
  },
  {
    "Gas Station Number": 1656,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Oberthann 9",
    "Postcode": 85301,
    "City": "Schweitenkirchen",
    "State": "Bayern",
    "Latitude": "048.50747000",
    "Longitude": "011.58537000",
    "Telephone Number": "+49 (0) 8444333",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.50747,11.58537",
    "IsOpen24Hours": false,
    "id": "48.50747,11.58537"
  },
  {
    "Gas Station Number": 1650,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Buergermeister-Hefele-Strasse 1",
    "Postcode": 86609,
    "City": "Donauwoerth",
    "State": "Bayern",
    "Latitude": "048.71945000",
    "Longitude": "010.77674000",
    "Telephone Number": "+49 (0) 90670556",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.71945,10.77674",
    "IsOpen24Hours": true,
    "id": "48.71945,10.77674"
  },
  {
    "Gas Station Number": 4780,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Rheinstr. 1a",
    "Postcode": 56242,
    "City": "Selters (Westerwald)",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.52831000",
    "Longitude": "007.75815000",
    "Telephone Number": "02626/5208",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.52831,7.75815",
    "IsOpen24Hours": false,
    "id": "50.52831,7.75815"
  },
  {
    "Gas Station Number": 5058,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Ahrensfelder Chaussee 152",
    "Postcode": 12689,
    "City": "Berlin-Marzahn",
    "State": "Berlin",
    "Latitude": "052.57228787",
    "Longitude": "013.56490688",
    "Telephone Number": "+49 (0) 30937439",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "52.57228787,13.56490688",
    "IsOpen24Hours": true,
    "id": "52.57228787,13.56490688"
  },
  {
    "Gas Station Number": 1108,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Chamer Strasse 48",
    "Postcode": 94315,
    "City": "Straubing",
    "State": "Bayern",
    "Latitude": "048.88767000",
    "Longitude": "012.57400000",
    "Telephone Number": "+49 (0) 94211236",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.88767,12.574",
    "IsOpen24Hours": false,
    "id": "48.88767,12.574"
  },
  {
    "Gas Station Number": 1621,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Preysingstr. 58",
    "Postcode": 85283,
    "City": "Wolnzach",
    "State": "Bayern",
    "Latitude": "048.59798814",
    "Longitude": "011.63000230",
    "Telephone Number": "+49 (0) 84429119",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.59798814,11.6300023",
    "IsOpen24Hours": false,
    "id": "48.59798814,11.6300023"
  },
  {
    "Gas Station Number": 1096,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Spitalhofstrasse 84",
    "Postcode": 94032,
    "City": "Passau",
    "State": "Bayern",
    "Latitude": "048.57020316",
    "Longitude": "013.42698438",
    "Telephone Number": "+49 (0) 85170166",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.57020316,13.42698438",
    "IsOpen24Hours": false,
    "id": "48.57020316,13.42698438"
  },
  {
    "Gas Station Number": 2201,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Bernlocher Str. 17",
    "Postcode": 72829,
    "City": "Engstingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.38574624",
    "Longitude": "009.30056324",
    "Telephone Number": "+49 (0) 71293318",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.38574624,9.30056324",
    "IsOpen24Hours": false,
    "id": "48.38574624,9.30056324"
  },
  {
    "Gas Station Number": 1255,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Kocheler Str. 11",
    "Postcode": 83673,
    "City": "Bichl",
    "State": "Bayern",
    "Latitude": "047.71488343",
    "Longitude": "011.41361774",
    "Telephone Number": "+49 (0) 88571263",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.71488343,11.41361774",
    "IsOpen24Hours": false,
    "id": "47.71488343,11.41361774"
  },
  {
    "Gas Station Number": 1291,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Eichendorffstr. 20",
    "Postcode": 85609,
    "City": "Aschheim",
    "State": "Bayern",
    "Latitude": "048.16518378",
    "Longitude": "011.72058280",
    "Telephone Number": "+49 (0) 89909375",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.16518378,11.7205828",
    "IsOpen24Hours": true,
    "id": "48.16518378,11.7205828"
  },
  {
    "Gas Station Number": 5080,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Langenwahler Str. 19",
    "Postcode": 15517,
    "City": "Fuerstenwalde",
    "State": "Brandenburg",
    "Latitude": "052.35128331",
    "Longitude": "014.07231842",
    "Telephone Number": "+49 (0) 33615020",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.35128331,14.07231842",
    "IsOpen24Hours": false,
    "id": "52.35128331,14.07231842"
  },
  {
    "Gas Station Number": 2253,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Mengener Str. 37",
    "Postcode": 88605,
    "City": "Messkirch",
    "State": "Baden-Württemberg",
    "Latitude": "047.99572000",
    "Longitude": "009.12102000",
    "Telephone Number": "+49 (0) 75759263",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.99572,9.12102",
    "IsOpen24Hours": false,
    "id": "47.99572,9.12102"
  },
  {
    "Gas Station Number": 2272,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Aichern 3",
    "Postcode": 71106,
    "City": "Magstadt",
    "State": "Baden-Württemberg",
    "Latitude": "048.74274000",
    "Longitude": "008.96295000",
    "Telephone Number": "+49 (0) 7159/939",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.74274,8.96295",
    "IsOpen24Hours": false,
    "id": "48.74274,8.96295"
  },
  {
    "Gas Station Number": 5103,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Paunsdorfer Str. 70",
    "Postcode": 4316,
    "City": "Leipzig",
    "State": "Sachsen",
    "Latitude": "051.34236000",
    "Longitude": "012.44519000",
    "Telephone Number": "+49 (0) 34165151",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "51.34236,12.44519",
    "IsOpen24Hours": true,
    "id": "51.34236,12.44519"
  },
  {
    "Gas Station Number": 1269,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Gangkofener Str. 73",
    "Postcode": 84160,
    "City": "Frontenhausen",
    "State": "Bayern",
    "Latitude": "048.54516395",
    "Longitude": "012.52843920",
    "Telephone Number": "+49 (0) 87321349",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.54516395,12.5284392",
    "IsOpen24Hours": false,
    "id": "48.54516395,12.5284392"
  },
  {
    "Gas Station Number": 4051,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Roemerstrasse 76-78",
    "Postcode": 68623,
    "City": "Lampertheim",
    "State": "Hessen",
    "Latitude": "049.59240000",
    "Longitude": "008.47200000",
    "Telephone Number": "+49 (0) 62069109",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.5924,8.472",
    "IsOpen24Hours": false,
    "id": "49.5924,8.472"
  },
  {
    "Gas Station Number": 3105,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Further Strasse 8",
    "Postcode": 93413,
    "City": "Cham",
    "State": "Bayern",
    "Latitude": "049.22373000",
    "Longitude": "012.67087000",
    "Telephone Number": "+49 (0) 99719270",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.22373,12.67087",
    "IsOpen24Hours": false,
    "id": "49.22373,12.67087"
  },
  {
    "Gas Station Number": 1283,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Traunsteiner Strasse 19",
    "Postcode": 83313,
    "City": "Siegsdorf",
    "State": "Bayern",
    "Latitude": "047.82500051",
    "Longitude": "012.63813139",
    "Telephone Number": "+49 (0) 86626682",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.82500051,12.63813139",
    "IsOpen24Hours": false,
    "id": "47.82500051,12.63813139"
  },
  {
    "Gas Station Number": 5221,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Spremberger Str.8/ B169 (Gewerbegeb",
    "Postcode": 3116,
    "City": "Drebkau",
    "State": "Brandenburg",
    "Latitude": "051.64623900",
    "Longitude": "014.23759500",
    "Telephone Number": "+49 (0) 35602510",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "51.646239,14.237595",
    "IsOpen24Hours": true,
    "id": "51.646239,14.237595"
  },
  {
    "Gas Station Number": 3003,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Rothenburger Strasse 32",
    "Postcode": 90522,
    "City": "Oberasbach",
    "State": "Bayern",
    "Latitude": "049.43594000",
    "Longitude": "010.97738000",
    "Telephone Number": "+49 (0) 91169221",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.43594,10.97738",
    "IsOpen24Hours": false,
    "id": "49.43594,10.97738"
  },
  {
    "Gas Station Number": 1664,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Buergermeister-Schlosser-Str. 2",
    "Postcode": 86199,
    "City": "Augsburg",
    "State": "Bayern",
    "Latitude": "048.31878000",
    "Longitude": "010.86128000",
    "Telephone Number": "0821/99863460",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.31878,10.86128",
    "IsOpen24Hours": false,
    "id": "48.31878,10.86128"
  },
  {
    "Gas Station Number": 4027,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Gelnhaeuser Str. 1-3",
    "Postcode": 63517,
    "City": "Rodenbach",
    "State": "Hessen",
    "Latitude": "050.14729573",
    "Longitude": "009.02503720",
    "Telephone Number": "+49 (0) 61845050",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.14729573,9.0250372",
    "IsOpen24Hours": false,
    "id": "50.14729573,9.0250372"
  },
  {
    "Gas Station Number": 1657,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Tuerkenfelder Strasse 2",
    "Postcode": 82272,
    "City": "Moorenweis",
    "State": "Bayern",
    "Latitude": "048.14925000",
    "Longitude": "011.07333000",
    "Telephone Number": "081469979172",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.14925,11.07333",
    "IsOpen24Hours": false,
    "id": "48.14925,11.07333"
  },
  {
    "Gas Station Number": 1666,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Landstr. 3",
    "Postcode": 84364,
    "City": "Bad Birnbach",
    "State": "Bayern",
    "Latitude": "048.44749000",
    "Longitude": "013.04008000",
    "Telephone Number": "08563/825",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.44749,13.04008",
    "IsOpen24Hours": false,
    "id": "48.44749,13.04008"
  },
  {
    "Gas Station Number": 4020,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Bleiswijker Strasse 200",
    "Postcode": 63303,
    "City": "Dreieich-Goetzenhain",
    "State": "Hessen",
    "Latitude": "050.00019000",
    "Longitude": "008.73126000",
    "Telephone Number": "06103/9951125",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.00019,8.73126",
    "IsOpen24Hours": false,
    "id": "50.00019,8.73126"
  },
  {
    "Gas Station Number": 2612,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Tuebinger Str. 26",
    "Postcode": 72108,
    "City": "Rottenburg",
    "State": "Baden-Württemberg",
    "Latitude": "048.47332000",
    "Longitude": "008.93871000",
    "Telephone Number": "+49 (0) 74729475",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.47332,8.93871",
    "IsOpen24Hours": true,
    "id": "48.47332,8.93871"
  },
  {
    "Gas Station Number": 2310,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Heilbronner Str. 75",
    "Postcode": 73635,
    "City": "Rudersberg",
    "State": "Baden-Württemberg",
    "Latitude": "048.87864000",
    "Longitude": "009.53008000",
    "Telephone Number": "+49 (0) 71833022",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.87864,9.53008",
    "IsOpen24Hours": false,
    "id": "48.87864,9.53008"
  },
  {
    "Gas Station Number": 3148,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Wunsiedler Str. 11",
    "Postcode": 95032,
    "City": "Hof",
    "State": "Bayern",
    "Latitude": "050.30473879",
    "Longitude": "011.91968644",
    "Telephone Number": "+49 (0) 92819701",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "50.30473879,11.91968644",
    "IsOpen24Hours": true,
    "id": "50.30473879,11.91968644"
  },
  {
    "Gas Station Number": 3622,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Wuerzburger Str. 23",
    "Postcode": 91477,
    "City": "Markt Bibart",
    "State": "Bayern",
    "Latitude": "049.64924000",
    "Longitude": "010.42396000",
    "Telephone Number": "+49 (0) 9162/825",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.64924,10.42396",
    "IsOpen24Hours": false,
    "id": "49.64924,10.42396"
  },
  {
    "Gas Station Number": 2025,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Welfenstrasse 32",
    "Postcode": 70599,
    "City": "Stuttgart",
    "State": "Baden-Württemberg",
    "Latitude": "048.71958000",
    "Longitude": "009.20812000",
    "Telephone Number": "+49 (0) 71145348",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.71958,9.20812",
    "IsOpen24Hours": false,
    "id": "48.71958,9.20812"
  },
  {
    "Gas Station Number": 3040,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Ansbacher Strasse 34",
    "Postcode": 91575,
    "City": "Windsbach",
    "State": "Bayern",
    "Latitude": "049.24591429",
    "Longitude": "010.82309287",
    "Telephone Number": "+49 (0) 9871246",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.24591429,10.82309287",
    "IsOpen24Hours": false,
    "id": "49.24591429,10.82309287"
  },
  {
    "Gas Station Number": 2010,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Haller Str. 210",
    "Postcode": 74564,
    "City": "Crailsheim-Rossfeld",
    "State": "Baden-Württemberg",
    "Latitude": "049.14136000",
    "Longitude": "010.06094000",
    "Telephone Number": "+49 (0) 79512297",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.14136,10.06094",
    "IsOpen24Hours": false,
    "id": "49.14136,10.06094"
  },
  {
    "Gas Station Number": 2280,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Wuerzburger Str.9",
    "Postcode": 74731,
    "City": "Wallduern",
    "State": "Baden-Württemberg",
    "Latitude": "049.58159000",
    "Longitude": "009.37450000",
    "Telephone Number": "062829289646",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.58159,9.3745",
    "IsOpen24Hours": false,
    "id": "49.58159,9.3745"
  },
  {
    "Gas Station Number": 5257,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Ilmenauer Str. 7 g",
    "Postcode": 98701,
    "City": "Grossbreitenbach",
    "State": "Thüringen",
    "Latitude": "050.58807000",
    "Longitude": "010.99362000",
    "Telephone Number": "036781/41077",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.58807,10.99362",
    "IsOpen24Hours": false,
    "id": "50.58807,10.99362"
  },
  {
    "Gas Station Number": 1655,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Am Kletthamer Feld 2",
    "Postcode": 85435,
    "City": "Erding",
    "State": "Bayern",
    "Latitude": "048.30920300",
    "Longitude": "011.88336600",
    "Telephone Number": "+49 (0) 81222274",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.309203,11.883366",
    "IsOpen24Hours": false,
    "id": "48.309203,11.883366"
  },
  {
    "Gas Station Number": 1201,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Rastanlage Vaterstetten-Ost",
    "Postcode": 85622,
    "City": "Feldkirchen",
    "State": "Bayern",
    "Latitude": "048.15384142",
    "Longitude": "011.75169259",
    "Telephone Number": "+49 (0) 81067096",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.15384142,11.75169259",
    "IsOpen24Hours": true,
    "id": "48.15384142,11.75169259"
  },
  {
    "Gas Station Number": 2141,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Badenstr. 6",
    "Postcode": 76467,
    "City": "Bietigheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.90025177",
    "Longitude": "008.24904179",
    "Telephone Number": "+49 (0) 72459381",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.90025177,8.24904179",
    "IsOpen24Hours": false,
    "id": "48.90025177,8.24904179"
  },
  {
    "Gas Station Number": 1349,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Zur Eisenschmiede 2",
    "Postcode": 87480,
    "City": "Weitnau/Hofen",
    "State": "Bayern",
    "Latitude": "047.65608000",
    "Longitude": "010.08856000",
    "Telephone Number": "08375/975773",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "47.65608,10.08856",
    "IsOpen24Hours": true,
    "id": "47.65608,10.08856"
  },
  {
    "Gas Station Number": 1212,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Gewerbestrasse 3",
    "Postcode": 84427,
    "City": "St Wolfgang",
    "State": "Bayern",
    "Latitude": "048.21235390",
    "Longitude": "012.13412406",
    "Telephone Number": "+49 (0) 80851208",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.2123539,12.13412406",
    "IsOpen24Hours": false,
    "id": "48.2123539,12.13412406"
  },
  {
    "Gas Station Number": 1630,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Quiddestrae 31",
    "Postcode": 81735,
    "City": "Muenchen",
    "State": "Bayern",
    "Latitude": "048.10898000",
    "Longitude": "011.64326000",
    "Telephone Number": "+49 (0) 89638498",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.10898,11.64326",
    "IsOpen24Hours": false,
    "id": "48.10898,11.64326"
  },
  {
    "Gas Station Number": 2143,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Rommelshauser Strasse 30",
    "Postcode": 70734,
    "City": "Fellbach",
    "State": "Baden-Württemberg",
    "Latitude": "048.80568569",
    "Longitude": "009.30515070",
    "Telephone Number": "+49 (0) 71158171",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.80568569,9.3051507",
    "IsOpen24Hours": false,
    "id": "48.80568569,9.3051507"
  },
  {
    "Gas Station Number": 5003,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Berliner Allee 14",
    "Postcode": 16833,
    "City": "Fehrbellin",
    "State": "Brandenburg",
    "Latitude": "052.80589145",
    "Longitude": "012.78574759",
    "Telephone Number": "+49 (0) 33932724",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.80589145,12.78574759",
    "IsOpen24Hours": false,
    "id": "52.80589145,12.78574759"
  },
  {
    "Gas Station Number": 1350,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Hauptstr. 18",
    "Postcode": 82493,
    "City": "Klais",
    "State": "Bayern",
    "Latitude": "047.48373000",
    "Longitude": "011.23647000",
    "Telephone Number": "08823/1617",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.48373,11.23647",
    "IsOpen24Hours": false,
    "id": "47.48373,11.23647"
  },
  {
    "Gas Station Number": 1665,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Dieselstrasse 16",
    "Postcode": 85386,
    "City": "Eching",
    "State": "Bayern",
    "Latitude": "048.30859000",
    "Longitude": "011.64120000",
    "Telephone Number": "08165/9087290",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.30859,11.6412",
    "IsOpen24Hours": false,
    "id": "48.30859,11.6412"
  },
  {
    "Gas Station Number": 2036,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Lippertsreuter Strasse 13",
    "Postcode": 88662,
    "City": "Ueberlingen",
    "State": "Baden-Württemberg",
    "Latitude": "047.77121630",
    "Longitude": "009.17326196",
    "Telephone Number": "+49 (0) 75514590",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.7712163,9.17326196",
    "IsOpen24Hours": false,
    "id": "47.7712163,9.17326196"
  },
  {
    "Gas Station Number": 2244,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Schwetzinger Str. 125",
    "Postcode": 69168,
    "City": "Wiesloch",
    "State": "Baden-Württemberg",
    "Latitude": "049.29148000",
    "Longitude": "008.68342000",
    "Telephone Number": "+49 (0) 62225276",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.29148,8.68342",
    "IsOpen24Hours": false,
    "id": "49.29148,8.68342"
  },
  {
    "Gas Station Number": 3060,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Ansbacher Strasse 102",
    "Postcode": 90449,
    "City": "Nuernberg",
    "State": "Bayern",
    "Latitude": "049.42069000",
    "Longitude": "011.03272000",
    "Telephone Number": "+49 (0) 91167656",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.42069,11.03272",
    "IsOpen24Hours": false,
    "id": "49.42069,11.03272"
  },
  {
    "Gas Station Number": 4742,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Woelfershaeuser Strasse 49",
    "Postcode": 36266,
    "City": "Heringen",
    "State": "Hessen",
    "Latitude": "050.88496600",
    "Longitude": "009.98764300",
    "Telephone Number": "+49 (0) 66241703",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.884966,9.987643",
    "IsOpen24Hours": false,
    "id": "50.884966,9.987643"
  },
  {
    "Gas Station Number": 2142,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Untermuehlaustr. 105-107",
    "Postcode": 68169,
    "City": "Mannheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.50557000",
    "Longitude": "008.46313000",
    "Telephone Number": "+49 (0) 62131650",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.50557,8.46313",
    "IsOpen24Hours": false,
    "id": "49.50557,8.46313"
  },
  {
    "Gas Station Number": 1136,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Zwieseler Strasse 44",
    "Postcode": 94209,
    "City": "Regen",
    "State": "Bayern",
    "Latitude": "048.97188331",
    "Longitude": "013.13808647",
    "Telephone Number": "+49 (0) 99211852",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.97188331,13.13808647",
    "IsOpen24Hours": false,
    "id": "48.97188331,13.13808647"
  },
  {
    "Gas Station Number": 5086,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Adlergestell 289 A",
    "Postcode": 12489,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.43197379",
    "Longitude": "013.54651899",
    "Telephone Number": "+49 (0) 30671329",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "52.43197379,13.54651899",
    "IsOpen24Hours": true,
    "id": "52.43197379,13.54651899"
  },
  {
    "Gas Station Number": 1286,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstrasse 10A",
    "Postcode": 94327,
    "City": "Bogen",
    "State": "Bayern",
    "Latitude": "048.90949000",
    "Longitude": "012.69103000",
    "Telephone Number": "+49 (0) 94221864",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.90949,12.69103",
    "IsOpen24Hours": false,
    "id": "48.90949,12.69103"
  },
  {
    "Gas Station Number": 4171,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "An der Hessenhalle 7",
    "Postcode": 36304,
    "City": "Alsfeld",
    "State": "Hessen",
    "Latitude": "050.73834000",
    "Longitude": "009.25037000",
    "Telephone Number": "06631/8026112",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "50.73834,9.25037",
    "IsOpen24Hours": true,
    "id": "50.73834,9.25037"
  },
  {
    "Gas Station Number": 3041,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Vorjurastrasse 180",
    "Postcode": 90453,
    "City": "Nuernberg",
    "State": "Bayern",
    "Latitude": "049.36863000",
    "Longitude": "011.04559000",
    "Telephone Number": "+49 (0) 91163787",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.36863,11.04559",
    "IsOpen24Hours": false,
    "id": "49.36863,11.04559"
  },
  {
    "Gas Station Number": 2028,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Heidenheimer Strasse 47",
    "Postcode": 89537,
    "City": "Giengen",
    "State": "Baden-Württemberg",
    "Latitude": "048.62368000",
    "Longitude": "010.24219000",
    "Telephone Number": "+49 (0) 73229195",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.62368,10.24219",
    "IsOpen24Hours": false,
    "id": "48.62368,10.24219"
  },
  {
    "Gas Station Number": 4026,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Hanauer Landstrasse 68",
    "Postcode": 63571,
    "City": "Gelnhausen",
    "State": "Hessen",
    "Latitude": "050.18244476",
    "Longitude": "009.13645147",
    "Telephone Number": "+49 (0) 60516866",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.18244476,9.13645147",
    "IsOpen24Hours": false,
    "id": "50.18244476,9.13645147"
  },
  {
    "Gas Station Number": 1614,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Silbergstrasse 91 A/B 20",
    "Postcode": 83489,
    "City": "Bischofswiesen - Strub",
    "State": "Bayern",
    "Latitude": "047.62964687",
    "Longitude": "012.96003066",
    "Telephone Number": "+49 (0) 86524757",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.62964687,12.96003066",
    "IsOpen24Hours": false,
    "id": "47.62964687,12.96003066"
  },
  {
    "Gas Station Number": 5105,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Kossaerstr./Maximilianallee",
    "Postcode": 4356,
    "City": "Leipzig-Podelwitz",
    "State": "Sachsen",
    "Latitude": "051.41040000",
    "Longitude": "012.39345000",
    "Telephone Number": "+49 (0) 34152172",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "51.4104,12.39345",
    "IsOpen24Hours": true,
    "id": "51.4104,12.39345"
  },
  {
    "Gas Station Number": 2609,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Stuttgarter Str. 144",
    "Postcode": 73230,
    "City": "Kirchheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.65565000",
    "Longitude": "009.42765000",
    "Telephone Number": "+49 (0) 70213054",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.65565,9.42765",
    "IsOpen24Hours": false,
    "id": "48.65565,9.42765"
  },
  {
    "Gas Station Number": 5025,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Hallesche Str. 182",
    "Postcode": 4159,
    "City": "Leipzig",
    "State": "Sachsen",
    "Latitude": "051.38467257",
    "Longitude": "012.27306411",
    "Telephone Number": "+49 (0) 34146157",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "51.38467257,12.27306411",
    "IsOpen24Hours": true,
    "id": "51.38467257,12.27306411"
  },
  {
    "Gas Station Number": 1262,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Von-Kahr-Str. 62",
    "Postcode": 80999,
    "City": "Muenchen",
    "State": "Bayern",
    "Latitude": "048.17759000",
    "Longitude": "011.46744000",
    "Telephone Number": "+49 (0) 89812118",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.17759,11.46744",
    "IsOpen24Hours": false,
    "id": "48.17759,11.46744"
  },
  {
    "Gas Station Number": 2247,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Jagsttal-Ost",
    "Postcode": 74259,
    "City": "Widdern",
    "State": "Baden-Württemberg",
    "Latitude": "049.31591500",
    "Longitude": "009.41948500",
    "Telephone Number": "+49 (0) 62985646",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "49.315915,9.419485",
    "IsOpen24Hours": true,
    "id": "49.315915,9.419485"
  },
  {
    "Gas Station Number": 4734,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Industriestr. 7a",
    "Postcode": 36275,
    "City": "Kirchheim",
    "State": "Hessen",
    "Latitude": "050.83196000",
    "Longitude": "009.57097000",
    "Telephone Number": "+49 (0) 6625632",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "50.83196,9.57097",
    "IsOpen24Hours": true,
    "id": "50.83196,9.57097"
  },
  {
    "Gas Station Number": 1672,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Carl-Heuchel-Strasse",
    "Postcode": 86720,
    "City": "Noerdlingen",
    "State": "Bayern",
    "Latitude": "048.86287000",
    "Longitude": "010.51788000",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.86287,10.51788",
    "IsOpen24Hours": false,
    "id": "48.86287,10.51788"
  },
  {
    "Gas Station Number": 4601,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Platanenallee 11",
    "Postcode": 63739,
    "City": "Aschaffenburg",
    "State": "Bayern",
    "Latitude": "049.97689000",
    "Longitude": "009.15258000",
    "Telephone Number": "+49 (0) 60214532",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.97689,9.15258",
    "IsOpen24Hours": false,
    "id": "49.97689,9.15258"
  },
  {
    "Gas Station Number": 2266,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Roemeraecker 5",
    "Postcode": 76351,
    "City": "Linkenheim-Hochstetten",
    "State": "Baden-Württemberg",
    "Latitude": "049.13768241",
    "Longitude": "008.42713194",
    "Telephone Number": "07247/9544915",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.13768241,8.42713194",
    "IsOpen24Hours": false,
    "id": "49.13768241,8.42713194"
  },
  {
    "Gas Station Number": 4149,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Kantstrasse 64",
    "Postcode": 67663,
    "City": "Kaiserslautern",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.43216000",
    "Longitude": "007.78105000",
    "Telephone Number": "+49 (0) 63131152",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.43216,7.78105",
    "IsOpen24Hours": false,
    "id": "49.43216,7.78105"
  },
  {
    "Gas Station Number": 4744,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Hohenkirchnerstr. 9",
    "Postcode": 34376,
    "City": "Immenhausen",
    "State": "Hessen",
    "Latitude": "051.42436000",
    "Longitude": "009.48025000",
    "Telephone Number": "+49 (0) 56732573",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.42436,9.48025",
    "IsOpen24Hours": false,
    "id": "51.42436,9.48025"
  },
  {
    "Gas Station Number": 4036,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Am Kreuzstein 55",
    "Postcode": 63477,
    "City": "Maintal",
    "State": "Hessen",
    "Latitude": "050.14811000",
    "Longitude": "008.80783000",
    "Telephone Number": "+49 (0) 61096163",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.14811,8.80783",
    "IsOpen24Hours": false,
    "id": "50.14811,8.80783"
  },
  {
    "Gas Station Number": 2194,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Bahnhofstr. 18",
    "Postcode": 77709,
    "City": "Wolfach",
    "State": "Baden-Württemberg",
    "Latitude": "048.29414979",
    "Longitude": "008.22118453",
    "Telephone Number": "+49 (0) 78344041",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.29414979,8.22118453",
    "IsOpen24Hours": false,
    "id": "48.29414979,8.22118453"
  },
  {
    "Gas Station Number": 2613,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Rielasingerstr. 139",
    "Postcode": 78224,
    "City": "Singen",
    "State": "Baden-Württemberg",
    "Latitude": "047.74207803",
    "Longitude": "008.84215889",
    "Telephone Number": "+49 (0) 77312134",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.74207803,8.84215889",
    "IsOpen24Hours": false,
    "id": "47.74207803,8.84215889"
  },
  {
    "Gas Station Number": 5083,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Damaschkeweg 64",
    "Postcode": 15234,
    "City": "Frankfurt/Oder",
    "State": "Brandenburg",
    "Latitude": "052.33690809",
    "Longitude": "014.52094182",
    "Telephone Number": "+49 (0) 33550000",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.33690809,14.52094182",
    "IsOpen24Hours": false,
    "id": "52.33690809,14.52094182"
  },
  {
    "Gas Station Number": 5032,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Haenchener Strasse 4",
    "Postcode": 3050,
    "City": "Cottbus",
    "State": "Brandenburg",
    "Latitude": "051.72071273",
    "Longitude": "014.34449376",
    "Telephone Number": "+49 (0) 35553408",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "51.72071273,14.34449376",
    "IsOpen24Hours": true,
    "id": "51.72071273,14.34449376"
  },
  {
    "Gas Station Number": 2100,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Reutlinger Strasse 68",
    "Postcode": 72766,
    "City": "Reutlingen-Sondelf.",
    "State": "Baden-Württemberg",
    "Latitude": "048.51504000",
    "Longitude": "009.22598000",
    "Telephone Number": "+49 (0) 71214918",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.51504,9.22598",
    "IsOpen24Hours": false,
    "id": "48.51504,9.22598"
  },
  {
    "Gas Station Number": 1028,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Puetrichstrasse 29",
    "Postcode": 82362,
    "City": "Weilheim",
    "State": "Bayern",
    "Latitude": "047.84385000",
    "Longitude": "011.14761000",
    "Telephone Number": "+49 (0) 8812885",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.84385,11.14761",
    "IsOpen24Hours": false,
    "id": "47.84385,11.14761"
  },
  {
    "Gas Station Number": 2252,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Karlstr. 60",
    "Postcode": 71394,
    "City": "Kernen",
    "State": "Baden-Württemberg",
    "Latitude": "048.80870000",
    "Longitude": "009.32529000",
    "Telephone Number": "+49 (0) 7151-206",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8087,9.32529",
    "IsOpen24Hours": false,
    "id": "48.8087,9.32529"
  },
  {
    "Gas Station Number": 3609,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Bayreuther Str. 46",
    "Postcode": 92224,
    "City": "Amberg",
    "State": "Bayern",
    "Latitude": "049.45707000",
    "Longitude": "011.84874000",
    "Telephone Number": "+49 (0) 96216202",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "49.45707,11.84874",
    "IsOpen24Hours": true,
    "id": "49.45707,11.84874"
  },
  {
    "Gas Station Number": 4168,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Schilfwiese 1",
    "Postcode": 34593,
    "City": "Knuellwald",
    "State": "Hessen",
    "Latitude": "051.00435000",
    "Longitude": "009.47990000",
    "Telephone Number": "05681/9399398",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "51.00435,9.4799",
    "IsOpen24Hours": true,
    "id": "51.00435,9.4799"
  },
  {
    "Gas Station Number": 2016,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Schwetzinger Strasse 138",
    "Postcode": 69124,
    "City": "Heidelberg",
    "State": "Baden-Württemberg",
    "Latitude": "049.38494000",
    "Longitude": "008.67295000",
    "Telephone Number": "+49 (0) 62217855",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "49.38494,8.67295",
    "IsOpen24Hours": true,
    "id": "49.38494,8.67295"
  },
  {
    "Gas Station Number": 2242,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Kammermoosstr. 20",
    "Postcode": 88339,
    "City": "Bad Waldsee",
    "State": "Baden-Württemberg",
    "Latitude": "047.90225967",
    "Longitude": "009.69823890",
    "Telephone Number": "+49 (0) 75243111",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.90225967,9.6982389",
    "IsOpen24Hours": false,
    "id": "47.90225967,9.6982389"
  },
  {
    "Gas Station Number": 1288,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Lindauer Str. 43",
    "Postcode": 88069,
    "City": "Tettnang",
    "State": "Baden-Württemberg",
    "Latitude": "047.66662000",
    "Longitude": "009.59375000",
    "Telephone Number": "+49 (0) 75427400",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.66662,9.59375",
    "IsOpen24Hours": false,
    "id": "47.66662,9.59375"
  },
  {
    "Gas Station Number": 2092,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Heilbronner Strasse 31",
    "Postcode": 74177,
    "City": "Bad Friedrichshall",
    "State": "Baden-Württemberg",
    "Latitude": "049.21915000",
    "Longitude": "009.21513000",
    "Telephone Number": "+49 (0) 71362371",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.21915,9.21513",
    "IsOpen24Hours": false,
    "id": "49.21915,9.21513"
  },
  {
    "Gas Station Number": 5129,
    "Brand": "ENI",
    "Description": "Autobahntankstelle",
    "Address": "Enderstrasse 92 e",
    "Postcode": 1277,
    "City": "Dresden",
    "State": "Sachsen",
    "Latitude": "051.03351000",
    "Longitude": "013.80604000",
    "Telephone Number": "0351 / 25020192",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.03351,13.80604",
    "IsOpen24Hours": false,
    "id": "51.03351,13.80604"
  },
  {
    "Gas Station Number": 3355,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Im Gewerbepark",
    "Postcode": 96155,
    "City": "Buttenheim",
    "State": "Bayern",
    "Latitude": "049.79857000",
    "Longitude": "011.02748000",
    "Telephone Number": "+49 (0) 95454455",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "49.79857,11.02748",
    "IsOpen24Hours": true,
    "id": "49.79857,11.02748"
  },
  {
    "Gas Station Number": 1139,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Frontenhausener Str. 40",
    "Postcode": 84140,
    "City": "Gangkofen",
    "State": "Bayern",
    "Latitude": "048.43850067",
    "Longitude": "012.56236309",
    "Telephone Number": "+49 (0) 8722481",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.43850067,12.56236309",
    "IsOpen24Hours": false,
    "id": "48.43850067,12.56236309"
  },
  {
    "Gas Station Number": 5082,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Wittstocker Kreuz, Dorfstrae 67",
    "Postcode": 16909,
    "City": "Liebenthal",
    "State": "Brandenburg",
    "Latitude": "053.14874161",
    "Longitude": "012.39723847",
    "Telephone Number": "+49 (0) 33962509",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "53.14874161,12.39723847",
    "IsOpen24Hours": true,
    "id": "53.14874161,12.39723847"
  },
  {
    "Gas Station Number": 4554,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Hofheimer Str. 61",
    "Postcode": 65719,
    "City": "Hofheim-Lorsbach",
    "State": "Hessen",
    "Latitude": "050.11231159",
    "Longitude": "008.42627387",
    "Telephone Number": "+49 (0) 61929019",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.11231159,8.42627387",
    "IsOpen24Hours": false,
    "id": "50.11231159,8.42627387"
  },
  {
    "Gas Station Number": 1091,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Landsberger Strasse 447",
    "Postcode": 81241,
    "City": "Muenchen",
    "State": "Bayern",
    "Latitude": "048.14532666",
    "Longitude": "011.48121739",
    "Telephone Number": "+49 (0) 89833399",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.14532666,11.48121739",
    "IsOpen24Hours": false,
    "id": "48.14532666,11.48121739"
  },
  {
    "Gas Station Number": 5017,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Bat A 111 Stolper Heide",
    "Postcode": 16540,
    "City": "Hohen-Neuendorf",
    "State": "Brandenburg",
    "Latitude": "052.65138313",
    "Longitude": "013.24402717",
    "Telephone Number": "+49 (0) 33024938",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "52.65138313,13.24402717",
    "IsOpen24Hours": true,
    "id": "52.65138313,13.24402717"
  },
  {
    "Gas Station Number": 5204,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Oelsnitzer Str. 10-12",
    "Postcode": 8527,
    "City": "Plauen",
    "State": "Sachsen",
    "Latitude": "050.48498767",
    "Longitude": "012.13900274",
    "Telephone Number": "+49 (0) 37412248",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "50.48498767,12.13900274",
    "IsOpen24Hours": true,
    "id": "50.48498767,12.13900274"
  },
  {
    "Gas Station Number": 5076,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Arneburger Strasse",
    "Postcode": 39576,
    "City": "Stendal",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.61214000",
    "Longitude": "011.86306000",
    "Telephone Number": "+49 (0) 39317110",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "52.61214,11.86306",
    "IsOpen24Hours": true,
    "id": "52.61214,11.86306"
  },
  {
    "Gas Station Number": 1264,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Augsburger Str. 23",
    "Postcode": 86899,
    "City": "Landsberg",
    "State": "Bayern",
    "Latitude": "048.05769588",
    "Longitude": "010.86800049",
    "Telephone Number": "+49 (0) 81912483",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.05769588,10.86800049",
    "IsOpen24Hours": false,
    "id": "48.05769588,10.86800049"
  },
  {
    "Gas Station Number": 3636,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Godelstatt 3",
    "Postcode": 97437,
    "City": "Hassfurt",
    "State": "Bayern",
    "Latitude": "050.03161000",
    "Longitude": "010.54097000",
    "Telephone Number": "09521/9525563",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.03161,10.54097",
    "IsOpen24Hours": false,
    "id": "50.03161,10.54097"
  },
  {
    "Gas Station Number": 1078,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Ortenburger Strasse 40",
    "Postcode": 94474,
    "City": "Vilshofen",
    "State": "Bayern",
    "Latitude": "048.62699120",
    "Longitude": "013.18982900",
    "Telephone Number": "+49 (0) 85418595",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.6269912,13.189829",
    "IsOpen24Hours": false,
    "id": "48.6269912,13.189829"
  },
  {
    "Gas Station Number": 2606,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Berliner Str. 30",
    "Postcode": 72458,
    "City": "Albstadt",
    "State": "Baden-Württemberg",
    "Latitude": "048.20617118",
    "Longitude": "009.03445253",
    "Telephone Number": "+49 (0) 74315116",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.20617118,9.03445253",
    "IsOpen24Hours": false,
    "id": "48.20617118,9.03445253"
  },
  {
    "Gas Station Number": 1015,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstrasse 6",
    "Postcode": 82487,
    "City": "Oberammergau",
    "State": "Bayern",
    "Latitude": "047.59880896",
    "Longitude": "011.05929735",
    "Telephone Number": "+49 (0) 88229306",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.59880896,11.05929735",
    "IsOpen24Hours": false,
    "id": "47.59880896,11.05929735"
  },
  {
    "Gas Station Number": 1542,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Muenchner Strasse 284",
    "Postcode": 85051,
    "City": "Ingolstadt",
    "State": "Bayern",
    "Latitude": "048.76215000",
    "Longitude": "011.42539000",
    "Telephone Number": "+49 (0) 84112945",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.76215,11.42539",
    "IsOpen24Hours": false,
    "id": "48.76215,11.42539"
  },
  {
    "Gas Station Number": 2602,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Stuttgarter Strasse 24",
    "Postcode": 70839,
    "City": "Gerlingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.78995000",
    "Longitude": "009.04618000",
    "Telephone Number": "+49 (0) 71562530",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.78995,9.04618",
    "IsOpen24Hours": false,
    "id": "48.78995,9.04618"
  },
  {
    "Gas Station Number": 1676,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Memminger Str. 37",
    "Postcode": 87751,
    "City": "Heimertingen",
    "State": "Bayern",
    "Latitude": "048.03481000",
    "Longitude": "010.15321000",
    "Telephone Number": "+49 (0) 8335/989",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.03481,10.15321",
    "IsOpen24Hours": false,
    "id": "48.03481,10.15321"
  },
  {
    "Gas Station Number": 5041,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Buecheloher Str. B 87",
    "Postcode": 98693,
    "City": "Ilmenau",
    "State": "Thüringen",
    "Latitude": "050.69034343",
    "Longitude": "010.94027344",
    "Telephone Number": "+49 (0) 36772049",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "50.69034343,10.94027344",
    "IsOpen24Hours": true,
    "id": "50.69034343,10.94027344"
  },
  {
    "Gas Station Number": 1214,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Roemerstr. 50",
    "Postcode": 85055,
    "City": "Ingolstadt",
    "State": "Bayern",
    "Latitude": "048.77641000",
    "Longitude": "011.45548000",
    "Telephone Number": "+49 (0) 84195653",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.77641,11.45548",
    "IsOpen24Hours": true,
    "id": "48.77641,11.45548"
  },
  {
    "Gas Station Number": 5115,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Kulmbacher Str. 71",
    "Postcode": 7318,
    "City": "Saalfeld",
    "State": "Thüringen",
    "Latitude": "050.64889306",
    "Longitude": "011.37350954",
    "Telephone Number": "+49 (0) 36715205",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "50.64889306,11.37350954",
    "IsOpen24Hours": true,
    "id": "50.64889306,11.37350954"
  },
  {
    "Gas Station Number": 4702,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Marburger Strasse 30",
    "Postcode": 35075,
    "City": "Gladenbach",
    "State": "Hessen",
    "Latitude": "050.77166000",
    "Longitude": "008.60434000",
    "Telephone Number": "+49 (0) 64629116",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.77166,8.60434",
    "IsOpen24Hours": false,
    "id": "50.77166,8.60434"
  },
  {
    "Gas Station Number": 5069,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Blankenburger Heerstr. 1 A",
    "Postcode": 38820,
    "City": "Halberstadt",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.88519000",
    "Longitude": "011.03109000",
    "Telephone Number": "+49 (0) 39412615",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "51.88519,11.03109",
    "IsOpen24Hours": true,
    "id": "51.88519,11.03109"
  },
  {
    "Gas Station Number": 2246,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Friedrich-Bauer-Str. 31",
    "Postcode": 73642,
    "City": "Welzheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.87023863",
    "Longitude": "009.62364836",
    "Telephone Number": "+49 (0) 71829293",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.87023863,9.62364836",
    "IsOpen24Hours": false,
    "id": "48.87023863,9.62364836"
  },
  {
    "Gas Station Number": 2137,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Donaustrasse 2",
    "Postcode": 76437,
    "City": "Rastatt",
    "State": "Baden-Württemberg",
    "Latitude": "048.83870000",
    "Longitude": "008.20140000",
    "Telephone Number": "+49 (0) 72223402",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8387,8.2014",
    "IsOpen24Hours": false,
    "id": "48.8387,8.2014"
  },
  {
    "Gas Station Number": 1034,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Perger Strasse 2",
    "Postcode": 84489,
    "City": "Burghausen",
    "State": "Bayern",
    "Latitude": "048.16638159",
    "Longitude": "012.82779441",
    "Telephone Number": "+49 (0) 86776516",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.16638159,12.82779441",
    "IsOpen24Hours": false,
    "id": "48.16638159,12.82779441"
  },
  {
    "Gas Station Number": 1219,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Waldseer Str. 107",
    "Postcode": 88400,
    "City": "Biberach",
    "State": "Baden-Württemberg",
    "Latitude": "048.08592000",
    "Longitude": "009.79590000",
    "Telephone Number": "+49 (0) 73512108",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.08592,9.7959",
    "IsOpen24Hours": false,
    "id": "48.08592,9.7959"
  },
  {
    "Gas Station Number": 4779,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Helmstedt Sued / BAB A2",
    "Postcode": 38350,
    "City": "Helmstedt",
    "State": "Niedersachsen",
    "Latitude": "052.22440000",
    "Longitude": "011.01110000",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "52.2244,11.0111",
    "IsOpen24Hours": true,
    "id": "52.2244,11.0111"
  },
  {
    "Gas Station Number": 4040,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Schnellstrasse West",
    "Postcode": 55130,
    "City": "Mainz",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.00437200",
    "Longitude": "008.26258800",
    "Telephone Number": "+49 (0) 61318648",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.004372,8.262588",
    "IsOpen24Hours": false,
    "id": "50.004372,8.262588"
  },
  {
    "Gas Station Number": 3155,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "An der A 3",
    "Postcode": 97355,
    "City": "Haidt",
    "State": "Bayern",
    "Latitude": "049.78054801",
    "Longitude": "010.24479844",
    "Telephone Number": "+49 (0) 9325200",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "49.78054801,10.24479844",
    "IsOpen24Hours": true,
    "id": "49.78054801,10.24479844"
  },
  {
    "Gas Station Number": 3014,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Hoefener Strasse 44",
    "Postcode": 90763,
    "City": "Fuerth",
    "State": "Bayern",
    "Latitude": "049.44896500",
    "Longitude": "011.00043000",
    "Telephone Number": "+49 (0) 91170571",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.448965,11.00043",
    "IsOpen24Hours": false,
    "id": "49.448965,11.00043"
  },
  {
    "Gas Station Number": 5007,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Halbendorfer Weg 9",
    "Postcode": 2943,
    "City": "Weisswasser",
    "State": "Sachsen",
    "Latitude": "051.52204305",
    "Longitude": "014.60600898",
    "Telephone Number": "+49 (0) 35762053",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.52204305,14.60600898",
    "IsOpen24Hours": false,
    "id": "51.52204305,14.60600898"
  },
  {
    "Gas Station Number": 2005,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Freiburger Str. 3",
    "Postcode": 79189,
    "City": "Bad Krozingen",
    "State": "Baden-Württemberg",
    "Latitude": "047.92133714",
    "Longitude": "007.70635386",
    "Telephone Number": "+49 (0) 76339088",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.92133714,7.70635386",
    "IsOpen24Hours": false,
    "id": "47.92133714,7.70635386"
  },
  {
    "Gas Station Number": 1175,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Albert-Schweitzer-Str. 68",
    "Postcode": 82008,
    "City": "Unterhaching",
    "State": "Bayern",
    "Latitude": "048.08286446",
    "Longitude": "011.60232257",
    "Telephone Number": "+49 (0) 89617016",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.08286446,11.60232257",
    "IsOpen24Hours": false,
    "id": "48.08286446,11.60232257"
  },
  {
    "Gas Station Number": 1166,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Schmidstrasse 2",
    "Postcode": 94234,
    "City": "Viechtach",
    "State": "Bayern",
    "Latitude": "049.07863000",
    "Longitude": "012.87956000",
    "Telephone Number": "+49 (0) 99429042",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.07863,12.87956",
    "IsOpen24Hours": false,
    "id": "49.07863,12.87956"
  },
  {
    "Gas Station Number": 3146,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Witschelstr. 91",
    "Postcode": 90431,
    "City": "Nuernberg",
    "State": "Bayern",
    "Latitude": "049.44781000",
    "Longitude": "011.03898000",
    "Telephone Number": "+49 (0) 91194062",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "49.44781,11.03898",
    "IsOpen24Hours": true,
    "id": "49.44781,11.03898"
  },
  {
    "Gas Station Number": 1276,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Dachauer Strasse 469",
    "Postcode": 80993,
    "City": "Muenchen",
    "State": "Bayern",
    "Latitude": "048.20245671",
    "Longitude": "011.49069687",
    "Telephone Number": "+49 (0) 89148397",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.20245671,11.49069687",
    "IsOpen24Hours": true,
    "id": "48.20245671,11.49069687"
  },
  {
    "Gas Station Number": 1606,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Reichenhaller Str. 24",
    "Postcode": 83334,
    "City": "Inzell",
    "State": "Bayern",
    "Latitude": "047.76111933",
    "Longitude": "012.74836932",
    "Telephone Number": "+49 (0) 86659293",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.76111933,12.74836932",
    "IsOpen24Hours": false,
    "id": "47.76111933,12.74836932"
  },
  {
    "Gas Station Number": 1086,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Muenchner Strasse 40",
    "Postcode": 83043,
    "City": "Bad Aibling",
    "State": "Bayern",
    "Latitude": "047.87268102",
    "Longitude": "011.98067547",
    "Telephone Number": "+49 (0) 80618508",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.87268102,11.98067547",
    "IsOpen24Hours": false,
    "id": "47.87268102,11.98067547"
  },
  {
    "Gas Station Number": 2615,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Kleinenztalstrasse 39",
    "Postcode": 75323,
    "City": "Bad Wildbad-Calmbach",
    "State": "Baden-Württemberg",
    "Latitude": "048.77125543",
    "Longitude": "008.58378518",
    "Telephone Number": "+49 (0) 70817554",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.77125543,8.58378518",
    "IsOpen24Hours": false,
    "id": "48.77125543,8.58378518"
  },
  {
    "Gas Station Number": 3102,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Nuernberger Strasse 2A",
    "Postcode": 92224,
    "City": "Amberg",
    "State": "Bayern",
    "Latitude": "049.44568314",
    "Longitude": "011.83479183",
    "Telephone Number": "+49 (0) 96212247",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.44568314,11.83479183",
    "IsOpen24Hours": false,
    "id": "49.44568314,11.83479183"
  },
  {
    "Gas Station Number": 3158,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Frankenhoehe Nord",
    "Postcode": 91589,
    "City": "Aurach",
    "State": "Bayern",
    "Latitude": "049.24234000",
    "Longitude": "010.35762000",
    "Telephone Number": "+49 (0) 98049191",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "49.24234,10.35762",
    "IsOpen24Hours": true,
    "id": "49.24234,10.35762"
  },
  {
    "Gas Station Number": 4093,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Salierstrasse 62",
    "Postcode": 67105,
    "City": "Schifferstadt",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.38307000",
    "Longitude": "008.38841000",
    "Telephone Number": "+49 (0) 62359886",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.38307,8.38841",
    "IsOpen24Hours": false,
    "id": "49.38307,8.38841"
  },
  {
    "Gas Station Number": 3142,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Allersberger Str. 81",
    "Postcode": 91154,
    "City": "Roth",
    "State": "Bayern",
    "Latitude": "049.24674000",
    "Longitude": "011.10639000",
    "Telephone Number": "+49 (0) 91716264",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.24674,11.10639",
    "IsOpen24Hours": false,
    "id": "49.24674,11.10639"
  },
  {
    "Gas Station Number": 3001,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Unterislinger Weg 13",
    "Postcode": 93053,
    "City": "Regensburg",
    "State": "Bayern",
    "Latitude": "048.99972000",
    "Longitude": "012.11096000",
    "Telephone Number": "+49 (0) 94172181",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.99972,12.11096",
    "IsOpen24Hours": false,
    "id": "48.99972,12.11096"
  },
  {
    "Gas Station Number": 1604,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Mindelheimer Str. 78",
    "Postcode": 87600,
    "City": "Kaufbeuren",
    "State": "Bayern",
    "Latitude": "047.90606284",
    "Longitude": "010.60847739",
    "Telephone Number": "+49 (0) 83411222",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.90606284,10.60847739",
    "IsOpen24Hours": false,
    "id": "47.90606284,10.60847739"
  },
  {
    "Gas Station Number": 4077,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Eberstaedter Strasse 115",
    "Postcode": 64319,
    "City": "Pfungstadt",
    "State": "Hessen",
    "Latitude": "049.80436000",
    "Longitude": "008.60201000",
    "Telephone Number": "+49 (0) 61578656",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.80436,8.60201",
    "IsOpen24Hours": false,
    "id": "49.80436,8.60201"
  },
  {
    "Gas Station Number": 5002,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Magdeburger Str. 262",
    "Postcode": 39218,
    "City": "Schoenebeck",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.03420000",
    "Longitude": "011.70533000",
    "Telephone Number": "+49 (0) 39288435",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "52.0342,11.70533",
    "IsOpen24Hours": true,
    "id": "52.0342,11.70533"
  },
  {
    "Gas Station Number": 5150,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Bergstrae 39",
    "Postcode": 37339,
    "City": "Teistungen",
    "State": "Thüringen",
    "Latitude": "051.46998000",
    "Longitude": "010.26971000",
    "Telephone Number": "+49 (0) 36071872",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.46998,10.26971",
    "IsOpen24Hours": false,
    "id": "51.46998,10.26971"
  },
  {
    "Gas Station Number": 1609,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Lindauer Str. 17",
    "Postcode": 87509,
    "City": "Immenstadt",
    "State": "Bayern",
    "Latitude": "047.56686000",
    "Longitude": "010.19303000",
    "Telephone Number": "+49 (0) 83231855",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.56686,10.19303",
    "IsOpen24Hours": false,
    "id": "47.56686,10.19303"
  },
  {
    "Gas Station Number": 3167,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Gersdorfer Hauptstr. 1",
    "Postcode": 91227,
    "City": "Leinburg",
    "State": "Bayern",
    "Latitude": "049.45836000",
    "Longitude": "011.34692000",
    "Telephone Number": "09120/565",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.45836,11.34692",
    "IsOpen24Hours": false,
    "id": "49.45836,11.34692"
  },
  {
    "Gas Station Number": 3069,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Regensburger Strasse 68/A",
    "Postcode": 92224,
    "City": "Amberg",
    "State": "Bayern",
    "Latitude": "049.43973000",
    "Longitude": "011.87710000",
    "Telephone Number": "+49 (0) 96217814",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.43973,11.8771",
    "IsOpen24Hours": false,
    "id": "49.43973,11.8771"
  },
  {
    "Gas Station Number": 4123,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Dieburger Strasse 117",
    "Postcode": 63322,
    "City": "Roedermark-Oberroden",
    "State": "Hessen",
    "Latitude": "049.97824548",
    "Longitude": "008.76175000",
    "Telephone Number": "+49 (0) 60748816",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.97824548,8.76175",
    "IsOpen24Hours": false,
    "id": "49.97824548,8.76175"
  },
  {
    "Gas Station Number": 2250,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Nicola-Tesla-Strae",
    "Postcode": 77972,
    "City": "Mahlberg",
    "State": "Baden-Württemberg",
    "Latitude": "048.27536233",
    "Longitude": "007.78465699",
    "Telephone Number": "+49 (0) 78224405",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.27536233,7.78465699",
    "IsOpen24Hours": true,
    "id": "48.27536233,7.78465699"
  },
  {
    "Gas Station Number": 5010,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Berliner Allee 67",
    "Postcode": 16303,
    "City": "Schwedt/Oder",
    "State": "Brandenburg",
    "Latitude": "053.05255601",
    "Longitude": "014.28044531",
    "Telephone Number": "+49 (0) 33322348",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "53.05255601,14.28044531",
    "IsOpen24Hours": true,
    "id": "53.05255601,14.28044531"
  },
  {
    "Gas Station Number": 2162,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Heidensteilstrasse 8",
    "Postcode": 55743,
    "City": "Idar-Oberstein",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.72719000",
    "Longitude": "007.31222000",
    "Telephone Number": "+49 (0) 67814313",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.72719,7.31222",
    "IsOpen24Hours": false,
    "id": "49.72719,7.31222"
  },
  {
    "Gas Station Number": 4065,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Heidelberger Strasse 35",
    "Postcode": 64342,
    "City": "Seeheim-Jugenheim",
    "State": "Hessen",
    "Latitude": "049.77138000",
    "Longitude": "008.64598000",
    "Telephone Number": "+49 (0) 62579629",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.77138,8.64598",
    "IsOpen24Hours": false,
    "id": "49.77138,8.64598"
  },
  {
    "Gas Station Number": 3604,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Leistenstrasse 2",
    "Postcode": 97204,
    "City": "Hoechberg",
    "State": "Bayern",
    "Latitude": "049.78842072",
    "Longitude": "009.89876343",
    "Telephone Number": "+49 (0) 93149266",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.78842072,9.89876343",
    "IsOpen24Hours": false,
    "id": "49.78842072,9.89876343"
  },
  {
    "Gas Station Number": 4054,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Zwingenberger Strasse 25",
    "Postcode": 64342,
    "City": "Seeheim-Jugenheim",
    "State": "Hessen",
    "Latitude": "049.75185400",
    "Longitude": "008.63109100",
    "Telephone Number": "+49 (0) 62579036",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.751854,8.631091",
    "IsOpen24Hours": false,
    "id": "49.751854,8.631091"
  },
  {
    "Gas Station Number": 5051,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Plauensche Str. 2",
    "Postcode": 7973,
    "City": "Greiz",
    "State": "Thüringen",
    "Latitude": "050.64720623",
    "Longitude": "012.19469413",
    "Telephone Number": "+49 (0) 36616352",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "50.64720623,12.19469413",
    "IsOpen24Hours": true,
    "id": "50.64720623,12.19469413"
  },
  {
    "Gas Station Number": 5029,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofsallee",
    "Postcode": 3253,
    "City": "Doberlug-Kirchhain",
    "State": "Brandenburg",
    "Latitude": "051.61389000",
    "Longitude": "013.55639000",
    "Telephone Number": "+49 (0) 35322451",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "51.61389,13.55639",
    "IsOpen24Hours": true,
    "id": "51.61389,13.55639"
  },
  {
    "Gas Station Number": 5062,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Flurteil Eule 2",
    "Postcode": 4680,
    "City": "Colditz",
    "State": "Sachsen",
    "Latitude": "051.13726944",
    "Longitude": "012.79151064",
    "Telephone Number": "+49 (0) 34381431",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.13726944,12.79151064",
    "IsOpen24Hours": false,
    "id": "51.13726944,12.79151064"
  },
  {
    "Gas Station Number": 5050,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Blumberger Chaussee",
    "Postcode": 16356,
    "City": "Ahrensfelde",
    "State": "Brandenburg",
    "Latitude": "052.58415806",
    "Longitude": "013.58893345",
    "Telephone Number": "+49 (0) 30937982",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "52.58415806,13.58893345",
    "IsOpen24Hours": true,
    "id": "52.58415806,13.58893345"
  },
  {
    "Gas Station Number": 1281,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Obere Hauptstr. 5",
    "Postcode": 85084,
    "City": "Reichertshofen/Wind.",
    "State": "Bayern",
    "Latitude": "048.64514720",
    "Longitude": "011.51395257",
    "Telephone Number": "+49 (0) 84533306",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.6451472,11.51395257",
    "IsOpen24Hours": true,
    "id": "48.6451472,11.51395257"
  },
  {
    "Gas Station Number": 5256,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Apoldaerstr. 2",
    "Postcode": 99441,
    "City": "Umpferstedt",
    "State": "Thüringen",
    "Latitude": "050.97510000",
    "Longitude": "011.41396000",
    "Telephone Number": "+49 (0) 36435105",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9751,11.41396",
    "IsOpen24Hours": false,
    "id": "50.9751,11.41396"
  },
  {
    "Gas Station Number": 2191,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Freiburger Str. 17B",
    "Postcode": 79215,
    "City": "Elzach",
    "State": "Baden-Württemberg",
    "Latitude": "048.16706655",
    "Longitude": "008.05931736",
    "Telephone Number": "+49 (0) 76827766",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.16706655,8.05931736",
    "IsOpen24Hours": false,
    "id": "48.16706655,8.05931736"
  },
  {
    "Gas Station Number": 1076,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Neurieder Strasse 18",
    "Postcode": 81475,
    "City": "Muenchen",
    "State": "Bayern",
    "Latitude": "048.08914000",
    "Longitude": "011.47439000",
    "Telephone Number": "+49 (0) 89751333",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.08914,11.47439",
    "IsOpen24Hours": false,
    "id": "48.08914,11.47439"
  },
  {
    "Gas Station Number": 2024,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Hirsauer Strasse 216",
    "Postcode": 75180,
    "City": "Pforzheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.87152000",
    "Longitude": "008.67859000",
    "Telephone Number": "+49 (0) 72317400",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.87152,8.67859",
    "IsOpen24Hours": false,
    "id": "48.87152,8.67859"
  },
  {
    "Gas Station Number": 5039,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Olvenstedter Chaussee 22",
    "Postcode": 39130,
    "City": "Magdeburg",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.15276000",
    "Longitude": "011.57365000",
    "Telephone Number": "+49 (0) 39172147",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "52.15276,11.57365",
    "IsOpen24Hours": true,
    "id": "52.15276,11.57365"
  },
  {
    "Gas Station Number": 4140,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Clemensstrasse 12",
    "Postcode": 56218,
    "City": "Muelheim-Kaerlich",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.39013000",
    "Longitude": "007.48685000",
    "Telephone Number": "+49 (0) 26301817",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.39013,7.48685",
    "IsOpen24Hours": false,
    "id": "50.39013,7.48685"
  },
  {
    "Gas Station Number": 2150,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Illinger Eck B 10/B 35",
    "Postcode": 75428,
    "City": "Illingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.95328663",
    "Longitude": "008.91265141",
    "Telephone Number": "+49 (0) 70422050",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.95328663,8.91265141",
    "IsOpen24Hours": false,
    "id": "48.95328663,8.91265141"
  },
  {
    "Gas Station Number": 3092,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Kissinger Strasse 35",
    "Postcode": 97769,
    "City": "Bad Brueckenau",
    "State": "Bayern",
    "Latitude": "050.31187411",
    "Longitude": "009.79383164",
    "Telephone Number": "+49 (0) 97412020",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.31187411,9.79383164",
    "IsOpen24Hours": false,
    "id": "50.31187411,9.79383164"
  },
  {
    "Gas Station Number": 2076,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Kriegsbergstrasse 55 A",
    "Postcode": 70174,
    "City": "Stuttgart",
    "State": "Baden-Württemberg",
    "Latitude": "048.78229000",
    "Longitude": "009.17149000",
    "Telephone Number": "+49 (0) 71129502",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.78229,9.17149",
    "IsOpen24Hours": true,
    "id": "48.78229,9.17149"
  },
  {
    "Gas Station Number": 1616,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Ittlinger Str. 226",
    "Postcode": 94315,
    "City": "Straubing",
    "State": "Bayern",
    "Latitude": "048.88413000",
    "Longitude": "012.59316000",
    "Telephone Number": "+49 (0) 94216113",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.88413,12.59316",
    "IsOpen24Hours": false,
    "id": "48.88413,12.59316"
  },
  {
    "Gas Station Number": 5016,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Stettiner Chaussee",
    "Postcode": 17309,
    "City": "Pasewalk",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "053.50287725",
    "Longitude": "014.00323994",
    "Telephone Number": "+49 (0) 39732101",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "53.50287725,14.00323994",
    "IsOpen24Hours": true,
    "id": "53.50287725,14.00323994"
  },
  {
    "Gas Station Number": 7014,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Dannenwalder Weg 69",
    "Postcode": 13439,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.59169070",
    "Longitude": "013.34644097",
    "Telephone Number": "+49 (0) 30416520",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5916907,13.34644097",
    "IsOpen24Hours": false,
    "id": "52.5916907,13.34644097"
  },
  {
    "Gas Station Number": 1203,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Seeonerstrasse 1",
    "Postcode": 83119,
    "City": "Obing",
    "State": "Bayern",
    "Latitude": "047.99977550",
    "Longitude": "012.41016086",
    "Telephone Number": "+49 (0) 86241450",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.9997755,12.41016086",
    "IsOpen24Hours": false,
    "id": "47.9997755,12.41016086"
  },
  {
    "Gas Station Number": 3127,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Nuernberger Strasse 51",
    "Postcode": 91781,
    "City": "Weissenburg",
    "State": "Bayern",
    "Latitude": "049.03406047",
    "Longitude": "010.97185819",
    "Telephone Number": "+49 (0) 91418737",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.03406047,10.97185819",
    "IsOpen24Hours": false,
    "id": "49.03406047,10.97185819"
  },
  {
    "Gas Station Number": 1152,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Cosimastrasse 234",
    "Postcode": 81927,
    "City": "Muenchen",
    "State": "Bayern",
    "Latitude": "048.17227843",
    "Longitude": "011.63686840",
    "Telephone Number": "+49 (0) 89951631",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.17227843,11.6368684",
    "IsOpen24Hours": false,
    "id": "48.17227843,11.6368684"
  },
  {
    "Gas Station Number": 3601,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "In der Au 1",
    "Postcode": 96224,
    "City": "Burgkunstadt",
    "State": "Bayern",
    "Latitude": "050.13719718",
    "Longitude": "011.25089166",
    "Telephone Number": "+49 (0) 95721544",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "50.13719718,11.25089166",
    "IsOpen24Hours": true,
    "id": "50.13719718,11.25089166"
  },
  {
    "Gas Station Number": 2027,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Killisfeldstrasse 44",
    "Postcode": 76227,
    "City": "Karlsruhe",
    "State": "Baden-Württemberg",
    "Latitude": "048.99646000",
    "Longitude": "008.45472000",
    "Telephone Number": "+49 (0) 72144175",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.99646,8.45472",
    "IsOpen24Hours": false,
    "id": "48.99646,8.45472"
  },
  {
    "Gas Station Number": 1285,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Lindenring 5",
    "Postcode": 82024,
    "City": "Taufkirchen",
    "State": "Bayern",
    "Latitude": "048.05210000",
    "Longitude": "011.60575000",
    "Telephone Number": "+49 (0) 89612171",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.0521,11.60575",
    "IsOpen24Hours": false,
    "id": "48.0521,11.60575"
  },
  {
    "Gas Station Number": 4132,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Konrad-Adenauer-Str. 71",
    "Postcode": 63322,
    "City": "Roedermark-Urberrach",
    "State": "Hessen",
    "Latitude": "049.97438586",
    "Longitude": "008.78935107",
    "Telephone Number": "+49 (0) 60748618",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.97438586,8.78935107",
    "IsOpen24Hours": false,
    "id": "49.97438586,8.78935107"
  },
  {
    "Gas Station Number": 4218,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Peisel",
    "Postcode": 53797,
    "City": "Lohmar",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.86883000",
    "Longitude": "007.25244000",
    "Telephone Number": "+49 (0) 22469126",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.86883,7.25244",
    "IsOpen24Hours": false,
    "id": "50.86883,7.25244"
  },
  {
    "Gas Station Number": 2014,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Huettenfelder Str. 46",
    "Postcode": 69502,
    "City": "Hemsbach",
    "State": "Baden-Württemberg",
    "Latitude": "049.59301623",
    "Longitude": "008.63971061",
    "Telephone Number": "+49 (0) 62017144",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "49.59301623,8.63971061",
    "IsOpen24Hours": true,
    "id": "49.59301623,8.63971061"
  },
  {
    "Gas Station Number": 4166,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Robert-Bosch-Strasse 14",
    "Postcode": 35440,
    "City": "Linden",
    "State": "Hessen",
    "Latitude": "050.53763000",
    "Longitude": "008.65385000",
    "Telephone Number": "+49 (0) 64037202",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.53763,8.65385",
    "IsOpen24Hours": false,
    "id": "50.53763,8.65385"
  },
  {
    "Gas Station Number": 1217,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Schumacherring 96",
    "Postcode": 87437,
    "City": "Kempten",
    "State": "Bayern",
    "Latitude": "047.73111000",
    "Longitude": "010.32634000",
    "Telephone Number": "+49 (0) 83175510",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "47.73111,10.32634",
    "IsOpen24Hours": true,
    "id": "47.73111,10.32634"
  },
  {
    "Gas Station Number": 2144,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Jakob-Schuele-Str. 52",
    "Postcode": 73655,
    "City": "Pluederhausen",
    "State": "Baden-Württemberg",
    "Latitude": "048.80059000",
    "Longitude": "009.59286000",
    "Telephone Number": "07181/82667",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.80059,9.59286",
    "IsOpen24Hours": false,
    "id": "48.80059,9.59286"
  },
  {
    "Gas Station Number": 3019,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Hauptstrasse 5-7",
    "Postcode": 90607,
    "City": "Rueckersdorf",
    "State": "Bayern",
    "Latitude": "049.49203000",
    "Longitude": "011.23676000",
    "Telephone Number": "+49 (0) 91157938",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.49203,11.23676",
    "IsOpen24Hours": false,
    "id": "49.49203,11.23676"
  },
  {
    "Gas Station Number": 4039,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Hindemithstr. 4 a",
    "Postcode": 55127,
    "City": "Mainz-Lerchenberg",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.95925000",
    "Longitude": "008.20013000",
    "Telephone Number": "+49 (0) 61317152",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.95925,8.20013",
    "IsOpen24Hours": false,
    "id": "49.95925,8.20013"
  },
  {
    "Gas Station Number": 2211,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Hauptstr. 57",
    "Postcode": 77960,
    "City": "Seelbach",
    "State": "Baden-Württemberg",
    "Latitude": "048.31576109",
    "Longitude": "007.93853569",
    "Telephone Number": "+49 (0) 78239663",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.31576109,7.93853569",
    "IsOpen24Hours": false,
    "id": "48.31576109,7.93853569"
  },
  {
    "Gas Station Number": 2067,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Friesenstrasse 38",
    "Postcode": 71640,
    "City": "Ludwigsburg",
    "State": "Baden-Württemberg",
    "Latitude": "048.89322000",
    "Longitude": "009.22330000",
    "Telephone Number": "+49 (0) 71412955",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.89322,9.2233",
    "IsOpen24Hours": false,
    "id": "48.89322,9.2233"
  },
  {
    "Gas Station Number": 1608,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Neuoettinger Str. 36",
    "Postcode": 84543,
    "City": "Winhoering",
    "State": "Bayern",
    "Latitude": "048.26572561",
    "Longitude": "012.65133925",
    "Telephone Number": "+49 (0) 86718820",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.26572561,12.65133925",
    "IsOpen24Hours": false,
    "id": "48.26572561,12.65133925"
  },
  {
    "Gas Station Number": 3080,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Schweinfurter Strasse 1",
    "Postcode": 93059,
    "City": "Regensburg",
    "State": "Bayern",
    "Latitude": "049.02885000",
    "Longitude": "012.08525000",
    "Telephone Number": "+49 (0) 94184667",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.02885,12.08525",
    "IsOpen24Hours": false,
    "id": "49.02885,12.08525"
  },
  {
    "Gas Station Number": 1165,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Holzweg 21",
    "Postcode": 86156,
    "City": "Augsburg",
    "State": "Bayern",
    "Latitude": "048.38996000",
    "Longitude": "010.86653000",
    "Telephone Number": "+49 (0) 82146136",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.38996,10.86653",
    "IsOpen24Hours": false,
    "id": "48.38996,10.86653"
  },
  {
    "Gas Station Number": 2040,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Reichenaustrasse 43 A",
    "Postcode": 78467,
    "City": "Konstanz",
    "State": "Baden-Württemberg",
    "Latitude": "047.67176000",
    "Longitude": "009.16650000",
    "Telephone Number": "+49 (0) 75316223",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.67176,9.1665",
    "IsOpen24Hours": false,
    "id": "47.67176,9.1665"
  },
  {
    "Gas Station Number": 4230,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Industriepark Hoechst",
    "Postcode": 65926,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.08232000",
    "Longitude": "008.54354000",
    "Telephone Number": "+49 (0) 69300928",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "50.08232,8.54354",
    "IsOpen24Hours": true,
    "id": "50.08232,8.54354"
  },
  {
    "Gas Station Number": 4775,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Morsbacher Str. 13",
    "Postcode": 57537,
    "City": "Wissen / Sieg",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.78691000",
    "Longitude": "007.72913000",
    "Telephone Number": "02742/2876",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.78691,7.72913",
    "IsOpen24Hours": false,
    "id": "50.78691,7.72913"
  },
  {
    "Gas Station Number": 4053,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Frankfurter Strasse 50",
    "Postcode": 64839,
    "City": "Muenster",
    "State": "Hessen",
    "Latitude": "049.92920876",
    "Longitude": "008.85750393",
    "Telephone Number": "+49 (0) 60713054",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.92920876,8.85750393",
    "IsOpen24Hours": false,
    "id": "49.92920876,8.85750393"
  },
  {
    "Gas Station Number": 2074,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Gymnasiumstrasse 71",
    "Postcode": 74072,
    "City": "Heilbronn",
    "State": "Baden-Württemberg",
    "Latitude": "049.14189000",
    "Longitude": "009.22569000",
    "Telephone Number": "+49 (0) 71318049",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.14189,9.22569",
    "IsOpen24Hours": false,
    "id": "49.14189,9.22569"
  },
  {
    "Gas Station Number": 2611,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Eutighofer Str. 124",
    "Postcode": 73525,
    "City": "Schwaebisch-Gmuend",
    "State": "Baden-Württemberg",
    "Latitude": "048.78604000",
    "Longitude": "009.76823000",
    "Telephone Number": "+49 (0) 71713082",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.78604,9.76823",
    "IsOpen24Hours": false,
    "id": "48.78604,9.76823"
  },
  {
    "Gas Station Number": 1056,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Sonthofener Strasse 16",
    "Postcode": 87561,
    "City": "Oberstdorf",
    "State": "Bayern",
    "Latitude": "047.41292000",
    "Longitude": "010.27536000",
    "Telephone Number": "+49 (0) 83229626",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.41292,10.27536",
    "IsOpen24Hours": false,
    "id": "47.41292,10.27536"
  },
  {
    "Gas Station Number": 1284,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Haager Strasse 34",
    "Postcode": 85435,
    "City": "Erding",
    "State": "Bayern",
    "Latitude": "048.30168997",
    "Longitude": "011.90837822",
    "Telephone Number": "+49 (0) 81229026",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.30168997,11.90837822",
    "IsOpen24Hours": false,
    "id": "48.30168997,11.90837822"
  },
  {
    "Gas Station Number": 2218,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Oberopfinger Str. 25",
    "Postcode": 88450,
    "City": "Berkheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.04266963",
    "Longitude": "010.08407617",
    "Telephone Number": "+49 (0) 8395658",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.04266963,10.08407617",
    "IsOpen24Hours": false,
    "id": "48.04266963,10.08407617"
  },
  {
    "Gas Station Number": 5209,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Goeltzschtalstr. 75",
    "Postcode": 8209,
    "City": "Auerbach",
    "State": "Sachsen",
    "Latitude": "050.49522921",
    "Longitude": "012.39653702",
    "Telephone Number": "+49 (0) 37441711",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "50.49522921,12.39653702",
    "IsOpen24Hours": true,
    "id": "50.49522921,12.39653702"
  },
  {
    "Gas Station Number": 1280,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Raffineriestr. 119",
    "Postcode": 93333,
    "City": "Neustadt/Donau",
    "State": "Bayern",
    "Latitude": "048.78366927",
    "Longitude": "011.76958312",
    "Telephone Number": "+49 (0) 94457502",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.78366927,11.76958312",
    "IsOpen24Hours": true,
    "id": "48.78366927,11.76958312"
  },
  {
    "Gas Station Number": 4305,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Metzer Str. 114",
    "Postcode": 66117,
    "City": "Saarbruecken",
    "State": "Saarland",
    "Latitude": "049.21765300",
    "Longitude": "006.96833600",
    "Telephone Number": "+49 (0) 68154240",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.217653,6.968336",
    "IsOpen24Hours": false,
    "id": "49.217653,6.968336"
  },
  {
    "Gas Station Number": 3144,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Hersbrucker Str. 64",
    "Postcode": 91207,
    "City": "Lauf",
    "State": "Bayern",
    "Latitude": "049.51597000",
    "Longitude": "011.29061000",
    "Telephone Number": "+49 (0) 91238446",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "49.51597,11.29061",
    "IsOpen24Hours": true,
    "id": "49.51597,11.29061"
  },
  {
    "Gas Station Number": 4602,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Pappelweg 10 und 12",
    "Postcode": 63741,
    "City": "Aschaffenburg",
    "State": "Bayern",
    "Latitude": "049.96155600",
    "Longitude": "009.12680800",
    "Telephone Number": "+49 (0) 6021/584",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.961556,9.126808",
    "IsOpen24Hours": false,
    "id": "49.961556,9.126808"
  },
  {
    "Gas Station Number": 3029,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Fuerther Strasse 51",
    "Postcode": 90513,
    "City": "Zirndorf",
    "State": "Bayern",
    "Latitude": "049.44864858",
    "Longitude": "010.96262984",
    "Telephone Number": "+49 (0) 91160448",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.44864858,10.96262984",
    "IsOpen24Hours": false,
    "id": "49.44864858,10.96262984"
  },
  {
    "Gas Station Number": 3063,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Riedfelder Ortsstrasse 2A",
    "Postcode": 91413,
    "City": "Neustadt a. d. Aisch",
    "State": "Bayern",
    "Latitude": "049.58219894",
    "Longitude": "010.59729519",
    "Telephone Number": "+49 (0) 91612336",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.58219894,10.59729519",
    "IsOpen24Hours": false,
    "id": "49.58219894,10.59729519"
  },
  {
    "Gas Station Number": 4164,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Bahnhofstr./Am Lohpfad 1",
    "Postcode": 35260,
    "City": "Stadtallendorf",
    "State": "Hessen",
    "Latitude": "050.82895000",
    "Longitude": "009.02061000",
    "Telephone Number": "+49 (0) 64289212",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.82895,9.02061",
    "IsOpen24Hours": false,
    "id": "50.82895,9.02061"
  },
  {
    "Gas Station Number": 5079,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Alt Mahlsdorf 94",
    "Postcode": 12623,
    "City": "Berlin-Hellersdorf",
    "State": "Berlin",
    "Latitude": "052.50414791",
    "Longitude": "013.61981328",
    "Telephone Number": "+49 (0) 30566781",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "52.50414791,13.61981328",
    "IsOpen24Hours": true,
    "id": "52.50414791,13.61981328"
  },
  {
    "Gas Station Number": 1618,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Augsburger Strasse 133",
    "Postcode": 86633,
    "City": "Neuburg / Donau",
    "State": "Bayern",
    "Latitude": "048.71954295",
    "Longitude": "011.17740875",
    "Telephone Number": "+49 (0) 84311329",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.71954295,11.17740875",
    "IsOpen24Hours": false,
    "id": "48.71954295,11.17740875"
  },
  {
    "Gas Station Number": 2034,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Schlater Strasse 39",
    "Postcode": 73037,
    "City": "Goeppingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.68181000",
    "Longitude": "009.68773000",
    "Telephone Number": "+49 (0) 71618118",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.68181,9.68773",
    "IsOpen24Hours": false,
    "id": "48.68181,9.68773"
  },
  {
    "Gas Station Number": 5009,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Am Bahnhof 1-10",
    "Postcode": 4579,
    "City": "Espenhain",
    "State": "Sachsen",
    "Latitude": "051.19516092",
    "Longitude": "012.46017865",
    "Telephone Number": "+49 (0) 34206721",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "51.19516092,12.46017865",
    "IsOpen24Hours": true,
    "id": "51.19516092,12.46017865"
  },
  {
    "Gas Station Number": 2023,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Radolfzeller Strasse 35",
    "Postcode": 78333,
    "City": "Stockach",
    "State": "Baden-Württemberg",
    "Latitude": "047.84504617",
    "Longitude": "008.99927077",
    "Telephone Number": "+49 (0) 77712625",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.84504617,8.99927077",
    "IsOpen24Hours": false,
    "id": "47.84504617,8.99927077"
  },
  {
    "Gas Station Number": 2267,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Nuertinger Strasse 30",
    "Postcode": 72661,
    "City": "Grafenberg",
    "State": "Baden-Württemberg",
    "Latitude": "048.56680000",
    "Longitude": "009.30846000",
    "Telephone Number": "07123/939610",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.5668,9.30846",
    "IsOpen24Hours": true,
    "id": "48.5668,9.30846"
  },
  {
    "Gas Station Number": 1668,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Salzburger Strasse 1 b",
    "Postcode": 83278,
    "City": "Traunstein",
    "State": "Bayern",
    "Latitude": "047.86902000",
    "Longitude": "012.66856000",
    "Telephone Number": "08611/2512",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.86902,12.66856",
    "IsOpen24Hours": false,
    "id": "47.86902,12.66856"
  },
  {
    "Gas Station Number": 1603,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Regensburger Str. 65",
    "Postcode": 85055,
    "City": "Ingolstadt",
    "State": "Bayern",
    "Latitude": "048.76782000",
    "Longitude": "011.44751000",
    "Telephone Number": "+49 (0) 84158198",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.76782,11.44751",
    "IsOpen24Hours": false,
    "id": "48.76782,11.44751"
  },
  {
    "Gas Station Number": 2133,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Ludwigsburger Str. 29-33",
    "Postcode": 74343,
    "City": "Sachsenheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.95836000",
    "Longitude": "009.07123000",
    "Telephone Number": "+49 (0) 71477621",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.95836,9.07123",
    "IsOpen24Hours": false,
    "id": "48.95836,9.07123"
  },
  {
    "Gas Station Number": 4203,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Godesberger Allee 63-65",
    "Postcode": 53175,
    "City": "Bonn",
    "State": "Nordrhein-Westfalen",
    "Latitude": "050.69702000",
    "Longitude": "007.14142000",
    "Telephone Number": "+49 (0) 22837403",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "50.69702,7.14142",
    "IsOpen24Hours": true,
    "id": "50.69702,7.14142"
  },
  {
    "Gas Station Number": 3143,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Nuernberger Str. 45",
    "Postcode": 91154,
    "City": "Roth",
    "State": "Bayern",
    "Latitude": "049.24995000",
    "Longitude": "011.08998000",
    "Telephone Number": "+49 (0) 91716362",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.24995,11.08998",
    "IsOpen24Hours": false,
    "id": "49.24995,11.08998"
  },
  {
    "Gas Station Number": 4001,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Darmstaedter Strasse 136",
    "Postcode": 64625,
    "City": "Bensheim",
    "State": "Hessen",
    "Latitude": "049.68325206",
    "Longitude": "008.62170914",
    "Telephone Number": "+49 (0) 62519837",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.68325206,8.62170914",
    "IsOpen24Hours": false,
    "id": "49.68325206,8.62170914"
  },
  {
    "Gas Station Number": 2254,
    "Brand": "ENI",
    "Description": "Autobahntankstelle",
    "Address": "Heilbronner Str. 390",
    "Postcode": 70469,
    "City": "Stuttgart",
    "State": "Baden-Württemberg",
    "Latitude": "048.80442000",
    "Longitude": "009.18345000",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.80442,9.18345",
    "IsOpen24Hours": true,
    "id": "48.80442,9.18345"
  },
  {
    "Gas Station Number": 3027,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Ingolstaedter Str. 44 A",
    "Postcode": 85072,
    "City": "Eichstaett",
    "State": "Bayern",
    "Latitude": "048.88202000",
    "Longitude": "011.19653000",
    "Telephone Number": "+49 (0) 84212865",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.88202,11.19653",
    "IsOpen24Hours": true,
    "id": "48.88202,11.19653"
  },
  {
    "Gas Station Number": 2130,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Neureuter Str. 5",
    "Postcode": 76185,
    "City": "Karlsruhe",
    "State": "Baden-Württemberg",
    "Latitude": "049.01733000",
    "Longitude": "008.35405000",
    "Telephone Number": "+49 (0) 72156801",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "49.01733,8.35405",
    "IsOpen24Hours": true,
    "id": "49.01733,8.35405"
  },
  {
    "Gas Station Number": 5006,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Dammmuehlenweg",
    "Postcode": 39340,
    "City": "Haldensleben",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.27768883",
    "Longitude": "011.44230902",
    "Telephone Number": "+49 (0) 39044102",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "52.27768883,11.44230902",
    "IsOpen24Hours": true,
    "id": "52.27768883,11.44230902"
  },
  {
    "Gas Station Number": 4170,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Kaiser-Friedrich-Str. 1a",
    "Postcode": 35619,
    "City": "Braunfels/Lahn",
    "State": "Hessen",
    "Latitude": "050.51772000",
    "Longitude": "008.39122000",
    "Telephone Number": "06442/9320047",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.51772,8.39122",
    "IsOpen24Hours": false,
    "id": "50.51772,8.39122"
  },
  {
    "Gas Station Number": 3140,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Bayreuther Str. 26",
    "Postcode": 92237,
    "City": "Sulzbach-Rosenberg",
    "State": "Bayern",
    "Latitude": "049.50422000",
    "Longitude": "011.74321000",
    "Telephone Number": "+49 (0) 96615313",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.50422,11.74321",
    "IsOpen24Hours": false,
    "id": "49.50422,11.74321"
  },
  {
    "Gas Station Number": 3610,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Bayreuther Str. 22",
    "Postcode": 91301,
    "City": "Forchheim",
    "State": "Bayern",
    "Latitude": "049.71588107",
    "Longitude": "011.07725804",
    "Telephone Number": "+49 (0) 91917277",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.71588107,11.07725804",
    "IsOpen24Hours": false,
    "id": "49.71588107,11.07725804"
  },
  {
    "Gas Station Number": 3162,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Am Gewerbepark 6",
    "Postcode": 90552,
    "City": "Roethenbach",
    "State": "Bayern",
    "Latitude": "049.47912000",
    "Longitude": "011.22447000",
    "Telephone Number": "0911/54099439",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.47912,11.22447",
    "IsOpen24Hours": false,
    "id": "49.47912,11.22447"
  },
  {
    "Gas Station Number": 5207,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Oelsnitzer Str.",
    "Postcode": 8626,
    "City": "Adorf",
    "State": "Sachsen",
    "Latitude": "050.32649610",
    "Longitude": "012.25652487",
    "Telephone Number": "+49 (0) 37423229",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.3264961,12.25652487",
    "IsOpen24Hours": false,
    "id": "50.3264961,12.25652487"
  },
  {
    "Gas Station Number": 1174,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Muenchner Str. 121A",
    "Postcode": 82008,
    "City": "Unterhaching",
    "State": "Bayern",
    "Latitude": "048.07417955",
    "Longitude": "011.60450351",
    "Telephone Number": "+49 (0) 89617338",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.07417955,11.60450351",
    "IsOpen24Hours": false,
    "id": "48.07417955,11.60450351"
  },
  {
    "Gas Station Number": 3163,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Laibacher Weg 1",
    "Postcode": 95469,
    "City": "Speichersdorf",
    "State": "Bayern",
    "Latitude": "049.87047000",
    "Longitude": "011.77287000",
    "Telephone Number": "09275/6059362",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.87047,11.77287",
    "IsOpen24Hours": false,
    "id": "49.87047,11.77287"
  },
  {
    "Gas Station Number": 1170,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Rosenauer Strasse 6",
    "Postcode": 94481,
    "City": "Grafenau",
    "State": "Bayern",
    "Latitude": "048.85963818",
    "Longitude": "013.39841438",
    "Telephone Number": "+49 (0) 85523488",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.85963818,13.39841438",
    "IsOpen24Hours": false,
    "id": "48.85963818,13.39841438"
  },
  {
    "Gas Station Number": 5066,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Halberstaedter Chaussee 25",
    "Postcode": 39116,
    "City": "Magdeburg",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.08910000",
    "Longitude": "011.56870000",
    "Telephone Number": "+49 (0) 39163133",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "52.0891,11.5687",
    "IsOpen24Hours": true,
    "id": "52.0891,11.5687"
  },
  {
    "Gas Station Number": 1193,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Suedl.Muenchener Str. 24 A",
    "Postcode": 82031,
    "City": "Gruenwald",
    "State": "Bayern",
    "Latitude": "048.04465439",
    "Longitude": "011.52815993",
    "Telephone Number": "+49 (0) 89641387",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.04465439,11.52815993",
    "IsOpen24Hours": false,
    "id": "48.04465439,11.52815993"
  },
  {
    "Gas Station Number": 3083,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Bamberger Strasse 39",
    "Postcode": 96135,
    "City": "Stegaurach",
    "State": "Bayern",
    "Latitude": "049.86295938",
    "Longitude": "010.84646514",
    "Telephone Number": "+49 (0) 95129333",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.86295938,10.84646514",
    "IsOpen24Hours": false,
    "id": "49.86295938,10.84646514"
  },
  {
    "Gas Station Number": 5014,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Radeburger Str. 150",
    "Postcode": 1558,
    "City": "Grossenhain",
    "State": "Sachsen",
    "Latitude": "051.29513500",
    "Longitude": "013.54341500",
    "Telephone Number": "+49 (0) 35225023",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "51.295135,13.543415",
    "IsOpen24Hours": true,
    "id": "51.295135,13.543415"
  },
  {
    "Gas Station Number": 4661,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Leinstrae 11",
    "Postcode": 30159,
    "City": "Hannover",
    "State": "Niedersachsen",
    "Latitude": "052.36960898",
    "Longitude": "009.73556302",
    "Telephone Number": "+49 (0) 51136373",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.36960898,9.73556302",
    "IsOpen24Hours": false,
    "id": "52.36960898,9.73556302"
  },
  {
    "Gas Station Number": 3095,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Wuerzburger Strasse 37",
    "Postcode": 97199,
    "City": "Ochsenfurt",
    "State": "Bayern",
    "Latitude": "049.66867228",
    "Longitude": "010.06488621",
    "Telephone Number": "+49 (0) 93313125",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.66867228,10.06488621",
    "IsOpen24Hours": false,
    "id": "49.66867228,10.06488621"
  },
  {
    "Gas Station Number": 5078,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Leipziger Str./B 93",
    "Postcode": 4600,
    "City": "Altenburg",
    "State": "Thüringen",
    "Latitude": "051.01108590",
    "Longitude": "012.45412420",
    "Telephone Number": "+49 (0) 34478343",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "51.0110859,12.4541242",
    "IsOpen24Hours": true,
    "id": "51.0110859,12.4541242"
  },
  {
    "Gas Station Number": 5092,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Freyburger Str. 17",
    "Postcode": 6132,
    "City": "Halle",
    "State": "Sachsen-Anhalt",
    "Latitude": "051.44469000",
    "Longitude": "011.96994000",
    "Telephone Number": "+49 (0) 34577030",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "51.44469,11.96994",
    "IsOpen24Hours": true,
    "id": "51.44469,11.96994"
  },
  {
    "Gas Station Number": 3103,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Rangaustr. 4A",
    "Postcode": 90530,
    "City": "Wendelstein",
    "State": "Bayern",
    "Latitude": "049.34587000",
    "Longitude": "011.11118000",
    "Telephone Number": "+49 (0) 91298929",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.34587,11.11118",
    "IsOpen24Hours": false,
    "id": "49.34587,11.11118"
  },
  {
    "Gas Station Number": 2309,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Hauptstr.",
    "Postcode": 73553,
    "City": "Alfdorf",
    "State": "Baden-Württemberg",
    "Latitude": "048.84252000",
    "Longitude": "009.70899600",
    "Telephone Number": "+49 (0) 71729399",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.84252,9.708996",
    "IsOpen24Hours": false,
    "id": "48.84252,9.708996"
  },
  {
    "Gas Station Number": 2604,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Hedelfinger Str. 73",
    "Postcode": 70327,
    "City": "Stuttgart",
    "State": "Baden-Württemberg",
    "Latitude": "048.76608000",
    "Longitude": "009.24985000",
    "Telephone Number": "+49 (0) 71142246",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.76608,9.24985",
    "IsOpen24Hours": false,
    "id": "48.76608,9.24985"
  },
  {
    "Gas Station Number": 2269,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Leibnizstr. 24",
    "Postcode": 74211,
    "City": "Leingarten",
    "State": "Baden-Württemberg",
    "Latitude": "049.14383000",
    "Longitude": "009.12661000",
    "Telephone Number": "07131-3994325",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.14383,9.12661",
    "IsOpen24Hours": false,
    "id": "49.14383,9.12661"
  },
  {
    "Gas Station Number": 1070,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Lenggrieser Strasse 46",
    "Postcode": 83646,
    "City": "Bad Toelz",
    "State": "Bayern",
    "Latitude": "047.74650124",
    "Longitude": "011.56668200",
    "Telephone Number": "+49 (0) 80418147",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.74650124,11.566682",
    "IsOpen24Hours": false,
    "id": "47.74650124,11.566682"
  },
  {
    "Gas Station Number": 7013,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Westhafenstrasse 1",
    "Postcode": 13353,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.53828412",
    "Longitude": "013.34408673",
    "Telephone Number": "+49 (0) 30395361",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.53828412,13.34408673",
    "IsOpen24Hours": false,
    "id": "52.53828412,13.34408673"
  },
  {
    "Gas Station Number": 2039,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Grossingersheimer Strasse",
    "Postcode": 74321,
    "City": "Bietigheim-Bissingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.95915307",
    "Longitude": "009.13343474",
    "Telephone Number": "+49 (0) 71425343",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.95915307,9.13343474",
    "IsOpen24Hours": false,
    "id": "48.95915307,9.13343474"
  },
  {
    "Gas Station Number": 3057,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Bachstr. 1",
    "Postcode": 93455,
    "City": "Traitsching",
    "State": "Bayern",
    "Latitude": "049.15276000",
    "Longitude": "012.64594000",
    "Telephone Number": "+49 (0) 9974395",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.15276,12.64594",
    "IsOpen24Hours": false,
    "id": "49.15276,12.64594"
  },
  {
    "Gas Station Number": 1093,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Wolfratshauser Str. 232",
    "Postcode": 81479,
    "City": "Muenchen",
    "State": "Bayern",
    "Latitude": "048.07575912",
    "Longitude": "011.52380028",
    "Telephone Number": "+49 (0) 89791228",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.07575912,11.52380028",
    "IsOpen24Hours": true,
    "id": "48.07575912,11.52380028"
  },
  {
    "Gas Station Number": 3621,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Hauptstr. 40",
    "Postcode": 96275,
    "City": "Marktzeuln",
    "State": "Bayern",
    "Latitude": "050.16035000",
    "Longitude": "011.18467000",
    "Telephone Number": "09574/505",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.16035,11.18467",
    "IsOpen24Hours": false,
    "id": "50.16035,11.18467"
  },
  {
    "Gas Station Number": 3017,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Loewenberger Strasse 135",
    "Postcode": 90475,
    "City": "Nuernberg",
    "State": "Bayern",
    "Latitude": "049.41112000",
    "Longitude": "011.17117000",
    "Telephone Number": "+49 (0) 91183475",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.41112,11.17117",
    "IsOpen24Hours": false,
    "id": "49.41112,11.17117"
  },
  {
    "Gas Station Number": 4146,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Pacelli-Allee 1 A",
    "Postcode": 36043,
    "City": "Fulda",
    "State": "Hessen",
    "Latitude": "050.54708000",
    "Longitude": "009.70289000",
    "Telephone Number": "+49 (0) 66163985",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.54708,9.70289",
    "IsOpen24Hours": false,
    "id": "50.54708,9.70289"
  },
  {
    "Gas Station Number": 1016,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Dillinger Strasse 34",
    "Postcode": 86660,
    "City": "Tapfheim",
    "State": "Bayern",
    "Latitude": "048.67982245",
    "Longitude": "010.69982659",
    "Telephone Number": "+49 (0) 90709217",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.67982245,10.69982659",
    "IsOpen24Hours": false,
    "id": "48.67982245,10.69982659"
  },
  {
    "Gas Station Number": 2055,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Waiblinger Strasse 67",
    "Postcode": 71364,
    "City": "Winnenden",
    "State": "Baden-Württemberg",
    "Latitude": "048.87320000",
    "Longitude": "009.38895000",
    "Telephone Number": "+49 (0) 71951740",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8732,9.38895",
    "IsOpen24Hours": false,
    "id": "48.8732,9.38895"
  },
  {
    "Gas Station Number": 2605,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Nuernberger Strasse 18",
    "Postcode": 70374,
    "City": "Stuttgart",
    "State": "Baden-Württemberg",
    "Latitude": "048.80984000",
    "Longitude": "009.25133000",
    "Telephone Number": "+49 (0) 71152745",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.80984,9.25133",
    "IsOpen24Hours": false,
    "id": "48.80984,9.25133"
  },
  {
    "Gas Station Number": 1293,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Pfaenderstr. 15 a",
    "Postcode": 88161,
    "City": "Lindenberg",
    "State": "Bayern",
    "Latitude": "047.59771000",
    "Longitude": "009.87793500",
    "Telephone Number": "+49 (0) 83819298",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.59771,9.877935",
    "IsOpen24Hours": false,
    "id": "47.59771,9.877935"
  },
  {
    "Gas Station Number": 2110,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Gustav-Schwab-Strasse 41",
    "Postcode": 72762,
    "City": "Reutlingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.48938000",
    "Longitude": "009.19247000",
    "Telephone Number": "+49 (0) 71212392",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.48938,9.19247",
    "IsOpen24Hours": false,
    "id": "48.48938,9.19247"
  },
  {
    "Gas Station Number": 1669,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Reichenhaller Strasse 20",
    "Postcode": 83483,
    "City": "Bischofswiesen",
    "State": "Bayern",
    "Latitude": "047.66930000",
    "Longitude": "012.94402000",
    "Telephone Number": "08652/985950",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.6693,12.94402",
    "IsOpen24Hours": false,
    "id": "47.6693,12.94402"
  },
  {
    "Gas Station Number": 1222,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Impler Str. 21",
    "Postcode": 81371,
    "City": "Muenchen",
    "State": "Bayern",
    "Latitude": "048.12237785",
    "Longitude": "011.54864215",
    "Telephone Number": "+49 (0) 89725771",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.12237785,11.54864215",
    "IsOpen24Hours": true,
    "id": "48.12237785,11.54864215"
  },
  {
    "Gas Station Number": 1268,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Rosenheimer Str. 44",
    "Postcode": 83101,
    "City": "Rohrdorf",
    "State": "Bayern",
    "Latitude": "047.82453501",
    "Longitude": "012.15353384",
    "Telephone Number": "+49 (0) 80317113",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.82453501,12.15353384",
    "IsOpen24Hours": false,
    "id": "47.82453501,12.15353384"
  },
  {
    "Gas Station Number": 4703,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Homberger Str. 24",
    "Postcode": 35325,
    "City": "Muecke/Bernsfeld",
    "State": "Hessen",
    "Latitude": "050.66766000",
    "Longitude": "008.99412000",
    "Telephone Number": "+49 (0) 66348856",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.66766,8.99412",
    "IsOpen24Hours": false,
    "id": "50.66766,8.99412"
  },
  {
    "Gas Station Number": 5220,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Aeuss.Zwickauer Str. 16-20",
    "Postcode": 9350,
    "City": "Lichtenstein",
    "State": "Sachsen",
    "Latitude": "050.75273644",
    "Longitude": "012.62110193",
    "Telephone Number": "+49 (0) 37204213",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.75273644,12.62110193",
    "IsOpen24Hours": false,
    "id": "50.75273644,12.62110193"
  },
  {
    "Gas Station Number": 1092,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Hubertusstrasse 9",
    "Postcode": 83022,
    "City": "Rosenheim",
    "State": "Bayern",
    "Latitude": "047.85597168",
    "Longitude": "012.11058043",
    "Telephone Number": "+49 (0) 80313710",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.85597168,12.11058043",
    "IsOpen24Hours": false,
    "id": "47.85597168,12.11058043"
  },
  {
    "Gas Station Number": 4041,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Schnellstrasse Ost",
    "Postcode": 55130,
    "City": "Mainz",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.00437200",
    "Longitude": "008.26258800",
    "Telephone Number": "+49 (0) 61316982",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.004372,8.262588",
    "IsOpen24Hours": false,
    "id": "50.004372,8.262588"
  },
  {
    "Gas Station Number": 1290,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Josephspitalstr. 12",
    "Postcode": 80331,
    "City": "Muenchen",
    "State": "Bayern",
    "Latitude": "048.13650000",
    "Longitude": "011.56593000",
    "Telephone Number": "+49 (0) 89558692",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.1365,11.56593",
    "IsOpen24Hours": false,
    "id": "48.1365,11.56593"
  },
  {
    "Gas Station Number": 1211,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Dreisesselstr. 39",
    "Postcode": 94089,
    "City": "Neureichenau",
    "State": "Bayern",
    "Latitude": "048.74444361",
    "Longitude": "013.74979969",
    "Telephone Number": "+49 (0) 85839607",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.74444361,13.74979969",
    "IsOpen24Hours": false,
    "id": "48.74444361,13.74979969"
  },
  {
    "Gas Station Number": 2072,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Abtsgmuender Strasse 20",
    "Postcode": 73433,
    "City": "Aalen",
    "State": "Baden-Württemberg",
    "Latitude": "048.86931000",
    "Longitude": "010.10666000",
    "Telephone Number": "+49 (0) 73617409",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.86931,10.10666",
    "IsOpen24Hours": false,
    "id": "48.86931,10.10666"
  },
  {
    "Gas Station Number": 1658,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Gerolsbacher Str. 9",
    "Postcode": 86529,
    "City": "Schrobenhausen",
    "State": "Bayern",
    "Latitude": "048.55546000",
    "Longitude": "011.26806000",
    "Telephone Number": "+49 (0) 82528877",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.55546,11.26806",
    "IsOpen24Hours": false,
    "id": "48.55546,11.26806"
  },
  {
    "Gas Station Number": 1161,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Detter Strasse 1A",
    "Postcode": 94469,
    "City": "Deggendorf",
    "State": "Bayern",
    "Latitude": "048.84126000",
    "Longitude": "012.95684000",
    "Telephone Number": "+49 (0) 99138308",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.84126,12.95684",
    "IsOpen24Hours": false,
    "id": "48.84126,12.95684"
  },
  {
    "Gas Station Number": 2113,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Heidelberger Strasse 49",
    "Postcode": 74821,
    "City": "Mosbach",
    "State": "Baden-Württemberg",
    "Latitude": "049.34331178",
    "Longitude": "009.10529611",
    "Telephone Number": "+49 (0) 62617767",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.34331178,9.10529611",
    "IsOpen24Hours": false,
    "id": "49.34331178,9.10529611"
  },
  {
    "Gas Station Number": 3366,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Donaustaufer Str. 316",
    "Postcode": 93055,
    "City": "Regensburg",
    "State": "Bayern",
    "Latitude": "049.02503000",
    "Longitude": "012.14775000",
    "Telephone Number": "+49 (0) 94145318",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.02503,12.14775",
    "IsOpen24Hours": false,
    "id": "49.02503,12.14775"
  },
  {
    "Gas Station Number": 2126,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Stettener Str. 51",
    "Postcode": 88709,
    "City": "Meersburg",
    "State": "Baden-Württemberg",
    "Latitude": "047.69355407",
    "Longitude": "009.27939105",
    "Telephone Number": "+49 (0) 75329618",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.69355407,9.27939105",
    "IsOpen24Hours": false,
    "id": "47.69355407,9.27939105"
  },
  {
    "Gas Station Number": 1009,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Schlierseer Strasse 9",
    "Postcode": 83734,
    "City": "Hausham",
    "State": "Bayern",
    "Latitude": "047.74636000",
    "Longitude": "011.84433000",
    "Telephone Number": "+49 (0) 80269327",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.74636,11.84433",
    "IsOpen24Hours": false,
    "id": "47.74636,11.84433"
  },
  {
    "Gas Station Number": 3042,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Oeslauer Strasse 13",
    "Postcode": 96472,
    "City": "Roedental",
    "State": "Bayern",
    "Latitude": "050.29485000",
    "Longitude": "011.04786000",
    "Telephone Number": "+49 (0) 95638252",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.29485,11.04786",
    "IsOpen24Hours": false,
    "id": "50.29485,11.04786"
  },
  {
    "Gas Station Number": 1258,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Allguer Str. 7",
    "Postcode": 87642,
    "City": "Halblech",
    "State": "Bayern",
    "Latitude": "047.63806000",
    "Longitude": "010.82567000",
    "Telephone Number": "+49 (0) 83689404",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.63806,10.82567",
    "IsOpen24Hours": false,
    "id": "47.63806,10.82567"
  },
  {
    "Gas Station Number": 1620,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Grosse Allee 17",
    "Postcode": 89407,
    "City": "Dillingen",
    "State": "Bayern",
    "Latitude": "048.58051000",
    "Longitude": "010.49173000",
    "Telephone Number": "+49 (0) 90718062",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.58051,10.49173",
    "IsOpen24Hours": true,
    "id": "48.58051,10.49173"
  },
  {
    "Gas Station Number": 1151,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Verdistrasse 141",
    "Postcode": 81247,
    "City": "Muenchen",
    "State": "Bayern",
    "Latitude": "048.16447242",
    "Longitude": "011.45922867",
    "Telephone Number": "+49 (0) 89811133",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.16447242,11.45922867",
    "IsOpen24Hours": true,
    "id": "48.16447242,11.45922867"
  },
  {
    "Gas Station Number": 1188,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Herterichstrasse 111",
    "Postcode": 81477,
    "City": "Muenchen",
    "State": "Bayern",
    "Latitude": "048.07513000",
    "Longitude": "011.51092000",
    "Telephone Number": "+49 (0) 89795346",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.07513,11.51092",
    "IsOpen24Hours": false,
    "id": "48.07513,11.51092"
  },
  {
    "Gas Station Number": 2127,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Welzheimer Strasse 22",
    "Postcode": 73614,
    "City": "Schorndorf",
    "State": "Baden-Württemberg",
    "Latitude": "048.81425000",
    "Longitude": "009.52972000",
    "Telephone Number": "+49 (0) 71819797",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.81425,9.52972",
    "IsOpen24Hours": false,
    "id": "48.81425,9.52972"
  },
  {
    "Gas Station Number": 7017,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Ostpreussendamm 89",
    "Postcode": 12207,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.41503211",
    "Longitude": "013.30259409",
    "Telephone Number": "+49 (0) 30755197",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "52.41503211,13.30259409",
    "IsOpen24Hours": true,
    "id": "52.41503211,13.30259409"
  },
  {
    "Gas Station Number": 2174,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Kreisstrasse",
    "Postcode": 66578,
    "City": "Schiffweiler",
    "State": "Saarland",
    "Latitude": "049.35391000",
    "Longitude": "007.13962000",
    "Telephone Number": "+49 (0) 68219637",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "49.35391,7.13962",
    "IsOpen24Hours": true,
    "id": "49.35391,7.13962"
  },
  {
    "Gas Station Number": 4705,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Herborner Str. 34",
    "Postcode": 35096,
    "City": "Weimar/Nieder-Weimar",
    "State": "Hessen",
    "Latitude": "050.76000475",
    "Longitude": "008.73340648",
    "Telephone Number": "+49 (0) 64217615",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.76000475,8.73340648",
    "IsOpen24Hours": false,
    "id": "50.76000475,8.73340648"
  },
  {
    "Gas Station Number": 4092,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Mannheimer Str. 2",
    "Postcode": 67134,
    "City": "Birkenheide",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.47983000",
    "Longitude": "008.26447000",
    "Telephone Number": "+49 (0) 62373301",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.47983,8.26447",
    "IsOpen24Hours": false,
    "id": "49.47983,8.26447"
  },
  {
    "Gas Station Number": 5064,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Magdeburger Chaussee 83",
    "Postcode": 39288,
    "City": "Burg",
    "State": "Sachsen-Anhalt",
    "Latitude": "052.26464000",
    "Longitude": "011.84645000",
    "Telephone Number": "+49 (0) 39219448",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.26464,11.84645",
    "IsOpen24Hours": false,
    "id": "52.26464,11.84645"
  },
  {
    "Gas Station Number": 1296,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Bezirksstrae 29",
    "Postcode": 85716,
    "City": "Unterschleiheim",
    "State": "Bayern",
    "Latitude": "048.28175955",
    "Longitude": "011.57578900",
    "Telephone Number": "+49 (0) 89310511",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.28175955,11.575789",
    "IsOpen24Hours": false,
    "id": "48.28175955,11.575789"
  },
  {
    "Gas Station Number": 3098,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Wallensteinstrasse 90",
    "Postcode": 90431,
    "City": "Nuernberg",
    "State": "Bayern",
    "Latitude": "049.43750000",
    "Longitude": "011.03140000",
    "Telephone Number": "+49 (0) 91161422",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.4375,11.0314",
    "IsOpen24Hours": false,
    "id": "49.4375,11.0314"
  },
  {
    "Gas Station Number": 3113,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Radmeisterstrasse 1",
    "Postcode": 90455,
    "City": "Nuernberg",
    "State": "Bayern",
    "Latitude": "049.37093000",
    "Longitude": "011.07774000",
    "Telephone Number": "+49 (0) 91188318",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.37093,11.07774",
    "IsOpen24Hours": false,
    "id": "49.37093,11.07774"
  },
  {
    "Gas Station Number": 5109,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Freiherr-vom-Stein-Str. 30",
    "Postcode": 99734,
    "City": "Nordhausen",
    "State": "Thüringen",
    "Latitude": "051.49546000",
    "Longitude": "010.77823000",
    "Telephone Number": "+49 (0) 36319029",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "51.49546,10.77823",
    "IsOpen24Hours": true,
    "id": "51.49546,10.77823"
  },
  {
    "Gas Station Number": 1079,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Innsbrucker Ring 149",
    "Postcode": 81669,
    "City": "Muenchen",
    "State": "Bayern",
    "Latitude": "048.11435095",
    "Longitude": "011.61760989",
    "Telephone Number": "+49 (0) 89689199",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.11435095,11.61760989",
    "IsOpen24Hours": true,
    "id": "48.11435095,11.61760989"
  },
  {
    "Gas Station Number": 1216,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Manchinger Str. 115",
    "Postcode": 85053,
    "City": "Ingolstadt",
    "State": "Bayern",
    "Latitude": "048.74492000",
    "Longitude": "011.47045000",
    "Telephone Number": "+49 (0) 84162883",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.74492,11.47045",
    "IsOpen24Hours": false,
    "id": "48.74492,11.47045"
  },
  {
    "Gas Station Number": 1611,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Maroldstrasse 64",
    "Postcode": 85229,
    "City": "Markt Indersdorf",
    "State": "Bayern",
    "Latitude": "048.35492000",
    "Longitude": "011.38783000",
    "Telephone Number": "+49 (0) 81366479",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.35492,11.38783",
    "IsOpen24Hours": false,
    "id": "48.35492,11.38783"
  },
  {
    "Gas Station Number": 1227,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Flughafen Nordallee 45",
    "Postcode": 85356,
    "City": "Muenchen",
    "State": "Bayern",
    "Latitude": "048.35395434",
    "Longitude": "011.74937560",
    "Telephone Number": "+49 (0) 89970518",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.35395434,11.7493756",
    "IsOpen24Hours": true,
    "id": "48.35395434,11.7493756"
  },
  {
    "Gas Station Number": 1663,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Lise-Meitner-Strasse 2",
    "Postcode": 82110,
    "City": "Germering",
    "State": "Bayern",
    "Latitude": "048.14067000",
    "Longitude": "011.36856000",
    "Telephone Number": "089/89408492",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.14067,11.36856",
    "IsOpen24Hours": false,
    "id": "48.14067,11.36856"
  },
  {
    "Gas Station Number": 2281,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Augsburger Strasse 231",
    "Postcode": 70327,
    "City": "Stuttgart",
    "State": "Baden-Württemberg",
    "Latitude": "048.79124000",
    "Longitude": "009.24389000",
    "Telephone Number": "0711/32770830",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.79124,9.24389",
    "IsOpen24Hours": false,
    "id": "48.79124,9.24389"
  },
  {
    "Gas Station Number": 4105,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Frankfurter Strasse 110",
    "Postcode": 63150,
    "City": "Heusenstamm",
    "State": "Hessen",
    "Latitude": "050.05859000",
    "Longitude": "008.79407000",
    "Telephone Number": "+49 (0) 61046182",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.05859,8.79407",
    "IsOpen24Hours": false,
    "id": "50.05859,8.79407"
  },
  {
    "Gas Station Number": 2043,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Stuttgarter Strasse 144",
    "Postcode": 71522,
    "City": "Backnang",
    "State": "Baden-Württemberg",
    "Latitude": "048.93528000",
    "Longitude": "009.43749000",
    "Telephone Number": "+49 (0) 71916267",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.93528,9.43749",
    "IsOpen24Hours": false,
    "id": "48.93528,9.43749"
  },
  {
    "Gas Station Number": 2149,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Magdeburger Strasse",
    "Postcode": 68309,
    "City": "Mannheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.51046000",
    "Longitude": "008.55189000",
    "Telephone Number": "+49 (0) 62170620",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.51046,8.55189",
    "IsOpen24Hours": false,
    "id": "49.51046,8.55189"
  },
  {
    "Gas Station Number": 1031,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Haager Strasse 41",
    "Postcode": 84405,
    "City": "Dorfen",
    "State": "Bayern",
    "Latitude": "048.26914338",
    "Longitude": "012.15474119",
    "Telephone Number": "+49 (0) 8081589",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.26914338,12.15474119",
    "IsOpen24Hours": false,
    "id": "48.26914338,12.15474119"
  },
  {
    "Gas Station Number": 2161,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Scheidter Strasse 260-262",
    "Postcode": 66125,
    "City": "Saarbruecken(Dudw.)",
    "State": "Saarland",
    "Latitude": "049.26802000",
    "Longitude": "007.04973000",
    "Telephone Number": "+49 (0) 68977336",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.26802,7.04973",
    "IsOpen24Hours": false,
    "id": "49.26802,7.04973"
  },
  {
    "Gas Station Number": 1090,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Muenchner Strasse 28",
    "Postcode": 86641,
    "City": "Rain am Lech",
    "State": "Bayern",
    "Latitude": "048.68683000",
    "Longitude": "010.91974000",
    "Telephone Number": "09090 / 9594877",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.68683,10.91974",
    "IsOpen24Hours": false,
    "id": "48.68683,10.91974"
  },
  {
    "Gas Station Number": 1277,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Meitinger Str. 16-18",
    "Postcode": 86672,
    "City": "Thierhaupten",
    "State": "Bayern",
    "Latitude": "048.56677000",
    "Longitude": "010.89826000",
    "Telephone Number": "+49 (0) 82712426",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.56677,10.89826",
    "IsOpen24Hours": false,
    "id": "48.56677,10.89826"
  },
  {
    "Gas Station Number": 1111,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Heerstr. 106",
    "Postcode": 94315,
    "City": "Straubing",
    "State": "Bayern",
    "Latitude": "048.88282000",
    "Longitude": "012.58570000",
    "Telephone Number": "+49 (0) 94212114",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.88282,12.5857",
    "IsOpen24Hours": false,
    "id": "48.88282,12.5857"
  },
  {
    "Gas Station Number": 4701,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Koelschhaeuser Strasse 25",
    "Postcode": 35630,
    "City": "Ehringshausen",
    "State": "Hessen",
    "Latitude": "050.60745713",
    "Longitude": "008.38310627",
    "Telephone Number": "+49 (0) 64438112",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.60745713,8.38310627",
    "IsOpen24Hours": false,
    "id": "50.60745713,8.38310627"
  },
  {
    "Gas Station Number": 4091,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Engersgaustrasse 67",
    "Postcode": 56566,
    "City": "Neuwied",
    "State": "Rheinland-Pfalz",
    "Latitude": "050.44210000",
    "Longitude": "007.54527000",
    "Telephone Number": "+49 (0) 26228322",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.4421,7.54527",
    "IsOpen24Hours": false,
    "id": "50.4421,7.54527"
  },
  {
    "Gas Station Number": 1215,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Mueldorferstr. 67",
    "Postcode": 84503,
    "City": "Altoetting",
    "State": "Bayern",
    "Latitude": "048.22635000",
    "Longitude": "012.65880000",
    "Telephone Number": "+49 (0) 86719698",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.22635,12.6588",
    "IsOpen24Hours": false,
    "id": "48.22635,12.6588"
  },
  {
    "Gas Station Number": 2614,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Sulzbacher Str. 192/1",
    "Postcode": 71522,
    "City": "Backnang",
    "State": "Baden-Württemberg",
    "Latitude": "048.96056000",
    "Longitude": "009.43078000",
    "Telephone Number": "+49 (0) 71919543",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.96056,9.43078",
    "IsOpen24Hours": false,
    "id": "48.96056,9.43078"
  },
  {
    "Gas Station Number": 3068,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Bamberger Strasse 30",
    "Postcode": 95445,
    "City": "Bayreuth",
    "State": "Bayern",
    "Latitude": "049.93694000",
    "Longitude": "011.55421000",
    "Telephone Number": "+49 (0) 92162678",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.93694,11.55421",
    "IsOpen24Hours": false,
    "id": "49.93694,11.55421"
  },
  {
    "Gas Station Number": 3611,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Nuernberger Str. 109",
    "Postcode": 91710,
    "City": "Gunzenhausen",
    "State": "Bayern",
    "Latitude": "049.12719767",
    "Longitude": "010.76824883",
    "Telephone Number": "+49 (0) 98312504",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "49.12719767,10.76824883",
    "IsOpen24Hours": true,
    "id": "49.12719767,10.76824883"
  },
  {
    "Gas Station Number": 2120,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "In Laisen 14",
    "Postcode": 72766,
    "City": "Reutlingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.50396000",
    "Longitude": "009.22034000",
    "Telephone Number": "+49 (0) 71214902",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.50396,9.22034",
    "IsOpen24Hours": true,
    "id": "48.50396,9.22034"
  },
  {
    "Gas Station Number": 1058,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Boschetsrieder Str. 162",
    "Postcode": 81379,
    "City": "Muenchen",
    "State": "Bayern",
    "Latitude": "048.11107000",
    "Longitude": "011.53658000",
    "Telephone Number": "+49 (0) 89748794",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.11107,11.53658",
    "IsOpen24Hours": false,
    "id": "48.11107,11.53658"
  },
  {
    "Gas Station Number": 1612,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Schongauer Str. 22",
    "Postcode": 82380,
    "City": "Peissenberg",
    "State": "Bayern",
    "Latitude": "047.79315314",
    "Longitude": "011.06287882",
    "Telephone Number": "+49 (0) 88032091",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.79315314,11.06287882",
    "IsOpen24Hours": false,
    "id": "47.79315314,11.06287882"
  },
  {
    "Gas Station Number": 3607,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Laufamholz Strasse 294",
    "Postcode": 90482,
    "City": "Nuernberg",
    "State": "Bayern",
    "Latitude": "049.46895000",
    "Longitude": "011.16705000",
    "Telephone Number": "+49 (0) 91150128",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.46895,11.16705",
    "IsOpen24Hours": false,
    "id": "49.46895,11.16705"
  },
  {
    "Gas Station Number": 5005,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Aeussere Bautzener Str. 38",
    "Postcode": 2708,
    "City": "Loebau",
    "State": "Sachsen",
    "Latitude": "051.10569485",
    "Longitude": "014.65293466",
    "Telephone Number": "+49 (0) 35858606",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "51.10569485,14.65293466",
    "IsOpen24Hours": true,
    "id": "51.10569485,14.65293466"
  },
  {
    "Gas Station Number": 1651,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Gewerbegeb. Langweid a.d. B301",
    "Postcode": 84094,
    "City": "Elsendorf",
    "State": "Bayern",
    "Latitude": "048.71580033",
    "Longitude": "011.80222790",
    "Telephone Number": "+49 (0) 87539674",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.71580033,11.8022279",
    "IsOpen24Hours": true,
    "id": "48.71580033,11.8022279"
  },
  {
    "Gas Station Number": 1182,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Haidenbucher Strasse 2",
    "Postcode": 86916,
    "City": "Kaufering",
    "State": "Bayern",
    "Latitude": "048.08772898",
    "Longitude": "010.85619475",
    "Telephone Number": "+49 (0) 81919662",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.08772898,10.85619475",
    "IsOpen24Hours": false,
    "id": "48.08772898,10.85619475"
  },
  {
    "Gas Station Number": 1675,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Reitham 51",
    "Postcode": 83627,
    "City": "Warngau",
    "State": "Bayern",
    "Latitude": "047.81595000",
    "Longitude": "011.71347000",
    "Telephone Number": "+49 8021-324",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.81595,11.71347",
    "IsOpen24Hours": false,
    "id": "47.81595,11.71347"
  },
  {
    "Gas Station Number": 3091,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Vacher Strasse 417",
    "Postcode": 90768,
    "City": "Fuerth",
    "State": "Bayern",
    "Latitude": "049.52106000",
    "Longitude": "010.96406000",
    "Telephone Number": "+49 (0) 91176138",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.52106,10.96406",
    "IsOpen24Hours": false,
    "id": "49.52106,10.96406"
  },
  {
    "Gas Station Number": 3079,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Am Moos 24",
    "Postcode": 96465,
    "City": "Neustadt",
    "State": "Bayern",
    "Latitude": "050.33223370",
    "Longitude": "011.11406417",
    "Telephone Number": "+49 (0) 95686114",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.3322337,11.11406417",
    "IsOpen24Hours": false,
    "id": "50.3322337,11.11406417"
  },
  {
    "Gas Station Number": 1279,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstrasse 47",
    "Postcode": 84494,
    "City": "Neumarkt/St.Veit",
    "State": "Bayern",
    "Latitude": "048.35931661",
    "Longitude": "012.50180251",
    "Telephone Number": "+49 (0) 86397098",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.35931661,12.50180251",
    "IsOpen24Hours": false,
    "id": "48.35931661,12.50180251"
  },
  {
    "Gas Station Number": 1256,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Ettaler Str. 3",
    "Postcode": 82496,
    "City": "Oberau",
    "State": "Bayern",
    "Latitude": "047.55964000",
    "Longitude": "011.13368000",
    "Telephone Number": "+49 (0) 88249305",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.55964,11.13368",
    "IsOpen24Hours": false,
    "id": "47.55964,11.13368"
  },
  {
    "Gas Station Number": 5119,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Zwickauer Strasse 16",
    "Postcode": 8485,
    "City": "Lengenfeld",
    "State": "Sachsen",
    "Latitude": "050.57422777",
    "Longitude": "012.37429416",
    "Telephone Number": "+49 (0) 37606378",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "50.57422777,12.37429416",
    "IsOpen24Hours": true,
    "id": "50.57422777,12.37429416"
  },
  {
    "Gas Station Number": 5011,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Loebauer Str. 151",
    "Postcode": 2625,
    "City": "Bautzen",
    "State": "Sachsen",
    "Latitude": "051.17910500",
    "Longitude": "014.45051000",
    "Telephone Number": "+49 (0) 35912419",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "51.179105,14.45051",
    "IsOpen24Hours": true,
    "id": "51.179105,14.45051"
  },
  {
    "Gas Station Number": 1659,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Hauptstr. 23",
    "Postcode": 83329,
    "City": "Waging",
    "State": "Bayern",
    "Latitude": "047.95683000",
    "Longitude": "012.75798000",
    "Telephone Number": "08681/4631",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.95683,12.75798",
    "IsOpen24Hours": false,
    "id": "47.95683,12.75798"
  },
  {
    "Gas Station Number": 2099,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Stettfelder Str. 56",
    "Postcode": 76698,
    "City": "Ubstadt-Weiher",
    "State": "Baden-Württemberg",
    "Latitude": "049.16381104",
    "Longitude": "008.63457943",
    "Telephone Number": "+49 (0) 72519616",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.16381104,8.63457943",
    "IsOpen24Hours": false,
    "id": "49.16381104,8.63457943"
  },
  {
    "Gas Station Number": 5151,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Am Huenstein 65",
    "Postcode": 99735,
    "City": "Nohra",
    "State": "Thüringen",
    "Latitude": "051.42875000",
    "Longitude": "010.71415000",
    "Telephone Number": "+49 (0) 36334538",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "51.42875,10.71415",
    "IsOpen24Hours": true,
    "id": "51.42875,10.71415"
  },
  {
    "Gas Station Number": 4163,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Weinstrasse Nord 57",
    "Postcode": 67098,
    "City": "Bad Duerkheim",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.46098000",
    "Longitude": "008.16238000",
    "Telephone Number": "+49 (0) 63226601",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.46098,8.16238",
    "IsOpen24Hours": false,
    "id": "49.46098,8.16238"
  },
  {
    "Gas Station Number": 1617,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstrasse 37",
    "Postcode": 83253,
    "City": "Rimsting",
    "State": "Bayern",
    "Latitude": "047.89013217",
    "Longitude": "012.34439795",
    "Telephone Number": "+49 (0) 80516109",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.89013217,12.34439795",
    "IsOpen24Hours": false,
    "id": "47.89013217,12.34439795"
  },
  {
    "Gas Station Number": 5047,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Leipziger Str. 51",
    "Postcode": 4828,
    "City": "Bennewitz",
    "State": "Sachsen",
    "Latitude": "051.36241500",
    "Longitude": "012.69706000",
    "Telephone Number": "+49 (0) 34258109",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "51.362415,12.69706",
    "IsOpen24Hours": true,
    "id": "51.362415,12.69706"
  },
  {
    "Gas Station Number": 4138,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Friedensstrasse 108",
    "Postcode": 63165,
    "City": "Muehlheim",
    "State": "Hessen",
    "Latitude": "050.11990000",
    "Longitude": "008.82474000",
    "Telephone Number": "+49 (0) 61087280",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "50.1199,8.82474",
    "IsOpen24Hours": true,
    "id": "50.1199,8.82474"
  },
  {
    "Gas Station Number": 5089,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Mahlower Str. 241",
    "Postcode": 14513,
    "City": "Teltow",
    "State": "Brandenburg",
    "Latitude": "052.38695825",
    "Longitude": "013.30493905",
    "Telephone Number": "+49 (0) 33284718",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "52.38695825,13.30493905",
    "IsOpen24Hours": true,
    "id": "52.38695825,13.30493905"
  },
  {
    "Gas Station Number": 4738,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Badelacher Weg 10",
    "Postcode": 36404,
    "City": "Vacha",
    "State": "Thüringen",
    "Latitude": "050.82847000",
    "Longitude": "010.03043000",
    "Telephone Number": "+49 (0) 36962246",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.82847,10.03043",
    "IsOpen24Hours": false,
    "id": "50.82847,10.03043"
  },
  {
    "Gas Station Number": 2238,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Karlsruher Str. 85",
    "Postcode": 75179,
    "City": "Pforzheim",
    "State": "Baden-Württemberg",
    "Latitude": "048.90988000",
    "Longitude": "008.64727000",
    "Telephone Number": "+49 (0) 72313282",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.90988,8.64727",
    "IsOpen24Hours": true,
    "id": "48.90988,8.64727"
  },
  {
    "Gas Station Number": 2032,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Richard-Wagner-Ring 26",
    "Postcode": 76437,
    "City": "Rastatt",
    "State": "Baden-Württemberg",
    "Latitude": "048.86691000",
    "Longitude": "008.20811000",
    "Telephone Number": "+49 (0) 72222281",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.86691,8.20811",
    "IsOpen24Hours": false,
    "id": "48.86691,8.20811"
  },
  {
    "Gas Station Number": 2251,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Am Hockheimring, Fahrerlager 3",
    "Postcode": 68766,
    "City": "Hockenheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.32117000",
    "Longitude": "008.56822000",
    "Telephone Number": "06205/950173",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.32117,8.56822",
    "IsOpen24Hours": false,
    "id": "49.32117,8.56822"
  },
  {
    "Gas Station Number": 4740,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Sandershaeuser Str. 43",
    "Postcode": 34123,
    "City": "Kassel",
    "State": "Hessen",
    "Latitude": "051.30889000",
    "Longitude": "009.51857000",
    "Telephone Number": "+49 (0) 56157995",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "51.30889,9.51857",
    "IsOpen24Hours": true,
    "id": "51.30889,9.51857"
  },
  {
    "Gas Station Number": 2240,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Goethestr. 2",
    "Postcode": 88046,
    "City": "Friedrichshafen",
    "State": "Baden-Württemberg",
    "Latitude": "047.66001000",
    "Longitude": "009.48732000",
    "Telephone Number": "+49 (0) 75417313",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.66001,9.48732",
    "IsOpen24Hours": false,
    "id": "47.66001,9.48732"
  },
  {
    "Gas Station Number": 1607,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Seehauser Str. 9",
    "Postcode": 83324,
    "City": "Ruhpolding",
    "State": "Bayern",
    "Latitude": "047.75992000",
    "Longitude": "012.65020000",
    "Telephone Number": "+49 (0) 86631336",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.75992,12.6502",
    "IsOpen24Hours": false,
    "id": "47.75992,12.6502"
  },
  {
    "Gas Station Number": 1540,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstrae 15",
    "Postcode": 84079,
    "City": "Bruckberg",
    "State": "Bayern",
    "Latitude": "048.52009841",
    "Longitude": "011.99503696",
    "Telephone Number": "+49 (0) 87659204",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.52009841,11.99503696",
    "IsOpen24Hours": false,
    "id": "48.52009841,11.99503696"
  },
  {
    "Gas Station Number": 5090,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Dresdner Str. 84",
    "Postcode": 9130,
    "City": "Chemnitz",
    "State": "Sachsen",
    "Latitude": "050.84159176",
    "Longitude": "012.93563266",
    "Telephone Number": "+49 (0) 37140450",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "50.84159176,12.93563266",
    "IsOpen24Hours": true,
    "id": "50.84159176,12.93563266"
  },
  {
    "Gas Station Number": 2135,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Blaufelder Strasse",
    "Postcode": 74564,
    "City": "Crailsheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.14064308",
    "Longitude": "010.07338490",
    "Telephone Number": "+49 (0) 79515922",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.14064308,10.0733849",
    "IsOpen24Hours": false,
    "id": "49.14064308,10.0733849"
  },
  {
    "Gas Station Number": 5106,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Jenaer Str. 71",
    "Postcode": 7607,
    "City": "Eisenberg",
    "State": "Thüringen",
    "Latitude": "050.96984449",
    "Longitude": "011.85835613",
    "Telephone Number": "+49 (0) 36691561",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "50.96984449,11.85835613",
    "IsOpen24Hours": true,
    "id": "50.96984449,11.85835613"
  },
  {
    "Gas Station Number": 2617,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "H.-M.-Schleyer Str. 1",
    "Postcode": 71063,
    "City": "Sindelfingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.70349621",
    "Longitude": "009.00171422",
    "Telephone Number": "+49 (0) 70318165",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "48.70349621,9.00171422",
    "IsOpen24Hours": true,
    "id": "48.70349621,9.00171422"
  },
  {
    "Gas Station Number": 2108,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Hauptstrasse 38",
    "Postcode": 73329,
    "City": "Kuchen",
    "State": "Baden-Württemberg",
    "Latitude": "048.63763000",
    "Longitude": "009.79569000",
    "Telephone Number": "+49 (0) 73318681",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.63763,9.79569",
    "IsOpen24Hours": false,
    "id": "48.63763,9.79569"
  },
  {
    "Gas Station Number": 4050,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Friedberger Strasse 10",
    "Postcode": 61381,
    "City": "Friedrichsdorf-Koepp",
    "State": "Hessen",
    "Latitude": "050.27863000",
    "Longitude": "008.65529000",
    "Telephone Number": "+49 (0) 61757067",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.27863,8.65529",
    "IsOpen24Hours": false,
    "id": "50.27863,8.65529"
  },
  {
    "Gas Station Number": 2103,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Obertuerkheimer Str. 21",
    "Postcode": 73733,
    "City": "Esslingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.75094000",
    "Longitude": "009.27560000",
    "Telephone Number": "+49 (0) 71191833",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.75094,9.2756",
    "IsOpen24Hours": false,
    "id": "48.75094,9.2756"
  },
  {
    "Gas Station Number": 2021,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Neckarsteinacher Str. 21",
    "Postcode": 69151,
    "City": "Neckargemuend",
    "State": "Baden-Württemberg",
    "Latitude": "049.39578334",
    "Longitude": "008.80907417",
    "Telephone Number": "+49 (0) 62232715",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.39578334,8.80907417",
    "IsOpen24Hours": false,
    "id": "49.39578334,8.80907417"
  },
  {
    "Gas Station Number": 3108,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Callenberger Strasse 38",
    "Postcode": 96450,
    "City": "Coburg",
    "State": "Bayern",
    "Latitude": "050.26845000",
    "Longitude": "010.95439000",
    "Telephone Number": "+49 (0) 95616956",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.26845,10.95439",
    "IsOpen24Hours": false,
    "id": "50.26845,10.95439"
  },
  {
    "Gas Station Number": 3141,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Niederwerrner Str. 4  1/3",
    "Postcode": 97421,
    "City": "Schweinfurt",
    "State": "Bayern",
    "Latitude": "050.04840000",
    "Longitude": "010.22893000",
    "Telephone Number": "+49 (0) 97211865",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.0484,10.22893",
    "IsOpen24Hours": false,
    "id": "50.0484,10.22893"
  },
  {
    "Gas Station Number": 1184,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "An Der B 12",
    "Postcode": 83558,
    "City": "Maitenbeth-Thal",
    "State": "Bayern",
    "Latitude": "048.15275325",
    "Longitude": "012.08951466",
    "Telephone Number": "+49 (0) 8076631",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.15275325,12.08951466",
    "IsOpen24Hours": false,
    "id": "48.15275325,12.08951466"
  },
  {
    "Gas Station Number": 4736,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Herfaer Str. 4",
    "Postcode": 36289,
    "City": "Friedewald",
    "State": "Hessen",
    "Latitude": "050.88390000",
    "Longitude": "009.86600000",
    "Telephone Number": "+49 (0) 66749191",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8839,9.866",
    "IsOpen24Hours": false,
    "id": "50.8839,9.866"
  },
  {
    "Gas Station Number": 4741,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Eisenacher Str. 15",
    "Postcode": 36266,
    "City": "Philippstal",
    "State": "Hessen",
    "Latitude": "050.88779500",
    "Longitude": "009.92265500",
    "Telephone Number": "+49 (0) 6620425",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.887795,9.922655",
    "IsOpen24Hours": false,
    "id": "50.887795,9.922655"
  },
  {
    "Gas Station Number": 5117,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Flughafenstrasse 81",
    "Postcode": 1109,
    "City": "Dresden",
    "State": "Sachsen",
    "Latitude": "051.12055389",
    "Longitude": "013.76287394",
    "Telephone Number": "+49 (0) 35189031",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "51.12055389,13.76287394",
    "IsOpen24Hours": true,
    "id": "51.12055389,13.76287394"
  },
  {
    "Gas Station Number": 1601,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Schwaighofstrasse 93",
    "Postcode": 83684,
    "City": "Tegernsee",
    "State": "Bayern",
    "Latitude": "047.69437000",
    "Longitude": "011.77229000",
    "Telephone Number": "+49 (0) 80225140",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.69437,11.77229",
    "IsOpen24Hours": false,
    "id": "47.69437,11.77229"
  },
  {
    "Gas Station Number": 4043,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Vilbeler Landstrasse 248",
    "Postcode": 60388,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.15693000",
    "Longitude": "008.74517000",
    "Telephone Number": "+49 (0) 61092760",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.15693,8.74517",
    "IsOpen24Hours": false,
    "id": "50.15693,8.74517"
  },
  {
    "Gas Station Number": 5116,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Dietzgenstr. 127",
    "Postcode": 13158,
    "City": "Berlin",
    "State": "Berlin",
    "Latitude": "052.59468387",
    "Longitude": "013.40291895",
    "Telephone Number": "+49 (0) 30477504",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "52.59468387,13.40291895",
    "IsOpen24Hours": true,
    "id": "52.59468387,13.40291895"
  },
  {
    "Gas Station Number": 4098,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Dortelweiler Strasse 49",
    "Postcode": 60389,
    "City": "Frankfurt",
    "State": "Hessen",
    "Latitude": "050.13377000",
    "Longitude": "008.70642000",
    "Telephone Number": "+49 (0) 69461333",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.13377,8.70642",
    "IsOpen24Hours": false,
    "id": "50.13377,8.70642"
  },
  {
    "Gas Station Number": 1210,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstr 1",
    "Postcode": 82152,
    "City": "Planegg",
    "State": "Bayern",
    "Latitude": "048.10185854",
    "Longitude": "011.42627818",
    "Telephone Number": "+49 (0) 89859415",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.10185854,11.42627818",
    "IsOpen24Hours": false,
    "id": "48.10185854,11.42627818"
  },
  {
    "Gas Station Number": 1160,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Muenchner Strasse 24/26",
    "Postcode": 82256,
    "City": "Fuerstenfeldbruck",
    "State": "Bayern",
    "Latitude": "048.17665000",
    "Longitude": "011.26006000",
    "Telephone Number": "+49 (0) 81416200",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.17665,11.26006",
    "IsOpen24Hours": false,
    "id": "48.17665,11.26006"
  },
  {
    "Gas Station Number": 2015,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Merzhauser Strasse 104",
    "Postcode": 79100,
    "City": "Freiburg",
    "State": "Baden-Württemberg",
    "Latitude": "047.97979000",
    "Longitude": "007.83187000",
    "Telephone Number": "+49 (0) 76140345",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.97979,7.83187",
    "IsOpen24Hours": false,
    "id": "47.97979,7.83187"
  },
  {
    "Gas Station Number": 4561,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Griesheimer Str. 31",
    "Postcode": 64560,
    "City": "Riedstadt-Wolfskehlen",
    "State": "Hessen",
    "Latitude": "049.85529487",
    "Longitude": "008.50622223",
    "Telephone Number": "+49 (0) 61589752",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.85529487,8.50622223",
    "IsOpen24Hours": false,
    "id": "49.85529487,8.50622223"
  },
  {
    "Gas Station Number": 4306,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Hauptstr. 1a",
    "Postcode": 67297,
    "City": "Marnheim",
    "State": "Rheinland-Pfalz",
    "Latitude": "049.62936000",
    "Longitude": "008.03572000",
    "Telephone Number": "+49 (0) 63527048",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "49.62936,8.03572",
    "IsOpen24Hours": true,
    "id": "49.62936,8.03572"
  },
  {
    "Gas Station Number": 1654,
    "Brand": "ENI",
    "Description": "LKW Tankstelle",
    "Address": "Inntal-Ost / Autobahnrastst.2",
    "Postcode": 83126,
    "City": "Kiefersfelden",
    "State": "Bayern",
    "Latitude": "047.73387000",
    "Longitude": "012.13147000",
    "Telephone Number": "+49 (0) 80333045",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "47.73387,12.13147",
    "IsOpen24Hours": true,
    "id": "47.73387,12.13147"
  },
  {
    "Gas Station Number": 4019,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Siegener Strasse",
    "Postcode": 65589,
    "City": "Hadamar",
    "State": "Hessen",
    "Latitude": "050.47772000",
    "Longitude": "008.05952000",
    "Telephone Number": "+49 (0) 64332630",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.47772,8.05952",
    "IsOpen24Hours": false,
    "id": "50.47772,8.05952"
  },
  {
    "Gas Station Number": 2044,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Gottl.-Daimler-Str. 44",
    "Postcode": 68165,
    "City": "Mannheim",
    "State": "Baden-Württemberg",
    "Latitude": "049.47373378",
    "Longitude": "008.49856516",
    "Telephone Number": "+49 (0) 62144855",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.47373378,8.49856516",
    "IsOpen24Hours": false,
    "id": "49.47373378,8.49856516"
  },
  {
    "Gas Station Number": 2616,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Schoenbuchstr. 40",
    "Postcode": 71032,
    "City": "Boeblingen",
    "State": "Baden-Württemberg",
    "Latitude": "048.67316000",
    "Longitude": "009.01353000",
    "Telephone Number": "+49 (0) 70312797",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.67316,9.01353",
    "IsOpen24Hours": false,
    "id": "48.67316,9.01353"
  },
  {
    "Gas Station Number": 1176,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Muenchner Str. 57",
    "Postcode": 85737,
    "City": "Ismaning",
    "State": "Bayern",
    "Latitude": "048.22335767",
    "Longitude": "011.66946611",
    "Telephone Number": "+49 (0) 89969238",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.22335767,11.66946611",
    "IsOpen24Hours": false,
    "id": "48.22335767,11.66946611"
  },
  {
    "Gas Station Number": 1541,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Luitpoldstrae 55",
    "Postcode": 84034,
    "City": "Landshut",
    "State": "Bayern",
    "Latitude": "048.54211133",
    "Longitude": "012.13959734",
    "Telephone Number": "+49 (0) 87161836",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.54211133,12.13959734",
    "IsOpen24Hours": false,
    "id": "48.54211133,12.13959734"
  },
  {
    "Gas Station Number": 5034,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Schmarler Damm 9",
    "Postcode": 18069,
    "City": "Rostock",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "054.12927218",
    "Longitude": "012.07547295",
    "Telephone Number": "+49 (0) 38112096",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "54.12927218,12.07547295",
    "IsOpen24Hours": true,
    "id": "54.12927218,12.07547295"
  },
  {
    "Gas Station Number": 1605,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Tegernseer Strasse 20",
    "Postcode": 83703,
    "City": "Gmund",
    "State": "Bayern",
    "Latitude": "047.74743942",
    "Longitude": "011.73804212",
    "Telephone Number": "+49 (0) 80227526",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.74743942,11.73804212",
    "IsOpen24Hours": false,
    "id": "47.74743942,11.73804212"
  },
  {
    "Gas Station Number": 4638,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Elverdisser Str. 327",
    "Postcode": 32052,
    "City": "Herford",
    "State": "Nordrhein-Westfalen",
    "Latitude": "052.10577592",
    "Longitude": "008.66133834",
    "Telephone Number": "+49 (0) 52217123",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.10577592,8.66133834",
    "IsOpen24Hours": false,
    "id": "52.10577592,8.66133834"
  },
  {
    "Gas Station Number": 5015,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Finsterwalder Str. 14",
    "Postcode": 3205,
    "City": "Calau",
    "State": "Brandenburg",
    "Latitude": "051.74351496",
    "Longitude": "013.94139301",
    "Telephone Number": "+49 (0) 35418019",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "51.74351496,13.94139301",
    "IsOpen24Hours": true,
    "id": "51.74351496,13.94139301"
  },
  {
    "Gas Station Number": 4777,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Talstr. 37",
    "Postcode": 66287,
    "City": "Quierschied",
    "State": "Saarland",
    "Latitude": "049.30380000",
    "Longitude": "007.02652000",
    "Telephone Number": "+49 (0) 6897-841",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.3038,7.02652",
    "IsOpen24Hours": false,
    "id": "49.3038,7.02652"
  },
  {
    "Gas Station Number": 4552,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Industriestr. 9",
    "Postcode": 64653,
    "City": "Lorsch",
    "State": "Hessen",
    "Latitude": "049.64247000",
    "Longitude": "008.57126000",
    "Telephone Number": "+49 (0) 6251/572",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.64247,8.57126",
    "IsOpen24Hours": false,
    "id": "49.64247,8.57126"
  },
  {
    "Gas Station Number": 1154,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Oberfoehringer Str. 178",
    "Postcode": 81925,
    "City": "Muenchen",
    "State": "Bayern",
    "Latitude": "048.16625326",
    "Longitude": "011.62366635",
    "Telephone Number": "+49 (0) 89951498",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.16625326,11.62366635",
    "IsOpen24Hours": false,
    "id": "48.16625326,11.62366635"
  },
  {
    "Gas Station Number": 3365,
    "Brand": "ENI",
    "Description": "Strassentankstelle",
    "Address": "Ingolstaedter Str. 1",
    "Postcode": 93349,
    "City": "Mindelstetten",
    "State": "Bayern",
    "Latitude": "048.85047097",
    "Longitude": "011.64196287",
    "Telephone Number": "+49 (0) 84049148",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.85047097,11.64196287",
    "IsOpen24Hours": false,
    "id": "48.85047097,11.64196287"
  },
  {
    "Gas Station Number": 2073,
    "Brand": "ENI",
    "Description": "Autohof",
    "Address": "Mannheimer Strasse 1A",
    "Postcode": 76676,
    "City": "Graben-Neudorf",
    "State": "Baden-Württemberg",
    "Latitude": "049.16917858",
    "Longitude": "008.49272693",
    "Telephone Number": "+49 (0) 72557255",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "49.16917858,8.49272693",
    "IsOpen24Hours": true,
    "id": "49.16917858,8.49272693"
  },
  {
    "Gas Station Number": "3EF001",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Fauststraße 3",
    "Postcode": 85051,
    "City": "Ingolstadt",
    "State": "Bayern",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF002",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Berliner Straße 277-279",
    "Postcode": 65205,
    "City": "Wiesbaden",
    "State": "Hessen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF003",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Am Helftaer Anger 5",
    "Postcode": "06295",
    "City": "Lutherstadt Eisleben",
    "State": "Sachsen-Anhalt",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF006",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Koitenhäger Landstr. 20",
    "Postcode": 17491,
    "City": "Greifswald",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF012",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Emderstr. 19",
    "Postcode": 26603,
    "City": "Aurich",
    "State": "Niedersachsen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF014",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Gottlieb-Daimler-Str. 2a",
    "Postcode": 29614,
    "City": "Soltau",
    "State": "Niedersachsen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF015",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Vahrenwalder Str. 194",
    "Postcode": 30165,
    "City": "Hannover",
    "State": "Niedersachsen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF016",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Rudolf-Diesel-Str. 2",
    "Postcode": 36251,
    "City": "Bad Hersfeld",
    "State": "Hessen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF017",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "In den Lohbalken 1",
    "Postcode": 38165,
    "City": "Lehre",
    "State": "Niedersachsen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF026",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Achimer Straße 4-4b",
    "Postcode": 27283,
    "City": "Verden a.d. Aller",
    "State": "Niedersachsen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF044",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Willi-Kaidel-Str. 7",
    "Postcode": 97424,
    "City": "Schweinfurt",
    "State": "Bayern",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF045",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Weseler Str. 101",
    "Postcode": 46487,
    "City": "Wesel",
    "State": "Nordrhein-Westfalen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF048",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Heinrich-Nordhoff-Str. 115",
    "Postcode": 38440,
    "City": "Wolfsburg",
    "State": "Niedersachsen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF055",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Hallesche Landstr. 107",
    "Postcode": "06406",
    "City": "Bernburg (Saale)",
    "State": "Sachsen-Anhalt",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF056",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Kolenfelder Str. 67b",
    "Postcode": 31515,
    "City": "Wunstorf",
    "State": "Niedersachsen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF058",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Druseltalstr. 3",
    "Postcode": 34131,
    "City": "Kassel",
    "State": "Hessen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF059",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Leipziger Str. 195",
    "Postcode": 34124,
    "City": "Kassel",
    "State": "Hessen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF061",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Bahnhofstr. 14b",
    "Postcode": 26506,
    "City": "Norden",
    "State": "Niedersachsen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF062",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Gießener Straße 83",
    "Postcode": 34560,
    "City": "Fritzlar",
    "State": "Hessen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF063",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Sonnenallee 3",
    "Postcode": 35274,
    "City": "Kirchhain",
    "State": "Hessen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF064",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Oberes Moos 1",
    "Postcode": 92318,
    "City": "Neumarkt in der Oberpfalz",
    "State": "Bayern",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF077",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Eyßelheideweg 1",
    "Postcode": 38518,
    "City": "Gifhorn",
    "State": "Niedersachsen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF081",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Arneburger Str. 32",
    "Postcode": 39576,
    "City": "Stendal",
    "State": "Sachsen-Anhalt",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF085",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Salbker Chaussee 67",
    "Postcode": 39118,
    "City": "Magdeburg",
    "State": "Sachsen-Anhalt",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF086",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Theodor-Sanne-Str. 4",
    "Postcode": 83233,
    "City": "Bernau am Chiemsee",
    "State": "Bayern",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF090",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Osloer Str.1",
    "Postcode": 49377,
    "City": "Vechta",
    "State": "Niedersachsen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF091",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Röntgenstraße 1",
    "Postcode": 32052,
    "City": "Herford",
    "State": "Nordrhein-Westfalen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF092",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Ringstr. 111",
    "Postcode": 32427,
    "City": "Minden",
    "State": "Nordrhein-Westfalen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF093",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Gewerbepark Euzenberg 1",
    "Postcode": 37115,
    "City": "Duderstadt",
    "State": "Niedersachsen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF283",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Lindenplatz 1",
    "Postcode": 99310,
    "City": "Arnstadt",
    "State": "Thüringen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF284",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Straßburg Straße 3",
    "Postcode": 97424,
    "City": "Schweinfurt",
    "State": "Bayern",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF285",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Gebr.-Franke-Str. 1",
    "Postcode": 99974,
    "City": "Mühlhausen",
    "State": "Thüringen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF288",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Pagenscher Str. 50",
    "Postcode": 49090,
    "City": "Osnabrück",
    "State": "Niedersachsen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF293",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Albaxer Str. 65",
    "Postcode": 37671,
    "City": "Höxter",
    "State": "Nordrhein-Westfalen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "3EF294",
    "Brand": "EV",
    "Description": "Pulse Ladestation",
    "Address": "Grabensteiner Str. 6",
    "Postcode": 34369,
    "City": "Hofgeismar",
    "State": "Hessen",
    "Latitude": "",
    "Longitude": "",
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Ja",
    "location": "0,0",
    "IsOpen24Hours": true,
    "id": "0,0"
  },
  {
    "Gas Station Number": "TD004440",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PADERBORNER TOR 164",
    "Postcode": 34414,
    "City": "WARBURG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4922,
    "Longitude": 9.1309,
    "Telephone Number": "05641-8530",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4922,9.1309",
    "IsOpen24Hours": false,
    "id": "51.4922,9.1309"
  },
  {
    "Gas Station Number": "TD040071",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "RUPPERTSWIES 6",
    "Postcode": 85092,
    "City": "KOESCHING",
    "State": "Bayern",
    "Latitude": 48.8111,
    "Longitude": 11.4791,
    "Telephone Number": "08456-9698530",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8111,11.4791",
    "IsOpen24Hours": false,
    "id": "48.8111,11.4791"
  },
  {
    "Gas Station Number": "TD032664",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PLAUER CHAUSSEE 26",
    "Postcode": 18292,
    "City": "KRAKOW AM SEE",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.6479,
    "Longitude": 12.2655,
    "Telephone Number": "038457-24645",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.6479,12.2655",
    "IsOpen24Hours": false,
    "id": "53.6479,12.2655"
  },
  {
    "Gas Station Number": "TD038505",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "JOSEF-NEUMEIER-STR. 1",
    "Postcode": 85664,
    "City": "HOHENLINDEN",
    "State": "Bayern",
    "Latitude": 48.1557,
    "Longitude": 11.9866,
    "Telephone Number": "08124/444842",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.1557,11.9866",
    "IsOpen24Hours": false,
    "id": "48.1557,11.9866"
  },
  {
    "Gas Station Number": "TD110910",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HORSTHAUSER STR.213",
    "Postcode": 44628,
    "City": "HERNE",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5608,
    "Longitude": 7.2312,
    "Telephone Number": "02323-8603",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5608,7.2312",
    "IsOpen24Hours": false,
    "id": "51.5608,7.2312"
  },
  {
    "Gas Station Number": "TD040410",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KORNWEG 1 / L1140",
    "Postcode": 71409,
    "City": "SCHWAIKHEIM",
    "State": "Baden-Württemberg",
    "Latitude": 48.8749,
    "Longitude": 9.3433,
    "Telephone Number": "07195-135491",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8749,9.3433",
    "IsOpen24Hours": false,
    "id": "48.8749,9.3433"
  },
  {
    "Gas Station Number": "TD037705",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AUTOHOF ELKERSBERG 2",
    "Postcode": 57234,
    "City": "WILNSDORF",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.8147,
    "Longitude": 8.0967,
    "Telephone Number": "02739-40339-0",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8147,8.0967",
    "IsOpen24Hours": false,
    "id": "50.8147,8.0967"
  },
  {
    "Gas Station Number": "TD000862",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MARXZELLER STR. 90",
    "Postcode": 75305,
    "City": "NEUENBUERG",
    "State": "Baden-Württemberg",
    "Latitude": 48.8529,
    "Longitude": 8.5821,
    "Telephone Number": "07082-413024",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8529,8.5821",
    "IsOpen24Hours": false,
    "id": "48.8529,8.5821"
  },
  {
    "Gas Station Number": "TD029868",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KLINKENBERG 28",
    "Postcode": 17126,
    "City": "JARMEN",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.9218,
    "Longitude": 13.3475,
    "Telephone Number": "039997-10404",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.9218,13.3475",
    "IsOpen24Hours": false,
    "id": "53.9218,13.3475"
  },
  {
    "Gas Station Number": "TD019414",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DUESSELDORFER STR.51",
    "Postcode": 47239,
    "City": "DUISBURG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.3964,
    "Longitude": 6.6578,
    "Telephone Number": "02151-406010",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3964,6.6578",
    "IsOpen24Hours": false,
    "id": "51.3964,6.6578"
  },
  {
    "Gas Station Number": "TD021246",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "KAMENZER STR. 27",
    "Postcode": 1561,
    "City": "THIENDORF",
    "State": "Sachsen",
    "Latitude": 51.2945,
    "Longitude": 13.7362,
    "Telephone Number": "035248-81325",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2945,13.7362",
    "IsOpen24Hours": false,
    "id": "51.2945,13.7362"
  },
  {
    "Gas Station Number": "TD001016",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KOELNER STR. 17 A",
    "Postcode": 53489,
    "City": "SINZIG",
    "State": "Rheinland-Pfalz",
    "Latitude": 50.5484,
    "Longitude": 7.245,
    "Telephone Number": "02642-409200",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.5484,7.245",
    "IsOpen24Hours": false,
    "id": "50.5484,7.245"
  },
  {
    "Gas Station Number": "TD034918",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FRIEDENSSTR. 88",
    "Postcode": 23942,
    "City": "DASSOW",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.9131,
    "Longitude": 10.9775,
    "Telephone Number": "038826-80219",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.9131,10.9775",
    "IsOpen24Hours": false,
    "id": "53.9131,10.9775"
  },
  {
    "Gas Station Number": "TD027102",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WERNSTEDTER STR 2",
    "Postcode": 39624,
    "City": "KALBE",
    "State": "Sachsen-Anhalt",
    "Latitude": 52.6573,
    "Longitude": 11.3833,
    "Telephone Number": "039080-40538",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.6573,11.3833",
    "IsOpen24Hours": false,
    "id": "52.6573,11.3833"
  },
  {
    "Gas Station Number": "TD124210",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SULZBACHTALSTR. 19",
    "Postcode": 66125,
    "City": "SAARBRUECKEN",
    "State": "Saarland",
    "Latitude": 49.2671,
    "Longitude": 7.0112,
    "Telephone Number": "0681-3908027",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.2671,7.0112",
    "IsOpen24Hours": false,
    "id": "49.2671,7.0112"
  },
  {
    "Gas Station Number": "TD019372",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FRIEDHOFSTR. 7",
    "Postcode": 77694,
    "City": "KEHL",
    "State": "Baden-Württemberg",
    "Latitude": 48.5709,
    "Longitude": 7.8204,
    "Telephone Number": "07851-640",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.5709,7.8204",
    "IsOpen24Hours": false,
    "id": "48.5709,7.8204"
  },
  {
    "Gas Station Number": "TD020099",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FREIENWALDER STR. 26",
    "Postcode": 16269,
    "City": "WRIEZEN",
    "State": "Brandenburg",
    "Latitude": 52.7253,
    "Longitude": 14.1269,
    "Telephone Number": "033456-3113",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.7253,14.1269",
    "IsOpen24Hours": false,
    "id": "52.7253,14.1269"
  },
  {
    "Gas Station Number": "TD020230",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "RIBNITZER STR. 42",
    "Postcode": 13051,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.5656,
    "Longitude": 13.4918,
    "Telephone Number": "030-9294032",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5656,13.4918",
    "IsOpen24Hours": false,
    "id": "52.5656,13.4918"
  },
  {
    "Gas Station Number": "TD040840",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AN DER AUTOBAHN 1",
    "Postcode": 4435,
    "City": "SCHKEUDITZ",
    "State": "Sachsen",
    "Latitude": 51.4019,
    "Longitude": 12.1813,
    "Telephone Number": "034204-989920",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4019,12.1813",
    "IsOpen24Hours": false,
    "id": "51.4019,12.1813"
  },
  {
    "Gas Station Number": "TD022863",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PUSCHKINALLEE 3 - 5",
    "Postcode": 99867,
    "City": "GOTHA",
    "State": "Thüringen",
    "Latitude": 50.9424,
    "Longitude": 10.7007,
    "Telephone Number": "03621-853772",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9424,10.7007",
    "IsOpen24Hours": false,
    "id": "50.9424,10.7007"
  },
  {
    "Gas Station Number": "TD003921",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SEGEBERGER STR. 55 A",
    "Postcode": 23617,
    "City": "STOCKELSDORF",
    "State": "Schleswig-Holstein",
    "Latitude": 53.8878,
    "Longitude": 10.6446,
    "Telephone Number": "0451-495676",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.8878,10.6446",
    "IsOpen24Hours": false,
    "id": "53.8878,10.6446"
  },
  {
    "Gas Station Number": "TD150370",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KOECHSTEDTER WEG 8A",
    "Postcode": 6179,
    "City": "TEUTSCHENTHAL OT LANGENBOGEN",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.4793,
    "Longitude": 11.7842,
    "Telephone Number": "034601-23164",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4793,11.7842",
    "IsOpen24Hours": false,
    "id": "51.4793,11.7842"
  },
  {
    "Gas Station Number": "TD182150",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAUSACHER STR. 13",
    "Postcode": 77793,
    "City": "GUTACH",
    "State": "Baden-Württemberg",
    "Latitude": 48.2833,
    "Longitude": 8.1939,
    "Telephone Number": "07831-969488",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.2833,8.1939",
    "IsOpen24Hours": false,
    "id": "48.2833,8.1939"
  },
  {
    "Gas Station Number": "TD041590",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MUENCHNER STR. 16",
    "Postcode": 82319,
    "City": "STARNBERG",
    "State": "Bayern",
    "Latitude": 48.0014,
    "Longitude": 11.3497,
    "Telephone Number": "08151-16785",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.0014,11.3497",
    "IsOpen24Hours": false,
    "id": "48.0014,11.3497"
  },
  {
    "Gas Station Number": "TD020347",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ZEPERNICKER STR. 9-10",
    "Postcode": 13125,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.641,
    "Longitude": 13.5143,
    "Telephone Number": "030-9497581",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.641,13.5143",
    "IsOpen24Hours": false,
    "id": "52.641,13.5143"
  },
  {
    "Gas Station Number": "TD026021",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LIMBACHER STR. 218-220",
    "Postcode": 9116,
    "City": "CHEMNITZ",
    "State": "Sachsen",
    "Latitude": 50.8329,
    "Longitude": 12.8734,
    "Telephone Number": "0371-364046",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8329,12.8734",
    "IsOpen24Hours": false,
    "id": "50.8329,12.8734"
  },
  {
    "Gas Station Number": "TD020164",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MARKENDORFER STR. 15",
    "Postcode": 15234,
    "City": "FRANKFURT",
    "State": "Brandenburg",
    "Latitude": 52.341,
    "Longitude": 14.5291,
    "Telephone Number": "0335-4000187",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.341,14.5291",
    "IsOpen24Hours": false,
    "id": "52.341,14.5291"
  },
  {
    "Gas Station Number": "TD035881",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AN DER B 49",
    "Postcode": 35447,
    "City": "REISKIRCHEN",
    "State": "Hessen",
    "Latitude": 50.5961,
    "Longitude": 8.8175,
    "Telephone Number": "06408549756",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.5961,8.8175",
    "IsOpen24Hours": false,
    "id": "50.5961,8.8175"
  },
  {
    "Gas Station Number": "TD028373",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ALTRUPPINER ALLEE 25",
    "Postcode": 16816,
    "City": "NEURUPPIN",
    "State": "Brandenburg",
    "Latitude": 52.939,
    "Longitude": 12.8187,
    "Telephone Number": "03391-3291",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.939,12.8187",
    "IsOpen24Hours": false,
    "id": "52.939,12.8187"
  },
  {
    "Gas Station Number": "TD005702",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "OSTERFELDSTR. 60",
    "Postcode": 58300,
    "City": "WETTER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.3968,
    "Longitude": 7.3519,
    "Telephone Number": "02335-70130",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3968,7.3519",
    "IsOpen24Hours": false,
    "id": "51.3968,7.3519"
  },
  {
    "Gas Station Number": "TD000603",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WORMSER STR. 2",
    "Postcode": 68519,
    "City": "VIERNHEIM",
    "State": "Hessen",
    "Latitude": 49.5435,
    "Longitude": 8.5743,
    "Telephone Number": "06204-76161",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.5435,8.5743",
    "IsOpen24Hours": false,
    "id": "49.5435,8.5743"
  },
  {
    "Gas Station Number": "TD111190",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WESTFALENDAMM 272",
    "Postcode": 44141,
    "City": "DORTMUND",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5041,
    "Longitude": 7.5108,
    "Telephone Number": "0231-596931",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5041,7.5108",
    "IsOpen24Hours": false,
    "id": "51.5041,7.5108"
  },
  {
    "Gas Station Number": "TD039834",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "OLOF-PALME-ALLEE 5",
    "Postcode": 25541,
    "City": "BRUNSBUETTEL",
    "State": "Schleswig-Holstein",
    "Latitude": 53.9021,
    "Longitude": 9.1222,
    "Telephone Number": "04852-9405010",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.9021,9.1222",
    "IsOpen24Hours": false,
    "id": "53.9021,9.1222"
  },
  {
    "Gas Station Number": "TD007120",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "VOGELSTANGE 17",
    "Postcode": 4741,
    "City": "ROSSWEIN",
    "State": "Sachsen",
    "Latitude": 51.0722,
    "Longitude": 13.1846,
    "Telephone Number": "034322-43775",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0722,13.1846",
    "IsOpen24Hours": false,
    "id": "51.0722,13.1846"
  },
  {
    "Gas Station Number": "TD003657",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "OSTERWALDER STR. 63",
    "Postcode": 30827,
    "City": "GARBSEN",
    "State": "Niedersachsen",
    "Latitude": 52.4437,
    "Longitude": 9.6086,
    "Telephone Number": "05131-91637",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4437,9.6086",
    "IsOpen24Hours": false,
    "id": "52.4437,9.6086"
  },
  {
    "Gas Station Number": "TD034439",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PENIGER STR. 1B",
    "Postcode": 4643,
    "City": "GEITHAIN",
    "State": "Sachsen",
    "Latitude": 51.0514,
    "Longitude": 12.7069,
    "Telephone Number": "034341-43567",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0514,12.7069",
    "IsOpen24Hours": false,
    "id": "51.0514,12.7069"
  },
  {
    "Gas Station Number": "TD040113",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "ERLACHSEEWEG 10",
    "Postcode": 76227,
    "City": "KARLSRUHE",
    "State": "Baden-Württemberg",
    "Latitude": 48.9859,
    "Longitude": 8.4476,
    "Telephone Number": "0721-56867276",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.9859,8.4476",
    "IsOpen24Hours": false,
    "id": "48.9859,8.4476"
  },
  {
    "Gas Station Number": "TD035428",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BERLINER STR. 135",
    "Postcode": 3046,
    "City": "COTTBUS",
    "State": "Brandenburg",
    "Latitude": 51.7614,
    "Longitude": 14.3247,
    "Telephone Number": "0355-4946930",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.7614,14.3247",
    "IsOpen24Hours": false,
    "id": "51.7614,14.3247"
  },
  {
    "Gas Station Number": "TD000079",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KESSENICHER STR. 212",
    "Postcode": 53129,
    "City": "BONN-DOTTENDORF",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.7028,
    "Longitude": 7.1161,
    "Telephone Number": "0228-231116",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.7028,7.1161",
    "IsOpen24Hours": false,
    "id": "50.7028,7.1161"
  },
  {
    "Gas Station Number": "TD041533",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "EUREN STRASSE 92",
    "Postcode": 54294,
    "City": "TRIER EUREN",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.7451,
    "Longitude": 6.6143,
    "Telephone Number": "06519982926",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.7451,6.6143",
    "IsOpen24Hours": false,
    "id": "49.7451,6.6143"
  },
  {
    "Gas Station Number": "TD030767",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ASCHERSLEBENER STR. 60",
    "Postcode": 6333,
    "City": "HETTSTEDT",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.6571,
    "Longitude": 11.4951,
    "Telephone Number": "03476-851065",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6571,11.4951",
    "IsOpen24Hours": false,
    "id": "51.6571,11.4951"
  },
  {
    "Gas Station Number": "TD002840",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "VOSSWALDESTR. 1",
    "Postcode": 63457,
    "City": "HANAU",
    "State": "Hessen",
    "Latitude": 50.1083,
    "Longitude": 8.9689,
    "Telephone Number": "06181-5206287",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.1083,8.9689",
    "IsOpen24Hours": false,
    "id": "50.1083,8.9689"
  },
  {
    "Gas Station Number": "TD039578",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KOHLENSTR. 51",
    "Postcode": 54296,
    "City": "TRIER-TARFORST",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.7451,
    "Longitude": 6.6916,
    "Telephone Number": "0651-9990133",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.7451,6.6916",
    "IsOpen24Hours": false,
    "id": "49.7451,6.6916"
  },
  {
    "Gas Station Number": "TD020214",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB WEST/A 11",
    "Postcode": 16244,
    "City": "SCHORFHEIDE-BUCKOWSEE",
    "State": "Brandenburg",
    "Latitude": 52.883,
    "Longitude": 13.7019,
    "Telephone Number": "033363-46210",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.883,13.7019",
    "IsOpen24Hours": false,
    "id": "52.883,13.7019"
  },
  {
    "Gas Station Number": "TD028662",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FRANKFURTER STR.14",
    "Postcode": 4916,
    "City": "HERZBERG",
    "State": "Brandenburg",
    "Latitude": 51.6972,
    "Longitude": 13.2516,
    "Telephone Number": "03535-247650",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6972,13.2516",
    "IsOpen24Hours": false,
    "id": "51.6972,13.2516"
  },
  {
    "Gas Station Number": "TD111630",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HUENFELDER STR. 4",
    "Postcode": 36282,
    "City": "HAUNECK",
    "State": "Hessen",
    "Latitude": 50.8242,
    "Longitude": 9.7325,
    "Telephone Number": "06621-75264",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8242,9.7325",
    "IsOpen24Hours": false,
    "id": "50.8242,9.7325"
  },
  {
    "Gas Station Number": "TD000758",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KARLSRUHER STR. 16",
    "Postcode": 76437,
    "City": "RASTATT",
    "State": "Baden-Württemberg",
    "Latitude": 48.8638,
    "Longitude": 8.2179,
    "Telephone Number": "07222-150160",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8638,8.2179",
    "IsOpen24Hours": false,
    "id": "48.8638,8.2179"
  },
  {
    "Gas Station Number": "TD150190",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB-BROHLTAL-OST/A 61",
    "Postcode": 56651,
    "City": "NIEDERZISSEN",
    "State": "Rheinland-Pfalz",
    "Latitude": 50.4398,
    "Longitude": 7.2261,
    "Telephone Number": "02636-9419460",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.4398,7.2261",
    "IsOpen24Hours": false,
    "id": "50.4398,7.2261"
  },
  {
    "Gas Station Number": "TD000803",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "EGERLAENDER WEG 2",
    "Postcode": 74523,
    "City": "SCHWAEBISCH HALL",
    "State": "Baden-Württemberg",
    "Latitude": 49.1065,
    "Longitude": 9.7188,
    "Telephone Number": "0791-52138",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.1065,9.7188",
    "IsOpen24Hours": false,
    "id": "49.1065,9.7188"
  },
  {
    "Gas Station Number": "TD036962",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BILLSTEDTER HAUPTSTRASSE 21",
    "Postcode": 22111,
    "City": "HAMBURG",
    "State": "Hamburg",
    "Latitude": 53.5416,
    "Longitude": 10.0988,
    "Telephone Number": "040-7314835",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.5416,10.0988",
    "IsOpen24Hours": false,
    "id": "53.5416,10.0988"
  },
  {
    "Gas Station Number": "TD028837",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BOBLITZER CHAUSSEESTR. 2",
    "Postcode": 3222,
    "City": "LUEBBENAU/OT BOBLITZ",
    "State": "Brandenburg",
    "Latitude": 51.8467,
    "Longitude": 13.9724,
    "Telephone Number": "03542-44138",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8467,13.9724",
    "IsOpen24Hours": false,
    "id": "51.8467,13.9724"
  },
  {
    "Gas Station Number": "TD042036",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHEINFELDER STR. 15",
    "Postcode": 96160,
    "City": "GEISELWIND",
    "State": "Bayern",
    "Latitude": 49.768,
    "Longitude": 10.4714,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.768,10.4714",
    "IsOpen24Hours": false,
    "id": "49.768,10.4714"
  },
  {
    "Gas Station Number": "TD036905",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ZEILSHEIMER STR. 37-39",
    "Postcode": 65719,
    "City": "HOFHEIM",
    "State": "Hessen",
    "Latitude": 50.0885,
    "Longitude": 8.4544,
    "Telephone Number": "06192-6776",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.0885,8.4544",
    "IsOpen24Hours": false,
    "id": "50.0885,8.4544"
  },
  {
    "Gas Station Number": "TD040600",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "REICHSSTR. 86",
    "Postcode": 14052,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.515,
    "Longitude": 13.2623,
    "Telephone Number": "030-3046042",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.515,13.2623",
    "IsOpen24Hours": false,
    "id": "52.515,13.2623"
  },
  {
    "Gas Station Number": "TD041442",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "HOHER RAIN 2",
    "Postcode": 73770,
    "City": "DENKENDORF",
    "State": "Baden-Württemberg",
    "Latitude": 48.6929,
    "Longitude": 9.3049,
    "Telephone Number": "071193445340",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.6929,9.3049",
    "IsOpen24Hours": false,
    "id": "48.6929,9.3049"
  },
  {
    "Gas Station Number": "TD040311",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "ALTE LANDSTR.23-29",
    "Postcode": 77972,
    "City": "MAHLBERG-ORSCHWEIER",
    "State": "Baden-Württemberg",
    "Latitude": 48.2749,
    "Longitude": 7.787,
    "Telephone Number": "07822-4349649",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.2749,7.787",
    "IsOpen24Hours": false,
    "id": "48.2749,7.787"
  },
  {
    "Gas Station Number": "TD038729",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "ANSBACHER STR. 42",
    "Postcode": 91589,
    "City": "AURACH",
    "State": "Bayern",
    "Latitude": 49.2523,
    "Longitude": 10.4345,
    "Telephone Number": "09804-9391922",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.2523,10.4345",
    "IsOpen24Hours": false,
    "id": "49.2523,10.4345"
  },
  {
    "Gas Station Number": "TD041657",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHWARMSTEDTER STR. 14",
    "Postcode": 29690,
    "City": "BUCHHOLZ",
    "State": "Niedersachsen",
    "Latitude": 52.6762,
    "Longitude": 9.6811,
    "Telephone Number": "05071-4380",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.6762,9.6811",
    "IsOpen24Hours": false,
    "id": "52.6762,9.6811"
  },
  {
    "Gas Station Number": "TD110030",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LANGE STR.159",
    "Postcode": 44581,
    "City": "CASTROP-RAUXEL",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5873,
    "Longitude": 7.326,
    "Telephone Number": "02305-79420",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5873,7.326",
    "IsOpen24Hours": false,
    "id": "51.5873,7.326"
  },
  {
    "Gas Station Number": "TD038828",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "SUESSWIESENSTR. 2",
    "Postcode": 74549,
    "City": "WOLPERTSHAUSEN",
    "State": "Baden-Württemberg",
    "Latitude": 49.1706,
    "Longitude": 9.8597,
    "Telephone Number": "07904-9435899",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.1706,9.8597",
    "IsOpen24Hours": false,
    "id": "49.1706,9.8597"
  },
  {
    "Gas Station Number": "TD007591",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ACHERINGER HAUPTSTR. 20",
    "Postcode": 85354,
    "City": "FREISING",
    "State": "Bayern",
    "Latitude": 48.3444,
    "Longitude": 11.7092,
    "Telephone Number": "08165-64290",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.3444,11.7092",
    "IsOpen24Hours": false,
    "id": "48.3444,11.7092"
  },
  {
    "Gas Station Number": "TD000679",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "VOCCARTSTR. 80",
    "Postcode": 52134,
    "City": "HERZOGENRATH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.8505,
    "Longitude": 6.0764,
    "Telephone Number": "02406-2480",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8505,6.0764",
    "IsOpen24Hours": false,
    "id": "50.8505,6.0764"
  },
  {
    "Gas Station Number": "TD022293",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MEISSNER STR.41",
    "Postcode": 1623,
    "City": "LOMMATZSCH",
    "State": "Sachsen",
    "Latitude": 51.1969,
    "Longitude": 13.3142,
    "Telephone Number": "035241-58631",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1969,13.3142",
    "IsOpen24Hours": false,
    "id": "51.1969,13.3142"
  },
  {
    "Gas Station Number": "TD031757",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AN DER STADTAUTOBAHN 70",
    "Postcode": 18107,
    "City": "ROSTOCK-LUETTENKLEIN",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 54.1315,
    "Longitude": 12.063,
    "Telephone Number": "0381-712092",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.1315,12.063",
    "IsOpen24Hours": false,
    "id": "54.1315,12.063"
  },
  {
    "Gas Station Number": "TD001909",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BAHNHOFSTR. 45",
    "Postcode": 76744,
    "City": "WOERTH",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.0464,
    "Longitude": 8.2732,
    "Telephone Number": "07271-7006",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.0464,8.2732",
    "IsOpen24Hours": false,
    "id": "49.0464,8.2732"
  },
  {
    "Gas Station Number": "TD003954",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WASBEKER STR. 361",
    "Postcode": 24537,
    "City": "NEUMUENSTER",
    "State": "Schleswig-Holstein",
    "Latitude": 54.0685,
    "Longitude": 9.938,
    "Telephone Number": "04321-61092",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.0685,9.938",
    "IsOpen24Hours": false,
    "id": "54.0685,9.938"
  },
  {
    "Gas Station Number": "TD040246",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ESSBAUM 2",
    "Postcode": 83122,
    "City": "SAMERBERG",
    "State": "Bayern",
    "Latitude": 47.7643,
    "Longitude": 12.1973,
    "Telephone Number": "08032/8826",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.7643,12.1973",
    "IsOpen24Hours": false,
    "id": "47.7643,12.1973"
  },
  {
    "Gas Station Number": "TD003343",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BINGER LANDSTR. 25",
    "Postcode": 55606,
    "City": "HOCHSTETTEN",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.7992,
    "Longitude": 7.5094,
    "Telephone Number": "06752-2066",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.7992,7.5094",
    "IsOpen24Hours": false,
    "id": "49.7992,7.5094"
  },
  {
    "Gas Station Number": "TD002162",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ROSENTHALER STR. 2",
    "Postcode": 67304,
    "City": "EISENBERG",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.5642,
    "Longitude": 8.0623,
    "Telephone Number": "06351-5164",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.5642,8.0623",
    "IsOpen24Hours": false,
    "id": "49.5642,8.0623"
  },
  {
    "Gas Station Number": "TD041632",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AUTOHOF STRASSBURGER STR. 4",
    "Postcode": 31275,
    "City": "LEHRTE",
    "State": "Niedersachsen",
    "Latitude": 52.3854,
    "Longitude": 9.9528,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3854,9.9528",
    "IsOpen24Hours": false,
    "id": "52.3854,9.9528"
  },
  {
    "Gas Station Number": "TD007450",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "OBERE LAUTER STR. 37",
    "Postcode": 67731,
    "City": "OTTERBACH",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.4788,
    "Longitude": 7.7355,
    "Telephone Number": "06301-8258",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.4788,7.7355",
    "IsOpen24Hours": false,
    "id": "49.4788,7.7355"
  },
  {
    "Gas Station Number": "TD039370",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ALEMANNENSTRASSE 1",
    "Postcode": 84130,
    "City": "DINGOLFING",
    "State": "Bayern",
    "Latitude": 48.6411,
    "Longitude": 12.4633,
    "Telephone Number": "087313099830",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.6411,12.4633",
    "IsOpen24Hours": false,
    "id": "48.6411,12.4633"
  },
  {
    "Gas Station Number": "TD028795",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "BERLINER STR. 23",
    "Postcode": 3226,
    "City": "VETSCHAU",
    "State": "Brandenburg",
    "Latitude": 51.7861,
    "Longitude": 14.066,
    "Telephone Number": "035433-2350",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.7861,14.066",
    "IsOpen24Hours": false,
    "id": "51.7861,14.066"
  },
  {
    "Gas Station Number": "TD003327",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "JAKOBSTR. 43",
    "Postcode": 67722,
    "City": "WINNWEILER",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.5648,
    "Longitude": 7.8567,
    "Telephone Number": "06302-2187",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.5648,7.8567",
    "IsOpen24Hours": false,
    "id": "49.5648,7.8567"
  },
  {
    "Gas Station Number": "TD005421",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ANTON-AULKE-RING 10",
    "Postcode": 48308,
    "City": "SENDEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8554,
    "Longitude": 7.4921,
    "Telephone Number": "02597-5547",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8554,7.4921",
    "IsOpen24Hours": false,
    "id": "51.8554,7.4921"
  },
  {
    "Gas Station Number": "TD004077",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BERLINER STR 15A",
    "Postcode": 14169,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.4361,
    "Longitude": 13.2658,
    "Telephone Number": "030-81059385",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4361,13.2658",
    "IsOpen24Hours": false,
    "id": "52.4361,13.2658"
  },
  {
    "Gas Station Number": "TD000284",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GROSSE STR. 38",
    "Postcode": 49451,
    "City": "HOLDORF",
    "State": "Niedersachsen",
    "Latitude": 52.5832,
    "Longitude": 8.1253,
    "Telephone Number": "05494-8603",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5832,8.1253",
    "IsOpen24Hours": false,
    "id": "52.5832,8.1253"
  },
  {
    "Gas Station Number": "TD028597",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MUSKAUER STR. 32",
    "Postcode": 3159,
    "City": "DOEBERN",
    "State": "Brandenburg",
    "Latitude": 51.6067,
    "Longitude": 14.6086,
    "Telephone Number": "035600-23083",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6067,14.6086",
    "IsOpen24Hours": false,
    "id": "51.6067,14.6086"
  },
  {
    "Gas Station Number": "TD037325",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DANZIGER STR. 69",
    "Postcode": 29633,
    "City": "MUNSTER",
    "State": "Niedersachsen",
    "Latitude": 52.9892,
    "Longitude": 10.0815,
    "Telephone Number": "05192-6267",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.9892,10.0815",
    "IsOpen24Hours": false,
    "id": "52.9892,10.0815"
  },
  {
    "Gas Station Number": "TD000926",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "O.-v.-NELL-BREUNING 4",
    "Postcode": 51503,
    "City": "ROESRATH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.8844,
    "Longitude": 7.1864,
    "Telephone Number": "02205-899802",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8844,7.1864",
    "IsOpen24Hours": false,
    "id": "50.8844,7.1864"
  },
  {
    "Gas Station Number": "TD028811",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AN DER B 96",
    "Postcode": 15938,
    "City": "GOLSSEN",
    "State": "Brandenburg",
    "Latitude": 51.9776,
    "Longitude": 13.5896,
    "Telephone Number": "035452-432",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9776,13.5896",
    "IsOpen24Hours": false,
    "id": "51.9776,13.5896"
  },
  {
    "Gas Station Number": "TD030411",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ROSTOCKER STR. 42",
    "Postcode": 17213,
    "City": "MALCHOW",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.481,
    "Longitude": 12.4211,
    "Telephone Number": "039932-12695",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.481,12.4211",
    "IsOpen24Hours": false,
    "id": "53.481,12.4211"
  },
  {
    "Gas Station Number": "TD040998",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "HARBURGER BERGE WEST/ A7",
    "Postcode": 21077,
    "City": "HAMBURG",
    "State": "Hamburg",
    "Latitude": 53.4244,
    "Longitude": 9.9629,
    "Telephone Number": "040-7001010",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.4244,9.9629",
    "IsOpen24Hours": false,
    "id": "53.4244,9.9629"
  },
  {
    "Gas Station Number": "TD181140",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "OFFENBURGER STR. 22",
    "Postcode": 79341,
    "City": "KENZINGEN",
    "State": "Baden-Württemberg",
    "Latitude": 48.1956,
    "Longitude": 7.7739,
    "Telephone Number": "07644-2557940",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.1956,7.7739",
    "IsOpen24Hours": false,
    "id": "48.1956,7.7739"
  },
  {
    "Gas Station Number": "TD000947",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PREINSTR. 126",
    "Postcode": 44265,
    "City": "DORTMUND",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4682,
    "Longitude": 7.4927,
    "Telephone Number": "0231-7246591",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4682,7.4927",
    "IsOpen24Hours": false,
    "id": "51.4682,7.4927"
  },
  {
    "Gas Station Number": "TD005678",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "CASTROPER STR. 123",
    "Postcode": 45665,
    "City": "RECKLINGHAUSEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.6046,
    "Longitude": 7.2147,
    "Telephone Number": "02361-484773",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6046,7.2147",
    "IsOpen24Hours": false,
    "id": "51.6046,7.2147"
  },
  {
    "Gas Station Number": "TD040089",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MELDORFER STR. 143",
    "Postcode": 25746,
    "City": "HEIDE",
    "State": "Schleswig-Holstein",
    "Latitude": 54.1844,
    "Longitude": 9.0904,
    "Telephone Number": "0481-69881555",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.1844,9.0904",
    "IsOpen24Hours": false,
    "id": "54.1844,9.0904"
  },
  {
    "Gas Station Number": "TD027391",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HERMANN-HESSE-STR. 1",
    "Postcode": 39118,
    "City": "MAGDEBURG",
    "State": "Sachsen-Anhalt",
    "Latitude": 52.0949,
    "Longitude": 11.6057,
    "Telephone Number": "0391-616397",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0949,11.6057",
    "IsOpen24Hours": false,
    "id": "52.0949,11.6057"
  },
  {
    "Gas Station Number": "TD030353",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AM WAETHERING NR. 2",
    "Postcode": 17335,
    "City": "STRASBURG",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.5021,
    "Longitude": 13.7339,
    "Telephone Number": "039753-22077",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.5021,13.7339",
    "IsOpen24Hours": false,
    "id": "53.5021,13.7339"
  },
  {
    "Gas Station Number": "TD041012",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "SOESTER BOERDE NORD, A44",
    "Postcode": 59494,
    "City": "SOEST",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5485,
    "Longitude": 8.1331,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5485,8.1331",
    "IsOpen24Hours": false,
    "id": "51.5485,8.1331"
  },
  {
    "Gas Station Number": "TD041756",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "EUTINGER STR.85",
    "Postcode": 75175,
    "City": "PFORZHEIM",
    "State": "Baden-Württemberg",
    "Latitude": 48.8985,
    "Longitude": 8.725,
    "Telephone Number": "07231-955235",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8985,8.725",
    "IsOpen24Hours": false,
    "id": "48.8985,8.725"
  },
  {
    "Gas Station Number": "TD037614",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HERMANN-LOENS-WEG 1",
    "Postcode": 25462,
    "City": "RELLINGEN",
    "State": "Schleswig-Holstein",
    "Latitude": 53.6387,
    "Longitude": 9.8935,
    "Telephone Number": "04101-2163907",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.6387,9.8935",
    "IsOpen24Hours": false,
    "id": "53.6387,9.8935"
  },
  {
    "Gas Station Number": "TD142370",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB BIGGENKOPF SUED",
    "Postcode": 34474,
    "City": "DIEMELSTADT",
    "State": "Hessen",
    "Latitude": 51.4995,
    "Longitude": 8.9834,
    "Telephone Number": "05642-98100",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4995,8.9834",
    "IsOpen24Hours": false,
    "id": "51.4995,8.9834"
  },
  {
    "Gas Station Number": "TD040824",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "HEIDESTR. 5",
    "Postcode": 39291,
    "City": "GENTHIN OT SCHOPSDORF",
    "State": "Sachsen-Anhalt",
    "Latitude": 52.2478,
    "Longitude": 12.2491,
    "Telephone Number": "039225395990",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2478,12.2491",
    "IsOpen24Hours": false,
    "id": "52.2478,12.2491"
  },
  {
    "Gas Station Number": "TD036475",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ALDINGER STR. 8",
    "Postcode": 71686,
    "City": "REMSECK",
    "State": "Baden-Württemberg",
    "Latitude": 48.8753,
    "Longitude": 9.2706,
    "Telephone Number": "07146-810047",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8753,9.2706",
    "IsOpen24Hours": false,
    "id": "48.8753,9.2706"
  },
  {
    "Gas Station Number": "TD040154",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "EUROPAALLEE 5A",
    "Postcode": 28309,
    "City": "BREMEN",
    "State": "Bremen",
    "Latitude": 53.0349,
    "Longitude": 8.8868,
    "Telephone Number": "0421-48536565",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.0349,8.8868",
    "IsOpen24Hours": false,
    "id": "53.0349,8.8868"
  },
  {
    "Gas Station Number": "TD029132",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GENTHINER STR. 59",
    "Postcode": 14712,
    "City": "RATHENOW",
    "State": "Brandenburg",
    "Latitude": 52.5994,
    "Longitude": 12.2971,
    "Telephone Number": "03385-512175",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5994,12.2971",
    "IsOpen24Hours": false,
    "id": "52.5994,12.2971"
  },
  {
    "Gas Station Number": "TD035345",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "COTTBUSER STRASSE 12 A",
    "Postcode": 3185,
    "City": "PEITZ",
    "State": "Brandenburg",
    "Latitude": 51.8532,
    "Longitude": 14.4065,
    "Telephone Number": "035601-80645",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8532,14.4065",
    "IsOpen24Hours": false,
    "id": "51.8532,14.4065"
  },
  {
    "Gas Station Number": "TD005322",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WERLER STR. 370",
    "Postcode": 59069,
    "City": "HAMM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.6459,
    "Longitude": 7.8428,
    "Telephone Number": "02381-51543",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6459,7.8428",
    "IsOpen24Hours": false,
    "id": "51.6459,7.8428"
  },
  {
    "Gas Station Number": "TD041004",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "NECKARBURG OST A81",
    "Postcode": 78661,
    "City": "DIETINGEN",
    "State": "Baden-Württemberg",
    "Latitude": 48.2058,
    "Longitude": 8.6251,
    "Telephone Number": "0741 8894",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.2058,8.6251",
    "IsOpen24Hours": false,
    "id": "48.2058,8.6251"
  },
  {
    "Gas Station Number": "TD038307",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "RUDOLF-DIESEL-STR. 3-5",
    "Postcode": 56566,
    "City": "NEUWIED",
    "State": "Rheinland-Pfalz",
    "Latitude": 50.4434,
    "Longitude": 7.4988,
    "Telephone Number": "02631-9427150",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.4434,7.4988",
    "IsOpen24Hours": false,
    "id": "50.4434,7.4988"
  },
  {
    "Gas Station Number": "TD036871",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AN DER BORNHOHL 56",
    "Postcode": 60437,
    "City": "FRANKFURT",
    "State": "Hessen",
    "Latitude": 50.1964,
    "Longitude": 8.6669,
    "Telephone Number": "069-5071584",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.1964,8.6669",
    "IsOpen24Hours": false,
    "id": "50.1964,8.6669"
  },
  {
    "Gas Station Number": "TD021444",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ROEDGENER STR.3",
    "Postcode": 4838,
    "City": "EILENBURG",
    "State": "Sachsen",
    "Latitude": 51.4581,
    "Longitude": 12.6146,
    "Telephone Number": "03423-602911",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4581,12.6146",
    "IsOpen24Hours": false,
    "id": "51.4581,12.6146"
  },
  {
    "Gas Station Number": "TD021329",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WIENER STR. 39",
    "Postcode": 1069,
    "City": "DRESDEN",
    "State": "Sachsen",
    "Latitude": 51.0361,
    "Longitude": 13.7485,
    "Telephone Number": "0351-46927235",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0361,13.7485",
    "IsOpen24Hours": false,
    "id": "51.0361,13.7485"
  },
  {
    "Gas Station Number": "TD041061",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "FREIENHUFENER ECK-OST/ A13",
    "Postcode": 1994,
    "City": "DROCHOW",
    "State": "Brandenburg",
    "Latitude": 51.5586,
    "Longitude": 13.9203,
    "Telephone Number": "0357543650",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5586,13.9203",
    "IsOpen24Hours": false,
    "id": "51.5586,13.9203"
  },
  {
    "Gas Station Number": "TD133000",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KAELBERAUER STR. 12",
    "Postcode": 63755,
    "City": "ALZENAU",
    "State": "Bayern",
    "Latitude": 50.0988,
    "Longitude": 9.1,
    "Telephone Number": "06023-1829",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.0988,9.1",
    "IsOpen24Hours": false,
    "id": "50.0988,9.1"
  },
  {
    "Gas Station Number": "TD032607",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AM KREUZSEE 1",
    "Postcode": 19412,
    "City": "BRUEEL",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.7294,
    "Longitude": 11.6909,
    "Telephone Number": "038483-22231",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.7294,11.6909",
    "IsOpen24Hours": false,
    "id": "53.7294,11.6909"
  },
  {
    "Gas Station Number": "TD038869",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "MINKELDONK 1",
    "Postcode": 47495,
    "City": "RHEINBERG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.534,
    "Longitude": 6.5809,
    "Telephone Number": "02843-9596456",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.534,6.5809",
    "IsOpen24Hours": false,
    "id": "51.534,6.5809"
  },
  {
    "Gas Station Number": "TD039412",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "OSTERHOFENER STR. 1",
    "Postcode": 93055,
    "City": "REGENSBURG",
    "State": "Bayern",
    "Latitude": 49.0132,
    "Longitude": 12.1397,
    "Telephone Number": "0941 59874567",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.0132,12.1397",
    "IsOpen24Hours": false,
    "id": "49.0132,12.1397"
  },
  {
    "Gas Station Number": "TD037762",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KOENIGSBRUECKER STR. 104",
    "Postcode": 1099,
    "City": "DRESDEN",
    "State": "Sachsen",
    "Latitude": 51.0831,
    "Longitude": 13.7616,
    "Telephone Number": "0351-8106054",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0831,13.7616",
    "IsOpen24Hours": false,
    "id": "51.0831,13.7616"
  },
  {
    "Gas Station Number": "TD006056",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "METZER STR. 123",
    "Postcode": 66117,
    "City": "SAARBRUECKEN",
    "State": "Saarland",
    "Latitude": 49.2095,
    "Longitude": 6.9637,
    "Telephone Number": "0681-5846632",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.2095,6.9637",
    "IsOpen24Hours": false,
    "id": "49.2095,6.9637"
  },
  {
    "Gas Station Number": "TD002808",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "OBERROEDERSTR.29",
    "Postcode": 64859,
    "City": "EPPERTSHAUSEN",
    "State": "Hessen",
    "Latitude": 49.9503,
    "Longitude": 8.8404,
    "Telephone Number": "06071-31359",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.9503,8.8404",
    "IsOpen24Hours": false,
    "id": "49.9503,8.8404"
  },
  {
    "Gas Station Number": "TD041194",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "RIEDLINGER STR. 52",
    "Postcode": 88400,
    "City": "BIBERACH",
    "State": "Baden-Württemberg",
    "Latitude": 48.0957,
    "Longitude": 9.7795,
    "Telephone Number": "07351-10022",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.0957,9.7795",
    "IsOpen24Hours": false,
    "id": "48.0957,9.7795"
  },
  {
    "Gas Station Number": "TD039016",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "WUELFERODE OST",
    "Postcode": 30539,
    "City": "HANNOVER",
    "State": "Niedersachsen",
    "Latitude": 52.3333,
    "Longitude": 9.8641,
    "Telephone Number": "0511-9545544",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3333,9.8641",
    "IsOpen24Hours": false,
    "id": "52.3333,9.8641"
  },
  {
    "Gas Station Number": "TD035808",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AN DER B 247",
    "Postcode": 99947,
    "City": "SCHOENSTEDT",
    "State": "Thüringen",
    "Latitude": 51.1248,
    "Longitude": 10.5816,
    "Telephone Number": "036022-91919",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1248,10.5816",
    "IsOpen24Hours": false,
    "id": "51.1248,10.5816"
  },
  {
    "Gas Station Number": "TD130580",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AN DER BUNDESSTR. 19",
    "Postcode": 97234,
    "City": "REICHENBERG/ALBERTSHAUSEN",
    "State": "Bayern",
    "Latitude": 49.692,
    "Longitude": 9.9419,
    "Telephone Number": "09334-355",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.692,9.9419",
    "IsOpen24Hours": false,
    "id": "49.692,9.9419"
  },
  {
    "Gas Station Number": "TD039941",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MAINBERGER STR. 22",
    "Postcode": 97422,
    "City": "SCHWEINFURT",
    "State": "Bayern",
    "Latitude": 50.0488,
    "Longitude": 10.2455,
    "Telephone Number": "09721-3709616",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.0488,10.2455",
    "IsOpen24Hours": false,
    "id": "50.0488,10.2455"
  },
  {
    "Gas Station Number": "TD037119",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BERLINER STR. 54 A",
    "Postcode": 38104,
    "City": "BRAUNSCHWEIG",
    "State": "Niedersachsen",
    "Latitude": 52.2805,
    "Longitude": 10.5737,
    "Telephone Number": "0531-374141",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2805,10.5737",
    "IsOpen24Hours": false,
    "id": "52.2805,10.5737"
  },
  {
    "Gas Station Number": "TD040030",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "ANDREAS MEYER STRASSE 63",
    "Postcode": 22113,
    "City": "HAMBURG",
    "State": "Hamburg",
    "Latitude": 53.5124,
    "Longitude": 10.0889,
    "Telephone Number": "040-53022628",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.5124,10.0889",
    "IsOpen24Hours": false,
    "id": "53.5124,10.0889"
  },
  {
    "Gas Station Number": "TD121320",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DUERENER STR. 38",
    "Postcode": 52372,
    "City": "KREUZAU",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.7488,
    "Longitude": 6.491,
    "Telephone Number": "02422-503234",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.7488,6.491",
    "IsOpen24Hours": false,
    "id": "50.7488,6.491"
  },
  {
    "Gas Station Number": "TD036988",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "CUXHAVENER STR. 380",
    "Postcode": 21149,
    "City": "HAMBURG",
    "State": "Hamburg",
    "Latitude": 53.473,
    "Longitude": 9.8468,
    "Telephone Number": "040-70380510",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.473,9.8468",
    "IsOpen24Hours": false,
    "id": "53.473,9.8468"
  },
  {
    "Gas Station Number": "TD032680",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SONNEBERGER STR. 86",
    "Postcode": 96523,
    "City": "STEINACH",
    "State": "Thüringen",
    "Latitude": 50.4223,
    "Longitude": 11.1661,
    "Telephone Number": "036762-31911",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.4223,11.1661",
    "IsOpen24Hours": false,
    "id": "50.4223,11.1661"
  },
  {
    "Gas Station Number": "TD170290",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BAMBERGER STR. 6",
    "Postcode": 96117,
    "City": "MEMMELSDORF",
    "State": "Bayern",
    "Latitude": 49.9314,
    "Longitude": 10.9509,
    "Telephone Number": "0951-44291",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.9314,10.9509",
    "IsOpen24Hours": false,
    "id": "49.9314,10.9509"
  },
  {
    "Gas Station Number": "TD030601",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MUECHELNER STR. 04",
    "Postcode": 6242,
    "City": "BRAUNSBEDRA",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.2837,
    "Longitude": 11.8752,
    "Telephone Number": "034633-22213",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2837,11.8752",
    "IsOpen24Hours": false,
    "id": "51.2837,11.8752"
  },
  {
    "Gas Station Number": "TD039768",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAUPTSTR. 56",
    "Postcode": 31860,
    "City": "EMMERTHAL",
    "State": "Niedersachsen",
    "Latitude": 52.0483,
    "Longitude": 9.39,
    "Telephone Number": "05155-9199900",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0483,9.39",
    "IsOpen24Hours": false,
    "id": "52.0483,9.39"
  },
  {
    "Gas Station Number": "TD182090",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "CALWER STR. 304",
    "Postcode": 72218,
    "City": "WILDBERG",
    "State": "Baden-Württemberg",
    "Latitude": 48.6367,
    "Longitude": 8.7468,
    "Telephone Number": "07054-365",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.6367,8.7468",
    "IsOpen24Hours": false,
    "id": "48.6367,8.7468"
  },
  {
    "Gas Station Number": "TD004309",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AHMSER STR. 95",
    "Postcode": 32052,
    "City": "HERFORD",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.1024,
    "Longitude": 8.6821,
    "Telephone Number": "05221-71087",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1024,8.6821",
    "IsOpen24Hours": false,
    "id": "52.1024,8.6821"
  },
  {
    "Gas Station Number": "TD170130",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WERNBERGER STR. 34",
    "Postcode": 92536,
    "City": "PFREIMD",
    "State": "Bayern",
    "Latitude": 49.4994,
    "Longitude": 12.1846,
    "Telephone Number": "09606-91277",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.4994,12.1846",
    "IsOpen24Hours": false,
    "id": "49.4994,12.1846"
  },
  {
    "Gas Station Number": "TD030809",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AM PLATZ DER JUGEND 21",
    "Postcode": 6406,
    "City": "BERNBURG",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.8041,
    "Longitude": 11.7385,
    "Telephone Number": "03471-350353",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8041,11.7385",
    "IsOpen24Hours": false,
    "id": "51.8041,11.7385"
  },
  {
    "Gas Station Number": "TD023309",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WEIMARISCHE STR. 31",
    "Postcode": 99438,
    "City": "BAD BERKA",
    "State": "Thüringen",
    "Latitude": 50.9038,
    "Longitude": 11.2789,
    "Telephone Number": "036458-42107",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9038,11.2789",
    "IsOpen24Hours": false,
    "id": "50.9038,11.2789"
  },
  {
    "Gas Station Number": "TD027359",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BISMARKER STR.82",
    "Postcode": 39606,
    "City": "OSTERBURG",
    "State": "Sachsen-Anhalt",
    "Latitude": 52.7872,
    "Longitude": 11.7426,
    "Telephone Number": "03937-82928",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.7872,11.7426",
    "IsOpen24Hours": false,
    "id": "52.7872,11.7426"
  },
  {
    "Gas Station Number": "TD028910",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHWERINSTR. 9-12",
    "Postcode": 3149,
    "City": "FORST",
    "State": "Brandenburg",
    "Latitude": 51.729,
    "Longitude": 14.6286,
    "Telephone Number": "03562-8334",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.729,14.6286",
    "IsOpen24Hours": false,
    "id": "51.729,14.6286"
  },
  {
    "Gas Station Number": "TD019323",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHOELLERSTR. 155",
    "Postcode": 52351,
    "City": "DUEREN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.8132,
    "Longitude": 6.4884,
    "Telephone Number": "02421-14252",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8132,6.4884",
    "IsOpen24Hours": false,
    "id": "50.8132,6.4884"
  },
  {
    "Gas Station Number": "TD038661",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "TROSSINGER STR. 41",
    "Postcode": 78609,
    "City": "TUNINGEN",
    "State": "Baden-Württemberg",
    "Latitude": 48.0347,
    "Longitude": 8.61,
    "Telephone Number": "07464-529989-0",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.0347,8.61",
    "IsOpen24Hours": false,
    "id": "48.0347,8.61"
  },
  {
    "Gas Station Number": "TD038042",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "ROENTGENSTR. 1",
    "Postcode": 63526,
    "City": "ERLENSEE",
    "State": "Hessen",
    "Latitude": 50.1824,
    "Longitude": 9.007,
    "Telephone Number": "06183-8007810",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.1824,9.007",
    "IsOpen24Hours": false,
    "id": "50.1824,9.007"
  },
  {
    "Gas Station Number": "TD006817",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FRANKFURTER STR. 92",
    "Postcode": 65830,
    "City": "KRIFTEL",
    "State": "Hessen",
    "Latitude": 50.0887,
    "Longitude": 8.4776,
    "Telephone Number": "06192-46296",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.0887,8.4776",
    "IsOpen24Hours": false,
    "id": "50.0887,8.4776"
  },
  {
    "Gas Station Number": "TD111940",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "UNTERSTRASSE 100",
    "Postcode": 44892,
    "City": "BOCHUM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4699,
    "Longitude": 7.3069,
    "Telephone Number": "0234-9272073",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4699,7.3069",
    "IsOpen24Hours": false,
    "id": "51.4699,7.3069"
  },
  {
    "Gas Station Number": "TD035683",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AM KESSELTAL 4",
    "Postcode": 55129,
    "City": "MAINZ-EBERSHEIM",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.9224,
    "Longitude": 8.2658,
    "Telephone Number": "06136-954195",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.9224,8.2658",
    "IsOpen24Hours": false,
    "id": "49.9224,8.2658"
  },
  {
    "Gas Station Number": "TD020917",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BAUTZNER STR. 72",
    "Postcode": 1099,
    "City": "DRESDEN",
    "State": "Sachsen",
    "Latitude": 51.0644,
    "Longitude": 13.7636,
    "Telephone Number": "0351-8049180",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0644,13.7636",
    "IsOpen24Hours": false,
    "id": "51.0644,13.7636"
  },
  {
    "Gas Station Number": "TD036848",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHIFFDORFER CHAUSSEE 32",
    "Postcode": 27574,
    "City": "BREMERHAVEN",
    "State": "Bremen",
    "Latitude": 53.5304,
    "Longitude": 8.6059,
    "Telephone Number": "0471-35001",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.5304,8.6059",
    "IsOpen24Hours": false,
    "id": "53.5304,8.6059"
  },
  {
    "Gas Station Number": "TD000966",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KAPELLENSTR. 29",
    "Postcode": 50997,
    "City": "KOELN RONDORF",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.8745,
    "Longitude": 6.9498,
    "Telephone Number": "02233-23640",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8745,6.9498",
    "IsOpen24Hours": false,
    "id": "50.8745,6.9498"
  },
  {
    "Gas Station Number": "TD000946",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ALTENDERNER STR.90",
    "Postcode": 44329,
    "City": "DORTMUND",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5693,
    "Longitude": 7.5114,
    "Telephone Number": "0231-7289051",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5693,7.5114",
    "IsOpen24Hours": false,
    "id": "51.5693,7.5114"
  },
  {
    "Gas Station Number": "TD131270",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BERLINER ALLEE 12",
    "Postcode": 63225,
    "City": "LANGEN",
    "State": "Hessen",
    "Latitude": 49.9923,
    "Longitude": 8.6495,
    "Telephone Number": "06103-71010",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.9923,8.6495",
    "IsOpen24Hours": false,
    "id": "49.9923,8.6495"
  },
  {
    "Gas Station Number": "TD006908",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "UBIERSTR. 8",
    "Postcode": 53173,
    "City": "BONN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.6945,
    "Longitude": 7.1618,
    "Telephone Number": "0228-363298",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.6945,7.1618",
    "IsOpen24Hours": false,
    "id": "50.6945,7.1618"
  },
  {
    "Gas Station Number": "TD000076",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAUPTSTR. 279",
    "Postcode": 44892,
    "City": "BOCHUM-LANGENDREER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4647,
    "Longitude": 7.3212,
    "Telephone Number": "0234-286628",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4647,7.3212",
    "IsOpen24Hours": false,
    "id": "51.4647,7.3212"
  },
  {
    "Gas Station Number": "TD034827",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AM KEESBOOM 2",
    "Postcode": 19258,
    "City": "BOIZENBURG",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.3858,
    "Longitude": 10.7352,
    "Telephone Number": "038847-38152",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.3858,10.7352",
    "IsOpen24Hours": false,
    "id": "53.3858,10.7352"
  },
  {
    "Gas Station Number": "TD033993",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FUERSTENBERGER STR. 1-3",
    "Postcode": 33142,
    "City": "BUEREN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5484,
    "Longitude": 8.5638,
    "Telephone Number": "02951-2902",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5484,8.5638",
    "IsOpen24Hours": false,
    "id": "51.5484,8.5638"
  },
  {
    "Gas Station Number": "TD004986",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KEMNADER-STR. 14",
    "Postcode": 44795,
    "City": "BOCHUM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4399,
    "Longitude": 7.2143,
    "Telephone Number": "0234-472286",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4399,7.2143",
    "IsOpen24Hours": false,
    "id": "51.4399,7.2143"
  },
  {
    "Gas Station Number": "TD001834",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KARLSRUHER STR. 61A",
    "Postcode": 76351,
    "City": "LINKENHEIM-HOCHSTETTEN",
    "State": "Baden-Württemberg",
    "Latitude": 49.1253,
    "Longitude": 8.409,
    "Telephone Number": "07247-4271",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.1253,8.409",
    "IsOpen24Hours": false,
    "id": "49.1253,8.409"
  },
  {
    "Gas Station Number": "TD150450",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AUTOHOF A14 POSTSTRASSE 3",
    "Postcode": 4158,
    "City": "LEIPZIG",
    "State": "Sachsen",
    "Latitude": 51.4136,
    "Longitude": 12.3082,
    "Telephone Number": "0341-4616662",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4136,12.3082",
    "IsOpen24Hours": false,
    "id": "51.4136,12.3082"
  },
  {
    "Gas Station Number": "TD036608",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BAMBERGER STR. 59/61",
    "Postcode": 91456,
    "City": "DIESPECK",
    "State": "Bayern",
    "Latitude": 49.6008,
    "Longitude": 10.6257,
    "Telephone Number": "09161-885858",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.6008,10.6257",
    "IsOpen24Hours": false,
    "id": "49.6008,10.6257"
  },
  {
    "Gas Station Number": "TD000239",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ALTER UENTROPER W.62",
    "Postcode": 59071,
    "City": "HAMM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.691,
    "Longitude": 7.8695,
    "Telephone Number": "02381-80632",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.691,7.8695",
    "IsOpen24Hours": false,
    "id": "51.691,7.8695"
  },
  {
    "Gas Station Number": "TD041145",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "IM RIEDLE 1",
    "Postcode": 74572,
    "City": "BLAUFELDEN",
    "State": "Baden-Württemberg",
    "Latitude": 49.2959,
    "Longitude": 9.9787,
    "Telephone Number": "07953-6249980",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.2959,9.9787",
    "IsOpen24Hours": false,
    "id": "49.2959,9.9787"
  },
  {
    "Gas Station Number": "TD041939",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "IM BIRKFELD 8",
    "Postcode": 91595,
    "City": "BURGOBERBACH",
    "State": "Bayern",
    "Latitude": 49.2423,
    "Longitude": 10.6061,
    "Telephone Number": "098054269946",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.2423,10.6061",
    "IsOpen24Hours": false,
    "id": "49.2423,10.6061"
  },
  {
    "Gas Station Number": "TD028589",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "JUETERBOGER CH. 8",
    "Postcode": 15936,
    "City": "DAHME",
    "State": "Brandenburg",
    "Latitude": 51.8687,
    "Longitude": 13.4131,
    "Telephone Number": "035451-8308",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8687,13.4131",
    "IsOpen24Hours": false,
    "id": "51.8687,13.4131"
  },
  {
    "Gas Station Number": "TD038141",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "GLINDENBERGER WEG 3",
    "Postcode": 39126,
    "City": "MAGDEBURG",
    "State": "Sachsen-Anhalt",
    "Latitude": 52.2113,
    "Longitude": 11.6705,
    "Telephone Number": "0391-59818744",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2113,11.6705",
    "IsOpen24Hours": false,
    "id": "52.2113,11.6705"
  },
  {
    "Gas Station Number": "TD028357",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BERLINER CH.",
    "Postcode": 15749,
    "City": "MITTENWALDE",
    "State": "Brandenburg",
    "Latitude": 52.2712,
    "Longitude": 13.5377,
    "Telephone Number": "033764-20389",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2712,13.5377",
    "IsOpen24Hours": false,
    "id": "52.2712,13.5377"
  },
  {
    "Gas Station Number": "TD034660",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "NORDRING 4",
    "Postcode": 6536,
    "City": "SUEDHARZ OT ROSSLA",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.4631,
    "Longitude": 11.0557,
    "Telephone Number": "034651-2548",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4631,11.0557",
    "IsOpen24Hours": false,
    "id": "51.4631,11.0557"
  },
  {
    "Gas Station Number": "TD000385",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "VALDERSWEG 99",
    "Postcode": 52399,
    "City": "MERZENICH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.8198,
    "Longitude": 6.533,
    "Telephone Number": "02421-393988",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8198,6.533",
    "IsOpen24Hours": false,
    "id": "50.8198,6.533"
  },
  {
    "Gas Station Number": "TD037556",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SINZIGER STR. 15",
    "Postcode": 53424,
    "City": "REMAGEN",
    "State": "Rheinland-Pfalz",
    "Latitude": 50.5729,
    "Longitude": 7.2353,
    "Telephone Number": "02642-22045",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.5729,7.2353",
    "IsOpen24Hours": false,
    "id": "50.5729,7.2353"
  },
  {
    "Gas Station Number": "TD036707",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AMBERGER STRASSE 37",
    "Postcode": 92431,
    "City": "NEUNBURG V.W.",
    "State": "Bayern",
    "Latitude": 49.3461,
    "Longitude": 12.3736,
    "Telephone Number": "09672-3493",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.3461,12.3736",
    "IsOpen24Hours": false,
    "id": "49.3461,12.3736"
  },
  {
    "Gas Station Number": "TD022855",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ARNSTAEDTER STR. 36",
    "Postcode": 99096,
    "City": "ERFURT",
    "State": "Thüringen",
    "Latitude": 50.9629,
    "Longitude": 11.0334,
    "Telephone Number": "0361-3460738",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9629,11.0334",
    "IsOpen24Hours": false,
    "id": "50.9629,11.0334"
  },
  {
    "Gas Station Number": "TD035626",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WIESBADENER STR. 77",
    "Postcode": 65510,
    "City": "IDSTEIN",
    "State": "Hessen",
    "Latitude": 50.2168,
    "Longitude": 8.254,
    "Telephone Number": "06126-953888",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.2168,8.254",
    "IsOpen24Hours": false,
    "id": "50.2168,8.254"
  },
  {
    "Gas Station Number": "TD034553",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "CHEMNITZER STR. 28f",
    "Postcode": 9648,
    "City": "MITTWEIDA",
    "State": "Sachsen",
    "Latitude": 50.9774,
    "Longitude": 12.9813,
    "Telephone Number": "03727-611392",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9774,12.9813",
    "IsOpen24Hours": false,
    "id": "50.9774,12.9813"
  },
  {
    "Gas Station Number": "TD033159",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "USEDOMER STR. 1",
    "Postcode": 1968,
    "City": "SENFTENBERG",
    "State": "Brandenburg",
    "Latitude": 51.51,
    "Longitude": 13.9818,
    "Telephone Number": "03573-61085",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.51,13.9818",
    "IsOpen24Hours": false,
    "id": "51.51,13.9818"
  },
  {
    "Gas Station Number": "TD032235",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LEIPZIGER STR. 152",
    "Postcode": 14929,
    "City": "TREUENBRIETZEN",
    "State": "Brandenburg",
    "Latitude": 52.0874,
    "Longitude": 12.8495,
    "Telephone Number": "033748-15429",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0874,12.8495",
    "IsOpen24Hours": false,
    "id": "52.0874,12.8495"
  },
  {
    "Gas Station Number": "TD041624",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WORPHAUSER LANDSTR. 38",
    "Postcode": 28865,
    "City": "LILIENTHAL",
    "State": "Niedersachsen",
    "Latitude": 53.1992,
    "Longitude": 8.9355,
    "Telephone Number": "04792-688",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.1992,8.9355",
    "IsOpen24Hours": false,
    "id": "53.1992,8.9355"
  },
  {
    "Gas Station Number": "TD034538",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "TROTHAER STRASSE 40",
    "Postcode": 6118,
    "City": "HALLE",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.5192,
    "Longitude": 11.955,
    "Telephone Number": "0345-5234122",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5192,11.955",
    "IsOpen24Hours": false,
    "id": "51.5192,11.955"
  },
  {
    "Gas Station Number": "TD035766",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "OSTERLANGE 1",
    "Postcode": 99189,
    "City": "ELXLEBEN",
    "State": "Thüringen",
    "Latitude": 51.048,
    "Longitude": 10.9421,
    "Telephone Number": "036201-86263",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.048,10.9421",
    "IsOpen24Hours": false,
    "id": "51.048,10.9421"
  },
  {
    "Gas Station Number": "TD039321",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LOESSNITZER STR. 87",
    "Postcode": 8280,
    "City": "AUE",
    "State": "Sachsen",
    "Latitude": 50.5965,
    "Longitude": 12.7072,
    "Telephone Number": "03771-2765355",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.5965,12.7072",
    "IsOpen24Hours": false,
    "id": "50.5965,12.7072"
  },
  {
    "Gas Station Number": "TD007567",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DRESDENER STR. 95",
    "Postcode": 4758,
    "City": "OSCHATZ",
    "State": "Sachsen",
    "Latitude": 51.291,
    "Longitude": 13.1332,
    "Telephone Number": "03435-929730",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.291,13.1332",
    "IsOpen24Hours": false,
    "id": "51.291,13.1332"
  },
  {
    "Gas Station Number": "TD124090",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MARIENBORNER STR.204",
    "Postcode": 57074,
    "City": "SIEGEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.8764,
    "Longitude": 8.0396,
    "Telephone Number": "0271-62105",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8764,8.0396",
    "IsOpen24Hours": false,
    "id": "50.8764,8.0396"
  },
  {
    "Gas Station Number": "TD002220",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HOCKENHEIMER STR. 108",
    "Postcode": 68775,
    "City": "KETSCH",
    "State": "Baden-Württemberg",
    "Latitude": 49.3604,
    "Longitude": 8.5269,
    "Telephone Number": "06202-692190",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.3604,8.5269",
    "IsOpen24Hours": false,
    "id": "49.3604,8.5269"
  },
  {
    "Gas Station Number": "TD112820",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DORTMUNDER STR. 174",
    "Postcode": 59077,
    "City": "HAMM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.6608,
    "Longitude": 7.7361,
    "Telephone Number": "02381-461785",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6608,7.7361",
    "IsOpen24Hours": false,
    "id": "51.6608,7.7361"
  },
  {
    "Gas Station Number": "TD039776",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "NUERNBERGER STR. 61",
    "Postcode": 86720,
    "City": "NOERDLINGEN",
    "State": "Bayern",
    "Latitude": 48.8608,
    "Longitude": 10.5022,
    "Telephone Number": "09081-6011",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8608,10.5022",
    "IsOpen24Hours": false,
    "id": "48.8608,10.5022"
  },
  {
    "Gas Station Number": "TD037077",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHWERINER CHAUSSEE 45",
    "Postcode": 19370,
    "City": "PARCHIM",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.4395,
    "Longitude": 11.8454,
    "Telephone Number": "03871-267297",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.4395,11.8454",
    "IsOpen24Hours": false,
    "id": "53.4395,11.8454"
  },
  {
    "Gas Station Number": "TD000091",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "EMMERICHER STRASSE 99",
    "Postcode": 47533,
    "City": "KLEVE",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8008,
    "Longitude": 6.1626,
    "Telephone Number": "02821-980082",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8008,6.1626",
    "IsOpen24Hours": false,
    "id": "51.8008,6.1626"
  },
  {
    "Gas Station Number": "TD035311",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MILOWER LANDSTR.35b",
    "Postcode": 14712,
    "City": "RATHENOW",
    "State": "Brandenburg",
    "Latitude": 52.594,
    "Longitude": 12.3384,
    "Telephone Number": "03385-511431",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.594,12.3384",
    "IsOpen24Hours": false,
    "id": "52.594,12.3384"
  },
  {
    "Gas Station Number": "TD003939",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAART 225",
    "Postcode": 24539,
    "City": "NEUMUENSTER",
    "State": "Schleswig-Holstein",
    "Latitude": 54.0609,
    "Longitude": 10.0109,
    "Telephone Number": "04321-71272",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.0609,10.0109",
    "IsOpen24Hours": false,
    "id": "54.0609,10.0109"
  },
  {
    "Gas Station Number": "TD038752",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "JESINGHAUSER STR. 110",
    "Postcode": 42389,
    "City": "WUPPERTAL",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.2828,
    "Longitude": 7.2561,
    "Telephone Number": "0202-70516555",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2828,7.2561",
    "IsOpen24Hours": false,
    "id": "51.2828,7.2561"
  },
  {
    "Gas Station Number": "TD181200",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "VOERSTETTER STR. 40",
    "Postcode": 79194,
    "City": "GUNDELFINGEN",
    "State": "Baden-Württemberg",
    "Latitude": 48.047,
    "Longitude": 7.8635,
    "Telephone Number": "0761-585928",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.047,7.8635",
    "IsOpen24Hours": false,
    "id": "48.047,7.8635"
  },
  {
    "Gas Station Number": "TD037788",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AUTOHOF BAB 7 / AS LUTTERBERG",
    "Postcode": 34355,
    "City": "STAUFENBERG",
    "State": "Niedersachsen",
    "Latitude": 51.3707,
    "Longitude": 9.6333,
    "Telephone Number": "05543-302710",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3707,9.6333",
    "IsOpen24Hours": false,
    "id": "51.3707,9.6333"
  },
  {
    "Gas Station Number": "TD038521",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "GEWERBEPARK 14",
    "Postcode": 84513,
    "City": "ERHARTING",
    "State": "Bayern",
    "Latitude": 48.2729,
    "Longitude": 12.5505,
    "Telephone Number": "08631-9895512",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.2729,12.5505",
    "IsOpen24Hours": false,
    "id": "48.2729,12.5505"
  },
  {
    "Gas Station Number": "TD000026",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KIRCHSTR. 1",
    "Postcode": 47198,
    "City": "DUISBURG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4439,
    "Longitude": 6.676,
    "Telephone Number": "02066-34083",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4439,6.676",
    "IsOpen24Hours": false,
    "id": "51.4439,6.676"
  },
  {
    "Gas Station Number": "TD124240",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SAARBRUECKER STR. 67",
    "Postcode": 66359,
    "City": "BOUS",
    "State": "Saarland",
    "Latitude": 49.2746,
    "Longitude": 6.7967,
    "Telephone Number": "06834-3630",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.2746,6.7967",
    "IsOpen24Hours": false,
    "id": "49.2746,6.7967"
  },
  {
    "Gas Station Number": "TD035014",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ADLERGESTELL 543",
    "Postcode": 12527,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.4143,
    "Longitude": 13.573,
    "Telephone Number": "030-67489152",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4143,13.573",
    "IsOpen24Hours": false,
    "id": "52.4143,13.573"
  },
  {
    "Gas Station Number": "TD041871",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AN DER A 8",
    "Postcode": 89340,
    "City": "LEIPHEIM",
    "State": "Bayern",
    "Latitude": 48.4389,
    "Longitude": 10.2129,
    "Telephone Number": "082212780280",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.4389,10.2129",
    "IsOpen24Hours": false,
    "id": "48.4389,10.2129"
  },
  {
    "Gas Station Number": "TD037861",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "NUERNBERGER STR. 1A",
    "Postcode": 91452,
    "City": "WILHERMSDORF",
    "State": "Bayern",
    "Latitude": 49.4834,
    "Longitude": 10.7255,
    "Telephone Number": "09102-993950",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.4834,10.7255",
    "IsOpen24Hours": false,
    "id": "49.4834,10.7255"
  },
  {
    "Gas Station Number": "TD040857",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "DURLACHER ALLEE 102",
    "Postcode": 76137,
    "City": "KARLSRUHE",
    "State": "Baden-Württemberg",
    "Latitude": 49.0047,
    "Longitude": 8.4387,
    "Telephone Number": "0721 20440908",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.0047,8.4387",
    "IsOpen24Hours": false,
    "id": "49.0047,8.4387"
  },
  {
    "Gas Station Number": "TD029629",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BERLINER STR. 31",
    "Postcode": 19348,
    "City": "PERLEBERG",
    "State": "Brandenburg",
    "Latitude": 53.0703,
    "Longitude": 11.8765,
    "Telephone Number": "03876-612301",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.0703,11.8765",
    "IsOpen24Hours": false,
    "id": "53.0703,11.8765"
  },
  {
    "Gas Station Number": "TD039149",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHOENEBERGER STR. 17",
    "Postcode": 12103,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.4701,
    "Longitude": 13.372,
    "Telephone Number": "030-43208844",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4701,13.372",
    "IsOpen24Hours": false,
    "id": "52.4701,13.372"
  },
  {
    "Gas Station Number": "TD000806",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB VIERWINDEN-NORD / A46",
    "Postcode": 41516,
    "City": "GREVENBROICH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.13,
    "Longitude": 6.6041,
    "Telephone Number": "02182-1468",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.13,6.6041",
    "IsOpen24Hours": false,
    "id": "51.13,6.6041"
  },
  {
    "Gas Station Number": "TD162560",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAUPTSTR. 40",
    "Postcode": 85659,
    "City": "FORSTERN",
    "State": "Bayern",
    "Latitude": 48.1866,
    "Longitude": 11.9753,
    "Telephone Number": "08124-527059",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.1866,11.9753",
    "IsOpen24Hours": false,
    "id": "48.1866,11.9753"
  },
  {
    "Gas Station Number": "TD002287",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GERMERSHEIMER STR. 2A",
    "Postcode": 67354,
    "City": "ROEMERBERG",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.2993,
    "Longitude": 8.4106,
    "Telephone Number": "06232-82636",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.2993,8.4106",
    "IsOpen24Hours": false,
    "id": "49.2993,8.4106"
  },
  {
    "Gas Station Number": "TD032581",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ALTER LANDWEG 5",
    "Postcode": 17258,
    "City": "FELDBERG",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.3317,
    "Longitude": 13.4311,
    "Telephone Number": "039831-20034",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.3317,13.4311",
    "IsOpen24Hours": false,
    "id": "53.3317,13.4311"
  },
  {
    "Gas Station Number": "TD042101",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SIEGERLAND OST /A45",
    "Postcode": 57258,
    "City": "FREUDENBERG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.8769,
    "Longitude": 7.9482,
    "Telephone Number": "02734-5471",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8769,7.9482",
    "IsOpen24Hours": false,
    "id": "50.8769,7.9482"
  },
  {
    "Gas Station Number": "TD002550",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PIRMASENSER STR. 93",
    "Postcode": 66497,
    "City": "CONTWIG",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.2484,
    "Longitude": 7.4431,
    "Telephone Number": "06332-5833",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.2484,7.4431",
    "IsOpen24Hours": false,
    "id": "49.2484,7.4431"
  },
  {
    "Gas Station Number": "TD035295",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AN DER LANDSTR. 73",
    "Postcode": 6429,
    "City": "NIENBURG",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.8401,
    "Longitude": 11.7536,
    "Telephone Number": "034721-22838",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8401,11.7536",
    "IsOpen24Hours": false,
    "id": "51.8401,11.7536"
  },
  {
    "Gas Station Number": "TD000945",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HEIDELBERGER STR. 55",
    "Postcode": 64285,
    "City": "DARMSTADT",
    "State": "Hessen",
    "Latitude": 49.8614,
    "Longitude": 8.6468,
    "Telephone Number": "06151-316459",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.8614,8.6468",
    "IsOpen24Hours": false,
    "id": "49.8614,8.6468"
  },
  {
    "Gas Station Number": "TD040212",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "EGGLKOFENSTR. 2",
    "Postcode": 84453,
    "City": "MUEHLDORF",
    "State": "Bayern",
    "Latitude": 48.2564,
    "Longitude": 12.5337,
    "Telephone Number": "08631-378121",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.2564,12.5337",
    "IsOpen24Hours": false,
    "id": "48.2564,12.5337"
  },
  {
    "Gas Station Number": "TD000765",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "THEODORSTR. 282",
    "Postcode": 40472,
    "City": "DUESSELDORF",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.2719,
    "Longitude": 6.8164,
    "Telephone Number": "0211-6549816",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2719,6.8164",
    "IsOpen24Hours": false,
    "id": "51.2719,6.8164"
  },
  {
    "Gas Station Number": "TD000810",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PRAEMIENSTR. 30",
    "Postcode": 52223,
    "City": "STOLBERG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.7645,
    "Longitude": 6.2172,
    "Telephone Number": "02402-24678",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.7645,6.2172",
    "IsOpen24Hours": false,
    "id": "50.7645,6.2172"
  },
  {
    "Gas Station Number": "TD034686",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DESSAUER STR. 13",
    "Postcode": 6886,
    "City": "WITTENBERG",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.8657,
    "Longitude": 12.6151,
    "Telephone Number": "03491-666986",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8657,12.6151",
    "IsOpen24Hours": false,
    "id": "51.8657,12.6151"
  },
  {
    "Gas Station Number": "TD190180",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "STAATSSTR. 47A",
    "Postcode": 67483,
    "City": "EDESHEIM",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.2658,
    "Longitude": 8.1336,
    "Telephone Number": "06323-2876",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.2658,8.1336",
    "IsOpen24Hours": false,
    "id": "49.2658,8.1336"
  },
  {
    "Gas Station Number": "TD192710",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ALTE STEINER STR. 43",
    "Postcode": 75239,
    "City": "EISINGEN",
    "State": "Baden-Württemberg",
    "Latitude": 48.9494,
    "Longitude": 8.6648,
    "Telephone Number": "07232-8994",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.9494,8.6648",
    "IsOpen24Hours": false,
    "id": "48.9494,8.6648"
  },
  {
    "Gas Station Number": "TD039982",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "NORDALLEE 2",
    "Postcode": 51147,
    "City": "KOELN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.8818,
    "Longitude": 7.1131,
    "Telephone Number": "02203-591642",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8818,7.1131",
    "IsOpen24Hours": false,
    "id": "50.8818,7.1131"
  },
  {
    "Gas Station Number": "TD035410",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LUDWIG-ERHARD-STR. 36",
    "Postcode": 4103,
    "City": "LEIPZIG",
    "State": "Sachsen",
    "Latitude": 51.3432,
    "Longitude": 12.3955,
    "Telephone Number": "0341-6810411",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3432,12.3955",
    "IsOpen24Hours": false,
    "id": "51.3432,12.3955"
  },
  {
    "Gas Station Number": "TD021386",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "W.-KAULISCH-STR.49",
    "Postcode": 1844,
    "City": "NEUSTADT",
    "State": "Sachsen",
    "Latitude": 51.0288,
    "Longitude": 14.2086,
    "Telephone Number": "03596-604238",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0288,14.2086",
    "IsOpen24Hours": false,
    "id": "51.0288,14.2086"
  },
  {
    "Gas Station Number": "TD038240",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ELLY-BEINHORN-RING 2 A",
    "Postcode": 12529,
    "City": "SCHOENEFELD",
    "State": "Berlin",
    "Latitude": 52.3704,
    "Longitude": 13.5272,
    "Telephone Number": "030-609156230",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3704,13.5272",
    "IsOpen24Hours": false,
    "id": "52.3704,13.5272"
  },
  {
    "Gas Station Number": "TD041434",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WERTHEIMER STR. 50",
    "Postcode": 97941,
    "City": "TAUBERBISCHOFSHEIM",
    "State": "Baden-Württemberg",
    "Latitude": 49.6302,
    "Longitude": 9.6661,
    "Telephone Number": "0934195164",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.6302,9.6661",
    "IsOpen24Hours": false,
    "id": "49.6302,9.6661"
  },
  {
    "Gas Station Number": "TD041426",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "DEUBACHER STR.1",
    "Postcode": 97922,
    "City": "LAUDA-KOENIGSHOFEN",
    "State": "Baden-Württemberg",
    "Latitude": 49.5413,
    "Longitude": 9.7366,
    "Telephone Number": "0934365440",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.5413,9.7366",
    "IsOpen24Hours": false,
    "id": "49.5413,9.7366"
  },
  {
    "Gas Station Number": "TD031948",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FERDINAND-EGELINSKI-STR. 3",
    "Postcode": 17419,
    "City": "AHLBECK",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.9341,
    "Longitude": 14.1945,
    "Telephone Number": "038378-28415",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.9341,14.1945",
    "IsOpen24Hours": false,
    "id": "53.9341,14.1945"
  },
  {
    "Gas Station Number": "TD124250",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "NEUE JUELICHER STR. 45",
    "Postcode": 52353,
    "City": "DUEREN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.8123,
    "Longitude": 6.4745,
    "Telephone Number": "02421-941417",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8123,6.4745",
    "IsOpen24Hours": false,
    "id": "50.8123,6.4745"
  },
  {
    "Gas Station Number": "TD001552",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "BAHNHOFSTR. 23",
    "Postcode": 73257,
    "City": "KOENGEN",
    "State": "Baden-Württemberg",
    "Latitude": 48.6775,
    "Longitude": 9.3671,
    "Telephone Number": "07024-84995",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.6775,9.3671",
    "IsOpen24Hours": false,
    "id": "48.6775,9.3671"
  },
  {
    "Gas Station Number": "TD041608",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SERVAISSTRASSE 1",
    "Postcode": 54293,
    "City": "TRIER- EHRANG",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.8064,
    "Longitude": 6.6854,
    "Telephone Number": "06519680914",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.8064,6.6854",
    "IsOpen24Hours": false,
    "id": "49.8064,6.6854"
  },
  {
    "Gas Station Number": "TD041863",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KAISERREICH-STR. 1",
    "Postcode": 83088,
    "City": "KIEFERSFELDEN",
    "State": "Bayern",
    "Latitude": 47.6186,
    "Longitude": 12.1986,
    "Telephone Number": "080334959712",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.6186,12.1986",
    "IsOpen24Hours": false,
    "id": "47.6186,12.1986"
  },
  {
    "Gas Station Number": "TD000984",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LINDLARER STR.143",
    "Postcode": 51491,
    "City": "OVERATH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.9627,
    "Longitude": 7.2444,
    "Telephone Number": "02204-74445",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9627,7.2444",
    "IsOpen24Hours": false,
    "id": "50.9627,7.2444"
  },
  {
    "Gas Station Number": "TD033035",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GEWERBEPARK 3",
    "Postcode": 18546,
    "City": "SASSNITZ",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 54.5133,
    "Longitude": 13.6031,
    "Telephone Number": "038392-32620",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.5133,13.6031",
    "IsOpen24Hours": false,
    "id": "54.5133,13.6031"
  },
  {
    "Gas Station Number": "TD037093",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GRABENSTR. 2",
    "Postcode": 19061,
    "City": "SCHWERIN",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.6012,
    "Longitude": 11.3911,
    "Telephone Number": "0385-6666666",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.6012,11.3911",
    "IsOpen24Hours": false,
    "id": "53.6012,11.3911"
  },
  {
    "Gas Station Number": "TD191330",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WATTENHEIMER STR. 28",
    "Postcode": 67310,
    "City": "HETTENLEIDELHEIM",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.5316,
    "Longitude": 8.0661,
    "Telephone Number": "06322-9799176",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.5316,8.0661",
    "IsOpen24Hours": false,
    "id": "49.5316,8.0661"
  },
  {
    "Gas Station Number": "TD000846",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BERLINER STR. 25",
    "Postcode": 51377,
    "City": "LEVERKUSEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.0431,
    "Longitude": 7.0723,
    "Telephone Number": "02148-909415",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0431,7.0723",
    "IsOpen24Hours": false,
    "id": "51.0431,7.0723"
  },
  {
    "Gas Station Number": "TD034165",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LANDSBERGER ALLEE 376",
    "Postcode": 12681,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.5354,
    "Longitude": 13.5228,
    "Telephone Number": "030-5437728",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5354,13.5228",
    "IsOpen24Hours": false,
    "id": "52.5354,13.5228"
  },
  {
    "Gas Station Number": "TD005819",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAGENER STR. 239",
    "Postcode": 57223,
    "City": "KREUZTAL",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.9881,
    "Longitude": 7.9593,
    "Telephone Number": "02732-5531848",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9881,7.9593",
    "IsOpen24Hours": false,
    "id": "50.9881,7.9593"
  },
  {
    "Gas Station Number": "TD028407",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BRANDENBURGER STR.",
    "Postcode": 14806,
    "City": "BAD BELZIG",
    "State": "Brandenburg",
    "Latitude": 52.1505,
    "Longitude": 12.6005,
    "Telephone Number": "033841-35772",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1505,12.6005",
    "IsOpen24Hours": false,
    "id": "52.1505,12.6005"
  },
  {
    "Gas Station Number": "TD025494",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "UNTERE SCHLOSSSTRASSE 37",
    "Postcode": 9573,
    "City": "AUGUSTUSBURG",
    "State": "Sachsen",
    "Latitude": 50.8183,
    "Longitude": 13.1009,
    "Telephone Number": "037291-6567",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8183,13.1009",
    "IsOpen24Hours": false,
    "id": "50.8183,13.1009"
  },
  {
    "Gas Station Number": "TD035188",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WISMARSCHE STR.153 A",
    "Postcode": 23936,
    "City": "GREVESMUEHLEN",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.8654,
    "Longitude": 11.2095,
    "Telephone Number": "03881-2729",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.8654,11.2095",
    "IsOpen24Hours": false,
    "id": "53.8654,11.2095"
  },
  {
    "Gas Station Number": "TD033019",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAMBURGER STR.44",
    "Postcode": 1067,
    "City": "DRESDEN",
    "State": "Sachsen",
    "Latitude": 51.0613,
    "Longitude": 13.6938,
    "Telephone Number": "0351-4942558",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0613,13.6938",
    "IsOpen24Hours": false,
    "id": "51.0613,13.6938"
  },
  {
    "Gas Station Number": "TD033779",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LUETJENBURGER STR. 61",
    "Postcode": 24306,
    "City": "PLOEN",
    "State": "Schleswig-Holstein",
    "Latitude": 54.1701,
    "Longitude": 10.4356,
    "Telephone Number": "04522-749000",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.1701,10.4356",
    "IsOpen24Hours": false,
    "id": "54.1701,10.4356"
  },
  {
    "Gas Station Number": "TD021220",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LEIPZIGER STR. 12",
    "Postcode": 1589,
    "City": "RIESA",
    "State": "Sachsen",
    "Latitude": 51.2901,
    "Longitude": 13.2906,
    "Telephone Number": "03525-731747",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2901,13.2906",
    "IsOpen24Hours": false,
    "id": "51.2901,13.2906"
  },
  {
    "Gas Station Number": "TD035949",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAUPTSTR. 257",
    "Postcode": 67065,
    "City": "LUDWIGSHAFEN",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.4502,
    "Longitude": 8.4235,
    "Telephone Number": "0621-542476",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.4502,8.4235",
    "IsOpen24Hours": false,
    "id": "49.4502,8.4235"
  },
  {
    "Gas Station Number": "TD020313",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BLOCKDAMMWEG 6",
    "Postcode": 10317,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.4829,
    "Longitude": 13.5022,
    "Telephone Number": "030-5099806",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4829,13.5022",
    "IsOpen24Hours": false,
    "id": "52.4829,13.5022"
  },
  {
    "Gas Station Number": "TD031856",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MARKTFLECKEN 17",
    "Postcode": 17498,
    "City": "NEUENKIRCHEN",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 54.1172,
    "Longitude": 13.3637,
    "Telephone Number": "03834-2340",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.1172,13.3637",
    "IsOpen24Hours": false,
    "id": "54.1172,13.3637"
  },
  {
    "Gas Station Number": "TD000683",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB EIFEL-OST / A1",
    "Postcode": 54533,
    "City": "NIEDEROEFFLINGEN",
    "State": "Rheinland-Pfalz",
    "Latitude": 50.0692,
    "Longitude": 6.8819,
    "Telephone Number": "06574-200",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.0692,6.8819",
    "IsOpen24Hours": false,
    "id": "50.0692,6.8819"
  },
  {
    "Gas Station Number": "TD004150",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "NEUKOELLNER STR. 294",
    "Postcode": 12357,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.4222,
    "Longitude": 13.4871,
    "Telephone Number": "030-6613037",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4222,13.4871",
    "IsOpen24Hours": false,
    "id": "52.4222,13.4871"
  },
  {
    "Gas Station Number": "TD023937",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HOFER STR. 38",
    "Postcode": 7926,
    "City": "GEFELL",
    "State": "Thüringen",
    "Latitude": 50.4352,
    "Longitude": 11.8556,
    "Telephone Number": "036649-82258",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.4352,11.8556",
    "IsOpen24Hours": false,
    "id": "50.4352,11.8556"
  },
  {
    "Gas Station Number": "TD040006",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "GREIFSWALDER CHAUSSEE 122",
    "Postcode": 18439,
    "City": "STRALSUND",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 54.3009,
    "Longitude": 13.0947,
    "Telephone Number": "03831-2031038",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.3009,13.0947",
    "IsOpen24Hours": false,
    "id": "54.3009,13.0947"
  },
  {
    "Gas Station Number": "TD133480",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ROEMERSTR. 1",
    "Postcode": 64560,
    "City": "RIEDSTADT-GODDELAU",
    "State": "Hessen",
    "Latitude": 49.8347,
    "Longitude": 8.4841,
    "Telephone Number": "06158-184056",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.8347,8.4841",
    "IsOpen24Hours": false,
    "id": "49.8347,8.4841"
  },
  {
    "Gas Station Number": "TD036459",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MARTIN-LUTHER-STR. 11",
    "Postcode": 71636,
    "City": "LUDWIGSBURG",
    "State": "Baden-Württemberg",
    "Latitude": 48.8961,
    "Longitude": 9.18,
    "Telephone Number": "07141-923171",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8961,9.18",
    "IsOpen24Hours": false,
    "id": "48.8961,9.18"
  },
  {
    "Gas Station Number": "TD131290",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ALTE FRANKFURTER STR. 92",
    "Postcode": 61118,
    "City": "BAD VILBEL",
    "State": "Hessen",
    "Latitude": 50.165,
    "Longitude": 8.7243,
    "Telephone Number": "06101-85770",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.165,8.7243",
    "IsOpen24Hours": false,
    "id": "50.165,8.7243"
  },
  {
    "Gas Station Number": "TD029876",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WOLDEGKER STR. 40",
    "Postcode": 17036,
    "City": "NEUBRANDENBURG",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.5562,
    "Longitude": 13.3128,
    "Telephone Number": "0395-37937301",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.5562,13.3128",
    "IsOpen24Hours": false,
    "id": "53.5562,13.3128"
  },
  {
    "Gas Station Number": "TD002121",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LEIPHEIMER STR. 60",
    "Postcode": 89233,
    "City": "NEU-ULM",
    "State": "Bayern",
    "Latitude": 48.4056,
    "Longitude": 10.0381,
    "Telephone Number": "0731-714728",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.4056,10.0381",
    "IsOpen24Hours": false,
    "id": "48.4056,10.0381"
  },
  {
    "Gas Station Number": "TD039362",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MOOSACHER STR.36",
    "Postcode": 80809,
    "City": "MUENCHEN",
    "State": "Bayern",
    "Latitude": 48.1865,
    "Longitude": 11.556,
    "Telephone Number": "089/35756644",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.1865,11.556",
    "IsOpen24Hours": false,
    "id": "48.1865,11.556"
  },
  {
    "Gas Station Number": "TD034926",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WEISSENBERGER STR. 10",
    "Postcode": 2708,
    "City": "LOEBAU",
    "State": "Sachsen",
    "Latitude": 51.1033,
    "Longitude": 14.6702,
    "Telephone Number": "03585-860090",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1033,14.6702",
    "IsOpen24Hours": false,
    "id": "51.1033,14.6702"
  },
  {
    "Gas Station Number": "TD039214",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BONNER STR. 71",
    "Postcode": 53332,
    "City": "BORNHEIM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.7563,
    "Longitude": 7.0086,
    "Telephone Number": "02222-9890200",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.7563,7.0086",
    "IsOpen24Hours": false,
    "id": "50.7563,7.0086"
  },
  {
    "Gas Station Number": "TD021352",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GOERLITZER STR.36",
    "Postcode": 2906,
    "City": "NIESKY",
    "State": "Sachsen",
    "Latitude": 51.2876,
    "Longitude": 14.8199,
    "Telephone Number": "03588-205610",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2876,14.8199",
    "IsOpen24Hours": false,
    "id": "51.2876,14.8199"
  },
  {
    "Gas Station Number": "TD037291",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "VLAEMISCHE STR. 40",
    "Postcode": 49688,
    "City": "LASTRUP",
    "State": "Niedersachsen",
    "Latitude": 52.7973,
    "Longitude": 7.8656,
    "Telephone Number": "04472-1450",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.7973,7.8656",
    "IsOpen24Hours": false,
    "id": "52.7973,7.8656"
  },
  {
    "Gas Station Number": "TD041277",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HERMANNSTEINERSTR. 30",
    "Postcode": 35576,
    "City": "WETZLAR",
    "State": "Hessen",
    "Latitude": 50.5683,
    "Longitude": 8.4997,
    "Telephone Number": "06441-36333",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.5683,8.4997",
    "IsOpen24Hours": false,
    "id": "50.5683,8.4997"
  },
  {
    "Gas Station Number": "TD031864",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "U.-VON-HUTTEN-STR. 7a",
    "Postcode": 18069,
    "City": "ROSTOCK",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 54.0954,
    "Longitude": 12.0831,
    "Telephone Number": "0381-8083459",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.0954,12.0831",
    "IsOpen24Hours": false,
    "id": "54.0954,12.0831"
  },
  {
    "Gas Station Number": "TD026625",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KLINGENTHALER STR. 3",
    "Postcode": 8261,
    "City": "SCHOENECK",
    "State": "Sachsen",
    "Latitude": 50.395,
    "Longitude": 12.3406,
    "Telephone Number": "037464-82595",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.395,12.3406",
    "IsOpen24Hours": false,
    "id": "50.395,12.3406"
  },
  {
    "Gas Station Number": "TD032714",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "VELTENER STR. 22",
    "Postcode": 16761,
    "City": "HENNIGSDORF",
    "State": "Brandenburg",
    "Latitude": 52.6539,
    "Longitude": 13.2043,
    "Telephone Number": "03302-810824",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.6539,13.2043",
    "IsOpen24Hours": false,
    "id": "52.6539,13.2043"
  },
  {
    "Gas Station Number": "TD021337",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BODENBACHER STR. 64",
    "Postcode": 1277,
    "City": "DRESDEN",
    "State": "Sachsen",
    "Latitude": 51.0294,
    "Longitude": 13.7907,
    "Telephone Number": "0351-2561098",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0294,13.7907",
    "IsOpen24Hours": false,
    "id": "51.0294,13.7907"
  },
  {
    "Gas Station Number": "TD000658",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "RHEINBERGER STR. 15",
    "Postcode": 46509,
    "City": "XANTEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.654,
    "Longitude": 6.4707,
    "Telephone Number": "02801-5360",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.654,6.4707",
    "IsOpen24Hours": false,
    "id": "51.654,6.4707"
  },
  {
    "Gas Station Number": "TD000931",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DUERENER STR. 308",
    "Postcode": 52249,
    "City": "ESCHWEILER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.8197,
    "Longitude": 6.2921,
    "Telephone Number": "02403-21572",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8197,6.2921",
    "IsOpen24Hours": false,
    "id": "50.8197,6.2921"
  },
  {
    "Gas Station Number": "TD032326",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB OST/A 11",
    "Postcode": 16244,
    "City": "SCHORFHEIDE-BUCKOWSEE",
    "State": "Brandenburg",
    "Latitude": 52.8833,
    "Longitude": 13.7047,
    "Telephone Number": "033363-46204",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.8833,13.7047",
    "IsOpen24Hours": false,
    "id": "52.8833,13.7047"
  },
  {
    "Gas Station Number": "TD000170",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KURHESSENSTR. 111",
    "Postcode": 60431,
    "City": "FRANKFURT",
    "State": "Hessen",
    "Latitude": 50.148,
    "Longitude": 8.6565,
    "Telephone Number": "069-512131",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.148,8.6565",
    "IsOpen24Hours": false,
    "id": "50.148,8.6565"
  },
  {
    "Gas Station Number": "TD030619",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "THOMAS-MUENTZER-STR. 5",
    "Postcode": 6217,
    "City": "MERSEBURG",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.3449,
    "Longitude": 11.988,
    "Telephone Number": "03461-503727",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3449,11.988",
    "IsOpen24Hours": false,
    "id": "51.3449,11.988"
  },
  {
    "Gas Station Number": "TD035105",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ELSTERWERDAER STR. 35",
    "Postcode": 1990,
    "City": "ORTRAND",
    "State": "Brandenburg",
    "Latitude": 51.3756,
    "Longitude": 13.7496,
    "Telephone Number": "035755-53819",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3756,13.7496",
    "IsOpen24Hours": false,
    "id": "51.3756,13.7496"
  },
  {
    "Gas Station Number": "TD036491",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "STUTTGARTER STR. 62",
    "Postcode": 73614,
    "City": "SCHORNDORF",
    "State": "Baden-Württemberg",
    "Latitude": 48.8057,
    "Longitude": 9.508,
    "Telephone Number": "07181-75965",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8057,9.508",
    "IsOpen24Hours": false,
    "id": "48.8057,9.508"
  },
  {
    "Gas Station Number": "TD028878",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FRANKFURTER STR. 37",
    "Postcode": 15907,
    "City": "LUEBBEN",
    "State": "Brandenburg",
    "Latitude": 51.9431,
    "Longitude": 13.9135,
    "Telephone Number": "03546-3106",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9431,13.9135",
    "IsOpen24Hours": false,
    "id": "51.9431,13.9135"
  },
  {
    "Gas Station Number": "TD040923",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "BREMIGSWIESE 2",
    "Postcode": 51674,
    "City": "WIEHL",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.9441,
    "Longitude": 7.5738,
    "Telephone Number": "02262-7084871",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9441,7.5738",
    "IsOpen24Hours": false,
    "id": "50.9441,7.5738"
  },
  {
    "Gas Station Number": "TD041772",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HOMBURGER STR. 50",
    "Postcode": 61184,
    "City": "KARBEN",
    "State": "Hessen",
    "Latitude": 50.2309,
    "Longitude": 8.7704,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.2309,8.7704",
    "IsOpen24Hours": false,
    "id": "50.2309,8.7704"
  },
  {
    "Gas Station Number": "TD031815",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "STRALSUNDER CH. 18",
    "Postcode": 18528,
    "City": "BERGEN",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 54.4075,
    "Longitude": 13.4181,
    "Telephone Number": "03838-22354",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.4075,13.4181",
    "IsOpen24Hours": false,
    "id": "54.4075,13.4181"
  },
  {
    "Gas Station Number": "TD035675",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "INDUSTRIESTRASSE 2",
    "Postcode": 56412,
    "City": "HEILIGENROTH",
    "State": "Rheinland-Pfalz",
    "Latitude": 50.4515,
    "Longitude": 7.8485,
    "Telephone Number": "02602-8387954",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.4515,7.8485",
    "IsOpen24Hours": false,
    "id": "50.4515,7.8485"
  },
  {
    "Gas Station Number": "TD000702",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "APFELWEG 2",
    "Postcode": 38871,
    "City": "ILSENBURG",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.8731,
    "Longitude": 10.6922,
    "Telephone Number": "039452-49262",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8731,10.6922",
    "IsOpen24Hours": false,
    "id": "51.8731,10.6922"
  },
  {
    "Gas Station Number": "TD041202",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHORNDORFER STR. 10",
    "Postcode": 71384,
    "City": "WEINSTADT",
    "State": "Baden-Württemberg",
    "Latitude": 48.8141,
    "Longitude": 9.3691,
    "Telephone Number": "0715161243",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8141,9.3691",
    "IsOpen24Hours": false,
    "id": "48.8141,9.3691"
  },
  {
    "Gas Station Number": "TD041947",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BRUCHFELD 94",
    "Postcode": 47809,
    "City": "KREFELD",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.3444,
    "Longitude": 6.6283,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3444,6.6283",
    "IsOpen24Hours": false,
    "id": "51.3444,6.6283"
  },
  {
    "Gas Station Number": "TD039800",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AUGSBURGER STRASSE 16",
    "Postcode": 86609,
    "City": "DONAUWOERTH",
    "State": "Bayern",
    "Latitude": 48.7108,
    "Longitude": 10.7878,
    "Telephone Number": "0906-4494",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.7108,10.7878",
    "IsOpen24Hours": false,
    "id": "48.7108,10.7878"
  },
  {
    "Gas Station Number": "TD027235",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "COLBITZER STR.",
    "Postcode": 39326,
    "City": "WOLMIRSTEDT",
    "State": "Sachsen-Anhalt",
    "Latitude": 52.2572,
    "Longitude": 11.6289,
    "Telephone Number": "039201-21376",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2572,11.6289",
    "IsOpen24Hours": false,
    "id": "52.2572,11.6289"
  },
  {
    "Gas Station Number": "TD037564",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ADENAUER ALLEE 10",
    "Postcode": 25524,
    "City": "ITZEHOE",
    "State": "Schleswig-Holstein",
    "Latitude": 53.9217,
    "Longitude": 9.5124,
    "Telephone Number": "04821-3333",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.9217,9.5124",
    "IsOpen24Hours": false,
    "id": "53.9217,9.5124"
  },
  {
    "Gas Station Number": "TD035642",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AUTOHOF WILHELM-MAYBACH-STR. 3",
    "Postcode": 89312,
    "City": "GUENZBURG",
    "State": "Bayern",
    "Latitude": 48.4276,
    "Longitude": 10.2882,
    "Telephone Number": "08221-2044013",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.4276,10.2882",
    "IsOpen24Hours": false,
    "id": "48.4276,10.2882"
  },
  {
    "Gas Station Number": "TD000746",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HARTH.HAUPTSTR. 50-52",
    "Postcode": 70794,
    "City": "FILDERSTADT-HARTH",
    "State": "Baden-Württemberg",
    "Latitude": 48.6516,
    "Longitude": 9.2463,
    "Telephone Number": "07158-982131",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.6516,9.2463",
    "IsOpen24Hours": false,
    "id": "48.6516,9.2463"
  },
  {
    "Gas Station Number": "TD001669",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BRUCHSALER STR. 15",
    "Postcode": 76698,
    "City": "UBSTADT",
    "State": "Baden-Württemberg",
    "Latitude": 49.1557,
    "Longitude": 8.6268,
    "Telephone Number": "07251-967011",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.1557,8.6268",
    "IsOpen24Hours": false,
    "id": "49.1557,8.6268"
  },
  {
    "Gas Station Number": "TD041467",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WEILRODER STR. 1",
    "Postcode": 61389,
    "City": "SCHMITTEN",
    "State": "Hessen",
    "Latitude": 50.2893,
    "Longitude": 8.4612,
    "Telephone Number": "060842377",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.2893,8.4612",
    "IsOpen24Hours": false,
    "id": "50.2893,8.4612"
  },
  {
    "Gas Station Number": "TD025163",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "INNERE HERLASGRUENER STR. 17",
    "Postcode": 8233,
    "City": "TREUEN",
    "State": "Sachsen",
    "Latitude": 50.5442,
    "Longitude": 12.2992,
    "Telephone Number": "037468-2639",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.5442,12.2992",
    "IsOpen24Hours": false,
    "id": "50.5442,12.2992"
  },
  {
    "Gas Station Number": "TD000874",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AM STEINERNEN KREUZ 1-3",
    "Postcode": 96110,
    "City": "SCHESSLITZ",
    "State": "Bayern",
    "Latitude": 49.9801,
    "Longitude": 11.0435,
    "Telephone Number": "0954-2942020",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.9801,11.0435",
    "IsOpen24Hours": false,
    "id": "49.9801,11.0435"
  },
  {
    "Gas Station Number": "TD162640",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AUGSBURGER STR. 29",
    "Postcode": 89331,
    "City": "BURGAU",
    "State": "Bayern",
    "Latitude": 48.4256,
    "Longitude": 10.4139,
    "Telephone Number": "08222-410209",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.4256,10.4139",
    "IsOpen24Hours": false,
    "id": "48.4256,10.4139"
  },
  {
    "Gas Station Number": "TD036947",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WOLFHAGER STRASSE 276",
    "Postcode": 34128,
    "City": "KASSEL",
    "State": "Hessen",
    "Latitude": 51.3314,
    "Longitude": 9.4557,
    "Telephone Number": "0561-882422",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3314,9.4557",
    "IsOpen24Hours": false,
    "id": "51.3314,9.4557"
  },
  {
    "Gas Station Number": "TD040386",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DEHMER STR. 52",
    "Postcode": 32549,
    "City": "BAD OEYNHAUSEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.2337,
    "Longitude": 8.8334,
    "Telephone Number": "05731-4989381",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2337,8.8334",
    "IsOpen24Hours": false,
    "id": "52.2337,8.8334"
  },
  {
    "Gas Station Number": "TD033506",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MERSEBURGER STR. 359",
    "Postcode": 6132,
    "City": "HALLE",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.4383,
    "Longitude": 11.9848,
    "Telephone Number": "0345-7809911",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4383,11.9848",
    "IsOpen24Hours": false,
    "id": "51.4383,11.9848"
  },
  {
    "Gas Station Number": "TD040527",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AUF DER BROOKE 2",
    "Postcode": 27404,
    "City": "ZEVEN-ELSDORF",
    "State": "Niedersachsen",
    "Latitude": 53.2306,
    "Longitude": 9.3429,
    "Telephone Number": "04286-9266416",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.2306,9.3429",
    "IsOpen24Hours": false,
    "id": "53.2306,9.3429"
  },
  {
    "Gas Station Number": "TD000974",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BURBACHER STR. 50",
    "Postcode": 66117,
    "City": "SAARBRUECKEN",
    "State": "Saarland",
    "Latitude": 49.2364,
    "Longitude": 6.9426,
    "Telephone Number": "0681-703332",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.2364,6.9426",
    "IsOpen24Hours": false,
    "id": "49.2364,6.9426"
  },
  {
    "Gas Station Number": "TD000369",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB A5/MAHLBERG-OST",
    "Postcode": 77972,
    "City": "MAHLBERG",
    "State": "Baden-Württemberg",
    "Latitude": 48.3066,
    "Longitude": 7.791,
    "Telephone Number": "07825-849631",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.3066,7.791",
    "IsOpen24Hours": false,
    "id": "48.3066,7.791"
  },
  {
    "Gas Station Number": "TD035261",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FELDSTR. 159-161",
    "Postcode": 45699,
    "City": "HERTEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.6072,
    "Longitude": 7.1242,
    "Telephone Number": "02366-937333",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6072,7.1242",
    "IsOpen24Hours": false,
    "id": "51.6072,7.1242"
  },
  {
    "Gas Station Number": "TD028506",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BERLINER STR. 107",
    "Postcode": 14547,
    "City": "BEELITZ",
    "State": "Brandenburg",
    "Latitude": 52.2524,
    "Longitude": 12.9861,
    "Telephone Number": "033204-35508",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2524,12.9861",
    "IsOpen24Hours": false,
    "id": "52.2524,12.9861"
  },
  {
    "Gas Station Number": "TD037937",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB WESTSEITE",
    "Postcode": 91620,
    "City": "OHRENBACH",
    "State": "Bayern",
    "Latitude": 49.4852,
    "Longitude": 10.2104,
    "Telephone Number": "09865-986113",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.4852,10.2104",
    "IsOpen24Hours": false,
    "id": "49.4852,10.2104"
  },
  {
    "Gas Station Number": "TD041046",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "KOECKERN OST / BAB 9",
    "Postcode": 6794,
    "City": "SANDERSDORF-BREHNA",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.6064,
    "Longitude": 12.1856,
    "Telephone Number": "034954-39237",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6064,12.1856",
    "IsOpen24Hours": false,
    "id": "51.6064,12.1856"
  },
  {
    "Gas Station Number": "TD029447",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BRANDISERSTR.1",
    "Postcode": 4916,
    "City": "SCHOENEWALDE",
    "State": "Brandenburg",
    "Latitude": 51.8134,
    "Longitude": 13.209,
    "Telephone Number": "0353626060",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8134,13.209",
    "IsOpen24Hours": false,
    "id": "51.8134,13.209"
  },
  {
    "Gas Station Number": "TD038836",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "SANDBREITE 1",
    "Postcode": 6869,
    "City": "COSWIG",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.889,
    "Longitude": 12.4032,
    "Telephone Number": "034903472774",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.889,12.4032",
    "IsOpen24Hours": false,
    "id": "51.889,12.4032"
  },
  {
    "Gas Station Number": "TD038455",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BINGERSTR. 27",
    "Postcode": 55131,
    "City": "MAINZ",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.9977,
    "Longitude": 8.2567,
    "Telephone Number": "06131-1432888",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.9977,8.2567",
    "IsOpen24Hours": false,
    "id": "49.9977,8.2567"
  },
  {
    "Gas Station Number": "TD038067",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AM AUTOHOF 1",
    "Postcode": 6188,
    "City": "LANDSBERG",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.5448,
    "Longitude": 12.0143,
    "Telephone Number": "034604/24690",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5448,12.0143",
    "IsOpen24Hours": false,
    "id": "51.5448,12.0143"
  },
  {
    "Gas Station Number": "TD039313",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "STREITSTR. 84",
    "Postcode": 13587,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.5542,
    "Longitude": 13.2074,
    "Telephone Number": "030/74782807",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5542,13.2074",
    "IsOpen24Hours": false,
    "id": "52.5542,13.2074"
  },
  {
    "Gas Station Number": "TD143120",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KALTHOFER FELD 1",
    "Postcode": 58640,
    "City": "ISERLOHN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.426,
    "Longitude": 7.6756,
    "Telephone Number": "02371-44346",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.426,7.6756",
    "IsOpen24Hours": false,
    "id": "51.426,7.6756"
  },
  {
    "Gas Station Number": "TD030072",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAGENOWER CHAUSSEE 1",
    "Postcode": 19243,
    "City": "WITTENBURG",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.5057,
    "Longitude": 11.0799,
    "Telephone Number": "038852-50132",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.5057,11.0799",
    "IsOpen24Hours": false,
    "id": "53.5057,11.0799"
  },
  {
    "Gas Station Number": "TD029686",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "TODDINER CH. 5",
    "Postcode": 19230,
    "City": "HAGENOW",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.4366,
    "Longitude": 11.1747,
    "Telephone Number": "03883-722207",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.4366,11.1747",
    "IsOpen24Hours": false,
    "id": "53.4366,11.1747"
  },
  {
    "Gas Station Number": "TD005124",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BURGALTENDORFER STR.3",
    "Postcode": 45529,
    "City": "HATTINGEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4056,
    "Longitude": 7.1392,
    "Telephone Number": "02324-40756",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4056,7.1392",
    "IsOpen24Hours": false,
    "id": "51.4056,7.1392"
  },
  {
    "Gas Station Number": "TD037838",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB SAMERBERG/SUED A8",
    "Postcode": 83101,
    "City": "ROHRDORF",
    "State": "Bayern",
    "Latitude": 47.802,
    "Longitude": 12.1754,
    "Telephone Number": "08032-98930",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.802,12.1754",
    "IsOpen24Hours": false,
    "id": "47.802,12.1754"
  },
  {
    "Gas Station Number": "TD000961",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KARLSRUHER STR. 82",
    "Postcode": 76139,
    "City": "KARLSRUHE",
    "State": "Baden-Württemberg",
    "Latitude": 49.0253,
    "Longitude": 8.4489,
    "Telephone Number": "0721-681250",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.0253,8.4489",
    "IsOpen24Hours": false,
    "id": "49.0253,8.4489"
  },
  {
    "Gas Station Number": "TD037820",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HOEHERWEG 202",
    "Postcode": 40233,
    "City": "DUESSELDORF",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.2221,
    "Longitude": 6.8203,
    "Telephone Number": "0211-5072244",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2221,6.8203",
    "IsOpen24Hours": false,
    "id": "51.2221,6.8203"
  },
  {
    "Gas Station Number": "TD141020",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BUECKEBERGSTR. 1",
    "Postcode": 31137,
    "City": "HILDESHEIM",
    "State": "Niedersachsen",
    "Latitude": 52.1539,
    "Longitude": 9.9292,
    "Telephone Number": "05121-43777",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1539,9.9292",
    "IsOpen24Hours": false,
    "id": "52.1539,9.9292"
  },
  {
    "Gas Station Number": "TD191230",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SAARBRUECKER STR. 1b",
    "Postcode": 66299,
    "City": "FRIEDRICHSTHAL",
    "State": "Saarland",
    "Latitude": 49.317,
    "Longitude": 7.0877,
    "Telephone Number": "06897-89213",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.317,7.0877",
    "IsOpen24Hours": false,
    "id": "49.317,7.0877"
  },
  {
    "Gas Station Number": "TD028423",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AN DER B 102 NR. 2",
    "Postcode": 14727,
    "City": "PREMNITZ",
    "State": "Brandenburg",
    "Latitude": 52.5247,
    "Longitude": 12.3582,
    "Telephone Number": "03386-280770",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5247,12.3582",
    "IsOpen24Hours": false,
    "id": "52.5247,12.3582"
  },
  {
    "Gas Station Number": "TD031765",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WERFTSTR. 13",
    "Postcode": 18439,
    "City": "STRALSUND",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 54.3022,
    "Longitude": 13.1006,
    "Telephone Number": "03831-292749",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.3022,13.1006",
    "IsOpen24Hours": false,
    "id": "54.3022,13.1006"
  },
  {
    "Gas Station Number": "TD000477",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "RHEINSTR. 38",
    "Postcode": 67574,
    "City": "OSTHOFEN",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.7047,
    "Longitude": 8.3318,
    "Telephone Number": "06242-6258",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.7047,8.3318",
    "IsOpen24Hours": false,
    "id": "49.7047,8.3318"
  },
  {
    "Gas Station Number": "TD002790",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HANAUER LANDSTR.114",
    "Postcode": 63791,
    "City": "KARLSTEIN",
    "State": "Bayern",
    "Latitude": 50.037,
    "Longitude": 9.0361,
    "Telephone Number": "06188-990599/98",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.037,9.0361",
    "IsOpen24Hours": false,
    "id": "50.037,9.0361"
  },
  {
    "Gas Station Number": "TD041392",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LANGENDORFER STR. 52",
    "Postcode": 56564,
    "City": "NEUWIED",
    "State": "Rheinland-Pfalz",
    "Latitude": 50.434,
    "Longitude": 7.4551,
    "Telephone Number": "02631-9549451",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.434,7.4551",
    "IsOpen24Hours": false,
    "id": "50.434,7.4551"
  },
  {
    "Gas Station Number": "TD022954",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB SUEDSEITE / A4",
    "Postcode": 99428,
    "City": "GRAMMETAL, OT EICHELBORN",
    "State": "Thüringen",
    "Latitude": 50.9482,
    "Longitude": 11.1939,
    "Telephone Number": "036209432950",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9482,11.1939",
    "IsOpen24Hours": false,
    "id": "50.9482,11.1939"
  },
  {
    "Gas Station Number": "TD034702",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB NORDSEITE / A4",
    "Postcode": 99428,
    "City": "GRAMMETAL, OT EICHELBORN",
    "State": "Thüringen",
    "Latitude": 50.95,
    "Longitude": 11.1974,
    "Telephone Number": "036209-40228",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.95,11.1974",
    "IsOpen24Hours": false,
    "id": "50.95,11.1974"
  },
  {
    "Gas Station Number": "TD038950",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "NIEVENHEIM WEST A57",
    "Postcode": 41542,
    "City": "DORMAGEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.1234,
    "Longitude": 6.7959,
    "Telephone Number": "02133-70550",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1234,6.7959",
    "IsOpen24Hours": false,
    "id": "51.1234,6.7959"
  },
  {
    "Gas Station Number": "TD034454",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FRITZ-REUTER-STRASSE 21",
    "Postcode": 9423,
    "City": "GELENAU",
    "State": "Sachsen",
    "Latitude": 50.6996,
    "Longitude": 12.9397,
    "Telephone Number": "037297-5055",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.6996,12.9397",
    "IsOpen24Hours": false,
    "id": "50.6996,12.9397"
  },
  {
    "Gas Station Number": "TD034447",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AEUSS.WOLKENSTEINER STR. 3",
    "Postcode": 9496,
    "City": "MARIENBERG",
    "State": "Sachsen",
    "Latitude": 50.65,
    "Longitude": 13.1564,
    "Telephone Number": "03735-22485",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.65,13.1564",
    "IsOpen24Hours": false,
    "id": "50.65,13.1564"
  },
  {
    "Gas Station Number": "TD038844",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "CZERNYRING 1",
    "Postcode": 69115,
    "City": "HEIDELBERG",
    "State": "Baden-Württemberg",
    "Latitude": 49.4074,
    "Longitude": 8.6723,
    "Telephone Number": "06221-29888",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.4074,8.6723",
    "IsOpen24Hours": false,
    "id": "49.4074,8.6723"
  },
  {
    "Gas Station Number": "TD110530",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BERLINER STR. 37",
    "Postcode": 44866,
    "City": "BOCHUM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4783,
    "Longitude": 7.1267,
    "Telephone Number": "02327-31229",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4783,7.1267",
    "IsOpen24Hours": false,
    "id": "51.4783,7.1267"
  },
  {
    "Gas Station Number": "TD025643",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "REICHERSDORFER STR. 9",
    "Postcode": 4651,
    "City": "BAD LAUSICK",
    "State": "Sachsen",
    "Latitude": 51.1407,
    "Longitude": 12.6501,
    "Telephone Number": "034345-22864",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1407,12.6501",
    "IsOpen24Hours": false,
    "id": "51.1407,12.6501"
  },
  {
    "Gas Station Number": "TD036749",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PEGNITZER STRASSE 31",
    "Postcode": 91278,
    "City": "POTTENSTEIN",
    "State": "Bayern",
    "Latitude": 49.7685,
    "Longitude": 11.4098,
    "Telephone Number": "09243-312",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.7685,11.4098",
    "IsOpen24Hours": false,
    "id": "49.7685,11.4098"
  },
  {
    "Gas Station Number": "TD034868",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WILHELMSDORFER LANDSTR. 36",
    "Postcode": 14776,
    "City": "BRANDENBURG",
    "State": "Brandenburg",
    "Latitude": 52.3952,
    "Longitude": 12.5405,
    "Telephone Number": "03381-795506",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3952,12.5405",
    "IsOpen24Hours": false,
    "id": "52.3952,12.5405"
  },
  {
    "Gas Station Number": "TD038711",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "WANDHOFENER STR. 9",
    "Postcode": 58099,
    "City": "HAGEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4066,
    "Longitude": 7.4781,
    "Telephone Number": "02331-9235959",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4066,7.4781",
    "IsOpen24Hours": false,
    "id": "51.4066,7.4781"
  },
  {
    "Gas Station Number": "TD004416",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LANGE STR. 147",
    "Postcode": 33397,
    "City": "RIETBERG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8325,
    "Longitude": 8.4453,
    "Telephone Number": "05244-2318",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8325,8.4453",
    "IsOpen24Hours": false,
    "id": "51.8325,8.4453"
  },
  {
    "Gas Station Number": "TD019349",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHWERTER STR. 161",
    "Postcode": 58099,
    "City": "HAGEN-BOELE",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.3989,
    "Longitude": 7.4733,
    "Telephone Number": "02331-65197",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3989,7.4733",
    "IsOpen24Hours": false,
    "id": "51.3989,7.4733"
  },
  {
    "Gas Station Number": "TD028704",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BERLINER STR. 1",
    "Postcode": 15926,
    "City": "LUCKAU",
    "State": "Brandenburg",
    "Latitude": 51.8517,
    "Longitude": 13.7032,
    "Telephone Number": "03544-2347",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8517,13.7032",
    "IsOpen24Hours": false,
    "id": "51.8517,13.7032"
  },
  {
    "Gas Station Number": "TD036434",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KOENIGSTR. 52",
    "Postcode": 73326,
    "City": "DEGGINGEN",
    "State": "Baden-Württemberg",
    "Latitude": 48.5968,
    "Longitude": 9.7159,
    "Telephone Number": "07334-5408",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.5968,9.7159",
    "IsOpen24Hours": false,
    "id": "48.5968,9.7159"
  },
  {
    "Gas Station Number": "TD035824",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ZUR THUERINGER PFORTE 10",
    "Postcode": 6577,
    "City": "AN DER SCHMUECKE",
    "State": "Thüringen",
    "Latitude": 51.2997,
    "Longitude": 11.2021,
    "Telephone Number": "034673-779718",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2997,11.2021",
    "IsOpen24Hours": false,
    "id": "51.2997,11.2021"
  },
  {
    "Gas Station Number": "TD191710",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "RINGSTR. 12",
    "Postcode": 69115,
    "City": "HEIDELBERG",
    "State": "Baden-Württemberg",
    "Latitude": 49.4043,
    "Longitude": 8.6826,
    "Telephone Number": "06221-23773",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.4043,8.6826",
    "IsOpen24Hours": false,
    "id": "49.4043,8.6826"
  },
  {
    "Gas Station Number": "TD122070",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KARLSTR. 44",
    "Postcode": 44649,
    "City": "HERNE",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5371,
    "Longitude": 7.1492,
    "Telephone Number": "02325-977166",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5371,7.1492",
    "IsOpen24Hours": false,
    "id": "51.5371,7.1492"
  },
  {
    "Gas Station Number": "TD006676",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WORMSER LANDSTR. 255",
    "Postcode": 67346,
    "City": "SPEYER",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.3379,
    "Longitude": 8.4297,
    "Telephone Number": "06232-34051",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.3379,8.4297",
    "IsOpen24Hours": false,
    "id": "49.3379,8.4297"
  },
  {
    "Gas Station Number": "TD029587",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PLAUER CHAUSSEE 2",
    "Postcode": 18273,
    "City": "GUESTROW",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.785,
    "Longitude": 12.1965,
    "Telephone Number": "03843-82149",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.785,12.1965",
    "IsOpen24Hours": false,
    "id": "53.785,12.1965"
  },
  {
    "Gas Station Number": "TD023069",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "NEUSTAEDTER STR. 162",
    "Postcode": 7381,
    "City": "POESSNECK",
    "State": "Thüringen",
    "Latitude": 50.701,
    "Longitude": 11.6142,
    "Telephone Number": "03647-425370",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.701,11.6142",
    "IsOpen24Hours": false,
    "id": "50.701,11.6142"
  },
  {
    "Gas Station Number": "TD000969",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FR.-EBERT-STR. 14-16",
    "Postcode": 68167,
    "City": "MANNHEIM",
    "State": "Baden-Württemberg",
    "Latitude": 49.4972,
    "Longitude": 8.4881,
    "Telephone Number": "0621-333545",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.4972,8.4881",
    "IsOpen24Hours": false,
    "id": "49.4972,8.4881"
  },
  {
    "Gas Station Number": "TD028845",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KOLKWITZER STR. 66A",
    "Postcode": 3046,
    "City": "COTTBUS",
    "State": "Brandenburg",
    "Latitude": 51.7531,
    "Longitude": 14.2975,
    "Telephone Number": "0355-31481",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.7531,14.2975",
    "IsOpen24Hours": false,
    "id": "51.7531,14.2975"
  },
  {
    "Gas Station Number": "TD124100",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB A 620 SUEDSEITE",
    "Postcode": 66740,
    "City": "SAARLOUIS",
    "State": "Saarland",
    "Latitude": 49.2998,
    "Longitude": 6.7484,
    "Telephone Number": "06831-2060",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.2998,6.7484",
    "IsOpen24Hours": false,
    "id": "49.2998,6.7484"
  },
  {
    "Gas Station Number": "TD028415",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BERLINER TOR 2",
    "Postcode": 14959,
    "City": "TREBBIN",
    "State": "Brandenburg",
    "Latitude": 52.222,
    "Longitude": 13.2196,
    "Telephone Number": "033731-15271",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.222,13.2196",
    "IsOpen24Hours": false,
    "id": "52.222,13.2196"
  },
  {
    "Gas Station Number": "TD003053",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FLUGPLATZSTR. 26",
    "Postcode": 55126,
    "City": "MAINZ-FINTHEN",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.9832,
    "Longitude": 8.1708,
    "Telephone Number": "06131-40805",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.9832,8.1708",
    "IsOpen24Hours": false,
    "id": "49.9832,8.1708"
  },
  {
    "Gas Station Number": "TD029884",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PASEWALKER ALLEE 95 A",
    "Postcode": 17389,
    "City": "ANKLAM",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.8387,
    "Longitude": 13.7148,
    "Telephone Number": "03971-210189",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.8387,13.7148",
    "IsOpen24Hours": false,
    "id": "53.8387,13.7148"
  },
  {
    "Gas Station Number": "TD035964",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "RACKWITZER STRASSE 26",
    "Postcode": 4347,
    "City": "LEIPZIG",
    "State": "Sachsen",
    "Latitude": 51.3544,
    "Longitude": 12.395,
    "Telephone Number": "0341-2345861",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3544,12.395",
    "IsOpen24Hours": false,
    "id": "51.3544,12.395"
  },
  {
    "Gas Station Number": "TD029728",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LUEBECKER STR. 283",
    "Postcode": 19059,
    "City": "SCHWERIN",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.6457,
    "Longitude": 11.3821,
    "Telephone Number": "0385-4864112",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.6457,11.3821",
    "IsOpen24Hours": false,
    "id": "53.6457,11.3821"
  },
  {
    "Gas Station Number": "TD032854",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "POISENTALSTR. 18",
    "Postcode": 1705,
    "City": "FREITAL",
    "State": "Sachsen",
    "Latitude": 50.9961,
    "Longitude": 13.6521,
    "Telephone Number": "0351-6491861",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9961,13.6521",
    "IsOpen24Hours": false,
    "id": "50.9961,13.6521"
  },
  {
    "Gas Station Number": "TD000944",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BOTTROPER STR. 18-20",
    "Postcode": 46244,
    "City": "BOTTROP",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.6006,
    "Longitude": 6.9144,
    "Telephone Number": "02045-2128",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6006,6.9144",
    "IsOpen24Hours": false,
    "id": "51.6006,6.9144"
  },
  {
    "Gas Station Number": "TD002568",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DOERRENBACHER STR. 22",
    "Postcode": 66564,
    "City": "OTTWEILER",
    "State": "Saarland",
    "Latitude": 49.424,
    "Longitude": 7.2361,
    "Telephone Number": "06858-332",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.424,7.2361",
    "IsOpen24Hours": false,
    "id": "49.424,7.2361"
  },
  {
    "Gas Station Number": "TD122740",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAUPTSTR.23",
    "Postcode": 56767,
    "City": "UERSFELD",
    "State": "Rheinland-Pfalz",
    "Latitude": 50.2558,
    "Longitude": 7.0136,
    "Telephone Number": "02657-247",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.2558,7.0136",
    "IsOpen24Hours": false,
    "id": "50.2558,7.0136"
  },
  {
    "Gas Station Number": "TD025254",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LANGENAUER STR. 1",
    "Postcode": 9618,
    "City": "BRAND-ERBISDORF",
    "State": "Sachsen",
    "Latitude": 50.8579,
    "Longitude": 13.319,
    "Telephone Number": "037322-2271",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8579,13.319",
    "IsOpen24Hours": false,
    "id": "50.8579,13.319"
  },
  {
    "Gas Station Number": "TD000766",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GROTHUSSTR. 4",
    "Postcode": 45881,
    "City": "GELSENKIRCHEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5203,
    "Longitude": 7.0788,
    "Telephone Number": "0209-498552",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5203,7.0788",
    "IsOpen24Hours": false,
    "id": "51.5203,7.0788"
  },
  {
    "Gas Station Number": "TD003236",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ALZEYER STR. 73",
    "Postcode": 55543,
    "City": "BAD KREUZNACH",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.8366,
    "Longitude": 7.8772,
    "Telephone Number": "0671-68373",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.8366,7.8772",
    "IsOpen24Hours": false,
    "id": "49.8366,7.8772"
  },
  {
    "Gas Station Number": "TD038125",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "BUCHAECKERRING 40",
    "Postcode": 74906,
    "City": "BAD RAPPENAU",
    "State": "Baden-Württemberg",
    "Latitude": 49.2123,
    "Longitude": 9.0773,
    "Telephone Number": "07066-9158824",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.2123,9.0773",
    "IsOpen24Hours": false,
    "id": "49.2123,9.0773"
  },
  {
    "Gas Station Number": "TD000869",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BONNER STR. 417-425",
    "Postcode": 50968,
    "City": "KOELN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.9009,
    "Longitude": 6.9657,
    "Telephone Number": "0221-372137",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9009,6.9657",
    "IsOpen24Hours": false,
    "id": "50.9009,6.9657"
  },
  {
    "Gas Station Number": "TD007385",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HEERESSTR. 27",
    "Postcode": 66822,
    "City": "LEBACH",
    "State": "Saarland",
    "Latitude": 49.4126,
    "Longitude": 6.8941,
    "Telephone Number": "06881-52861",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.4126,6.8941",
    "IsOpen24Hours": false,
    "id": "49.4126,6.8941"
  },
  {
    "Gas Station Number": "TD170350",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DORFGUETINGEN 43",
    "Postcode": 91555,
    "City": "FEUCHTWANGEN",
    "State": "Bayern",
    "Latitude": 49.2163,
    "Longitude": 10.3002,
    "Telephone Number": "09852-768",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.2163,10.3002",
    "IsOpen24Hours": false,
    "id": "49.2163,10.3002"
  },
  {
    "Gas Station Number": "TD006775",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ERLIGHEIMER STR. 20",
    "Postcode": 74369,
    "City": "LOECHGAU",
    "State": "Baden-Württemberg",
    "Latitude": 49.0034,
    "Longitude": 9.1044,
    "Telephone Number": "07143-88302-0",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.0034,9.1044",
    "IsOpen24Hours": false,
    "id": "49.0034,9.1044"
  },
  {
    "Gas Station Number": "TD111660",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KASSELER STR. 49-51",
    "Postcode": 37247,
    "City": "GROSSALMERODE",
    "State": "Hessen",
    "Latitude": 51.2583,
    "Longitude": 9.7782,
    "Telephone Number": "05604-6386",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2583,9.7782",
    "IsOpen24Hours": false,
    "id": "51.2583,9.7782"
  },
  {
    "Gas Station Number": "TD000861",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "RODGAUSTRASSE 6",
    "Postcode": 63457,
    "City": "HANAU",
    "State": "Hessen",
    "Latitude": 50.1167,
    "Longitude": 8.9289,
    "Telephone Number": "06181-399572",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.1167,8.9289",
    "IsOpen24Hours": false,
    "id": "50.1167,8.9289"
  },
  {
    "Gas Station Number": "TD131890",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB BROHLTAL WEST/A 61",
    "Postcode": 56651,
    "City": "NIEDERZISSEN",
    "State": "Rheinland-Pfalz",
    "Latitude": 50.4418,
    "Longitude": 7.2247,
    "Telephone Number": "02636-970077",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.4418,7.2247",
    "IsOpen24Hours": false,
    "id": "50.4418,7.2247"
  },
  {
    "Gas Station Number": "TD000949",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "STOCKUMER STR. 200",
    "Postcode": 44225,
    "City": "DORTMUND",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4818,
    "Longitude": 7.4363,
    "Telephone Number": "0231-711950",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4818,7.4363",
    "IsOpen24Hours": false,
    "id": "51.4818,7.4363"
  },
  {
    "Gas Station Number": "TD025247",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DRESDNER STR. 5",
    "Postcode": 9456,
    "City": "ANNABERG-BUCHHOLZ",
    "State": "Sachsen",
    "Latitude": 50.5903,
    "Longitude": 13.0135,
    "Telephone Number": "03733-53417",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.5903,13.0135",
    "IsOpen24Hours": false,
    "id": "50.5903,13.0135"
  },
  {
    "Gas Station Number": "TD023242",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BETTELHECKER STR. 160",
    "Postcode": 96515,
    "City": "SONNEBERG",
    "State": "Thüringen",
    "Latitude": 50.3657,
    "Longitude": 11.1433,
    "Telephone Number": "03675-702932",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.3657,11.1433",
    "IsOpen24Hours": false,
    "id": "50.3657,11.1433"
  },
  {
    "Gas Station Number": "TD130780",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LORSBACHER STR.14",
    "Postcode": 65817,
    "City": "EPPSTEIN",
    "State": "Hessen",
    "Latitude": 50.1353,
    "Longitude": 8.4004,
    "Telephone Number": "06198-349178",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.1353,8.4004",
    "IsOpen24Hours": false,
    "id": "50.1353,8.4004"
  },
  {
    "Gas Station Number": "TD035485",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AUTOHOF REIMERSHECK 3",
    "Postcode": 56424,
    "City": "MOGENDORF",
    "State": "Rheinland-Pfalz",
    "Latitude": 50.4828,
    "Longitude": 7.7491,
    "Telephone Number": "02623-9262132",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.4828,7.7491",
    "IsOpen24Hours": false,
    "id": "50.4828,7.7491"
  },
  {
    "Gas Station Number": "TD029611",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WESTRING 40",
    "Postcode": 19370,
    "City": "PARCHIM",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.4275,
    "Longitude": 11.8273,
    "Telephone Number": "03871-441021",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.4275,11.8273",
    "IsOpen24Hours": false,
    "id": "53.4275,11.8273"
  },
  {
    "Gas Station Number": "TD035980",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AUTOHOF EYTHSTRASSE 16 A",
    "Postcode": 72189,
    "City": "VOEHRINGEN",
    "State": "Baden-Württemberg",
    "Latitude": 48.3434,
    "Longitude": 8.6631,
    "Telephone Number": "07454-96040",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.3434,8.6631",
    "IsOpen24Hours": false,
    "id": "48.3434,8.6631"
  },
  {
    "Gas Station Number": "TD000786",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AHRSTR. 24",
    "Postcode": 53925,
    "City": "KALL",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.4708,
    "Longitude": 6.5309,
    "Telephone Number": "02447-95050",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.4708,6.5309",
    "IsOpen24Hours": false,
    "id": "50.4708,6.5309"
  },
  {
    "Gas Station Number": "TD027060",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "NORDHAEUSER STR. 19",
    "Postcode": 38899,
    "City": "OBERHARZ AM BROCKEN",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.6889,
    "Longitude": 10.8448,
    "Telephone Number": "039459-71276",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6889,10.8448",
    "IsOpen24Hours": false,
    "id": "51.6889,10.8448"
  },
  {
    "Gas Station Number": "TD031716",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ZIEROWER LANDSTRASSE 1",
    "Postcode": 23968,
    "City": "WISMAR",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.9027,
    "Longitude": 11.4047,
    "Telephone Number": "03841-643704",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.9027,11.4047",
    "IsOpen24Hours": false,
    "id": "53.9027,11.4047"
  },
  {
    "Gas Station Number": "TD033274",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DESSAUER STR.",
    "Postcode": 6366,
    "City": "KOETHEN",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.765,
    "Longitude": 11.9876,
    "Telephone Number": "03496-216047",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.765,11.9876",
    "IsOpen24Hours": false,
    "id": "51.765,11.9876"
  },
  {
    "Gas Station Number": "TD027342",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DAHLENER STR. 16",
    "Postcode": 39576,
    "City": "STENDAL",
    "State": "Sachsen-Anhalt",
    "Latitude": 52.5863,
    "Longitude": 11.8387,
    "Telephone Number": "03931-414079",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5863,11.8387",
    "IsOpen24Hours": false,
    "id": "52.5863,11.8387"
  },
  {
    "Gas Station Number": "TD027078",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GERICKESTR. 108",
    "Postcode": 39340,
    "City": "HALDENSLEBEN",
    "State": "Sachsen-Anhalt",
    "Latitude": 52.2805,
    "Longitude": 11.4314,
    "Telephone Number": "03904-65040",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2805,11.4314",
    "IsOpen24Hours": false,
    "id": "52.2805,11.4314"
  },
  {
    "Gas Station Number": "TD040337",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AN DER A 3",
    "Postcode": 93164,
    "City": "BRUNN",
    "State": "Bayern",
    "Latitude": 49.091,
    "Longitude": 11.8885,
    "Telephone Number": "09498/9077889",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.091,11.8885",
    "IsOpen24Hours": false,
    "id": "49.091,11.8885"
  },
  {
    "Gas Station Number": "TD000566",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "OSTSTR. 5",
    "Postcode": 42651,
    "City": "SOLINGEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.1719,
    "Longitude": 7.0895,
    "Telephone Number": "0212-202894",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1719,7.0895",
    "IsOpen24Hours": false,
    "id": "51.1719,7.0895"
  },
  {
    "Gas Station Number": "TD124220",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BENSBERGER STR. 240",
    "Postcode": 51469,
    "City": "BERGISCH GLADBACH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.9753,
    "Longitude": 7.1403,
    "Telephone Number": "02202-34120",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9753,7.1403",
    "IsOpen24Hours": false,
    "id": "50.9753,7.1403"
  },
  {
    "Gas Station Number": "TD022848",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "NORDHAEUSER STR.9",
    "Postcode": 99752,
    "City": "BLEICHERODE",
    "State": "Thüringen",
    "Latitude": 51.4474,
    "Longitude": 10.5841,
    "Telephone Number": "036338-43505",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4474,10.5841",
    "IsOpen24Hours": false,
    "id": "51.4474,10.5841"
  },
  {
    "Gas Station Number": "TD170070",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "COBURGER STR. 42",
    "Postcode": 96476,
    "City": "BAD RODACH",
    "State": "Bayern",
    "Latitude": 50.3409,
    "Longitude": 10.784,
    "Telephone Number": "09564-4833",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.3409,10.784",
    "IsOpen24Hours": false,
    "id": "50.3409,10.784"
  },
  {
    "Gas Station Number": "TD040352",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AN DER LEIBI 1",
    "Postcode": 89278,
    "City": "NERSINGEN",
    "State": "Bayern",
    "Latitude": 48.4164,
    "Longitude": 10.101,
    "Telephone Number": "07308-9240280",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.4164,10.101",
    "IsOpen24Hours": false,
    "id": "48.4164,10.101"
  },
  {
    "Gas Station Number": "TD180680",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "TUNIBERGSTR. 2",
    "Postcode": 79189,
    "City": "BAD KROZINGEN-HAUSEN",
    "State": "Baden-Württemberg",
    "Latitude": 47.9537,
    "Longitude": 7.6698,
    "Telephone Number": "07633-14592",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.9537,7.6698",
    "IsOpen24Hours": false,
    "id": "47.9537,7.6698"
  },
  {
    "Gas Station Number": "TD000440",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "34 BERGSTR.",
    "Postcode": 67067,
    "City": "LUDWIGSHAFEN-MAUDACH",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.4534,
    "Longitude": 8.3755,
    "Telephone Number": "0621-553424",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.4534,8.3755",
    "IsOpen24Hours": false,
    "id": "49.4534,8.3755"
  },
  {
    "Gas Station Number": "TD041053",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB SUED / A10",
    "Postcode": 14552,
    "City": "MICHENDORF",
    "State": "Brandenburg",
    "Latitude": 52.3026,
    "Longitude": 13.0148,
    "Telephone Number": "033205-46699",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3026,13.0148",
    "IsOpen24Hours": false,
    "id": "52.3026,13.0148"
  },
  {
    "Gas Station Number": "TD037127",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "CELLER STRASSE 65",
    "Postcode": 38114,
    "City": "BRAUNSCHWEIG",
    "State": "Niedersachsen",
    "Latitude": 52.2796,
    "Longitude": 10.5054,
    "Telephone Number": "053121931707",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2796,10.5054",
    "IsOpen24Hours": false,
    "id": "52.2796,10.5054"
  },
  {
    "Gas Station Number": "TD033860",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FRIEDRICH-ECKARDT-STRASSE 2",
    "Postcode": 8529,
    "City": "PLAUEN",
    "State": "Sachsen",
    "Latitude": 50.508,
    "Longitude": 12.1585,
    "Telephone Number": "03741-442003",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.508,12.1585",
    "IsOpen24Hours": false,
    "id": "50.508,12.1585"
  },
  {
    "Gas Station Number": "TD120680",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BGM.-PUETZ-STRASSE 8",
    "Postcode": 47137,
    "City": "DUISBURG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4635,
    "Longitude": 6.7735,
    "Telephone Number": "0203-446384",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4635,6.7735",
    "IsOpen24Hours": false,
    "id": "51.4635,6.7735"
  },
  {
    "Gas Station Number": "TD039008",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB WESTSEITE / A 61",
    "Postcode": 67125,
    "City": "DANNSTADT-SCHAUERNHEIM",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.4103,
    "Longitude": 8.34,
    "Telephone Number": "06231-2542",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.4103,8.34",
    "IsOpen24Hours": false,
    "id": "49.4103,8.34"
  },
  {
    "Gas Station Number": "TD133450",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "INDUSTRIESTR. 19",
    "Postcode": 64546,
    "City": "MOERFELDEN-WALLDORF",
    "State": "Hessen",
    "Latitude": 49.9836,
    "Longitude": 8.5793,
    "Telephone Number": "06105-21069",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.9836,8.5793",
    "IsOpen24Hours": false,
    "id": "49.9836,8.5793"
  },
  {
    "Gas Station Number": "TD036582",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LENGGRIESER STR. 87",
    "Postcode": 83646,
    "City": "BAD TOELZ",
    "State": "Bayern",
    "Latitude": 47.7479,
    "Longitude": 11.5654,
    "Telephone Number": "08041-8445",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.7479,11.5654",
    "IsOpen24Hours": false,
    "id": "47.7479,11.5654"
  },
  {
    "Gas Station Number": "TD038778",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "ALUMINIUMSTR. 5",
    "Postcode": 21129,
    "City": "HAMBURG",
    "State": "Hamburg",
    "Latitude": 53.5173,
    "Longitude": 9.8903,
    "Telephone Number": "040-30239835",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.5173,9.8903",
    "IsOpen24Hours": false,
    "id": "53.5173,9.8903"
  },
  {
    "Gas Station Number": "TD005694",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHUETZENSTR.18",
    "Postcode": 58239,
    "City": "SCHWERTE",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4444,
    "Longitude": 7.5771,
    "Telephone Number": "02304-13152",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4444,7.5771",
    "IsOpen24Hours": false,
    "id": "51.4444,7.5771"
  },
  {
    "Gas Station Number": "TD000959",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BASLER STR. 57",
    "Postcode": 79100,
    "City": "FREIBURG",
    "State": "Baden-Württemberg",
    "Latitude": 47.9871,
    "Longitude": 7.8385,
    "Telephone Number": "0761402902",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.9871,7.8385",
    "IsOpen24Hours": false,
    "id": "47.9871,7.8385"
  },
  {
    "Gas Station Number": "TD035659",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AUTOHOF STRAENGENBACH 1",
    "Postcode": 59510,
    "City": "LIPPETAL",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.6957,
    "Longitude": 7.9671,
    "Telephone Number": "02388-307400",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6957,7.9671",
    "IsOpen24Hours": false,
    "id": "51.6957,7.9671"
  },
  {
    "Gas Station Number": "TD041707",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DODOWEG 29",
    "Postcode": 26386,
    "City": "WILHELMSHAVEN",
    "State": "Niedersachsen",
    "Latitude": 53.5585,
    "Longitude": 8.0993,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.5585,8.0993",
    "IsOpen24Hours": false,
    "id": "53.5585,8.0993"
  },
  {
    "Gas Station Number": "TD041517",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ZURMAIENERSTRASSE 155-157",
    "Postcode": 54292,
    "City": "TRIER",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.7717,
    "Longitude": 6.6579,
    "Telephone Number": "06514368353",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.7717,6.6579",
    "IsOpen24Hours": false,
    "id": "49.7717,6.6579"
  },
  {
    "Gas Station Number": "TD042119",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "AM GROSSEN TORFMOOR 1/DEMMINER LAND",
    "Postcode": 17129,
    "City": "VOELSCHOW",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.8566,
    "Longitude": 13.3347,
    "Telephone Number": "039991-367822",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.8566,13.3347",
    "IsOpen24Hours": false,
    "id": "53.8566,13.3347"
  },
  {
    "Gas Station Number": "TD112580",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MARKSTR. 119",
    "Postcode": 44803,
    "City": "BOCHUM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4555,
    "Longitude": 7.2503,
    "Telephone Number": "0234-38619",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4555,7.2503",
    "IsOpen24Hours": false,
    "id": "51.4555,7.2503"
  },
  {
    "Gas Station Number": "TD122220",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KIRCHSTR. 23",
    "Postcode": 40699,
    "City": "ERKRATH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.2238,
    "Longitude": 6.9165,
    "Telephone Number": "0211-9003030",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2238,6.9165",
    "IsOpen24Hours": false,
    "id": "51.2238,6.9165"
  },
  {
    "Gas Station Number": "TD038364",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "EICHBORNDAMM 91",
    "Postcode": 13403,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.5764,
    "Longitude": 13.3145,
    "Telephone Number": "030-41703023",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5764,13.3145",
    "IsOpen24Hours": false,
    "id": "52.5764,13.3145"
  },
  {
    "Gas Station Number": "TD029678",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PARKSTR. 90 A",
    "Postcode": 19322,
    "City": "WITTENBERGE",
    "State": "Brandenburg",
    "Latitude": 53.0013,
    "Longitude": 11.7469,
    "Telephone Number": "03877-402317",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.0013,11.7469",
    "IsOpen24Hours": false,
    "id": "53.0013,11.7469"
  },
  {
    "Gas Station Number": "TD027243",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AM ANGER",
    "Postcode": 38855,
    "City": "WERNIGERODE",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.8379,
    "Longitude": 10.7947,
    "Telephone Number": "03943-23094",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8379,10.7947",
    "IsOpen24Hours": false,
    "id": "51.8379,10.7947"
  },
  {
    "Gas Station Number": "TD026500",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "INN.ZWICKAUER STR.29",
    "Postcode": 8062,
    "City": "ZWICKAU",
    "State": "Sachsen",
    "Latitude": 50.6947,
    "Longitude": 12.4792,
    "Telephone Number": "0375-787047",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.6947,12.4792",
    "IsOpen24Hours": false,
    "id": "50.6947,12.4792"
  },
  {
    "Gas Station Number": "TD040956",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GOTTENHEIMER STR.16",
    "Postcode": 79268,
    "City": "BOETZINGEN",
    "State": "Baden-Württemberg",
    "Latitude": 48.0701,
    "Longitude": 7.7201,
    "Telephone Number": "076639427420",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.0701,7.7201",
    "IsOpen24Hours": false,
    "id": "48.0701,7.7201"
  },
  {
    "Gas Station Number": "TD021634",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WUERZBURGER STR. 26",
    "Postcode": 1187,
    "City": "DRESDEN",
    "State": "Sachsen",
    "Latitude": 51.0322,
    "Longitude": 13.7107,
    "Telephone Number": "0351-4717777",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0322,13.7107",
    "IsOpen24Hours": false,
    "id": "51.0322,13.7107"
  },
  {
    "Gas Station Number": "TD006783",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ALTENLINDE 1",
    "Postcode": 51789,
    "City": "LINDLAR",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.0235,
    "Longitude": 7.3705,
    "Telephone Number": "02266-5556",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0235,7.3705",
    "IsOpen24Hours": false,
    "id": "51.0235,7.3705"
  },
  {
    "Gas Station Number": "TD032631",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LEIBNIZSTR. 74",
    "Postcode": 7548,
    "City": "GERA",
    "State": "Thüringen",
    "Latitude": 50.8911,
    "Longitude": 12.0686,
    "Telephone Number": "036520519740",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8911,12.0686",
    "IsOpen24Hours": false,
    "id": "50.8911,12.0686"
  },
  {
    "Gas Station Number": "TD035303",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KEMBERGER STR. 9",
    "Postcode": 6905,
    "City": "BAD SCHMIEDEBERG",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.6951,
    "Longitude": 12.7387,
    "Telephone Number": "034925-71176",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6951,12.7387",
    "IsOpen24Hours": false,
    "id": "51.6951,12.7387"
  },
  {
    "Gas Station Number": "TD007708",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "OLCHINGER STR. 82",
    "Postcode": 82194,
    "City": "GROEBENZELL",
    "State": "Bayern",
    "Latitude": 48.1861,
    "Longitude": 11.3842,
    "Telephone Number": "08142-58627",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.1861,11.3842",
    "IsOpen24Hours": false,
    "id": "48.1861,11.3842"
  },
  {
    "Gas Station Number": "TD038448",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "SEBASTIANSTR. 28",
    "Postcode": 91058,
    "City": "ERLANGEN",
    "State": "Bayern",
    "Latitude": 49.5452,
    "Longitude": 11.0299,
    "Telephone Number": "09131/602151",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.5452,11.0299",
    "IsOpen24Hours": false,
    "id": "49.5452,11.0299"
  },
  {
    "Gas Station Number": "TD030049",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GLETZOWER STR. 62 B",
    "Postcode": 19217,
    "City": "REHNA",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.7854,
    "Longitude": 11.0441,
    "Telephone Number": "038872-53341",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.7854,11.0441",
    "IsOpen24Hours": false,
    "id": "53.7854,11.0441"
  },
  {
    "Gas Station Number": "TD033175",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BOEBLINGER STR. 6",
    "Postcode": 71116,
    "City": "GAERTRINGEN",
    "State": "Baden-Württemberg",
    "Latitude": 48.6389,
    "Longitude": 8.9098,
    "Telephone Number": "07034-285065",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.6389,8.9098",
    "IsOpen24Hours": false,
    "id": "48.6389,8.9098"
  },
  {
    "Gas Station Number": "TD040618",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KLOETZER STR. 12a",
    "Postcode": 1587,
    "City": "RIESA",
    "State": "Sachsen",
    "Latitude": 51.3054,
    "Longitude": 13.2976,
    "Telephone Number": "03525/7770310",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3054,13.2976",
    "IsOpen24Hours": false,
    "id": "51.3054,13.2976"
  },
  {
    "Gas Station Number": "TD038851",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PROSPERSTR. 440",
    "Postcode": 46238,
    "City": "BOTTROP",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5222,
    "Longitude": 6.9844,
    "Telephone Number": "02041-7763253",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5222,6.9844",
    "IsOpen24Hours": false,
    "id": "51.5222,6.9844"
  },
  {
    "Gas Station Number": "TD039230",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LORCHER STR. 199",
    "Postcode": 73529,
    "City": "SCHWAEBISCH-GMUEND",
    "State": "Baden-Württemberg",
    "Latitude": 48.7886,
    "Longitude": 9.7634,
    "Telephone Number": "07171-9998895",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.7886,9.7634",
    "IsOpen24Hours": false,
    "id": "48.7886,9.7634"
  },
  {
    "Gas Station Number": "TD121500",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LIECKER STR. 62",
    "Postcode": 52525,
    "City": "HEINSBERG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.0664,
    "Longitude": 6.088,
    "Telephone Number": "02452-155476",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0664,6.088",
    "IsOpen24Hours": false,
    "id": "51.0664,6.088"
  },
  {
    "Gas Station Number": "TD040469",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BANTER WEG 121",
    "Postcode": 26389,
    "City": "WILHELMSHAVEN",
    "State": "Niedersachsen",
    "Latitude": 53.5224,
    "Longitude": 8.0916,
    "Telephone Number": "04421-9644884",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.5224,8.0916",
    "IsOpen24Hours": false,
    "id": "53.5224,8.0916"
  },
  {
    "Gas Station Number": "TD181480",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "NECKARSTR. 47",
    "Postcode": 71334,
    "City": "WAIBLINGEN",
    "State": "Baden-Württemberg",
    "Latitude": 48.8529,
    "Longitude": 9.297,
    "Telephone Number": "07151-52837",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8529,9.297",
    "IsOpen24Hours": false,
    "id": "48.8529,9.297"
  },
  {
    "Gas Station Number": "TD038414",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MUENCHNERSTR. 11A",
    "Postcode": 85375,
    "City": "NEUFAHRN",
    "State": "Bayern",
    "Latitude": 48.3184,
    "Longitude": 11.6915,
    "Telephone Number": "08165-66442",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.3184,11.6915",
    "IsOpen24Hours": false,
    "id": "48.3184,11.6915"
  },
  {
    "Gas Station Number": "TD040253",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KRAIBURGER STR. 13",
    "Postcode": 84478,
    "City": "WALDKRAIBURG",
    "State": "Bayern",
    "Latitude": 48.2076,
    "Longitude": 12.4179,
    "Telephone Number": "08631-378131",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.2076,12.4179",
    "IsOpen24Hours": false,
    "id": "48.2076,12.4179"
  },
  {
    "Gas Station Number": "TD034835",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FRIEDRICH-FRANZ-STR. 2",
    "Postcode": 14770,
    "City": "BRANDENBURG",
    "State": "Brandenburg",
    "Latitude": 52.4137,
    "Longitude": 12.5241,
    "Telephone Number": "03381-301295",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4137,12.5241",
    "IsOpen24Hours": false,
    "id": "52.4137,12.5241"
  },
  {
    "Gas Station Number": "TD041483",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KASSELER STR. 30",
    "Postcode": 35683,
    "City": "DILLENBURG",
    "State": "Hessen",
    "Latitude": 50.7496,
    "Longitude": 8.277,
    "Telephone Number": "02771-812042",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.7496,8.277",
    "IsOpen24Hours": false,
    "id": "50.7496,8.277"
  },
  {
    "Gas Station Number": "TD041673",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "23 VINNHORSTER WEG",
    "Postcode": 30419,
    "City": "HANNOVER",
    "State": "Niedersachsen",
    "Latitude": 52.4008,
    "Longitude": 9.6936,
    "Telephone Number": "0511-753165",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4008,9.6936",
    "IsOpen24Hours": false,
    "id": "52.4008,9.6936"
  },
  {
    "Gas Station Number": "TD041574",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "THEELTALSTRASSE 50",
    "Postcode": 66636,
    "City": "THOLEY-HASBORN",
    "State": "Saarland",
    "Latitude": 49.4877,
    "Longitude": 6.9846,
    "Telephone Number": "068538540045",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.4877,6.9846",
    "IsOpen24Hours": false,
    "id": "49.4877,6.9846"
  },
  {
    "Gas Station Number": "TD007112",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DRESDNER STR.53",
    "Postcode": 1458,
    "City": "OTTENDORF-OKRILLA",
    "State": "Sachsen",
    "Latitude": 51.1749,
    "Longitude": 13.8252,
    "Telephone Number": "035205-54211",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1749,13.8252",
    "IsOpen24Hours": false,
    "id": "51.1749,13.8252"
  },
  {
    "Gas Station Number": "TD032672",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "TONNAER STR. 28",
    "Postcode": 99947,
    "City": "BAD LANGENSALZA",
    "State": "Thüringen",
    "Latitude": 51.1043,
    "Longitude": 10.6644,
    "Telephone Number": "03603-842355",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1043,10.6644",
    "IsOpen24Hours": false,
    "id": "51.1043,10.6644"
  },
  {
    "Gas Station Number": "TD182370",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "HECHTSBERG 8",
    "Postcode": 77756,
    "City": "HAUSACH",
    "State": "Baden-Württemberg",
    "Latitude": 48.2819,
    "Longitude": 8.1372,
    "Telephone Number": "078314149988",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.2819,8.1372",
    "IsOpen24Hours": false,
    "id": "48.2819,8.1372"
  },
  {
    "Gas Station Number": "TD143170",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHUETTENDELLE 27",
    "Postcode": 42857,
    "City": "REMSCHEID",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.181,
    "Longitude": 7.1741,
    "Telephone Number": "02191-2099115",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.181,7.1741",
    "IsOpen24Hours": false,
    "id": "51.181,7.1741"
  },
  {
    "Gas Station Number": "TD041350",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LEHRTER STR. 20",
    "Postcode": 31319,
    "City": "SEHNDE",
    "State": "Niedersachsen",
    "Latitude": 52.3178,
    "Longitude": 9.9661,
    "Telephone Number": "05138-7099202",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3178,9.9661",
    "IsOpen24Hours": false,
    "id": "52.3178,9.9661"
  },
  {
    "Gas Station Number": "TD039883",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "B2",
    "Postcode": 86695,
    "City": "NORDENDORF",
    "State": "Bayern",
    "Latitude": 48.5984,
    "Longitude": 10.8405,
    "Telephone Number": "08273/9969887",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.5984,10.8405",
    "IsOpen24Hours": false,
    "id": "48.5984,10.8405"
  },
  {
    "Gas Station Number": "TD031880",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "UMGEHUNGSSTRASSE 1",
    "Postcode": 18556,
    "City": "ALTENKIRCHEN",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 54.6318,
    "Longitude": 13.3476,
    "Telephone Number": "038391-231",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.6318,13.3476",
    "IsOpen24Hours": false,
    "id": "54.6318,13.3476"
  },
  {
    "Gas Station Number": "TD037481",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DUESSELDORFER STR. 28",
    "Postcode": 42781,
    "City": "HAAN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.1883,
    "Longitude": 6.9949,
    "Telephone Number": "02129-959770",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1883,6.9949",
    "IsOpen24Hours": false,
    "id": "51.1883,6.9949"
  },
  {
    "Gas Station Number": "TD029660",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BRUEELER CH. 12",
    "Postcode": 19406,
    "City": "STERNBERG",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.7089,
    "Longitude": 11.8067,
    "Telephone Number": "03847-2348",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.7089,11.8067",
    "IsOpen24Hours": false,
    "id": "53.7089,11.8067"
  },
  {
    "Gas Station Number": "TD034967",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHWANEBECKER CHAUSSEE 29",
    "Postcode": 16321,
    "City": "BERNAU",
    "State": "Brandenburg",
    "Latitude": 52.6681,
    "Longitude": 13.5823,
    "Telephone Number": "03338-5500",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.6681,13.5823",
    "IsOpen24Hours": false,
    "id": "52.6681,13.5823"
  },
  {
    "Gas Station Number": "TD040790",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DACHSBACHER STR. 1",
    "Postcode": 91466,
    "City": "GERHARDSHOFEN",
    "State": "Bayern",
    "Latitude": 49.6323,
    "Longitude": 10.6927,
    "Telephone Number": "09163997290",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.6323,10.6927",
    "IsOpen24Hours": false,
    "id": "49.6323,10.6927"
  },
  {
    "Gas Station Number": "TD150480",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "Am Magna Park 4",
    "Postcode": 14542,
    "City": "PLOETZIN (WERDER)",
    "State": "Brandenburg",
    "Latitude": 52.3909,
    "Longitude": 12.845,
    "Telephone Number": "03327-730313",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3909,12.845",
    "IsOpen24Hours": false,
    "id": "52.3909,12.845"
  },
  {
    "Gas Station Number": "TD031807",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ERICH-SCHLESINGER-STR.26",
    "Postcode": 18059,
    "City": "ROSTOCK",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 54.0766,
    "Longitude": 12.1194,
    "Telephone Number": "0381-442084",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.0766,12.1194",
    "IsOpen24Hours": false,
    "id": "54.0766,12.1194"
  },
  {
    "Gas Station Number": "TD026989",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HALBERSTAEDTER STR.21 B",
    "Postcode": 39435,
    "City": "EGELN",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.9406,
    "Longitude": 11.4181,
    "Telephone Number": "039268-2235",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9406,11.4181",
    "IsOpen24Hours": false,
    "id": "51.9406,11.4181"
  },
  {
    "Gas Station Number": "TD123890",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAUPTSTR.149",
    "Postcode": 47877,
    "City": "WILLICH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.2571,
    "Longitude": 6.4869,
    "Telephone Number": "02156-60714",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2571,6.4869",
    "IsOpen24Hours": false,
    "id": "51.2571,6.4869"
  },
  {
    "Gas Station Number": "TD025361",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "CHEMNITZER STR. 38 B",
    "Postcode": 9232,
    "City": "HARTMANNSDORF",
    "State": "Sachsen",
    "Latitude": 50.8785,
    "Longitude": 12.8218,
    "Telephone Number": "03722-96464",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8785,12.8218",
    "IsOpen24Hours": false,
    "id": "50.8785,12.8218"
  },
  {
    "Gas Station Number": "TD124310",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HASTENERSTR. 33",
    "Postcode": 42855,
    "City": "REMSCHEID",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.1915,
    "Longitude": 7.1708,
    "Telephone Number": "02191-81588",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1915,7.1708",
    "IsOpen24Hours": false,
    "id": "51.1915,7.1708"
  },
  {
    "Gas Station Number": "TD037671",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "TALSTR. 60",
    "Postcode": 98544,
    "City": "ZELLA-MEHLIS",
    "State": "Thüringen",
    "Latitude": 50.6542,
    "Longitude": 10.6605,
    "Telephone Number": "03682-486011",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.6542,10.6605",
    "IsOpen24Hours": false,
    "id": "50.6542,10.6605"
  },
  {
    "Gas Station Number": "TD038968",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB OST A 67",
    "Postcode": 64653,
    "City": "LORSCH",
    "State": "Hessen",
    "Latitude": 49.6426,
    "Longitude": 8.5534,
    "Telephone Number": "06251-175180",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.6426,8.5534",
    "IsOpen24Hours": false,
    "id": "49.6426,8.5534"
  },
  {
    "Gas Station Number": "TD028381",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BERLINER ALLEE 2a",
    "Postcode": 14662,
    "City": "FRIESACK",
    "State": "Brandenburg",
    "Latitude": 52.7234,
    "Longitude": 12.588,
    "Telephone Number": "033235-1592",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.7234,12.588",
    "IsOpen24Hours": false,
    "id": "52.7234,12.588"
  },
  {
    "Gas Station Number": "TD000180",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GUNDELFINGER STR. 27",
    "Postcode": 79108,
    "City": "FREIBURG",
    "State": "Baden-Württemberg",
    "Latitude": 48.0303,
    "Longitude": 7.8628,
    "Telephone Number": "0761-552674",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.0303,7.8628",
    "IsOpen24Hours": false,
    "id": "48.0303,7.8628"
  },
  {
    "Gas Station Number": "TD180400",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "NUERTINGER STR. 64",
    "Postcode": 72555,
    "City": "METZINGEN",
    "State": "Baden-Württemberg",
    "Latitude": 48.5437,
    "Longitude": 9.2902,
    "Telephone Number": "07123-200830",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.5437,9.2902",
    "IsOpen24Hours": false,
    "id": "48.5437,9.2902"
  },
  {
    "Gas Station Number": "TD005140",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "EICKELER BRUCH 143",
    "Postcode": 44652,
    "City": "HERNE",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5222,
    "Longitude": 7.1599,
    "Telephone Number": "02325-33436",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5222,7.1599",
    "IsOpen24Hours": false,
    "id": "51.5222,7.1599"
  },
  {
    "Gas Station Number": "TD036855",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "CARL-BENZ-STR. 3",
    "Postcode": 36251,
    "City": "BAD HERSFELD",
    "State": "Hessen",
    "Latitude": 50.8582,
    "Longitude": 9.7227,
    "Telephone Number": "06621-919744",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8582,9.7227",
    "IsOpen24Hours": false,
    "id": "50.8582,9.7227"
  },
  {
    "Gas Station Number": "TD000965",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HOLZMARKT 49",
    "Postcode": 50676,
    "City": "KOELN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.9313,
    "Longitude": 6.9624,
    "Telephone Number": "0221-218144",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9313,6.9624",
    "IsOpen24Hours": false,
    "id": "50.9313,6.9624"
  },
  {
    "Gas Station Number": "TD038190",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GEWERBERING 9",
    "Postcode": 8468,
    "City": "HEINSDORFERGRUND",
    "State": "Sachsen",
    "Latitude": 50.6022,
    "Longitude": 12.3201,
    "Telephone Number": "03765/717373",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.6022,12.3201",
    "IsOpen24Hours": false,
    "id": "50.6022,12.3201"
  },
  {
    "Gas Station Number": "TD001248",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHEFFELSTR. 13",
    "Postcode": 78073,
    "City": "BAD DUERRHEIM",
    "State": "Baden-Württemberg",
    "Latitude": 48.0212,
    "Longitude": 8.5279,
    "Telephone Number": "07726-7545",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.0212,8.5279",
    "IsOpen24Hours": false,
    "id": "48.0212,8.5279"
  },
  {
    "Gas Station Number": "TD024638",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KOEPPELSDORFER STR. 69",
    "Postcode": 96515,
    "City": "SONNEBERG",
    "State": "Thüringen",
    "Latitude": 50.3562,
    "Longitude": 11.18,
    "Telephone Number": "03675-427258",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.3562,11.18",
    "IsOpen24Hours": false,
    "id": "50.3562,11.18"
  },
  {
    "Gas Station Number": "TD000955",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "VENNHAUSER ALLEE 266",
    "Postcode": 40627,
    "City": "DUESSELDORF",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.2144,
    "Longitude": 6.8649,
    "Telephone Number": "0211-279666",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2144,6.8649",
    "IsOpen24Hours": false,
    "id": "51.2144,6.8649"
  },
  {
    "Gas Station Number": "TD034124",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PLOENER STR. 2",
    "Postcode": 19395,
    "City": "PLAU AM SEE",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.4704,
    "Longitude": 12.2643,
    "Telephone Number": "038735-40270",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.4704,12.2643",
    "IsOpen24Hours": false,
    "id": "53.4704,12.2643"
  },
  {
    "Gas Station Number": "TD004689",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "TAUBENSTR. 40",
    "Postcode": 48282,
    "City": "EMSDETTEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.1649,
    "Longitude": 7.5094,
    "Telephone Number": "02572-8771733",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1649,7.5094",
    "IsOpen24Hours": false,
    "id": "52.1649,7.5094"
  },
  {
    "Gas Station Number": "TD141470",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HANNOVERSCHE STR. 29",
    "Postcode": 30916,
    "City": "ISERNHAGEN",
    "State": "Niedersachsen",
    "Latitude": 52.4341,
    "Longitude": 9.8636,
    "Telephone Number": "0511-612900",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4341,9.8636",
    "IsOpen24Hours": false,
    "id": "52.4341,9.8636"
  },
  {
    "Gas Station Number": "TD036350",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SAARLANDSTR. 40-42",
    "Postcode": 16515,
    "City": "ORANIENBURG",
    "State": "Brandenburg",
    "Latitude": 52.7383,
    "Longitude": 13.2491,
    "Telephone Number": "03301-3243",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.7383,13.2491",
    "IsOpen24Hours": false,
    "id": "52.7383,13.2491"
  },
  {
    "Gas Station Number": "TD110350",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "OESTINGHAUSER STR.14",
    "Postcode": 59494,
    "City": "SOEST",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5805,
    "Longitude": 8.1117,
    "Telephone Number": "02921-80663",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5805,8.1117",
    "IsOpen24Hours": false,
    "id": "51.5805,8.1117"
  },
  {
    "Gas Station Number": "TD037424",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BAHNHOFSTR. 36",
    "Postcode": 27404,
    "City": "ZEVEN",
    "State": "Niedersachsen",
    "Latitude": 53.2872,
    "Longitude": 9.2769,
    "Telephone Number": "04281-80380",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.2872,9.2769",
    "IsOpen24Hours": false,
    "id": "53.2872,9.2769"
  },
  {
    "Gas Station Number": "TD019398",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BIEFANGSTR. 92",
    "Postcode": 46149,
    "City": "OBERHAUSEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5136,
    "Longitude": 6.8274,
    "Telephone Number": "0208-669830",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5136,6.8274",
    "IsOpen24Hours": false,
    "id": "51.5136,6.8274"
  },
  {
    "Gas Station Number": "TD038406",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "IM HAG 2-4",
    "Postcode": 73230,
    "City": "KIRCHHEIM/TECK",
    "State": "Baden-Württemberg",
    "Latitude": 48.6422,
    "Longitude": 9.4337,
    "Telephone Number": "07021-863650",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.6422,9.4337",
    "IsOpen24Hours": false,
    "id": "48.6422,9.4337"
  },
  {
    "Gas Station Number": "TD000422",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DUESSELDORFER STR. 66",
    "Postcode": 40721,
    "City": "HILDEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.1647,
    "Longitude": 6.9171,
    "Telephone Number": "02103-55579",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1647,6.9171",
    "IsOpen24Hours": false,
    "id": "51.1647,6.9171"
  },
  {
    "Gas Station Number": "TD038620",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "PAXTONSTR. 2",
    "Postcode": 48488,
    "City": "EMSBUEREN",
    "State": "Niedersachsen",
    "Latitude": 52.3588,
    "Longitude": 7.2611,
    "Telephone Number": "05903-9321345",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3588,7.2611",
    "IsOpen24Hours": false,
    "id": "52.3588,7.2611"
  },
  {
    "Gas Station Number": "TD022871",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PULVERHAUSWEG 1",
    "Postcode": 99734,
    "City": "NORDHAUSEN",
    "State": "Thüringen",
    "Latitude": 51.4944,
    "Longitude": 10.8147,
    "Telephone Number": "03631-601162",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4944,10.8147",
    "IsOpen24Hours": false,
    "id": "51.4944,10.8147"
  },
  {
    "Gas Station Number": "TD037713",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HEERSTR. 324",
    "Postcode": 13593,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.5168,
    "Longitude": 13.1801,
    "Telephone Number": "030-30124300",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5168,13.1801",
    "IsOpen24Hours": false,
    "id": "52.5168,13.1801"
  },
  {
    "Gas Station Number": "TD041962",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BAHNHOFSTR. 8 A",
    "Postcode": 86981,
    "City": "KINSAU",
    "State": "Bayern",
    "Latitude": 47.8817,
    "Longitude": 10.8915,
    "Telephone Number": "08869228",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.8817,10.8915",
    "IsOpen24Hours": false,
    "id": "47.8817,10.8915"
  },
  {
    "Gas Station Number": "TD002337",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "HAUPTSTR. 180",
    "Postcode": 69168,
    "City": "WIESLOCH",
    "State": "Baden-Württemberg",
    "Latitude": 49.2835,
    "Longitude": 8.6863,
    "Telephone Number": "06222-50637",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.2835,8.6863",
    "IsOpen24Hours": false,
    "id": "49.2835,8.6863"
  },
  {
    "Gas Station Number": "TD041475",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "TAUNUSSTR. 1",
    "Postcode": 63811,
    "City": "STOCKSTADT",
    "State": "Bayern",
    "Latitude": 49.9609,
    "Longitude": 9.0807,
    "Telephone Number": "060279795656",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.9609,9.0807",
    "IsOpen24Hours": false,
    "id": "49.9609,9.0807"
  },
  {
    "Gas Station Number": "TD035196",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LANDSBERGER ALLEE 526",
    "Postcode": 12681,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.5449,
    "Longitude": 13.569,
    "Telephone Number": "030-54376273/74",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5449,13.569",
    "IsOpen24Hours": false,
    "id": "52.5449,13.569"
  },
  {
    "Gas Station Number": "TD039248",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "HICKLSTR. 2",
    "Postcode": 85764,
    "City": "OBERSCHLEISSHEIM",
    "State": "Bayern",
    "Latitude": 48.2652,
    "Longitude": 11.5932,
    "Telephone Number": "089-21894466",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.2652,11.5932",
    "IsOpen24Hours": false,
    "id": "48.2652,11.5932"
  },
  {
    "Gas Station Number": "TD038398",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "MANCHINGER STR. 84",
    "Postcode": 85053,
    "City": "INGOLSTADT",
    "State": "Bayern",
    "Latitude": 48.753,
    "Longitude": 11.4518,
    "Telephone Number": "0841/99389815",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.753,11.4518",
    "IsOpen24Hours": false,
    "id": "48.753,11.4518"
  },
  {
    "Gas Station Number": "TD001115",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DUERENER STR. 168-172",
    "Postcode": 52249,
    "City": "ESCHWEILER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.8189,
    "Longitude": 6.2821,
    "Telephone Number": "02403-4600",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8189,6.2821",
    "IsOpen24Hours": false,
    "id": "50.8189,6.2821"
  },
  {
    "Gas Station Number": "TD181490",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB- WUNNENSTEIN OST/A 81",
    "Postcode": 74360,
    "City": "ILSFELD",
    "State": "Baden-Württemberg",
    "Latitude": 49.043,
    "Longitude": 9.2625,
    "Telephone Number": "07062-4170",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.043,9.2625",
    "IsOpen24Hours": false,
    "id": "49.043,9.2625"
  },
  {
    "Gas Station Number": "TD037689",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AM DORN 5-7",
    "Postcode": 48308,
    "City": "SENDEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9041,
    "Longitude": 7.4884,
    "Telephone Number": "02536-345620",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9041,7.4884",
    "IsOpen24Hours": false,
    "id": "51.9041,7.4884"
  },
  {
    "Gas Station Number": "TD003340",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AUTOHOF AM ESCHENBACH 1",
    "Postcode": 97440,
    "City": "WERNECK",
    "State": "Bayern",
    "Latitude": 50.0033,
    "Longitude": 10.118,
    "Telephone Number": "09722-94620",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.0033,10.118",
    "IsOpen24Hours": false,
    "id": "50.0033,10.118"
  },
  {
    "Gas Station Number": "TD002774",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SIEMENSSTR. 2",
    "Postcode": 64289,
    "City": "DARMSTADT",
    "State": "Hessen",
    "Latitude": 49.8961,
    "Longitude": 8.6806,
    "Telephone Number": "06151-4929866",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.8961,8.6806",
    "IsOpen24Hours": false,
    "id": "49.8961,8.6806"
  },
  {
    "Gas Station Number": "TD036525",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "NUERNBERGER STR. 9",
    "Postcode": 70374,
    "City": "STUTTGART",
    "State": "Baden-Württemberg",
    "Latitude": 48.8061,
    "Longitude": 9.2324,
    "Telephone Number": "0711-522550",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8061,9.2324",
    "IsOpen24Hours": false,
    "id": "48.8061,9.2324"
  },
  {
    "Gas Station Number": "TD035238",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "IM NEYL 30",
    "Postcode": 59823,
    "City": "ARNSBERG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.3906,
    "Longitude": 8.1303,
    "Telephone Number": "02937-6473",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3906,8.1303",
    "IsOpen24Hours": false,
    "id": "51.3906,8.1303"
  },
  {
    "Gas Station Number": "TD000729",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BUNDESSTR.HAUS NR. 7",
    "Postcode": 52159,
    "City": "ROETGEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.6469,
    "Longitude": 6.1853,
    "Telephone Number": "02471-2510",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.6469,6.1853",
    "IsOpen24Hours": false,
    "id": "50.6469,6.1853"
  },
  {
    "Gas Station Number": "TD035444",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "IN DER WAAGE 1",
    "Postcode": 73463,
    "City": "WESTHAUSEN",
    "State": "Baden-Württemberg",
    "Latitude": 48.8831,
    "Longitude": 10.175,
    "Telephone Number": "07363-9547097",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8831,10.175",
    "IsOpen24Hours": false,
    "id": "48.8831,10.175"
  },
  {
    "Gas Station Number": "TD111280",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SENNER STR. 8",
    "Postcode": 33335,
    "City": "GUETERSLOH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9401,
    "Longitude": 8.487,
    "Telephone Number": "05209-980818",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9401,8.487",
    "IsOpen24Hours": false,
    "id": "51.9401,8.487"
  },
  {
    "Gas Station Number": "TD034611",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ARNO-NITZSCHE STRASSE 32",
    "Postcode": 4277,
    "City": "LEIPZIG",
    "State": "Sachsen",
    "Latitude": 51.3089,
    "Longitude": 12.3895,
    "Telephone Number": "0341-3018122",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3089,12.3895",
    "IsOpen24Hours": false,
    "id": "51.3089,12.3895"
  },
  {
    "Gas Station Number": "TD171350",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AUTOHOF VOGELHERD 4",
    "Postcode": 91236,
    "City": "ALFELD",
    "State": "Bayern",
    "Latitude": 49.4104,
    "Longitude": 11.5448,
    "Telephone Number": "09157-95170",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.4104,11.5448",
    "IsOpen24Hours": false,
    "id": "49.4104,11.5448"
  },
  {
    "Gas Station Number": "TD000735",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHIMMELBUSCHSTR. 27",
    "Postcode": 40699,
    "City": "ERKRATH-HOCHDAHL",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.2155,
    "Longitude": 6.9499,
    "Telephone Number": "02104-39604",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2155,6.9499",
    "IsOpen24Hours": false,
    "id": "51.2155,6.9499"
  },
  {
    "Gas Station Number": "TD035592",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AUTOHOF AN DER AUTOBAHN K4",
    "Postcode": 91161,
    "City": "HILPOLTSTEIN",
    "State": "Bayern",
    "Latitude": 49.1609,
    "Longitude": 11.2622,
    "Telephone Number": "09174-976666",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.1609,11.2622",
    "IsOpen24Hours": false,
    "id": "49.1609,11.2622"
  },
  {
    "Gas Station Number": "TD123070",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KOMMERNER STR. 147",
    "Postcode": 53879,
    "City": "EUSKIRCHEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.6566,
    "Longitude": 6.7681,
    "Telephone Number": "02251-929103",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.6566,6.7681",
    "IsOpen24Hours": false,
    "id": "50.6566,6.7681"
  },
  {
    "Gas Station Number": "TD037218",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BRAUNSCHWEIGER STR. 45 - 47",
    "Postcode": 38518,
    "City": "GIFHORN",
    "State": "Niedersachsen",
    "Latitude": 52.4767,
    "Longitude": 10.5442,
    "Telephone Number": "05371-13613",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4767,10.5442",
    "IsOpen24Hours": false,
    "id": "52.4767,10.5442"
  },
  {
    "Gas Station Number": "TD037697",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHUETZENSTR. 1A",
    "Postcode": 98527,
    "City": "SUHL",
    "State": "Thüringen",
    "Latitude": 50.5881,
    "Longitude": 10.7192,
    "Telephone Number": "03681-807271",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.5881,10.7192",
    "IsOpen24Hours": false,
    "id": "50.5881,10.7192"
  },
  {
    "Gas Station Number": "TD036822",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "OSTERDEICH 124-125",
    "Postcode": 28205,
    "City": "BREMEN",
    "State": "Bremen",
    "Latitude": 53.0669,
    "Longitude": 8.8443,
    "Telephone Number": "0421-440704",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.0669,8.8443",
    "IsOpen24Hours": false,
    "id": "53.0669,8.8443"
  },
  {
    "Gas Station Number": "TD031799",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BOLTENHAGENER STR. 11b",
    "Postcode": 23948,
    "City": "KLUETZ",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.9706,
    "Longitude": 11.1741,
    "Telephone Number": "038825-22578",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.9706,11.1741",
    "IsOpen24Hours": false,
    "id": "53.9706,11.1741"
  },
  {
    "Gas Station Number": "TD024869",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LUETZNER STR.7B",
    "Postcode": 4177,
    "City": "LEIPZIG",
    "State": "Sachsen",
    "Latitude": 51.3353,
    "Longitude": 12.3378,
    "Telephone Number": "0341-4800930",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3353,12.3378",
    "IsOpen24Hours": false,
    "id": "51.3353,12.3378"
  },
  {
    "Gas Station Number": "TD041699",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LANDSBERGER STR. 2",
    "Postcode": 82110,
    "City": "GERMERING",
    "State": "Bayern",
    "Latitude": 48.1367,
    "Longitude": 11.3911,
    "Telephone Number": "089847250",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.1367,11.3911",
    "IsOpen24Hours": false,
    "id": "48.1367,11.3911"
  },
  {
    "Gas Station Number": "TD007930",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BAHNHOFSTR. 15",
    "Postcode": 4668,
    "City": "GRIMMA",
    "State": "Sachsen",
    "Latitude": 51.2284,
    "Longitude": 12.7159,
    "Telephone Number": "03437-916889",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2284,12.7159",
    "IsOpen24Hours": false,
    "id": "51.2284,12.7159"
  },
  {
    "Gas Station Number": "TD042051",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AUGUST-HORCH-STR. 12",
    "Postcode": 95213,
    "City": "MUENCHBERG",
    "State": "Bayern",
    "Latitude": 50.2028,
    "Longitude": 11.7779,
    "Telephone Number": "092517109",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.2028,11.7779",
    "IsOpen24Hours": false,
    "id": "50.2028,11.7779"
  },
  {
    "Gas Station Number": "TD037986",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LANDSTRASSE 64",
    "Postcode": 42781,
    "City": "HAAN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.2049,
    "Longitude": 7.0415,
    "Telephone Number": "02129-3456929",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2049,7.0415",
    "IsOpen24Hours": false,
    "id": "51.2049,7.0415"
  },
  {
    "Gas Station Number": "TD027466",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BAHNHOFSTR.60",
    "Postcode": 38486,
    "City": "KLOETZE",
    "State": "Sachsen-Anhalt",
    "Latitude": 52.63,
    "Longitude": 11.1534,
    "Telephone Number": "03909-3890",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.63,11.1534",
    "IsOpen24Hours": false,
    "id": "52.63,11.1534"
  },
  {
    "Gas Station Number": "TD040196",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "TIEFSTADT 10",
    "Postcode": 84307,
    "City": "EGGENFELDEN",
    "State": "Bayern",
    "Latitude": 48.3958,
    "Longitude": 12.7661,
    "Telephone Number": "08721/508143",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.3958,12.7661",
    "IsOpen24Hours": false,
    "id": "48.3958,12.7661"
  },
  {
    "Gas Station Number": "TD023648",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ILMENAUER STR.2",
    "Postcode": 99326,
    "City": "STADTILM",
    "State": "Thüringen",
    "Latitude": 50.7677,
    "Longitude": 11.0648,
    "Telephone Number": "03629-3172",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.7677,11.0648",
    "IsOpen24Hours": false,
    "id": "50.7677,11.0648"
  },
  {
    "Gas Station Number": "TD006866",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAUPTSTR. 72",
    "Postcode": 74869,
    "City": "SCHWARZACH",
    "State": "Baden-Württemberg",
    "Latitude": 49.3666,
    "Longitude": 8.9815,
    "Telephone Number": "06262-6294",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.3666,8.9815",
    "IsOpen24Hours": false,
    "id": "49.3666,8.9815"
  },
  {
    "Gas Station Number": "TD040758",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BOUXWILLER STRASSE 1",
    "Postcode": 64832,
    "City": "BABENHAUSEN",
    "State": "Hessen",
    "Latitude": 49.9698,
    "Longitude": 8.9493,
    "Telephone Number": "06073-723376",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.9698,8.9493",
    "IsOpen24Hours": false,
    "id": "49.9698,8.9493"
  },
  {
    "Gas Station Number": "TD041038",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "KOECKERN WEST/BAB 9",
    "Postcode": 6794,
    "City": "SANDERSDORF-BREHNA",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.6082,
    "Longitude": 12.1845,
    "Telephone Number": "034954-39317",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6082,12.1845",
    "IsOpen24Hours": false,
    "id": "51.6082,12.1845"
  },
  {
    "Gas Station Number": "TD019356",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HESSENSTR. 79",
    "Postcode": 36358,
    "City": "HERBSTEIN",
    "State": "Hessen",
    "Latitude": 50.5565,
    "Longitude": 9.3504,
    "Telephone Number": "06643-1347",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.5565,9.3504",
    "IsOpen24Hours": false,
    "id": "50.5565,9.3504"
  },
  {
    "Gas Station Number": "TD006684",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FRANKFURTER STR. 85",
    "Postcode": 58553,
    "City": "HALVER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.1844,
    "Longitude": 7.5096,
    "Telephone Number": "02353-3322",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1844,7.5096",
    "IsOpen24Hours": false,
    "id": "51.1844,7.5096"
  },
  {
    "Gas Station Number": "TD000759",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BAHNSTR. 174",
    "Postcode": 41069,
    "City": "MOENCHENGLADBACH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.1818,
    "Longitude": 6.4118,
    "Telephone Number": "02161-592993",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1818,6.4118",
    "IsOpen24Hours": false,
    "id": "51.1818,6.4118"
  },
  {
    "Gas Station Number": "TD031971",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "NEUE REIHE 94C",
    "Postcode": 18225,
    "City": "KUEHLUNGSBORN",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 54.1449,
    "Longitude": 11.7356,
    "Telephone Number": "038293-7608",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.1449,11.7356",
    "IsOpen24Hours": false,
    "id": "54.1449,11.7356"
  },
  {
    "Gas Station Number": "TD037952",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ARTILLERIEPARK 5",
    "Postcode": 14624,
    "City": "DALLGOW",
    "State": "Brandenburg",
    "Latitude": 52.5335,
    "Longitude": 13.0599,
    "Telephone Number": "03322-2137842/43",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5335,13.0599",
    "IsOpen24Hours": false,
    "id": "52.5335,13.0599"
  },
  {
    "Gas Station Number": "TD038166",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "VAHRENER DAMM 10",
    "Postcode": 49661,
    "City": "CLOPPENBURG",
    "State": "Niedersachsen",
    "Latitude": 52.8272,
    "Longitude": 8.0003,
    "Telephone Number": "04471-7012924",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.8272,8.0003",
    "IsOpen24Hours": false,
    "id": "52.8272,8.0003"
  },
  {
    "Gas Station Number": "TD110090",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ISERLOHNER STR.45",
    "Postcode": 59423,
    "City": "UNNA",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5268,
    "Longitude": 7.6949,
    "Telephone Number": "02303-80000",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5268,7.6949",
    "IsOpen24Hours": false,
    "id": "51.5268,7.6949"
  },
  {
    "Gas Station Number": "TD035709",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AUTOHOF AN DER BAB 61",
    "Postcode": 55444,
    "City": "WALDLAUBERSHEIM",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.9242,
    "Longitude": 7.8247,
    "Telephone Number": "06707 242984863",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.9242,7.8247",
    "IsOpen24Hours": false,
    "id": "49.9242,7.8247"
  },
  {
    "Gas Station Number": "TD037945",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FRIEDERIKENHOFER STR. 1",
    "Postcode": 14979,
    "City": "GROSSBEEREN",
    "State": "Brandenburg",
    "Latitude": 52.3903,
    "Longitude": 13.3407,
    "Telephone Number": "033701-365783",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3903,13.3407",
    "IsOpen24Hours": false,
    "id": "52.3903,13.3407"
  },
  {
    "Gas Station Number": "TD033878",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WENDEWEHRSTR. 129",
    "Postcode": 99974,
    "City": "MUEHLHAUSEN",
    "State": "Thüringen",
    "Latitude": 51.2162,
    "Longitude": 10.4634,
    "Telephone Number": "03601-815176",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2162,10.4634",
    "IsOpen24Hours": false,
    "id": "51.2162,10.4634"
  },
  {
    "Gas Station Number": "TD040568",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BRAMFELDER STR. 86a",
    "Postcode": 22305,
    "City": "HAMBURG",
    "State": "Hamburg",
    "Latitude": 53.5879,
    "Longitude": 10.0528,
    "Telephone Number": "040-22605444",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.5879,10.0528",
    "IsOpen24Hours": false,
    "id": "53.5879,10.0528"
  },
  {
    "Gas Station Number": "TD007559",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ROELCKESTR. 74-76",
    "Postcode": 13088,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.5625,
    "Longitude": 13.4595,
    "Telephone Number": "030-92799956",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5625,13.4595",
    "IsOpen24Hours": false,
    "id": "52.5625,13.4595"
  },
  {
    "Gas Station Number": "TD000956",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KOLONIESTR. 141",
    "Postcode": 47057,
    "City": "DUISBURG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4198,
    "Longitude": 6.7866,
    "Telephone Number": "0203-352727",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4198,6.7866",
    "IsOpen24Hours": false,
    "id": "51.4198,6.7866"
  },
  {
    "Gas Station Number": "TD192060",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DURMERSHEIMER STR. 14a",
    "Postcode": 76316,
    "City": "MALSCH",
    "State": "Baden-Württemberg",
    "Latitude": 48.902,
    "Longitude": 8.3108,
    "Telephone Number": "07246-8484",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.902,8.3108",
    "IsOpen24Hours": false,
    "id": "48.902,8.3108"
  },
  {
    "Gas Station Number": "TD038257",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "4 SOPHIE-OPEL STR.",
    "Postcode": 55286,
    "City": "WOERRSTADT",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.8398,
    "Longitude": 8.1423,
    "Telephone Number": "06732255117",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.8398,8.1423",
    "IsOpen24Hours": false,
    "id": "49.8398,8.1423"
  },
  {
    "Gas Station Number": "TD007302",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DORFSTR.37a",
    "Postcode": 16356,
    "City": "AHRENSFELDE",
    "State": "Brandenburg",
    "Latitude": 52.5775,
    "Longitude": 13.5847,
    "Telephone Number": "030-93497825",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5775,13.5847",
    "IsOpen24Hours": false,
    "id": "52.5775,13.5847"
  },
  {
    "Gas Station Number": "TD041137",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ROTTWEILER STR. 2",
    "Postcode": 78727,
    "City": "OBERNDORF",
    "State": "Baden-Württemberg",
    "Latitude": 48.2861,
    "Longitude": 8.5781,
    "Telephone Number": "074238758974",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.2861,8.5781",
    "IsOpen24Hours": false,
    "id": "48.2861,8.5781"
  },
  {
    "Gas Station Number": "TD039461",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "OBERNDORFER STR. 23a",
    "Postcode": 84032,
    "City": "LANDSHUT",
    "State": "Bayern",
    "Latitude": 48.548,
    "Longitude": 12.1318,
    "Telephone Number": "0871-40471787",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.548,12.1318",
    "IsOpen24Hours": false,
    "id": "48.548,12.1318"
  },
  {
    "Gas Station Number": "TD030932",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "1 EILENBURGER STR.",
    "Postcode": 4849,
    "City": "BAD DUEBEN",
    "State": "Sachsen",
    "Latitude": 51.5845,
    "Longitude": 12.5923,
    "Telephone Number": "034243-23151",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5845,12.5923",
    "IsOpen24Hours": false,
    "id": "51.5845,12.5923"
  },
  {
    "Gas Station Number": "TD000962",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHLACHTHOFSTR. 88",
    "Postcode": 56073,
    "City": "KOBLENZ",
    "State": "Rheinland-Pfalz",
    "Latitude": 50.3625,
    "Longitude": 7.5716,
    "Telephone Number": "0261-43111",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.3625,7.5716",
    "IsOpen24Hours": false,
    "id": "50.3625,7.5716"
  },
  {
    "Gas Station Number": "TD036368",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAMBURGER STR. 18",
    "Postcode": 19348,
    "City": "PERLEBERG",
    "State": "Brandenburg",
    "Latitude": 53.0827,
    "Longitude": 11.8588,
    "Telephone Number": "03876-785150",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.0827,11.8588",
    "IsOpen24Hours": false,
    "id": "53.0827,11.8588"
  },
  {
    "Gas Station Number": "TD002279",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ALTLUSSHEIMER STR. 54",
    "Postcode": 68809,
    "City": "NEULUSSHEIM",
    "State": "Baden-Württemberg",
    "Latitude": 49.2944,
    "Longitude": 8.5169,
    "Telephone Number": "06205-31735",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.2944,8.5169",
    "IsOpen24Hours": false,
    "id": "49.2944,8.5169"
  },
  {
    "Gas Station Number": "TD038422",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LAUENFOERDERSTR. 3",
    "Postcode": 37176,
    "City": "NOERTEN-HARDENBERG",
    "State": "Niedersachsen",
    "Latitude": 51.6265,
    "Longitude": 9.9342,
    "Telephone Number": "05503-9159377",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6265,9.9342",
    "IsOpen24Hours": false,
    "id": "51.6265,9.9342"
  },
  {
    "Gas Station Number": "TD007500",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WESTERHAEUSER STR. 38",
    "Postcode": 6484,
    "City": "QUEDLINBURG",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.7923,
    "Longitude": 11.1291,
    "Telephone Number": "03946-702014",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.7923,11.1291",
    "IsOpen24Hours": false,
    "id": "51.7923,11.1291"
  },
  {
    "Gas Station Number": "TD039891",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "EDISONSTR. 44",
    "Postcode": 24145,
    "City": "KIEL",
    "State": "Schleswig-Holstein",
    "Latitude": 54.2774,
    "Longitude": 10.161,
    "Telephone Number": "0431-717570",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.2774,10.161",
    "IsOpen24Hours": false,
    "id": "54.2774,10.161"
  },
  {
    "Gas Station Number": "TD032805",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BORNAISCHE STR. 227",
    "Postcode": 4279,
    "City": "LEIPZIG",
    "State": "Sachsen",
    "Latitude": 51.2849,
    "Longitude": 12.3946,
    "Telephone Number": "0341-3389322",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2849,12.3946",
    "IsOpen24Hours": false,
    "id": "51.2849,12.3946"
  },
  {
    "Gas Station Number": "TD028829",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHIPKAUER STR.20",
    "Postcode": 1987,
    "City": "SCHWARZHEIDE",
    "State": "Brandenburg",
    "Latitude": 51.4665,
    "Longitude": 13.8682,
    "Telephone Number": "035752-50893",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4665,13.8682",
    "IsOpen24Hours": false,
    "id": "51.4665,13.8682"
  },
  {
    "Gas Station Number": "TD001891",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BRESLAUER STR. 1",
    "Postcode": 75181,
    "City": "PFORZHEIM",
    "State": "Baden-Württemberg",
    "Latitude": 48.8848,
    "Longitude": 8.7273,
    "Telephone Number": "07231-63949",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8848,8.7273",
    "IsOpen24Hours": false,
    "id": "48.8848,8.7273"
  },
  {
    "Gas Station Number": "TD037523",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "VOLMESTR. 49",
    "Postcode": 58579,
    "City": "SCHALKSMUEHLE",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.2453,
    "Longitude": 7.524,
    "Telephone Number": "02355-1404",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2453,7.524",
    "IsOpen24Hours": false,
    "id": "51.2453,7.524"
  },
  {
    "Gas Station Number": "TD025379",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FREIBERGER STR. 72",
    "Postcode": 9569,
    "City": "OEDERAN",
    "State": "Sachsen",
    "Latitude": 50.8649,
    "Longitude": 13.1789,
    "Telephone Number": "037292-60273",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8649,13.1789",
    "IsOpen24Hours": false,
    "id": "50.8649,13.1789"
  },
  {
    "Gas Station Number": "TD000133",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DAHLENER STR.520",
    "Postcode": 41239,
    "City": "MOENCHENGLADBACH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.1595,
    "Longitude": 6.4153,
    "Telephone Number": "02166-31582",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1595,6.4153",
    "IsOpen24Hours": false,
    "id": "51.1595,6.4153"
  },
  {
    "Gas Station Number": "TD031385",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AN DER B87",
    "Postcode": 6628,
    "City": "BAD KOESEN",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.1389,
    "Longitude": 11.7302,
    "Telephone Number": "034463-26808",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1389,11.7302",
    "IsOpen24Hours": false,
    "id": "51.1389,11.7302"
  },
  {
    "Gas Station Number": "TD000855",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "IN DEN ENGEMATTEN 2",
    "Postcode": 79286,
    "City": "GLOTTERTAL",
    "State": "Baden-Württemberg",
    "Latitude": 48.0545,
    "Longitude": 7.9227,
    "Telephone Number": "07684-909083",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.0545,7.9227",
    "IsOpen24Hours": false,
    "id": "48.0545,7.9227"
  },
  {
    "Gas Station Number": "TD027334",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ANDERLEBENER STR. 53",
    "Postcode": 39387,
    "City": "OSCHERSLEBEN",
    "State": "Sachsen-Anhalt",
    "Latitude": 52.0285,
    "Longitude": 11.2461,
    "Telephone Number": "03949-81693",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0285,11.2461",
    "IsOpen24Hours": false,
    "id": "52.0285,11.2461"
  },
  {
    "Gas Station Number": "TD040436",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "RASTANLAGE 10/ B31",
    "Postcode": 79877,
    "City": "FRIEDENWEILER",
    "State": "Baden-Württemberg",
    "Latitude": 47.8974,
    "Longitude": 8.2744,
    "Telephone Number": "076546829903",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.8974,8.2744",
    "IsOpen24Hours": false,
    "id": "47.8974,8.2744"
  },
  {
    "Gas Station Number": "TD020511",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PRENZLAUER ALLEE 1-4",
    "Postcode": 10405,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.5289,
    "Longitude": 13.4181,
    "Telephone Number": "030-88910772",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5289,13.4181",
    "IsOpen24Hours": false,
    "id": "52.5289,13.4181"
  },
  {
    "Gas Station Number": "TD020438",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HOLZMARKTSTR. 36-42",
    "Postcode": 10243,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.5114,
    "Longitude": 13.4285,
    "Telephone Number": "030-2493777",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5114,13.4285",
    "IsOpen24Hours": false,
    "id": "52.5114,13.4285"
  },
  {
    "Gas Station Number": "TD040733",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "CELLER STR. 27",
    "Postcode": 29313,
    "City": "HAMBUEHREN",
    "State": "Niedersachsen",
    "Latitude": 52.634,
    "Longitude": 9.9405,
    "Telephone Number": "05084-6260",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.634,9.9405",
    "IsOpen24Hours": false,
    "id": "52.634,9.9405"
  },
  {
    "Gas Station Number": "TD033894",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SALZUFLER ALLEE 40",
    "Postcode": 14943,
    "City": "LUCKENWALDE",
    "State": "Brandenburg",
    "Latitude": 52.0885,
    "Longitude": 13.1792,
    "Telephone Number": "03371-622696",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0885,13.1792",
    "IsOpen24Hours": false,
    "id": "52.0885,13.1792"
  },
  {
    "Gas Station Number": "TD041764",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HATTSTEINSTRASSE 2",
    "Postcode": 63939,
    "City": "WOERTH AM MAIN",
    "State": "Bayern",
    "Latitude": 49.7835,
    "Longitude": 9.17,
    "Telephone Number": "093727063484",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.7835,9.17",
    "IsOpen24Hours": false,
    "id": "49.7835,9.17"
  },
  {
    "Gas Station Number": "TD024430",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AUGUST-BEBEL-STR. 98",
    "Postcode": 36433,
    "City": "BAD SALZUNGEN",
    "State": "Thüringen",
    "Latitude": 50.8083,
    "Longitude": 10.2584,
    "Telephone Number": "03695-622489",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8083,10.2584",
    "IsOpen24Hours": false,
    "id": "50.8083,10.2584"
  },
  {
    "Gas Station Number": "TD040477",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BAHNHOFSTR. 22",
    "Postcode": 89168,
    "City": "NIEDERSTOTZINGEN",
    "State": "Baden-Württemberg",
    "Latitude": 48.5361,
    "Longitude": 10.237,
    "Telephone Number": "07325/919070",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.5361,10.237",
    "IsOpen24Hours": false,
    "id": "48.5361,10.237"
  },
  {
    "Gas Station Number": "TD124060",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LEININGER STR. 39-41",
    "Postcode": 67269,
    "City": "GRUENSTADT",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.5469,
    "Longitude": 8.1593,
    "Telephone Number": "06359-92214",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.5469,8.1593",
    "IsOpen24Hours": false,
    "id": "49.5469,8.1593"
  },
  {
    "Gas Station Number": "TD040808",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "VELBURGER STR. 1",
    "Postcode": 92331,
    "City": "PARSBERG",
    "State": "Bayern",
    "Latitude": 49.1625,
    "Longitude": 11.7161,
    "Telephone Number": "09492954590",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.1625,11.7161",
    "IsOpen24Hours": false,
    "id": "49.1625,11.7161"
  },
  {
    "Gas Station Number": "TD190620",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BLOCKSBERGSTR. 55",
    "Postcode": 66955,
    "City": "PIRMASENS",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.1919,
    "Longitude": 7.5997,
    "Telephone Number": "06331-75557",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.1919,7.5997",
    "IsOpen24Hours": false,
    "id": "49.1919,7.5997"
  },
  {
    "Gas Station Number": "TD033969",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "RHINSTRASSE 52",
    "Postcode": 12681,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.5184,
    "Longitude": 13.52,
    "Telephone Number": "030-54376256",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5184,13.52",
    "IsOpen24Hours": false,
    "id": "52.5184,13.52"
  },
  {
    "Gas Station Number": "TD039446",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "MEMMINGER HAU 1",
    "Postcode": 89564,
    "City": "NATTHEIM",
    "State": "Baden-Württemberg",
    "Latitude": 48.6894,
    "Longitude": 10.2133,
    "Telephone Number": "073217478314",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.6894,10.2133",
    "IsOpen24Hours": false,
    "id": "48.6894,10.2133"
  },
  {
    "Gas Station Number": "TD037465",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MUENDELHEIMER STR. 106",
    "Postcode": 47259,
    "City": "DUISBURG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.3699,
    "Longitude": 6.7399,
    "Telephone Number": "0203-781405",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3699,6.7399",
    "IsOpen24Hours": false,
    "id": "51.3699,6.7399"
  },
  {
    "Gas Station Number": "TD000867",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AM REMBERG 94",
    "Postcode": 44269,
    "City": "DORTMUND",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4937,
    "Longitude": 7.5206,
    "Telephone Number": "0231-42786733",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4937,7.5206",
    "IsOpen24Hours": false,
    "id": "51.4937,7.5206"
  },
  {
    "Gas Station Number": "TD001628",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "STORLACHSTR. 2",
    "Postcode": 72760,
    "City": "REUTLINGEN",
    "State": "Baden-Württemberg",
    "Latitude": 48.504,
    "Longitude": 9.2124,
    "Telephone Number": "07121-334290",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.504,9.2124",
    "IsOpen24Hours": false,
    "id": "48.504,9.2124"
  },
  {
    "Gas Station Number": "TD021600",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GRUNDSTR. 99",
    "Postcode": 1326,
    "City": "DRESDEN",
    "State": "Sachsen",
    "Latitude": 51.0581,
    "Longitude": 13.8243,
    "Telephone Number": "0351-2683294",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0581,13.8243",
    "IsOpen24Hours": false,
    "id": "51.0581,13.8243"
  },
  {
    "Gas Station Number": "TD003947",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KIELER STR. 387-391",
    "Postcode": 24536,
    "City": "NEUMUENSTER",
    "State": "Schleswig-Holstein",
    "Latitude": 54.1028,
    "Longitude": 9.9903,
    "Telephone Number": "04321-31282",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.1028,9.9903",
    "IsOpen24Hours": false,
    "id": "54.1028,9.9903"
  },
  {
    "Gas Station Number": "TD028944",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SPREMBERGER STR. 30",
    "Postcode": 3103,
    "City": "NEUPETERSHAIN",
    "State": "Brandenburg",
    "Latitude": 51.5921,
    "Longitude": 14.1666,
    "Telephone Number": "035751/2100",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5921,14.1666",
    "IsOpen24Hours": false,
    "id": "51.5921,14.1666"
  },
  {
    "Gas Station Number": "TD039206",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AM HELLENKREUZ 1",
    "Postcode": 53332,
    "City": "BORNHEIM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.7624,
    "Longitude": 6.9758,
    "Telephone Number": "02222-9891379",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.7624,6.9758",
    "IsOpen24Hours": false,
    "id": "50.7624,6.9758"
  },
  {
    "Gas Station Number": "TD029835",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LINDENSTR. 16",
    "Postcode": 17358,
    "City": "TORGELOW",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.6299,
    "Longitude": 13.9997,
    "Telephone Number": "03976-202336",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.6299,13.9997",
    "IsOpen24Hours": false,
    "id": "53.6299,13.9997"
  },
  {
    "Gas Station Number": "TD027367",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BERLINER CH. 103",
    "Postcode": 39288,
    "City": "BURG",
    "State": "Sachsen-Anhalt",
    "Latitude": 52.2734,
    "Longitude": 11.8722,
    "Telephone Number": "03921-944029",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2734,11.8722",
    "IsOpen24Hours": false,
    "id": "52.2734,11.8722"
  },
  {
    "Gas Station Number": "TD170680",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LAUFAMHOLZSTR. 146",
    "Postcode": 90482,
    "City": "NUERNBERG",
    "State": "Bayern",
    "Latitude": 49.4652,
    "Longitude": 11.1526,
    "Telephone Number": "0911-501968",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.4652,11.1526",
    "IsOpen24Hours": false,
    "id": "49.4652,11.1526"
  },
  {
    "Gas Station Number": "TD028167",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "BERLINER STR. 123",
    "Postcode": 14641,
    "City": "NAUEN",
    "State": "Brandenburg",
    "Latitude": 52.5957,
    "Longitude": 12.8871,
    "Telephone Number": "03321-455369",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5957,12.8871",
    "IsOpen24Hours": false,
    "id": "52.5957,12.8871"
  },
  {
    "Gas Station Number": "TD000398",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KOELNER STR. 451",
    "Postcode": 45481,
    "City": "MUELHEIM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.3635,
    "Longitude": 6.8633,
    "Telephone Number": "0208-480146",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3635,6.8633",
    "IsOpen24Hours": false,
    "id": "51.3635,6.8633"
  },
  {
    "Gas Station Number": "TD025502",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HARTENSTEINER STR. 11",
    "Postcode": 8297,
    "City": "ZWOENITZ",
    "State": "Sachsen",
    "Latitude": 50.6344,
    "Longitude": 12.794,
    "Telephone Number": "037754-5536",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.6344,12.794",
    "IsOpen24Hours": false,
    "id": "50.6344,12.794"
  },
  {
    "Gas Station Number": "TD026294",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BLUMENAUER STR.9",
    "Postcode": 9526,
    "City": "OLBERNHAU",
    "State": "Sachsen",
    "Latitude": 50.6616,
    "Longitude": 13.3303,
    "Telephone Number": "037360-74280",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.6616,13.3303",
    "IsOpen24Hours": false,
    "id": "50.6616,13.3303"
  },
  {
    "Gas Station Number": "TD000113",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WILLY-BRANDT-STR. 206",
    "Postcode": 46535,
    "City": "DINSLAKEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5496,
    "Longitude": 6.7289,
    "Telephone Number": "02064-732480",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5496,6.7289",
    "IsOpen24Hours": false,
    "id": "51.5496,6.7289"
  },
  {
    "Gas Station Number": "TD024109",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "RUDOLSTAEDTER STR. 13",
    "Postcode": 7318,
    "City": "SAALFELD",
    "State": "Thüringen",
    "Latitude": 50.6574,
    "Longitude": 11.3474,
    "Telephone Number": "03671-2264",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.6574,11.3474",
    "IsOpen24Hours": false,
    "id": "50.6574,11.3474"
  },
  {
    "Gas Station Number": "TD034892",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DR.-LEBER-STR. 8",
    "Postcode": 23966,
    "City": "WISMAR",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.8898,
    "Longitude": 11.4714,
    "Telephone Number": "03841-40523",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.8898,11.4714",
    "IsOpen24Hours": false,
    "id": "53.8898,11.4714"
  },
  {
    "Gas Station Number": "TD007948",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "EUTINER STR. 82",
    "Postcode": 23689,
    "City": "PANSDORF",
    "State": "Schleswig-Holstein",
    "Latitude": 53.9876,
    "Longitude": 10.7129,
    "Telephone Number": "04504-1786",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.9876,10.7129",
    "IsOpen24Hours": false,
    "id": "53.9876,10.7129"
  },
  {
    "Gas Station Number": "TD110830",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "BOTTROPER STR. 248",
    "Postcode": 45964,
    "City": "GLADBECK",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5662,
    "Longitude": 6.9647,
    "Telephone Number": "02043-489074",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5662,6.9647",
    "IsOpen24Hours": false,
    "id": "51.5662,6.9647"
  },
  {
    "Gas Station Number": "TD200328",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GOLDBECKSTR. 1",
    "Postcode": 69493,
    "City": "HIRSCHBERG",
    "State": "Baden-Württemberg",
    "Latitude": 49.5077,
    "Longitude": 8.6373,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.5077,8.6373",
    "IsOpen24Hours": false,
    "id": "49.5077,8.6373"
  },
  {
    "Gas Station Number": "TD041186",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LEITSCHAECKER 2",
    "Postcode": 89257,
    "City": "ILLERTISSEN",
    "State": "Bayern",
    "Latitude": 48.2198,
    "Longitude": 10.1275,
    "Telephone Number": "07303/1579395",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.2198,10.1275",
    "IsOpen24Hours": false,
    "id": "48.2198,10.1275"
  },
  {
    "Gas Station Number": "TD039636",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ROSTOCKER STR . 72",
    "Postcode": 18190,
    "City": "SANITZ",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 54.0777,
    "Longitude": 12.3673,
    "Telephone Number": "038209-308",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.0777,12.3673",
    "IsOpen24Hours": false,
    "id": "54.0777,12.3673"
  },
  {
    "Gas Station Number": "TD000942",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PAFFRATHER STR. 215",
    "Postcode": 51469,
    "City": "BERGISCH GLADBACH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.9953,
    "Longitude": 7.1098,
    "Telephone Number": "02202-920265",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9953,7.1098",
    "IsOpen24Hours": false,
    "id": "50.9953,7.1098"
  },
  {
    "Gas Station Number": "TD040634",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "JULIUS-LEBER-STR. 2c",
    "Postcode": 53123,
    "City": "BONN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.7093,
    "Longitude": 7.0546,
    "Telephone Number": "0228-96285704",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.7093,7.0546",
    "IsOpen24Hours": false,
    "id": "50.7093,7.0546"
  },
  {
    "Gas Station Number": "TD041616",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BRESLAUER STR.45",
    "Postcode": 38440,
    "City": "WOLFSBURG",
    "State": "Niedersachsen",
    "Latitude": 52.4192,
    "Longitude": 10.7542,
    "Telephone Number": "05361-39000",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4192,10.7542",
    "IsOpen24Hours": false,
    "id": "52.4192,10.7542"
  },
  {
    "Gas Station Number": "TD004390",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PORTASTR. 44",
    "Postcode": 32457,
    "City": "PORTA WESTFALICA",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.2532,
    "Longitude": 8.9097,
    "Telephone Number": "0571-51142",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2532,8.9097",
    "IsOpen24Hours": false,
    "id": "52.2532,8.9097"
  },
  {
    "Gas Station Number": "TD038737",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SAARSTR. 6",
    "Postcode": 55122,
    "City": "MAINZ",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.9977,
    "Longitude": 8.2536,
    "Telephone Number": "06131-381434",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.9977,8.2536",
    "IsOpen24Hours": false,
    "id": "49.9977,8.2536"
  },
  {
    "Gas Station Number": "TD040287",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WENSCHTSTR. 7",
    "Postcode": 57078,
    "City": "SIEGEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.9125,
    "Longitude": 8.0074,
    "Telephone Number": "0271-74113512",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9125,8.0074",
    "IsOpen24Hours": false,
    "id": "50.9125,8.0074"
  },
  {
    "Gas Station Number": "TD034843",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WARNOWUFER 53",
    "Postcode": 18057,
    "City": "ROSTOCK",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 54.0935,
    "Longitude": 12.1155,
    "Telephone Number": "0381-4903119",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.0935,12.1155",
    "IsOpen24Hours": false,
    "id": "54.0935,12.1155"
  },
  {
    "Gas Station Number": "TD032813",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MAGDEBURGER STR. 84",
    "Postcode": 39261,
    "City": "ZERBST",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.975,
    "Longitude": 12.066,
    "Telephone Number": "03923-780437",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.975,12.066",
    "IsOpen24Hours": false,
    "id": "51.975,12.066"
  },
  {
    "Gas Station Number": "TD039131",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MOORREDDER 1",
    "Postcode": 23570,
    "City": "LUEBECK",
    "State": "Schleswig-Holstein",
    "Latitude": 53.9627,
    "Longitude": 10.8611,
    "Telephone Number": "04502-3510",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.9627,10.8611",
    "IsOpen24Hours": false,
    "id": "53.9627,10.8611"
  },
  {
    "Gas Station Number": "TD003384",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAFENSTR. 41",
    "Postcode": 55276,
    "City": "OPPENHEIM",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.8606,
    "Longitude": 8.3568,
    "Telephone Number": "06133-9427",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.8606,8.3568",
    "IsOpen24Hours": false,
    "id": "49.8606,8.3568"
  },
  {
    "Gas Station Number": "TD027086",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MAGDEBURGER STR. 39-45",
    "Postcode": 38820,
    "City": "HALBERSTADT",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.898,
    "Longitude": 11.0634,
    "Telephone Number": "03941-600139",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.898,11.0634",
    "IsOpen24Hours": false,
    "id": "51.898,11.0634"
  },
  {
    "Gas Station Number": "TD038000",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "GUTENBERGSTRASSE 2",
    "Postcode": 87781,
    "City": "UNGERHAUSEN",
    "State": "Bayern",
    "Latitude": 48.0183,
    "Longitude": 10.2736,
    "Telephone Number": "08393-9439170",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.0183,10.2736",
    "IsOpen24Hours": false,
    "id": "48.0183,10.2736"
  },
  {
    "Gas Station Number": "TD034728",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB BOERDE SUED / A2",
    "Postcode": 39343,
    "City": "GROSS SANTERSLEBEN",
    "State": "Sachsen-Anhalt",
    "Latitude": 52.1841,
    "Longitude": 11.4502,
    "Telephone Number": "039206-68764",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1841,11.4502",
    "IsOpen24Hours": false,
    "id": "52.1841,11.4502"
  },
  {
    "Gas Station Number": "TD038117",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB 4 / ALTENBURGER LAND-SUED",
    "Postcode": 4626,
    "City": "SCHMOELLN OT WEISSBACH",
    "State": "Thüringen",
    "Latitude": 50.8567,
    "Longitude": 12.3135,
    "Telephone Number": "034491/554813",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8567,12.3135",
    "IsOpen24Hours": false,
    "id": "50.8567,12.3135"
  },
  {
    "Gas Station Number": "TD041913",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PETKUMER STR. 284",
    "Postcode": 26725,
    "City": "EMDEN",
    "State": "Niedersachsen",
    "Latitude": 53.3407,
    "Longitude": 7.2266,
    "Telephone Number": "04921-6888855",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.3407,7.2266",
    "IsOpen24Hours": false,
    "id": "53.3407,7.2266"
  },
  {
    "Gas Station Number": "TD041830",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "OBERWINKL 4",
    "Postcode": 83355,
    "City": "GRABENSTAETT",
    "State": "Bayern",
    "Latitude": 47.828,
    "Longitude": 12.5211,
    "Telephone Number": "08661982510",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.828,12.5211",
    "IsOpen24Hours": false,
    "id": "47.828,12.5211"
  },
  {
    "Gas Station Number": "TD040048",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GRUBER STR. 14",
    "Postcode": 85599,
    "City": "PARSDORF",
    "State": "Bayern",
    "Latitude": 48.1477,
    "Longitude": 11.7878,
    "Telephone Number": "08932601090",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.1477,11.7878",
    "IsOpen24Hours": false,
    "id": "48.1477,11.7878"
  },
  {
    "Gas Station Number": "TD024521",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PROBSTZELLAER STR. 15",
    "Postcode": 98743,
    "City": "GRAEFENTHAL",
    "State": "Thüringen",
    "Latitude": 50.5273,
    "Longitude": 11.3127,
    "Telephone Number": "036703-80739",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.5273,11.3127",
    "IsOpen24Hours": false,
    "id": "50.5273,11.3127"
  },
  {
    "Gas Station Number": "TD034561",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HANS-GUENTER-BOCK-STR. 8",
    "Postcode": 16798,
    "City": "FUERSTENBERG",
    "State": "Brandenburg",
    "Latitude": 53.1717,
    "Longitude": 13.1408,
    "Telephone Number": "033093-32106",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.1717,13.1408",
    "IsOpen24Hours": false,
    "id": "53.1717,13.1408"
  },
  {
    "Gas Station Number": "TD041541",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BRUNOSTRASSE 34",
    "Postcode": 54329,
    "City": "KONZ",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.7106,
    "Longitude": 6.5911,
    "Telephone Number": "06501607546",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.7106,6.5911",
    "IsOpen24Hours": false,
    "id": "49.7106,6.5911"
  },
  {
    "Gas Station Number": "TD004069",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB HOLMMOORWEG WEST/A 7",
    "Postcode": 25451,
    "City": "QUICKBORN",
    "State": "Schleswig-Holstein",
    "Latitude": 53.7177,
    "Longitude": 9.938,
    "Telephone Number": "04106-9769520",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.7177,9.938",
    "IsOpen24Hours": false,
    "id": "53.7177,9.938"
  },
  {
    "Gas Station Number": "TD020271",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "RHINSTRASSE 175",
    "Postcode": 13053,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.5367,
    "Longitude": 13.5154,
    "Telephone Number": "030-9824536",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5367,13.5154",
    "IsOpen24Hours": false,
    "id": "52.5367,13.5154"
  },
  {
    "Gas Station Number": "TD029124",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BERLINER STR. 23",
    "Postcode": 14712,
    "City": "RATHENOW",
    "State": "Brandenburg",
    "Latitude": 52.6041,
    "Longitude": 12.3479,
    "Telephone Number": "03385-5200351",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.6041,12.3479",
    "IsOpen24Hours": false,
    "id": "52.6041,12.3479"
  },
  {
    "Gas Station Number": "TD023275",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ERFURTER STR. 60",
    "Postcode": 98693,
    "City": "ILMENAU",
    "State": "Thüringen",
    "Latitude": 50.6913,
    "Longitude": 10.905,
    "Telephone Number": "03677-840890",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.6913,10.905",
    "IsOpen24Hours": false,
    "id": "50.6913,10.905"
  },
  {
    "Gas Station Number": "TD025981",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LIMBACHER STR. 58",
    "Postcode": 9113,
    "City": "CHEMNITZ",
    "State": "Sachsen",
    "Latitude": 50.838,
    "Longitude": 12.897,
    "Telephone Number": "0371-3363873",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.838,12.897",
    "IsOpen24Hours": false,
    "id": "50.838,12.897"
  },
  {
    "Gas Station Number": "TD041582",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LINDENSTRASSE 25",
    "Postcode": 54662,
    "City": "HERFORST",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.9463,
    "Longitude": 6.6905,
    "Telephone Number": "06562930304",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.9463,6.6905",
    "IsOpen24Hours": false,
    "id": "49.9463,6.6905"
  },
  {
    "Gas Station Number": "TD036731",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DEGGENDORFER STRASSE 61",
    "Postcode": 94447,
    "City": "PLATTLING",
    "State": "Bayern",
    "Latitude": 48.7828,
    "Longitude": 12.8804,
    "Telephone Number": "09931-3696",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.7828,12.8804",
    "IsOpen24Hours": false,
    "id": "48.7828,12.8804"
  },
  {
    "Gas Station Number": "TD000410",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "STAENDENHOF 1, B10",
    "Postcode": 66957,
    "City": "RUPPERTSWEILER",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.2041,
    "Longitude": 7.6961,
    "Telephone Number": "06395-6280",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.2041,7.6961",
    "IsOpen24Hours": false,
    "id": "49.2041,7.6961"
  },
  {
    "Gas Station Number": "TD037028",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KROHNSTIEG 71-75",
    "Postcode": 22415,
    "City": "HAMBURG",
    "State": "Hamburg",
    "Latitude": 53.6483,
    "Longitude": 10.006,
    "Telephone Number": "040-5319690",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.6483,10.006",
    "IsOpen24Hours": false,
    "id": "53.6483,10.006"
  },
  {
    "Gas Station Number": "TD023234",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "POSTSTR. 12",
    "Postcode": 98559,
    "City": "OBERHOF",
    "State": "Thüringen",
    "Latitude": 50.705,
    "Longitude": 10.7319,
    "Telephone Number": "036842-22598",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.705,10.7319",
    "IsOpen24Hours": false,
    "id": "50.705,10.7319"
  },
  {
    "Gas Station Number": "TD003855",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "NEUHOEFER STR. 28",
    "Postcode": 21107,
    "City": "HAMBURG",
    "State": "Hamburg",
    "Latitude": 53.5108,
    "Longitude": 9.9845,
    "Telephone Number": "040-7532295",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.5108,9.9845",
    "IsOpen24Hours": false,
    "id": "53.5108,9.9845"
  },
  {
    "Gas Station Number": "TD024950",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FRANKENBERGER STR. 56",
    "Postcode": 9661,
    "City": "HAINICHEN",
    "State": "Sachsen",
    "Latitude": 50.968,
    "Longitude": 13.1051,
    "Telephone Number": "037207-2588",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.968,13.1051",
    "IsOpen24Hours": false,
    "id": "50.968,13.1051"
  },
  {
    "Gas Station Number": "TD111430",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GRAVENHORSTER STR.21",
    "Postcode": 49477,
    "City": "IBBENBUEREN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.2745,
    "Longitude": 7.7049,
    "Telephone Number": "05451-894751",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2745,7.7049",
    "IsOpen24Hours": false,
    "id": "52.2745,7.7049"
  },
  {
    "Gas Station Number": "TD003822",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "TURNSTR. 3",
    "Postcode": 25335,
    "City": "ELMSHORN",
    "State": "Schleswig-Holstein",
    "Latitude": 53.7546,
    "Longitude": 9.6418,
    "Telephone Number": "04121-3232",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.7546,9.6418",
    "IsOpen24Hours": false,
    "id": "53.7546,9.6418"
  },
  {
    "Gas Station Number": "TD024307",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GOESCHWITZER STR. 1",
    "Postcode": 7745,
    "City": "JENA",
    "State": "Thüringen",
    "Latitude": 50.898,
    "Longitude": 11.5934,
    "Telephone Number": "03641-215080",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.898,11.5934",
    "IsOpen24Hours": false,
    "id": "50.898,11.5934"
  },
  {
    "Gas Station Number": "TD181470",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DUNDENHEIMER STR. 31",
    "Postcode": 77743,
    "City": "NEURIED",
    "State": "Baden-Württemberg",
    "Latitude": 48.4509,
    "Longitude": 7.8164,
    "Telephone Number": "07807-887",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.4509,7.8164",
    "IsOpen24Hours": false,
    "id": "48.4509,7.8164"
  },
  {
    "Gas Station Number": "TD007187",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LINDENTHALER STR.61",
    "Postcode": 4155,
    "City": "LEIPZIG",
    "State": "Sachsen",
    "Latitude": 51.366,
    "Longitude": 12.3661,
    "Telephone Number": "0341-5832105",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.366,12.3661",
    "IsOpen24Hours": false,
    "id": "51.366,12.3661"
  },
  {
    "Gas Station Number": "TD133600",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MURGTALSTR.89",
    "Postcode": 76571,
    "City": "GAGGENAU",
    "State": "Baden-Württemberg",
    "Latitude": 48.8103,
    "Longitude": 8.3101,
    "Telephone Number": "07225-5603",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8103,8.3101",
    "IsOpen24Hours": false,
    "id": "48.8103,8.3101"
  },
  {
    "Gas Station Number": "TD000132",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "UERDINGER STR. 130",
    "Postcode": 47259,
    "City": "DUISBURG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.3587,
    "Longitude": 6.6875,
    "Telephone Number": "0203-781709",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3587,6.6875",
    "IsOpen24Hours": false,
    "id": "51.3587,6.6875"
  },
  {
    "Gas Station Number": "TD032946",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LAUCHHAMMERSTR. 12",
    "Postcode": 1591,
    "City": "RIESA",
    "State": "Sachsen",
    "Latitude": 51.3133,
    "Longitude": 13.2893,
    "Telephone Number": "03525-733388",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3133,13.2893",
    "IsOpen24Hours": false,
    "id": "51.3133,13.2893"
  },
  {
    "Gas Station Number": "TD038497",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AM SCHACHENFELD 1",
    "Postcode": 83527,
    "City": "HAAG",
    "State": "Bayern",
    "Latitude": 48.1652,
    "Longitude": 12.1636,
    "Telephone Number": "08072/370722",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.1652,12.1636",
    "IsOpen24Hours": false,
    "id": "48.1652,12.1636"
  },
  {
    "Gas Station Number": "TD028431",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BRANDENBURGISCHE STR. 3",
    "Postcode": 14974,
    "City": "LUDWIGSFELDE",
    "State": "Brandenburg",
    "Latitude": 52.3118,
    "Longitude": 13.2434,
    "Telephone Number": "03378-870107",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3118,13.2434",
    "IsOpen24Hours": false,
    "id": "52.3118,13.2434"
  },
  {
    "Gas Station Number": "TD034819",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PRORAER CHAUSSEE 8",
    "Postcode": 18609,
    "City": "BINZ",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 54.4024,
    "Longitude": 13.6029,
    "Telephone Number": "038393-144146",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.4024,13.6029",
    "IsOpen24Hours": false,
    "id": "54.4024,13.6029"
  },
  {
    "Gas Station Number": "TD039255",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "FRAUNHOFER STR.3",
    "Postcode": 93092,
    "City": "BARBING",
    "State": "Bayern",
    "Latitude": 48.9957,
    "Longitude": 12.2352,
    "Telephone Number": "09401-5278911",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.9957,12.2352",
    "IsOpen24Hours": false,
    "id": "48.9957,12.2352"
  },
  {
    "Gas Station Number": "TD002642",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAUPTSTR. 258",
    "Postcode": 66287,
    "City": "QUIERSCHIED",
    "State": "Saarland",
    "Latitude": 49.3417,
    "Longitude": 7.0206,
    "Telephone Number": "06825-5306",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.3417,7.0206",
    "IsOpen24Hours": false,
    "id": "49.3417,7.0206"
  },
  {
    "Gas Station Number": "TD035469",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HILDBURGHAUSER STR. 222",
    "Postcode": 12209,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.4192,
    "Longitude": 13.3194,
    "Telephone Number": "030-76687462",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4192,13.3194",
    "IsOpen24Hours": false,
    "id": "52.4192,13.3194"
  },
  {
    "Gas Station Number": "TD024711",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAUPTSTRASSE 121",
    "Postcode": 98553,
    "City": "SCHLEUSINGEN OT WALDAU",
    "State": "Thüringen",
    "Latitude": 50.5044,
    "Longitude": 10.8337,
    "Telephone Number": "036878-61521",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.5044,10.8337",
    "IsOpen24Hours": false,
    "id": "50.5044,10.8337"
  },
  {
    "Gas Station Number": "TD005579",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BORNSTR.266",
    "Postcode": 44145,
    "City": "DORTMUND",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5357,
    "Longitude": 7.4745,
    "Telephone Number": "0231-818218",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5357,7.4745",
    "IsOpen24Hours": false,
    "id": "51.5357,7.4745"
  },
  {
    "Gas Station Number": "TD035840",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WALLDORFER STRASSE 59",
    "Postcode": 98617,
    "City": "HERPF",
    "State": "Thüringen",
    "Latitude": 50.5835,
    "Longitude": 10.339,
    "Telephone Number": "036943-63204",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.5835,10.339",
    "IsOpen24Hours": false,
    "id": "50.5835,10.339"
  },
  {
    "Gas Station Number": "TD028530",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GROSSBEERENSTR. 137",
    "Postcode": 14482,
    "City": "POTSDAM",
    "State": "Brandenburg",
    "Latitude": 52.3856,
    "Longitude": 13.1096,
    "Telephone Number": "0331-7482098",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3856,13.1096",
    "IsOpen24Hours": false,
    "id": "52.3856,13.1096"
  },
  {
    "Gas Station Number": "TD041640",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "A71 RASTANLAGE MELLRICHSTAEDTER HOE",
    "Postcode": 97638,
    "City": "MELLRICHSTADT",
    "State": "Bayern",
    "Latitude": 50.4214,
    "Longitude": 10.349,
    "Telephone Number": "097767090343",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.4214,10.349",
    "IsOpen24Hours": false,
    "id": "50.4214,10.349"
  },
  {
    "Gas Station Number": "TD034579",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LEIPZIGER STR. 87",
    "Postcode": 4600,
    "City": "ALTENBURG",
    "State": "Thüringen",
    "Latitude": 51.008,
    "Longitude": 12.4533,
    "Telephone Number": "03447-836804",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.008,12.4533",
    "IsOpen24Hours": false,
    "id": "51.008,12.4533"
  },
  {
    "Gas Station Number": "TD032953",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DELITZSCHER STR. 36",
    "Postcode": 6112,
    "City": "HALLE",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.4785,
    "Longitude": 11.996,
    "Telephone Number": "0345-5606281",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4785,11.996",
    "IsOpen24Hours": false,
    "id": "51.4785,11.996"
  },
  {
    "Gas Station Number": "TD124270",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HEERSTR.270",
    "Postcode": 47053,
    "City": "DUISBURG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4151,
    "Longitude": 6.7626,
    "Telephone Number": "0203-63355",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4151,6.7626",
    "IsOpen24Hours": false,
    "id": "51.4151,6.7626"
  },
  {
    "Gas Station Number": "TD041566",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AEPPELALLEE 39",
    "Postcode": 65203,
    "City": "WIESBADEN",
    "State": "Hessen",
    "Latitude": 50.0477,
    "Longitude": 8.2312,
    "Telephone Number": "06116010537",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.0477,8.2312",
    "IsOpen24Hours": false,
    "id": "50.0477,8.2312"
  },
  {
    "Gas Station Number": "TD041384",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB A9 PEGNITZ WEST",
    "Postcode": 91257,
    "City": "PEGNITZ",
    "State": "Bayern",
    "Latitude": 49.7496,
    "Longitude": 11.5107,
    "Telephone Number": "092414944539",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.7496,11.5107",
    "IsOpen24Hours": false,
    "id": "49.7496,11.5107"
  },
  {
    "Gas Station Number": "TD038315",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BRITZER DAMM 60-62",
    "Postcode": 12347,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.457,
    "Longitude": 13.4364,
    "Telephone Number": "030/62981575",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.457,13.4364",
    "IsOpen24Hours": false,
    "id": "52.457,13.4364"
  },
  {
    "Gas Station Number": "TD040428",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PADERBORNER STR. 78",
    "Postcode": 33189,
    "City": "SCHLANGEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8008,
    "Longitude": 8.8371,
    "Telephone Number": "05252-7109",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8008,8.8371",
    "IsOpen24Hours": false,
    "id": "51.8008,8.8371"
  },
  {
    "Gas Station Number": "TD021394",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "NEBELSCHUETZER STR.4",
    "Postcode": 1917,
    "City": "KAMENZ",
    "State": "Sachsen",
    "Latitude": 51.2633,
    "Longitude": 14.1164,
    "Telephone Number": "03578/309435",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2633,14.1164",
    "IsOpen24Hours": false,
    "id": "51.2633,14.1164"
  },
  {
    "Gas Station Number": "TD021568",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HUEBLERSTR. 36",
    "Postcode": 1309,
    "City": "DRESDEN",
    "State": "Sachsen",
    "Latitude": 51.0492,
    "Longitude": 13.7932,
    "Telephone Number": "0351-40425070",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0492,13.7932",
    "IsOpen24Hours": false,
    "id": "51.0492,13.7932"
  },
  {
    "Gas Station Number": "TD006619",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DAIMLER STR. 1",
    "Postcode": 71546,
    "City": "ASPACH",
    "State": "Baden-Württemberg",
    "Latitude": 48.9719,
    "Longitude": 9.3871,
    "Telephone Number": "07191-3678024",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.9719,9.3871",
    "IsOpen24Hours": false,
    "id": "48.9719,9.3871"
  },
  {
    "Gas Station Number": "TD031674",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GREIFSWALDER CHAUSSEE 4",
    "Postcode": 18507,
    "City": "GRIMMEN",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 54.1093,
    "Longitude": 13.0562,
    "Telephone Number": "038326-2134",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.1093,13.0562",
    "IsOpen24Hours": false,
    "id": "54.1093,13.0562"
  },
  {
    "Gas Station Number": "TD029801",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MALCHINER STR. 81",
    "Postcode": 17153,
    "City": "STAVENHAGEN",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.7002,
    "Longitude": 12.8977,
    "Telephone Number": "039954-22175",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.7002,12.8977",
    "IsOpen24Hours": false,
    "id": "53.7002,12.8977"
  },
  {
    "Gas Station Number": "TD038323",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "NIROSTASTR. 10",
    "Postcode": 47807,
    "City": "KREFELD",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.289,
    "Longitude": 6.554,
    "Telephone Number": "02151-9372354",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.289,6.554",
    "IsOpen24Hours": false,
    "id": "51.289,6.554"
  },
  {
    "Gas Station Number": "TD039107",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "5 AM BAHNHOF LIENEWITZ",
    "Postcode": 14548,
    "City": "SCHWIELOWSEE / OT FERCH",
    "State": "Brandenburg",
    "Latitude": 52.3036,
    "Longitude": 12.9706,
    "Telephone Number": "033205757972",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3036,12.9706",
    "IsOpen24Hours": false,
    "id": "52.3036,12.9706"
  },
  {
    "Gas Station Number": "TD007211",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AUTOHOF KRONSKAMP 3",
    "Postcode": 30890,
    "City": "BARSINGHAUSEN",
    "State": "Niedersachsen",
    "Latitude": 52.3332,
    "Longitude": 9.4146,
    "Telephone Number": "05105-514923",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3332,9.4146",
    "IsOpen24Hours": false,
    "id": "52.3332,9.4146"
  },
  {
    "Gas Station Number": "TD123980",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HOMBERGER STR. 5",
    "Postcode": 36251,
    "City": "BAD HERSFELD",
    "State": "Hessen",
    "Latitude": 50.8742,
    "Longitude": 9.7051,
    "Telephone Number": "06621-917756",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8742,9.7051",
    "IsOpen24Hours": false,
    "id": "50.8742,9.7051"
  },
  {
    "Gas Station Number": "TD001727",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DAETZINGER STR. 36",
    "Postcode": 71120,
    "City": "GRAFENAU",
    "State": "Baden-Württemberg",
    "Latitude": 48.7149,
    "Longitude": 8.9006,
    "Telephone Number": "07033-43950",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.7149,8.9006",
    "IsOpen24Hours": false,
    "id": "48.7149,8.9006"
  },
  {
    "Gas Station Number": "TD143190",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "VOGELSANGER STR.37",
    "Postcode": 58300,
    "City": "WETTER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.3608,
    "Longitude": 7.376,
    "Telephone Number": "02335-60371",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3608,7.376",
    "IsOpen24Hours": false,
    "id": "51.3608,7.376"
  },
  {
    "Gas Station Number": "TD000380",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SUEDRING 60",
    "Postcode": 40223,
    "City": "DUESSELDORF",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.2001,
    "Longitude": 6.768,
    "Telephone Number": "0211-151643",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2001,6.768",
    "IsOpen24Hours": false,
    "id": "51.2001,6.768"
  },
  {
    "Gas Station Number": "TD028050",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ZOSSENER DAMM 19",
    "Postcode": 15827,
    "City": "BLANKENFELDE",
    "State": "Brandenburg",
    "Latitude": 52.3301,
    "Longitude": 13.4041,
    "Telephone Number": "03379-379940",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3301,13.4041",
    "IsOpen24Hours": false,
    "id": "52.3301,13.4041"
  },
  {
    "Gas Station Number": "TD032870",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "UMGEHUNGSSTR. 18",
    "Postcode": 1723,
    "City": "WILSDRUFF",
    "State": "Sachsen",
    "Latitude": 51.055,
    "Longitude": 13.5384,
    "Telephone Number": "035204-61992",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.055,13.5384",
    "IsOpen24Hours": false,
    "id": "51.055,13.5384"
  },
  {
    "Gas Station Number": "TD002188",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DUDWEILER STR. 111",
    "Postcode": 66111,
    "City": "SAARBRUECKEN",
    "State": "Saarland",
    "Latitude": 49.241,
    "Longitude": 7.0015,
    "Telephone Number": "0681-34507",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.241,7.0015",
    "IsOpen24Hours": false,
    "id": "49.241,7.0015"
  },
  {
    "Gas Station Number": "TD023358",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FRIEDRICHSTR.1",
    "Postcode": 99310,
    "City": "ARNSTADT",
    "State": "Thüringen",
    "Latitude": 50.8399,
    "Longitude": 10.9559,
    "Telephone Number": "03628-76218",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8399,10.9559",
    "IsOpen24Hours": false,
    "id": "50.8399,10.9559"
  },
  {
    "Gas Station Number": "TD005777",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KOELNER STR. 86",
    "Postcode": 57439,
    "City": "ATTENDORN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.1187,
    "Longitude": 7.8933,
    "Telephone Number": "02722-3235",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1187,7.8933",
    "IsOpen24Hours": false,
    "id": "51.1187,7.8933"
  },
  {
    "Gas Station Number": "TD035816",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "GEWERBESTR. 4",
    "Postcode": 99880,
    "City": "LAUCHA",
    "State": "Thüringen",
    "Latitude": 50.9225,
    "Longitude": 10.5522,
    "Telephone Number": "03622-440211",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9225,10.5522",
    "IsOpen24Hours": false,
    "id": "50.9225,10.5522"
  },
  {
    "Gas Station Number": "TD003665",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BRAUNSCHWEIGER STR.137",
    "Postcode": 38518,
    "City": "GIFHORN",
    "State": "Niedersachsen",
    "Latitude": 52.4653,
    "Longitude": 10.5426,
    "Telephone Number": "05371-3455",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4653,10.5426",
    "IsOpen24Hours": false,
    "id": "52.4653,10.5426"
  },
  {
    "Gas Station Number": "TD003061",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "TAUNUSSTR. 1",
    "Postcode": 61191,
    "City": "ROSBACH",
    "State": "Hessen",
    "Latitude": 50.307,
    "Longitude": 8.6972,
    "Telephone Number": "06003-1203",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.307,8.6972",
    "IsOpen24Hours": false,
    "id": "50.307,8.6972"
  },
  {
    "Gas Station Number": "TD132960",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DARMSTAEDTER LANDSTR 2",
    "Postcode": 64569,
    "City": "NAUHEIM",
    "State": "Hessen",
    "Latitude": 49.9391,
    "Longitude": 8.4489,
    "Telephone Number": "06152-64953",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.9391,8.4489",
    "IsOpen24Hours": false,
    "id": "49.9391,8.4489"
  },
  {
    "Gas Station Number": "TD038695",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "ZUM HANSA-CENTER 2",
    "Postcode": 49451,
    "City": "HOLDORF",
    "State": "Niedersachsen",
    "Latitude": 52.5974,
    "Longitude": 8.118,
    "Telephone Number": "05494-9159243",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5974,8.118",
    "IsOpen24Hours": false,
    "id": "52.5974,8.118"
  },
  {
    "Gas Station Number": "TD032938",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PRAGER STRASSE 420",
    "Postcode": 4288,
    "City": "LEIPZIG",
    "State": "Sachsen",
    "Latitude": 51.2908,
    "Longitude": 12.4447,
    "Telephone Number": "034297-42240",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2908,12.4447",
    "IsOpen24Hours": false,
    "id": "51.2908,12.4447"
  },
  {
    "Gas Station Number": "TD023119",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AUGUST-BEBEL-STR. 31",
    "Postcode": 7743,
    "City": "JENA",
    "State": "Thüringen",
    "Latitude": 50.932,
    "Longitude": 11.5722,
    "Telephone Number": "03641-820879",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.932,11.5722",
    "IsOpen24Hours": false,
    "id": "50.932,11.5722"
  },
  {
    "Gas Station Number": "TD026302",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FLOEHATALSTR. 2A",
    "Postcode": 9509,
    "City": "POCKAU-LENGEFELD",
    "State": "Sachsen",
    "Latitude": 50.7087,
    "Longitude": 13.2198,
    "Telephone Number": "037367-9659",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.7087,13.2198",
    "IsOpen24Hours": false,
    "id": "50.7087,13.2198"
  },
  {
    "Gas Station Number": "TD001644",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ULMER STR. 206",
    "Postcode": 70188,
    "City": "STUTTGART",
    "State": "Baden-Württemberg",
    "Latitude": 48.7794,
    "Longitude": 9.2307,
    "Telephone Number": "0711-423388",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.7794,9.2307",
    "IsOpen24Hours": false,
    "id": "48.7794,9.2307"
  },
  {
    "Gas Station Number": "TD130920",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HEIDELBERGER STR.71",
    "Postcode": 64385,
    "City": "REICHELSHEIM",
    "State": "Hessen",
    "Latitude": 49.7102,
    "Longitude": 8.8335,
    "Telephone Number": "06164-913955",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.7102,8.8335",
    "IsOpen24Hours": false,
    "id": "49.7102,8.8335"
  },
  {
    "Gas Station Number": "TD161090",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHWABMUENCHNER STR. 16",
    "Postcode": 86836,
    "City": "KLOSTERLECHFELD",
    "State": "Bayern",
    "Latitude": 48.159,
    "Longitude": 10.8282,
    "Telephone Number": "08232-8088112",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.159,10.8282",
    "IsOpen24Hours": false,
    "id": "48.159,10.8282"
  },
  {
    "Gas Station Number": "TD040014",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "POINGER STR. 2",
    "Postcode": 85570,
    "City": "MARKT SCHWABEN",
    "State": "Bayern",
    "Latitude": 48.1903,
    "Longitude": 11.8485,
    "Telephone Number": "08121-4767555",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.1903,11.8485",
    "IsOpen24Hours": false,
    "id": "48.1903,11.8485"
  },
  {
    "Gas Station Number": "TD037226",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "CALBERLAHER DAMM 54",
    "Postcode": 38518,
    "City": "GIFHORN",
    "State": "Niedersachsen",
    "Latitude": 52.4754,
    "Longitude": 10.5564,
    "Telephone Number": "05371-12646",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4754,10.5564",
    "IsOpen24Hours": false,
    "id": "52.4754,10.5564"
  },
  {
    "Gas Station Number": "TD037648",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ST. WENDELER STR. 33",
    "Postcode": 66606,
    "City": "ST. WENDEL",
    "State": "Saarland",
    "Latitude": 49.4933,
    "Longitude": 7.1144,
    "Telephone Number": "06854-8654",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.4933,7.1144",
    "IsOpen24Hours": false,
    "id": "49.4933,7.1144"
  },
  {
    "Gas Station Number": "TD042002",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AM HELMEBERG 3",
    "Postcode": 99735,
    "City": "WERTHER",
    "State": "Thüringen",
    "Latitude": 51.4871,
    "Longitude": 10.7424,
    "Telephone Number": "036319999169",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4871,10.7424",
    "IsOpen24Hours": false,
    "id": "51.4871,10.7424"
  },
  {
    "Gas Station Number": "TD110570",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "UETTERLINGSER STR.39",
    "Postcode": 58791,
    "City": "WERDOHL",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.2545,
    "Longitude": 7.7459,
    "Telephone Number": "02392-180326",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2545,7.7459",
    "IsOpen24Hours": false,
    "id": "51.2545,7.7459"
  },
  {
    "Gas Station Number": "TD004432",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BIELEFELDER STR.81",
    "Postcode": 33803,
    "City": "STEINHAGEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.0133,
    "Longitude": 8.441,
    "Telephone Number": "05204-4634",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0133,8.441",
    "IsOpen24Hours": false,
    "id": "52.0133,8.441"
  },
  {
    "Gas Station Number": "TD039487",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HEERSTR. 35-37",
    "Postcode": 14055,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.5084,
    "Longitude": 13.2606,
    "Telephone Number": "030-50569275",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5084,13.2606",
    "IsOpen24Hours": false,
    "id": "52.5084,13.2606"
  },
  {
    "Gas Station Number": "TD130860",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HANAUERSTR. 61",
    "Postcode": 63579,
    "City": "FREIGERICHT",
    "State": "Hessen",
    "Latitude": 50.1471,
    "Longitude": 9.1118,
    "Telephone Number": "06055-915217",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.1471,9.1118",
    "IsOpen24Hours": false,
    "id": "50.1471,9.1118"
  },
  {
    "Gas Station Number": "TD041921",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAUPTSTR. 127",
    "Postcode": 26639,
    "City": "WIESMOOR",
    "State": "Niedersachsen",
    "Latitude": 53.4203,
    "Longitude": 7.7447,
    "Telephone Number": "04944-9154010",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.4203,7.7447",
    "IsOpen24Hours": false,
    "id": "53.4203,7.7447"
  },
  {
    "Gas Station Number": "TD000325",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "OLPENER STR. 690",
    "Postcode": 51109,
    "City": "KOELN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.945,
    "Longitude": 7.0626,
    "Telephone Number": "0221-6908711",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.945,7.0626",
    "IsOpen24Hours": false,
    "id": "50.945,7.0626"
  },
  {
    "Gas Station Number": "TD035089",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KESSELSDORFER STR. 214",
    "Postcode": 1169,
    "City": "DRESDEN",
    "State": "Sachsen",
    "Latitude": 51.0398,
    "Longitude": 13.6571,
    "Telephone Number": "0351-4126824",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0398,13.6571",
    "IsOpen24Hours": false,
    "id": "51.0398,13.6571"
  },
  {
    "Gas Station Number": "TD000927",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WESTSTR. 10",
    "Postcode": 58089,
    "City": "HAGEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.3859,
    "Longitude": 7.4374,
    "Telephone Number": "02331-3483454",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3859,7.4374",
    "IsOpen24Hours": false,
    "id": "51.3859,7.4374"
  },
  {
    "Gas Station Number": "TD036566",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DONAUWOERTHER STR. 102",
    "Postcode": 86154,
    "City": "AUGSBURG",
    "State": "Bayern",
    "Latitude": 48.3891,
    "Longitude": 10.8816,
    "Telephone Number": "0821-414291",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.3891,10.8816",
    "IsOpen24Hours": false,
    "id": "48.3891,10.8816"
  },
  {
    "Gas Station Number": "TD041111",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "RITTER-TUSCHL-STR. 39",
    "Postcode": 94501,
    "City": "ALDERSBACH",
    "State": "Bayern",
    "Latitude": 48.5903,
    "Longitude": 13.094,
    "Telephone Number": "08543916298",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.5903,13.094",
    "IsOpen24Hours": false,
    "id": "48.5903,13.094"
  },
  {
    "Gas Station Number": "TD020107",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "RUDOLF-BREITSCHEID-STR. 9b",
    "Postcode": 15848,
    "City": "BEESKOW",
    "State": "Brandenburg",
    "Latitude": 52.1662,
    "Longitude": 14.2402,
    "Telephone Number": "03366-20497",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1662,14.2402",
    "IsOpen24Hours": false,
    "id": "52.1662,14.2402"
  },
  {
    "Gas Station Number": "TD133540",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ROEMERSTR.16",
    "Postcode": 63486,
    "City": "BRUCHKOEBEL",
    "State": "Hessen",
    "Latitude": 50.1794,
    "Longitude": 8.9092,
    "Telephone Number": "06181-576095",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.1794,8.9092",
    "IsOpen24Hours": false,
    "id": "50.1794,8.9092"
  },
  {
    "Gas Station Number": "TD030759",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KUPFERHUETTE 7",
    "Postcode": 6526,
    "City": "SANGERHAUSEN",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.486,
    "Longitude": 11.3086,
    "Telephone Number": "03464-572685",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.486,11.3086",
    "IsOpen24Hours": false,
    "id": "51.486,11.3086"
  },
  {
    "Gas Station Number": "TD124140",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AUTOHOF PFEFFERHOEHE 18",
    "Postcode": 36304,
    "City": "ALSFELD",
    "State": "Hessen",
    "Latitude": 50.7334,
    "Longitude": 9.2415,
    "Telephone Number": "06631-800850",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.7334,9.2415",
    "IsOpen24Hours": false,
    "id": "50.7334,9.2415"
  },
  {
    "Gas Station Number": "TD038372",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "VON-SAUERSTR. 27",
    "Postcode": 22761,
    "City": "HAMBURG",
    "State": "Hamburg",
    "Latitude": 53.5656,
    "Longitude": 9.9065,
    "Telephone Number": "040-89064795",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.5656,9.9065",
    "IsOpen24Hours": false,
    "id": "53.5656,9.9065"
  },
  {
    "Gas Station Number": "TD021063",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BAHNHOFSTR. 68-72",
    "Postcode": 2826,
    "City": "GOERLITZ",
    "State": "Sachsen",
    "Latitude": 51.1461,
    "Longitude": 14.9839,
    "Telephone Number": "03581-409306",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1461,14.9839",
    "IsOpen24Hours": false,
    "id": "51.1461,14.9839"
  },
  {
    "Gas Station Number": "TD039966",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MAX-PLANCK-STR. 1A",
    "Postcode": 77656,
    "City": "OFFENBURG",
    "State": "Baden-Württemberg",
    "Latitude": 48.4725,
    "Longitude": 7.9273,
    "Telephone Number": "0781-20396585",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.4725,7.9273",
    "IsOpen24Hours": false,
    "id": "48.4725,7.9273"
  },
  {
    "Gas Station Number": "TD182380",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AM RHEINUEBERGANG",
    "Postcode": 77866,
    "City": "RHEINAU-FREISTETT",
    "State": "Baden-Württemberg",
    "Latitude": 48.6805,
    "Longitude": 7.9222,
    "Telephone Number": "07844-991122/24",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.6805,7.9222",
    "IsOpen24Hours": false,
    "id": "48.6805,7.9222"
  },
  {
    "Gas Station Number": "TD037887",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DETMOLDSTR. 1",
    "Postcode": 80935,
    "City": "MUENCHEN",
    "State": "Bayern",
    "Latitude": 48.1923,
    "Longitude": 11.5643,
    "Telephone Number": "089-35043040",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.1923,11.5643",
    "IsOpen24Hours": false,
    "id": "48.1923,11.5643"
  },
  {
    "Gas Station Number": "TD029744",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "RTK Sued 4",
    "Postcode": 19372,
    "City": "STOLPE",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.367,
    "Longitude": 11.7271,
    "Telephone Number": "038725-20216",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.367,11.7271",
    "IsOpen24Hours": false,
    "id": "53.367,11.7271"
  },
  {
    "Gas Station Number": "TD041954",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "TRIEBSTR. 11 E",
    "Postcode": 80993,
    "City": "MUENCHEN",
    "State": "Bayern",
    "Latitude": 48.1876,
    "Longitude": 11.5309,
    "Telephone Number": "0891415222",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.1876,11.5309",
    "IsOpen24Hours": false,
    "id": "48.1876,11.5309"
  },
  {
    "Gas Station Number": "TD030734",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ALBRECHTSTR. 95",
    "Postcode": 6844,
    "City": "DESSAU",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.8488,
    "Longitude": 12.2404,
    "Telephone Number": "0340-214417",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8488,12.2404",
    "IsOpen24Hours": false,
    "id": "51.8488,12.2404"
  },
  {
    "Gas Station Number": "TD020222",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DIEDERSDORFER STR. 2",
    "Postcode": 15306,
    "City": "SEELOW",
    "State": "Brandenburg",
    "Latitude": 52.5299,
    "Longitude": 14.3705,
    "Telephone Number": "03346-316",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5299,14.3705",
    "IsOpen24Hours": false,
    "id": "52.5299,14.3705"
  },
  {
    "Gas Station Number": "TD036996",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAMMER LANDSTR. 204",
    "Postcode": 20537,
    "City": "HAMBURG",
    "State": "Hamburg",
    "Latitude": 53.554,
    "Longitude": 10.0632,
    "Telephone Number": "040-211533",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.554,10.0632",
    "IsOpen24Hours": false,
    "id": "53.554,10.0632"
  },
  {
    "Gas Station Number": "TD028449",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KARL-LIEBKNECHT-STR. 155",
    "Postcode": 15711,
    "City": "ZEESEN",
    "State": "Brandenburg",
    "Latitude": 52.2844,
    "Longitude": 13.6303,
    "Telephone Number": "03375-900332",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2844,13.6303",
    "IsOpen24Hours": false,
    "id": "52.2844,13.6303"
  },
  {
    "Gas Station Number": "TD000840",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MELLINGHOFER STR.110",
    "Postcode": 45473,
    "City": "MUELHEIM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4453,
    "Longitude": 6.8844,
    "Telephone Number": "0208-995570",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4453,6.8844",
    "IsOpen24Hours": false,
    "id": "51.4453,6.8844"
  },
  {
    "Gas Station Number": "TD038687",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "ARNO-SCHMIDT-STR. 28 A",
    "Postcode": 8134,
    "City": "WILDENFELS",
    "State": "Sachsen",
    "Latitude": 50.6806,
    "Longitude": 12.5927,
    "Telephone Number": "037603-550711",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.6806,12.5927",
    "IsOpen24Hours": false,
    "id": "50.6806,12.5927"
  },
  {
    "Gas Station Number": "TD004549",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MUENSTERSTR. 51",
    "Postcode": 49477,
    "City": "IBBENBUEREN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.2691,
    "Longitude": 7.7175,
    "Telephone Number": "05451-894755",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2691,7.7175",
    "IsOpen24Hours": false,
    "id": "52.2691,7.7175"
  },
  {
    "Gas Station Number": "TD022921",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GOTHAER STR.9",
    "Postcode": 99880,
    "City": "WALTERSHAUSEN",
    "State": "Thüringen",
    "Latitude": 50.9018,
    "Longitude": 10.5665,
    "Telephone Number": "03622-68444",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9018,10.5665",
    "IsOpen24Hours": false,
    "id": "50.9018,10.5665"
  },
  {
    "Gas Station Number": "TD032128",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AHLBECKER STR. 37",
    "Postcode": 17454,
    "City": "ZINNOWITZ",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 54.0674,
    "Longitude": 13.9196,
    "Telephone Number": "038377-42728",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.0674,13.9196",
    "IsOpen24Hours": false,
    "id": "54.0674,13.9196"
  },
  {
    "Gas Station Number": "TD181430",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "URBANSTR. 65",
    "Postcode": 73728,
    "City": "ESSLINGEN",
    "State": "Baden-Württemberg",
    "Latitude": 48.7396,
    "Longitude": 9.3197,
    "Telephone Number": "0711-9319777",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.7396,9.3197",
    "IsOpen24Hours": false,
    "id": "48.7396,9.3197"
  },
  {
    "Gas Station Number": "TD004978",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FRAUENLOBSTR.112",
    "Postcode": 44805,
    "City": "BOCHUM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5153,
    "Longitude": 7.2589,
    "Telephone Number": "0234-850992",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5153,7.2589",
    "IsOpen24Hours": false,
    "id": "51.5153,7.2589"
  },
  {
    "Gas Station Number": "TD006072",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "RHEINLANDSTR. 28-30",
    "Postcode": 72070,
    "City": "TUEBINGEN",
    "State": "Baden-Württemberg",
    "Latitude": 48.5235,
    "Longitude": 9.0393,
    "Telephone Number": "07071-45366",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.5235,9.0393",
    "IsOpen24Hours": false,
    "id": "48.5235,9.0393"
  },
  {
    "Gas Station Number": "TD034140",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DINGELSTAEDTER STRASSE 33",
    "Postcode": 37308,
    "City": "HEILIGENSTADT",
    "State": "Thüringen",
    "Latitude": 51.3753,
    "Longitude": 10.1484,
    "Telephone Number": "03606-608552",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3753,10.1484",
    "IsOpen24Hours": false,
    "id": "51.3753,10.1484"
  },
  {
    "Gas Station Number": "TD037366",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HARENBERGER MEILE 39",
    "Postcode": 30926,
    "City": "SEELZE",
    "State": "Niedersachsen",
    "Latitude": 52.3778,
    "Longitude": 9.6187,
    "Telephone Number": "05137-877106",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3778,9.6187",
    "IsOpen24Hours": false,
    "id": "52.3778,9.6187"
  },
  {
    "Gas Station Number": "TD040485",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ERLENWIESE 21",
    "Postcode": 35794,
    "City": "MENGERSKIRCHEN",
    "State": "Hessen",
    "Latitude": 50.5317,
    "Longitude": 8.1433,
    "Telephone Number": "06476-411250",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.5317,8.1433",
    "IsOpen24Hours": false,
    "id": "50.5317,8.1433"
  },
  {
    "Gas Station Number": "TD000755",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHERMBECKER LSTR.28",
    "Postcode": 46485,
    "City": "WESEL",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.6595,
    "Longitude": 6.6389,
    "Telephone Number": "0281-50167",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6595,6.6389",
    "IsOpen24Hours": false,
    "id": "51.6595,6.6389"
  },
  {
    "Gas Station Number": "TD039305",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "STEGENWALDHAUSER STR. 1",
    "Postcode": 95152,
    "City": "SELBITZ OT SELLANGER",
    "State": "Bayern",
    "Latitude": 50.3243,
    "Longitude": 11.7845,
    "Telephone Number": "09280953550",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.3243,11.7845",
    "IsOpen24Hours": false,
    "id": "50.3243,11.7845"
  },
  {
    "Gas Station Number": "TD000169",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HEERSTR. 78",
    "Postcode": 60488,
    "City": "FRANKFURT",
    "State": "Hessen",
    "Latitude": 50.1471,
    "Longitude": 8.6121,
    "Telephone Number": "069-762965",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.1471,8.6121",
    "IsOpen24Hours": false,
    "id": "50.1471,8.6121"
  },
  {
    "Gas Station Number": "TD003087",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAUPTSTR. 103",
    "Postcode": 65843,
    "City": "SULZBACH",
    "State": "Hessen",
    "Latitude": 50.1378,
    "Longitude": 8.5192,
    "Telephone Number": "06196-71144",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.1378,8.5192",
    "IsOpen24Hours": false,
    "id": "50.1378,8.5192"
  },
  {
    "Gas Station Number": "TD036442",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SAARBRUECKENER STR. 41",
    "Postcode": 74078,
    "City": "HEILBRONN",
    "State": "Baden-Württemberg",
    "Latitude": 49.1552,
    "Longitude": 9.1764,
    "Telephone Number": "07131-42829",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.1552,9.1764",
    "IsOpen24Hours": false,
    "id": "49.1552,9.1764"
  },
  {
    "Gas Station Number": "TD000950",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ASBACHER STR. 55",
    "Postcode": 53545,
    "City": "LINZ",
    "State": "Rheinland-Pfalz",
    "Latitude": 50.5697,
    "Longitude": 7.2869,
    "Telephone Number": "02644-2749",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.5697,7.2869",
    "IsOpen24Hours": false,
    "id": "50.5697,7.2869"
  },
  {
    "Gas Station Number": "TD130420",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GRIEDELER STR.72",
    "Postcode": 35510,
    "City": "BUTZBACH",
    "State": "Hessen",
    "Latitude": 50.4365,
    "Longitude": 8.6815,
    "Telephone Number": "06033-60763",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.4365,8.6815",
    "IsOpen24Hours": false,
    "id": "50.4365,8.6815"
  },
  {
    "Gas Station Number": "TD020156",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "STEINSTR.11",
    "Postcode": 16303,
    "City": "SCHWEDT",
    "State": "Brandenburg",
    "Latitude": 53.0714,
    "Longitude": 14.2543,
    "Telephone Number": "03332-417992",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.0714,14.2543",
    "IsOpen24Hours": false,
    "id": "53.0714,14.2543"
  },
  {
    "Gas Station Number": "TD007237",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAUPTSTR. 200",
    "Postcode": 16547,
    "City": "BIRKENWERDER",
    "State": "Brandenburg",
    "Latitude": 52.7029,
    "Longitude": 13.2726,
    "Telephone Number": "03303-501693",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.7029,13.2726",
    "IsOpen24Hours": false,
    "id": "52.7029,13.2726"
  },
  {
    "Gas Station Number": "TD035477",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AUTOHOF ROSENGARTENWEG 1",
    "Postcode": 67281,
    "City": "KIRCHHEIM",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.5542,
    "Longitude": 8.1856,
    "Telephone Number": "06359-9692132",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.5542,8.1856",
    "IsOpen24Hours": false,
    "id": "49.5542,8.1856"
  },
  {
    "Gas Station Number": "TD019463",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DOTZHEIMER STR. 184",
    "Postcode": 65197,
    "City": "WIESBADEN",
    "State": "Hessen",
    "Latitude": 50.0761,
    "Longitude": 8.2086,
    "Telephone Number": "0611-420626",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.0761,8.2086",
    "IsOpen24Hours": false,
    "id": "50.0761,8.2086"
  },
  {
    "Gas Station Number": "TD036954",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AN DER B 255",
    "Postcode": 35756,
    "City": "MITTENAAR",
    "State": "Hessen",
    "Latitude": 50.6983,
    "Longitude": 8.4089,
    "Telephone Number": "02778-913110",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.6983,8.4089",
    "IsOpen24Hours": false,
    "id": "50.6983,8.4089"
  },
  {
    "Gas Station Number": "TD000958",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ALFREDSTR. 248",
    "Postcode": 45133,
    "City": "ESSEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4219,
    "Longitude": 6.9985,
    "Telephone Number": "0201-410115",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4219,6.9985",
    "IsOpen24Hours": false,
    "id": "51.4219,6.9985"
  },
  {
    "Gas Station Number": "TD038810",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHMALKALDER STRASSE 13",
    "Postcode": 45665,
    "City": "RECKLINGHAUSEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5978,
    "Longitude": 7.2451,
    "Telephone Number": "02361-9387600",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5978,7.2451",
    "IsOpen24Hours": false,
    "id": "51.5978,7.2451"
  },
  {
    "Gas Station Number": "TD027029",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MAGDEBURGER STR. 28",
    "Postcode": 39245,
    "City": "GOMMERN",
    "State": "Sachsen-Anhalt",
    "Latitude": 52.0815,
    "Longitude": 11.8156,
    "Telephone Number": "039200-51317",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0815,11.8156",
    "IsOpen24Hours": false,
    "id": "52.0815,11.8156"
  },
  {
    "Gas Station Number": "TD041376",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB A8 - AUGSBURG OST",
    "Postcode": 86169,
    "City": "AUGSBURG",
    "State": "Bayern",
    "Latitude": 48.4121,
    "Longitude": 10.9128,
    "Telephone Number": "082148044019",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.4121,10.9128",
    "IsOpen24Hours": false,
    "id": "48.4121,10.9128"
  },
  {
    "Gas Station Number": "TD000960",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HORSTER STR. 69",
    "Postcode": 45897,
    "City": "GELSENKIRCHEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5735,
    "Longitude": 7.048,
    "Telephone Number": "0209-594033",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5735,7.048",
    "IsOpen24Hours": false,
    "id": "51.5735,7.048"
  },
  {
    "Gas Station Number": "TD034603",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "HEITERER BLICK 2",
    "Postcode": 4720,
    "City": "GROSSWEITZSCHEN",
    "State": "Sachsen",
    "Latitude": 51.1539,
    "Longitude": 13.1197,
    "Telephone Number": "03431-729730",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1539,13.1197",
    "IsOpen24Hours": false,
    "id": "51.1539,13.1197"
  },
  {
    "Gas Station Number": "TD180760",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "OFFENBURGER STR. 36",
    "Postcode": 77855,
    "City": "ACHERN",
    "State": "Baden-Württemberg",
    "Latitude": 48.6054,
    "Longitude": 8.0324,
    "Telephone Number": "07841-21364",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.6054,8.0324",
    "IsOpen24Hours": false,
    "id": "48.6054,8.0324"
  },
  {
    "Gas Station Number": "TD035287",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HOENOWER CHAUSSEE 1 A",
    "Postcode": 15345,
    "City": "ALTLANDSBERG",
    "State": "Brandenburg",
    "Latitude": 52.5566,
    "Longitude": 13.7142,
    "Telephone Number": "033438-61811",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5566,13.7142",
    "IsOpen24Hours": false,
    "id": "52.5566,13.7142"
  },
  {
    "Gas Station Number": "TD006833",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ALTE HEERSTR.2-4",
    "Postcode": 49124,
    "City": "GEORGSMARIENHUETTE",
    "State": "Niedersachsen",
    "Latitude": 52.2221,
    "Longitude": 8.0835,
    "Telephone Number": "05401-43174",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2221,8.0835",
    "IsOpen24Hours": false,
    "id": "52.2221,8.0835"
  },
  {
    "Gas Station Number": "TD027433",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BARLEBER CH. 3",
    "Postcode": 39126,
    "City": "MAGDEBURG",
    "State": "Sachsen-Anhalt",
    "Latitude": 52.1786,
    "Longitude": 11.6291,
    "Telephone Number": "0391-2530930",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1786,11.6291",
    "IsOpen24Hours": false,
    "id": "52.1786,11.6291"
  },
  {
    "Gas Station Number": "TD002329",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHATTHAEUSER STR. 44",
    "Postcode": 69168,
    "City": "WIESLOCH",
    "State": "Baden-Württemberg",
    "Latitude": 49.306,
    "Longitude": 8.7389,
    "Telephone Number": "06222-772652",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.306,8.7389",
    "IsOpen24Hours": false,
    "id": "49.306,8.7389"
  },
  {
    "Gas Station Number": "TD037036",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MUNDSBURGER DAMM 47",
    "Postcode": 22087,
    "City": "HAMBURG",
    "State": "Hamburg",
    "Latitude": 53.5687,
    "Longitude": 10.0241,
    "Telephone Number": "040-22739104",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.5687,10.0241",
    "IsOpen24Hours": false,
    "id": "53.5687,10.0241"
  },
  {
    "Gas Station Number": "TD029793",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "STETTINER STR. 100",
    "Postcode": 17291,
    "City": "PRENZLAU",
    "State": "Brandenburg",
    "Latitude": 53.3302,
    "Longitude": 13.8679,
    "Telephone Number": "03984-2888",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.3302,13.8679",
    "IsOpen24Hours": false,
    "id": "53.3302,13.8679"
  },
  {
    "Gas Station Number": "TD022988",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHLACHTHOFSTR. 14",
    "Postcode": 99085,
    "City": "ERFURT",
    "State": "Thüringen",
    "Latitude": 50.9862,
    "Longitude": 11.0382,
    "Telephone Number": "0361-5623517",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9862,11.0382",
    "IsOpen24Hours": false,
    "id": "50.9862,11.0382"
  },
  {
    "Gas Station Number": "TD025650",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PENIGER STR.40",
    "Postcode": 4654,
    "City": "FROHBURG",
    "State": "Sachsen",
    "Latitude": 51.0497,
    "Longitude": 12.5563,
    "Telephone Number": "034348-51177",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0497,12.5563",
    "IsOpen24Hours": false,
    "id": "51.0497,12.5563"
  },
  {
    "Gas Station Number": "TD007195",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MAINZER STR. 4",
    "Postcode": 65550,
    "City": "LIMBURG-LINTER",
    "State": "Hessen",
    "Latitude": 50.3709,
    "Longitude": 8.0847,
    "Telephone Number": "06431-47600",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.3709,8.0847",
    "IsOpen24Hours": false,
    "id": "50.3709,8.0847"
  },
  {
    "Gas Station Number": "TD041970",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MINDELHEIMER STR. 1",
    "Postcode": 86842,
    "City": "TUERKHEIM",
    "State": "Bayern",
    "Latitude": 48.0431,
    "Longitude": 10.62,
    "Telephone Number": "08245904530",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.0431,10.62",
    "IsOpen24Hours": false,
    "id": "48.0431,10.62"
  },
  {
    "Gas Station Number": "TD038059",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AM FIESELER WERK 7",
    "Postcode": 34253,
    "City": "LOHFELDEN",
    "State": "Hessen",
    "Latitude": 51.2867,
    "Longitude": 9.5293,
    "Telephone Number": "0561-9513161",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2867,9.5293",
    "IsOpen24Hours": false,
    "id": "51.2867,9.5293"
  },
  {
    "Gas Station Number": "TD037895",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "CHAUSSEESTR. 61",
    "Postcode": 10115,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.5372,
    "Longitude": 13.3754,
    "Telephone Number": "030-25762994",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5372,13.3754",
    "IsOpen24Hours": false,
    "id": "52.5372,13.3754"
  },
  {
    "Gas Station Number": "TD040659",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "INDUSTRIESTR.22",
    "Postcode": 67227,
    "City": "FRANKENTHAL",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.5514,
    "Longitude": 8.3478,
    "Telephone Number": "06233-7370237",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.5514,8.3478",
    "IsOpen24Hours": false,
    "id": "49.5514,8.3478"
  },
  {
    "Gas Station Number": "TD110850",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HEINRICHPLATZ 3",
    "Postcode": 45888,
    "City": "GELSENKIRCHEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5123,
    "Longitude": 7.1111,
    "Telephone Number": "0209-1659850",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5123,7.1111",
    "IsOpen24Hours": false,
    "id": "51.5123,7.1111"
  },
  {
    "Gas Station Number": "TD005652",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WERLER STR. 60",
    "Postcode": 58706,
    "City": "MENDEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4478,
    "Longitude": 7.7866,
    "Telephone Number": "02373-3544",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4478,7.7866",
    "IsOpen24Hours": false,
    "id": "51.4478,7.7866"
  },
  {
    "Gas Station Number": "TD000992",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "OLPER STR.41",
    "Postcode": 51491,
    "City": "OVERATH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.9469,
    "Longitude": 7.2177,
    "Telephone Number": "02204-73484",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9469,7.2177",
    "IsOpen24Hours": false,
    "id": "50.9469,7.2177"
  },
  {
    "Gas Station Number": "TD124080",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB A 620 NORDSEITE",
    "Postcode": 66740,
    "City": "SAARLOUIS",
    "State": "Saarland",
    "Latitude": 49.2998,
    "Longitude": 6.7494,
    "Telephone Number": "06831-1585",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.2998,6.7494",
    "IsOpen24Hours": false,
    "id": "49.2998,6.7494"
  },
  {
    "Gas Station Number": "TD006080",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "OSTRING 140",
    "Postcode": 47574,
    "City": "GOCH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.6725,
    "Longitude": 6.1721,
    "Telephone Number": "02823-7406",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6725,6.1721",
    "IsOpen24Hours": false,
    "id": "51.6725,6.1721"
  },
  {
    "Gas Station Number": "TD000863",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GRENZHOEFER WEG 35",
    "Postcode": 69123,
    "City": "HEIDELBERG",
    "State": "Baden-Württemberg",
    "Latitude": 49.4241,
    "Longitude": 8.6385,
    "Telephone Number": "06221-705929",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.4241,8.6385",
    "IsOpen24Hours": false,
    "id": "49.4241,8.6385"
  },
  {
    "Gas Station Number": "TD161240",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FUESSENER STR. 2A",
    "Postcode": 87471,
    "City": "DURACH",
    "State": "Bayern",
    "Latitude": 47.6994,
    "Longitude": 10.3415,
    "Telephone Number": "0831-63279",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.6994,10.3415",
    "IsOpen24Hours": false,
    "id": "47.6994,10.3415"
  },
  {
    "Gas Station Number": "TD000864",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "UNTERE WIESEN 2",
    "Postcode": 76437,
    "City": "RASTATT",
    "State": "Baden-Württemberg",
    "Latitude": 48.8613,
    "Longitude": 8.2397,
    "Telephone Number": "07222-830839",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8613,8.2397",
    "IsOpen24Hours": false,
    "id": "48.8613,8.2397"
  },
  {
    "Gas Station Number": "TD041889",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AM ETZFELD 2",
    "Postcode": 85669,
    "City": "PASTETTEN",
    "State": "Bayern",
    "Latitude": 48.2109,
    "Longitude": 11.9542,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.2109,11.9542",
    "IsOpen24Hours": false,
    "id": "48.2109,11.9542"
  },
  {
    "Gas Station Number": "TD033316",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HOHENSTEINER STR.44",
    "Postcode": 9385,
    "City": "LUGAU",
    "State": "Sachsen",
    "Latitude": 50.7467,
    "Longitude": 12.7419,
    "Telephone Number": "037295-41978",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.7467,12.7419",
    "IsOpen24Hours": false,
    "id": "50.7467,12.7419"
  },
  {
    "Gas Station Number": "TD000821",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FORSTSTRASSE 15",
    "Postcode": 40597,
    "City": "DUESSELDORF",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.1689,
    "Longitude": 6.875,
    "Telephone Number": "0211-7100357",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1689,6.875",
    "IsOpen24Hours": false,
    "id": "51.1689,6.875"
  },
  {
    "Gas Station Number": "TD001537",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KOLPINGSTR.32",
    "Postcode": 74081,
    "City": "HEILBRONN",
    "State": "Baden-Württemberg",
    "Latitude": 49.1228,
    "Longitude": 9.1959,
    "Telephone Number": "07131-255313",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.1228,9.1959",
    "IsOpen24Hours": false,
    "id": "49.1228,9.1959"
  },
  {
    "Gas Station Number": "TD020487",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MARGARETE-SOMMER-STR. 2",
    "Postcode": 10407,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.5308,
    "Longitude": 13.4409,
    "Telephone Number": "030-42852514",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5308,13.4409",
    "IsOpen24Hours": false,
    "id": "52.5308,13.4409"
  },
  {
    "Gas Station Number": "TD036798",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LEHER HEERSTR. 98",
    "Postcode": 28359,
    "City": "BREMEN",
    "State": "Bremen",
    "Latitude": 53.1016,
    "Longitude": 8.8783,
    "Telephone Number": "0421-234565",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.1016,8.8783",
    "IsOpen24Hours": false,
    "id": "53.1016,8.8783"
  },
  {
    "Gas Station Number": "TD041335",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BAHNHOFSTR. 30",
    "Postcode": 9366,
    "City": "STOLLBERG",
    "State": "Sachsen",
    "Latitude": 50.6997,
    "Longitude": 12.7669,
    "Telephone Number": "037296884265",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.6997,12.7669",
    "IsOpen24Hours": false,
    "id": "50.6997,12.7669"
  },
  {
    "Gas Station Number": "TD033498",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BINDERSLEBENER LANDSTR. 98",
    "Postcode": 99092,
    "City": "ERFURT",
    "State": "Thüringen",
    "Latitude": 50.9724,
    "Longitude": 10.9728,
    "Telephone Number": "0361-2229393",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9724,10.9728",
    "IsOpen24Hours": false,
    "id": "50.9724,10.9728"
  },
  {
    "Gas Station Number": "TD035782",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "An der B 62 Nr. 2",
    "Postcode": 36469,
    "City": "BAD SALZUNGEN",
    "State": "Thüringen",
    "Latitude": 50.8258,
    "Longitude": 10.1601,
    "Telephone Number": "03695-82010",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8258,10.1601",
    "IsOpen24Hours": false,
    "id": "50.8258,10.1601"
  },
  {
    "Gas Station Number": "TD001842",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PFORTZER STR. 1",
    "Postcode": 76744,
    "City": "WOERTH",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.0383,
    "Longitude": 8.2893,
    "Telephone Number": "07271-41041",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.0383,8.2893",
    "IsOpen24Hours": false,
    "id": "49.0383,8.2893"
  },
  {
    "Gas Station Number": "TD039818",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SPAETHSTR. 158",
    "Postcode": 12359,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.4532,
    "Longitude": 13.4568,
    "Telephone Number": "030/43208977",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4532,13.4568",
    "IsOpen24Hours": false,
    "id": "52.4532,13.4568"
  },
  {
    "Gas Station Number": "TD035535",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ROSSERTSTR. 2",
    "Postcode": 65451,
    "City": "KELSTERBACH",
    "State": "Hessen",
    "Latitude": 50.066,
    "Longitude": 8.5373,
    "Telephone Number": "06107-3013911",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.066,8.5373",
    "IsOpen24Hours": false,
    "id": "50.066,8.5373"
  },
  {
    "Gas Station Number": "TD002535",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FRIEDRICHSTR. 41",
    "Postcode": 54516,
    "City": "WITTLICH",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.9883,
    "Longitude": 6.8991,
    "Telephone Number": "06571-7277",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.9883,6.8991",
    "IsOpen24Hours": false,
    "id": "49.9883,6.8991"
  },
  {
    "Gas Station Number": "TD030528",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AN DER MAGISTRALE 2",
    "Postcode": 6124,
    "City": "HALLE",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.4817,
    "Longitude": 11.9453,
    "Telephone Number": "0345-8044362",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4817,11.9453",
    "IsOpen24Hours": false,
    "id": "51.4817,11.9453"
  },
  {
    "Gas Station Number": "TD180180",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LORCHER STR. 67",
    "Postcode": 73033,
    "City": "GOEPPINGEN",
    "State": "Baden-Württemberg",
    "Latitude": 48.7114,
    "Longitude": 9.6489,
    "Telephone Number": "07161-25770",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.7114,9.6489",
    "IsOpen24Hours": false,
    "id": "48.7114,9.6489"
  },
  {
    "Gas Station Number": "TD041400",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "RHEINSTR. 31",
    "Postcode": 56179,
    "City": "VALLENDAR",
    "State": "Rheinland-Pfalz",
    "Latitude": 50.3932,
    "Longitude": 7.6168,
    "Telephone Number": "0261-96371544",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.3932,7.6168",
    "IsOpen24Hours": false,
    "id": "50.3932,7.6168"
  },
  {
    "Gas Station Number": "TD123630",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AUTOHOF - AM SCHLUETERSHOF 27",
    "Postcode": 47059,
    "City": "DUISBURG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4379,
    "Longitude": 6.7388,
    "Telephone Number": "0203-314086",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4379,6.7388",
    "IsOpen24Hours": false,
    "id": "51.4379,6.7388"
  },
  {
    "Gas Station Number": "TD035931",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "BAEUMCHEN 15",
    "Postcode": 6667,
    "City": "WEISSENFELS",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.2687,
    "Longitude": 11.9783,
    "Telephone Number": "03443-3383245",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2687,11.9783",
    "IsOpen24Hours": false,
    "id": "51.2687,11.9783"
  },
  {
    "Gas Station Number": "TD039297",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "EICHENBUEHLER STR. 52",
    "Postcode": 63897,
    "City": "MILTENBERG",
    "State": "Bayern",
    "Latitude": 49.7044,
    "Longitude": 9.2673,
    "Telephone Number": "09371-3885",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.7044,9.2673",
    "IsOpen24Hours": false,
    "id": "49.7044,9.2673"
  },
  {
    "Gas Station Number": "TD037499",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AM SPORTPARK 32",
    "Postcode": 58097,
    "City": "HAGEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.369,
    "Longitude": 7.4752,
    "Telephone Number": "02331-81616",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.369,7.4752",
    "IsOpen24Hours": false,
    "id": "51.369,7.4752"
  },
  {
    "Gas Station Number": "TD040717",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "B14/MARBACHER STR.",
    "Postcode": 71364,
    "City": "WINNENDEN",
    "State": "Baden-Württemberg",
    "Latitude": 48.8824,
    "Longitude": 9.3867,
    "Telephone Number": "071955-988995",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8824,9.3867",
    "IsOpen24Hours": false,
    "id": "48.8824,9.3867"
  },
  {
    "Gas Station Number": "TD037903",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB A6/FRANKENHOEHE SUED",
    "Postcode": 91589,
    "City": "AURACH",
    "State": "Bayern",
    "Latitude": 49.2415,
    "Longitude": 10.3522,
    "Telephone Number": "09804-919150",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.2415,10.3522",
    "IsOpen24Hours": false,
    "id": "49.2415,10.3522"
  },
  {
    "Gas Station Number": "TD120480",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FRIEDRICH-EBERT-STR. 12-14",
    "Postcode": 42549,
    "City": "VELBERT",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.3348,
    "Longitude": 7.0489,
    "Telephone Number": "02051-255409",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3348,7.0489",
    "IsOpen24Hours": false,
    "id": "51.3348,7.0489"
  },
  {
    "Gas Station Number": "TD034470",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "OBOTRITENRING 19 A",
    "Postcode": 19053,
    "City": "SCHWERIN",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.6387,
    "Longitude": 11.4041,
    "Telephone Number": "0385-77313",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.6387,11.4041",
    "IsOpen24Hours": false,
    "id": "53.6387,11.4041"
  },
  {
    "Gas Station Number": "TD004283",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "VON KETTELER STR. 13",
    "Postcode": 33106,
    "City": "PADERBORN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.7338,
    "Longitude": 8.6896,
    "Telephone Number": "05254-5150",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.7338,8.6896",
    "IsOpen24Hours": false,
    "id": "51.7338,8.6896"
  },
  {
    "Gas Station Number": "TD032763",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LANGER WEG 17",
    "Postcode": 1257,
    "City": "DRESDEN",
    "State": "Sachsen",
    "Latitude": 51.0069,
    "Longitude": 13.81,
    "Telephone Number": "0351-2753023",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0069,13.81",
    "IsOpen24Hours": false,
    "id": "51.0069,13.81"
  },
  {
    "Gas Station Number": "TD030486",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MERSEBURGER STR.31 b",
    "Postcode": 6632,
    "City": "FREYBURG",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.2207,
    "Longitude": 11.7728,
    "Telephone Number": "034464-28271",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2207,11.7728",
    "IsOpen24Hours": false,
    "id": "51.2207,11.7728"
  },
  {
    "Gas Station Number": "TD035691",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BOSCHSTR. 2",
    "Postcode": 55283,
    "City": "NIERSTEIN",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.8633,
    "Longitude": 8.333,
    "Telephone Number": "06133-491802",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.8633,8.333",
    "IsOpen24Hours": false,
    "id": "49.8633,8.333"
  },
  {
    "Gas Station Number": "TD120580",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ROERMONDER STR. 315",
    "Postcode": 52072,
    "City": "AACHEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.7961,
    "Longitude": 6.0636,
    "Telephone Number": "0241-12773",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.7961,6.0636",
    "IsOpen24Hours": false,
    "id": "50.7961,6.0636"
  },
  {
    "Gas Station Number": "TD020354",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WACKENBERGSTR. 2-6",
    "Postcode": 13156,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.5829,
    "Longitude": 13.4043,
    "Telephone Number": "030-4765891",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5829,13.4043",
    "IsOpen24Hours": false,
    "id": "52.5829,13.4043"
  },
  {
    "Gas Station Number": "TD036699",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PETUELRING 92",
    "Postcode": 80807,
    "City": "MUENCHEN",
    "State": "Bayern",
    "Latitude": 48.1782,
    "Longitude": 11.5721,
    "Telephone Number": "089-3595277",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.1782,11.5721",
    "IsOpen24Hours": false,
    "id": "48.1782,11.5721"
  },
  {
    "Gas Station Number": "TD040691",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WEILSTRASSE 112",
    "Postcode": 35789,
    "City": "WEILMUENSTER",
    "State": "Hessen",
    "Latitude": 50.4341,
    "Longitude": 8.3745,
    "Telephone Number": "06472-8313603",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.4341,8.3745",
    "IsOpen24Hours": false,
    "id": "50.4341,8.3745"
  },
  {
    "Gas Station Number": "TD028894",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ROSA-LUXEMBURG-STR. 133",
    "Postcode": 6917,
    "City": "JESSEN",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.7972,
    "Longitude": 12.9874,
    "Telephone Number": "03537-212408",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.7972,12.9874",
    "IsOpen24Hours": false,
    "id": "51.7972,12.9874"
  },
  {
    "Gas Station Number": "TD005173",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "RUHRORTER STR. 65",
    "Postcode": 46049,
    "City": "OBERHAUSEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4774,
    "Longitude": 6.83,
    "Telephone Number": "0208-807951",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4774,6.83",
    "IsOpen24Hours": false,
    "id": "51.4774,6.83"
  },
  {
    "Gas Station Number": "TD030023",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BAHNHOFSTR.23",
    "Postcode": 19273,
    "City": "NEUHAUS",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.2869,
    "Longitude": 10.9368,
    "Telephone Number": "038841-20733",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.2869,10.9368",
    "IsOpen24Hours": false,
    "id": "53.2869,10.9368"
  },
  {
    "Gas Station Number": "TD031666",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "CHAUSSEESTRASSE 75",
    "Postcode": 18356,
    "City": "BARTH",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 54.3535,
    "Longitude": 12.7245,
    "Telephone Number": "038231-2023",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.3535,12.7245",
    "IsOpen24Hours": false,
    "id": "54.3535,12.7245"
  },
  {
    "Gas Station Number": "TD130140",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DARMSTAEDTER STR. 92",
    "Postcode": 63303,
    "City": "DREIEICH",
    "State": "Hessen",
    "Latitude": 50.0088,
    "Longitude": 8.6894,
    "Telephone Number": "06103-697050",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.0088,8.6894",
    "IsOpen24Hours": false,
    "id": "50.0088,8.6894"
  },
  {
    "Gas Station Number": "TD040022",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "ESCHOLLBRUECKER STR.47",
    "Postcode": 64319,
    "City": "PFUNGSTADT",
    "State": "Hessen",
    "Latitude": 49.8042,
    "Longitude": 8.5836,
    "Telephone Number": "061579390703",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.8042,8.5836",
    "IsOpen24Hours": false,
    "id": "49.8042,8.5836"
  },
  {
    "Gas Station Number": "TD007443",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHUTTERSTR. 3",
    "Postcode": 77746,
    "City": "SCHUTTERWALD",
    "State": "Baden-Württemberg",
    "Latitude": 48.4657,
    "Longitude": 7.8799,
    "Telephone Number": "0781-96560",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.4657,7.8799",
    "IsOpen24Hours": false,
    "id": "48.4657,7.8799"
  },
  {
    "Gas Station Number": "TD036814",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "OBERNEULANDER HEERSTR. 38",
    "Postcode": 28355,
    "City": "BREMEN",
    "State": "Bremen",
    "Latitude": 53.1004,
    "Longitude": 8.9028,
    "Telephone Number": "0421-251443",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.1004,8.9028",
    "IsOpen24Hours": false,
    "id": "53.1004,8.9028"
  },
  {
    "Gas Station Number": "TD150470",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "EICHSTAEDTER CHAUSSEE 4",
    "Postcode": 16727,
    "City": "OBERKRAEMER OT VEHLEFANZ",
    "State": "Brandenburg",
    "Latitude": 52.7087,
    "Longitude": 13.107,
    "Telephone Number": "03304-502308",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.7087,13.107",
    "IsOpen24Hours": false,
    "id": "52.7087,13.107"
  },
  {
    "Gas Station Number": "TD033811",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MITTELHAEUSER STR. 69",
    "Postcode": 99089,
    "City": "ERFURT",
    "State": "Thüringen",
    "Latitude": 51.0096,
    "Longitude": 11.018,
    "Telephone Number": "0361-7920622",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0096,11.018",
    "IsOpen24Hours": false,
    "id": "51.0096,11.018"
  },
  {
    "Gas Station Number": "TD000737",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "VENLOER STR. 46",
    "Postcode": 41569,
    "City": "ROMMERSKIRCHEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.0344,
    "Longitude": 6.6877,
    "Telephone Number": "02183-9530",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0344,6.6877",
    "IsOpen24Hours": false,
    "id": "51.0344,6.6877"
  },
  {
    "Gas Station Number": "TD035394",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AUTOHOF NORD-LIPPE STR. 44",
    "Postcode": 59368,
    "City": "WERNE-STOCKUM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.6985,
    "Longitude": 7.6736,
    "Telephone Number": "02389-780256",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6985,7.6736",
    "IsOpen24Hours": false,
    "id": "51.6985,7.6736"
  },
  {
    "Gas Station Number": "TD040865",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "ZUR KUHBRUECKE 1",
    "Postcode": 1458,
    "City": "OTTENDORF-OKRILLA",
    "State": "Sachsen",
    "Latitude": 51.1747,
    "Longitude": 13.854,
    "Telephone Number": "03520/5189791",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1747,13.854",
    "IsOpen24Hours": false,
    "id": "51.1747,13.854"
  },
  {
    "Gas Station Number": "TD033464",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WERRASTR. 1",
    "Postcode": 98617,
    "City": "MEININGEN",
    "State": "Thüringen",
    "Latitude": 50.5595,
    "Longitude": 10.4131,
    "Telephone Number": "03693-476431",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.5595,10.4131",
    "IsOpen24Hours": false,
    "id": "50.5595,10.4131"
  },
  {
    "Gas Station Number": "TD031831",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "TESSINER STR. 98",
    "Postcode": 18055,
    "City": "ROSTOCK",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 54.0807,
    "Longitude": 12.1891,
    "Telephone Number": "0381-683783",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.0807,12.1891",
    "IsOpen24Hours": false,
    "id": "54.0807,12.1891"
  },
  {
    "Gas Station Number": "TD003038",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FRANKFURTER STR.2",
    "Postcode": 35440,
    "City": "LINDEN",
    "State": "Hessen",
    "Latitude": 50.5325,
    "Longitude": 8.65,
    "Telephone Number": "06403-2522",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.5325,8.65",
    "IsOpen24Hours": false,
    "id": "50.5325,8.65"
  },
  {
    "Gas Station Number": "TD041558",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WINDMUEHLE B51",
    "Postcode": 54298,
    "City": "WELSCHBILLIG",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.8283,
    "Longitude": 6.5643,
    "Telephone Number": "0650699044",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.8283,6.5643",
    "IsOpen24Hours": false,
    "id": "49.8283,6.5643"
  },
  {
    "Gas Station Number": "TD000749",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHWABENSTR. 41",
    "Postcode": 68239,
    "City": "MANNHEIM",
    "State": "Baden-Württemberg",
    "Latitude": 49.4608,
    "Longitude": 8.5697,
    "Telephone Number": "0621-472576",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.4608,8.5697",
    "IsOpen24Hours": false,
    "id": "49.4608,8.5697"
  },
  {
    "Gas Station Number": "TD039040",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "SCHEINFELDER STR. 15",
    "Postcode": 96160,
    "City": "GEISELWIND",
    "State": "Bayern",
    "Latitude": 49.7694,
    "Longitude": 10.4704,
    "Telephone Number": "09556-180",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.7694,10.4704",
    "IsOpen24Hours": false,
    "id": "49.7694,10.4704"
  },
  {
    "Gas Station Number": "TD007278",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GOECKINGKSTR.",
    "Postcode": 99755,
    "City": "ELLRICH",
    "State": "Thüringen",
    "Latitude": 51.5799,
    "Longitude": 10.6774,
    "Telephone Number": "036332-20236",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5799,10.6774",
    "IsOpen24Hours": false,
    "id": "51.5799,10.6774"
  },
  {
    "Gas Station Number": "TD035774",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "NUERNBERGER STRASSE 67",
    "Postcode": 36199,
    "City": "ROTENBURG-LISPENHAUSEN",
    "State": "Hessen",
    "Latitude": 50.9907,
    "Longitude": 9.7758,
    "Telephone Number": "06623-919323",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9907,9.7758",
    "IsOpen24Hours": false,
    "id": "50.9907,9.7758"
  },
  {
    "Gas Station Number": "TD000258",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "INDUSTRIESTR. 4",
    "Postcode": 23843,
    "City": "BAD OLDESLOE",
    "State": "Schleswig-Holstein",
    "Latitude": 53.8019,
    "Longitude": 10.3858,
    "Telephone Number": "04531-4199834",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.8019,10.3858",
    "IsOpen24Hours": false,
    "id": "53.8019,10.3858"
  },
  {
    "Gas Station Number": "TD038158",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "RHOENHOF 5",
    "Postcode": 36124,
    "City": "EICHENZELL",
    "State": "Hessen",
    "Latitude": 50.4884,
    "Longitude": 9.708,
    "Telephone Number": "06659-915450",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.4884,9.708",
    "IsOpen24Hours": false,
    "id": "50.4884,9.708"
  },
  {
    "Gas Station Number": "TD027011",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BERLINER CH.",
    "Postcode": 39307,
    "City": "GENTHIN",
    "State": "Sachsen-Anhalt",
    "Latitude": 52.4082,
    "Longitude": 12.1755,
    "Telephone Number": "03933-803515",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4082,12.1755",
    "IsOpen24Hours": false,
    "id": "52.4082,12.1755"
  },
  {
    "Gas Station Number": "TD039065",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MUENCHNER STR. 87",
    "Postcode": 85221,
    "City": "DACHAU",
    "State": "Bayern",
    "Latitude": 48.2452,
    "Longitude": 11.4488,
    "Telephone Number": "08131-5150350",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.2452,11.4488",
    "IsOpen24Hours": false,
    "id": "48.2452,11.4488"
  },
  {
    "Gas Station Number": "TD004036",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ALTONAER STR. 377",
    "Postcode": 25462,
    "City": "RELLINGEN",
    "State": "Schleswig-Holstein",
    "Latitude": 53.6222,
    "Longitude": 9.885,
    "Telephone Number": "04101-32439",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.6222,9.885",
    "IsOpen24Hours": false,
    "id": "53.6222,9.885"
  },
  {
    "Gas Station Number": "TD039263",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DARMSTAEDTER STR. 143",
    "Postcode": 68647,
    "City": "BIBLIS",
    "State": "Hessen",
    "Latitude": 49.695,
    "Longitude": 8.4653,
    "Telephone Number": "06245-2007479",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.695,8.4653",
    "IsOpen24Hours": false,
    "id": "49.695,8.4653"
  },
  {
    "Gas Station Number": "TD001784",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB-WUNNENSTEIN-WEST/A 81",
    "Postcode": 74360,
    "City": "ILSFELD",
    "State": "Baden-Württemberg",
    "Latitude": 49.0468,
    "Longitude": 9.2656,
    "Telephone Number": "07062-267330",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.0468,9.2656",
    "IsOpen24Hours": false,
    "id": "49.0468,9.2656"
  },
  {
    "Gas Station Number": "TD027789",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "12b WANZLEBENER Allee",
    "Postcode": 39164,
    "City": "WANZLEBEN-BOERDE",
    "State": "Sachsen-Anhalt",
    "Latitude": 52.0967,
    "Longitude": 11.3077,
    "Telephone Number": "039407-5080",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0967,11.3077",
    "IsOpen24Hours": false,
    "id": "52.0967,11.3077"
  },
  {
    "Gas Station Number": "TD007393",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BERLINER CH. 70",
    "Postcode": 6886,
    "City": "WITTENBERG",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.8907,
    "Longitude": 12.6655,
    "Telephone Number": "03491-443011",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8907,12.6655",
    "IsOpen24Hours": false,
    "id": "51.8907,12.6655"
  },
  {
    "Gas Station Number": "TD000879",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAFENBAHNSTR 74",
    "Postcode": 68305,
    "City": "MANNHEIM",
    "State": "Baden-Württemberg",
    "Latitude": 49.5133,
    "Longitude": 8.4843,
    "Telephone Number": "0621-7621810",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.5133,8.4843",
    "IsOpen24Hours": false,
    "id": "49.5133,8.4843"
  },
  {
    "Gas Station Number": "TD040683",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GIESSENER STR.120",
    "Postcode": 35452,
    "City": "HEUCHELHEIM",
    "State": "Hessen",
    "Latitude": 50.5863,
    "Longitude": 8.6414,
    "Telephone Number": "0641-63624",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.5863,8.6414",
    "IsOpen24Hours": false,
    "id": "50.5863,8.6414"
  },
  {
    "Gas Station Number": "TD040220",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MUENCHENER STR. 70",
    "Postcode": 84453,
    "City": "MUEHLDORF",
    "State": "Bayern",
    "Latitude": 48.2457,
    "Longitude": 12.5105,
    "Telephone Number": "08631/37810",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.2457,12.5105",
    "IsOpen24Hours": false,
    "id": "48.2457,12.5105"
  },
  {
    "Gas Station Number": "TD029645",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WISMARSCHE STR. 13B",
    "Postcode": 18246,
    "City": "BUETZOW",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.8468,
    "Longitude": 11.9585,
    "Telephone Number": "038461-67157",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.8468,11.9585",
    "IsOpen24Hours": false,
    "id": "53.8468,11.9585"
  },
  {
    "Gas Station Number": "TD020529",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "I.-GANDHI-STR. 106-109",
    "Postcode": 13053,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.5475,
    "Longitude": 13.4681,
    "Telephone Number": "030-96064977",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5475,13.4681",
    "IsOpen24Hours": false,
    "id": "52.5475,13.4681"
  },
  {
    "Gas Station Number": "TD038489",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ZSCHOCHERSCHE STR. 84",
    "Postcode": 4229,
    "City": "LEIPZIG",
    "State": "Sachsen",
    "Latitude": 51.3227,
    "Longitude": 12.3319,
    "Telephone Number": "0341-4803837",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3227,12.3319",
    "IsOpen24Hours": false,
    "id": "51.3227,12.3319"
  },
  {
    "Gas Station Number": "TD190090",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAUPTSTR. 25",
    "Postcode": 77880,
    "City": "SASBACH",
    "State": "Baden-Württemberg",
    "Latitude": 48.6355,
    "Longitude": 8.0881,
    "Telephone Number": "07841-664138",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.6355,8.0881",
    "IsOpen24Hours": false,
    "id": "48.6355,8.0881"
  },
  {
    "Gas Station Number": "TD042069",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MARCO-POLO-STR. 1",
    "Postcode": 74589,
    "City": "SATTELDORF",
    "State": "Baden-Württemberg",
    "Latitude": 49.1818,
    "Longitude": 10.0694,
    "Telephone Number": "0795196900",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.1818,10.0694",
    "IsOpen24Hours": false,
    "id": "49.1818,10.0694"
  },
  {
    "Gas Station Number": "TD007096",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WALDENBURGER STR. 115",
    "Postcode": 8371,
    "City": "GLAUCHAU",
    "State": "Sachsen",
    "Latitude": 50.8402,
    "Longitude": 12.5425,
    "Telephone Number": "03763-15022",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8402,12.5425",
    "IsOpen24Hours": false,
    "id": "50.8402,12.5425"
  },
  {
    "Gas Station Number": "TD161460",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LEONHARDSBERG 17",
    "Postcode": 86150,
    "City": "AUGSBURG",
    "State": "Bayern",
    "Latitude": 48.3707,
    "Longitude": 10.9004,
    "Telephone Number": "0821-514662",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.3707,10.9004",
    "IsOpen24Hours": false,
    "id": "48.3707,10.9004"
  },
  {
    "Gas Station Number": "TD029702",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "RTK Nord 1",
    "Postcode": 19372,
    "City": "STOLPE",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.3677,
    "Longitude": 11.7319,
    "Telephone Number": "038725-169975",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.3677,11.7319",
    "IsOpen24Hours": false,
    "id": "53.3677,11.7319"
  },
  {
    "Gas Station Number": "TD033068",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KOENIGSBRUECKER LANDSTR. 265",
    "Postcode": 1108,
    "City": "DRESDEN-WEIXDORF",
    "State": "Sachsen",
    "Latitude": 51.1404,
    "Longitude": 13.7976,
    "Telephone Number": "0351-8802301",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1404,13.7976",
    "IsOpen24Hours": false,
    "id": "51.1404,13.7976"
  },
  {
    "Gas Station Number": "TD033431",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "TOEPELSTR.",
    "Postcode": 4746,
    "City": "HARTHA",
    "State": "Sachsen",
    "Latitude": 51.1043,
    "Longitude": 12.9623,
    "Telephone Number": "034328-60424",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1043,12.9623",
    "IsOpen24Hours": false,
    "id": "51.1043,12.9623"
  },
  {
    "Gas Station Number": "TD191420",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FOLTZRING 38-40",
    "Postcode": 67227,
    "City": "FRANKENTHAL",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.5378,
    "Longitude": 8.359,
    "Telephone Number": "06233-66197",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.5378,8.359",
    "IsOpen24Hours": false,
    "id": "49.5378,8.359"
  },
  {
    "Gas Station Number": "TD040378",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "WUERZBURGER STR. 55",
    "Postcode": 97318,
    "City": "BIEBELRIED",
    "State": "Bayern",
    "Latitude": 49.7668,
    "Longitude": 10.0758,
    "Telephone Number": "09302-6498750",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.7668,10.0758",
    "IsOpen24Hours": false,
    "id": "49.7668,10.0758"
  },
  {
    "Gas Station Number": "TD007542",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DUEBENER STR. 133",
    "Postcode": 4509,
    "City": "DELITZSCH",
    "State": "Sachsen",
    "Latitude": 51.5319,
    "Longitude": 12.3573,
    "Telephone Number": "034202/969969",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5319,12.3573",
    "IsOpen24Hours": false,
    "id": "51.5319,12.3573"
  },
  {
    "Gas Station Number": "TD040360",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAUPTSTRASSE / KORNBLUMENWEG 43",
    "Postcode": 78244,
    "City": "GOTTMADINGEN",
    "State": "Baden-Württemberg",
    "Latitude": 47.7366,
    "Longitude": 8.7881,
    "Telephone Number": "07731-9072490",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.7366,8.7881",
    "IsOpen24Hours": false,
    "id": "47.7366,8.7881"
  },
  {
    "Gas Station Number": "TD000748",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KAISERSLAUTERER STR.37",
    "Postcode": 66424,
    "City": "HOMBURG-BRUCHHOF",
    "State": "Saarland",
    "Latitude": 49.343,
    "Longitude": 7.3586,
    "Telephone Number": "06841-64264",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.343,7.3586",
    "IsOpen24Hours": false,
    "id": "49.343,7.3586"
  },
  {
    "Gas Station Number": "TD111130",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WESTICKER STR. 58",
    "Postcode": 58730,
    "City": "FROENDENBERG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4753,
    "Longitude": 7.7887,
    "Telephone Number": "02373-76259",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4753,7.7887",
    "IsOpen24Hours": false,
    "id": "51.4753,7.7887"
  },
  {
    "Gas Station Number": "TD036376",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ARNULFSTR. 98-99",
    "Postcode": 12105,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.4556,
    "Longitude": 13.3673,
    "Telephone Number": "030-75489500",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4556,13.3673",
    "IsOpen24Hours": false,
    "id": "52.4556,13.3673"
  },
  {
    "Gas Station Number": "TD038430",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AN DER AUTOBAHN 4",
    "Postcode": 28876,
    "City": "OYTEN",
    "State": "Niedersachsen",
    "Latitude": 53.0534,
    "Longitude": 9.0318,
    "Telephone Number": "04207-7081",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.0534,9.0318",
    "IsOpen24Hours": false,
    "id": "53.0534,9.0318"
  },
  {
    "Gas Station Number": "TD182410",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SALEMER STR. 47",
    "Postcode": 88697,
    "City": "BERMATINGEN",
    "State": "Baden-Württemberg",
    "Latitude": 47.7358,
    "Longitude": 9.3414,
    "Telephone Number": "07544-741930",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.7358,9.3414",
    "IsOpen24Hours": false,
    "id": "47.7358,9.3414"
  },
  {
    "Gas Station Number": "TD005967",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WAIBSTAEDTER STR. 35",
    "Postcode": 74924,
    "City": "NECKARBISCHOFSHEIM",
    "State": "Baden-Württemberg",
    "Latitude": 49.2958,
    "Longitude": 8.9544,
    "Telephone Number": "07263-969900",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.2958,8.9544",
    "IsOpen24Hours": false,
    "id": "49.2958,8.9544"
  },
  {
    "Gas Station Number": "TD021360",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FABRIKSTR. 17",
    "Postcode": 1683,
    "City": "NOSSEN",
    "State": "Sachsen",
    "Latitude": 51.0625,
    "Longitude": 13.2912,
    "Telephone Number": "035242-68259",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0625,13.2912",
    "IsOpen24Hours": false,
    "id": "51.0625,13.2912"
  },
  {
    "Gas Station Number": "TD039784",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HEIDENHEIMER STR. 17",
    "Postcode": 73450,
    "City": "NERESHEIM",
    "State": "Baden-Württemberg",
    "Latitude": 48.755,
    "Longitude": 10.3283,
    "Telephone Number": "07326389",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.755,10.3283",
    "IsOpen24Hours": false,
    "id": "48.755,10.3283"
  },
  {
    "Gas Station Number": "TD181280",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SEEROSENSTR. 48",
    "Postcode": 70563,
    "City": "STUTTGART",
    "State": "Baden-Württemberg",
    "Latitude": 48.7337,
    "Longitude": 9.1069,
    "Telephone Number": "0711-731477",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.7337,9.1069",
    "IsOpen24Hours": false,
    "id": "48.7337,9.1069"
  },
  {
    "Gas Station Number": "TD020362",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ADLERGESTELL 623",
    "Postcode": 12527,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.3878,
    "Longitude": 13.6307,
    "Telephone Number": "030-6758108",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3878,13.6307",
    "IsOpen24Hours": false,
    "id": "52.3878,13.6307"
  },
  {
    "Gas Station Number": "TD003111",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AARSTR. 212",
    "Postcode": 65232,
    "City": "TAUNUSSTEIN",
    "State": "Hessen",
    "Latitude": 50.1528,
    "Longitude": 8.1805,
    "Telephone Number": "06128-982542",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.1528,8.1805",
    "IsOpen24Hours": false,
    "id": "50.1528,8.1805"
  },
  {
    "Gas Station Number": "TD039792",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "NOERDLINGER STR. 11",
    "Postcode": 86757,
    "City": "WALLERSTEIN",
    "State": "Bayern",
    "Latitude": 48.8836,
    "Longitude": 10.4761,
    "Telephone Number": "09081-7087",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8836,10.4761",
    "IsOpen24Hours": false,
    "id": "48.8836,10.4761"
  },
  {
    "Gas Station Number": "TD004614",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BENTHEIMER STR. 233",
    "Postcode": 48529,
    "City": "NORDHORN",
    "State": "Niedersachsen",
    "Latitude": 52.4196,
    "Longitude": 7.0836,
    "Telephone Number": "05921-8274720",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4196,7.0836",
    "IsOpen24Hours": false,
    "id": "52.4196,7.0836"
  },
  {
    "Gas Station Number": "TD020297",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BULGARISCHE STR. 9",
    "Postcode": 12435,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.4838,
    "Longitude": 13.4787,
    "Telephone Number": "030-5337955",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4838,13.4787",
    "IsOpen24Hours": false,
    "id": "52.4838,13.4787"
  },
  {
    "Gas Station Number": "TD040774",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHLACHTHAUSSTR. 52",
    "Postcode": 89312,
    "City": "GUENZBURG",
    "State": "Bayern",
    "Latitude": 48.4586,
    "Longitude": 10.2767,
    "Telephone Number": "08221-2039255",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.4586,10.2767",
    "IsOpen24Hours": false,
    "id": "48.4586,10.2767"
  },
  {
    "Gas Station Number": "TD040931",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "REGENSBURGER STR. 20A",
    "Postcode": 93080,
    "City": "PENTLING",
    "State": "Bayern",
    "Latitude": 48.9603,
    "Longitude": 12.048,
    "Telephone Number": "09405-9680979",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.9603,12.048",
    "IsOpen24Hours": false,
    "id": "48.9603,12.048"
  },
  {
    "Gas Station Number": "TD038182",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "RUHMRISCHKAMP 3",
    "Postcode": 38179,
    "City": "SCHWUELPER",
    "State": "Niedersachsen",
    "Latitude": 52.3376,
    "Longitude": 10.4178,
    "Telephone Number": "05303-9709854",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3376,10.4178",
    "IsOpen24Hours": false,
    "id": "52.3376,10.4178"
  },
  {
    "Gas Station Number": "TD020321",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ALT MAHLSDORF 60",
    "Postcode": 12623,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.5042,
    "Longitude": 13.6243,
    "Telephone Number": "030-5677297",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5042,13.6243",
    "IsOpen24Hours": false,
    "id": "52.5042,13.6243"
  },
  {
    "Gas Station Number": "TD027284",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ALBERT-VATER-STR. 30",
    "Postcode": 39108,
    "City": "MAGDEBURG",
    "State": "Sachsen-Anhalt",
    "Latitude": 52.1413,
    "Longitude": 11.6234,
    "Telephone Number": "0391-2511279",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1413,11.6234",
    "IsOpen24Hours": false,
    "id": "52.1413,11.6234"
  },
  {
    "Gas Station Number": "TD041665",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MEINKINGSBURG 6a",
    "Postcode": 31636,
    "City": "LINSBURG",
    "State": "Niedersachsen",
    "Latitude": 52.5821,
    "Longitude": 9.2914,
    "Telephone Number": "05027-776",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5821,9.2914",
    "IsOpen24Hours": false,
    "id": "52.5821,9.2914"
  },
  {
    "Gas Station Number": "TD034710",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB BOERDE NORD / A2",
    "Postcode": 39343,
    "City": "GROSS SANTERSLEBEN",
    "State": "Sachsen-Anhalt",
    "Latitude": 52.1844,
    "Longitude": 11.4542,
    "Telephone Number": "039206-68351",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1844,11.4542",
    "IsOpen24Hours": false,
    "id": "52.1844,11.4542"
  },
  {
    "Gas Station Number": "TD000842",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "UNGERATH 301",
    "Postcode": 41366,
    "City": "SCHWALMTAL",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.2086,
    "Longitude": 6.2808,
    "Telephone Number": "02163-943140",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2086,6.2808",
    "IsOpen24Hours": false,
    "id": "51.2086,6.2808"
  },
  {
    "Gas Station Number": "TD182310",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "OHMSTR. 25",
    "Postcode": 70736,
    "City": "FELLBACH",
    "State": "Baden-Württemberg",
    "Latitude": 48.8223,
    "Longitude": 9.285,
    "Telephone Number": "0711-54041707",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8223,9.285",
    "IsOpen24Hours": false,
    "id": "48.8223,9.285"
  },
  {
    "Gas Station Number": "TD035329",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MAINZER STR. 140",
    "Postcode": 67657,
    "City": "KAISERSLAUTERN",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.4573,
    "Longitude": 7.8015,
    "Telephone Number": "0631-40226",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.4573,7.8015",
    "IsOpen24Hours": false,
    "id": "49.4573,7.8015"
  },
  {
    "Gas Station Number": "TD020131",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BERLINER STR. 31",
    "Postcode": 16244,
    "City": "SCHORFHEIDE",
    "State": "Brandenburg",
    "Latitude": 52.8982,
    "Longitude": 13.5374,
    "Telephone Number": "033393-516",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.8982,13.5374",
    "IsOpen24Hours": false,
    "id": "52.8982,13.5374"
  },
  {
    "Gas Station Number": "TD029595",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GRABOWER ALLEE 31",
    "Postcode": 19288,
    "City": "LUDWIGSLUST",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.3161,
    "Longitude": 11.5065,
    "Telephone Number": "03874-61143/45",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.3161,11.5065",
    "IsOpen24Hours": false,
    "id": "53.3161,11.5065"
  },
  {
    "Gas Station Number": "TD019299",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HARPENER HELLWEG 88",
    "Postcode": 44805,
    "City": "BOCHUM-HARPEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4993,
    "Longitude": 7.2764,
    "Telephone Number": "0234-231498",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4993,7.2764",
    "IsOpen24Hours": false,
    "id": "51.4993,7.2764"
  },
  {
    "Gas Station Number": "TD039354",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LANGES GEWAND 3",
    "Postcode": 78604,
    "City": "RIETHEIM-WEILHEIM",
    "State": "Baden-Württemberg",
    "Latitude": 48.0303,
    "Longitude": 8.7757,
    "Telephone Number": "07461-969725100",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.0303,8.7757",
    "IsOpen24Hours": false,
    "id": "48.0303,8.7757"
  },
  {
    "Gas Station Number": "TD130230",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WEILBURGER STR. 30/33",
    "Postcode": 61250,
    "City": "USINGEN",
    "State": "Hessen",
    "Latitude": 50.3401,
    "Longitude": 8.5349,
    "Telephone Number": "06081-2680",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.3401,8.5349",
    "IsOpen24Hours": false,
    "id": "50.3401,8.5349"
  },
  {
    "Gas Station Number": "TD035717",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "NAUMBURGER STRASSE 26",
    "Postcode": 6667,
    "City": "WEISSENFELS",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.1983,
    "Longitude": 11.9639,
    "Telephone Number": "03443-334566",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1983,11.9639",
    "IsOpen24Hours": false,
    "id": "51.1983,11.9639"
  },
  {
    "Gas Station Number": "TD000757",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "EDINGER RIEDWEG 8-12",
    "Postcode": 68219,
    "City": "MANNHEIM",
    "State": "Baden-Württemberg",
    "Latitude": 49.4218,
    "Longitude": 8.534,
    "Telephone Number": "0621-8019209",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.4218,8.534",
    "IsOpen24Hours": false,
    "id": "49.4218,8.534"
  },
  {
    "Gas Station Number": "TD030775",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "QUERFURTER STR. 4A",
    "Postcode": 6217,
    "City": "MERSEBURG",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.3718,
    "Longitude": 11.9829,
    "Telephone Number": "03461-204531",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3718,11.9829",
    "IsOpen24Hours": false,
    "id": "51.3718,11.9829"
  },
  {
    "Gas Station Number": "TD120390",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KREUZBERGSTR. 65",
    "Postcode": 42899,
    "City": "REMSCHEID",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.2086,
    "Longitude": 7.2314,
    "Telephone Number": "02191-462832",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2086,7.2314",
    "IsOpen24Hours": false,
    "id": "51.2086,7.2314"
  },
  {
    "Gas Station Number": "TD000232",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PARKSTR. 16",
    "Postcode": 47877,
    "City": "WILLICH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.2667,
    "Longitude": 6.5514,
    "Telephone Number": "02154-205770",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2667,6.5514",
    "IsOpen24Hours": false,
    "id": "51.2667,6.5514"
  },
  {
    "Gas Station Number": "TD124230",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHULTH.-KOLLEI-STR. 23",
    "Postcode": 55411,
    "City": "BINGEN",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.9501,
    "Longitude": 7.9032,
    "Telephone Number": "06721-44713",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.9501,7.9032",
    "IsOpen24Hours": false,
    "id": "49.9501,7.9032"
  },
  {
    "Gas Station Number": "TD000460",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HAUPERSWEILER STR. 1",
    "Postcode": 66629,
    "City": "FREISEN-OBERKIRCHEN",
    "State": "Saarland",
    "Latitude": 49.525,
    "Longitude": 7.263,
    "Telephone Number": "06855-7100",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.525,7.263",
    "IsOpen24Hours": false,
    "id": "49.525,7.263"
  },
  {
    "Gas Station Number": "TD133510",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "IDSTEINER STR. 50",
    "Postcode": 65527,
    "City": "NIEDERNHAUSEN",
    "State": "Hessen",
    "Latitude": 50.1643,
    "Longitude": 8.3126,
    "Telephone Number": "06127-903620",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.1643,8.3126",
    "IsOpen24Hours": false,
    "id": "50.1643,8.3126"
  },
  {
    "Gas Station Number": "TD035790",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "ZUM DOENGES 2",
    "Postcode": 36208,
    "City": "WILDECK-OBERSUHL",
    "State": "Hessen",
    "Latitude": 50.9551,
    "Longitude": 10.0224,
    "Telephone Number": "06626-92100",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9551,10.0224",
    "IsOpen24Hours": false,
    "id": "50.9551,10.0224"
  },
  {
    "Gas Station Number": "TD032557",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "NAUENER STR. 116",
    "Postcode": 14612,
    "City": "FALKENSEE",
    "State": "Brandenburg",
    "Latitude": 52.5864,
    "Longitude": 13.065,
    "Telephone Number": "03322-22701",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5864,13.065",
    "IsOpen24Hours": false,
    "id": "52.5864,13.065"
  },
  {
    "Gas Station Number": "TD039198",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HORSTWEG 53c",
    "Postcode": 14478,
    "City": "POTSDAM",
    "State": "Brandenburg",
    "Latitude": 52.3824,
    "Longitude": 13.0885,
    "Telephone Number": "0331-87003713",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3824,13.0885",
    "IsOpen24Hours": false,
    "id": "52.3824,13.0885"
  },
  {
    "Gas Station Number": "TD000430",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "RICHRATHER STR. 116",
    "Postcode": 40723,
    "City": "HILDEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.1583,
    "Longitude": 6.9386,
    "Telephone Number": "02103-64460",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1583,6.9386",
    "IsOpen24Hours": false,
    "id": "51.1583,6.9386"
  },
  {
    "Gas Station Number": "TD180490",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AACHER STR. 18",
    "Postcode": 78234,
    "City": "ENGEN",
    "State": "Baden-Württemberg",
    "Latitude": 47.8557,
    "Longitude": 8.7816,
    "Telephone Number": "07733-9399413",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.8557,8.7816",
    "IsOpen24Hours": false,
    "id": "47.8557,8.7816"
  },
  {
    "Gas Station Number": "TD000638",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ZOLLHAUSSTR. 10",
    "Postcode": 52353,
    "City": "DUEREN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.8222,
    "Longitude": 6.4614,
    "Telephone Number": "02421-81150",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8222,6.4614",
    "IsOpen24Hours": false,
    "id": "50.8222,6.4614"
  },
  {
    "Gas Station Number": "TD027847",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FRIEDENSSTR.1",
    "Postcode": 39393,
    "City": "VOELPKE",
    "State": "Sachsen-Anhalt",
    "Latitude": 52.1406,
    "Longitude": 11.1057,
    "Telephone Number": "039402-9610",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1406,11.1057",
    "IsOpen24Hours": false,
    "id": "52.1406,11.1057"
  },
  {
    "Gas Station Number": "TD034942",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ERNST-THAELMANN-STR. 1",
    "Postcode": 2994,
    "City": "BERNSDORF",
    "State": "Sachsen",
    "Latitude": 51.3769,
    "Longitude": 14.0714,
    "Telephone Number": "035723-20110",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3769,14.0714",
    "IsOpen24Hours": false,
    "id": "51.3769,14.0714"
  },
  {
    "Gas Station Number": "TD040816",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "UNTERER KELLERMANN 1",
    "Postcode": 97273,
    "City": "KUERNACH",
    "State": "Bayern",
    "Latitude": 49.84,
    "Longitude": 10.0183,
    "Telephone Number": "093679889070",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.84,10.0183",
    "IsOpen24Hours": false,
    "id": "49.84,10.0183"
  },
  {
    "Gas Station Number": "TD124360",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HANNOEVERSCHE STR.52A",
    "Postcode": 44143,
    "City": "DORTMUND",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5245,
    "Longitude": 7.511,
    "Telephone Number": "0231-5315877",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5245,7.511",
    "IsOpen24Hours": false,
    "id": "51.5245,7.511"
  },
  {
    "Gas Station Number": "TD036772",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KANTSTR. 25",
    "Postcode": 97074,
    "City": "WUERZBURG",
    "State": "Bayern",
    "Latitude": 49.778,
    "Longitude": 9.9427,
    "Telephone Number": "0931-81701",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.778,9.9427",
    "IsOpen24Hours": false,
    "id": "49.778,9.9427"
  },
  {
    "Gas Station Number": "TD039404",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LERCHENAUERSTR. 75",
    "Postcode": 80809,
    "City": "MUENCHEN",
    "State": "Bayern",
    "Latitude": 48.1823,
    "Longitude": 11.5539,
    "Telephone Number": "089-23540304",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.1823,11.5539",
    "IsOpen24Hours": false,
    "id": "48.1823,11.5539"
  },
  {
    "Gas Station Number": "TD110310",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HALTERNER STR.100",
    "Postcode": 45657,
    "City": "RECKLINGHAUSEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.6244,
    "Longitude": 7.193,
    "Telephone Number": "02361-22485",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6244,7.193",
    "IsOpen24Hours": false,
    "id": "51.6244,7.193"
  },
  {
    "Gas Station Number": "TD028951",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ROTHSTEINSLACHE 2",
    "Postcode": 4895,
    "City": "FALKENBERG",
    "State": "Brandenburg",
    "Latitude": 51.587,
    "Longitude": 13.2486,
    "Telephone Number": "03536-531275",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.587,13.2486",
    "IsOpen24Hours": false,
    "id": "51.587,13.2486"
  },
  {
    "Gas Station Number": "TD028290",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PLAUER LANDSTR. 3",
    "Postcode": 14772,
    "City": "BRANDENBURG",
    "State": "Brandenburg",
    "Latitude": 52.4166,
    "Longitude": 12.4867,
    "Telephone Number": "03381-700066",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4166,12.4867",
    "IsOpen24Hours": false,
    "id": "52.4166,12.4867"
  },
  {
    "Gas Station Number": "TD024588",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SAALFELDER STR. 90",
    "Postcode": 98724,
    "City": "NEUHAUS AM RENNWEG OT LICHTE",
    "State": "Thüringen",
    "Latitude": 50.5253,
    "Longitude": 11.2237,
    "Telephone Number": "036701-60425",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.5253,11.2237",
    "IsOpen24Hours": false,
    "id": "50.5253,11.2237"
  },
  {
    "Gas Station Number": "TD040238",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ROSENHEIMER STR. 116",
    "Postcode": 83080,
    "City": "NIEDERAUDORF",
    "State": "Bayern",
    "Latitude": 47.6675,
    "Longitude": 12.1691,
    "Telephone Number": "08033/1501",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "47.6675,12.1691",
    "IsOpen24Hours": false,
    "id": "47.6675,12.1691"
  },
  {
    "Gas Station Number": "TD029165",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AN DER KLEMPNITZ 2",
    "Postcode": 16868,
    "City": "WUSTERHAUSEN/DOSSE OT WUSTERHAUSEN",
    "State": "Brandenburg",
    "Latitude": 52.8936,
    "Longitude": 12.4551,
    "Telephone Number": "033979-14515",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.8936,12.4551",
    "IsOpen24Hours": false,
    "id": "52.8936,12.4551"
  },
  {
    "Gas Station Number": "TD123870",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DR.-WILH.-ROELEN-STR 357 a",
    "Postcode": 47179,
    "City": "DUISBURG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5272,
    "Longitude": 6.7359,
    "Telephone Number": "0203-493735",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5272,6.7359",
    "IsOpen24Hours": false,
    "id": "51.5272,6.7359"
  },
  {
    "Gas Station Number": "TD041095",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "2 MUGGENSTUMER LANDSTR.",
    "Postcode": 76467,
    "City": "BIETIGHEIM",
    "State": "Baden-Württemberg",
    "Latitude": 48.8849,
    "Longitude": 8.2692,
    "Telephone Number": "07222-150050",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8849,8.2692",
    "IsOpen24Hours": false,
    "id": "48.8849,8.2692"
  },
  {
    "Gas Station Number": "TD031682",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ROEVERSHAEGER CH. 3",
    "Postcode": 18146,
    "City": "ROSTOCK",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 54.0996,
    "Longitude": 12.1773,
    "Telephone Number": "0381-681630",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.0996,12.1773",
    "IsOpen24Hours": false,
    "id": "54.0996,12.1773"
  },
  {
    "Gas Station Number": "TD041368",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MEIERKAMP 1",
    "Postcode": 31249,
    "City": "HOHENHAMELN",
    "State": "Niedersachsen",
    "Latitude": 52.2626,
    "Longitude": 10.0751,
    "Telephone Number": "05128-9489910",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2626,10.0751",
    "IsOpen24Hours": false,
    "id": "52.2626,10.0751"
  },
  {
    "Gas Station Number": "TD124180",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "JAEGERHOF 10",
    "Postcode": 41516,
    "City": "GREVENBROICH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.1218,
    "Longitude": 6.6667,
    "Telephone Number": "02182-885034",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1218,6.6667",
    "IsOpen24Hours": false,
    "id": "51.1218,6.6667"
  },
  {
    "Gas Station Number": "TD036723",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AM SAUERECK 2",
    "Postcode": 86732,
    "City": "OETTINGEN",
    "State": "Bayern",
    "Latitude": 48.947,
    "Longitude": 10.597,
    "Telephone Number": "09082-1444",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.947,10.597",
    "IsOpen24Hours": false,
    "id": "48.947,10.597"
  },
  {
    "Gas Station Number": "TD040444",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "HAUPTSTR. 107",
    "Postcode": 49205,
    "City": "HASBERGEN",
    "State": "Niedersachsen",
    "Latitude": 52.2657,
    "Longitude": 7.9643,
    "Telephone Number": "05405-8081564",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2657,7.9643",
    "IsOpen24Hours": false,
    "id": "52.2657,7.9643"
  },
  {
    "Gas Station Number": "TD037374",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BRAUNSCHWEIGER STR. 36 A",
    "Postcode": 38723,
    "City": "SEESEN",
    "State": "Niedersachsen",
    "Latitude": 51.8979,
    "Longitude": 10.1832,
    "Telephone Number": "05381-1220",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8979,10.1832",
    "IsOpen24Hours": false,
    "id": "51.8979,10.1832"
  },
  {
    "Gas Station Number": "TD000739",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HOMBERGER STR.21",
    "Postcode": 40882,
    "City": "RATINGEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.296,
    "Longitude": 6.8668,
    "Telephone Number": "02102-843167",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.296,6.8668",
    "IsOpen24Hours": false,
    "id": "51.296,6.8668"
  },
  {
    "Gas Station Number": "TD038653",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ROHRAUER STR.38",
    "Postcode": 71154,
    "City": "NUFRINGEN",
    "State": "Baden-Württemberg",
    "Latitude": 48.6208,
    "Longitude": 8.8946,
    "Telephone Number": "07032958055",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.6208,8.8946",
    "IsOpen24Hours": false,
    "id": "48.6208,8.8946"
  },
  {
    "Gas Station Number": "TD041418",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MERCHINGER STR.1",
    "Postcode": 74706,
    "City": "OSTERBURKEN",
    "State": "Baden-Württemberg",
    "Latitude": 49.4279,
    "Longitude": 9.4271,
    "Telephone Number": "0629164210",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.4279,9.4271",
    "IsOpen24Hours": false,
    "id": "49.4279,9.4271"
  },
  {
    "Gas Station Number": "TD023796",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AM URBICHER KREUZ 34",
    "Postcode": 99099,
    "City": "ERFURT",
    "State": "Thüringen",
    "Latitude": 50.9496,
    "Longitude": 11.0942,
    "Telephone Number": "0361-4210828",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9496,11.0942",
    "IsOpen24Hours": false,
    "id": "50.9496,11.0942"
  },
  {
    "Gas Station Number": "TD038380",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "GOLDBECKSTR. 1",
    "Postcode": 69493,
    "City": "HIRSCHBERG",
    "State": "Baden-Württemberg",
    "Latitude": 49.5074,
    "Longitude": 8.6369,
    "Telephone Number": "06201-876431",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.5074,8.6369",
    "IsOpen24Hours": false,
    "id": "49.5074,8.6369"
  },
  {
    "Gas Station Number": "TD035857",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BORSCHER STRASSE 21",
    "Postcode": 36419,
    "City": "GEISA",
    "State": "Thüringen",
    "Latitude": 50.7225,
    "Longitude": 9.9585,
    "Telephone Number": "036967-76511",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.7225,9.9585",
    "IsOpen24Hours": false,
    "id": "50.7225,9.9585"
  },
  {
    "Gas Station Number": "TD037184",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SYKERSTR. 74",
    "Postcode": 27751,
    "City": "DELMENHORST",
    "State": "Niedersachsen",
    "Latitude": 53.0503,
    "Longitude": 8.6545,
    "Telephone Number": "04221-71253",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.0503,8.6545",
    "IsOpen24Hours": false,
    "id": "53.0503,8.6545"
  },
  {
    "Gas Station Number": "TD023754",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "ERFURTER LANDSTRASSE 49",
    "Postcode": 99095,
    "City": "ERFURT-STOTTERNHEIM",
    "State": "Thüringen",
    "Latitude": 51.0462,
    "Longitude": 11.0421,
    "Telephone Number": "036204-52515",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0462,11.0421",
    "IsOpen24Hours": false,
    "id": "51.0462,11.0421"
  },
  {
    "Gas Station Number": "TD020370",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "OBERSPREESTR. 138",
    "Postcode": 12555,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.4458,
    "Longitude": 13.5618,
    "Telephone Number": "030-6519761",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4458,13.5618",
    "IsOpen24Hours": false,
    "id": "52.4458,13.5618"
  },
  {
    "Gas Station Number": "TD025346",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ZSCHOPAUER STR. 319",
    "Postcode": 9127,
    "City": "CHEMNITZ",
    "State": "Sachsen",
    "Latitude": 50.8102,
    "Longitude": 12.9651,
    "Telephone Number": "0371-7253452",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8102,12.9651",
    "IsOpen24Hours": false,
    "id": "50.8102,12.9651"
  },
  {
    "Gas Station Number": "TD029827",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ROSTOCKER STR. 154",
    "Postcode": 17166,
    "City": "TETEROW",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 53.7859,
    "Longitude": 12.5705,
    "Telephone Number": "03996-172306",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.7859,12.5705",
    "IsOpen24Hours": false,
    "id": "53.7859,12.5705"
  },
  {
    "Gas Station Number": "TD112720",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HEEKER STR. 9",
    "Postcode": 48629,
    "City": "METELEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.1434,
    "Longitude": 7.2078,
    "Telephone Number": "02556-98775",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1434,7.2078",
    "IsOpen24Hours": false,
    "id": "52.1434,7.2078"
  },
  {
    "Gas Station Number": "TD002493",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "MARTIN-LUTHER-STR. 21",
    "Postcode": 66111,
    "City": "SAARBRUECKEN",
    "State": "Saarland",
    "Latitude": 49.2376,
    "Longitude": 7.0048,
    "Telephone Number": "0681-398444",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.2376,7.0048",
    "IsOpen24Hours": false,
    "id": "49.2376,7.0048"
  },
  {
    "Gas Station Number": "TD141130",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WALDSTR. 20",
    "Postcode": 37520,
    "City": "OSTERODE",
    "State": "Niedersachsen",
    "Latitude": 51.7259,
    "Longitude": 10.2613,
    "Telephone Number": "05522-2522",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.7259,10.2613",
    "IsOpen24Hours": false,
    "id": "51.7259,10.2613"
  },
  {
    "Gas Station Number": "TD042077",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WREXER TEICH 1",
    "Postcode": 34474,
    "City": "DIEMELSTADT",
    "State": "Hessen",
    "Latitude": 51.4886,
    "Longitude": 9.0072,
    "Telephone Number": "05694-1688",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4886,9.0072",
    "IsOpen24Hours": false,
    "id": "51.4886,9.0072"
  },
  {
    "Gas Station Number": "TD038232",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "EUTINER STR. 54",
    "Postcode": 23714,
    "City": "BAD MALENTE",
    "State": "Schleswig-Holstein",
    "Latitude": 54.1634,
    "Longitude": 10.5664,
    "Telephone Number": "04523-6050",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.1634,10.5664",
    "IsOpen24Hours": false,
    "id": "54.1634,10.5664"
  },
  {
    "Gas Station Number": "TD040147",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "BECHLINER CHAUSSEE 18A",
    "Postcode": 16816,
    "City": "NEURUPPIN",
    "State": "Brandenburg",
    "Latitude": 52.9061,
    "Longitude": 12.7513,
    "Telephone Number": "03391-3469813",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.9061,12.7513",
    "IsOpen24Hours": false,
    "id": "52.9061,12.7513"
  },
  {
    "Gas Station Number": "TD040873",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "OSTERHOLZER HEERSTR. 83",
    "Postcode": 28307,
    "City": "BREMEN",
    "State": "Bremen",
    "Latitude": 53.0588,
    "Longitude": 8.93,
    "Telephone Number": "0421-451779",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.0588,8.93",
    "IsOpen24Hours": false,
    "id": "53.0588,8.93"
  },
  {
    "Gas Station Number": "TD123790",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AN DER B 51",
    "Postcode": 54597,
    "City": "OLZHEIM",
    "State": "Rheinland-Pfalz",
    "Latitude": 50.2688,
    "Longitude": 6.4559,
    "Telephone Number": "06552-99110",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.2688,6.4559",
    "IsOpen24Hours": false,
    "id": "50.2688,6.4559"
  },
  {
    "Gas Station Number": "TD037572",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SOLMITZSTRASSE 23a",
    "Postcode": 23569,
    "City": "LUEBECK",
    "State": "Schleswig-Holstein",
    "Latitude": 53.9153,
    "Longitude": 10.8053,
    "Telephone Number": "0451-301413",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.9153,10.8053",
    "IsOpen24Hours": false,
    "id": "53.9153,10.8053"
  },
  {
    "Gas Station Number": "TD037440",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KONRAD-ADENAUER-DAMM 200",
    "Postcode": 53123,
    "City": "BONN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.7122,
    "Longitude": 7.0406,
    "Telephone Number": "0228-644806",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.7122,7.0406",
    "IsOpen24Hours": false,
    "id": "50.7122,7.0406"
  },
  {
    "Gas Station Number": "TD039826",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AM HEERBACH 1",
    "Postcode": 63857,
    "City": "WALDASCHAFF",
    "State": "Bayern",
    "Latitude": 49.9809,
    "Longitude": 9.2754,
    "Telephone Number": "06095-3373",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.9809,9.2754",
    "IsOpen24Hours": false,
    "id": "49.9809,9.2754"
  },
  {
    "Gas Station Number": "TD041988",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GABLINGER WEG 83",
    "Postcode": 86154,
    "City": "AUGSBURG",
    "State": "Bayern",
    "Latitude": 48.3989,
    "Longitude": 10.8687,
    "Telephone Number": "0821-45309480",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.3989,10.8687",
    "IsOpen24Hours": false,
    "id": "48.3989,10.8687"
  },
  {
    "Gas Station Number": "TD124170",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DORSTENER STR. 203",
    "Postcode": 44652,
    "City": "HERNE",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5274,
    "Longitude": 7.1875,
    "Telephone Number": "02325-910170",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5274,7.1875",
    "IsOpen24Hours": false,
    "id": "51.5274,7.1875"
  },
  {
    "Gas Station Number": "TD041905",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "THANEBERG 1",
    "Postcode": 21272,
    "City": "EGESTORF",
    "State": "Niedersachsen",
    "Latitude": 53.1617,
    "Longitude": 10.0621,
    "Telephone Number": "04175-2299990",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.1617,10.0621",
    "IsOpen24Hours": false,
    "id": "53.1617,10.0621"
  },
  {
    "Gas Station Number": "TD000724",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HOHERODSKOPFSTR. 2",
    "Postcode": 63667,
    "City": "NIDDA",
    "State": "Hessen",
    "Latitude": 50.4201,
    "Longitude": 9.0091,
    "Telephone Number": "06043-7031",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.4201,9.0091",
    "IsOpen24Hours": false,
    "id": "50.4201,9.0091"
  },
  {
    "Gas Station Number": "TD032706",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PRAGER STRASSE 179",
    "Postcode": 4299,
    "City": "LEIPZIG",
    "State": "Sachsen",
    "Latitude": 51.313,
    "Longitude": 12.4149,
    "Telephone Number": "0341-8629060",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.313,12.4149",
    "IsOpen24Hours": false,
    "id": "51.313,12.4149"
  },
  {
    "Gas Station Number": "TD033886",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "IM HEIDEGRUND SUED 1",
    "Postcode": 6724,
    "City": "OSTERFELD",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.0402,
    "Longitude": 11.9385,
    "Telephone Number": "034422-31018",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0402,11.9385",
    "IsOpen24Hours": false,
    "id": "51.0402,11.9385"
  },
  {
    "Gas Station Number": "TD036384",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "HABICHTSTR. 11-13",
    "Postcode": 13505,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.5859,
    "Longitude": 13.2319,
    "Telephone Number": "03043673233",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5859,13.2319",
    "IsOpen24Hours": false,
    "id": "52.5859,13.2319"
  },
  {
    "Gas Station Number": "TD123920",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GRUNDSCHOETTELER STR 58",
    "Postcode": 58300,
    "City": "WETTER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.3712,
    "Longitude": 7.3697,
    "Telephone Number": "02335-69161",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3712,7.3697",
    "IsOpen24Hours": false,
    "id": "51.3712,7.3697"
  },
  {
    "Gas Station Number": "TD133570",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "NIBELUNGENSTR. 129",
    "Postcode": 64653,
    "City": "LORSCH",
    "State": "Hessen",
    "Latitude": 49.6532,
    "Longitude": 8.5575,
    "Telephone Number": "06251-51222",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.6532,8.5575",
    "IsOpen24Hours": false,
    "id": "49.6532,8.5575"
  },
  {
    "Gas Station Number": "TD003681",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SUTELSTR. 23 D",
    "Postcode": 30659,
    "City": "HANNOVER",
    "State": "Niedersachsen",
    "Latitude": 52.4143,
    "Longitude": 9.7974,
    "Telephone Number": "0511-6497636",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4143,9.7974",
    "IsOpen24Hours": false,
    "id": "52.4143,9.7974"
  },
  {
    "Gas Station Number": "TD040832",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "ALMOSENBERG 4",
    "Postcode": 97877,
    "City": "WERTHEIM",
    "State": "Baden-Württemberg",
    "Latitude": 49.7716,
    "Longitude": 9.5875,
    "Telephone Number": "09342-3029802",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.7716,9.5875",
    "IsOpen24Hours": false,
    "id": "49.7716,9.5875"
  },
  {
    "Gas Station Number": "TD041798",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AM CHRISTOPHSBUEHL 1",
    "Postcode": 95509,
    "City": "MARKTSCHORGAST",
    "State": "Bayern",
    "Latitude": 50.0819,
    "Longitude": 11.6645,
    "Telephone Number": "092273329885",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.0819,11.6645",
    "IsOpen24Hours": false,
    "id": "50.0819,11.6645"
  },
  {
    "Gas Station Number": "TD035063",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DRESDNER STR. 49",
    "Postcode": 1156,
    "City": "DRESDEN",
    "State": "Sachsen",
    "Latitude": 51.0871,
    "Longitude": 13.6404,
    "Telephone Number": "0351-4540000",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0871,13.6404",
    "IsOpen24Hours": false,
    "id": "51.0871,13.6404"
  },
  {
    "Gas Station Number": "TD006718",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "33 BAHNHOFSTR.",
    "Postcode": 69190,
    "City": "WALLDORF",
    "State": "Baden-Württemberg",
    "Latitude": 49.3005,
    "Longitude": 8.645,
    "Telephone Number": "06227890051",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.3005,8.645",
    "IsOpen24Hours": false,
    "id": "49.3005,8.645"
  },
  {
    "Gas Station Number": "TD028472",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PIRSCHHEIDE 1",
    "Postcode": 14471,
    "City": "POTSDAM",
    "State": "Brandenburg",
    "Latitude": 52.3753,
    "Longitude": 13.0082,
    "Telephone Number": "0331-972922",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3753,13.0082",
    "IsOpen24Hours": false,
    "id": "52.3753,13.0082"
  },
  {
    "Gas Station Number": "TD028613",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SONNEWALDER STR.91 b",
    "Postcode": 3238,
    "City": "FINSTERWALDE",
    "State": "Brandenburg",
    "Latitude": 51.6429,
    "Longitude": 13.6963,
    "Telephone Number": "03531-704610",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6429,13.6963",
    "IsOpen24Hours": false,
    "id": "51.6429,13.6963"
  },
  {
    "Gas Station Number": "TD005892",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BAHNHOFSTR. 37",
    "Postcode": 57392,
    "City": "SCHMALLENBERG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.1583,
    "Longitude": 8.2915,
    "Telephone Number": "02972-6279",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1583,8.2915",
    "IsOpen24Hours": false,
    "id": "51.1583,8.2915"
  },
  {
    "Gas Station Number": "TD025452",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "REICHENBACHER STR. 115",
    "Postcode": 8056,
    "City": "ZWICKAU",
    "State": "Sachsen",
    "Latitude": 50.7084,
    "Longitude": 12.4648,
    "Telephone Number": "0375-216851",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.7084,12.4648",
    "IsOpen24Hours": false,
    "id": "50.7084,12.4648"
  },
  {
    "Gas Station Number": "TD024927",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LEIPZIGER STRASSE 2F",
    "Postcode": 4552,
    "City": "BORNA",
    "State": "Sachsen",
    "Latitude": 51.1323,
    "Longitude": 12.5057,
    "Telephone Number": "03433-208626",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1323,12.5057",
    "IsOpen24Hours": false,
    "id": "51.1323,12.5057"
  },
  {
    "Gas Station Number": "TD007377",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KOELLERTALSTR. 141",
    "Postcode": 66346,
    "City": "PUETTLINGEN",
    "State": "Saarland",
    "Latitude": 49.2939,
    "Longitude": 6.8921,
    "Telephone Number": "06806-440332",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.2939,6.8921",
    "IsOpen24Hours": false,
    "id": "49.2939,6.8921"
  },
  {
    "Gas Station Number": "TD121040",
    "Brand": "TotalEnergies",
    "Description": "Autobahntankstelle",
    "Address": "BAB SUEDSEITE / A 4",
    "Postcode": 50226,
    "City": "FRECHEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.9286,
    "Longitude": 6.7757,
    "Telephone Number": "02234-52164",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9286,6.7757",
    "IsOpen24Hours": false,
    "id": "50.9286,6.7757"
  },
  {
    "Gas Station Number": "TD002733",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SCHILLERSTR. 80",
    "Postcode": 63741,
    "City": "ASCHAFFENBURG",
    "State": "Bayern",
    "Latitude": 49.9858,
    "Longitude": 9.1352,
    "Telephone Number": "06021-423279",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.9858,9.1352",
    "IsOpen24Hours": false,
    "id": "49.9858,9.1352"
  },
  {
    "Gas Station Number": "TD039396",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "WALTER-ALTHOFFSTR. 2",
    "Postcode": 31167,
    "City": "BOCKENEM",
    "State": "Niedersachsen",
    "Latitude": 52.0015,
    "Longitude": 10.1447,
    "Telephone Number": "05067-8959975",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0015,10.1447",
    "IsOpen24Hours": false,
    "id": "52.0015,10.1447"
  },
  {
    "Gas Station Number": "TD005827",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "ALTENAER STR. 244",
    "Postcode": 58513,
    "City": "LUEDENSCHEID",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.2545,
    "Longitude": 7.6352,
    "Telephone Number": "02351-50021",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2545,7.6352",
    "IsOpen24Hours": false,
    "id": "51.2545,7.6352"
  },
  {
    "Gas Station Number": "TD120350",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GROSSENBAUMER ALLEE 75",
    "Postcode": 47269,
    "City": "DUISBURG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.3695,
    "Longitude": 6.7791,
    "Telephone Number": "0203-761567",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3695,6.7791",
    "IsOpen24Hours": false,
    "id": "51.3695,6.7791"
  },
  {
    "Gas Station Number": "TD040576",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WEINHEIMER STR. 34",
    "Postcode": 69509,
    "City": "MOERLENBACH",
    "State": "Hessen",
    "Latitude": 49.5966,
    "Longitude": 8.7317,
    "Telephone Number": "062093305",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.5966,8.7317",
    "IsOpen24Hours": false,
    "id": "49.5966,8.7317"
  },
  {
    "Gas Station Number": "TD037978",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "HALBERSTAEDTER STR.87",
    "Postcode": 6484,
    "City": "QUEDLINBURG",
    "State": "Sachsen-Anhalt",
    "Latitude": 51.8091,
    "Longitude": 11.1287,
    "Telephone Number": "03946-9019080",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8091,11.1287",
    "IsOpen24Hours": false,
    "id": "51.8091,11.1287"
  },
  {
    "Gas Station Number": "TD028118",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LUCKENWALDER STR. 19",
    "Postcode": 14913,
    "City": "JUETERBOG",
    "State": "Brandenburg",
    "Latitude": 52.0031,
    "Longitude": 13.0867,
    "Telephone Number": "03372-432352",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0031,13.0867",
    "IsOpen24Hours": false,
    "id": "52.0031,13.0867"
  },
  {
    "Gas Station Number": "TD041525",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KOELNER STR. 18",
    "Postcode": 54634,
    "City": "BITBURG",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.9788,
    "Longitude": 6.5259,
    "Telephone Number": "065612395",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.9788,6.5259",
    "IsOpen24Hours": false,
    "id": "49.9788,6.5259"
  },
  {
    "Gas Station Number": "TD000935",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SIEGBURGER STR.490-492",
    "Postcode": 51105,
    "City": "KOELN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.9132,
    "Longitude": 6.9976,
    "Telephone Number": "0221-834992",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9132,6.9976",
    "IsOpen24Hours": false,
    "id": "50.9132,6.9976"
  },
  {
    "Gas Station Number": "TD002105",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LEONRODSTR. 48",
    "Postcode": 80636,
    "City": "MUENCHEN",
    "State": "Bayern",
    "Latitude": 48.1575,
    "Longitude": 11.5439,
    "Telephone Number": "089-1292340",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.1575,11.5439",
    "IsOpen24Hours": false,
    "id": "48.1575,11.5439"
  },
  {
    "Gas Station Number": "TD004994",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "KEMNADER STR. 315",
    "Postcode": 44797,
    "City": "BOCHUM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4285,
    "Longitude": 7.2425,
    "Telephone Number": "0234-791008",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4285,7.2425",
    "IsOpen24Hours": false,
    "id": "51.4285,7.2425"
  },
  {
    "Gas Station Number": "TD035212",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "SANGERHAEUSER STR. 35",
    "Postcode": 6556,
    "City": "ARTERN",
    "State": "Thüringen",
    "Latitude": 51.3641,
    "Longitude": 11.287,
    "Telephone Number": "03466-740568",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3641,11.287",
    "IsOpen24Hours": false,
    "id": "51.3641,11.287"
  },
  {
    "Gas Station Number": "TD007252",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DRESDNER STR. 30 o",
    "Postcode": 4720,
    "City": "DOEBELN",
    "State": "Sachsen",
    "Latitude": 51.1208,
    "Longitude": 13.1507,
    "Telephone Number": "03431-574700",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1208,13.1507",
    "IsOpen24Hours": false,
    "id": "51.1208,13.1507"
  },
  {
    "Gas Station Number": "TD182070",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "BAHNHOFSTR. 8",
    "Postcode": 77781,
    "City": "BIBERACH",
    "State": "Baden-Württemberg",
    "Latitude": 48.3387,
    "Longitude": 8.0304,
    "Telephone Number": "07835-3070",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.3387,8.0304",
    "IsOpen24Hours": false,
    "id": "48.3387,8.0304"
  },
  {
    "Gas Station Number": "TD020255",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "AM FEUCHTEN WINKEL 11",
    "Postcode": 13127,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.5821,
    "Longitude": 13.4324,
    "Telephone Number": "030-4742621",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5821,13.4324",
    "IsOpen24Hours": false,
    "id": "52.5821,13.4324"
  },
  {
    "Gas Station Number": "TD029330",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "PRITZWALKER STR. 11",
    "Postcode": 16949,
    "City": "PUTLITZ",
    "State": "Brandenburg",
    "Latitude": 53.2377,
    "Longitude": 12.0556,
    "Telephone Number": "033981-80578",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.2377,12.0556",
    "IsOpen24Hours": false,
    "id": "53.2377,12.0556"
  },
  {
    "Gas Station Number": "TD020388",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "STORKOWER STR. 174",
    "Postcode": 10369,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.5274,
    "Longitude": 13.4578,
    "Telephone Number": "030-9720385",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5274,13.4578",
    "IsOpen24Hours": false,
    "id": "52.5274,13.4578"
  },
  {
    "Gas Station Number": "TD038018",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "DEKAN-LAIST-STR. 56",
    "Postcode": 55129,
    "City": "MAINZ",
    "State": "Rheinland-Pfalz",
    "Latitude": 49.9638,
    "Longitude": 8.2535,
    "Telephone Number": "06131-9728871",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.9638,8.2535",
    "IsOpen24Hours": false,
    "id": "49.9638,8.2535"
  },
  {
    "Gas Station Number": "TD036830",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "LANGENER LANDSTR. 245-247",
    "Postcode": 27578,
    "City": "BREMERHAVEN",
    "State": "Bremen",
    "Latitude": 53.5878,
    "Longitude": 8.5972,
    "Telephone Number": "0471-804075",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.5878,8.5972",
    "IsOpen24Hours": false,
    "id": "53.5878,8.5972"
  },
  {
    "Gas Station Number": "TD031740",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "CHAUSSEESTR. 45",
    "Postcode": 17438,
    "City": "WOLGAST",
    "State": "Mecklenburg-Vorpommern",
    "Latitude": 54.0467,
    "Longitude": 13.7521,
    "Telephone Number": "03836-232995",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "54.0467,13.7521",
    "IsOpen24Hours": false,
    "id": "54.0467,13.7521"
  },
  {
    "Gas Station Number": "TD000880",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "MUENSTERSTR. 212 A",
    "Postcode": 49479,
    "City": "IBBENBUEREN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.2581,
    "Longitude": 7.7115,
    "Telephone Number": "05451-936673",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2581,7.7115",
    "IsOpen24Hours": false,
    "id": "52.2581,7.7115"
  },
  {
    "Gas Station Number": "TD132990",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "WIMSHEIMER STR. 21",
    "Postcode": 75233,
    "City": "TIEFENBRONN",
    "State": "Baden-Württemberg",
    "Latitude": 48.8286,
    "Longitude": 8.8012,
    "Telephone Number": "07234-95150",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "48.8286,8.8012",
    "IsOpen24Hours": false,
    "id": "48.8286,8.8012"
  },
  {
    "Gas Station Number": "TD037747",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "DIESELSTR. 2",
    "Postcode": 41352,
    "City": "KORSCHENBROICH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.168,
    "Longitude": 6.5908,
    "Telephone Number": "02182-5272",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.168,6.5908",
    "IsOpen24Hours": false,
    "id": "51.168,6.5908"
  },
  {
    "Gas Station Number": "TD038133",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "TEMPELHOFER UFER 33-35",
    "Postcode": 10963,
    "City": "BERLIN",
    "State": "Berlin",
    "Latitude": 52.5005,
    "Longitude": 13.3767,
    "Telephone Number": "030-2621259",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5005,13.3767",
    "IsOpen24Hours": false,
    "id": "52.5005,13.3767"
  },
  {
    "Gas Station Number": "TD039172",
    "Brand": "TotalEnergies",
    "Description": "LKW Tankstelle",
    "Address": "MITTELBIEL 2",
    "Postcode": 35606,
    "City": "SOLMS",
    "State": "Hessen",
    "Latitude": 50.5544,
    "Longitude": 8.4124,
    "Telephone Number": "06441-9521852",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.5544,8.4124",
    "IsOpen24Hours": false,
    "id": "50.5544,8.4124"
  },
  {
    "Gas Station Number": "TD037663",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "AUGSBURGER STRASSE 2",
    "Postcode": 99091,
    "City": "ERFURT",
    "State": "Thüringen",
    "Latitude": 51.0022,
    "Longitude": 11.0026,
    "Telephone Number": "0361-26232699",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0022,11.0026",
    "IsOpen24Hours": false,
    "id": "51.0022,11.0026"
  },
  {
    "Gas Station Number": "TD033266",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "GRENZHAMMER 4",
    "Postcode": 98693,
    "City": "ILMENAU",
    "State": "Thüringen",
    "Latitude": 50.6787,
    "Longitude": 10.936,
    "Telephone Number": "03677-842787",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.6787,10.936",
    "IsOpen24Hours": false,
    "id": "50.6787,10.936"
  },
  {
    "Gas Station Number": "TD039719",
    "Brand": "TotalEnergies",
    "Description": "Strassentankstelle",
    "Address": "FRANKENSTR. 241",
    "Postcode": 45134,
    "City": "ESSEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.422,
    "Longitude": 7.0284,
    "Telephone Number": "0201-50776097",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.422,7.0284",
    "IsOpen24Hours": false,
    "id": "51.422,7.0284"
  },
  {
    "Gas Station Number": 610583,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Weseler Str. 269-271",
    "Postcode": 48151,
    "City": "MUENSTER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.94,
    "Longitude": 7.60893,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.94,7.60893",
    "IsOpen24Hours": false,
    "id": "51.94,7.60893"
  },
  {
    "Gas Station Number": 610640,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Badenstedter Str. 145",
    "Postcode": 30455,
    "City": "HANNOVER",
    "State": "Niedersachsen",
    "Latitude": 52.359,
    "Longitude": 9.67777,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.359,9.67777",
    "IsOpen24Hours": false,
    "id": "52.359,9.67777"
  },
  {
    "Gas Station Number": 610589,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Wilhelm-Hess-Str. 25",
    "Postcode": 30890,
    "City": "BARSINGHAUSEN",
    "State": "Niedersachsen",
    "Latitude": 52.3074,
    "Longitude": 9.45226,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3074,9.45226",
    "IsOpen24Hours": false,
    "id": "52.3074,9.45226"
  },
  {
    "Gas Station Number": 610431,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Alexanderstr. 420",
    "Postcode": 26127,
    "City": "OLDENBURG",
    "State": "Niedersachsen",
    "Latitude": 53.1752,
    "Longitude": 8.19138,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.1752,8.19138",
    "IsOpen24Hours": false,
    "id": "53.1752,8.19138"
  },
  {
    "Gas Station Number": 610512,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Coesfelder Str. 182",
    "Postcode": 48249,
    "City": "DUELMEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8396,
    "Longitude": 7.26597,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8396,7.26597",
    "IsOpen24Hours": false,
    "id": "51.8396,7.26597"
  },
  {
    "Gas Station Number": 610643,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Hauptstr. 63",
    "Postcode": 31559,
    "City": "HASTE",
    "State": "Niedersachsen",
    "Latitude": 52.3839,
    "Longitude": 9.39574,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3839,9.39574",
    "IsOpen24Hours": false,
    "id": "52.3839,9.39574"
  },
  {
    "Gas Station Number": 610401,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Darfelder Str. 14",
    "Postcode": 48727,
    "City": "BILLERBECK",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9825,
    "Longitude": 7.29368,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9825,7.29368",
    "IsOpen24Hours": false,
    "id": "51.9825,7.29368"
  },
  {
    "Gas Station Number": 610648,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Lingener Damm 26",
    "Postcode": 48429,
    "City": "RHEINE",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.2882,
    "Longitude": 7.44072,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2882,7.44072",
    "IsOpen24Hours": false,
    "id": "52.2882,7.44072"
  },
  {
    "Gas Station Number": 610374,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Roemerstr. 17",
    "Postcode": 52382,
    "City": "NIEDERZIER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.866,
    "Longitude": 6.43456,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.866,6.43456",
    "IsOpen24Hours": false,
    "id": "50.866,6.43456"
  },
  {
    "Gas Station Number": 610250,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Wandsbecker Zollstrasse 117-123",
    "Postcode": 22041,
    "City": "HAMBURG",
    "State": "Hamburg",
    "Latitude": 53.5771,
    "Longitude": 10.0838,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.5771,10.0838",
    "IsOpen24Hours": false,
    "id": "53.5771,10.0838"
  },
  {
    "Gas Station Number": 610352,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Resser Weg 20",
    "Postcode": 45699,
    "City": "HERTEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5947,
    "Longitude": 7.12981,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5947,7.12981",
    "IsOpen24Hours": false,
    "id": "51.5947,7.12981"
  },
  {
    "Gas Station Number": 900550,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Stadtholzstr. 250",
    "Postcode": 32052,
    "City": "BAT HERFORD-NORD",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.1034,
    "Longitude": 8.72086,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1034,8.72086",
    "IsOpen24Hours": false,
    "id": "52.1034,8.72086"
  },
  {
    "Gas Station Number": 610205,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Haller Str. 69",
    "Postcode": 33803,
    "City": "STEINHAGEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.0285,
    "Longitude": 8.40664,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0285,8.40664",
    "IsOpen24Hours": false,
    "id": "52.0285,8.40664"
  },
  {
    "Gas Station Number": 610370,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Siemensallee 77",
    "Postcode": 76187,
    "City": "KARLSRUHE",
    "State": "Baden-Württemberg",
    "Latitude": 49.0293,
    "Longitude": 8.347,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "49.0293,8.347",
    "IsOpen24Hours": false,
    "id": "49.0293,8.347"
  },
  {
    "Gas Station Number": 610373,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Olpener Str. 27",
    "Postcode": 51103,
    "City": "KOELN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.9403,
    "Longitude": 7.01887,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9403,7.01887",
    "IsOpen24Hours": false,
    "id": "50.9403,7.01887"
  },
  {
    "Gas Station Number": 610009,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Heisenbergstr. 2a",
    "Postcode": 50169,
    "City": "KERPEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.8699,
    "Longitude": 6.75738,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8699,6.75738",
    "IsOpen24Hours": false,
    "id": "50.8699,6.75738"
  },
  {
    "Gas Station Number": 610419,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Lipprahmsdorfer 6",
    "Postcode": 46282,
    "City": "DORSTEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.7604,
    "Longitude": 7.00015,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.7604,7.00015",
    "IsOpen24Hours": false,
    "id": "51.7604,7.00015"
  },
  {
    "Gas Station Number": 610318,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Tiergartenstr. 84",
    "Postcode": 26349,
    "City": "JADE",
    "State": "Niedersachsen",
    "Latitude": 53.3289,
    "Longitude": 8.18689,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.3289,8.18689",
    "IsOpen24Hours": false,
    "id": "53.3289,8.18689"
  },
  {
    "Gas Station Number": 610268,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Hauptstr. 372-376",
    "Postcode": 33818,
    "City": "LEOPOLDSHOEHE",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9756,
    "Longitude": 8.66539,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9756,8.66539",
    "IsOpen24Hours": false,
    "id": "51.9756,8.66539"
  },
  {
    "Gas Station Number": 610371,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Pfarrer-Kraus-Str. 154",
    "Postcode": 56077,
    "City": "KOBLENZ",
    "State": "Rheinland-Pfalz",
    "Latitude": 50.3697,
    "Longitude": 7.6598,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.3697,7.6598",
    "IsOpen24Hours": false,
    "id": "50.3697,7.6598"
  },
  {
    "Gas Station Number": 610432,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Donnerschweer 215",
    "Postcode": 26123,
    "City": "OLDENBURG",
    "State": "Niedersachsen",
    "Latitude": 53.1513,
    "Longitude": 8.23653,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.1513,8.23653",
    "IsOpen24Hours": false,
    "id": "53.1513,8.23653"
  },
  {
    "Gas Station Number": 610592,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Hansastr. 45a",
    "Postcode": 49090,
    "City": "OSNABRUECK",
    "State": "Niedersachsen",
    "Latitude": 52.2879,
    "Longitude": 8.0381,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2879,8.0381",
    "IsOpen24Hours": false,
    "id": "52.2879,8.0381"
  },
  {
    "Gas Station Number": 610365,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Luecker Str. 1",
    "Postcode": 41540,
    "City": "DORMAGEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.0949,
    "Longitude": 6.81309,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0949,6.81309",
    "IsOpen24Hours": false,
    "id": "51.0949,6.81309"
  },
  {
    "Gas Station Number": 610351,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Polsumer Str. 38 - 40",
    "Postcode": 45894,
    "City": "GELSENKIRCHEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5885,
    "Longitude": 7.05046,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5885,7.05046",
    "IsOpen24Hours": false,
    "id": "51.5885,7.05046"
  },
  {
    "Gas Station Number": 610144,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Hauptstrasse 19",
    "Postcode": 59227,
    "City": "AHLEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8005,
    "Longitude": 7.95315,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8005,7.95315",
    "IsOpen24Hours": false,
    "id": "51.8005,7.95315"
  },
  {
    "Gas Station Number": 610063,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Raiffeisenstr. 31 - 33",
    "Postcode": 48231,
    "City": "WARENDORF",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8741,
    "Longitude": 7.91428,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8741,7.91428",
    "IsOpen24Hours": false,
    "id": "51.8741,7.91428"
  },
  {
    "Gas Station Number": 610130,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Westtor 40",
    "Postcode": 48324,
    "City": "SENDENHORST",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8432,
    "Longitude": 7.81461,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8432,7.81461",
    "IsOpen24Hours": false,
    "id": "51.8432,7.81461"
  },
  {
    "Gas Station Number": 610654,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Alte Wittener Str. 40",
    "Postcode": 44803,
    "City": "BOCHUM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.468118,
    "Longitude": 7.271057,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.468118,7.271057",
    "IsOpen24Hours": false,
    "id": "51.468118,7.271057"
  },
  {
    "Gas Station Number": 610030,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Gahlenstr. 105",
    "Postcode": 46286,
    "City": "DORSTEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.6568,
    "Longitude": 6.949,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6568,6.949",
    "IsOpen24Hours": false,
    "id": "51.6568,6.949"
  },
  {
    "Gas Station Number": 610647,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Buchenweg 107",
    "Postcode": 46147,
    "City": "OBERHAUSEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5455,
    "Longitude": 6.82915,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5455,6.82915",
    "IsOpen24Hours": false,
    "id": "51.5455,6.82915"
  },
  {
    "Gas Station Number": 610646,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Gesmolder Str. 61",
    "Postcode": 49324,
    "City": "MELLE",
    "State": "Niedersachsen",
    "Latitude": 52.1996,
    "Longitude": 8.32536,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1996,8.32536",
    "IsOpen24Hours": false,
    "id": "52.1996,8.32536"
  },
  {
    "Gas Station Number": 610475,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Oldenburger Str. 87",
    "Postcode": 27753,
    "City": "DELMENHORST",
    "State": "Niedersachsen",
    "Latitude": 53.0492,
    "Longitude": 8.60549,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.0492,8.60549",
    "IsOpen24Hours": false,
    "id": "53.0492,8.60549"
  },
  {
    "Gas Station Number": 610615,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Schoetmarsche Str. 356",
    "Postcode": 32791,
    "City": "LAGE",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.0234,
    "Longitude": 8.76142,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0234,8.76142",
    "IsOpen24Hours": false,
    "id": "52.0234,8.76142"
  },
  {
    "Gas Station Number": 610128,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Olfener Str. 11",
    "Postcode": 59379,
    "City": "SELM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.7059,
    "Longitude": 7.45515,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.7059,7.45515",
    "IsOpen24Hours": false,
    "id": "51.7059,7.45515"
  },
  {
    "Gas Station Number": 610369,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "An der Leimkaul 1",
    "Postcode": 52428,
    "City": "JUELICH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.9268,
    "Longitude": 6.37195,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9268,6.37195",
    "IsOpen24Hours": false,
    "id": "50.9268,6.37195"
  },
  {
    "Gas Station Number": 610713,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Raesfelder Str. 24",
    "Postcode": 46325,
    "City": "BORKEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8385,
    "Longitude": 6.85822,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8385,6.85822",
    "IsOpen24Hours": false,
    "id": "51.8385,6.85822"
  },
  {
    "Gas Station Number": 610596,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Schullendamm 63",
    "Postcode": 49716,
    "City": "MEPPEN",
    "State": "Niedersachsen",
    "Latitude": 52.6916,
    "Longitude": 7.27288,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.6916,7.27288",
    "IsOpen24Hours": false,
    "id": "52.6916,7.27288"
  },
  {
    "Gas Station Number": 610522,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Grevener Str. 395",
    "Postcode": 48159,
    "City": "MUENSTER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.0015,
    "Longitude": 7.61421,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0015,7.61421",
    "IsOpen24Hours": false,
    "id": "52.0015,7.61421"
  },
  {
    "Gas Station Number": 610650,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Muensterstr. 14",
    "Postcode": 49219,
    "City": "GLANDORF",
    "State": "Niedersachsen",
    "Latitude": 52.0814,
    "Longitude": 7.9986,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0814,7.9986",
    "IsOpen24Hours": false,
    "id": "52.0814,7.9986"
  },
  {
    "Gas Station Number": 610536,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Sachsenstr. 149",
    "Postcode": 45665,
    "City": "RECKLINGHAUSEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.6043,
    "Longitude": 7.27329,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6043,7.27329",
    "IsOpen24Hours": false,
    "id": "51.6043,7.27329"
  },
  {
    "Gas Station Number": 610462,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Herner Strasse 249",
    "Postcode": 45659,
    "City": "RECKLINGHAUSEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5834,
    "Longitude": 7.2126,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5834,7.2126",
    "IsOpen24Hours": false,
    "id": "51.5834,7.2126"
  },
  {
    "Gas Station Number": 900689,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Astruper Str. 168",
    "Postcode": 26203,
    "City": "BAT HUNTETAL WEST WARDENBURG",
    "State": "Niedersachsen",
    "Latitude": 53.0525,
    "Longitude": 8.2061,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.0525,8.2061",
    "IsOpen24Hours": false,
    "id": "53.0525,8.2061"
  },
  {
    "Gas Station Number": 610379,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Wanheimer Str. 225",
    "Postcode": 47053,
    "City": "DUISBURG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.243516,
    "Longitude": 6.450886,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.243516,6.450886",
    "IsOpen24Hours": false,
    "id": "51.243516,6.450886"
  },
  {
    "Gas Station Number": 610645,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Hamelner Strasse 26",
    "Postcode": 32657,
    "City": "LEMGO",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.026,
    "Longitude": 8.91649,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.026,8.91649",
    "IsOpen24Hours": false,
    "id": "52.026,8.91649"
  },
  {
    "Gas Station Number": 610429,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Steinfurter Str. 166",
    "Postcode": 48159,
    "City": "MUENSTER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9767,
    "Longitude": 7.60132,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9767,7.60132",
    "IsOpen24Hours": false,
    "id": "51.9767,7.60132"
  },
  {
    "Gas Station Number": 610362,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Am alten Bahnhof",
    "Postcode": 52457,
    "City": "ALDENHOVEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.8909,
    "Longitude": 6.27512,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8909,6.27512",
    "IsOpen24Hours": false,
    "id": "50.8909,6.27512"
  },
  {
    "Gas Station Number": 610057,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Wilhelmstr. 184",
    "Postcode": 59067,
    "City": "HAMM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.6664,
    "Longitude": 7.78781,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6664,7.78781",
    "IsOpen24Hours": false,
    "id": "51.6664,7.78781"
  },
  {
    "Gas Station Number": 610587,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "In den Bremen 48",
    "Postcode": 52152,
    "City": "SIMMERATH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.607472,
    "Longitude": 6.312293,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.607472,6.312293",
    "IsOpen24Hours": false,
    "id": "50.607472,6.312293"
  },
  {
    "Gas Station Number": 610341,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Heeper Str. 413",
    "Postcode": 33719,
    "City": "BIELEFELD",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.0319,
    "Longitude": 8.59885,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0319,8.59885",
    "IsOpen24Hours": false,
    "id": "52.0319,8.59885"
  },
  {
    "Gas Station Number": 610187,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Detmolder 112",
    "Postcode": 33100,
    "City": "PADERBORN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.7347,
    "Longitude": 8.7669,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.7347,8.7669",
    "IsOpen24Hours": false,
    "id": "51.7347,8.7669"
  },
  {
    "Gas Station Number": 610480,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Nadorster 287",
    "Postcode": 26125,
    "City": "OLDENBURG",
    "State": "Niedersachsen",
    "Latitude": 53.1663,
    "Longitude": 8.22672,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.1663,8.22672",
    "IsOpen24Hours": false,
    "id": "53.1663,8.22672"
  },
  {
    "Gas Station Number": 610603,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Windelsbleicher 101",
    "Postcode": 33647,
    "City": "BIELEFELD",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9766,
    "Longitude": 8.51832,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9766,8.51832",
    "IsOpen24Hours": false,
    "id": "51.9766,8.51832"
  },
  {
    "Gas Station Number": 610649,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Hauptstr. 134",
    "Postcode": 58332,
    "City": "SCHWELM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.2879,
    "Longitude": 7.30058,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2879,7.30058",
    "IsOpen24Hours": false,
    "id": "51.2879,7.30058"
  },
  {
    "Gas Station Number": 610623,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Dolberger Str. 136",
    "Postcode": 59229,
    "City": "AHLEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.7503,
    "Longitude": 7.89772,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.7503,7.89772",
    "IsOpen24Hours": false,
    "id": "51.7503,7.89772"
  },
  {
    "Gas Station Number": 610321,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Nordloher Str. 31",
    "Postcode": 26689,
    "City": "APEN",
    "State": "Niedersachsen",
    "Latitude": 53.1757,
    "Longitude": 7.82345,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.1757,7.82345",
    "IsOpen24Hours": false,
    "id": "53.1757,7.82345"
  },
  {
    "Gas Station Number": 610622,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Bornstr. 31 - 33",
    "Postcode": 44145,
    "City": "DORTMUND",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.521,
    "Longitude": 7.47031,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.521,7.47031",
    "IsOpen24Hours": false,
    "id": "51.521,7.47031"
  },
  {
    "Gas Station Number": 610541,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Hertener Weg 30-38",
    "Postcode": 45701,
    "City": "HERTEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.6,
    "Longitude": 7.09332,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6,7.09332",
    "IsOpen24Hours": false,
    "id": "51.6,7.09332"
  },
  {
    "Gas Station Number": 610499,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Reichsstr. 3 - 5",
    "Postcode": 53125,
    "City": "BONN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.6832,
    "Longitude": 7.07574,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.6832,7.07574",
    "IsOpen24Hours": false,
    "id": "50.6832,7.07574"
  },
  {
    "Gas Station Number": 610015,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Von-Galen-Str. 2",
    "Postcode": 48727,
    "City": "BILLERBECK",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9704,
    "Longitude": 7.2807,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9704,7.2807",
    "IsOpen24Hours": false,
    "id": "51.9704,7.2807"
  },
  {
    "Gas Station Number": 610418,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Beckumer Str. 133",
    "Postcode": 59555,
    "City": "LIPPSTADT",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.6805,
    "Longitude": 8.31155,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6805,8.31155",
    "IsOpen24Hours": false,
    "id": "51.6805,8.31155"
  },
  {
    "Gas Station Number": 610170,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Metelener Stra? 40",
    "Postcode": 48493,
    "City": "WETTRINGEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.2067,
    "Longitude": 7.31031,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2067,7.31031",
    "IsOpen24Hours": false,
    "id": "52.2067,7.31031"
  },
  {
    "Gas Station Number": 610566,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Muensterstr. 52",
    "Postcode": 48308,
    "City": "SENDEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8617,
    "Longitude": 7.49228,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8617,7.49228",
    "IsOpen24Hours": false,
    "id": "51.8617,7.49228"
  },
  {
    "Gas Station Number": 610481,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Bremer Str. 46",
    "Postcode": 27239,
    "City": "TWISTRINGEN",
    "State": "Niedersachsen",
    "Latitude": 52.8045,
    "Longitude": 8.64704,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.8045,8.64704",
    "IsOpen24Hours": false,
    "id": "52.8045,8.64704"
  },
  {
    "Gas Station Number": 610042,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstr. 37/39",
    "Postcode": 48691,
    "City": "VREDEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.0301,
    "Longitude": 6.82317,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0301,6.82317",
    "IsOpen24Hours": false,
    "id": "52.0301,6.82317"
  },
  {
    "Gas Station Number": 610090,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Grosse Str. 22",
    "Postcode": 49434,
    "City": "NEUENKIRCHEN",
    "State": "Niedersachsen",
    "Latitude": 52.5096,
    "Longitude": 8.06854,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.5096,8.06854",
    "IsOpen24Hours": false,
    "id": "52.5096,8.06854"
  },
  {
    "Gas Station Number": 610455,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Im Babenbecker Feld 56",
    "Postcode": 32051,
    "City": "HERFORD",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.1295,
    "Longitude": 8.64347,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1295,8.64347",
    "IsOpen24Hours": false,
    "id": "52.1295,8.64347"
  },
  {
    "Gas Station Number": 610593,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Hildesheimer 407",
    "Postcode": 30519,
    "City": "HANNOVER",
    "State": "Niedersachsen",
    "Latitude": 52.3261,
    "Longitude": 9.78182,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3261,9.78182",
    "IsOpen24Hours": false,
    "id": "52.3261,9.78182"
  },
  {
    "Gas Station Number": 610629,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Hervester Str. 43 - 45",
    "Postcode": 46286,
    "City": "DORSTEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.7137,
    "Longitude": 7.01182,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.7137,7.01182",
    "IsOpen24Hours": false,
    "id": "51.7137,7.01182"
  },
  {
    "Gas Station Number": 610703,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Herforder Str. 54",
    "Postcode": 32257,
    "City": "BUENDE",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.1922,
    "Longitude": 8.59386,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1922,8.59386",
    "IsOpen24Hours": false,
    "id": "52.1922,8.59386"
  },
  {
    "Gas Station Number": 610653,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Werner Hellweg 526",
    "Postcode": 44894,
    "City": "BOCHUM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4924,
    "Longitude": 7.31072,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4924,7.31072",
    "IsOpen24Hours": false,
    "id": "51.4924,7.31072"
  },
  {
    "Gas Station Number": 610348,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Bottroper 331",
    "Postcode": 45356,
    "City": "ESSEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4849,
    "Longitude": 6.97206,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4849,6.97206",
    "IsOpen24Hours": false,
    "id": "51.4849,6.97206"
  },
  {
    "Gas Station Number": 610617,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Elberfelder 148",
    "Postcode": 42477,
    "City": "RADEVORMWALD",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.2022,
    "Longitude": 7.32606,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2022,7.32606",
    "IsOpen24Hours": false,
    "id": "51.2022,7.32606"
  },
  {
    "Gas Station Number": 610651,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Lennestr. 17",
    "Postcode": 58791,
    "City": "WERDOHL",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.2571,
    "Longitude": 7.75746,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2571,7.75746",
    "IsOpen24Hours": false,
    "id": "51.2571,7.75746"
  },
  {
    "Gas Station Number": 610368,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Brueckenstr. 85",
    "Postcode": 51643,
    "City": "GUMMERSBACH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.0236,
    "Longitude": 7.55463,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0236,7.55463",
    "IsOpen24Hours": false,
    "id": "51.0236,7.55463"
  },
  {
    "Gas Station Number": 610360,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Horster Str. 21",
    "Postcode": 59368,
    "City": "WERNE",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.6681,
    "Longitude": 7.64041,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6681,7.64041",
    "IsOpen24Hours": false,
    "id": "51.6681,7.64041"
  },
  {
    "Gas Station Number": 610003,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Wüllener Str. 64",
    "Postcode": 48683,
    "City": "AHAUS",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.0738,
    "Longitude": 6.99724,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0738,6.99724",
    "IsOpen24Hours": false,
    "id": "52.0738,6.99724"
  },
  {
    "Gas Station Number": 610426,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Schiffahrter Damm 29",
    "Postcode": 48145,
    "City": "MUENSTER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9691,
    "Longitude": 7.65604,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9691,7.65604",
    "IsOpen24Hours": false,
    "id": "51.9691,7.65604"
  },
  {
    "Gas Station Number": 610228,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Kirchsiek 11",
    "Postcode": 32457,
    "City": "PORTA WESTFALICA",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.2394,
    "Longitude": 8.92517,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2394,8.92517",
    "IsOpen24Hours": false,
    "id": "52.2394,8.92517"
  },
  {
    "Gas Station Number": 610420,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Altenberger 128",
    "Postcode": 48565,
    "City": "STEINFURT",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.111,
    "Longitude": 7.40107,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.111,7.40107",
    "IsOpen24Hours": false,
    "id": "52.111,7.40107"
  },
  {
    "Gas Station Number": 610425,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Holtwicker Str. 31",
    "Postcode": 48653,
    "City": "COESFELD",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9528,
    "Longitude": 7.16396,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9528,7.16396",
    "IsOpen24Hours": false,
    "id": "51.9528,7.16396"
  },
  {
    "Gas Station Number": 610412,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Schneidemuehler Str. 9",
    "Postcode": 48157,
    "City": "MUENSTER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9946,
    "Longitude": 7.64946,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9946,7.64946",
    "IsOpen24Hours": false,
    "id": "51.9946,7.64946"
  },
  {
    "Gas Station Number": 610106,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Appelhuelsener Str. 16",
    "Postcode": 48301,
    "City": "NOTTULN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9284,
    "Longitude": 7.36153,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9284,7.36153",
    "IsOpen24Hours": false,
    "id": "51.9284,7.36153"
  },
  {
    "Gas Station Number": 610016,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Osnabruecker Str. 4",
    "Postcode": 32289,
    "City": "ROEDINGHAUSEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.1987,
    "Longitude": 8.46148,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1987,8.46148",
    "IsOpen24Hours": false,
    "id": "52.1987,8.46148"
  },
  {
    "Gas Station Number": 610518,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Grevener Str. 5",
    "Postcode": 49549,
    "City": "LADBERGEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.136,
    "Longitude": 7.73631,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.136,7.73631",
    "IsOpen24Hours": false,
    "id": "52.136,7.73631"
  },
  {
    "Gas Station Number": 610132,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Dufkamp 87",
    "Postcode": 48703,
    "City": "STADTLOHN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9925,
    "Longitude": 6.9273,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9925,6.9273",
    "IsOpen24Hours": false,
    "id": "51.9925,6.9273"
  },
  {
    "Gas Station Number": 610002,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstr. 30",
    "Postcode": 48317,
    "City": "DRENSTEINFURT",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8,
    "Longitude": 7.73369,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8,7.73369",
    "IsOpen24Hours": false,
    "id": "51.8,7.73369"
  },
  {
    "Gas Station Number": 900688,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Am Rohrbusch 52",
    "Postcode": 48161,
    "City": "BAT MUENSTERLAND OST MÜNSTER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9402,
    "Longitude": 7.55176,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9402,7.55176",
    "IsOpen24Hours": false,
    "id": "51.9402,7.55176"
  },
  {
    "Gas Station Number": 610381,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Kasseler Str. 38",
    "Postcode": 34560,
    "City": "FRITZLAR",
    "State": "Hessen",
    "Latitude": 51.1357,
    "Longitude": 9.2725,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1357,9.2725",
    "IsOpen24Hours": false,
    "id": "51.1357,9.2725"
  },
  {
    "Gas Station Number": 610476,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Ringer Str. 10",
    "Postcode": 49824,
    "City": "EMLICHHEIM",
    "State": "Niedersachsen",
    "Latitude": 52.6069,
    "Longitude": 6.85482,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.6069,6.85482",
    "IsOpen24Hours": false,
    "id": "52.6069,6.85482"
  },
  {
    "Gas Station Number": 610633,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Zum Bauverein 44",
    "Postcode": 45899,
    "City": "GELSENKIRCHEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5269,
    "Longitude": 7.01836,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5269,7.01836",
    "IsOpen24Hours": false,
    "id": "51.5269,7.01836"
  },
  {
    "Gas Station Number": 610621,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Westfälische Str. 21",
    "Postcode": 44869,
    "City": "BOCHUM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4621,
    "Longitude": 7.1545,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4621,7.1545",
    "IsOpen24Hours": false,
    "id": "51.4621,7.1545"
  },
  {
    "Gas Station Number": 610275,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Zur Obersburg 2",
    "Postcode": 31749,
    "City": "AUETAL",
    "State": "Niedersachsen",
    "Latitude": 52.231,
    "Longitude": 9.23689,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.231,9.23689",
    "IsOpen24Hours": false,
    "id": "52.231,9.23689"
  },
  {
    "Gas Station Number": 610590,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Gehrdener Str. 5",
    "Postcode": 30989,
    "City": "GEHRDEN",
    "State": "Niedersachsen",
    "Latitude": 52.3229,
    "Longitude": 9.55289,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3229,9.55289",
    "IsOpen24Hours": false,
    "id": "52.3229,9.55289"
  },
  {
    "Gas Station Number": 610472,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Farger Str. 16",
    "Postcode": 28777,
    "City": "BREMEN",
    "State": "Bremen",
    "Latitude": "",
    "Longitude": 8.53232,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "0,8.53232",
    "IsOpen24Hours": false,
    "id": "0,8.53232"
  },
  {
    "Gas Station Number": 610619,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Duelmener Str. 26",
    "Postcode": 48653,
    "City": "COESFELD",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9374,
    "Longitude": 7.16822,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9374,7.16822",
    "IsOpen24Hours": false,
    "id": "51.9374,7.16822"
  },
  {
    "Gas Station Number": 610479,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Cloppenburger 224",
    "Postcode": 26133,
    "City": "OLDENBURG",
    "State": "Niedersachsen",
    "Latitude": 53.1176,
    "Longitude": 8.21453,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.1176,8.21453",
    "IsOpen24Hours": false,
    "id": "53.1176,8.21453"
  },
  {
    "Gas Station Number": 610356,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Huelsdonker Str. 117",
    "Postcode": 47441,
    "City": "MOERS",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.451,
    "Longitude": 6.60871,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.451,6.60871",
    "IsOpen24Hours": false,
    "id": "51.451,6.60871"
  },
  {
    "Gas Station Number": 610601,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Eckendorfer Str. 32",
    "Postcode": 33609,
    "City": "BIELEFELD",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.0333,
    "Longitude": 8.55744,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0333,8.55744",
    "IsOpen24Hours": false,
    "id": "52.0333,8.55744"
  },
  {
    "Gas Station Number": 610639,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Werler Str. 238-240",
    "Postcode": 59063,
    "City": "HAMM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.6628,
    "Longitude": 7.83413,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6628,7.83413",
    "IsOpen24Hours": false,
    "id": "51.6628,7.83413"
  },
  {
    "Gas Station Number": 610079,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Coesfelder Str. 91",
    "Postcode": 48653,
    "City": "COESFELD",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.896,
    "Longitude": 7.19337,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.896,7.19337",
    "IsOpen24Hours": false,
    "id": "51.896,7.19337"
  },
  {
    "Gas Station Number": 610552,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Kopenhagener Straße 19",
    "Postcode": 48163,
    "City": "Münster",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.56203,
    "Longitude": 7.355878,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.56203,7.355878",
    "IsOpen24Hours": false,
    "id": "51.56203,7.355878"
  },
  {
    "Gas Station Number": 610072,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Puesselbuerener Damm 16",
    "Postcode": 49477,
    "City": "IBBENBUEREN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.2817,
    "Longitude": 7.70657,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2817,7.70657",
    "IsOpen24Hours": false,
    "id": "52.2817,7.70657"
  },
  {
    "Gas Station Number": 610364,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Kölner Str. 124",
    "Postcode": 51702,
    "City": "BERGNEUSTADT",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.0171,
    "Longitude": 7.63931,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0171,7.63931",
    "IsOpen24Hours": false,
    "id": "51.0171,7.63931"
  },
  {
    "Gas Station Number": 610189,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Detmolder Straße 230",
    "Postcode": 33175,
    "City": "Bad Lippspringe",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.789614,
    "Longitude": 8.826766,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.789614,8.826766",
    "IsOpen24Hours": false,
    "id": "51.789614,8.826766"
  },
  {
    "Gas Station Number": 900687,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Roemerstr. 28",
    "Postcode": 44289,
    "City": "BAT LICHTENDORF NORD DORTMUND",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4688,
    "Longitude": 7.59318,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4688,7.59318",
    "IsOpen24Hours": false,
    "id": "51.4688,7.59318"
  },
  {
    "Gas Station Number": 610641,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Melanchthonstr. 40",
    "Postcode": 30165,
    "City": "HANNOVER",
    "State": "Niedersachsen",
    "Latitude": 52.3982,
    "Longitude": 9.73002,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3982,9.73002",
    "IsOpen24Hours": false,
    "id": "52.3982,9.73002"
  },
  {
    "Gas Station Number": 610342,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Elsa-Brandstroem-Str. 137",
    "Postcode": 44869,
    "City": "BOCHUM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4563,
    "Longitude": 7.17608,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4563,7.17608",
    "IsOpen24Hours": false,
    "id": "51.4563,7.17608"
  },
  {
    "Gas Station Number": 610376,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Am Dreieck 16",
    "Postcode": 51580,
    "City": "REICHSHOF",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.9281,
    "Longitude": 7.64401,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9281,7.64401",
    "IsOpen24Hours": false,
    "id": "50.9281,7.64401"
  },
  {
    "Gas Station Number": 610626,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Ammerländer Str. 8",
    "Postcode": 26676,
    "City": "BARSSEL",
    "State": "Niedersachsen",
    "Latitude": 53.1692,
    "Longitude": 7.74629,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.1692,7.74629",
    "IsOpen24Hours": false,
    "id": "53.1692,7.74629"
  },
  {
    "Gas Station Number": 610249,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Edertalstrasse 6",
    "Postcode": 34549,
    "City": "EDERTAL",
    "State": "Hessen",
    "Latitude": 51.1435,
    "Longitude": 9.17621,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1435,9.17621",
    "IsOpen24Hours": false,
    "id": "51.1435,9.17621"
  },
  {
    "Gas Station Number": 610465,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Soester Str. 43",
    "Postcode": 59457,
    "City": "WERL",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5496,
    "Longitude": 7.92261,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5496,7.92261",
    "IsOpen24Hours": false,
    "id": "51.5496,7.92261"
  },
  {
    "Gas Station Number": 610618,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Kamper Str. 11",
    "Postcode": 42699,
    "City": "SOLINGEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.1586,
    "Longitude": 7.00536,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1586,7.00536",
    "IsOpen24Hours": false,
    "id": "51.1586,7.00536"
  },
  {
    "Gas Station Number": 610037,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Karl-Benz-Str. 2",
    "Postcode": 48565,
    "City": "STEINFURT",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.1597,
    "Longitude": 7.31872,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1597,7.31872",
    "IsOpen24Hours": false,
    "id": "52.1597,7.31872"
  },
  {
    "Gas Station Number": 900695,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Danebrocker Str. 10",
    "Postcode": 49492,
    "City": "WESTERKAPPELN S",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.260647,
    "Longitude": 7.849282,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.260647,7.849282",
    "IsOpen24Hours": false,
    "id": "52.260647,7.849282"
  },
  {
    "Gas Station Number": 610538,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Lingener Str. 106",
    "Postcode": 48531,
    "City": "NORDHORN",
    "State": "Niedersachsen",
    "Latitude": 52.4406,
    "Longitude": 7.08632,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4406,7.08632",
    "IsOpen24Hours": false,
    "id": "52.4406,7.08632"
  },
  {
    "Gas Station Number": 610358,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Dortmunder Str. 239",
    "Postcode": 45665,
    "City": "RECKLINGHAUSEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.6225,
    "Longitude": 7.2291,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6225,7.2291",
    "IsOpen24Hours": false,
    "id": "51.6225,7.2291"
  },
  {
    "Gas Station Number": 610020,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Stieghorster Str. 108",
    "Postcode": 33605,
    "City": "BIELEFELD",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.0073,
    "Longitude": 8.58954,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0073,8.58954",
    "IsOpen24Hours": false,
    "id": "52.0073,8.58954"
  },
  {
    "Gas Station Number": 610505,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Warendorfer Str. 12",
    "Postcode": 48361,
    "City": "BEELEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9297,
    "Longitude": 8.12068,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9297,8.12068",
    "IsOpen24Hours": false,
    "id": "51.9297,8.12068"
  },
  {
    "Gas Station Number": 610631,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Westkirchener 7-9",
    "Postcode": 59320,
    "City": "ENNIGERLOH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8396,
    "Longitude": 8.02576,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8396,8.02576",
    "IsOpen24Hours": false,
    "id": "51.8396,8.02576"
  },
  {
    "Gas Station Number": 610413,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Paderborner Str. 319",
    "Postcode": 33689,
    "City": "BIELEFELD",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9324,
    "Longitude": 8.6069,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9324,8.6069",
    "IsOpen24Hours": false,
    "id": "51.9324,8.6069"
  },
  {
    "Gas Station Number": 610335,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Hauptstr. 58",
    "Postcode": 31712,
    "City": "WILHELMSHAVEN",
    "State": "Niedersachsen",
    "Latitude": 53.5926,
    "Longitude": 8.04519,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.5926,8.04519",
    "IsOpen24Hours": false,
    "id": "53.5926,8.04519"
  },
  {
    "Gas Station Number": 610616,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Bielefelder Str. 107",
    "Postcode": 33378,
    "City": "RHEDA-WIEDENBRUECK",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8459,
    "Longitude": 8.32075,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8459,8.32075",
    "IsOpen24Hours": false,
    "id": "51.8459,8.32075"
  },
  {
    "Gas Station Number": 610350,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Cranger Str. 215",
    "Postcode": 45891,
    "City": "GELSENKIRCHEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5636,
    "Longitude": 7.08538,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5636,7.08538",
    "IsOpen24Hours": false,
    "id": "51.5636,7.08538"
  },
  {
    "Gas Station Number": 610367,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Roitzheimer Str. 117",
    "Postcode": 53879,
    "City": "EUSKIRCHEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.6522,
    "Longitude": 6.80102,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.6522,6.80102",
    "IsOpen24Hours": false,
    "id": "50.6522,6.80102"
  },
  {
    "Gas Station Number": 610357,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Bruchstr. 18 - 21",
    "Postcode": 45468,
    "City": "MUELHEIM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4357,
    "Longitude": 6.89093,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4357,6.89093",
    "IsOpen24Hours": false,
    "id": "51.4357,6.89093"
  },
  {
    "Gas Station Number": 610355,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Asberger Str. 188",
    "Postcode": 47441,
    "City": "MOERS",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4387,
    "Longitude": 6.6576,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4387,6.6576",
    "IsOpen24Hours": false,
    "id": "51.4387,6.6576"
  },
  {
    "Gas Station Number": 610451,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Schulstrasse 32",
    "Postcode": 59192,
    "City": "BERGKAMEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.606,
    "Longitude": 7.61878,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.606,7.61878",
    "IsOpen24Hours": false,
    "id": "51.606,7.61878"
  },
  {
    "Gas Station Number": 610256,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Hauptstrasse 1 a",
    "Postcode": 34590,
    "City": "WABERN",
    "State": "Hessen",
    "Latitude": 51.0824,
    "Longitude": 9.37424,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0824,9.37424",
    "IsOpen24Hours": false,
    "id": "51.0824,9.37424"
  },
  {
    "Gas Station Number": 610366,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Koelner Landstr. 417",
    "Postcode": 52351,
    "City": "DUEREN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.8137,
    "Longitude": 6.51702,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8137,6.51702",
    "IsOpen24Hours": false,
    "id": "50.8137,6.51702"
  },
  {
    "Gas Station Number": 610542,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Dollersweg 103",
    "Postcode": 44319,
    "City": "DORTMUND",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5376,
    "Longitude": 7.62727,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5376,7.62727",
    "IsOpen24Hours": false,
    "id": "51.5376,7.62727"
  },
  {
    "Gas Station Number": 610055,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Bachackerweg 1",
    "Postcode": 45772,
    "City": "MARL",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.6922,
    "Longitude": 7.11865,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6922,7.11865",
    "IsOpen24Hours": false,
    "id": "51.6922,7.11865"
  },
  {
    "Gas Station Number": 610380,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Attenser Allee 141",
    "Postcode": 26954,
    "City": "NORDENHAM",
    "State": "Niedersachsen",
    "Latitude": 53.5026,
    "Longitude": 8.47792,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.5026,8.47792",
    "IsOpen24Hours": false,
    "id": "53.5026,8.47792"
  },
  {
    "Gas Station Number": 610403,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Halterner Str. 61",
    "Postcode": 48249,
    "City": "DUELMEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8255,
    "Longitude": 7.27279,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8255,7.27279",
    "IsOpen24Hours": false,
    "id": "51.8255,7.27279"
  },
  {
    "Gas Station Number": 610131,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Bernard-Krone-Str. 1",
    "Postcode": 48480,
    "City": "SPELLE",
    "State": "Niedersachsen",
    "Latitude": 52.366,
    "Longitude": 7.45558,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.366,7.45558",
    "IsOpen24Hours": false,
    "id": "52.366,7.45558"
  },
  {
    "Gas Station Number": 610591,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Reher Weg 36 a",
    "Postcode": 31855,
    "City": "AERZEN",
    "State": "Niedersachsen",
    "Latitude": 52.0429,
    "Longitude": 9.2582,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0429,9.2582",
    "IsOpen24Hours": false,
    "id": "52.0429,9.2582"
  },
  {
    "Gas Station Number": 610088,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Rekener Str. 100",
    "Postcode": 48249,
    "City": "DUELMEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8484,
    "Longitude": 7.19356,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8484,7.19356",
    "IsOpen24Hours": false,
    "id": "51.8484,7.19356"
  },
  {
    "Gas Station Number": 610134,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Industriestrasse 1",
    "Postcode": 46354,
    "City": "SUEDLOHN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9354,
    "Longitude": 6.82016,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9354,6.82016",
    "IsOpen24Hours": false,
    "id": "51.9354,6.82016"
  },
  {
    "Gas Station Number": 610103,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Laurenzstr. 108",
    "Postcode": 48607,
    "City": "OCHTRUP",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.2067,
    "Longitude": 7.2077,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2067,7.2077",
    "IsOpen24Hours": false,
    "id": "52.2067,7.2077"
  },
  {
    "Gas Station Number": 610363,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Heerstr. 166",
    "Postcode": 53474,
    "City": "BAD NEUENAHR",
    "State": "Rheinland-Pfalz",
    "Latitude": 50.5493,
    "Longitude": 7.1308,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.5493,7.1308",
    "IsOpen24Hours": false,
    "id": "50.5493,7.1308"
  },
  {
    "Gas Station Number": 610404,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Westfalenstr. 124-126",
    "Postcode": 48165,
    "City": "MUENSTER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9071,
    "Longitude": 7.63365,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9071,7.63365",
    "IsOpen24Hours": false,
    "id": "51.9071,7.63365"
  },
  {
    "Gas Station Number": 900686,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Stadtholzstr. 252",
    "Postcode": 32052,
    "City": "BAT HERFORD-S?",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.1015,
    "Longitude": 8.72013,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1015,8.72013",
    "IsOpen24Hours": false,
    "id": "52.1015,8.72013"
  },
  {
    "Gas Station Number": 610343,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Herner Str. 269",
    "Postcode": 44809,
    "City": "BOCHUM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5025,
    "Longitude": 7.21228,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5025,7.21228",
    "IsOpen24Hours": false,
    "id": "51.5025,7.21228"
  },
  {
    "Gas Station Number": 610549,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Holsterhauser",
    "Postcode": 44625,
    "City": "HERNE",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.531645,
    "Longitude": 7.205456,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.531645,7.205456",
    "IsOpen24Hours": false,
    "id": "51.531645,7.205456"
  },
  {
    "Gas Station Number": 610180,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Osnabruecker Str. 18",
    "Postcode": 49492,
    "City": "WESTERKAPPELN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.3129,
    "Longitude": 7.88154,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3129,7.88154",
    "IsOpen24Hours": false,
    "id": "52.3129,7.88154"
  },
  {
    "Gas Station Number": 610624,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Werler Str. 8",
    "Postcode": 59755,
    "City": "ARNSBERG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4549,
    "Longitude": 7.95911,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4549,7.95911",
    "IsOpen24Hours": false,
    "id": "51.4549,7.95911"
  },
  {
    "Gas Station Number": 610171,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Davertstr. 53",
    "Postcode": 48163,
    "City": "MUENSTER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8829,
    "Longitude": 7.60634,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8829,7.60634",
    "IsOpen24Hours": false,
    "id": "51.8829,7.60634"
  },
  {
    "Gas Station Number": 610606,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Pyrmonter Str. 35",
    "Postcode": 31789,
    "City": "HAMELN",
    "State": "Niedersachsen",
    "Latitude": 52.0996,
    "Longitude": 9.35025,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0996,9.35025",
    "IsOpen24Hours": false,
    "id": "52.0996,9.35025"
  },
  {
    "Gas Station Number": 610554,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Roxeler Str. 556",
    "Postcode": 48161,
    "City": "MUENSTER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.955,
    "Longitude": 7.5374,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.955,7.5374",
    "IsOpen24Hours": false,
    "id": "51.955,7.5374"
  },
  {
    "Gas Station Number": 610185,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Frettholz 5",
    "Postcode": 32683,
    "City": "BARNTRUP",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.988651,
    "Longitude": 9.135773,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.988651,9.135773",
    "IsOpen24Hours": false,
    "id": "51.988651,9.135773"
  },
  {
    "Gas Station Number": 610610,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Stiftsallee 36",
    "Postcode": 32425,
    "City": "MINDEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.3039,
    "Longitude": 8.90441,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3039,8.90441",
    "IsOpen24Hours": false,
    "id": "52.3039,8.90441"
  },
  {
    "Gas Station Number": 610251,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Hauptstrasse 58",
    "Postcode": 31712,
    "City": "NIEDERNWOEHREN",
    "State": "Niedersachsen",
    "Latitude": 52.3555,
    "Longitude": 9.14227,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3555,9.14227",
    "IsOpen24Hours": false,
    "id": "52.3555,9.14227"
  },
  {
    "Gas Station Number": 610093,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Bieste 2",
    "Postcode": 49434,
    "City": "NEUENKIRCHEN",
    "State": "Niedersachsen",
    "Latitude": 52.521,
    "Longitude": 8.03923,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.521,8.03923",
    "IsOpen24Hours": false,
    "id": "52.521,8.03923"
  },
  {
    "Gas Station Number": 610353,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Zur alten Kolonie 1",
    "Postcode": 59439,
    "City": "HOLZWICKEDE",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5148,
    "Longitude": 7.62053,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5148,7.62053",
    "IsOpen24Hours": false,
    "id": "51.5148,7.62053"
  },
  {
    "Gas Station Number": 610316,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Goekerstr. 125 a",
    "Postcode": 26384,
    "City": "WILHELMSHAVEN",
    "State": "Niedersachsen",
    "Latitude": 53.5385,
    "Longitude": 8.12352,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.5385,8.12352",
    "IsOpen24Hours": false,
    "id": "53.5385,8.12352"
  },
  {
    "Gas Station Number": 610021,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Jöllenbecker 526a",
    "Postcode": 33739,
    "City": "BIELEFELD",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.0909,
    "Longitude": 8.51502,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0909,8.51502",
    "IsOpen24Hours": false,
    "id": "52.0909,8.51502"
  },
  {
    "Gas Station Number": 610311,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Lange Str. 100",
    "Postcode": 49632,
    "City": "ESSEN/OLDB.",
    "State": "Niedersachsen",
    "Latitude": 52.7159,
    "Longitude": 7.93839,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.7159,7.93839",
    "IsOpen24Hours": false,
    "id": "52.7159,7.93839"
  },
  {
    "Gas Station Number": 610029,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Bocholter Str. 30a",
    "Postcode": 46499,
    "City": "HAMMINKELN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.7771,
    "Longitude": 6.61225,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.7771,6.61225",
    "IsOpen24Hours": false,
    "id": "51.7771,6.61225"
  },
  {
    "Gas Station Number": 610544,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Ahlener Str. 118",
    "Postcode": 59073,
    "City": "HAMM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.7075,
    "Longitude": 7.83246,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.7075,7.83246",
    "IsOpen24Hours": false,
    "id": "51.7075,7.83246"
  },
  {
    "Gas Station Number": 610405,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Albersloher Weg 580",
    "Postcode": 48167,
    "City": "MUENSTER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9224,
    "Longitude": 7.674,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9224,7.674",
    "IsOpen24Hours": false,
    "id": "51.9224,7.674"
  },
  {
    "Gas Station Number": 610613,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Augustdorfer Str. 67",
    "Postcode": 32758,
    "City": "DETMOLD",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9377,
    "Longitude": 8.77863,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9377,8.77863",
    "IsOpen24Hours": false,
    "id": "51.9377,8.77863"
  },
  {
    "Gas Station Number": 610038,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Nienborger Str. 28",
    "Postcode": 48599,
    "City": "GRONAU",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.1757,
    "Longitude": 7.04898,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1757,7.04898",
    "IsOpen24Hours": false,
    "id": "52.1757,7.04898"
  },
  {
    "Gas Station Number": 610502,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "In der Geist 110",
    "Postcode": 59302,
    "City": "OELDE",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8119,
    "Longitude": 8.13451,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8119,8.13451",
    "IsOpen24Hours": false,
    "id": "51.8119,8.13451"
  },
  {
    "Gas Station Number": 610223,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Zum Eistruper Feld 2",
    "Postcode": 49143,
    "City": "BISSENDORF",
    "State": "Niedersachsen",
    "Latitude": 52.2398,
    "Longitude": 8.16133,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2398,8.16133",
    "IsOpen24Hours": false,
    "id": "52.2398,8.16133"
  },
  {
    "Gas Station Number": 610361,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Strangenhäuschen 10",
    "Postcode": 52070,
    "City": "AACHEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.8018,
    "Longitude": 6.11078,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8018,6.11078",
    "IsOpen24Hours": false,
    "id": "50.8018,6.11078"
  },
  {
    "Gas Station Number": 610344,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Kurt-Schumacher Str. 236",
    "Postcode": 46539,
    "City": "DINSLAKEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5575,
    "Longitude": 6.79647,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5575,6.79647",
    "IsOpen24Hours": false,
    "id": "51.5575,6.79647"
  },
  {
    "Gas Station Number": 610109,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Hannoversche Str. 20",
    "Postcode": 49084,
    "City": "OSNABRUECK",
    "State": "Niedersachsen",
    "Latitude": 52.264,
    "Longitude": 8.06611,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.264,8.06611",
    "IsOpen24Hours": false,
    "id": "52.264,8.06611"
  },
  {
    "Gas Station Number": 610498,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Toepferstrasse 2",
    "Postcode": 53343,
    "City": "WACHTBERG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.6128,
    "Longitude": 7.05347,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.6128,7.05347",
    "IsOpen24Hours": false,
    "id": "50.6128,7.05347"
  },
  {
    "Gas Station Number": 610652,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Rheinberger Str. 306",
    "Postcode": 47445,
    "City": "MOERS",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.488,
    "Longitude": 6.63318,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.488,6.63318",
    "IsOpen24Hours": false,
    "id": "51.488,6.63318"
  },
  {
    "Gas Station Number": 610550,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Vardingholter Str. 10",
    "Postcode": 46414,
    "City": "RHEDE",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8443,
    "Longitude": 6.69483,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8443,6.69483",
    "IsOpen24Hours": false,
    "id": "51.8443,6.69483"
  },
  {
    "Gas Station Number": 610014,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Riesenbecker Str. 27",
    "Postcode": 48477,
    "City": "HOESTEL",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.2715,
    "Longitude": 7.58977,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2715,7.58977",
    "IsOpen24Hours": false,
    "id": "52.2715,7.58977"
  },
  {
    "Gas Station Number": 610416,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Steinfurter Str. 24",
    "Postcode": 59387,
    "City": "ASCHEBERG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.7926,
    "Longitude": 7.62922,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.7926,7.62922",
    "IsOpen24Hours": false,
    "id": "51.7926,7.62922"
  },
  {
    "Gas Station Number": 610503,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Ennigerloher Str. 13",
    "Postcode": 59269,
    "City": "BECKUM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8062,
    "Longitude": 8.0256,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8062,8.0256",
    "IsOpen24Hours": false,
    "id": "51.8062,8.0256"
  },
  {
    "Gas Station Number": 610430,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Bohlweg 70-72",
    "Postcode": 48147,
    "City": "MUENSTER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9673,
    "Longitude": 7.64306,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9673,7.64306",
    "IsOpen24Hours": false,
    "id": "51.9673,7.64306"
  },
  {
    "Gas Station Number": 610274,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Roisdorfer Str.",
    "Postcode": 53332,
    "City": "BORNHEIM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.761,
    "Longitude": 7.02631,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.761,7.02631",
    "IsOpen24Hours": false,
    "id": "50.761,7.02631"
  },
  {
    "Gas Station Number": 610497,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Rolshover Str. 380",
    "Postcode": 51105,
    "City": "KOELN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.9205,
    "Longitude": 6.99883,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9205,6.99883",
    "IsOpen24Hours": false,
    "id": "50.9205,6.99883"
  },
  {
    "Gas Station Number": 610527,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Pothof 14",
    "Postcode": 48301,
    "City": "NOTTULN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9279,
    "Longitude": 7.35334,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9279,7.35334",
    "IsOpen24Hours": false,
    "id": "51.9279,7.35334"
  },
  {
    "Gas Station Number": 610620,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Rheindeichstr. 20",
    "Postcode": 47198,
    "City": "DUISBURG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4572,
    "Longitude": 6.71349,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4572,6.71349",
    "IsOpen24Hours": false,
    "id": "51.4572,6.71349"
  },
  {
    "Gas Station Number": 610372,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Duerener Str. 388-392",
    "Postcode": 50935,
    "City": "KOELN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.9255,
    "Longitude": 6.89543,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9255,6.89543",
    "IsOpen24Hours": false,
    "id": "50.9255,6.89543"
  },
  {
    "Gas Station Number": 610628,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Lemgoer Str. 15",
    "Postcode": 32756,
    "City": "DETMOLD",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9436,
    "Longitude": 8.87837,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9436,8.87837",
    "IsOpen24Hours": false,
    "id": "51.9436,8.87837"
  },
  {
    "Gas Station Number": 610627,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Dinxperloer Str. 55",
    "Postcode": 46399,
    "City": "BOCHOLT",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8429,
    "Longitude": 6.60589,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8429,6.60589",
    "IsOpen24Hours": false,
    "id": "51.8429,6.60589"
  },
  {
    "Gas Station Number": 610471,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Breite Str. 173 a",
    "Postcode": 26919,
    "City": "BRAKE",
    "State": "Niedersachsen",
    "Latitude": 53.325,
    "Longitude": 8.46438,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.325,8.46438",
    "IsOpen24Hours": false,
    "id": "53.325,8.46438"
  },
  {
    "Gas Station Number": 610325,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Stedinger Landstr. 21",
    "Postcode": 27753,
    "City": "DELMENHORST",
    "State": "Niedersachsen",
    "Latitude": 53.086,
    "Longitude": 8.65038,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.086,8.65038",
    "IsOpen24Hours": false,
    "id": "53.086,8.65038"
  },
  {
    "Gas Station Number": 610070,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Bottroper Str. 184",
    "Postcode": 46244,
    "City": "BOTTROP",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5702,
    "Longitude": 6.91089,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5702,6.91089",
    "IsOpen24Hours": false,
    "id": "51.5702,6.91089"
  },
  {
    "Gas Station Number": 610585,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Teutoburger-Wald-Str. 45",
    "Postcode": 49124,
    "City": "GEORGSMARIENHUETTE",
    "State": "Niedersachsen",
    "Latitude": 52.1901,
    "Longitude": 8.06873,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1901,8.06873",
    "IsOpen24Hours": false,
    "id": "52.1901,8.06873"
  },
  {
    "Gas Station Number": 610569,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Schiffahrter Damm 506",
    "Postcode": 48157,
    "City": "MUENSTER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.0104,
    "Longitude": 7.67915,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0104,7.67915",
    "IsOpen24Hours": false,
    "id": "52.0104,7.67915"
  },
  {
    "Gas Station Number": 610525,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Weseler Str. 212-216",
    "Postcode": 48151,
    "City": "MUENSTER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.944,
    "Longitude": 7.61172,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.944,7.61172",
    "IsOpen24Hours": false,
    "id": "51.944,7.61172"
  },
  {
    "Gas Station Number": 610044,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Armlandstrasse 22",
    "Postcode": 48712,
    "City": "GESCHER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.957,
    "Longitude": 7.00132,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.957,7.00132",
    "IsOpen24Hours": false,
    "id": "51.957,7.00132"
  },
  {
    "Gas Station Number": 900694,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Brockbachstr. 20",
    "Postcode": 49492,
    "City": "WESTERKAPPELN N",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.261223,
    "Longitude": 7.846699,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.261223,7.846699",
    "IsOpen24Hours": false,
    "id": "52.261223,7.846699"
  },
  {
    "Gas Station Number": 610632,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Polsumer Str. 236",
    "Postcode": 45896,
    "City": "GELSENKIRCHEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.6074,
    "Longitude": 7.04898,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6074,7.04898",
    "IsOpen24Hours": false,
    "id": "51.6074,7.04898"
  },
  {
    "Gas Station Number": 610520,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Muensterdamm 35",
    "Postcode": 48366,
    "City": "LAER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.0568,
    "Longitude": 7.36472,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0568,7.36472",
    "IsOpen24Hours": false,
    "id": "52.0568,7.36472"
  },
  {
    "Gas Station Number": 610349,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Horster Str. 189",
    "Postcode": 45897,
    "City": "GELSENKIRCHEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5614,
    "Longitude": 7.04644,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5614,7.04644",
    "IsOpen24Hours": false,
    "id": "51.5614,7.04644"
  },
  {
    "Gas Station Number": 610636,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Becheltestr. 56",
    "Postcode": 58089,
    "City": "HAGEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.378,
    "Longitude": 7.45177,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.378,7.45177",
    "IsOpen24Hours": false,
    "id": "51.378,7.45177"
  },
  {
    "Gas Station Number": 610184,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Auf dem Quellberg 18",
    "Postcode": 48249,
    "City": "DUELMEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8435,
    "Longitude": 7.2946,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8435,7.2946",
    "IsOpen24Hours": false,
    "id": "51.8435,7.2946"
  },
  {
    "Gas Station Number": 610317,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Meller Str. 41",
    "Postcode": 49143,
    "City": "BISSENDORF",
    "State": "Niedersachsen",
    "Latitude": 52.2325,
    "Longitude": 8.17424,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2325,8.17424",
    "IsOpen24Hours": false,
    "id": "52.2325,8.17424"
  },
  {
    "Gas Station Number": 610523,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Weseler Str. 383",
    "Postcode": 48163,
    "City": "MUENSTER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9331,
    "Longitude": 7.59754,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9331,7.59754",
    "IsOpen24Hours": false,
    "id": "51.9331,7.59754"
  },
  {
    "Gas Station Number": 900685,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "BAT Lipperland",
    "Postcode": 32107,
    "City": "BAT LIPPERLAND",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.073474,
    "Longitude": 8.667235,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.073474,8.667235",
    "IsOpen24Hours": false,
    "id": "52.073474,8.667235"
  },
  {
    "Gas Station Number": 610314,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Schlingstr. 26",
    "Postcode": 49406,
    "City": "BARNSTORF",
    "State": "Niedersachsen",
    "Latitude": 52.7115,
    "Longitude": 8.50964,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.7115,8.50964",
    "IsOpen24Hours": false,
    "id": "52.7115,8.50964"
  },
  {
    "Gas Station Number": 610612,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Rheiner Landstr. 69",
    "Postcode": 49078,
    "City": "OSNABRUECK",
    "State": "Niedersachsen",
    "Latitude": 52.2744,
    "Longitude": 8.02246,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2744,8.02246",
    "IsOpen24Hours": false,
    "id": "52.2744,8.02246"
  },
  {
    "Gas Station Number": 610183,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Emschertalstr. 131",
    "Postcode": 44289,
    "City": "DORTMUND",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5061,
    "Longitude": 7.58915,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5061,7.58915",
    "IsOpen24Hours": false,
    "id": "51.5061,7.58915"
  },
  {
    "Gas Station Number": 610409,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Von-Steuben-Str. 13",
    "Postcode": 48143,
    "City": "MUENSTER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9549,
    "Longitude": 7.63226,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9549,7.63226",
    "IsOpen24Hours": false,
    "id": "51.9549,7.63226"
  },
  {
    "Gas Station Number": 610217,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Am Zubringer 2",
    "Postcode": 32107,
    "City": "BAD SALZUFLEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.0826,
    "Longitude": 8.71713,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0826,8.71713",
    "IsOpen24Hours": false,
    "id": "52.0826,8.71713"
  },
  {
    "Gas Station Number": 610414,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Wessumer Str. 63",
    "Postcode": 48683,
    "City": "AHAUS",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.0823,
    "Longitude": 7.00263,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0823,7.00263",
    "IsOpen24Hours": false,
    "id": "52.0823,7.00263"
  },
  {
    "Gas Station Number": 610581,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Rheiner Str. 121",
    "Postcode": 48282,
    "City": "EMSDETTEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.1825,
    "Longitude": 7.52076,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1825,7.52076",
    "IsOpen24Hours": false,
    "id": "52.1825,7.52076"
  },
  {
    "Gas Station Number": 610259,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Bursdamm 1",
    "Postcode": 33129,
    "City": "DELBRUECK",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.7822,
    "Longitude": 8.62402,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.7822,8.62402",
    "IsOpen24Hours": false,
    "id": "51.7822,8.62402"
  },
  {
    "Gas Station Number": 610346,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Sternbuschweg 103a",
    "Postcode": 47057,
    "City": "DUISBURG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4267,
    "Longitude": 6.79122,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4267,6.79122",
    "IsOpen24Hours": false,
    "id": "51.4267,6.79122"
  },
  {
    "Gas Station Number": 610470,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Borsteler Landstr. 47",
    "Postcode": 28832,
    "City": "ACHIM",
    "State": "Niedersachsen",
    "Latitude": 53.0175,
    "Longitude": 9.03867,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.0175,9.03867",
    "IsOpen24Hours": false,
    "id": "53.0175,9.03867"
  },
  {
    "Gas Station Number": 610085,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Zum Heubach 3",
    "Postcode": 48734,
    "City": "REKEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8411,
    "Longitude": 7.09537,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8411,7.09537",
    "IsOpen24Hours": false,
    "id": "51.8411,7.09537"
  },
  {
    "Gas Station Number": 610630,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Benninghofer Str. 265",
    "Postcode": 44267,
    "City": "DORTMUND",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4687,
    "Longitude": 7.51574,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4687,7.51574",
    "IsOpen24Hours": false,
    "id": "51.4687,7.51574"
  },
  {
    "Gas Station Number": 610127,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Schlosser Str 2",
    "Postcode": 59399,
    "City": "OLFEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.706752,
    "Longitude": 7.392409,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.706752,7.392409",
    "IsOpen24Hours": false,
    "id": "51.706752,7.392409"
  },
  {
    "Gas Station Number": 610609,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Luebecker Str. 55",
    "Postcode": 32429,
    "City": "MINDEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.2746,
    "Longitude": 8.89266,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2746,8.89266",
    "IsOpen24Hours": false,
    "id": "52.2746,8.89266"
  },
  {
    "Gas Station Number": 610241,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Westheider Weg 46 - 48",
    "Postcode": 33775,
    "City": "VERSMOLD",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.0439,
    "Longitude": 8.1492,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0439,8.1492",
    "IsOpen24Hours": false,
    "id": "52.0439,8.1492"
  },
  {
    "Gas Station Number": 610313,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Butjadinger Str. 6",
    "Postcode": 26954,
    "City": "NORDENHAM",
    "State": "Niedersachsen",
    "Latitude": 53.4778,
    "Longitude": 8.45039,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.4778,8.45039",
    "IsOpen24Hours": false,
    "id": "53.4778,8.45039"
  },
  {
    "Gas Station Number": 610707,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Wilhelm-Tenhagen-Str. 1",
    "Postcode": 46240,
    "City": "BOTTROP",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5353,
    "Longitude": 6.97838,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5353,6.97838",
    "IsOpen24Hours": false,
    "id": "51.5353,6.97838"
  },
  {
    "Gas Station Number": 610315,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Friesoyther Str. 2",
    "Postcode": 26219,
    "City": "BOESEL",
    "State": "Niedersachsen",
    "Latitude": 53.0061,
    "Longitude": 7.95418,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.0061,7.95418",
    "IsOpen24Hours": false,
    "id": "53.0061,7.95418"
  },
  {
    "Gas Station Number": 610096,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Lindenstr. 35",
    "Postcode": 49586,
    "City": "NEUENKIRCHEN",
    "State": "Niedersachsen",
    "Latitude": 52.4194,
    "Longitude": 7.8381,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.4194,7.8381",
    "IsOpen24Hours": false,
    "id": "52.4194,7.8381"
  },
  {
    "Gas Station Number": 610457,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Mittelstr. 20",
    "Postcode": 32805,
    "City": "HORN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8713,
    "Longitude": 8.94023,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8713,8.94023",
    "IsOpen24Hours": false,
    "id": "51.8713,8.94023"
  },
  {
    "Gas Station Number": 610327,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Bockhorner Str. 78",
    "Postcode": 26316,
    "City": "VAREL",
    "State": "Niedersachsen",
    "Latitude": 53.3849,
    "Longitude": 8.05711,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.3849,8.05711",
    "IsOpen24Hours": false,
    "id": "53.3849,8.05711"
  },
  {
    "Gas Station Number": 610322,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Dorfstr. 4",
    "Postcode": 26427,
    "City": "NEUHARLINGERSIEL",
    "State": "Niedersachsen",
    "Latitude": 53.6841,
    "Longitude": 7.68646,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.6841,7.68646",
    "IsOpen24Hours": false,
    "id": "53.6841,7.68646"
  },
  {
    "Gas Station Number": 900690,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Braukstr. 160",
    "Postcode": 45892,
    "City": "BAT RESSER MARK NORD GELSENKIRCHEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.5709,
    "Longitude": 7.10451,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.5709,7.10451",
    "IsOpen24Hours": false,
    "id": "51.5709,7.10451"
  },
  {
    "Gas Station Number": 610359,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Düsseldorfer Str. 2",
    "Postcode": 47918,
    "City": "TOENISVORST",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.3231,
    "Longitude": 6.47272,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3231,6.47272",
    "IsOpen24Hours": false,
    "id": "51.3231,6.47272"
  },
  {
    "Gas Station Number": 610354,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Neue Ritterstr. 41",
    "Postcode": 47805,
    "City": "KREFELD",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.3289,
    "Longitude": 6.58639,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3289,6.58639",
    "IsOpen24Hours": false,
    "id": "51.3289,6.58639"
  },
  {
    "Gas Station Number": 610330,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Hauptstr. 455",
    "Postcode": 26689,
    "City": "APEN",
    "State": "Niedersachsen",
    "Latitude": 53.2166,
    "Longitude": 7.76252,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.2166,7.76252",
    "IsOpen24Hours": false,
    "id": "53.2166,7.76252"
  },
  {
    "Gas Station Number": 610625,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Rheiner Str.5",
    "Postcode": 48455,
    "City": "BAD BENTHEIM",
    "State": "Niedersachsen",
    "Latitude": 52.3047,
    "Longitude": 7.15061,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.3047,7.15061",
    "IsOpen24Hours": false,
    "id": "52.3047,7.15061"
  },
  {
    "Gas Station Number": 610129,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Osttor 63",
    "Postcode": 48324,
    "City": "SENDENHORST",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8412,
    "Longitude": 7.84299,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8412,7.84299",
    "IsOpen24Hours": false,
    "id": "51.8412,7.84299"
  },
  {
    "Gas Station Number": 610010,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Sindorfer Str. 52",
    "Postcode": 50171,
    "City": "KERPEN II",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.8826,
    "Longitude": 6.69456,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.8826,6.69456",
    "IsOpen24Hours": false,
    "id": "50.8826,6.69456"
  },
  {
    "Gas Station Number": 610638,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Ostenallee 48",
    "Postcode": 59063,
    "City": "HAMM",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.6858,
    "Longitude": 7.8345,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6858,7.8345",
    "IsOpen24Hours": false,
    "id": "51.6858,7.8345"
  },
  {
    "Gas Station Number": 610702,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Petzer Strasse 6 c",
    "Postcode": 31675,
    "City": "BUEKEBURG",
    "State": "Niedersachsen",
    "Latitude": 52.2625,
    "Longitude": 9.03767,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2625,9.03767",
    "IsOpen24Hours": false,
    "id": "52.2625,9.03767"
  },
  {
    "Gas Station Number": 610285,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Knallhuetter Str. 43",
    "Postcode": 34225,
    "City": "BAUNATAL",
    "State": "Hessen",
    "Latitude": 51.2583,
    "Longitude": 9.44833,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2583,9.44833",
    "IsOpen24Hours": false,
    "id": "51.2583,9.44833"
  },
  {
    "Gas Station Number": 610482,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Nordener Str. 5",
    "Postcode": 26556,
    "City": "WESTERHOLT",
    "State": "Niedersachsen",
    "Latitude": 53.5897,
    "Longitude": 7.45539,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.5897,7.45539",
    "IsOpen24Hours": false,
    "id": "53.5897,7.45539"
  },
  {
    "Gas Station Number": 610473,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Stresemannstr. 253",
    "Postcode": 27576,
    "City": "BREMERHAVEN",
    "State": "Bremen",
    "Latitude": 53.5651,
    "Longitude": 8.59509,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.5651,8.59509",
    "IsOpen24Hours": false,
    "id": "53.5651,8.59509"
  },
  {
    "Gas Station Number": 610375,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Koelner Str. 188",
    "Postcode": 51645,
    "City": "GUMMERSBACH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.0042,
    "Longitude": 7.59517,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.0042,7.59517",
    "IsOpen24Hours": false,
    "id": "51.0042,7.59517"
  },
  {
    "Gas Station Number": 610378,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Ritter-Heinrich-Str.",
    "Postcode": 56766,
    "City": "ULMEN",
    "State": "Rheinland-Pfalz",
    "Latitude": 50.2142,
    "Longitude": 6.97223,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.2142,6.97223",
    "IsOpen24Hours": false,
    "id": "50.2142,6.97223"
  },
  {
    "Gas Station Number": 610407,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Brautäcker 2",
    "Postcode": 34560,
    "City": "FRITZLAR",
    "State": "Hessen",
    "Latitude": 51.140017,
    "Longitude": 9.274789,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.140017,9.274789",
    "IsOpen24Hours": false,
    "id": "51.140017,9.274789"
  },
  {
    "Gas Station Number": 610310,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Preussnstr. 2 a",
    "Postcode": 26388,
    "City": "WILHELMSHAVEN",
    "State": "Niedersachsen",
    "Latitude": 53.5669,
    "Longitude": 8.09868,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.5669,8.09868",
    "IsOpen24Hours": false,
    "id": "53.5669,8.09868"
  },
  {
    "Gas Station Number": 610477,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Koenigstr. 69",
    "Postcode": 48268,
    "City": "GREVEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.0934,
    "Longitude": 7.6234,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0934,7.6234",
    "IsOpen24Hours": false,
    "id": "52.0934,7.6234"
  },
  {
    "Gas Station Number": 610510,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Osnabruecker Str. 6",
    "Postcode": 49201,
    "City": "DISSEN",
    "State": "Niedersachsen",
    "Latitude": 52.1157,
    "Longitude": 8.19804,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1157,8.19804",
    "IsOpen24Hours": false,
    "id": "52.1157,8.19804"
  },
  {
    "Gas Station Number": 610230,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Niedringhausener Str. 130",
    "Postcode": 32609,
    "City": "HUELLHORST",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.2693,
    "Longitude": 8.63558,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2693,8.63558",
    "IsOpen24Hours": false,
    "id": "52.2693,8.63558"
  },
  {
    "Gas Station Number": 610711,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Osningstr. 15",
    "Postcode": 33605,
    "City": "BIELEFELD",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.0334,
    "Longitude": 8.55888,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0334,8.55888",
    "IsOpen24Hours": false,
    "id": "52.0334,8.55888"
  },
  {
    "Gas Station Number": 610634,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Wittener Str. 19",
    "Postcode": 58285,
    "City": "GEVELSBERG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.3217,
    "Longitude": 7.33794,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3217,7.33794",
    "IsOpen24Hours": false,
    "id": "51.3217,7.33794"
  },
  {
    "Gas Station Number": 610644,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Mettinger Str. 33",
    "Postcode": 49479,
    "City": "IBBENBUEREN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.2665,
    "Longitude": 7.78847,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2665,7.78847",
    "IsOpen24Hours": false,
    "id": "52.2665,7.78847"
  },
  {
    "Gas Station Number": 610006,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Roentgenstr. 2/2a",
    "Postcode": 32052,
    "City": "HERFORD",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.0608,
    "Longitude": 8.64421,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0608,8.64421",
    "IsOpen24Hours": false,
    "id": "52.0608,8.64421"
  },
  {
    "Gas Station Number": 610328,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Jacob-Borchers-Str. 49 a",
    "Postcode": 26340,
    "City": "ZETEL",
    "State": "Niedersachsen",
    "Latitude": 53.4217,
    "Longitude": 7.97962,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.4217,7.97962",
    "IsOpen24Hours": false,
    "id": "53.4217,7.97962"
  },
  {
    "Gas Station Number": 610608,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Luebbecker Str.115",
    "Postcode": 32278,
    "City": "KIRCHLENGERN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.2042,
    "Longitude": 8.64026,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2042,8.64026",
    "IsOpen24Hours": false,
    "id": "52.2042,8.64026"
  },
  {
    "Gas Station Number": 610011,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Gerdener Str. 4",
    "Postcode": 49326,
    "City": "MELLE",
    "State": "Niedersachsen",
    "Latitude": 52.1427,
    "Longitude": 8.38747,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1427,8.38747",
    "IsOpen24Hours": false,
    "id": "52.1427,8.38747"
  },
  {
    "Gas Station Number": 610478,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstr. 41",
    "Postcode": 26441,
    "City": "JEVER",
    "State": "Niedersachsen",
    "Latitude": 53.5706,
    "Longitude": 7.89232,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.5706,7.89232",
    "IsOpen24Hours": false,
    "id": "53.5706,7.89232"
  },
  {
    "Gas Station Number": 610347,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Moehlenkampstr. 9",
    "Postcode": 47139,
    "City": "DUISBURG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.4802,
    "Longitude": 6.74379,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.4802,6.74379",
    "IsOpen24Hours": false,
    "id": "51.4802,6.74379"
  },
  {
    "Gas Station Number": 610345,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Duesseldorfer Landstr. 343",
    "Postcode": 47259,
    "City": "DUISBURG",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.3535,
    "Longitude": 6.74872,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.3535,6.74872",
    "IsOpen24Hours": false,
    "id": "51.3535,6.74872"
  },
  {
    "Gas Station Number": 610584,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Lippestr. 34",
    "Postcode": 59558,
    "City": "LIPPSTADT",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.6902,
    "Longitude": 8.37274,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.6902,8.37274",
    "IsOpen24Hours": false,
    "id": "51.6902,8.37274"
  },
  {
    "Gas Station Number": 610469,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Embser Landstr. 22",
    "Postcode": 28832,
    "City": "ACHIM",
    "State": "Niedersachsen",
    "Latitude": 53.01937,
    "Longitude": 9.03098,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "53.01937,9.03098",
    "IsOpen24Hours": false,
    "id": "53.01937,9.03098"
  },
  {
    "Gas Station Number": 610532,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Sutthauser Str. 166",
    "Postcode": 49080,
    "City": "OSNABRUECK",
    "State": "Niedersachsen",
    "Latitude": 52.2561,
    "Longitude": 8.04179,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2561,8.04179",
    "IsOpen24Hours": false,
    "id": "52.2561,8.04179"
  },
  {
    "Gas Station Number": 610276,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Nicolaus-Otto-Str. 1",
    "Postcode": 52351,
    "City": "DUEREN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.7793,
    "Longitude": 6.50664,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.7793,6.50664",
    "IsOpen24Hours": false,
    "id": "50.7793,6.50664"
  },
  {
    "Gas Station Number": 610453,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Am Anger 22",
    "Postcode": 33332,
    "City": "GUETERSLOH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9005,
    "Longitude": 8.40111,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9005,8.40111",
    "IsOpen24Hours": false,
    "id": "51.9005,8.40111"
  },
  {
    "Gas Station Number": 610258,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstr. 90",
    "Postcode": 33813,
    "City": "OERLINGHAUSEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9795,
    "Longitude": 8.71404,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9795,8.71404",
    "IsOpen24Hours": false,
    "id": "51.9795,8.71404"
  },
  {
    "Gas Station Number": 610833,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Nobelstraße",
    "Postcode": 48477,
    "City": "HOERSTEL",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.30908,
    "Longitude": 7.61614,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.30908,7.61614",
    "IsOpen24Hours": false,
    "id": "52.30908,7.61614"
  },
  {
    "Gas Station Number": 610402,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Weseler Str. 3",
    "Postcode": 48249,
    "City": "DUELMEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8648,
    "Longitude": 7.36028,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8648,7.36028",
    "IsOpen24Hours": false,
    "id": "51.8648,7.36028"
  },
  {
    "Gas Station Number": 900530,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Bielefelder Str. 107",
    "Postcode": 33378,
    "City": "BAT REMSCHEID-Ostseite",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.1586,
    "Longitude": 7.23084,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.1586,7.23084",
    "IsOpen24Hours": false,
    "id": "51.1586,7.23084"
  },
  {
    "Gas Station Number": 610452,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Herforder 110-114",
    "Postcode": 33602,
    "City": "BIELEFELD",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.0328,
    "Longitude": 8.54145,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0328,8.54145",
    "IsOpen24Hours": false,
    "id": "52.0328,8.54145"
  },
  {
    "Gas Station Number": 610012,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Herforder 111",
    "Postcode": 32602,
    "City": "VLOTHO",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.1573,
    "Longitude": 8.84415,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1573,8.84415",
    "IsOpen24Hours": false,
    "id": "52.1573,8.84415"
  },
  {
    "Gas Station Number": 610588,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Osnabrücker Str. 21",
    "Postcode": 49214,
    "City": "BAD ROTHENFELDE",
    "State": "Niedersachsen",
    "Latitude": 52.1131,
    "Longitude": 8.16225,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.1131,8.16225",
    "IsOpen24Hours": false,
    "id": "52.1131,8.16225"
  },
  {
    "Gas Station Number": 610415,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Mondstrasse 160 - 162",
    "Postcode": 48155,
    "City": "MUENSTER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.9554,
    "Longitude": 7.67722,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.9554,7.67722",
    "IsOpen24Hours": false,
    "id": "51.9554,7.67722"
  },
  {
    "Gas Station Number": 610456,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Paderborner Str. 32",
    "Postcode": 33161,
    "City": "HOEVELHOF",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.8176,
    "Longitude": 8.66084,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.8176,8.66084",
    "IsOpen24Hours": false,
    "id": "51.8176,8.66084"
  },
  {
    "Gas Station Number": 610377,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Meckenheimer Str. 8",
    "Postcode": 53359,
    "City": "RHEINBACH",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.625,
    "Longitude": 6.96066,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.625,6.96066",
    "IsOpen24Hours": false,
    "id": "50.625,6.96066"
  },
  {
    "Gas Station Number": 610460,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Bahnhofstr. 76",
    "Postcode": 33102,
    "City": "PADERBORN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.7122,
    "Longitude": 8.73424,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.7122,8.73424",
    "IsOpen24Hours": false,
    "id": "51.7122,8.73424"
  },
  {
    "Gas Station Number": 610329,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Landstr. 10",
    "Postcode": 37170,
    "City": "USLAR",
    "State": "Niedersachsen",
    "Latitude": 51.627,
    "Longitude": 9.66106,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.627,9.66106",
    "IsOpen24Hours": false,
    "id": "51.627,9.66106"
  },
  {
    "Gas Station Number": 610580,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Schiffahrter Damm 511",
    "Postcode": 48157,
    "City": "MUENSTER",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.0105,
    "Longitude": 7.6789,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0105,7.6789",
    "IsOpen24Hours": false,
    "id": "52.0105,7.6789"
  },
  {
    "Gas Station Number": 610708,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Natruper Str. 166",
    "Postcode": 49090,
    "City": "OSNABRUECK",
    "State": "Niedersachsen",
    "Latitude": 52.2905,
    "Longitude": 8.02025,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.2905,8.02025",
    "IsOpen24Hours": false,
    "id": "52.2905,8.02025"
  },
  {
    "Gas Station Number": 610637,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Ruhrtalstr. 5",
    "Postcode": 58099,
    "City": "HAGEN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.412,
    "Longitude": 7.51293,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.412,7.51293",
    "IsOpen24Hours": false,
    "id": "51.412,7.51293"
  },
  {
    "Gas Station Number": 610004,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Fr.-Ebert-Str. 129",
    "Postcode": 42117,
    "City": "WUPPERTAL",
    "State": "Nordrhein-Westfalen",
    "Latitude": 51.2511,
    "Longitude": 7.12846,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "51.2511,7.12846",
    "IsOpen24Hours": false,
    "id": "51.2511,7.12846"
  },
  {
    "Gas Station Number": 610176,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Loburg 58",
    "Postcode": 48346,
    "City": "OSTBEVERN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 52.0367,
    "Longitude": 7.85874,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "52.0367,7.85874",
    "IsOpen24Hours": false,
    "id": "52.0367,7.85874"
  },
  {
    "Gas Station Number": 610500,
    "Brand": "Westfalen",
    "Description": "Strassentankstelle",
    "Address": "Spenrather Weg 1",
    "Postcode": 50829,
    "City": "KOELN",
    "State": "Nordrhein-Westfalen",
    "Latitude": 50.9805,
    "Longitude": 6.84661,
    "Telephone Number": "",
    "Week From": "",
    "Week To": "",
    "Saturdays From": "",
    "Saturdays To": "",
    "Sundays From": "",
    "Sundays To": "",
    "24 hour?": "Nein",
    "location": "50.9805,6.84661",
    "IsOpen24Hours": false,
    "id": "50.9805,6.84661"
  }
];
