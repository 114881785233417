/* eslint-disable no-undef */
import { interpolateVariables } from './localize';
import { isWeb } from './platformHelpers';
import { State } from '../@types';
import { store } from '../redux';

type Dictionary = {
  group: string;
  language: string;
  [key: string]: any;
};

const getDictionary = (): Dictionary | null => {
  const state: State = store.getState();
  const {
    appData: { texts },
    userData: {
      user: { user },
    },
    device: { language, deviceUUID },
  } = state;

  if (!texts) return null;

  return {
    ...texts,
    language: language,
    group: 'default',
    uniqueId: user?.id || deviceUUID,
  };
};

export const localizeStandalone = (
  key: string = '',
  ...params: Array<string>
): string => {
  const dictionary = getDictionary();
  if (!dictionary || !dictionary.group || !dictionary.language) {
    // @ts-ignore
    if (!isWeb() && __DEV__) {
      console.error('Localization dictionary is not found');
    }
    return key;
  }

  const languages = dictionary[dictionary.group];
  const values = languages[dictionary.language];
  const value = values?.[key];
  const localization = value && interpolateVariables(value, params);

  if (!localization) {
    // @ts-ignore
    if (!isWeb() && __DEV__) {
      console.warn(`Localization not found for text key "${key}"`);
    }
    return key;
  }

  return localization;
};
