import { connect } from 'react-redux';
import { compose, withHandlers, mapProps } from 'recompose';
import { State } from '../@types';
import { localize } from '../helpers/localize';
import { getBrandLanguage } from '../helpers/brandHelpers';

export type LocalizePropType = (
  key: string,
  ...params: Array<string | number>
) => string;

export type Props = {
  localize: LocalizePropType;
};

const withTextLocalizer: any = compose(
  connect(
    ({
      appData: { texts },
      userData: {
        user: { user },
      },
      device: { language, deviceUUID },
      config: { xBrandId },
    }: State) => ({
      uniqueId: user?.id! || deviceUUID,
      dictionary: {
        ...texts,
        language: language || getBrandLanguage(xBrandId),
        group: 'default',
      },
    })
  ),
  withHandlers({
    localize:
      ({ dictionary, uniqueId }: any) =>
      (key: string | undefined, ...params: any) =>
        localize(dictionary, uniqueId, key, ...params),
  } as any),
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  mapProps(({ dictionary, dispatch, ...props }) => ({ ...props }))
);
export default withTextLocalizer;
