import React, { Suspense, useState, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import LandingPage from '../init/AuthLoading/AuthLoadingContainer';
import MapPage from '../ui/pages/Map/MapContainer';
import LoginPage from '../ui/pages/Login/LoginContainer';
import ForgotPage from '../ui/pages/ForgetPassword/ForgotPasswordContainer';
import ResetPasswordPage from '../ui/pages/ResetPassword/ResetPasswordContainer';
import SignUpPage from '../ui/pages/SignUp/SignupContainer';
import OrgSignUpPage from '../ui/pages/OrgSignUp/OrgSignupContainer';
import LoadingContainer from '../ui/components/LoadingScreen/LoadingContainer';
import AddPhoneContainer from '../ui/pages/onboarding/AddPhone/AddPhoneContainer';
import VerifyPhoneContainer from '../ui/pages/onboarding/VerifyPhone/VerifyPhoneContainer';
import UpdateAddressContainer from '../ui/pages/onboarding/UpdateAddress/UpdateAddressContainer';
import AddSepaContainer from '../ui/pages/onboarding/addPayment/AddSepaPayment/AddSepaContainer';
import SelectPaymentContainer from '../ui/pages/onboarding/addPayment/SelectPayment/SelectPaymentContainer';
import SchufaCheckContainer from '../ui/pages/onboarding/SchufaCheck/SchufaCheckContainer';
import AddCCPaymentContainer from '../ui/pages/onboarding/addPayment/AddCCPayment/AddCCPaymentContainer';
import FuelSettingsContainer from '../ui/pages/Rental/FuelSettings/FuelSettingsContainer';
import BrowseDamagesContainer from '../ui/pages/Rental/Damages/Browse/BrowseDamagesContainer';
import AddDamageContainer from '../ui/pages/Rental/Damages/Add/AddDamageContainer';
import AddTestDamageContainer from '../ui/pages/Rental/Damages/AddTest/AddDamageContainer';
import DLOnboardingContainer from '../ui/pages/onboarding/DrivingLicense/Onboarding/DLOnboardingContainer';
import KeyVideoContainer from '../ui/pages/Rental/KeyVideo/KeyVideoContainer';
import RentalHandoverContainer from '../ui/pages/Rental/Handover/RentalHandoverContainer';
import DLScanningContainer from '../ui/pages/onboarding/DrivingLicense/Scanning/DLScanningContainer';
import CardVideoContainer from '../ui/pages/Rental/CardVideo/CardVideoContainer';
import BookingConfirmationContainer from '../ui/pages/Booking/Confirmation/BookingConfirmationContainer';
import BookingContainer from '../ui/pages/Booking/BookingContainer';
import AddCircleContainer from '../ui/pages/Dashboard/circle/AddCircleContainer';
import RentalsPage from '../ui/pages/Dashboard/Rentals/UserRentals/UserRentalsContainer';
import RentalDetailsPage from '../ui/pages/Dashboard/Rentals/RentalDetails/RentalDetailsContainer';
import BalancePage from '../ui/pages/Dashboard/Balance/BalanceContainer';
import ProfilePage from '../ui/pages/Dashboard/Profile/ProfileContainer';
import DesktopDashboardContainer from '../ui/pages/Dashboard/DesktopDashboardContainer';
import { isMobileBrowser } from '../utils';
import {
  isKYCApp,
  selectDeeplink,
  selectIsEmbedded,
  selectShowHomeWT,
  selectUser,
  setDeeplinkUrl,
} from 'getaway-data-layer';
import { useDispatch, useSelector } from 'react-redux';
import DesktopWTContainer from '../ui/pages/DesktopWT/DesktopWTContainer';
import MobileSwitchContainer from '../ui/pages/MobileSwitch/MobileSwitchContainer';
import BookingConsentContainer from '../ui/pages/Booking/Consent/BookingConsentContainer';
import HomeContainer from '../ui/pages/Home/HomeContainer';
import CCAdyenComponent from '../ui/pages/CCAdyenComponent/CCAdyenComponent';
import VerifyEmailContainer from '../ui/pages/onboarding/VerifyEmail/VerifyEmailContainer';
import IDOnboardingContainer from '../ui/pages/onboarding/ID/Onboarding/IDOnboardingContainer';
import IDScanningContainer from '../ui/pages/onboarding/ID/Scanning/IDScanningContainer';
import PaymentLinkContainer from '../ui/pages/Booking/PaymentLink/PaymentLinkContainer';
import OptimizedSepaContainer from '../ui/pages/onboarding/addPayment/OptimizedSepaPayment/OptimizedSepaContainer';
import PaymentProfileContainer from '../ui/pages/onboarding/addPayment/PaymentProfile/PaymentProfileContainer';
import UpdateEmailContainer from '../ui/pages/onboarding/UpdateEmail/UpdateEmailContainer';
import DepositPaymentContainer from '../ui/pages/onboarding/addPayment/DepositPayment/DepositPaymentContainer';
import TariffContainer from '../ui/pages/onboarding/Tariff/TariffContainer';
import UpsellingsContainer from '../ui/pages/onboarding/Upsellings/UpsellingsContainer';
import DynamicFormContainer from '../ui/pages/onboarding/DynamicForm/DynamicFormContainer';
import MdPlaygroundContainer from '../ui/pages/MarkdownPlayground/MdPlaygroundContainer';
import AllAroundContainer from '../ui/pages/Rental/AllAround/AllAroundContainer';
import ConsentsContainer from '../ui/pages/Dashboard/consents/ConsentsContainer';
import JumioSdk from '../ui/pages/onboarding/ID/JumioSDK/JumioSdk';
import NotFoundPage from '../ui/pages/NotFound/NotFoundContainer';

const AppRoute = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const showHomeWT = useSelector(selectShowHomeWT) as boolean;
  const deeplink = useSelector(selectDeeplink);
  const history = useHistory();
  const [hasHandledDeepLink, setHasHandledDeepLink] = useState(false);
  const isEmbedded = useSelector(selectIsEmbedded);

  const handleDeeplink = () => {
    if (
      !user &&
      !['/', '/reset-password'].includes(history?.location?.pathname)
    ) {
      const newDeeplink =
        history?.location?.pathname + history?.location?.search;
      if (newDeeplink !== '/map') {
        dispatch(setDeeplinkUrl(newDeeplink));
      }
    }
    setHasHandledDeepLink(true);
  };

  useEffect(() => {
    handleDeeplink();
  }, []);

  useEffect(() => {
    if (user && deeplink !== '') {
      dispatch(setDeeplinkUrl(''));
    }
  }, [user]);

  if (!hasHandledDeepLink) {
    return <LoadingContainer />;
  }

  return (
    <Suspense fallback={<LoadingContainer />}>
      {!isEmbedded &&
        isKYCApp() &&
        showHomeWT &&
        history?.location?.pathname === '/' &&
        (isMobileBrowser() ? <HomeContainer /> : <DesktopWTContainer />)}
      {isKYCApp() &&
        isMobileBrowser() &&
        history?.location?.pathname === '/' && <SignUpPage />}
      <Switch>
        {/* AUTH */}
        <Route path="/login" exact component={LoginPage} />
        <Route path="/signup" exact component={SignUpPage} />
        <Route path="/org-signup" exact component={OrgSignUpPage} />
        <Route
          path="/circle/join"
          exact
          component={user ? LandingPage : SignUpPage}
        />
        <Route path="/forgot" exact component={ForgotPage} />
        <Route path="/reset-password" exact component={ResetPasswordPage} />
        <Route path="/markdown" exact component={MdPlaygroundContainer} />

        {/* ON BOARDING */}
        <PrivateRoute
          path="/onboarding/email"
          exact
          component={VerifyEmailContainer}
        />
        <PrivateRoute
          path="/onboarding/updateEmail"
          exact
          component={UpdateEmailContainer}
        />
        <PrivateRoute
          path={'/onboarding/addPhone' || '/dashboard/addPhone'}
          exact
          component={AddPhoneContainer}
        />
        <PrivateRoute
          path="/onboarding/verifyPhone"
          exact
          component={VerifyPhoneContainer}
        />
        <PrivateRoute
          path="/onboarding/addAddress"
          exact
          component={UpdateAddressContainer}
        />
        <PrivateRoute
          path="/onboarding/selectPayment"
          exact
          component={SelectPaymentContainer}
        />
        <PrivateRoute
          path="/onboarding/addCC"
          exact
          component={AddCCPaymentContainer}
        />
        <PrivateRoute
          path="/onboarding/addSepa"
          exact
          component={AddSepaContainer}
        />
        <PrivateRoute
          path="/onboarding/optimizedSepa"
          exact
          component={OptimizedSepaContainer}
        />
        <PrivateRoute
          path="/onboarding/schufaCheck"
          exact
          component={SchufaCheckContainer}
        />
        <PrivateRoute
          path="/onboarding/DLOnboarding"
          exact
          component={DLOnboardingContainer}
        />
        <PrivateRoute
          path="/onboarding/DLScanning"
          exact
          component={DLScanningContainer}
        />
        <PrivateRoute path="/dashboard/jumio-sdk" exact component={JumioSdk} />
        <PrivateRoute
          path="/onboarding/IDOnboarding"
          exact
          component={IDOnboardingContainer}
        />
        <PrivateRoute
          path="/onboarding/IDScanning"
          exact
          component={IDScanningContainer}
        />
        <PrivateRoute
          path="/onboarding/tariff"
          exact
          component={TariffContainer}
        />
        <PrivateRoute
          path="/onboarding/upsellings"
          exact
          component={UpsellingsContainer}
        />
        <PrivateRoute
          path="/onboarding/dynamicForm"
          exact
          component={DynamicFormContainer}
        />

        {/* DASHBOARD */}
        <PrivateRoute
          path="/dashboard/email"
          exact
          component={VerifyEmailContainer}
        />
        <PrivateRoute
          path="/dashboard/updateEmail"
          exact
          component={UpdateEmailContainer}
        />
        <PrivateRoute
          path="/dashboard/addPhone"
          exact
          component={AddPhoneContainer}
        />
        <PrivateRoute
          path="/dashboard/verifyPhone"
          exact
          component={VerifyPhoneContainer}
        />
        <PrivateRoute
          path="/dashboard/joinCircle"
          exact
          component={AddCircleContainer}
        />
        <PrivateRoute
          path="/dashboard/addAddress"
          exact
          component={UpdateAddressContainer}
        />
        <PrivateRoute
          path="/dashboard/selectPayment"
          exact
          component={SelectPaymentContainer}
        />
        <PrivateRoute
          path="/dashboard/addCC"
          exact
          component={AddCCPaymentContainer}
        />
        <PrivateRoute
          path="/dashboard/schufaCheck"
          exact
          component={SchufaCheckContainer}
        />
        <PrivateRoute
          path="/dashboard/addSepa"
          exact
          component={AddSepaContainer}
        />
        <PrivateRoute
          path="/dashboard/deposit"
          exact
          component={DepositPaymentContainer}
        />
        <PrivateRoute
          path="/dashboard/optimizedSepa"
          exact
          component={OptimizedSepaContainer}
        />
        <PrivateRoute
          path="/dashboard/DLOnboarding"
          exact
          component={DLOnboardingContainer}
        />
        <PrivateRoute
          path="/dashboard/DLScanning"
          exact
          component={DLScanningContainer}
        />
        <PrivateRoute
          path="/dashboard/IDOnboarding"
          exact
          component={IDOnboardingContainer}
        />
        <PrivateRoute
          path="/dashboard/IDScanning"
          exact
          component={IDScanningContainer}
        />
        <PrivateRoute
          path="/dashboard/paymentProfile/:id"
          exact
          component={PaymentProfileContainer}
        />
        <PrivateRoute
          path="/dashboard/paymentProfile"
          exact
          component={PaymentProfileContainer}
        />
        <PrivateRoute
          path="/dashboard/consents"
          exact
          component={ConsentsContainer}
        />
        <PrivateRoute
          path="/dashboard/tariff"
          exact
          component={TariffContainer}
        />
        <PrivateRoute
          path="/dashboard/upsellings"
          exact
          component={UpsellingsContainer}
        />
        <PrivateRoute
          path="/dashboard/dynamicForm"
          exact
          component={DynamicFormContainer}
        />

        {/* RENTAL */}
        <PrivateRoute
          path="/rental/AllAround"
          exact
          component={AllAroundContainer}
        />
        <PrivateRoute
          path="/rental/fuelSettings"
          exact
          component={FuelSettingsContainer}
        />
        <PrivateRoute
          path="/rental/browseDamages"
          exact
          component={BrowseDamagesContainer}
        />
        <PrivateRoute
          path="/rental/addDamage"
          exact
          component={AddDamageContainer}
        />
        <PrivateRoute
          path="/rental/addTestDamage"
          exact
          component={AddTestDamageContainer}
        />
        <PrivateRoute
          path="/rental/keyVideo"
          exact
          component={KeyVideoContainer}
        />
        <PrivateRoute
          path="/rental/cardVideo"
          exact
          component={CardVideoContainer}
        />
        <PrivateRoute
          path="/rental/handover"
          exact
          component={RentalHandoverContainer}
        />

        <Route
          path="/booking/confirmation"
          exact
          component={BookingConfirmationContainer}
        />

        <PrivateRoute path="/booking/:id" exact component={BookingContainer} />

        <PrivateRoute
          path="/booking/payment/:id"
          exact
          component={PaymentLinkContainer}
        />

        <PrivateRoute
          path="/booking/consent/:id"
          exact
          component={BookingConsentContainer}
        />

        {/* ROOT */}
        {!isKYCApp() && (
          <Route
            path="/map"
            exact
            component={
              isMobileBrowser() || isEmbedded
                ? MapPage
                : user
                ? () => null
                : DesktopWTContainer
            }
          />
        )}
        <PrivateRoute
          path="/rentalDetails/:id"
          exact
          component={RentalDetailsPage}
        />
        <PrivateRoute path="/rentals" exact component={RentalsPage} />
        <PrivateRoute path="/balance" exact component={BalancePage} />
        <PrivateRoute path="/settings" exact component={ProfilePage} />
        <PrivateRoute path="/settings/services" exact component={ProfilePage} />
        <PrivateRoute path="/adyen/cc" exact component={CCAdyenComponent} />

        <PrivateRoute
          path="/dashboard"
          exact
          component={DesktopDashboardContainer}
        />
        <PrivateRoute path="/mobile" exact component={MobileSwitchContainer} />
        <Route path="/" exact component={LandingPage} />
        <Route path="/*" component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
};

export default AppRoute;
