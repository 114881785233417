import React from 'react';
import './VerifyEmailView.less';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import { isLocalizeAdded, LocalizePropType } from 'getaway-data-layer';
import { isDashboardScreen } from '../../../../utils';
import { TextField } from '@mui/material';
import ListItem from '../../../components/ListItem/ListItem';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Checkbox } from 'antd';

type Props = {
  localize: LocalizePropType;
  email: string;
  isEditingPhone: boolean;
  onClose: () => void;
  onSubmit: (code: string) => void;
  onPressSkip: () => void;
  skipCountdown: number;
  onPressEmail: () => void;
  isVerifyingGDPREmail: boolean;
  code: string;
  setCode: (arg: any) => void;
  isEmailVerified: boolean;
  marketingConsentGiven: boolean;
  marketingConsentRequired: boolean;
  onPressMarketingConsent: () => void;
  onResendPressed: () => void;
};

const VerifyEmailView = ({
  localize,
  email,
  onClose,
  onSubmit,
  onPressSkip,
  skipCountdown,
  onPressEmail,
  isVerifyingGDPREmail,
  isEditingPhone,
  code,
  setCode,
  isEmailVerified,
  marketingConsentGiven,
  marketingConsentRequired,
  onPressMarketingConsent,
  onResendPressed,
}: Props) => {
  const activateSkip = skipCountdown <= 0 || isDashboardScreen();

  const processInput = (e: any) => {
    const num = e.target.value;
    if (/[^0-9]/.test(num)) return;
    if (num?.length === 4) {
      e.clipboardData = {
        getData: () => num.trim(),
      };
      onPaste(e);
    } else {
      if (num?.length <= 4) {
        setCode(num);
      }
    }
  };

  const onPaste = (e: any) => {
    const num = e?.clipboardData?.getData('text');
    if (/^\d+$/.test(num) && num?.length === 4) {
      setCode(num);
      onSubmit(num);
    }
  };

  return (
    <div className="screen-container">
      <NavigationBar
        TitleTestId="VerifyEmailView-title"
        DescriptionTestId="VerifyEmailView-description"
        title={localize('wj.email.verify.title')}
        description={localize('wj.email.verify.desc', email)}
        showHelp
        showArrowBack={isDashboardScreen()}
        onClose={onClose}
      />
      <div
        className={
          isLocalizeAdded(localize, 'email.verify.faq')
            ? 'screen-body-faq'
            : 'screen-body'
        }
      >
        <div
          className={
            isLocalizeAdded(localize, 'email.verify.faq')
              ? 'screen-body-content-faq'
              : 'screen-body-content'
          }
        >
          <ListItem
            title={email || ''}
            leftIcon="email"
            rightIcon={isEmailVerified ? undefined : 'edit'}
            rightIconSize={17}
            onClick={isEmailVerified ? undefined : onPressEmail}
          />
          <div className="code-input">
            <TextField
              data-testid="VerifyEmailView-otp"
              id="otp"
              sx={{ width: 1 }}
              label={localize('wj.email.code.placeholder')}
              variant="standard"
              size="small"
              type="number"
              value={code}
              name="one-time-code"
              autoComplete="one-time-code"
              inputProps={{
                pattern: '[0-9]*',
                inputMode: 'numeric',
                maxLength: 4,
              }}
              onChange={(e) => processInput(e)}
              onPaste={(e) => onPaste(e)}
            />
          </div>

          {marketingConsentRequired &&
            !isVerifyingGDPREmail &&
            !isEditingPhone && (
              <div
                className="marketing-consent"
                onClick={onPressMarketingConsent}
              >
                <Checkbox
                  style={{ marginRight: 8 }}
                  checked={marketingConsentGiven}
                />
                <Markdown rehypePlugins={[rehypeRaw]}>
                  {localize('marketing.consent')}
                </Markdown>
              </div>
            )}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!isVerifyingGDPREmail && (
              <div
                className="verify-email-skip-container"
                onClick={activateSkip ? onPressSkip : () => {}}
              >
                <div
                  className={
                    activateSkip
                      ? 'verify-email-skip-active'
                      : 'verify-email-skip'
                  }
                >
                  {activateSkip
                    ? localize('email.verify.skip')
                    : `${localize('email.verify.skip')} (${skipCountdown}s)`}
                </div>
              </div>
            )}
            {isDashboardScreen() && (
              <div className="verify-phone-btn-container">
                <div
                  className={'verify-phone-btn-txt-active'}
                  onClick={onResendPressed}
                >
                  {localize('phone.resend.code')}
                </div>
              </div>
            )}
          </div>
        </div>
        {isLocalizeAdded(localize, 'email.verify.faq') && (
          <div className="faq">
            <Markdown rehypePlugins={[rehypeRaw]}>
              {localize('email.verify.faq')}
            </Markdown>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyEmailView;
