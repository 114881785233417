import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  addPayment,
  getUser,
  LocalizePropType,
  schufaCheck,
  selectUser,
  withTextLocalizer,
  setBDay,
  selectDateOfBirth,
  selectIban,
  selectBIC,
  setIban,
  isKYCApp,
  setNotification,
  APP,
  selectBicMandatory,
  UserDataOutput,
  selectAllowedSEPACountries,
  setBIC,
} from 'getaway-data-layer';
import { useFormik } from 'formik';
import {
  GA_TITLES,
  getAddressStatus,
  isDashboardScreen,
  isIDVerificationRequired,
  navigateTo,
  reportGAEvent,
  showAlert,
} from '../../../../../utils';
import OptimizedSepaView from './OptimizedSepaView';
import {
  AddSepaModal,
  AddSepaSchema,
  maxBirthDate,
  minBirthDate,
} from '../../../../../validation';
import { format, isValid } from 'date-fns';
import { BANK_OWNER_STATUS } from '../../../../../enums';

interface Values {
  iban: string;
  bic: string;
  dateOfBirth: any;
  firstName: string;
  lastName: string;
}

type Props = {
  localize: LocalizePropType;
};

const USER_UPDATER_DURATION = 10000;

const OptimizedSepaContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user: any = useSelector(selectUser);
  const reduxDateOfBirth: any = useSelector(selectDateOfBirth);
  const iban: any = useSelector(selectIban);
  const bic: any = useSelector(selectBIC);
  const bicMandatory: any = useSelector(selectBicMandatory);
  const allowedSEPACountries: any = useSelector(selectAllowedSEPACountries);
  const addressStatus = getAddressStatus(user);
  const dateFormat = 'dd/MM/yyyy';
  const [bankOwnerStatus, setBankOwnerStatus] = useState(
    user?.userType === 'org' || !!user?.identityApprovedAt
      ? BANK_OWNER_STATUS.APPROVED
      : BANK_OWNER_STATUS.OPEN
  ) as any;

  const [expanded, setExpanded] = useState(false);
  const [customError, setCustomError] = useState('') as any;

  const onExpandPressed = (value: boolean) => {
    setExpanded(value);
  };

  const onSuccess = (user = null) => {
    if (user?.deposit?.toPayForSepaUnlock !== undefined && !user?.blocked) {
      // const newWindow = window.open('', '_blank');
      // navigateTo(history, '/settings');
      // dispatch(
      //   generateDepositPaymentLink(
      //     user?.deposit?.toPayForSepaUnlock,
      //     `${window.location.origin}/settings`,
      //     (paymentLink: string) => {
      //       if (newWindow) {
      //         // Redirect the new window to the payment link
      //         newWindow.location.href = paymentLink;
      //       }
      //     }
      //   )
      // );
      navigateTo(history, '/settings');
    } else if (isDashboardScreen() || isKYCApp()) {
      navigateTo(history, '/settings');
    } else {
      if (isIDVerificationRequired(user)) {
        navigateTo(history, `/onboarding/IDOnboarding`);
      } else {
        navigateTo(history, '/onboarding/DLOnboarding');
      }
    }
    reportGAEvent(GA_TITLES.ON_BOARDING.SEPA_SUBMITTED);
  };

  const onSubmit = (values: Values) => {
    if (bankOwnerStatus !== BANK_OWNER_STATUS.APPROVED) {
      setBankOwnerStatus(BANK_OWNER_STATUS.ERROR);
      return;
    }
    const formattedBirth = values?.dateOfBirth
      ? format(new Date(values.dateOfBirth), 'yyyy-MM-dd')
      : null;
    const sepa = {
      iban: values.iban.replace(/\s/g, ''),
      accountHolder: `${values.firstName.trim()} ${values.lastName.trim()}`,
      bic: values.bic.replace(/\s/g, ''),
    };
    dispatch(
      addPayment(
        {
          sepa,
          userInput: {
            allowCreditCheck: true,
            firstName: values.firstName.trim(),
            lastName: values.lastName.trim(),
            ...(formattedBirth && { dateOfBirth: formattedBirth.trim() }),
            ...(user?.address?.streetName && {
              address: {
                streetName: user.address?.streetName?.trim(),
                streetNumber: user.address?.streetNumber?.trim(),
                city: user.address?.city?.trim(),
                postalCode: user.address?.postalCode?.trim(),
                country: user.address?.country?.trim(),
              },
            }),
          },
        },
        () => {
          onSuccess();
        },
        () => {},
        () => {
          setTimeout(() => navigateTo(history, '/login'), 100);
        }
      )
    );
  };

  const onClose = () => {
    if (isDashboardScreen() || !isKYCApp()) {
      history.goBack();
    } else {
      showAlert(
        '',
        localize('cancel.FirstSteps.dialog.message'),
        localize('cancel.FirstSteps.dialog.yes'),
        () => navigateTo(history, '/settings'),
        localize('cancel.FirstSteps.dialog.no'),
        () => {},
        true
      );
    }
  };

  const onPressAddress = () => {
    navigateTo(history, '/dashboard/addAddress');
  };

  const onPressAddressInProgress = () => {
    navigateTo(history, '/dashboard/addAddress');
  };

  const onPressAddressCompleted = () => {};

  const AddSepaFormik = useFormik({
    initialValues: AddSepaModal(user, reduxDateOfBirth, iban, bic),
    validationSchema: AddSepaSchema(
      localize,
      user,
      iban,
      bicMandatory,
      allowedSEPACountries
    ),
    onSubmit,
  });

  const onIbanChanged = (e: any) => {
    const valueWithSpaces = e.target.value
      .toUpperCase()
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
    dispatch(setIban(valueWithSpaces));
    AddSepaFormik.setFieldValue('iban', valueWithSpaces, true);
  };

  const onBicChange = (e: any) => {
    const value = e.target.value?.trim();
    dispatch(setBIC(value));
    AddSepaFormik.setFieldValue('bic', value, true);
  };

  useEffect(() => {
    if (isValid(AddSepaFormik.values.dateOfBirth))
      dispatch(setBDay(AddSepaFormik?.values?.dateOfBirth));
  }, [AddSepaFormik.values.dateOfBirth]);

  useEffect(() => {
    let userUpdaterId;
    if (isDashboardScreen()) {
      dispatch(getUser());
    } else {
      dispatch(getUser());
      userUpdaterId = setInterval(() => {
        dispatch(getUser());
      }, USER_UPDATER_DURATION);
    }
    return () => {
      if (userUpdaterId) clearInterval(userUpdaterId);
    };
  }, []);

  return (
    <>
      <OptimizedSepaView
        localize={localize}
        user={user}
        customError={customError || AddSepaFormik.errors.dateOfBirth}
        formik={AddSepaFormik}
        onClose={onClose}
        dateFormat={dateFormat}
        minDate={minBirthDate}
        maxDate={maxBirthDate}
        expanded={expanded}
        addressStatus={addressStatus}
        address={user?.address}
        bicMandatory={bicMandatory}
        bankOwnerStatus={bankOwnerStatus}
        setBankOwnerStatus={setBankOwnerStatus}
        isIdentityApproved={!!user?.identityApprovedAt}
        onExpandPressed={onExpandPressed}
        onIbanChanged={onIbanChanged}
        onBicChange={onBicChange}
        onPressAddress={onPressAddress}
        onPressAddressInProgress={onPressAddressInProgress}
        onPressAddressCompleted={onPressAddressCompleted}
      />
    </>
  );
};

export default withTextLocalizer(OptimizedSepaContainer);
