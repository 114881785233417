import { setIsKeyboardOpened } from 'getaway-data-layer';
import { store } from '../redux/config';

export const attachInputListeners = () => {
  const inputs = document?.getElementsByTagName('input');
  if (inputs) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < inputs?.length; i++) {
      const input = inputs[i];

      // Skip if the input type is 'checkbox'
      if (input.type.toLowerCase() === 'checkbox') {
        continue;
      }

      input.addEventListener('focus', () => {
        store.dispatch(setIsKeyboardOpened(true));
      });

      input.addEventListener('blur', () => {
        store.dispatch(setIsKeyboardOpened(false));
      });
    }
  }
};

export const removeInputListeners = () => {
  store.dispatch(setIsKeyboardOpened(false));
  const inputs = document?.getElementsByTagName('input');
  if (inputs) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < inputs?.length; i++) {
      inputs[i].removeEventListener('focus', () => {});
      inputs[i].removeEventListener('blur', () => {});
    }
  }
};
