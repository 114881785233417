import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import './AddPhoneView.less';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import BrandButton from '../../../components/BrandButton/BrandButton';
import { GA_TITLES, isDashboardScreen } from '../../../../utils';
import { PhoneItem } from './AddPhoneContainer';
import {
  isLocalizeAdded,
  LocalizePropType,
  PhoneCode,
} from 'getaway-data-layer';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

type Props = {
  localize: LocalizePropType;
  phoneNumber: PhoneItem;
  onSubmit: () => void;
  onClose: () => void;
  isValidNumber: () => void;
  phoneCodes: Array<PhoneCode>;
  onPhoneNumberChanged: (value: string, data: any) => void;
};

const AddPhoneView = ({
  localize,
  phoneNumber,
  isValidNumber,
  phoneCodes,
  onPhoneNumberChanged,
  onSubmit,
  onClose,
}: Props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        TitleTestId="AddPhoneView-title"
        DescriptionTestId="AddPhoneView-description"
        title={localize('phone.verify.title')}
        description={localize('phone.verify.desc')}
        showHelp
        showArrowBack={isDashboardScreen()}
        onClose={onClose}
      />
      <div
        className={
          isLocalizeAdded(localize, 'phone.faq')
            ? 'screen-body-faq'
            : 'screen-body'
        }
      >
        <div
          className={
            isLocalizeAdded(localize, 'phone.faq')
              ? 'screen-body-content-faq'
              : 'screen-body-content'
          }
        >
          <form style={{ width: '100%' }}>
            <PhoneInput
              country="de"
              specialLabel={localize('phone.verify.mobile.phone.text')}
              onlyCountries={phoneCodes?.map((phoneCode) =>
                phoneCode?.country?.toLowerCase()
              )}
              value={phoneNumber.value}
              isValid={() => !phoneNumber.error}
              onBlur={isValidNumber}
              onChange={onPhoneNumberChanged}
              inputClass="add-phone-input"
              inputProps={{
                'data-testid': 'AddPhoneView-addPhone',
                id: 'number',
                name: 'phone',
                required: true,
                autoComplete: 'tel',
              }}
            />
            <p className="add-phone-error noselect">{phoneNumber.error}</p>
          </form>
          <BrandButton
            id={GA_TITLES.ON_BOARDING.ADD_PHONE}
            title={localize('phone.verify.send.code')}
            onClick={onSubmit}
          />
        </div>
      </div>
      {isLocalizeAdded(localize, 'phone.faq') && (
        <div className="faq">
          <Markdown rehypePlugins={[rehypeRaw]}>
            {localize('phone.faq')}
          </Markdown>
        </div>
      )}
    </div>
  );
};

export default AddPhoneView;
