import * as React from 'react';
import './EmptyIcon.less';
import BrandIcon from '../BrandIcon/BrandIcon';
import { LocalizePropType, withTextLocalizer } from 'getaway-data-layer';

type Props = {
  localize: LocalizePropType;
};

const EmptyIcon = ({ localize }: Props) => (
  <div className="empty-icon-container">
    <BrandIcon
      name="empty"
      style={{ width: 30, height: 30, marginBottom: 4 }}
    />
    <div className="empty-label">{localize('empty')}</div>
  </div>
);

EmptyIcon.defaultProps = {};

export default withTextLocalizer(EmptyIcon);
