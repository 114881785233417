import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { isKYCApp, selectAuth, selectUser } from 'getaway-data-layer';
import { useSelector } from 'react-redux';

const NotFoundContainer = () => {
  const authUser = useSelector(selectUser);
  const basicAuth = useSelector(selectAuth);
  const user = authUser && basicAuth;
  const history = useHistory();

  return user ? (
    <Redirect to={{ pathname: isKYCApp() ? '/settings' : '/map' }} />
  ) : (
    <Redirect
      to={{
        pathname: isKYCApp() ? '/' + history?.location?.search : '/map',
      }}
    />
  );
};

export default NotFoundContainer;
