import React from 'react';
import './SlideItem.less';

type Props = {
  title: string;
  description: string;
  imageSrc: any;
  extraComponents?: any;
  titleTestId?: string;
  descriptionTestId?: string;
};

const SlideItem = ({
  titleTestId,
  descriptionTestId,
  title,
  description,
  imageSrc,
  extraComponents,
}: Props) => {
  return (
    <div style={{ height: window.innerHeight }} className="slide-item-wrapper">
      {extraComponents}
      <img
        data-testid="SlideItem-img"
        className="slide-item-img"
        src={imageSrc}
        alt={imageSrc}
      />
      <div data-testid={titleTestId} className="slide-item-title">
        {title}
      </div>
      <div data-testid={descriptionTestId} className="slide-item-description">
        {description}
      </div>
    </div>
  );
};

export default SlideItem;
