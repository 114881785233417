import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  withTextLocalizer,
  initJumioForWeb,
  selectJumioRedirectData,
  LocalizePropType,
  JumioWebDocumentType,
  selectUser,
  isKYCApp,
  setNotification,
  APP,
  selectShowExternalBrowserScreen,
  initJumioForMobile,
  selectBrandSettings,
  selectDeviceLanguage,
} from 'getaway-data-layer';
import {
  isDashboardScreen,
  isDLVerificationRequired,
  navigateTo,
  showAlert,
} from '../../../../../utils';
import IDOnboardingView from './IDOnboardingView';
import { IDModal, IDSchema } from '../../../../../validation';
import { useFormik } from 'formik';
import ExternalBrowser from '../../../../components/ExternalBrowser/ExternalBrowser';
import { useQuery } from '../../../../../hooks/useQuery';

type Props = {
  localize: LocalizePropType;
};

interface Values {
  documentType: string;
}

const IDOnboardingContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const utm = query?.get('utm_source')?.toLowerCase();
  const jumioRedirectData: any = useSelector(selectJumioRedirectData);
  const user = useSelector(selectUser);
  const brandSettings = useSelector(selectBrandSettings);
  const showExternalBrowserScreen = useSelector(
    selectShowExternalBrowserScreen
  );
  const language = useSelector(selectDeviceLanguage);
  const scanEnabled = user?.remainingIDSubmissionAttempts > 0;

  const navigateToJumioScan = (data) => {
    if (!data?.redirectUrl) return;

    const jumioUrl = new URL(data.redirectUrl);

    jumioUrl.searchParams.set(
      'locale',
      language === 'deformal' ? 'de' : language
    );
    window.location.href = jumioUrl.toString();
  };

  const navigateToScan = (url) => {
    const jumioUrl = new URL(url);

    jumioUrl.searchParams.set(
      'locale',
      language === 'deformal' ? 'de' : language
    );
    window.location.href = jumioUrl.toString();
  };

  const onPressStart = (values: Values) => {
    const redirectUrl = `${window?.location?.origin}${
      isDashboardScreen()
        ? '/settings'
        : isKYCApp()
        ? isDLVerificationRequired(user)
          ? '/onboarding/DLOnboarding'
          : '/onboarding/optimizedSepa'
        : '/map'
    }`;
    if (scanEnabled) {
      if (brandSettings?.kycSettings?.jumioWebV4Enabled) {
        dispatch(
          initJumioForMobile(
            IDFormik.values?.documentType === 'ID_CARD'
              ? ['ID_CARD', 'PASSPORT']
              : ['PASSPORT', 'ID_CARD'],
            (res) => {
              if (res?.web?.href) navigateToScan(res.web.href);
            },
            redirectUrl,
            redirectUrl
          )
        );
      } else {
        dispatch(
          initJumioForWeb(
            localize,
            history,
            values.documentType as JumioWebDocumentType,
            jumioRedirectData?.transactionReference,
            user?.id,
            isDashboardScreen(),
            isKYCApp(),
            isKYCApp(),
            (data) => {
              navigateToJumioScan(data);
            },
            redirectUrl,
            redirectUrl
          )
        );
      }
    }
  };

  const onClose = () => {
    if (isDashboardScreen()) {
      navigateTo(history, '/settings');
    } else {
      showAlert(
        '',
        localize('cancel.FirstSteps.dialog.message'),
        localize('cancel.FirstSteps.dialog.yes'),
        () => navigateTo(history, '/settings'),
        localize('cancel.FirstSteps.dialog.no'),
        () => {},
        true
      );
    }
  };

  const onClickDisabled = () => {
    dispatch(
      setNotification({
        message: 'user.scan.attempts.out',
        type: APP.NOTIFICATION_TYPE.ERROR,
      })
    );
  };

  const IDFormik = useFormik({
    initialValues: IDModal,
    validationSchema: IDSchema(localize),
    onSubmit: onPressStart,
  });

  return (
    <>
      {showExternalBrowserScreen && utm?.includes('app') ? (
        <ExternalBrowser localize={localize} onClose={onClose} />
      ) : (
        <IDOnboardingView
          localize={localize}
          scanEnabled={scanEnabled}
          onClose={onClose}
          formik={IDFormik}
          onClickDisabled={onClickDisabled}
        />
      )}
    </>
  );
};

export default withTextLocalizer(IDOnboardingContainer);
