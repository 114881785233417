import React from 'react';
import './ProfileView.less';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import {
  LocalizePropType,
  isKYCApp,
  UserDataOutput,
  TransactionData,
  formatMoneyEuro,
} from 'getaway-data-layer';
import ToggleTab from '../../../components/ToggleTab/ToggleTab';
import AccountTabContainer from './AccountTab/AccountTabContainer';
import ServicesTabContainer from './ServicesTab/ServicesTabContainer';
import { PROFILE_TABS } from '../../../../enums/Profile';
import { Divider } from '@mui/material';
import BrandIcon from '../../../components/BrandIcon/BrandIcon';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

type Props = {
  localize: LocalizePropType;
  user: UserDataOutput;
  openTransactions: TransactionData[];
  onClose: () => void;
  activeTab: string;
  setActiveTab: (arg: string) => void;
  enableServicesTab: boolean;
  onClickOpenInvoice: (url: string) => void;
  onClickPayOpenInvoice: (receiptNo: any) => void;
  onDuplicateLogin: () => void;
};

const ProfileView = ({
  localize,
  user,
  openTransactions,
  onClose,
  activeTab,
  setActiveTab,
  enableServicesTab,
  onClickOpenInvoice,
  onClickPayOpenInvoice,
  onDuplicateLogin,
}: Props) => {
  const blockedButOriginalAccountActive =
    user?.blockData?.reason === 'd' &&
    user?.blockData?.originalEmail &&
    !user?.blockData?.originalReason;

  const blockedDialog = (['f', 'd'].includes(user?.blockData?.reason) ||
    ['cC', 'pDr', 'pDe']?.includes(user?.blockData?.details)) && (
    <div className="profile-account-warning">
      <div className="profile-account-warning-title">
        {localize(
          blockedButOriginalAccountActive
            ? 'profile.blocked.title.originalactive'
            : `profile.blocked.title.${user?.blockData?.reason}`
        )}
      </div>
      <div className="profile-account-warning-msg">
        <Markdown rehypePlugins={[rehypeRaw]}>
          {localize(
            blockedButOriginalAccountActive
              ? 'profile.blocked.msg.originalactive'
              : ['cC', 'pDr', 'pDe'].includes(user?.blockData?.details)
              ? `profile.blocked.msg.${user?.blockData?.details}`
              : `profile.blocked.msg.${user?.blockData?.reason}`
          )}
        </Markdown>
      </div>

      {blockedButOriginalAccountActive && (
        <div className="profile-account-warning-row">
          <button
            className="profile-account-warning-btn"
            onClick={onDuplicateLogin}
          >
            {localize('profile.blocked.button.login')}
          </button>
          {user?.blockData?.originalEmail}
        </div>
      )}
    </div>
  );

  const openTransactionUI = openTransactions?.length > 0 && (
    <div className="profile-tx-warning">
      <div className="profile-tx-warning-title">
        {localize(`profile.tx.open.title`)}
      </div>
      {openTransactions?.map((tx, index) => (
        <>
          <div key={tx?.id} className="profile-account-tx">
            <div
              className="profile-account-tx-nr"
              // @ts-ignore*
              onClick={() => onClickOpenInvoice(tx?.invoiceLink)}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src="/images/invoice.png"
                  alt="invoice"
                  style={{ width: 20, height: 20, marginRight: '12px' }}
                />
                {tx.receiptNo}
              </div>
              <BrandIcon name="search" style={{ width: 16, height: 16 }} />
            </div>
            <div
              className="profile-account-tx-action"
              onClick={() => onClickPayOpenInvoice(tx?.id)}
            >
              <div className="profile-account-tx-pay">
                {localize('profile.tx.open.pay')}
              </div>
              <div className="profile-account-tx-amount-container">
                <div className="profile-account-tx-amount">
                  {formatMoneyEuro(tx?.unpaidAmount || tx?.amount)}
                </div>
                <BrandIcon
                  name="arrowRight"
                  style={{ width: 16, height: 16, marginLeft: '8px' }}
                />
              </div>
            </div>
          </div>
          {openTransactions?.length > 1 &&
            index !== openTransactions?.length - 1 && <Divider />}
        </>
      ))}
    </div>
  );

  return (
    <div className="screen-container">
      <NavigationBar
        TitleTestId="ProfileView-title"
        DescriptionTestId="ProfileView-description"
        title={localize('dashboard.profile')}
        description=""
        showHelp
        onClose={isKYCApp() ? undefined : onClose}
      />
      <div
        className="screen-body"
        style={enableServicesTab ? { paddingTop: 24 } : {}}
      >
        {blockedDialog}
        {openTransactionUI}
        {enableServicesTab && (
          <ToggleTab
            localize={localize}
            optionOne={localize(`profile.tab.services`)}
            optionTwo={localize(`profile.tab.account`)}
            isOptionOneSelected={activeTab !== PROFILE_TABS.ACCOUNT}
            onToggleValueChange={() => {
              if (activeTab === PROFILE_TABS.ACCOUNT) {
                setActiveTab(PROFILE_TABS.SERVICES);
              } else {
                setActiveTab(PROFILE_TABS.ACCOUNT);
              }
            }}
            hideOR
          />
        )}
        {activeTab === PROFILE_TABS.ACCOUNT && <AccountTabContainer />}
        {activeTab === PROFILE_TABS.SERVICES && <ServicesTabContainer />}
      </div>
    </div>
  );
};

export default ProfileView;
