import { v4 as uuidv4 } from 'uuid';
import { APP } from '../../../../enums';
import { getBrandLanguage, isBrand } from '../../../../helpers/brandHelpers';
import TYPES from '../../../../@types/redux/store/NotificationTypes';
import {
  Dispatch,
  GetState,
  DismissNotification,
  ClearNotifications,
  TextGroup,
} from '../../../../@types';
import { localize as localizer } from '../../../../helpers/localize';

const NOTIFICATION_DURATION = 6000;
export const clearNotifications = (): ClearNotifications => ({
  type: TYPES.CLEAR_NOTIFICATIONS,
});
export const dismissNotification = (id: string): DismissNotification => ({
  type: TYPES.DISMISS_NOTIFICATION,
  payload: {
    id,
  },
});
type Notification = {
  message?: string;
  title?: string | null | undefined;
  actionText?: string | null | undefined;
  actionCallback?: (() => void) | null | undefined;
  dismissCallback?: (() => void) | null | undefined;
  autoDismissCallback?: () => void;
  type?: any;
  localize?: boolean;
  autoDismiss?: boolean;
  duration?: number;
};
export const setNotification =
  ({
    message,
    title = null,
    actionText = null,
    actionCallback = () => {},
    dismissCallback = () => {},
    autoDismissCallback = () => {},
    type = APP.NOTIFICATION_TYPE.INFO,
    localize = true,
    autoDismiss = true,
    duration = NOTIFICATION_DURATION,
  }: Notification) =>
  (dispatch: Dispatch, getState: GetState) => {
    let messageDisplay = message;
    let titleDisplay = title;
    let actionTextDisplay = actionText;

    if (localize) {
      const {
        appData: { texts },
        userData: {
          user: { user },
        },
        device: { language, deviceUUID },
      } = getState();
      const dictionary = {
        ...texts,
        language: language,
        group: 'default' as TextGroup,
      };
      const uniqueId = user?.id! || deviceUUID;

      if (message) {
        messageDisplay = localizer(dictionary, uniqueId, message);
      }

      if (title) {
        titleDisplay = localizer(dictionary, uniqueId, title);
      }

      if (actionText) {
        actionTextDisplay = localizer(dictionary, uniqueId, actionText);
      }
    }

    const id = uuidv4();
    dispatch({
      type: TYPES.SET_NOTIFICATION,
      payload: {
        id,
        type,
        message: messageDisplay,
        title: titleDisplay,
        actionText: actionTextDisplay,
        actionCallback,
        dismissCallback,
      },
    });

    if (autoDismiss) {
      setTimeout(() => {
        const notificationsIds = getState().ui.notifications.map(
          (notification: { id: string }) => notification.id
        );

        if (notificationsIds.includes(id)) {
          dispatch(dismissNotification(id));
          autoDismissCallback();
        }
      }, duration);
    }
  };
