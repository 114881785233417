import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeviceUUID } from 'device-uuid';
import {
  setApiBaseURL,
  setApiVersion,
  setXBrandId,
  setDeviceUUID,
  setPlatform,
  selectDeviceUUID,
  setCardState,
  setIsKeyboardOpened,
  setLanguage,
  selectDeviceLanguage,
  getBrandLanguage,
  selectBrandSettings,
} from 'getaway-data-layer';
import config from '../env.config';

type props = {
  children: React.ReactElement;
};

const Setup = ({ children }: props) => {
  const dispatch = useDispatch();
  const deviceUUID = useSelector(selectDeviceUUID);
  const language = useSelector(selectDeviceLanguage);
  const brandSettings = useSelector(selectBrandSettings);
  const allowedLanguages = brandSettings?.allowedLanguages || [];

  useEffect(() => {
    dispatch(setIsKeyboardOpened(false));
    dispatch(setPlatform('browser'));
    dispatch(setApiVersion(config.api.apiVersion));
    dispatch(setXBrandId(config.brand.xBrandId!));
    dispatch(setApiBaseURL(config.api.baseURL));
    dispatch(setCardState('Expanded'));
    if (!language && brandSettings) {
      dispatch(
        setLanguage(
          navigator?.language
            ? navigator.language.slice(0, 2) === 'de'
              ? getBrandLanguage(config.brand.xBrandId)
              : navigator.language.slice(0, 2) &&
                allowedLanguages?.includes(
                  navigator.language?.toLowerCase()?.slice(0, 2)
                )
              ? navigator.language.slice(0, 2)
              : getBrandLanguage(config.brand.xBrandId)
            : getBrandLanguage(config.brand.xBrandId)
        )
      );
    }
    if (!deviceUUID) {
      dispatch(setDeviceUUID(new DeviceUUID().get()));
    }
  }, [brandSettings?.allowedLanguages]);

  return children;
};

export default Setup;
