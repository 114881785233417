/* eslint-disable no-undef */
import { Texts, Language, TextGroup, Languages } from '../@types';
import { isWeb } from './platformHelpers';
import { injectedDimensions, injectedSentry } from '../redux/ConfigureGlobals';

type Dictionary = Texts & {
  group: TextGroup;
  language: Language;
};

export const isReportingMissingTextsEnabled = (userId: string): boolean => {
  const randomHex = Math.floor(Math.random() * 16 + 1).toString(16);
  return userId?.endsWith(randomHex);
};

export const interpolateVariables = (
  template: string,
  params: Array<string>
): string => {
  const matcher = /(%s|%@|%d)/;
  return params.reduce(
    (stringBuilder, param) => stringBuilder.replace(matcher, param),
    template
  );
};
export const localize = (
  dictionary: Dictionary,
  uniqueId: string,
  key: string = '',
  ...params: Array<string>
) => {
  if (
    !dictionary ||
    !dictionary?.group ||
    !dictionary?.language ||
    !dictionary?.[dictionary?.group]
  ) {
    // @ts-ignore
    if (!isWeb() && __DEV__)
      // eslint-disable-next-line no-console
      console.error('Localization dictionary is not found');
    return key;
  }
  const languages: Languages = dictionary?.[dictionary?.group];
  const values = languages?.[dictionary?.language];
  const value = values?.[key];
  const localization = value && interpolateVariables(value, params);

  if (!localization) {
    // @ts-ignore
    if (!isWeb() && __DEV__) {
      if (key === '') {
        // eslint-disable-next-line no-console
        console.error('No text key provided to localization function');
      } else {
        const consoleMethod = injectedDimensions?.get('window')
          ?.showLocalizationWarnings
          ? 'warn'
          : 'log';
        // eslint-disable-next-line no-console
        console[consoleMethod](`Localization not found for text key "${key}"`);
      }
    }
    // else if (isReportingMissingTextsEnabled(uniqueId)) {
    // injectedSentry?.captureException(
    //   `Localization not found for text key "${key}"`
    // );
    // }

    return key;
  }

  return localization;
};
