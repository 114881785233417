import React from 'react';
import Hyperlink from 'react-native-hyperlink';
import {
  getBrandColor,
  getBrandName,
  LocalizePropType,
  selectIsKeyboardOpened,
  isLocalizeAdded,
  LegalEntityData,
  ManagingDirector,
} from 'getaway-data-layer';
import './OrgSignupView.less';
import { FormikValues } from 'formik';
import Box from '@mui/material/Box';
import {
  IconButton,
  InputAdornment,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import BrandButton from '../../components/BrandButton/BrandButton';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import config from '../../../env.config';
import { GA_TITLES, isMobileBrowser } from '../../../utils';
import { LEGAL_TYPES } from '../../../validation';
import { useSelector } from 'react-redux';
import { Checkbox, Spin } from 'antd';
import { PRIVACY_POLICY_STATUS } from '../../../enums/PrivacyPolicy';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Slider from 'react-slick';
import CardItem from '../../components/CardItem/CardItem';
import { LoadingOutlined } from '@ant-design/icons';
import BrandIcon from '../../components/BrandIcon/BrandIcon';
import { format, parseISO } from 'date-fns';
import ListItem from '../../components/ListItem/ListItem';
import EmptyIcon from '../../components/EmptyIcon/EmptyIcon';

type props = {
  localize: LocalizePropType;
  activeSlide: number;
  afterSlide: (index: number) => void;
  formik: FormikValues;
  showPassword: boolean;
  onClose: () => void;
  showAboutDialog: () => void;
  handleClickShowPassword: () => void;
  handleMouseDownPassword: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onChangeLegalType: (event: React.MouseEvent<HTMLButtonElement>) => void;
  containerStyles: any;
  showBack: boolean;
  onPressLogin: () => void;
  privacyPolicyStatus: string;
  privacyPolicyConsentRequired: boolean;
  onPressPrivacyPolicyConsent: () => void;
  handleCompanyNameChange: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickIndividual: () => void;
  onClickOrg: () => void;
  onClickManualLinkOrg: () => void;
  onClickManualLinkMD: () => void;
  orgSearch: string;
  onChangeOrgSearch: (v: any) => void;
  searchOrgas: () => void;
  searchResults: Array<LegalEntityData>;
  searchLoading: boolean;
  searchError: string | null;
  onSelectOrg: (org: LegalEntityData) => void;
  selectedOrg: LegalEntityData;
  selectedOrgDetails: LegalEntityData;
  loadingOrgDetails: boolean;
  removeSelectedOrg: () => void;
  showIndividualDialog: boolean;
  openIndividualSignup: () => void;
  disableHorizontalScroll: boolean;
  slideBack: () => void;
  onSelectMd: (md: ManagingDirector) => void;
  selectedMd: ManagingDirector;
  removeMD: () => void;
};

const SLIDER_SETTING = {
  arrows: false,
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const OrgSignupView = ({
  localize,
  activeSlide,
  formik,
  showPassword,
  onClose,
  showAboutDialog,
  handleClickShowPassword,
  handleMouseDownPassword,
  onChangeLegalType,
  containerStyles,
  showBack,
  onPressLogin,
  privacyPolicyStatus,
  privacyPolicyConsentRequired,
  onPressPrivacyPolicyConsent,
  handleCompanyNameChange,
  onClickIndividual,
  onClickOrg,
  orgSearch,
  onChangeOrgSearch,
  searchOrgas,
  searchResults,
  searchLoading,
  onClickManualLinkOrg,
  onClickManualLinkMD,
  searchError,
  onSelectOrg,
  selectedOrg,
  selectedOrgDetails,
  loadingOrgDetails,
  removeSelectedOrg,
  showIndividualDialog,
  openIndividualSignup,
  disableHorizontalScroll,
  slideBack,
  onSelectMd,
  selectedMd,
  removeMD,
}: props) => {
  const isKeyboardOpened = useSelector(selectIsKeyboardOpened) as any;

  const individualDialog = (
    <div className="signup-warning">
      <div className="signup-warning-msg">
        {localize('signup.individual.dialog.msg')}
      </div>

      <button className="signup-warning-btn" onClick={openIndividualSignup}>
        {localize('signup.individual.dialog.btn')}
      </button>
    </div>
  );

  const TypeSelection = (
    <>
      <div className="header">{localize('signup.org.type.header')}</div>
      <CardItem
        localize={localize}
        title={localize('signup.card.org.title')}
        description={localize('signup.card.org.desc')}
        selected={false}
        hideRadio
        firstItem
        icon="/images/businessPayment.png"
        onClick={onClickOrg}
      />
      <CardItem
        localize={localize}
        title={localize('signup.individual.org.title')}
        description={localize('signup.individual.org.desc')}
        selected={false}
        hideRadio
        lastItem
        icon="/images/privatePayment.png"
        onClick={onClickIndividual}
      />
      {showIndividualDialog && individualDialog}
    </>
  );

  const OrgSearch = (
    <>
      <div onClick={slideBack}>
        <BrandIcon
          name="arrowLeftBlack"
          style={{ width: 16, height: 16, marginBottom: 3, cursor: 'pointer' }}
        />
      </div>
      <div className="header">{localize('signup.org.search.header')}</div>
      <TextField
        fullWidth
        sx={{ pr: 1 }}
        label={localize('signup.org.search.placeholder')}
        variant="standard"
        size="small"
        type="text"
        value={orgSearch}
        onChange={onChangeOrgSearch}
        helperText={(searchError && searchError) || ''}
        error={searchError && Boolean(searchError)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" onClick={searchOrgas}>
              <BrandIcon
                name="search"
                style={{ width: 16, height: 16, cursor: 'pointer' }}
              />
            </InputAdornment>
          ),
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            searchOrgas();
          }
        }}
      />
      {searchLoading && (
        <div className="org-signup-loader">
          <Spin indicator={loadingIcon} />
        </div>
      )}
      {!searchLoading && searchResults?.length === 0 && <EmptyIcon />}
      {!searchLoading && searchResults?.length > 0 && (
        <div className="org-signup-results">
          {searchResults?.map((org, index: number) => (
            <CardItem
              key={org?.name}
              localize={localize}
              title={org?.name}
              description={org?.court?.replace(/^District court\s+/i, '')}
              selected={false}
              hideRadio
              firstItem={index === 0}
              lastItem={index === searchResults.length - 1}
              icon="/images/businessPayment.png"
              onClick={() => onSelectOrg(org)}
            />
          ))}
        </div>
      )}

      <div className="org-signup-link" onClick={onClickManualLinkOrg}>
        <Markdown rehypePlugins={[rehypeRaw]}>
          {localize('signup.org.manual.link')}
        </Markdown>
      </div>
    </>
  );

  const ManagingDirectorSelection = (
    <>
      <div onClick={slideBack}>
        <BrandIcon
          name="arrowLeftBlack"
          style={{ width: 16, height: 16, marginBottom: 3, cursor: 'pointer' }}
        />
      </div>
      <div className="header">{localize('signup.org.director.header')}</div>
      {loadingOrgDetails && (
        <div className="org-signup-loader">
          <Spin indicator={loadingIcon} />
        </div>
      )}
      {!loadingOrgDetails &&
        selectedOrgDetails?.siData?.managingDirectors?.length === 0 && (
          <EmptyIcon />
        )}
      {!loadingOrgDetails &&
        selectedOrgDetails?.siData?.managingDirectors?.length > 0 && (
          <div className="org-signup-results" style={{ paddingTop: 0 }}>
            {selectedOrgDetails?.siData?.managingDirectors.map((md, index) => (
              <CardItem
                key={`${md.mdFirstname} ${md?.mdLastname}`}
                localize={localize}
                title={`${md.mdFirstname} ${md?.mdLastname}`}
                description={
                  md.mdBirthdate
                    ? format(parseISO(md.mdBirthdate), 'dd.MM.yyyy')
                    : null
                }
                selected={false}
                hideRadio
                firstItem={index === 0}
                lastItem={
                  index ===
                  selectedOrgDetails?.siData?.managingDirectors?.length - 1
                }
                icon="/images/privatePayment.png"
                onClick={() => onSelectMd(md)}
              />
            ))}
          </div>
        )}

      <div className="org-signup-link" onClick={onClickManualLinkMD}>
        <Markdown rehypePlugins={[rehypeRaw]}>
          {localize('signup.org.manual.link')}
        </Markdown>
      </div>
    </>
  );

  const ManualForm = (
    <Box
      component="form"
      noValidate
      onSubmit={formik.handleSubmit}
      style={{ height: '100%' }}
    >
      <div onClick={slideBack}>
        <BrandIcon
          name="arrowLeftBlack"
          style={{ width: 16, height: 16, marginBottom: 3, cursor: 'pointer' }}
        />
      </div>
      <>
        {selectedOrg ? (
          <>
            <div
              className="signup-section"
              style={{ marginTop: 0, marginBottom: '16px' }}
            >
              {localize('signup.org.info')}
            </div>
            <ListItem
              title={selectedOrg?.name}
              leftItem={
                <img
                  style={{
                    width: 20,
                    height: 20,
                    marginRight: 35,
                    objectFit: 'contain',
                  }}
                  src="/images/businessPayment.png"
                  alt="user"
                />
              }
              rightIcon="redTrash"
              rightIconSize={16}
              onClick={removeSelectedOrg}
            />
            {!selectedOrgDetails?.siData?.orgaType && (
              <>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="legalType-label">
                    {localize('signup.legal.type.placeholder')}
                  </InputLabel>
                  <Select
                    fullWidth
                    required
                    id="legalType"
                    variant="standard"
                    {...formik.getFieldProps('legalType')}
                    onChange={(event: any) => {
                      onChangeLegalType(event);
                    }}
                    style={{ margin: '10px 0' }}
                    inputProps={{
                      label: localize('signup.legal.type.placeholder'),
                    }}
                    label={localize('signup.legal.type.placeholder')}
                  >
                    {Object.values(LEGAL_TYPES)?.map((typ) => (
                      <MenuItem key={typ} value={typ}>
                        {typ}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {formik.values.legalType === LEGAL_TYPES.Sonstige && (
                  <>
                    <TextField
                      required
                      fullWidth
                      id="otherLegalType"
                      sx={{ pr: 1 }}
                      label={localize('signup.legal.other.placeholder')}
                      helperText={
                        (formik.touched.otherLegalType &&
                          formik.errors.otherLegalType) ||
                        ''
                      }
                      error={
                        formik.touched.otherLegalType &&
                        Boolean(formik.errors.otherLegalType)
                      }
                      variant="standard"
                      size="small"
                      type="text"
                      {...formik.getFieldProps('otherLegalType')}
                    />
                    {isLocalizeAdded(localize, 'signup.org.other.footnote') && (
                      <div className="other-footnote">
                        <Markdown rehypePlugins={[rehypeRaw]}>
                          {localize('signup.org.other.footnote')}
                        </Markdown>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            {!selectedOrgDetails?.siData?.registerNumber && (
              <TextField
                required
                id="registerNr"
                sx={{ width: 0.5, pr: 1 }}
                label={localize('signup.register.nr.placeholder')}
                helperText={
                  (formik.touched.registerNr && formik.errors.registerNr) || ''
                }
                error={
                  formik.touched.registerNr && Boolean(formik.errors.registerNr)
                }
                variant="standard"
                size="small"
                type="text"
                {...formik.getFieldProps('registerNr')}
              />
            )}
          </>
        ) : (
          <>
            <div
              className="signup-section"
              style={{ marginTop: 0, marginBottom: '16px' }}
            >
              {localize('signup.org.info')}
            </div>
            <TextField
              required
              fullWidth
              id="companyName"
              sx={{ pr: 1 }}
              label={localize('signup.company.name.placeholder')}
              helperText={
                (formik.touched.companyName && formik.errors.companyName) || ''
              }
              error={
                formik.touched.companyName && Boolean(formik.errors.companyName)
              }
              variant="standard"
              size="small"
              type="text"
              {...formik.getFieldProps('companyName')}
              onChange={handleCompanyNameChange}
            />
            <FormControl fullWidth variant="standard">
              <InputLabel id="legalType-label">
                {localize('signup.legal.type.placeholder')}
              </InputLabel>
              <Select
                fullWidth
                required
                id="legalType"
                variant="standard"
                {...formik.getFieldProps('legalType')}
                onChange={(event: any) => {
                  onChangeLegalType(event);
                }}
                style={{ margin: '10px 0' }}
                inputProps={{
                  label: localize('signup.legal.type.placeholder'),
                }}
                label={localize('signup.legal.type.placeholder')}
              >
                {Object.values(LEGAL_TYPES)?.map((typ) => (
                  <MenuItem key={typ} value={typ}>
                    {typ}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {formik.values.legalType === LEGAL_TYPES.Sonstige && (
              <>
                <TextField
                  required
                  fullWidth
                  id="otherLegalType"
                  sx={{ pr: 1 }}
                  label={localize('signup.legal.other.placeholder')}
                  helperText={
                    (formik.touched.otherLegalType &&
                      formik.errors.otherLegalType) ||
                    ''
                  }
                  error={
                    formik.touched.otherLegalType &&
                    Boolean(formik.errors.otherLegalType)
                  }
                  variant="standard"
                  size="small"
                  type="text"
                  {...formik.getFieldProps('otherLegalType')}
                />
                {isLocalizeAdded(localize, 'signup.org.other.footnote') && (
                  <div className="other-footnote">
                    <Markdown rehypePlugins={[rehypeRaw]}>
                      {localize('signup.org.other.footnote')}
                    </Markdown>
                  </div>
                )}
              </>
            )}
            <TextField
              required
              id="registerNr"
              sx={{ width: 0.5, pr: 1 }}
              label={localize('signup.register.nr.placeholder')}
              helperText={
                (formik.touched.registerNr && formik.errors.registerNr) || ''
              }
              error={
                formik.touched.registerNr && Boolean(formik.errors.registerNr)
              }
              variant="standard"
              size="small"
              type="text"
              {...formik.getFieldProps('registerNr')}
            />
            <TextField
              required
              id="registerLocation"
              sx={{ width: 0.5 }}
              label={localize('signup.register.location.placeholder')}
              helperText={
                (formik.touched.registerLocation &&
                  formik.errors.registerLocation) ||
                ''
              }
              error={
                formik.touched.registerLocation &&
                Boolean(formik.errors.registerLocation)
              }
              variant="standard"
              size="small"
              type="text"
              {...formik.getFieldProps('registerLocation')}
            />
          </>
        )}
        <div className="signup-section" style={{ marginBottom: '16px' }}>
          {localize('signup.personal.section')}
        </div>
        {selectedMd ? (
          <div style={{ marginBottom: '8px' }}>
            <ListItem
              title={`${selectedMd?.mdFirstname} ${selectedMd?.mdLastname}`}
              leftItem={
                <img
                  style={{
                    width: 20,
                    height: 20,
                    marginRight: 35,
                    objectFit: 'contain',
                  }}
                  src="/images/privatePayment.png"
                  alt="user"
                />
              }
              rightIcon="redTrash"
              rightIconSize={16}
              onClick={removeMD}
            />
          </div>
        ) : (
          <>
            <TextField
              required
              id="firstName"
              sx={{ width: 0.5, pr: 1 }}
              label={localize('signup.name.placeholder')}
              helperText={
                (formik.touched.firstName && formik.errors.firstName) || ''
              }
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              variant="standard"
              size="small"
              type="text"
              autoComplete="given-name"
              {...formik.getFieldProps('firstName')}
            />
            <TextField
              required
              id="lastName"
              sx={{ width: 0.5 }}
              label={localize('signup.facebook.lastname.placeholder')}
              helperText={
                (formik.touched.lastName && formik.errors.lastName) || ''
              }
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              variant="standard"
              size="small"
              type="text"
              autoComplete="family-name"
              {...formik.getFieldProps('lastName')}
            />
          </>
        )}
        <TextField
          required
          fullWidth
          id="email"
          className="form-input"
          label={localize('signup.email.org.placeholder')}
          helperText={(formik.touched.email && formik.errors.email) || ''}
          error={formik.touched.email && Boolean(formik.errors.email)}
          variant="standard"
          size="small"
          type="email"
          autoComplete="email"
          {...formik.getFieldProps('email')}
        />
        <TextField
          required
          fullWidth
          id="password"
          className="form-input"
          type={showPassword ? 'text' : 'password'}
          label={localize('signup.password.placeholder')}
          helperText={(formik.touched.password && formik.errors.password) || ''}
          error={formik.touched.password && Boolean(formik.errors.password)}
          variant="standard"
          size="small"
          autoComplete="new-password"
          {...formik.getFieldProps('password')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div
          style={{ display: 'flex', marginTop: 24 }}
          onClick={
            privacyPolicyConsentRequired ? onPressPrivacyPolicyConsent : null
          }
        >
          {privacyPolicyConsentRequired && (
            <Checkbox
              style={{ marginRight: 8 }}
              checked={privacyPolicyStatus === PRIVACY_POLICY_STATUS.APPROVED}
            />
          )}
          <div className="signup-privacy-policy-container">
            <Hyperlink
              linkStyle={{ color: getBrandColor() }}
              linkText={(url) => {
                if (url === localize('signup.terms.url')) {
                  return localize('signup.terms.url.text');
                }
                if (url === localize('privacy.url')) {
                  return localize('signup.name.policy.text');
                }
                return url;
              }}
              onPress={showAboutDialog}
            >
              <div
                style={
                  privacyPolicyStatus === PRIVACY_POLICY_STATUS.ERROR
                    ? { color: 'red' }
                    : {}
                }
              >
                {localize(
                  'signup.terms.text',
                  localize('signup.terms.url'),
                  localize('privacy.url'),
                  localize(`brand.${getBrandName(config.brand.xBrandId!)}.name`)
                )}
              </div>
            </Hyperlink>
          </div>
        </div>
        {!isKeyboardOpened && <div className="screen-vertical-space" />}
        <div
          className="signup-footnote-container"
          style={
            isKeyboardOpened && isMobileBrowser()
              ? {
                  position: 'unset',
                  width: '100%',
                  marginTop: 30,
                  padding: 0,
                }
              : {}
          }
        >
          <BrandButton
            id={GA_TITLES.AUTH.SIGN_UP}
            title={localize('signup.button.title')}
            containerStyles={{ position: 'unset', width: '100%' }}
            formik={formik}
          />
          <div onClick={onPressLogin} className="signup-footnote">
            <div className="signup-footnote-text">
              {localize('signup.footnote.login.text')}
            </div>
            <div className="signup-footnote-action">
              {localize('signup.footnote.login.action')}
            </div>
          </div>
        </div>
      </>
    </Box>
  );

  return (
    <div className="screen-container" style={containerStyles}>
      <NavigationBar
        title={localize('signup.org.header')}
        description={localize('signup.org.desc')}
        showHelp
        onClose={onClose}
        showLogo
        showBack={showBack}
        showLanguageSelector
      />
      <div className="screen-body">
        {activeSlide === 0 && <div className="org-slide">{TypeSelection}</div>}
        {activeSlide === 1 && <div className="org-slide">{OrgSearch}</div>}
        {activeSlide === 2 && (
          <div className="org-slide">{ManagingDirectorSelection}</div>
        )}
        {activeSlide === 3 && <div className="org-slide">{ManualForm}</div>}
      </div>
    </div>
  );
};

export default OrgSignupView;
