import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Card from '../Card/Card';
import './CardItem.less';
import { getLightBrandColor, LocalizePropType } from 'getaway-data-layer';
import BrandIcon from '../BrandIcon/BrandIcon';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

type Props = {
  title: string;
  subTitle?: string;
  price?: string;
  description: string;
  deductionTitle?: string;
  deductionPrice?: string;
  selected: boolean;
  onClick: () => void;
  localize: LocalizePropType;
  showExtraOptionsLink?: boolean;
  onClickExtraOptions?: () => void;
  firstItem?: boolean;
  lastItem?: boolean;
  subTitleClassName?: string;
  showPopularBadge?: boolean;
  popularText?: string;
  hideRadio?: boolean;
  icon?: string;
  rightIcon?: string;
  showDescriptionLoading?: boolean;
};

const loadingIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

const CardItem = ({
  localize,
  title,
  subTitle,
  price,
  description,
  selected,
  onClick,
  showExtraOptionsLink,
  onClickExtraOptions,
  firstItem,
  lastItem,
  subTitleClassName,
  showPopularBadge,
  popularText,
  hideRadio,
  icon,
  rightIcon,
  showDescriptionLoading,
}: Props) => {
  return (
    <Card hideShadow={true} firstItem={firstItem} lastItem={lastItem}>
      <div
        className="card-item-container"
        onClick={onClick}
        style={selected ? { backgroundColor: getLightBrandColor() } : {}}
      >
        <div className="card-item-header">
          <div className="card-item-header-left">
            {icon && (
              <img
                style={{
                  width: 31,
                  height: 20,
                  marginRight: 12,
                  objectFit: 'contain',
                }}
                src={icon}
                alt="icon"
              />
            )}
            <div>
              <div className="card-item-title-container">
                <strong className="card-item-title">{title}</strong>
                {showPopularBadge && (
                  <div className={`card-item-badge-popular`}>{popularText}</div>
                )}
              </div>

              {subTitle && (
                <Markdown
                  rehypePlugins={[rehypeRaw]}
                  className={`card-item-subtitle ${subTitleClassName}`}
                >
                  {subTitle}
                </Markdown>
              )}

              {description && (
                <Markdown
                  rehypePlugins={[rehypeRaw]}
                  className="card-item-desc"
                >
                  {description}
                </Markdown>
              )}
              {showDescriptionLoading && <Spin indicator={loadingIcon} />}
            </div>
          </div>
          {hideRadio && (
            <BrandIcon
              name={rightIcon || 'arrowRight'}
              style={{
                width: 18,
                height: 18,
                marginLeft: '10px',
                alignSelf: 'center',
              }}
            />
          )}
        </div>
        {showExtraOptionsLink && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              onClickExtraOptions();
            }}
            className="card-item-link"
          >
            {localize('card-item.extra.options.link')}
          </div>
        )}
        {!hideRadio && (
          <div className="card-item-radio-wrapper">
            <div className="card-item-price">
              <Markdown rehypePlugins={[rehypeRaw]}>{price}</Markdown>
            </div>
            <input
              type="radio"
              id="radioButton"
              name="radioGroup"
              className="card-item-radio"
              checked={selected}
            />
          </div>
        )}
      </div>
    </Card>
  );
};

CardItem.defaultProps = {
  subTitleClassName: '',
  showPopularBadge: false,
  rightIcon: null,
  showDescriptionLoading: false,
};

export default CardItem;
