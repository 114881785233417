import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  signUp,
  withTextLocalizer,
  selectBrandSettings,
  LocalizePropType,
  DEFAULT_PASSWORD_REGEX,
  selectDialog,
  selectNetworkActivities,
  CLIENT_TYPE,
  LegalEntityData,
  ManagingDirector,
} from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import OrgSignupView from './OrgSignupView';
import {
  GA_TITLES,
  isEmailVerificationRequired,
  navigateTo,
  reportGAEvent,
} from '../../../utils';
import { showSignupTermsDialog } from '../../../utils/dialogsHelpers';
import {
  SignupModal,
  SignupSchema,
  SIGNUP_TYPES,
  LEGAL_TYPES,
} from '../../../validation';
import { useQuery } from '../../../hooks/useQuery';
import { PRIVACY_POLICY_STATUS } from '../../../enums';
import { searchOrgs } from 'getaway-data-layer/src/redux/store/userData/user/actions';

interface Values {
  // org
  companyName?: string;
  legalType?: string;
  otherLegalName?: string;
  registerNr?: string;
  registerLocation?: string;

  // private
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

type Props = {
  localize: LocalizePropType;
  containerStyles: any;
  showBack: boolean;
};

const hasAllAddressProperties = (siData) => {
  const requiredProps = [
    siData.headquartersCountry,
    siData.addressStreet,
    siData.addressStreetNumber,
    siData.addressCity,
    siData.addressPostalCode,
  ];

  return requiredProps.every((prop) => prop && prop.trim() !== '');
};

const OrgSignupContainer = ({
  localize,
  containerStyles,
  showBack = true,
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const brandSettings = useSelector(selectBrandSettings);
  const networkActivities: any = useSelector(selectNetworkActivities);
  const privacyPolicyConsentRequired =
    brandSettings?.privacyPolicyConsentRequired;
  const selectedDialog = useSelector(selectDialog);
  const query = useQuery();
  const [showPassword, setShowPassword] = useState(false);
  const [privacyPolicyStatus, setPrivacyPolicyStatus] = useState(
    PRIVACY_POLICY_STATUS.OPEN
  );
  const [disableHorizontalScroll, setDisableHorizontalScroll] = useState(false);

  const searchLoading = networkActivities.some((activityType: any) =>
    [CLIENT_TYPE.USER_CLIENT.SEARCH_ORGS].includes(activityType)
  );

  const loadingOrgDetails = networkActivities.some((activityType: any) =>
    [CLIENT_TYPE.USER_CLIENT.SEARCH_ORGS_FULL_VIEW].includes(activityType)
  );
  const circleCode = query?.get('id') || query?.get('circleCode');

  const [activeSlide, setActiveSlide] = useState(0);
  const [input, setInput] = useState('');
  const [searchResults, setSearchResults] = useState(null) as any;
  const [searchError, setSearchError] = useState(null);

  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedOrgDetails, setSelectedOrgDetails] = useState(null);

  const [showIndividualDialog, setShowIndividualDialog] = useState(false);

  const [selectedMD, setSelectedMD] = useState(null);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit = (values: any) => {
    const { firstName, lastName, email, password } = values;
    if (
      privacyPolicyConsentRequired &&
      privacyPolicyStatus === PRIVACY_POLICY_STATUS.OPEN
    ) {
      setPrivacyPolicyStatus(PRIVACY_POLICY_STATUS.ERROR);
    } else if (
      !privacyPolicyConsentRequired ||
      (privacyPolicyStatus === PRIVACY_POLICY_STATUS.APPROVED &&
        privacyPolicyConsentRequired)
    ) {
      const signUpInput = {
        ...values,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        password: password.trim(),
        userType: 'org',
        ...(hasAllAddressProperties(selectedOrgDetails?.siData) && {
          address: {
            country: selectedOrgDetails.siData.headquartersCountry,
            streetName: selectedOrgDetails.siData.addressStreet,
            streetNumber: selectedOrgDetails.siData.addressStreetNumber,
            city: selectedOrgDetails.siData.addressCity,
            postalCode: selectedOrgDetails.siData.addressPostalCode,
          },
        }),
      };
      if (circleCode) {
        // @ts-ignore
        signUpInput.circleCode = circleCode;
      }
      dispatch(
        signUp(
          signUpInput,
          localize,
          (user) => {
            if (brandSettings?.onboardingType === 'onBooking') {
              navigateTo(history, '/map');
            } else {
              navigateTo(
                history,
                isEmailVerificationRequired(user)
                  ? '/onboarding/email'
                  : '/onboarding/addPhone'
              );
            }
            reportGAEvent(GA_TITLES.AUTH.SIGN_UP_SUBMITTED);
          },
          query.get('utm_source'),
          query.get('utm_medium')
        )
      );
    }
  };

  const onChangeOrgSearch = (e: any) => {
    setInput(e.target.value);
    setSearchError(null);
  };

  const searchOrgas = () => {
    if (input && !searchLoading) {
      dispatch(
        searchOrgs(input, 'false', 'EXACT_MATCH', (res) =>
          setSearchResults(res)
        )
      );
      setSelectedOrg(null);
      setSelectedOrgDetails(null);
    } else if (!input) {
      setSearchError(localize('validation.required'));
    }
  };

  const SignupFormik = useFormik({
    initialValues: SignupModal(SIGNUP_TYPES.ORG),
    validationSchema: SignupSchema(
      SIGNUP_TYPES.ORG,
      localize,
      brandSettings?.passwordRegex || DEFAULT_PASSWORD_REGEX
    ),
    onSubmit,
  });

  const onClose = () => {
    history.replace('/signup');
  };

  const showAboutDialog = () => {
    showSignupTermsDialog(localize);
  };

  const onPressPrivacyPolicyConsent = () => {
    setPrivacyPolicyStatus(
      privacyPolicyStatus === PRIVACY_POLICY_STATUS.APPROVED
        ? PRIVACY_POLICY_STATUS.OPEN
        : PRIVACY_POLICY_STATUS.APPROVED
    );
  };

  const onChangeLegalType = (e) => {
    SignupFormik.setFieldValue('legalType', e.target.value);
  };

  const onPressLogin = () => {
    navigateTo(history, '/login');
  };

  const handleCompanyNameChange = (e) => {
    const value = e.target.value;
    const trimmedValue = value.trim();

    const lastWord = trimmedValue.split(' ').pop();

    if (Object.values(LEGAL_TYPES).includes(lastWord)) {
      SignupFormik.setFieldValue('legalType', lastWord);
      SignupFormik.setFieldValue(
        'companyName',
        trimmedValue.replace(new RegExp(`\\s*${lastWord}$`), '')
      );
    } else {
      SignupFormik.setFieldValue('companyName', value);
    }
  };

  const afterSlide = (index: number) => {};

  const onClickIndividual = () => {
    setShowIndividualDialog(true);
    setDisableHorizontalScroll(true);
  };

  const openIndividualSignup = () => {
    navigateTo(history, '/signup');
  };

  const onClickOrg = () => {
    setShowIndividualDialog(false);
    setActiveSlide(activeSlide + 1);
    setDisableHorizontalScroll(false);
  };

  const onClickManualLinkOrg = () => {
    setActiveSlide(3);
  };

  const onClickManualLinkMD = () => {
    setActiveSlide(activeSlide + 1);
  };

  const removeSelectedOrg = () => {
    setSelectedOrg(null);
    setDisableHorizontalScroll(true);
    setActiveSlide(1);
  };

  const slideBack = () => {
    setActiveSlide(activeSlide - 1);
  };

  const onSelectOrg = (org: LegalEntityData) => {
    setActiveSlide(activeSlide + 1);
    setDisableHorizontalScroll(true);
    setSelectedOrg(org);
    setSelectedOrgDetails(null);
    dispatch(
      searchOrgs(org?.name, 'true', 'EXACT_MATCH', (res) => {
        setSelectedOrgDetails(res?.[0]);
        SignupFormik.setFieldValue(
          'companyName',
          res?.[0]?.name?.replace(
            new RegExp(`\\s*${res?.[0]?.siData?.orgaType}$`),
            ''
          )
        );
        SignupFormik.setFieldValue('legalType', res?.[0]?.siData?.orgaType);
        SignupFormik.setFieldValue(
          'registerNr',
          res?.[0]?.siData?.registerNumber
        );
        SignupFormik.setFieldValue('registerLocation', res?.[0]?.state);
      })
    );
  };

  const onSelectMd = (md: ManagingDirector) => {
    setSelectedMD(md);
    SignupFormik.setFieldValue('firstName', md?.mdFirstname);
    SignupFormik.setFieldValue('lastName', md?.mdLastname);
    setActiveSlide(activeSlide + 1);
  };

  const removeMD = () => {
    setSelectedMD(null);
    SignupFormik.setFieldValue('firstName', '');
    SignupFormik.setFieldValue('lastName', '');
  };

  useEffect(() => {
    if (!selectedDialog) {
      SignupFormik.setFieldValue('signupType', SIGNUP_TYPES.PRIVATE);
    }
  }, [selectedDialog]);

  return (
    <OrgSignupView
      localize={localize}
      formik={SignupFormik}
      containerStyles={containerStyles}
      showBack={showBack}
      showPassword={showPassword}
      privacyPolicyStatus={privacyPolicyStatus}
      privacyPolicyConsentRequired={privacyPolicyConsentRequired}
      activeSlide={activeSlide}
      afterSlide={afterSlide}
      onClose={onClose}
      showAboutDialog={showAboutDialog}
      handleClickShowPassword={handleClickShowPassword}
      handleMouseDownPassword={handleMouseDownPassword}
      onPressLogin={onPressLogin}
      onPressPrivacyPolicyConsent={onPressPrivacyPolicyConsent}
      onChangeLegalType={onChangeLegalType}
      handleCompanyNameChange={handleCompanyNameChange}
      onClickIndividual={onClickIndividual}
      onClickOrg={onClickOrg}
      orgSearch={input}
      searchResults={searchResults}
      onChangeOrgSearch={onChangeOrgSearch}
      searchOrgas={searchOrgas}
      searchLoading={searchLoading}
      onClickManualLinkOrg={onClickManualLinkOrg}
      onClickManualLinkMD={onClickManualLinkMD}
      searchError={searchError}
      onSelectOrg={onSelectOrg}
      selectedOrg={selectedOrg}
      selectedOrgDetails={selectedOrgDetails}
      loadingOrgDetails={loadingOrgDetails}
      removeSelectedOrg={removeSelectedOrg}
      showIndividualDialog={showIndividualDialog}
      openIndividualSignup={openIndividualSignup}
      disableHorizontalScroll={disableHorizontalScroll}
      slideBack={slideBack}
      onSelectMd={onSelectMd}
      selectedMd={selectedMD}
      removeMD={removeMD}
    />
  );
};

export default withTextLocalizer(OrgSignupContainer);
